import { DocumentElement } from '@contrail/documents';
import { DocumentService } from '../document.service';
import { ClipboardPasteHandler } from './paste/clipboard-paste-handler';
import { AuthService } from '@common/auth/auth.service';
import { EntityReference } from '@contrail/sdk';

export class DocumentClipboard {
  private pasteHandler: ClipboardPasteHandler;

  constructor(
    private documentService: DocumentService,
    private authService: AuthService,
  ) {
    this.pasteHandler = new ClipboardPasteHandler(documentService, authService);
  }
  private documentId: string;
  private clipboardData?: {
    elements: DocumentElement[];
    orgId: string;
    documentId: string;
    entityType?: string;
  };
  private clipboardElementFormatData: DocumentElement;

  public pasteCopiedElements(
    pasteEvent,
    options: DocumentElement = {},
    shiftKey = false,
    config?: { scaleSize?: boolean },
  ) {
    this.pasteHandler.handleDocumentPasteEvent(pasteEvent, options, shiftKey, config);
  }

  public pasteDocumentElementsFromClipboard() {
    this.pasteHandler.pasteDocumentElementsFromClipboard({}, null, false, true);
  }

  public getSelectionText(): string {
    let text = null;
    if (window.getSelection) {
      text = window.getSelection().toString();
    }
    return text;
  }

  /**
   * Check whether window selection text is in the text of
   * the component @element
   * @param element
   * @returns
   */
  private selectionTextInComponent(element: DocumentElement): boolean {
    const selectionText = this.getSelectionText()?.replace(/\n/g, '');
    if (selectionText?.length === 0) {
      return false;
    }
    let componentText = '';
    for (let i = 0; i < element?.elements?.length; i++) {
      let e = element.elements[i];
      componentText = componentText + (e?.label || '') + (e?.text || '');
    }
    return componentText.indexOf(selectionText) !== -1;
  }

  public copySelectedElementsToClipboard() {
    console.log('DocumentClipboard: copySelectedElementsToClipboard');
    // Copy element to OS clipboard. Currently only works on one image or text.
    // This code will create a temporary DOM element and attach it to the composer layout for copying.

    const selectionText = this.getSelectionText();
    // Do not override clipboard content if there is any text in clipboard - user might be trying to
    // copy simple text (for ex., item names from alerts)
    if (selectionText && selectionText != '') {
      return;
    }

    let isComponentTextCopy = false;
    let selectedElements = [...this.documentService.getSelectedExpandedElements()];
    if (selectedElements?.length > 0) {
      // Filter out component elements if window selection text
      // is in the component's text. We want to allow users to select
      // text in the component and paste it to the document
      selectedElements = selectedElements.filter((e) => {
        if (e.type === 'component' && this.selectionTextInComponent(e)) {
          isComponentTextCopy = true;
          return false;
        }
        return e;
      });
    }

    if (selectedElements) {
      let copyTarget;
      const mainLayout = document.querySelector('.main-theme');
      if (selectedElements.length === 1) {
        selectedElements.forEach((element) => {
          if (element.type === 'image' && element.url) {
            copyTarget = document.createElement('img');
            copyTarget.src = element.url;
            mainLayout.appendChild(copyTarget);
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNode(copyTarget);
            selection.removeAllRanges();
            selection.addRange(range);
          } else {
            copyTarget = document.createElement('input');
            copyTarget.setAttribute('type', 'text');
            copyTarget.value = ' '; // clear out OS clipboard if this is not an image or text
            if (element.type === 'text') {
              copyTarget.value = element.text; // this is a text, copy to OS clipboard
            }
            mainLayout.appendChild(copyTarget);
            copyTarget.select();
          }
        });
      } else {
        // Do not clear our clipboard if user is trying to copy text from component elements
        if (!isComponentTextCopy && !(selectedElements?.length === 0 && selectionText?.length > 0)) {
          copyTarget = document.createElement('input');
          copyTarget.setAttribute('type', 'text');
          copyTarget.value = ' '; // clear out OS clipboard if more than one element is copied.
          mainLayout.appendChild(copyTarget);
          copyTarget.select();
        } else {
          copyTarget = document.createElement('input');
          copyTarget.setAttribute('type', 'text');
          copyTarget.value = selectionText ? selectionText?.replace(/\n/g, ' ') : ' ';
          mainLayout.appendChild(copyTarget);
          copyTarget.select();
        }
      }

      if (copyTarget) {
        document.execCommand('copy');
        mainLayout.removeChild(copyTarget);
      }

      // save document id from which the elements were copied from
      this.documentId = this.documentService?.currentDocument?.id;
      // copy to an array for internal consumption
      let entityType;
      if (this.documentService?.ownerReference) {
        const entity = new EntityReference(this.documentService?.ownerReference);
        entityType = entity?.entityType;
      }
      this.clipboardData = {
        entityType,
        documentId: this.documentId,
        elements: selectedElements,
        orgId: this.authService.getCurrentOrg()?.orgId,
      };
      localStorage.setItem('clipboardData', JSON.stringify(this.clipboardData));

      // copy the clipboardData content to the OS clipboard
      copyTarget = document.createElement('input');
      copyTarget.setAttribute('type', 'text');
      copyTarget.value = JSON.stringify(this.clipboardData);
      mainLayout.appendChild(copyTarget);
      copyTarget.select();
      document.execCommand('copy');
      mainLayout.removeChild(copyTarget);
    }
  }

  copySelectedElementFormatToClipboard() {
    const selectedElements = this.documentService.getSelectedElements();
    if (selectedElements.length === 1) {
      this.clipboardElementFormatData = selectedElements[0];
    }
  }

  public getClipboardContent() {
    let clipboardData = this.clipboardData;
    try {
      clipboardData = JSON.parse(localStorage.getItem('clipboardData'));
    } catch (e) {
      clipboardData = this.clipboardData;
    }

    if (clipboardData && clipboardData.orgId !== this.authService.getCurrentOrg()?.orgId) {
      return null;
    }

    return clipboardData;
  }

  public getClipboardDocumentId() {
    return this.documentId;
  }

  public getClipboardElementFormatContent() {
    return this.clipboardElementFormatData;
  }

  public clear() {
    this.clipboardData = null;
  }

  public clearElementFormatContent() {
    this.clipboardElementFormatData = null;
  }
}
