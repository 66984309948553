import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { PresentationCollectionElement } from '../../../../presentation';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { AddPinnedCommentsService } from '../../../composer-pinned-comments/add-pinned-comments-service';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { ShowcasesActions } from 'src/app/showcases/showcases-store';
import { ObjectUtil } from '@contrail/util';
import { ContextMenuActionDefinition } from '@common/components/context-menu/context-menu.component';
import { ComposerGridFrameService } from '../composer-grid-frame.service';
import { CommentsActions } from '@common/comments/comments-store';
import { ComposerToolbarService } from '../../../composer-toolbar/composer-toolbar.service';
import { ShowcaseBackingAssortmentService } from '../../../backing-assortment/showcase-backing-assortment-service';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { AssortmentUtil } from '@common/assortments/assortment-util';
import { ItemService } from '@common/items/item.service';

const ITEMS_PER_GROUP = 10;

@Component({
  selector: 'app-composer-grid-frame-section',
  templateUrl: './composer-grid-frame-section.component.html',
  styleUrls: ['./composer-grid-frame-section.component.scss'],
})
export class ComposerGridFrameSectionComponent implements OnInit, OnChanges {
  optionPreviewClass = 'option';
  @Input() section: PresentationCollectionElement;
  @Input() backingAssortmentItemData: Array<any>;
  @Input() viewDefinition: any;
  @Input() movedItem: any;
  @Output() updateCollectionItem = new EventEmitter();
  @Input() annotationType;
  @Input() annotationOptionMap: any;
  @Input() selected = false;
  @Input() editorMode: EditorMode;
  @Input() frameId: string;
  @Input() searchResults: any[];
  @Input() activeSearchResultElement: any;
  @Output() viewItemDetail = new EventEmitter();
  cursorClass;
  @ViewChildren(MatMenuTrigger) triggers: QueryList<MatMenuTrigger>;
  contextMenuPosition = { x: '0px', y: '0px' };
  optionCount = 0;
  sectionGroups;
  hoveredOver = false;
  properties: any[] = ['optionName'];
  @Input() itemMap;
  @Output() openMenu = new EventEmitter();

  public contextMenuActions: Array<ContextMenuActionDefinition> = [
    { actionName: 'editName', icon: 'edit', label: 'Edit section name' },
    { actionName: 'delete', icon: 'delete', label: 'Delete' },
  ];
  constructor(
    private store: Store<RootStoreState.State>,
    private composerGridFrameService: ComposerGridFrameService,
    private composerToolbarService: ComposerToolbarService,
    private backingAssortmentService: ShowcaseBackingAssortmentService,
    private addPinnedCommentsService: AddPinnedCommentsService,
    private itemService: ItemService,
  ) {}

  ngOnInit(): void {
    this.optionCount = this.backingAssortmentItemData.filter(
      (itemData) => itemData.item.itemFamilyId === this.section.value,
    ).length;
    this.generateSectionGroups();
    console.log(this.itemMap);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.section) {
      this.reconcileItemChanges(changes.section);
    }
    if (changes.itemMap) {
      this.generateSectionGroups();
    }

    if (changes.annotationType) {
      if (changes.annotationType.currentValue) {
        this.cursorClass = 'option ' + changes.annotationType.currentValue + '-cursor';
      } else {
        this.cursorClass = null;
      }
    }
    if (this.viewDefinition) {
      this.properties = this.viewDefinition.properties;
    }
  }

  generateSectionGroups() {
    if (!this.sectionGroups) {
      const allItems = this.section.children.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / ITEMS_PER_GROUP);
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }
        resultArray[chunkIndex].push(item.value);
        return resultArray;
      }, []);
      this.sectionGroups = allItems;
    } else {
      this.sectionGroups.forEach((sectionGroup) => {
        sectionGroup.splice(0); // clear all rows
      });
      const updatedItemIds = this.section.children.map((item) => item.value);
      let groupIndex = 0;
      let itemIndex = 0;
      while (itemIndex < updatedItemIds.length) {
        if (!this.sectionGroups[groupIndex]) {
          this.sectionGroups.push([]);
        }
        for (let i = 0; i < ITEMS_PER_GROUP; i++) {
          itemIndex = groupIndex * ITEMS_PER_GROUP + i;
          if (updatedItemIds[itemIndex]) {
            this.sectionGroups[groupIndex].push(updatedItemIds[itemIndex]);
          }
        }
        groupIndex++;
      }
      // remove empty rows
      const emptyRowIndex = this.sectionGroups.findIndex((sectionGroup) => sectionGroup.length === 0);
      if (emptyRowIndex > -1) {
        this.sectionGroups.splice(emptyRowIndex);
      }
    }
  }

  drop(event: CdkDragDrop<any>) {
    const itemId = event.item.data.item;
    const previousContainerId = event.previousContainer.id;
    const containerId = event.container.id;
    const itemIndex = this.section.children.findIndex((child) => child.value === itemId);
    const sourceContainerSectionId = previousContainerId.substring(0, previousContainerId.indexOf('|'));
    const sourceRowIndex = parseInt(previousContainerId.substring(previousContainerId.indexOf('|') + 1));
    const targetContainerSectionId = containerId.substring(0, containerId.indexOf('|'));
    const targetRowIndex = parseInt(containerId.substring(containerId.indexOf('|') + 1));
    const targetRowNumber = parseInt(containerId.substring(containerId.indexOf('|') + 1));
    if (sourceContainerSectionId === targetContainerSectionId) {
      // same section
      if (sourceRowIndex === targetRowIndex && event.currentIndex === event.previousIndex) {
        // position is not changed.
        return;
      }
      moveItemInArray(this.section.children, itemIndex, ITEMS_PER_GROUP * targetRowNumber + event.currentIndex);
      this.updateCollectionItem.emit({ item: this.section });
    } else {
      const backingItemData = this.backingAssortmentItemData.find((itemData) => itemData.id === itemId);
      const item = {
        enabled: true,
        value: itemId,
        display: backingItemData.properties.optionName,
      };
      this.section.children.splice(ITEMS_PER_GROUP * targetRowNumber + event.currentIndex, 0, item);
      this.updateCollectionItem.emit({
        item: ObjectUtil.cloneDeep(this.section),
        removedItemDataInfo: { containerId: sourceContainerSectionId, item: itemId },
      });
    }
  }

  removeOption(event, itemId) {
    event.stopPropagation();
    const index = this.section.children.findIndex((child) => child.value === itemId);
    this.section.children.splice(index, 1);
    this.updateCollectionItem.emit({ item: ObjectUtil.cloneDeep(this.section) });
  }

  onGridSectionContextMenu(event: MouseEvent, sectionId) {
    this.onContextMenu(event, { sectionId });
  }

  onItemContextMenu(event: MouseEvent, itemId) {
    this.onContextMenu(event, { item: itemId });
  }

  onContextMenu(event: MouseEvent, menuData) {
    const contextMenu = this.triggers.toArray()[1];
    let positionX = event.clientX;
    let positionY = event.clientY;
    // To display collection items scrollable content which uses transform: translateY(0px) - which
    // resets coordinate system so we need to adjust the context menu position.
    const scrollableContent = document.getElementsByClassName('scrollable-content');
    if (scrollableContent?.length > 0) {
      positionX = positionX - scrollableContent[0].getBoundingClientRect().left;
      positionY = positionY - scrollableContent[0].getBoundingClientRect().top;
    }
    event.preventDefault();
    this.contextMenuPosition.x = positionX + 'px';
    this.contextMenuPosition.y = positionY + 'px';
    contextMenu.menuData = menuData;
    contextMenu.menu.focusFirstItem('mouse');
    contextMenu.openMenu();
  }

  addItemComment(itemId) {
    this.triggers.toArray()[1].closeMenu();
    this.addPinnedCommentsService.addItemComment(itemId, this.frameId);
  }

  addGridSectionComment(sectionId) {
    this.triggers.toArray()[1].closeMenu();
    this.addPinnedCommentsService.addGridSectionComment(this.frameId, sectionId);
  }

  onClick(event) {
    event.stopPropagation();
    const objHierarchy = event.path || (event.composedPath && event.composedPath());
    const commentAvatar = objHierarchy.find(
      (el) =>
        ['APP-COMPOSER-COLLECTION-ITEM-PINNED-COMMENTS', 'APP-COMPOSER-GRID-SECTION-PINNED-COMMENTS'].indexOf(
          el.nodeName,
        ) !== -1,
    );
    this.store.dispatch(
      ShowcasesActions.setGridFrameActiveContainer({ gridFrameActiveContainer: ObjectUtil.cloneDeep(this.section) }),
    );
    if (!commentAvatar) {
      this.store.dispatch(CommentsActions.hideCommentOverlay());
    }
  }

  handleMenuAction(action) {
    if (action === 'editName') {
      this.composerGridFrameService.editSectionName(this.section);
    } else if (action === 'delete') {
      this.composerGridFrameService.deleteSection(this.section);
    }
  }

  async onDrop(event) {
    if (event.dataTransfer.getData('dataObject')) {
      let data = event.dataTransfer.getData('dataObject');
      if (data) {
        data = JSON.parse(data);
      }
      const itemDataRelativeToSource = await this.itemService.setItemDataRelativeToSource([data]);
      const entity = AssortmentUtil.convertItemData(itemDataRelativeToSource[0]);

      const itemData = entity.item || entity;
      const item = { value: itemData.id, label: itemData.optionName, enabled: true };
      this.section.children.push(item);
      if (this.sectionGroups.length > 0) {
        this.sectionGroups[this.sectionGroups.length - 1].push(item);
      } else {
        this.sectionGroups[0] = [];
        this.sectionGroups[0].push(item);
      }
    }
    this.updateCollectionItem.emit({ item: ObjectUtil.cloneDeep(this.section) });
  }

  onDragOver(event) {
    event.preventDefault();
    if (!this.hoveredOver) {
      this.hoveredOver = true;
    }
  }

  onDragLeave(event) {
    if (this.hoveredOver) {
      this.hoveredOver = false;
    }
  }

  openItemChooser() {
    this.composerToolbarService.showItemChooser();
  }

  handleItemClick(item) {
    if (this.annotationType) {
      const property = this.annotationOptionMap[this.annotationType].property;
      this.backingAssortmentService.updateAssortmentItemsByProperty([item], property);
    }
  }

  trackByItem(index, itemId) {
    return itemId;
  }

  private reconcileItemChanges(changes) {
    if (!changes.firstChange) {
      // 1) Removed item
      // 2) Moved item from section to section
      if (changes.currentValue.children.length !== changes.previousValue?.children.length) {
        this.generateSectionGroups();
      } else {
        const allItemIds = this.sectionGroups.reduce((acc, val) => acc.concat(val), []).map((item) => item);
        const updatedItemIds = this.section.children.map((item) => item.value);
        if (allItemIds.join() !== updatedItemIds.join()) {
          // rearranged items in a section.
          this.generateSectionGroups();
        }
      }
    }
  }

  inSearchResults(item) {
    return (
      this.searchResults.findIndex(
        (searchResult) => searchResult.id === item && searchResult.frameId === this.frameId,
      ) > -1
    );
  }

  inSearchResultsActive(item) {
    return this.activeSearchResultElement?.id === item && this.activeSearchResultElement?.frameId === this.frameId;
  }

  viewItem(itemId) {
    this.viewItemDetail.emit({ container: ObjectUtil.cloneDeep(this.section), itemId });
  }
}
