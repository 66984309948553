import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ComposerIframeFrameService } from '../composer-iframe-frame.service';
import { RootStoreState } from '@rootstore';

@Component({
  selector: 'app-new-iframe-frame-modal',
  templateUrl: './new-iframe-frame-modal.component.html',
  styleUrls: ['./new-iframe-frame-modal.component.scss'],
})
export class NewIframeFrameModalComponent {
  public urlControl = new UntypedFormControl(null, [Validators.required]);
  public nameControl = new UntypedFormControl(null, [Validators.required]);
  error = null;

  constructor(
    private store: Store<RootStoreState.State>,
    public dialogRef: MatDialogRef<NewIframeFrameModalComponent>,
    private composerIframeFrameService: ComposerIframeFrameService,
  ) {}

  async create() {
    let url;
    if (this.urlControl.value?.toLowerCase().indexOf('iframe') > -1) {
      const htmlElement = new DOMParser().parseFromString(this.urlControl.value, 'text/html');
      const iframe = htmlElement.querySelector('iframe');
      if (iframe?.src) {
        url = iframe.src;
      } else {
        this.showError();
      }
    } else {
      this.showError();
    }

    if (url) {
      this.composerIframeFrameService.createIframeFrame(this.nameControl.value, url);
      this.dialogRef.close();
    }
  }

  showError() {
    this.error = 'The iFrame code or URL entered is invalid.';
    this.urlControl.setErrors(this.error);
    return;
  }

  cancel() {
    this.dialogRef.close();
  }
}
