import { Injectable } from '@angular/core';
import { DocumentService } from '../../document.service';
import { DocumentAction, DocumentChangeType, DocumentElement, DocumentElementFactory } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';

@Injectable({
  providedIn: 'root',
})
export class GroupElementService {
  constructor(private documentService: DocumentService) {
    this.documentService.actionRequests.subscribe((request) => {
      if (request?.actionType === 'remove_element_from_group') {
        const selected = this.documentService.getSelectedElements();
        if (selected.length === 1) {
          const actions: Array<DocumentAction> = [];
          const element = selected[0];
          let groupElement = this.documentService.getGroupByMemberId(element.id);
          groupElement = ObjectUtil.cloneDeep(groupElement);
          if (groupElement.elementIds.length > 2) {
            const originalGroupElement = ObjectUtil.cloneDeep(groupElement);
            groupElement.elementIds.splice(groupElement.elementIds.indexOf(element.id), 1);
            actions.push(
              new DocumentAction(
                {
                  changeType: DocumentChangeType.MODIFY_ELEMENT,
                  elementId: groupElement.id,
                  elementData: { id: groupElement.id, elementIds: groupElement.elementIds },
                },
                {
                  changeType: DocumentChangeType.MODIFY_ELEMENT,
                  elementId: groupElement.id,
                  elementData: originalGroupElement,
                },
              ),
            );
          } else {
            this.updateOrDeleteGroupElement(element.id, actions);
          }
          this.documentService.handleDocumentActions(actions);
        }
      } else if (request?.actionType === 'toggle_group_elements') {
        const selectedGroupElements = this.documentService.getSelectedGroupElements();
        if (selectedGroupElements.length > 1) {
          this.createGroupElement(selectedGroupElements);
        } else if (selectedGroupElements.length === 1 && selectedGroupElements[0].type === 'group') {
          this.deleteGroupElement(selectedGroupElements);
        }
      }
    });
  }

  createGroupElement(elements: Array<DocumentElement>) {
    const groupElement = DocumentElementFactory.createElement('group', {
      elementIds: elements.map((element) => element.id),
    });
    // Size and position of a group element is derived from its members. Size is set because API requires it.
    groupElement.size = { height: 1, width: 1 };
    const actions: Array<DocumentAction> = [];
    const action: DocumentAction = new DocumentAction(
      {
        elementId: groupElement.id,
        elementData: groupElement,
        changeType: DocumentChangeType.ADD_ELEMENT,
      },
      {
        elementId: groupElement.id,
        elementData: groupElement,
        changeType: DocumentChangeType.DELETE_ELEMENT,
      },
    );
    actions.push(action);
    this.documentService.handleDocumentActions(actions);
  }

  deleteGroupElement(elements: Array<DocumentElement>) {
    const groupElement = elements[0];
    const actions: Array<DocumentAction> = [];
    const action: DocumentAction = new DocumentAction(
      {
        elementId: groupElement.id,
        elementData: groupElement,
        changeType: DocumentChangeType.DELETE_ELEMENT,
      },
      {
        elementId: groupElement.id,
        elementData: groupElement,
        changeType: DocumentChangeType.ADD_ELEMENT,
      },
    );
    actions.push(action);
    this.documentService.handleDocumentActions(actions);
  }

  public updateOrDeleteGroupElement(elementId: string, actions: DocumentAction[]) {
    const groupElementMap: Map<string, DocumentElement> = new Map();
    this.gatherGroupChanges(elementId, groupElementMap);
    const updateActions: DocumentAction[] = [];
    groupElementMap.forEach((group, id) => {
      const originalGroupElement = this.documentService.getGroupById(id);
      if (group.elementIds.length === 1) {
        actions.push(
          new DocumentAction(
            {
              changeType: DocumentChangeType.DELETE_ELEMENT,
              elementId: id,
            },
            {
              changeType: DocumentChangeType.ADD_ELEMENT,
              elementId: id,
              elementData: originalGroupElement,
            },
          ),
        );
      } else {
        if (ObjectUtil.compareDeep(originalGroupElement.elementIds, group.elementIds, '').length) {
          updateActions.push(
            new DocumentAction(
              {
                changeType: DocumentChangeType.MODIFY_ELEMENT,
                elementId: id,
                elementData: { id, elementIds: group.elementIds },
              },
              {
                changeType: DocumentChangeType.MODIFY_ELEMENT,
                elementId: id,
                elementData: originalGroupElement,
              },
            ),
          );
        }
      }
    });
    actions.push(...updateActions);
  }

  private gatherGroupChanges(elementId: string, groupElementMap: Map<string, DocumentElement>) {
    let groupElement = this.documentService.getGroupByMemberId(elementId);
    if (groupElement) {
      if (groupElementMap.get(groupElement.id)) {
        groupElement = groupElementMap.get(groupElement.id);
      } else {
        groupElement = ObjectUtil.cloneDeep(groupElement);
        groupElementMap.set(groupElement.id, groupElement);
      }
      groupElement.elementIds.splice(groupElement.elementIds.indexOf(elementId), 1); // remove from group element
      if (groupElement.elementIds.length === 1) {
        // if only 1 element in the group, delete it and move remaining element to parent group
        let parentGroup = this.documentService.getGroupByMemberId(groupElement.id);
        if (parentGroup) {
          if (groupElementMap.get(parentGroup.id)) {
            parentGroup = groupElementMap.get(parentGroup.id);
          } else {
            parentGroup = ObjectUtil.cloneDeep(parentGroup);
            groupElementMap.set(parentGroup.id, parentGroup);
          }
          parentGroup.elementIds.push(groupElement.elementIds[0]); // move the remaining element to the parent group
        }
        this.gatherGroupChanges(groupElement.id, groupElementMap); // the parent group could be affected too
      }
    }
  }
}
