import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { ComposerService } from '../../composer.service';
import { NewIframeFrameModalComponent } from './new-iframe-frame-modal/new-iframe-frame-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ComposerIframeFrameService {
  public currentFrame;

  constructor(
    private store: Store<RootStoreState.State>,
    private composerService: ComposerService,
    private dialog: MatDialog,
  ) {
    this.composerService.currentFrame.subscribe((frame) => {
      this.currentFrame = frame;
    });
  }

  showCreateIframeFrame() {
    this.dialog.open(NewIframeFrameModalComponent, { width: '688px' });
  }

  async createIframeFrame(name: string, url: string) {
    const newFrame = {
      name,
      type: 'iframe',
      url,
    };

    await this.composerService.addPresentationFrame(newFrame);
  }
}
