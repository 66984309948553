import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './common/auth/auth.guard';
import { LoginComponent } from './common//auth/login/login.component';
import { MainComponent } from './main/main.component';
import { NoOrgsRegisteredComponent } from './common/auth/no-orgs-registered/no-orgs-registered.component';
import { UserOrgResolver } from './common/auth/user-org.resolver';
import { DefaultOrgResolver } from './common/auth/default-org.resolver';
import { FrameTemplateEditor } from './presentation/document-templates/frame-template-editor/frame-template-editor.component';
import { FrameTemplateLibrary } from './presentation/document-templates/frame-template-library/frame-template-library.component';
import { ItemTestComponent } from '@common/items/item-test/item-test.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    resolve: { defaultOrg: DefaultOrgResolver },
    children: [
      {
        path: 'org/:org',
        resolve: { user: UserOrgResolver },
        children: [
          { path: '', redirectTo: 'showcases', pathMatch: 'full' },
          { path: 'frame-template-library', component: FrameTemplateLibrary },
          {
            path: 'showcase-details',
            loadChildren: () =>
              import('./showcase-details/showcase-details.module').then((m) => m.ShowcaseDetailsModule),
          },
          {
            path: 'showcases',
            loadChildren: () => import('./showcases/showcases.module').then((m) => m.ShowcasesModule),
          },
          { path: 'item/:itemId', component: ItemTestComponent },
        ],
      },
    ],
  },

  { path: 'login', component: LoginComponent },
  { path: 'frame-template-editor', component: FrameTemplateEditor },
  { path: 'no-org-found', component: NoOrgsRegisteredComponent },
  { path: 'home', redirectTo: 'showcases', pathMatch: 'full' },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
