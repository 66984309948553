import { Injectable } from '@angular/core';
import { DocumentHistoryActions } from '@common/document-history/document-history-store';
import { DocumentHistoryActionTypes } from '@common/document-history/document-history-store/document-history.actions';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { WebSocketService } from '@common/web-socket/web-socket.service';
import { ConfirmationBoxService } from '@components/confirmation-box/confirmation-box';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { tap } from 'rxjs/operators';
import { ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { DocumentActions } from '../../document/document-store';
import { Presentation } from '../../presentation';
import { ComposerService } from '../composer.service';
import { Request } from '@contrail/sdk';

@Injectable({
  providedIn: 'root',
})
export class ComposerSnapshotService {
  private showcase: any;
  private presentation: Presentation;
  private snapshotFrameCopied = false; // used to indicate that a frame from a snapshot has been copied

  constructor(
    private composerService: ComposerService,
    private confirmationBoxService: ConfirmationBoxService,
    private actions$: Actions,
    private webSocketService: WebSocketService,
    private store: Store<RootStoreState.State>,
  ) {
    this.composerService.presentation.subscribe((presentation) => {
      this.presentation = presentation;
      // When a snapshot has been reverted to the latest version and a frame from a snapshot was copied, paste the copied frame.
      // The paste should be done after the presentation has been loaded.
      if (presentation && this.snapshotFrameCopied) {
        this.composerService.setSelectedPlaceholderFrame(null);
        this.composerService.setSelectedFrame(null);
        this.composerService.composerClipboard.pasteFrame();
        this.snapshotFrameCopied = false;
      }
    });
    this.store.select(ShowcasesSelectors.currentShowcase).subscribe((showcase) => (this.showcase = showcase));

    // listens to the RESTORE_ENTITY_SNAPSHOT action from the store
    this.actions$
      .pipe(
        ofType(DocumentHistoryActionTypes.RESTORE_ENTITY_SNAPSHOT),
        tap((action: any) => {
          this.restoreSnapshot(action.id);
        }),
      )
      .subscribe();
  }

  async restoreSnapshot(snapshotId: string) {
    const confirm = await this.confirmationBoxService.open(
      'Confirmation',
      'This action will save the current state of the showcase and replace it with this version. Do you want to proceed?',
    );
    if (confirm) {
      this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));
      await this.performRestoreSnapshot(this.showcase.id, snapshotId);
      this.webSocketService.sendSessionEvent({
        eventType: 'RESTORE_SHOWCASE_SNAPSHOT',
        changes: this.showcase.id,
      });
      this.clearSnapshot();
      this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
    }
  }

  async performRestoreSnapshot(showcaseId: string, snapshotId: string) {
    const requestUrl = '/showcases/' + showcaseId + '/restore/' + snapshotId;
    await Request.request(requestUrl, {
      method: 'PUT',
      body: JSON.stringify({}),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }

  pasteFrameFromSnapshot() {
    this.snapshotFrameCopied = true;
    // get the currently selected frame in the snapshot
    const selectedFrame = this.composerService.getSelectedFrameObject() || this.presentation.frames[0];
    // copy the selected frame from the snapshot
    this.composerService.composerClipboard.copyFrame(selectedFrame);
    this.clearSnapshot();
  }

  private clearSnapshot() {
    // revert to the latest showcase version
    this.store.dispatch(DocumentHistoryActions.clearCurrentEntitySnapshot());
    // close the snapshots panel
    this.store.dispatch(
      DocumentActions.toggleChooser({
        overlay: {
          showChooser: false,
        },
      }),
    );
  }
}
