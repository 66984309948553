<div
  class="cell"
  [ngClass]="{
    right: columnDefinition.alignment === 'right',
    left: columnDefinition.alignment === 'left',
    center: columnDefinition.alignment === 'center'
  }"
>
  <div class="value">
    <span *ngIf="!(columnDefinition?.propertyType === 'icon')">
      {{ display }}
    </span>
    <span *ngIf="columnDefinition?.propertyType === 'icon'">
      <img [src]="columnDefinition.constantValue || value" />
    </span>
  </div>
</div>
