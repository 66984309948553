import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { ActionRequest } from '@contrail/actions';
import { ObjectUtil } from '@contrail/util';
import { Subscription, fromEvent, filter } from 'rxjs';
import { DocumentService } from 'src/app/presentation/document/document.service';
import { ComposerToolbarService } from '../composer-toolbar.service';

export interface MenuItem {
  id: string;
  icon?: string;
  svgIcon?: string;
  class?: string;
  label?: string;
}

@Component({
  selector: 'app-composer-toolbar-add-element',
  templateUrl: './composer-toolbar-add-element.component.html',
  styleUrls: ['./composer-toolbar-add-element.component.scss'],
})
export class ComposerToolbarAddElementComponent implements OnInit, OnDestroy {
  constructor(
    private composerToolbarService: ComposerToolbarService,
    private documentService: DocumentService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.subscriptions.add(
      this.documentService.documentElementEvents.subscribe((event) => {
        if (event?.eventType === 'interactionMode' && event?.data?.interactionMode) {
          this.interactionMode = event?.data?.interactionMode;
        }
      }),
    );
  }
  @ViewChild(MatMenuTrigger) private menuTrigger: MatMenuTrigger;
  @ViewChild('penMenuTrigger') penMenuTrigger: MatMenuTrigger;

  shapeMenus: Array<MenuItem> = [
    { id: 'rectangle', icon: 'square', class: 'material-icons-outlined' },
    { id: 'round_rectangle', icon: 'check_box_outline_blank', class: 'material-icons-outlined' },
    { id: 'circle', icon: 'circle', class: 'material-icons-outlined' },
    { id: 'triangle', icon: 'change_history', class: 'material-icons-outlined' },
    { id: 'diamond', icon: 'square', class: 'rotate-45 scale-90 material-icons-outlined' },
    { id: 'rhombus', svgIcon: 'app-rhombus-shape', class: 'material-icons-outlined' },
    { id: 'double_arrow', svgIcon: 'app-double-arrow-shape', class: 'material-icons-outlined' },
    { id: 'right_arrow', svgIcon: 'app-right-arrow-shape', class: 'material-icons-outlined' },
    { id: 'star', icon: 'grade', class: 'material-icons-outlined' },
    { id: 'heart', icon: 'favorite_border', class: 'material-icons-outlined' },
    { id: 'cloud', icon: 'cloud', class: 'material-icons-outlined' },
  ];

  lineMenus: Array<MenuItem> = [
    { id: 'line', icon: 'horizontal_rule' },
    { id: 'arrow', icon: 'trending_flat' },
  ];

  textMenus: Array<MenuItem> = [
    {
      id: 'create_text_element',
      svgIcon: 'app-text-tool-create',
      label: 'Text tool',
    },
    {
      id: 'text',
      svgIcon: 'app-text-box-create',
      label: 'Text box',
    },
  ];

  penMenus: Array<MenuItem> = [
    {
      id: 'pen',
      icon: 'stylus',
      label: 'Pen',
      class: 'material-symbols-outlined',
    },
    {
      id: 'highlighter',
      icon: 'ink_highlighter',
      label: 'Highlighter',
      class: 'material-symbols-outlined',
    },
    {
      id: 'eraser',
      icon: 'ink_eraser',
      label: 'Eraser',
      class: 'material-symbols-outlined',
    },
  ];

  public elements;

  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };

  private subscriptions: Subscription = new Subscription();
  private documentClickSubscription: Subscription;
  private documentElementEventSubscription: Subscription;

  public interactionMode: string;

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.documentClickSubscription?.unsubscribe();
    this.documentElementEventSubscription?.unsubscribe();
  }

  setInteractionMode(mode) {
    this.composerToolbarService.setInteractionMode(mode);
  }

  getActiveIcon(menus) {
    return menus
      .filter((menu) => {
        return menu.id === this.composerToolbarService.getInteractionMode();
      })
      .map((menu) => {
        return menu.icon;
      })[0];
  }

  getActiveColor(mode) {
    return mode === this.composerToolbarService.getInteractionMode() ||
      (mode === 'shape' && this.getActiveIcon(this.shapeMenus)) ||
      (mode === 'line' && this.getActiveIcon(this.lineMenus))
      ? 'accent'
      : '';
  }

  isModeSelected(menus) {
    return (
      menus.filter((menu) => {
        return menu.id === this.getInteractionMode();
      }).length > 0
    );
  }

  getInteractionMode() {
    return this.composerToolbarService.getInteractionMode();
  }

  resetInteractionMode() {
    this.documentService.setInteractionMode('root');
    this.documentService.handleActionRequest(new ActionRequest('root'));
  }

  async onContextMenu(event: MouseEvent) {
    const menu = document.getElementById('toolMenuBtn');
    menu.style.display = '';
    menu.style.position = 'fixed';
    menu.style.left = '80px';
    menu.style.top = event.pageY - 55 + 'px';

    this.menuTrigger.openMenu();
  }

  closeToolMenu() {
    this.menuTrigger.closeMenu();
  }
  onToolMenuClosed(event): void {
    const menu = document.getElementById('toolMenuBtn');
    if (menu) {
      menu.style.display = 'none';
    }
  }

  showComments() {
    this.composerToolbarService.showComments();
  }

  uploadImage() {
    this.composerToolbarService.openImageFileUploadModal();
  }

  getCurrentValue(path) {
    const lastAppliedStyle = this.documentService.getLastAppliedStyle(this.documentService.getInteractionMode());
    const value = ObjectUtil.getByPath(lastAppliedStyle || {}, path);
    return value;
  }

  updateValues(value) {
    if (value?.style) {
      this.documentService.setLastAppliedStyle(this.documentService.getInteractionMode(), value.style);
    }
  }

  subscribeToDocumentClickToCloseMenu($event: MouseEvent, interactionModes, matMenuTrigger) {
    const target = $event.target as HTMLElement;
    if (this.documentClickSubscription && !this.documentClickSubscription.closed) {
      this.documentClickSubscription.unsubscribe();
    }
    this.documentClickSubscription = fromEvent(this.document, 'click')
      .pipe(
        filter((event) => {
          console.log(
            event,
            target,
            (event.target as HTMLElement)?.tagName !== 'CANVAS',
            !target?.contains(event.target as HTMLElement),
          );
          return (event.target as HTMLElement)?.tagName !== 'CANVAS' && !target?.contains(event.target as HTMLElement);
        }),
      )
      .subscribe(() => {
        this.closeMenu(matMenuTrigger);
      });

    if (!this.documentElementEventSubscription || this.documentElementEventSubscription.closed) {
      this.documentElementEventSubscription = this.documentService.documentElementEvents.subscribe((event) => {
        if (event?.eventType === 'interactionMode' && interactionModes.indexOf(event?.data?.interactionMode) === -1) {
          this.closeMenu(matMenuTrigger);
        }
      });
    }
  }

  public closeMenu(matMenuTrigger) {
    this.documentClickSubscription?.unsubscribe();
    this.documentElementEventSubscription?.unsubscribe();
    matMenuTrigger?.closeMenu();
    this.resetInteractionMode();
  }
}
