import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { ComposerService } from '../../composer.service';
import { NewShowroomFrameModalComponent } from './new-showroom-frame-modal/new-showroom-frame-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ComposerShowroomFrameService {
  public currentFrame;
  constructor(
    private store: Store<RootStoreState.State>,
    private composerService: ComposerService,
    private dialog: MatDialog,
  ) {
    this.composerService.currentFrame.subscribe((frame) => {
      this.currentFrame = frame;
    });
  }

  showCreateShowroomFrame() {
    this.dialog.open(NewShowroomFrameModalComponent, { width: '688px' });
  }

  async createFrame(showroomId) {
    const newFrame = {
      name: 'Showroom Frame',
      type: 'showroom',
      showroomId,
    };

    await this.composerService.addPresentationFrame(newFrame);
  }
}
