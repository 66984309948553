import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-entity-tooltip',
  template: `
    <div class="p-3 bg-white rounded-lg text-black60">
      <p class="text-lg font-bold mb-1">{{ name }}</p>
      <p class="text-sm font-medium">{{ colorName }}</p>
    </div>
  `,
  styles: [],
})
export class EntityTooltipComponent {
  @Input() colorName: string;
  @Input() name: string;
}

export class TooltipLayerUtil {
  ref: ComponentRef<EntityTooltipComponent>;
  tooltipOverlay: OverlayRef;

  posX: string;
  posY: string;

  constructor(private overlay: Overlay) {
    const handleMousemove = (event) => {
      // console.log(`mouse position: ${event.x}:${event.y}`);
      this.posX = event.x.toString() + 'px';
      this.posY = event.y.toString() + 'px';
    };

    document.addEventListener('mousemove', handleMousemove);
  }

  showTooltip(name: string, colorName: any) {
    const positionStrategy = this.overlay.position().global().top(this.posY).left(this.posX);
    this.tooltipOverlay = this.overlay.create({
      hasBackdrop: false,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      panelClass: 'tooltip-overlay-panel',
      positionStrategy: positionStrategy,
    });
    const componentPortal = new ComponentPortal(EntityTooltipComponent);
    this.ref = this.tooltipOverlay.attach(componentPortal);
    this.ref.instance.colorName = colorName;
    this.ref.instance.name = name;
  }

  closeTooltip() {
    this.tooltipOverlay?.dispose();
    this.overlay?.position().global().dispose();
  }
}
