import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalCsvExportComponent } from './local-csv-export/local-csv-export.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { DownloadContentIndicatorComponent } from './download/download-content-indicator/download-content-indicator.component';
import { AnalyticsModule } from '../analytics/analytics.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatBadgeModule } from '@angular/material/badge';
import { ExcelExportComponent } from './excel-export/excel-export.component';
import { ExportComponent } from './export/export.component';
import { ExportWidgetComponent } from './export-widget/export-widget.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { FormsModule } from '@angular/forms';
import { DownloadContentSnackComponent } from './download/download-content-snack/download-content-snack.component';
import { CsvExportComponent } from './csv-export/csv-export.component';

@NgModule({
  declarations: [
    LocalCsvExportComponent,
    CsvExportComponent,
    ExcelExportComponent,
    DownloadContentIndicatorComponent,
    ExportComponent,
    ExportWidgetComponent,
    DownloadContentSnackComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    AnalyticsModule,
    MatToolbarModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    FormsModule,
    OverlayModule,
  ],
  exports: [
    LocalCsvExportComponent,
    CsvExportComponent,
    ExcelExportComponent,
    DownloadContentIndicatorComponent,
    ExportComponent,
    ExportWidgetComponent,
    DownloadContentSnackComponent,
  ],
})
export class ExportsModule {}
