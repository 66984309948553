import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AssortmentsActions } from '../common/assortments/assortments-store';
import { LocalizationService } from '../common/localization/localization.service';
import { RoutingService } from '../common/routing/routing.service';
import { RootStoreState } from '../root-store';
import { WorkspacesActions } from '@common/workspaces/workspaces-store';
import { RecentShowcaseStore } from './recent-showcases-storage';
import { ShowcasesActions, ShowcasesSelectors } from './showcases-store';
import { ItemService } from '@common/items/item.service';

export interface Showcase {
  name?: string;
  isTemplate?: boolean;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class ShowcasesService {
  private currentShowcaseId;
  private currentShowcase;

  private recentShowcasesObject: Array<Showcase> = [];
  private recentShowcasesSubject: Subject<Array<Showcase>> = new BehaviorSubject(this.recentShowcasesObject);
  public recentShowcases: Observable<Array<Showcase>> = this.recentShowcasesSubject.asObservable();

  private sourceAssortmentId = null;

  constructor(
    private localizationService: LocalizationService,
    private store: Store<RootStoreState.State>,
    private routingService: RoutingService,
    private itemService: ItemService,
  ) {
    this.store
      .select(ShowcasesSelectors.currentShowcase)
      .pipe(
        tap((showcase) => {
          if (!showcase || this.currentShowcaseId === showcase.id) {
            return;
          }
          this.currentShowcaseId = showcase.id;
          this.currentShowcase = showcase;
          if (showcase?.sourceAssortmentId && this.sourceAssortmentId !== showcase?.sourceAssortmentId) {
            this.sourceAssortmentId = showcase.sourceAssortmentId;
            this.itemService.sourceAssortment = showcase.sourceAssortment;
            this.store.dispatch(
              AssortmentsActions.loadSourceAssortment({ sourceAssortmentId: showcase.sourceAssortmentId }),
            );
          } else if (!showcase?.sourceAssortmentId) {
            this.itemService.sourceAssortment = null;
          }
          if (showcase?.rootWorkspaceId) {
            this.store.dispatch(WorkspacesActions.loadCurrentWorkspace({ id: showcase?.rootWorkspaceId }));
          }
          this.localizationService.setCurrencyCode(showcase.preferredCurrency);
        }),
      )
      .subscribe();
  }

  public async getShowcases(workspaceId: string) {
    return new Entities().get({
      entityName: 'showcase',
      criteria: { rootWorkspaceId: workspaceId, isTrashed: false },
      relations: ['updatedBy', 'createdBy', 'previewFile', 'sourceAssortment'],
    });
  }
  public async getShowcaseById(id: string) {
    return new Entities().get({
      entityName: 'showcase',
      id,
      relations: ['presentation', 'sourceAssortment', 'workspace', 'createdBy', 'updatedBy'],
    });
  }
  public async createShowcase(showcase: Showcase) {
    return new Entities().create({ entityName: 'showcase', object: showcase });
  }
  public async deleteShowcase(showcase: Showcase) {
    await new Entities().delete({ entityName: 'showcase', id: showcase.id });
    return showcase;
  }
  public async updateShowcase(id: string, changes: Showcase) {
    delete changes.previewFileId;
    return new Entities().update({ entityName: 'showcase', id, object: changes });
  }

  public clearSelectedShowcase() {
    this.store.dispatch(ShowcasesActions.clearCurrentShowcase());
  }
  // TODO: remove ok?
  public async loadShowcases(archived: boolean = false, ownedByUser: boolean = false, template: boolean = false) {}

  public async copyShowcase(newname, showcaseid, newWorkspaceId = null) {
    return await new Entities().create({
      entityName: 'showcase',
      object: { name: newname, newWorkspaceId: newWorkspaceId },
      id: showcaseid,
      relation: 'copy',
    });
  }

  public async asyncCopyShowcase(name, id, newWorkspaceId = null) {
    return await new Entities().create({
      entityName: 'showcase',
      object: { name, showcaseId: id, newWorkspaceId },
      relation: 'copy',
      suffix: 'async',
    });
  }

  public openShowcaseViewer(showcase, newTab = true) {
    this.routingService.openApp('showcase', `sc/${showcase.id}`, environment.showcaseAppHost, newTab);
  }

  // public loadRecentShowcases() {
  //   this.recentShowcasesObject = RecentShowcaseStore.getRecentShowcases(this.showcasesObject);
  //   console.log('loadRecentShowcases: ', this.recentShowcasesObject);
  //   this.recentShowcasesSubject.next(this.recentShowcasesObject);
  // }

  public storeShowcaseId(showcaseId) {
    RecentShowcaseStore.storeShowcaseId(showcaseId);
  }

  public assignSourceAssortment(sourceAssortmentId: string) {
    this.store.dispatch(ShowcasesActions.assignShowcaseAssortment({ id: this.currentShowcaseId, sourceAssortmentId }));
  }

  public assignSourceAssortmentAndRefresh(showcase: any) {
    this.store.dispatch(ShowcasesActions.assignShowcaseAssortmentSuccess({ showcase }));
  }

  public clearSourceAssortment() {
    this.store.dispatch(ShowcasesActions.clearShowcaseSourceAssortment({ id: this.currentShowcaseId }));
  }

  public clearSourceAssortmentAndRefresh(showcase: any) {
    this.store.dispatch(ShowcasesActions.clearShowcaseSourceAssortmentSuccess({ showcase }));
  }

  public getCurrentShowcase() {
    return this.currentShowcase;
  }
}
