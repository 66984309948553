import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TableRange } from '@contrail/document-table';
import { DocumentService } from '../../document.service';

@Component({
  selector: 'app-property-configurator-table-merge-cells',
  template: `<button
      *ngIf="isVisible"
      [attr.data-test]="isMerge ? 'table-merge-cells' : 'table-unmerge-cells'"
      (click)="isMerge ? mergeCells() : unmergeCells()"
      [disabled]="isDisabled"
      mat-icon-button
      [matTooltip]="isMerge ? 'Merge cells' : 'Unmerge cells'"
      matTooltipPosition="below"
    >
      <mat-icon class="icons pb-px" [svgIcon]="isMerge ? 'cell-merge' : 'cell-unmerge'"></mat-icon>
    </button>
    <mat-divider *ngIf="isVisible" vertical="true" class="divider !ml-px"></mat-divider>`,
  styles: [
    `
      :host {
        ::ng-deep button:not(.mat-button-disabled) .mat-icon svg path {
          @apply fill-black60;
        }

        ::ng-deep button.mat-button-disabled .mat-icon svg path {
          @apply fill-black26;
        }
      }
    `,
  ],
})
export class PropertyConfiguratorTableMergeCellsComponent implements OnChanges {
  @Input() selectedElement: any;
  @Input() selectedTableRanges: TableRange[] = [];
  public isVisible = false;
  public isDisabled = false;
  public isMerge = false;
  constructor(private documentService: DocumentService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectedTableRanges) {
      this.isVisible =
        this.selectedTableRanges.length > 0 &&
        !(this.selectedTableRanges.length === 1 && this.selectedTableRanges[0].singular());
      this.isDisabled = this.isVisible && !this.documentService.documentRenderer?.getValidRangeToMerge();
      this.isMerge =
        this.isVisible &&
        !(
          this.selectedTableRanges.length === 1 &&
          this.documentService.documentRenderer
            ?.getMergedRanges()
            ?.find((range) => range.equals(this.selectedTableRanges[0]))
        );
    }
  }

  mergeCells() {
    this.documentService.documentRenderer?.mergeSelectedCells();
  }

  unmergeCells() {
    this.documentService.documentRenderer?.unmergeSelectedCells();
  }
}
