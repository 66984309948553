import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { remoteMouseTrackerReducers } from './remote-mouse-tracker-store.reducers';
import { EffectsModule } from '@ngrx/effects';
import { RemoteMouseTrackerEffects } from './remote-mouse-tracker-store.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('remoteMouseTrackers', remoteMouseTrackerReducers),
    EffectsModule.forFeature([RemoteMouseTrackerEffects]),
  ],
})
export class RemoteMouseTrackersStoreModule {}
