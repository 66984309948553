import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { PresentationCollectionElement } from '../../../../presentation';
import { ItemData } from 'src/app/common/item-data/item-data';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { AddPinnedCommentsService } from '../../../composer-pinned-comments/add-pinned-comments-service';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { ShowcaseBackingAssortmentService } from '../../../backing-assortment/showcase-backing-assortment-service';
import { ComposerFrameLayoutService } from '../../composer-frame-layout/composer-frame-layout.service';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { ShowcasesActions, ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { CommentsActions } from '@common/comments/comments-store';
import { ObjectUtil } from '@contrail/util';
import { Observable, Subject, firstValueFrom, takeUntil } from 'rxjs';

@Component({
  selector: 'app-presentation-collection-item',
  templateUrl: './presentation-collection-item.component.html',
  styleUrls: ['./presentation-collection-item.component.scss'],
})
export class PresentationCollectionItemComponent implements OnInit, OnChanges, OnDestroy {
  private destroy$ = new Subject();
  private showSourceAssortmentWarning$: Observable<boolean>;
  optionPreviewClass = 'option';
  @Input() item: any;
  @Input() editorMode: EditorMode = EditorMode.EDIT;
  @Input() assortmentItemData: Array<ItemData>;
  @Input() itemIndex: number;
  @Input() annotationOptions: any[];
  @Input() annotationType;
  @Input() statusMessages: any[];
  @Input() frameId: string;
  @Input() searchResults: any[];
  @Input() activeSearchResultElement: any;
  @Output() updateCollectionItem = new EventEmitter();
  @Output() viewItemDetail = new EventEmitter();
  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };
  optionCount = 0;
  annotationOptionMap: any;
  cursorClass;
  statusMessageMap = {};
  itemFamilyName = '';
  backingAssortmentItems;
  backingAssortmentItemMap: any = {};

  constructor(
    private composerFrameLayoutService: ComposerFrameLayoutService,
    private store: Store<RootStoreState.State>,
    private addPinnedCommentsService: AddPinnedCommentsService,
    private backingAssortmentService: ShowcaseBackingAssortmentService,
  ) {
    this.showSourceAssortmentWarning$ = this.store.select(ShowcasesSelectors.showSourceAssortmentWarning);
  }

  ngOnInit(): void {
    // this.initCollection();
    this.optionCount = this.assortmentItemData.filter(
      (itemData) => itemData.item.itemFamilyId === this.item.value,
    ).length;
    this.showSourceAssortmentWarning$.pipe(takeUntil(this.destroy$)).subscribe((showSourceAssortmentWarning) => {
      this.setStatusMessageMap(showSourceAssortmentWarning);
    });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!this.annotationOptionMap && changes.annotationOptions?.currentValue) {
      this.annotationOptionMap = changes.annotationOptions.currentValue.reduce((map, option) => {
        map[option.type] = option;
        return map;
      }, {});
    }

    if (changes.annotationType) {
      if (changes.annotationType.currentValue) {
        this.cursorClass = 'option ' + changes.annotationType.currentValue + '-cursor';
      } else {
        this.cursorClass = null;
      }
    }

    if (changes.statusMessages?.currentValue) {
      const showSourceAssortmentWarning = await firstValueFrom(this.showSourceAssortmentWarning$);
      this.setStatusMessageMap(showSourceAssortmentWarning);
    }

    const backingAssortmentItems = this.assortmentItemData.filter(
      (itemData) => itemData.item.itemFamilyId === this.item.value,
    );
    this.itemFamilyName = backingAssortmentItems[0]?.properties?.name;
    backingAssortmentItems.forEach((backingAssortmentItem) => {
      this.backingAssortmentItemMap[backingAssortmentItem.id] = backingAssortmentItem;
    });
  }

  private setStatusMessageMap(show: boolean) {
    if (show && this.statusMessages?.length) {
      this.statusMessageMap = this.statusMessages.reduce((map, statusMessage) => {
        map[statusMessage.collectionElementId] = statusMessage;
        return map;
      }, {});
    } else {
      this.statusMessageMap = {};
    }
  }

  getThumbnail(element: PresentationCollectionElement) {
    const id = element.value;
    const item = this.assortmentItemData.find((itemData) => itemData.id === id);
    return item?.thumbnail;
  }

  getAnnotations(element: PresentationCollectionElement) {
    const annotations = [];
    const id = element.value;
    const item = this.assortmentItemData.find((itemData) => itemData.id === id);
    this.annotationOptions?.forEach((annotationOption) => {
      if (item?.assortmentItem[annotationOption.property]) {
        annotations.push(annotationOption.svgIcon);
      }
    });
    return annotations;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.item.children, event.previousIndex, event.currentIndex);
    this.updateCollectionItem.emit({ item: this.item });
  }

  removeOption(option) {
    const index = this.item.children.findIndex((child) => child.value === option.value);
    this.item.children.splice(index, 1);
    this.updateCollectionItem.emit({ item: this.item });
  }

  removeItem() {
    this.updateCollectionItem.emit({ item: this.item, delete: true });
  }

  launchItemPreview(event) {
    event.stopPropagation();
    this.composerFrameLayoutService.showPreview(this.item);
  }

  onContextMenu(event: MouseEvent, item) {
    let positionX = event.clientX;
    let positionY = event.clientY;
    // To display collection items scrollable content which uses transform: translateY(0px) - which
    // resets coordinate system so we need to adjust the context menu position.
    const scrollableContent = document.getElementsByClassName('scrollable-content');
    if (scrollableContent?.length > 0) {
      positionX = positionX - scrollableContent[0].getBoundingClientRect().left;
      positionY = positionY - scrollableContent[0].getBoundingClientRect().top;
    }
    event.preventDefault();
    this.contextMenuPosition.x = positionX + 'px';
    this.contextMenuPosition.y = positionY + 'px';
    this.contextMenu.menuData = { item };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  addItemComment(item) {
    this.contextMenu.closeMenu();
    this.addPinnedCommentsService.addItemComment(item.value, this.frameId);
  }

  handleItemClick(item) {
    if (this.annotationType) {
      const property = this.annotationOptionMap[this.annotationType].property;
      this.backingAssortmentService.updateAssortmentItemsByProperty([item.value], property);
    }
  }

  inSearchResults(item) {
    return (
      this.searchResults.findIndex(
        (searchResult) => searchResult.id === item && searchResult.frameId === this.frameId,
      ) > -1
    );
  }

  inSearchResultsActive(item) {
    return this.activeSearchResultElement?.id === item && this.activeSearchResultElement?.frameId === this.frameId;
  }

  onClick(event) {
    event.stopPropagation();
    const commentAvatar = event.path?.find((el) => el.nodeName === 'APP-COMPOSER-COLLECTION-ITEM-PINNED-COMMENTS');
    this.store.dispatch(
      ShowcasesActions.setCollectionFrameActiveContainer({
        collectionFrameActiveContainer: ObjectUtil.cloneDeep(this.item),
      }),
    );
    if (!commentAvatar) {
      this.store.dispatch(CommentsActions.hideCommentOverlay());
    }
  }

  viewItem(item) {
    this.viewItemDetail.emit({ container: ObjectUtil.cloneDeep(this.item), itemId: item.value });
  }
}
