<div class="relative w-full h-full">
  <div class="absolute justify-end flex w-full -top-4 -right-4">
    <button mat-icon-button [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <app-presentation-item-details
    class="w-full h-full px-10"
    data-test="presentation-collection-preview-modal"
    [includeCommentWidget]="includeCommentWidget"
    [itemData]="itemData"
    [itemsMissingFromSource]="itemsMissingFromSource"
  >
  </app-presentation-item-details>

  <div *ngIf="items.length > 1" class="arrow left" (click)="navigate(-1)">
    <mat-icon class="navigator">chevron_left</mat-icon>
  </div>
  <div *ngIf="items.length > 1" class="arrow right" (click)="navigate(1)">
    <mat-icon class="navigator">chevron_right</mat-icon>
  </div>
</div>
