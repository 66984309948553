import { Action } from '@contrail/actions';
import { PresentationChange } from './presentation-change';

export class PresentationAction extends Action {
  public changeDefinition: PresentationChange;
  public undoChangeDefinition: PresentationChange;
  constructor(actionType, change, undoChange = null) {
    super(actionType, change, undoChange);
  }
}
