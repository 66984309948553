import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CommentsSelectors } from 'src/app/common/comments/comments-store';
import { Comment } from 'src/app/common/comments/comments.service';
import { DocumentService } from '../../document/document.service';
import { DocumentElement, PositionDefinition } from '@contrail/documents';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ComposerService } from '../composer.service';
import { Showcase } from 'src/app/showcases/showcases-store/showcases.state';
import { PresentationFrame, Presentation } from '../../presentation';
import { AddPinnedCommentsService } from './add-pinned-comments-service';
import { ShowcasesSelectors } from 'src/app/showcases/showcases-store';

@Injectable({
  providedIn: 'root',
})
export class ShowPinnedCommentsListenerService {
  private showcase: Showcase;
  private presentation: Presentation;
  private frame: PresentationFrame;
  private elements: Array<DocumentElement>;

  constructor(
    private store: Store<RootStoreState.State>,
    private composerService: ComposerService,
    private documentService: DocumentService,
    private addPinnedCommentsService: AddPinnedCommentsService,
    private snackBar: MatSnackBar,
  ) {
    this.init();
  }

  private init() {
    this.store.select(ShowcasesSelectors.currentShowcase).subscribe((showcase) => (this.showcase = showcase));
    this.composerService.presentation.subscribe((presentation) => (this.presentation = presentation));
    this.composerService.currentFrame.subscribe((frame) => (this.frame = frame));
    this.documentService.documentElements.subscribe((elements) => (this.elements = elements)); // Need to subscribe to document elements because document.elements is not updated on changes
    this.store
      .select(CommentsSelectors.selectedComment)
      .subscribe((selectedComment) => this.showSelectedComment(selectedComment));
  }

  /**
   * Navigate to the @selectedComment
   * Possible options:
   * - comment on the document element within a frame
   * - comment at a document position within a frame
   * - comment on a deleted element within a frame
   * - showcase specific comment
   * - frame preview specific comment
   * - item family within a frame
   * - item option within a frame
   * First, navigate to the frame then show comment.
   * @param selectedComment
   */
  private showSelectedComment(selectedComment: Comment) {
    if (selectedComment) {
      const frameId = selectedComment?.subContextReference?.split(':')[1];
      // Comment is not from a current frame
      if (frameId && frameId != this.frame?.id) {
        const selectedFrame = this.presentation.frames.find((frame) => frame.id === frameId);
        if (!selectedFrame) {
          this.snackBar.open('Frame no longer exists.', '', { duration: 4000 });
        } else {
          this.composerService.setCurrentFrame(selectedFrame);
          this.composerService.setSelectedFrame(selectedFrame);
          setTimeout(() => this.showSelectedCommentOnCurrentFrame(selectedComment, frameId), 200);
        }
      } else if (frameId && frameId === this.frame.id) {
        this.showSelectedCommentOnCurrentFrame(selectedComment, frameId);
      } else {
        this.snackBar.open('Showcase specific comment.', '', { duration: 5000 });
      }
    }
  }

  private showSelectedCommentOnCurrentFrame(selectedComment: Comment, frameId: string) {
    const ownedByReference = selectedComment?.ownedByReference?.split(':');
    if (selectedComment.documentElementId) {
      const element = this.getDocumentElement(selectedComment.documentElementId);
      if (!element) {
        this.snackBar.open('Element no longer exists.', '', { duration: 4000 });
      } else {
        this.navigateToElementComment(selectedComment, { id: element.id, position: element.position });
      }
    } else if (selectedComment.documentPosition) {
      this.navigateToElementComment(selectedComment);
    } else if (ownedByReference?.length === 2 && ownedByReference[0] === 'item') {
      this.navigateToItemComment(ownedByReference[1]);
    } else if (ownedByReference?.length === 2 && ownedByReference[0] === 'showcase') {
      this.navigateToFramePreviewComment(frameId);
    }
  }

  /**
   * Show comment overlay that belongs to a document element or document position
   * @param comment
   * @param documentElement
   */
  private navigateToElementComment(
    comment: Comment,
    documentElement: { id: string; position: PositionDefinition } = null,
  ) {
    const commentOverlayDocumentPosition = {
      x: comment.documentPosition.x + (documentElement ? documentElement.position.x : 0),
      y: comment.documentPosition.y + (documentElement ? documentElement.position.y : 0),
    };

    this.addPinnedCommentsService.addCommentToDocumentElement(
      {
        entityType: 'showcase',
        id: this.showcase.id,
        documentPosition: comment.documentPosition,
        documentElementId: comment.documentElementId,
        subContextReference: comment.subContextReference,
      },
      commentOverlayDocumentPosition,
    );
  }

  private getDocumentElement(documentElementId?: string): DocumentElement {
    return documentElementId ? this.elements.find((element) => element.id === documentElementId) : null;
  }

  /**
   * Show comment overlay that belongs to an item option
   * @param itemOptionValue
   */
  private navigateToItemComment(itemValue) {
    if (!this.getItemFromCollection(itemValue)) {
      this.snackBar.open('Item no longer exists.', '', { duration: 4000 });
      return;
    }
    this.addPinnedCommentsService.addItemComment(itemValue, this.frame.id);
  }

  private getItemFromCollection(itemValue) {
    return (
      this.frame?.collection?.set.find((itemFamily) => itemFamily.value === itemValue) ||
      []
        .concat(...this.frame?.collection?.set.map((itemFamily) => itemFamily.children))
        .find((itemOption) => itemOption.value === itemValue)
    );
  }

  /**
   * Show comment overlay that belongs to a frame preview
   * @param comment
   */
  navigateToFramePreviewComment(frameId: string) {
    this.addPinnedCommentsService.addFramePreviewComment(frameId);
  }
}
