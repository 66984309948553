import { createSelector } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { Showcase, showcasesEntityAdapter } from './showcases.state';

export const { selectAll, selectEntities, selectIds, selectTotal } = showcasesEntityAdapter.getSelectors();

const showcaseEntities = (state: any) => state.showcases.showcases.entities;
export const showcases = createSelector(showcaseEntities, (state) => Object.values(state) as Array<Showcase>);
export const currentShowcase = (state: any) => state.showcases.currentShowcase;
export const itemsUsedInShowcase = (state: any) => state.showcases.itemsUsedInShowcase;
export const gridFrameActiveContainer = (state: any) => state.showcases.gridFrameActiveContainer;
export const collectionFrameActiveContainer = (state: any) => state.showcases.collectionFrameActiveContainer;
export const annotationType = (state: any) => state.showcases.annotationType;
export const statusMessageElement = (state: any) => state.showcases.statusMessageElement;
export const showSourceAssortmentWarning = (state: any) => state.showcases.showSourceAssortmentWarning;
