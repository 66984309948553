<div class="header flex-between-center">
  <div class="flex justify-start">
    <div class="title mr-1">Dashboard</div>
  </div>
  <div class="flex space-x-2">
    <div class="cursor-pointer flex-center" (click)="handleClose()">
      <mat-icon>close</mat-icon>
    </div>
  </div>
</div>
<div class="mx-4">
  <mat-form-field>
    <mat-label>Scope</mat-label>
    <mat-select data-test="choose-dashboard-scope" (selectionChange)="handleScopeChange($event)" [value]="scope">
      <mat-option value="Frame">Frame</mat-option>
      <mat-option value="Showcase">Showcase</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<app-dashboard-panel
  [style.height]="'calc(100% - 53px)'"
  [assortmentItemData]="filteredAssortmentItemData"
  [appContext]="appContext"
  [annotationOptions]="annotationOptions"
  (panelClosed)="handleClose()"
>
</app-dashboard-panel>
