import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnInit, OnChanges {
  public selectedViewable;
  @Input() viewables: Array<any>;

  public is3d = false;
  private enableCustomAssetViewer: boolean = false; // org managed - can update in ADMIN app.
  public useVibeViewer = true; // if enableCustomAssetViewer is false, useVibeViewer must be TRUE

  constructor(private authService: AuthService) {
    this.enableCustomAssetViewer = this.authService.getCurrentOrg().orgConfig?.enableCustomAssetViewer;
  }

  ngOnInit(): void {}
  ngOnChanges() {
    this.selectedViewable = null;

    this.is3d = false;
    this.useVibeViewer = true;

    if (this.viewables && this.viewables.length) {
      this.selectedViewable = this.viewables[0];
    } else if (!this.viewables) {
      this.selectedViewable = null;
    }

    if (this.selectedViewable?.type === 'glb') {
      this.is3d = true;
      this.useVibeViewer =
        this.enableCustomAssetViewer &&
        this.selectedViewable?.content?.embedLink &&
        !this.selectedViewable?.content?.useVibeViewer
          ? false
          : true;
    }
  }

  selectContent(view) {
    this.is3d = false;
    this.useVibeViewer = true;
    this.selectedViewable = view;
    if (view.type === 'glb') {
      this.is3d = true;
      this.useVibeViewer =
        this.enableCustomAssetViewer && view?.content?.embedLink && !view?.content?.useVibeViewer ? false : true;
    }
  }
}
