<div class="item-container" (click)="onClick($event)" (contextmenu)="onContextMenu($event, item)">
  <div class="item">
    <div class="item-drag flex-center relative h-full" *ngIf="editorMode === 'EDIT'">
      <span>{{ itemIndex + 1 }}</span>
      <mat-icon cdkDragHandle class="cursor-move mr-5">drag_handle</mat-icon>
    </div>
    <div>
      <div class="title">
        <span [matTooltip]="itemFamilyName" matTooltipPosition="right">{{ itemFamilyName }}</span>
      </div>
      <!--<div class="count" *ngIf="optionCount > 0">{{item.children?.length}}/{{optionCount}} Options</div>-->
    </div>
  </div>

  <div
    class="options narrow-scroll"
    cdkDropList
    cdkDropListOrientation="horizontal"
    (cdkDropListDropped)="drop($event)"
  >
    <div
      class="option"
      [ngStyle]="!cursorClass ? { cursor: 'move' } : ''"
      *ngFor="let element of item?.children"
      cdkDrag
      [cdkDragDisabled]="editorMode !== 'EDIT'"
      [cdkDragPreviewClass]="optionPreviewClass"
      (click)="handleItemClick(element)"
      [attr.id]="element.value"
      (contextmenu)="onContextMenu($event, element)"
      [ngClass]="{
        inSearchResults: inSearchResults(element.value),
        inSearchResultsActive: inSearchResultsActive(element.value)
      }"
    >
      <div class="option-action">
        <div class="annotations flex-center">
          <mat-icon *ngFor="let annotation of getAnnotations(element)" [svgIcon]="annotation" class="ml-1"></mat-icon>
        </div>
        <div class="remove-option cursor-pointer" *ngIf="editorMode === 'EDIT'">
          <mat-icon
            [attr.data-test]="'app-presentation-collection-option-remove-' + element.value"
            (click)="removeOption(element)"
            >close</mat-icon
          >
        </div>
      </div>
      <div class="thumbnail" [ngClass]="{ 'opacity-60': statusMessageMap[element.value] }">
        <ng-container *ngIf="getThumbnail(element); else placeholder">
          <img [attr.src]="getThumbnail(element) | secureImage | async" class="object-contain w-full h-full" />
        </ng-container>
        <ng-template #placeholder>
          <div class="rounded-lg w-full h-full flex-center">
            <mat-icon class="!w-full !h-full" svgIcon="placeholder-item-option-image"></mat-icon>
          </div>
        </ng-template>
        <div
          class="absolute"
          *ngIf="statusMessageMap[element.value]"
          [matTooltip]="statusMessageMap[element.value].message"
        >
          <mat-icon svgIcon="warning-alert"></mat-icon>
        </div>
      </div>
      <div class="option-label" [matTooltip]="backingAssortmentItemMap[element.value]?.properties.optionName">
        {{ backingAssortmentItemMap[element.value]?.properties.optionName }}
      </div>
      <div class="absolute top-4 right-7">
        <app-composer-collection-item-pinned-comments
          [item]="element"
          *ngIf="editorMode !== 'VIEW'"
        ></app-composer-collection-item-pinned-comments>
      </div>
    </div>
  </div>

  <div class="actions">
    <button matTooltip="Preview" mat-button class="btn-32" (click)="launchItemPreview($event)">
      <mat-icon>visibility</mat-icon>
    </button>
    <button
      *ngIf="editorMode === 'EDIT'"
      matTooltip="Remove"
      [attr.data-test]="'app-presentation-collection-item-remove-' + item.value"
      mat-button
      class="btn-32"
      (click)="removeItem()"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>

  <div class="absolute top-1 right-5" *ngIf="editorMode !== 'VIEW'">
    <app-composer-collection-item-pinned-comments [item]="item"></app-composer-collection-item-pinned-comments>
  </div>
</div>

<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>

<mat-menu #contextMenu="matMenu" class="collection-item-context-menu">
  <ng-template matMenuContent let-itemOption="itemOption" let-item="item">
    <button mat-menu-item (click)="viewItem(item)" data-test="collection-item-view">
      <span>View Item</span>
    </button>
    <button mat-menu-item (click)="addItemComment(item)" data-test="collection-item-add-comment">
      <span>Comment</span>
    </button>
  </ng-template>
</mat-menu>
