import { DocumentAction, DocumentChangeType, StyleDefinition } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { Injectable } from '@angular/core';
import { DocumentService } from '../document.service';
import { ComponentEditorService } from '../component-editor/component-editor.service';
import { DocumentItemService } from '../document-item/document-item.service';
import { DocumentColorService } from '../document-color/document-color.service';
import { STICKY_NOTE_BACKGROUND_COLORS } from '../../canvas-lib';

export enum PaintFormatterAction {
  PAINT_FORMAT = 'paint_format',
  PAINT_FORMAT_HOLD = 'paint_format_hold',
}

@Injectable({
  providedIn: 'root',
})
export class PaintFormatterActionService {
  constructor(
    private documentService: DocumentService,
    private componentEditorService: ComponentEditorService,
  ) {
    this.init();
  }

  init() {
    this.documentService.actionRequests.subscribe((request) => {
      if (
        request?.actionType === PaintFormatterAction.PAINT_FORMAT ||
        request?.actionType === PaintFormatterAction.PAINT_FORMAT_HOLD
      ) {
        this.documentService.documentClipboard.copySelectedElementFormatToClipboard();
      } else {
        if (request?.actionType === 'root') {
          this.restoreDefaultCursor();
        }
      }
    });

    this.documentService.documentElementEvents.subscribe((event) => {
      const interactionMode = this.documentService.getInteractionMode();
      if (
        interactionMode === PaintFormatterAction.PAINT_FORMAT ||
        interactionMode === PaintFormatterAction.PAINT_FORMAT_HOLD
      ) {
        if (event.eventType === 'right-click') {
          // This is to address vibe-2608-Mouse right-click allows paint-formatting and annotation.
          this.restoreDefaultCursor();
          return;
        }
        if (event?.eventType === 'selected') {
          const selectedElements = this.documentService.getSelectedElements()?.filter((e) => !e.isLocked);
          if (selectedElements.length) {
            this.applyFormat(selectedElements);
            if (interactionMode === PaintFormatterAction.PAINT_FORMAT) {
              this.restoreDefaultCursor();
            }
          } else {
            this.restoreDefaultCursor();
          }
        }
      }
    });
  }

  restoreDefaultCursor() {
    // setTimeout(() => {
    this.documentService.setInteractionMode('select');
    // }, 50);
  }

  applyFormat(selectedElements) {
    const element = this.documentService.documentClipboard.getClipboardElementFormatContent();

    if (DocumentItemService.isItemComponet(element) || DocumentColorService.isColorComponent(element)) {
      this.componentEditorService.updateComponentElements(element, element.elements, false);
      return;
    }

    const changes = [];
    const undoChanges = [];
    selectedElements.forEach((selectedElement) => {
      if (!DocumentItemService.isItemComponet(selectedElement)) {
        const style: StyleDefinition = ObjectUtil.cloneDeep(element.style);
        const existingElement = ObjectUtil.cloneDeep(selectedElement);
        undoChanges.push(existingElement);
        if (element.type === 'sticky_note' && style?.font?.size) {
          delete style.font.size;
        }
        if (
          selectedElement.type === 'sticky_note' &&
          style?.backgroundColor &&
          STICKY_NOTE_BACKGROUND_COLORS.map((c) => c.value).indexOf(style.backgroundColor) === -1
        ) {
          delete style.backgroundColor;
        }
        selectedElement.style = ObjectUtil.mergeDeep(selectedElement.style, style); // only copy the style
        changes.push(selectedElement);
      }
    });

    const actions: Array<DocumentAction> = [];
    changes.forEach((el, i) => {
      actions.push(
        new DocumentAction(
          {
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementData: el,
            elementId: el.id,
          },
          undoChanges
            ? {
                elementId: el.id,
                changeType: DocumentChangeType.MODIFY_ELEMENT,
                elementData: undoChanges[i],
              }
            : null,
        ),
      );
    });
    this.documentService.handleDocumentActions(actions);
  }
}
