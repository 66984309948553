import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Entities } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { ComposerShowroomFrameService } from '../composer-showroom-frame-service';

@Component({
  selector: 'app-new-showroom-frame-modal',
  templateUrl: './new-showroom-frame-modal.component.html',
  styleUrls: ['./new-showroom-frame-modal.component.scss'],
})
export class NewShowroomFrameModalComponent implements OnInit {
  public showrooms = [];
  public tours = [];
  constructor(
    private store: Store<RootStoreState.State>,
    public composerShowroomService: ComposerShowroomFrameService,
    public dialogRef: MatDialogRef<NewShowroomFrameModalComponent>,
  ) {}

  async ngOnInit() {
    this.showrooms = await new Entities().get({ entityName: 'showroom', relations: ['showroomLayouts'] });
    this.showrooms = this.showrooms.sort((s1, s2) => (s1.name > s2.name ? 1 : -1));
    //this.tours = await new Entities().get({ entityName: 'showroom-tour'})
  }

  create(showroomId) {
    this.composerShowroomService.createFrame(showroomId);
    this.dialogRef.close();
  }
}
