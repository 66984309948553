<app-page-section>
  <app-page-section-header>
    <app-page-section-title title="Activity"></app-page-section-title>
  </app-page-section-header>
  <app-page-section-body>
    <div class="chartWrapper">
      <google-chart
        class="chart"
        *ngIf="!loading"
        [type]="chartType"
        [data]="chartData"
        [columns]="chartColumns"
        [options]="chartOptions"
        dynamicResize="true"
      >
        >
      </google-chart>
      <mat-spinner *ngIf="loading" diameter="40"> </mat-spinner>
    </div>
  </app-page-section-body>
</app-page-section>
