import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, debounceTime, map, Observable, Subject, Subscription } from 'rxjs';
import { AuthService } from '@common/auth/auth.service';
import { DocumentContentEditorService } from '../document-content-editor.service';
import { DocumentAction, DocumentChangeType, DocumentElement, SizeDefinition } from '@contrail/documents';
import { ContextualEntityHelper } from '../../contextual-entity-helper';
import { ColorDetail, ColorWidgetComponent } from '@common/color/color-widget/color-widget.component';
import { ContentEntity, ContentService } from '@common/content/content.service';
import { DocumentService } from '../../document.service';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { State } from 'src/app/root-store/root-state';
import { DocumentSelectors } from '../../document-store';
import { ObjectUtil } from '@contrail/util';
import pLimit from 'p-limit';
import { ColorService } from '@common/color/color.service';
import { FileDownloader, SVGHelper } from '@contrail/canvas';
const limit = pLimit(10);

interface IReplaceColor {
  color: ColorDetail;
  newColor: ColorDetail;
}

@Component({
  selector: 'app-document-content-editor',
  templateUrl: './document-content-editor.component.html',
  styleUrls: ['./document-content-editor.component.scss'],
})
export class DocumentContentEditorComponent implements OnInit, OnDestroy {
  private subject: Subject<string> = new Subject();
  private subscriptions = new Subscription();
  private element: DocumentElement;
  private svgHtmlString: string;
  private newSvgHtmlString: string;

  public item?: any;
  public content: ContentEntity;
  public fills: Array<IReplaceColor>;
  public currentColor: IReplaceColor;
  public dimensions: string;
  public createdByName: string;
  public loadingSubject: Subject<boolean> = new BehaviorSubject(true);
  public loading$: Observable<boolean> = this.loadingSubject.asObservable();
  public isSaveDisabled = true;

  @ViewChild('colorWidget') colorPicker: ColorWidgetComponent;
  @ViewChild('colorTarget') colorTarget: ElementRef;
  constructor(
    private store: Store<State>,
    private authService: AuthService,
    private documentService: DocumentService,
    private contentService: ContentService,
    private contentEditorService: DocumentContentEditorService,
    private contextualEntityHelper: ContextualEntityHelper,
  ) {}

  ngOnInit(): void {
    const selectedElements = this.documentService
      .getSelectedElements()
      .filter((element) => this.contentEditorService.isContentEditable(element));
    if (selectedElements?.length === 1 && this.contentEditorService.isContentEditable(selectedElements[0])) {
      this.contentEditorService.loadElement(selectedElements[0], null);
    }

    this.subscriptions.add(
      this.documentService.documentElementEvents.subscribe((event) => {
        if (!event) {
          return;
        }

        if (event.eventType === 'selected' && event.element) {
          const selectedElements = this.documentService.getSelectedElements();
          if (selectedElements?.length !== 1) {
            this.contentEditorService.hideEditorOrStay();
          } else {
            if (!event.element.isLocked && this.contentEditorService.isContentEditable(event.element)) {
              // If selecting different SVG element - check for unsaved changes, and keep current element selected if user wants to save
              if (
                this.element &&
                event.element.id != this.element.id &&
                this.contentEditorService.isDirty &&
                !this.contentEditorService.exitUnsaved()
              ) {
                this.documentService.selectElement(this.element);
              } else if (!this.element || event.element.id != this.element.id) {
                this.contentEditorService.loadElement(event.element, null);
              }
              this.documentService.setInteractionMode('select');
            } else {
              this.contentEditorService.hideEditorOrStay();
            }
          }
        }
      }),
    );

    this.subscriptions.add(
      this.documentService.documentActions
        .pipe(
          map((actions) =>
            actions?.filter(
              (action) =>
                action.changeDefinition.changeType === DocumentChangeType.DELETE_ELEMENT ||
                action.changeDefinition.changeType === DocumentChangeType.MODIFY_ELEMENT,
            ),
          ),
        )
        .subscribe((actions: DocumentAction[]) => {
          if (this.element) {
            if (
              actions
                ?.filter((action) => action.changeDefinition.changeType === DocumentChangeType.DELETE_ELEMENT)
                ?.findIndex((action) => action?.changeDefinition?.elementId === this.element.id) !== -1
            ) {
              this.contentEditorService.hide();
            }

            const modifiedElement = actions
              ?.filter((action) => action.changeDefinition.changeType === DocumentChangeType.MODIFY_ELEMENT)
              ?.find((action) => action?.changeDefinition?.elementId === this.element.id)
              ?.changeDefinition?.elementData;
            if (modifiedElement) {
              const imageElement =
                this.element?.type === 'component'
                  ? this.element?.elements?.find((e) => e.type === 'image')
                  : this.element;
              const modifiedImageElement =
                this.element?.type === 'component'
                  ? modifiedElement?.elements?.find((e) => e.type === 'image')
                  : modifiedElement;
              if (
                (modifiedImageElement.url && imageElement.url !== modifiedElement?.url) ||
                (modifiedElement?.alternateUrls?.originalFile &&
                  imageElement?.alternateUrls?.originalFile &&
                  modifiedElement.alternateUrls.originalFile !== imageElement.alternateUrls.originalFile)
              ) {
                this.contentEditorService.loadElement(
                  ObjectUtil.mergeDeep(ObjectUtil.cloneDeep(this.element), modifiedElement),
                  null,
                );
              } else if (!this.element.isLocked && modifiedElement.isLocked) {
                this.contentEditorService.hideUnsaved();
              }
            }
          }
        }),
    );

    this.subscriptions.add(
      this.contentEditorService.element$.subscribe((elementContainer) => {
        if (elementContainer && (!this.element || this.element.id !== elementContainer?.element?.id)) {
          this.loadNew(elementContainer?.element);
        } else {
          if (elementContainer) {
            this.load(elementContainer?.element);
          }
        }
      }),
    );

    this.subscriptions.add(
      this.store.select(DocumentSelectors.toggleChooser).subscribe((toggleChooser) => {
        if (!(toggleChooser?.slug === 'contentEditor' && toggleChooser.showChooser === true)) {
          // If closing content editor sidebar check for unsaved changes. Keep sidebar open if user wants to save.
          if (this.contentEditorService.isDirty) {
            if (!this.contentEditorService.exitUnsaved()) {
              this.contentEditorService.show();
            }
          }
        }
      }),
    );

    this.subscriptions.add(this.subject.pipe(debounceTime(800)).subscribe((values) => this.handleValueChange(values)));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public async loadNew(element: DocumentElement) {
    this.loadingSubject.next(true);
    await this.load(element);
    this.loadingSubject.next(false);
  }

  public async load(element: DocumentElement) {
    this.element = element;
    const context = await this.contextualEntityHelper.getContextualEntityFromDocumentElement(element, true);
    if (context?.reference?.entityType === 'item') {
      this.item = context.entity;
      this.content = context.viewableEntity;
    } else if (context?.reference?.entityType === 'file') {
      this.content = {
        primaryFile: context.entity,
      };
    } else {
      this.content = context.entity;
      if (!this.content) {
        // Content might return 404 in case element was just recolored
        this.content = {
          id: context.reference.id,
        };
      }
    }
    console.log('DocumentContentEditorComponent loading content', this.content, this.item);
    const authContext = await this.authService.getAuthContext();
    const fileDownLoader = new FileDownloader(
      { apiToken: authContext.token, orgSlug: authContext.currentOrg.orgSlug },
      { imageHost: environment.imageHost },
    );
    this.svgHtmlString = await SVGHelper.getSVGTextFromDocumentElement(element, fileDownLoader);
    if (!this.svgHtmlString && this.content?.primaryFileUrl) {
      // in case alternateUrls.originalFile is null load primaryFileUrl
      this.svgHtmlString = await fileDownLoader.downloadFileAsText(this.content.primaryFileUrl);
    }
    this.newSvgHtmlString = this.svgHtmlString;
    const colors = SVGHelper.getColors(this.svgHtmlString);
    this.fills = await this.loadColors(colors?.fills);
    if (this.svgHtmlString) {
      const size = SVGHelper.getSize(this.svgHtmlString);
      if (size) {
        this.dimensions = `${Math.round(size.width)}x${Math.round(size.height)}`;
      }
    }
    this.createdByName = this.content?.createdBy?.email;
  }

  public editColor(color, $event) {
    this.currentColor = color;
    if (!this.colorPicker.isOpened) {
      // do not open again if it's already open
      this.colorPicker.toggleDisplay(this.colorTarget, [
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
        },
      ]);
    }
    setTimeout(() => {
      if (!this.colorPicker.tabGroup) {
        return;
      }
      if (
        (this.currentColor?.newColor?.backgroundColor && this.currentColor?.newColor?.name) ||
        this.currentColor?.color?.name
      ) {
        this.colorPicker.tabGroup.selectedIndex = 1;
      } else {
        this.colorPicker.tabGroup.selectedIndex = 0;
      }
    });
  }

  public handleDelayedValueChange(values) {
    this.subject.next(values);
  }

  public handleValueChange(values) {
    this.currentColor.newColor = values;
    let svgHtmlString = this.svgHtmlString;
    for (let c of this.fills) {
      if (c.newColor?.backgroundColor) {
        svgHtmlString = SVGHelper.replaceColor(c.color.backgroundColor, c.newColor.backgroundColor, svgHtmlString);
      }
    }
    this.newSvgHtmlString = svgHtmlString;
    this.contentEditorService.applyRasterizedSVG(this.element, this.newSvgHtmlString);
    this.setSaveDisabled();
  }

  private setSaveDisabled() {
    let isSaveDisabled = true;
    for (let c of this.fills) {
      if (c.newColor?.backgroundColor != null && c.newColor?.backgroundColor != c.color?.backgroundColor) {
        isSaveDisabled = false;
        break;
      }
    }
    this.isSaveDisabled = isSaveDisabled;
    this.contentEditorService.setIsDirty(!this.isSaveDisabled);
  }

  public hide() {
    this.contentEditorService.hideContentEditor();
  }

  public save() {
    this.fills = this.fills.map((c) => {
      if (c.newColor?.backgroundColor) {
        c.color.backgroundColor = c.newColor.backgroundColor;
        c.color.name = c.newColor.name;
      }
      c.newColor = null;
      return c;
    });
    this.isSaveDisabled = true;
    this.contentEditorService.setIsDirty(!this.isSaveDisabled);
    this.svgHtmlString = this.newSvgHtmlString;
    this.documentService.fileHandler.handleUpdateContentElement(this.element, this.newSvgHtmlString, this.content.id);
  }

  public restore() {
    this.fills = this.fills.map((c) => {
      c.newColor = null;
      return c;
    });
    this.isSaveDisabled = true;
    this.newSvgHtmlString = this.svgHtmlString;
    this.contentEditorService.applyOriginalSVG(this.element);
  }

  public download() {
    this.contentService.downloadContent(this.content);
  }

  private findColor(color: string) {
    return this.fills?.find((c) => c.color?.backgroundColor === color);
  }

  private async loadColors(colors: string[]): Promise<Array<IReplaceColor>> {
    const promises = [];
    for (let i = 0; i < colors?.length; i++) {
      const color = colors[i];
      const promise = limit(async () => {
        return {
          color: {
            backgroundColor: color,
            name: this.findColor(color)?.color?.name || (await ColorService.getColor(color))?.name,
          },
          newColor: null,
        };
      });
      promises.push(promise);
    }

    return await Promise.all(promises).catch(() => {
      return colors?.map((color) => ({
        color: {
          backgroundColor: color,
          name: null,
        },
        newColor: null,
      }));
    });
  }
}
