import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ComposerService } from '../../../composer.service';
import { PresentationCollectionElement, PresentationFrame } from '../../../../presentation';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { AssortmentsSelectors } from 'src/app/common/assortments/assortments-store';
import { ObjectUtil } from '@contrail/util';
import { Observable, Subscription } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ItemDetailsModalComponent } from '@common/items/item-details-modal/item-details-modal.component';
import { DocumentSelectors } from 'src/app/presentation/document/document-store';
import { VirtualScrollerComponent } from '@iharbeck/ngx-virtual-scroller';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
import { ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { ComposerItemService } from '../../../composer-item/composer-item.service';

interface BreadCrumbItem {
  label: string;
  optionType: ElementType;
}
enum ElementType {
  ITEMS,
  ITEMOPTIONS,
}

@Component({
  selector: 'app-presentation-collection-editor',
  templateUrl: './presentation-collection-editor.component.html',
  styleUrls: ['./presentation-collection-editor.component.scss'],
})
export class PresentationCollectionEditorComponent implements OnInit, OnDestroy, OnChanges {
  public currentProperty;
  public showcase: any;
  public collectionElements: Array<PresentationCollectionElement>;
  public selectedElementType: ElementType = ElementType.ITEMS;
  public leafNode = false;
  public breadCrumb: Array<BreadCrumbItem>;
  public selectedItemElement;
  backingAssortmentItems$: Observable<Array<any>>;
  optionPreviewClass = 'option';
  sidebarOpened = false;
  collectionFrameActiveContainer$: Observable<PresentationCollectionElement>;
  private subscriptions = new Subscription();
  public editorMode: EditorMode;
  @Input() annotationType;
  @Input() annotationOptions: any[];
  @Input() frame: PresentationFrame;
  @Input() statusMessages: any[];
  @Input() searchResults: any[];
  @Input() activeSearchResultElement: any;
  @ViewChild(VirtualScrollerComponent)
  private virtualScroller: VirtualScrollerComponent;

  constructor(
    private composerService: ComposerService,
    private composerItemService: ComposerItemService,
    private store: Store<RootStoreState.State>,
    private matDialog: MatDialog,
  ) {
    this.subscriptions.add(
      this.store.select(ShowcasesSelectors.currentShowcase).subscribe((showcase) => {
        this.showcase = showcase;
      }),
    );
    this.subscriptions.add(
      this.store.select(DocumentSelectors.toggleChooser).subscribe((toggleChooser) => {
        this.sidebarOpened = toggleChooser?.showChooser;
      }),
    );
    this.subscriptions.add(
      this.store.select(EditorModeSelectors.editorMode).subscribe((m) => {
        this.editorMode = m;
      }),
    );
    this.collectionFrameActiveContainer$ = this.store.select(ShowcasesSelectors.collectionFrameActiveContainer);
  }

  ngOnInit(): void {
    // this.initCollection();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.frame) {
      this.leafNode = false;
      this.collectionElements = null;
      (this.selectedElementType = ElementType.ITEMS), await this.initCollection();
    }

    // this will scroll to the element in the search result
    if (changes.activeSearchResultElement?.currentValue) {
      if (this.collectionElements?.length > 0) {
        const index = this.collectionElements.findIndex((collectionElement) =>
          collectionElement.children
            .map((child) => child.value)
            .includes(changes.activeSearchResultElement?.currentValue?.id),
        );
        let offset = this.collectionElements[index].children.findIndex(
          (child) => child.value === changes.activeSearchResultElement?.currentValue?.id,
        );
        offset = offset * 150;
        this.virtualScroller.scrollToIndex(index, true, offset);
      }
    }
  }

  async initCollection() {
    this.collectionElements = ObjectUtil.cloneDeep(this.frame.collection.set);
    this.backingAssortmentItems$ = this.store.select(AssortmentsSelectors.backingAssortmentItemData);
  }

  drop(event: CdkDragDrop<string[]>) {
    // virtual scroll doesn't give us the real index as it only considers the elements displayed in the scroller.
    // the real current index must be calculated based on the realPrevious index.
    const realPreviousIndex = event.item.data;
    const realCurrentIndex = event.currentIndex + (realPreviousIndex - event.previousIndex);
    const undoChangeDefinition = ObjectUtil.cloneDeep(this.frame);
    moveItemInArray(this.collectionElements, realPreviousIndex, realCurrentIndex);
    this.frame.collection.set = ObjectUtil.cloneDeep(this.collectionElements);
    this.composerService.handleFrameChanges(ObjectUtil.cloneDeep(this.frame), undoChangeDefinition);
  }

  launchItemDetails(item) {
    const itemId = item.value;
    const accessLevel = 'EDIT';
    const config = {
      data: { itemId, accessLevel },
      panelClass: [`no-padding`, `item-details-modal`],
      maxWidth: '95vw',
      width: '1350px',
      height: '800px',
      autoFocus: true,
    };
    const dialogRef = this.matDialog.open(ItemDetailsModalComponent, config);
    const itemModalComponent: ItemDetailsModalComponent = dialogRef.componentInstance;
    itemModalComponent.updated.subscribe((result) => {});
  }

  updateCollectionItem(itemData: any) {
    const undoChangeDefinition = ObjectUtil.cloneDeep(this.frame);
    const changedItemIndex = this.frame.collection.set.findIndex((item) => item.value === itemData.item.value);
    if (itemData.delete) {
      this.frame.collection.set.splice(changedItemIndex, 1);
    } else {
      this.frame.collection.set.splice(changedItemIndex, 1, ObjectUtil.cloneDeep(itemData.item));
    }
    this.collectionElements = ObjectUtil.cloneDeep(this.frame.collection.set);
    this.composerService.handleFrameChanges(ObjectUtil.cloneDeep(this.frame), undoChangeDefinition);
  }

  viewItemDetail(data: any) {
    const itemId = data.itemId;
    const container: PresentationCollectionElement = data.container;
    const accessLevel = 'EDIT';
    const config = {
      data: { itemId, accessLevel },
      panelClass: [`no-padding`, `item-details-modal`],
      maxWidth: '95vw',
      width: '1350px',
      height: '800px',
      autoFocus: true,
    };
    const dialogRef = this.matDialog.open(ItemDetailsModalComponent, config);
    const itemModalComponent: ItemDetailsModalComponent = dialogRef.componentInstance;
    itemModalComponent.updated.subscribe((updatedData) => {
      this.updateItemDetail(container, updatedData);
    });
  }

  async updateItemDetail(item, data) {
    if (data.changes.optionName || data.changes.name) {
      if (data.changes.optionName) {
        const updatedItem = item.children.find((child) => child.value === data.object.id);
        updatedItem.label = data.changes.optionName;
      } else if (data.changes.name) {
        item.label = data.changes.name;
      }
      this.updateCollectionItem({ item });
    }
    this.composerItemService.syncElements(data);
  }

  getItemIndex(id) {
    return this.collectionElements.findIndex((element) => element.value === id);
  }
}
