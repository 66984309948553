import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ObjectUtil } from '@contrail/util';
import { PresentationFrame } from 'src/app/presentation/presentation';

@Component({
  selector: 'app-composer-grid-frame-preview',
  templateUrl: './composer-grid-frame-preview.component.html',
  styleUrls: ['./composer-grid-frame-preview.component.scss'],
})
export class ComposerGridFramePreviewComponent implements OnInit, OnChanges {
  @Input() frame: PresentationFrame;
  public label;
  public itemCount = 0;
  public optionCount = 0;
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.frame = ObjectUtil.cloneDeep(changes.frame.currentValue);
    this.setValues();
  }

  async ngOnInit() {
    this.setValues();
  }

  private setValues() {
    this.label = this.frame.name;
    this.itemCount = this.frame.collection.set.length;
    this.optionCount = this.frame.collection.set.reduce(
      (previousValue, currentSet) => previousValue + currentSet.children.length,
      0,
    );
  }
}
