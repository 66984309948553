import { Component, Input, OnInit } from '@angular/core';
import { PresentationFrame } from '../../../presentation';

@Component({
  selector: 'app-composer-svg-frame',
  templateUrl: './composer-svg-frame.component.html',
  styleUrls: ['./composer-svg-frame.component.scss'],
})
export class ComposerSvgFrameComponent implements OnInit {
  @Input() frame: PresentationFrame;
  constructor() {}

  ngOnInit(): void {}
}
