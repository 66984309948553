<div class="app-menu" (click)="$event.stopPropagation()">
  <div class="header">Apps & Tools</div>
  <div class="search">
    <app-search-bar [lgGray]="true" [placeholder]="'Search'"></app-search-bar>
  </div>
  <div class="list-apps">
    <button class="app" *ngFor="let app of appList$ | async" (click)="handleAppToolSelection(app)" mat-menu-item>
      <mat-icon svgIcon="{{ app.icon }}"></mat-icon>
      <span> {{ app.label }}</span>
    </button>
  </div>
  <div class="more-apps"></div>
</div>
