import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemoteMouseTrackersStoreModule } from './remote-mouse-tracker-store';
import { ComponentsModule } from '../components/components.module';
import { MouseTrackerComponent } from './mouse-tracker/mouse-tracker.component';
import { RemoteMouseComponent } from './remote-mouse/remote-mouse.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [MouseTrackerComponent, RemoteMouseComponent],
  imports: [CommonModule, MatIconModule, RemoteMouseTrackersStoreModule, ComponentsModule],
  exports: [MouseTrackerComponent, RemoteMouseComponent],
})
export class RemoteMouseTrackerModule {
  constructor() {}
}
