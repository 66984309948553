import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ViewDefinition } from '@contrail/client-views';
import { ObjectUtil } from '@contrail/util';
import { ComposerFrameLayoutService } from '../composer-frame-layout.service';
import { AuthService } from '@common/auth/auth.service';

@Component({
  selector: 'app-composer-frame-create-template-view',
  templateUrl: './create-template-view.component.html',
  styleUrls: ['./create-template-view.component.scss'],
})
export class ComposerFrameCreateTemplateViewComponent implements OnInit {
  public nameFormControl = new UntypedFormControl(null, Validators.required);
  public viewTypeFormControl = new UntypedFormControl(null, Validators.required);

  @Input() typeProperties: any[] = [];
  public isAlreadySubmitted = false;
  isOrgAdmin = false;
  title = 'New preset';

  constructor(
    public dialogRef: MatDialogRef<ComposerFrameCreateTemplateViewComponent>,
    private service: ComposerFrameLayoutService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public viewDefinition: any,
  ) {}

  ngOnInit(): void {
    this.isOrgAdmin = this.authService.isAdmin();
    //if (this.label) {
    //this.nameFormControl.setValue(this.label);
    this.title = 'Save as preset';
    //}
    this.viewTypeFormControl.setValue('private');
  }

  async create() {
    this.isAlreadySubmitted = true;
    const properties = ObjectUtil.cloneDeep(this.viewDefinition.properties.filter((property) => property.enabled));
    let newViewTemplate: ViewDefinition = {
      label: this.nameFormControl.value,
      properties,
      viewType: this.viewDefinition.viewType,
      applicationViewSlug: this.viewDefinition.applicationViewSlug,
    };
    if (this.viewTypeFormControl.value === 'private') {
      newViewTemplate.private = true;
    } else {
      newViewTemplate.admin = true;
    }
    newViewTemplate = ObjectUtil.cloneDeep(await this.service.createViewDefinitionTemplate(newViewTemplate));
    this.dialogRef.close(newViewTemplate);
  }

  cancel() {
    this.dialogRef.close();
  }

  isValid() {
    if (!this.nameFormControl.value) {
      return false;
    }
    return true;
  }
}
