<app-composer-toolbar></app-composer-toolbar>
<div class="composer-layout">
  <app-mouse-tracker
    *ngIf="contextReference && viewSize && editorMode === 'EDIT'"
    [viewSize]="viewSize"
    [contextReference]="contextReference"
    class="!absolute top-0 left-0"
  ></app-mouse-tracker>
  <app-composer-pinned-comments *ngIf="editorMode !== 'VIEW'"></app-composer-pinned-comments>
  <app-composer-frame-tray></app-composer-frame-tray>
  <app-composer-frame (mousedown)="resetSelectedFrame()" #composerFrame></app-composer-frame>
  <app-side-panel (click)="resetSelectedFrame()"></app-side-panel>
  <!-- <app-composer-data-chooser></app-composer-data-chooser> -->
  <app-composer-context-menu></app-composer-context-menu>
  <document-status-message></document-status-message>
  <app-comment-overlay></app-comment-overlay>
  <app-property-configurator-bar></app-property-configurator-bar>
</div>
