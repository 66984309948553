import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmationBoxService } from '@common/components/confirmation-box/confirmation-box';
import { RootStoreState } from '@rootstore';
import { ViewDefinition } from '@contrail/client-views';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DocumentActions } from 'src/app/presentation/document/document-store';
import { SideMenuOverlay } from 'src/app/presentation/document/document-store/document.state';
import { PresentationFrame } from 'src/app/presentation/presentation';
import { ComposerFrameCreateTemplateViewComponent } from './create-template-view/create-template-view.component';
import { COLLECTION_VIEW_APPLICATION_SLUG, ComposerFrameLayoutService } from './composer-frame-layout.service';
import { ComposerFramePropertySelectorComponent } from './composer-frame-property-selector/composer-frame-property-selector.component';
import { ComposerService } from '../../composer.service';
import { Entities } from '@contrail/sdk';

@Component({
  selector: 'app-composer-frame-layout',
  templateUrl: './composer-frame-layout.component.html',
  styleUrls: ['./composer-frame-layout.component.scss'],
})
export class ComposerFrameLayoutComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  @Input() applicationViewSlug: string;
  @ViewChild('viewNameInput') viewNameInput: ElementRef;
  @ViewChild('propertySelector') propertySelector: ComposerFramePropertySelectorComponent;
  form: UntypedFormGroup = new UntypedFormGroup({
    viewName: new UntypedFormControl('', [Validators.required]),
    selectedViewDefTemplate: new UntypedFormControl('', []),
  });
  public editName = false;

  private currentFrame: PresentationFrame;
  selectedViewDefinitionTemplate: ViewDefinition; // Compare SelectedViewDefinition with ViewDefinitionTemplates(Presets)
  selectedViewDefinition: ViewDefinition; // Frame Level
  viewDefinitionTemplates: Array<ViewDefinition>; // Showcase Level Presets

  constructor(
    private composerService: ComposerService,
    private layoutService: ComposerFrameLayoutService,
    private store: Store<RootStoreState.State>,
    private dialog: MatDialog,
    private confirmationBoxService: ConfirmationBoxService,
  ) {
    this.composerService.currentFrame.pipe(takeUntil(this.destroy$)).subscribe((currentFrame) => {
      this.currentFrame = currentFrame;
    });
  }

  async ngOnInit() {
    this.viewDefinitionTemplates = await this.layoutService.getViewDefinitionTemplates(this.applicationViewSlug);
    this.viewDefinitionTemplates.unshift({ id: 'null', label: '' });

    const contextReference = this.composerService.currentFrameContextReference;
    this.selectedViewDefinition = await this.layoutService.getViewDefinition(
      contextReference,
      this.applicationViewSlug,
      true,
    );

    this.setSelectedViewDefinitionTemplate();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  setSelectedViewDefinitionTemplate() {
    for (let viewDefTemplate of this.viewDefinitionTemplates) {
      if (viewDefTemplate.id !== 'null' && this.sameProperties(viewDefTemplate)) {
        this.selectedViewDefinitionTemplate = ObjectUtil.cloneDeep(viewDefTemplate);
        this.form.get('selectedViewDefTemplate').setValue(viewDefTemplate);
        break;
      }
    }
  }

  async synchProperties(properties) {
    if (this.currentFrame?.hasCustomViewDefinition) {
      const updatedProperties = ObjectUtil.cloneDeep(properties);
      const updatedViewDefinition = await this.layoutService.updateViewDefinition(this.selectedViewDefinition.id, {
        properties: updatedProperties,
      });
      this.selectedViewDefinition = ObjectUtil.cloneDeep(updatedViewDefinition);
    } else {
      await this.createCustomViewDefinition(properties);
    }
  }

  async onSelectedViewDefChange(event) {
    this.selectedViewDefinitionTemplate = event.value;
    if (event.value.id !== 'null') {
      const properties = ObjectUtil.cloneDeep(this.selectedViewDefinitionTemplate.properties);
      properties.forEach((property) => {
        if (!property.style) {
          property.style = {
            font: {
              size: this.applicationViewSlug === COLLECTION_VIEW_APPLICATION_SLUG ? 14 : 8,
            },
          };
        }
      });
      if (this.currentFrame?.hasCustomViewDefinition) {
        const updatedView = await this.layoutService.updateViewDefinition(this.selectedViewDefinition.id, {
          properties,
        });
        this.selectedViewDefinition = ObjectUtil.cloneDeep(updatedView);
      } else {
        await this.createCustomViewDefinition(properties);
      }
    }
  }

  private async createCustomViewDefinition(properties) {
    const updatedProperties = ObjectUtil.cloneDeep(properties);
    const viewDefinition: ViewDefinition = {
      applicationViewSlug: this.applicationViewSlug,
      contextReference: `presentation-frame:${this.currentFrame.id}`,
      label: 'custom view',
      viewType: 'properties_list',
      properties: updatedProperties,
    };
    const newViewDefinition = await this.layoutService.createViewDefinition(viewDefinition);
    this.selectedViewDefinition = ObjectUtil.cloneDeep(newViewDefinition);
    await this.toggleCustomViewDefinition(true);
  }

  private async toggleCustomViewDefinition(hasCustomViewDefinition: boolean) {
    this.currentFrame.hasCustomViewDefinition = hasCustomViewDefinition;
    this.composerService.setCurrentFrame(this.currentFrame);
    const object = { hasCustomViewDefinition };
    await new Entities().update({ entityName: 'presentation-frame', id: this.currentFrame.id, object });
  }

  hide() {
    const overlay: SideMenuOverlay = {};
    overlay.showChooser = false;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  hidePropertyWidget() {
    this.propertySelector.hidePropertyWidget();
  }

  startNameEdit() {
    this.editName = true;
    this.form.get('viewName').setValue(this.selectedViewDefinitionTemplate.label);
    setTimeout(() => {
      this.viewNameInput.nativeElement.focus();
    }, 100);
  }

  async endNameEdit() {
    this.editName = false;
    const updatedViewName = this.form.controls.viewName.value;
    const index = this.viewDefinitionTemplates.findIndex(
      (template) => template.id === this.selectedViewDefinitionTemplate.id,
    );
    this.viewDefinitionTemplates[index].label = updatedViewName;
    this.updateTemplate(index, { label: updatedViewName });
  }

  async saveTemplate() {
    if (this.selectedViewDefinitionTemplate) {
      const index = this.viewDefinitionTemplates.findIndex(
        (template) => template.id === this.selectedViewDefinitionTemplate.id,
      );
      const updatedProperties = ObjectUtil.cloneDeep(
        this.selectedViewDefinition.properties.filter((property) => property.enabled),
      );
      this.updateTemplate(index, { properties: updatedProperties });
    }
  }

  async updateTemplate(index, changes) {
    let updatedViewDefTemplate = await this.layoutService.updateViewDefinitionTemplate(
      this.selectedViewDefinitionTemplate.id,
      changes,
    );
    updatedViewDefTemplate = ObjectUtil.cloneDeep(updatedViewDefTemplate);
    this.viewDefinitionTemplates.splice(index, 1, updatedViewDefTemplate);
    this.selectedViewDefinitionTemplate = this.viewDefinitionTemplates[index];
    this.form.get('selectedViewDefTemplate').setValue(this.viewDefinitionTemplates[index]);
  }

  async deleteViewTemplate() {
    const confirm = await this.confirmationBoxService.open(`Confirm`, `Are you sure you want to delete this preset?`);
    const index = this.viewDefinitionTemplates.findIndex(
      (template) => template.id === this.selectedViewDefinitionTemplate.id,
    );
    if (confirm) {
      await this.layoutService.deleteViewDefinitionTemplate(this.selectedViewDefinitionTemplate.id);
    }
    this.viewDefinitionTemplates.splice(index, 1);
    this.selectedViewDefinitionTemplate = null;
  }

  async resetPreset() {
    this.synchProperties(this.selectedViewDefinitionTemplate?.properties);
  }

  createPreset(): void {
    const dialogRef = this.dialog.open(ComposerFrameCreateTemplateViewComponent, {
      width: '350px',
      disableClose: false,
      autoFocus: true,
      panelClass: 'full-screen-modal',
      data: this.selectedViewDefinition,
    });

    dialogRef.afterClosed().subscribe((newViewTemplate) => {
      if (newViewTemplate) {
        this.viewDefinitionTemplates.push(newViewTemplate);
        this.selectedViewDefinitionTemplate = newViewTemplate;
        this.form.get('selectedViewDefTemplate').setValue(newViewTemplate);
      }
    });
  }

  showPreview(event) {
    event.stopPropagation();
    this.layoutService.showPreview();
  }
  refreshFrame(event) {
    this.composerService.setCurrentFrame(ObjectUtil.cloneDeep(this.currentFrame));
  }

  sameProperties(viewDefinitionTemplate) {
    if (!viewDefinitionTemplate) {
      return true;
    }
    if (viewDefinitionTemplate && viewDefinitionTemplate?.id !== 'null' && this.selectedViewDefinition) {
      let sameProperties = true;
      const selectedViewDefinitionProps = this.selectedViewDefinition.properties.filter((property) => property.enabled);

      if (viewDefinitionTemplate.properties.length !== selectedViewDefinitionProps.length) {
        return false;
      }

      for (let i = 0; i < selectedViewDefinitionProps.length; i++) {
        const property = selectedViewDefinitionProps[i];

        if (
          !viewDefinitionTemplate.properties[i] ||
          property.slug !== viewDefinitionTemplate.properties[i].slug ||
          property.includeLabel !== viewDefinitionTemplate.properties[i].includeLabel ||
          JSON.stringify(property?.style) !== JSON.stringify(viewDefinitionTemplate.properties[i]?.style)
        ) {
          return false;
        }
      }
      return sameProperties;
    }
    return false;
  }
}
