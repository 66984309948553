import { Injectable } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { firstValueFrom, from, lastValueFrom, take, tap, timeout } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CanvasDocument, CANVAS_MODE, DrawOptions } from '../../canvas-lib';
import { AnnotatedElement } from '../document-annotation/document-annotation-options';
import { DocumentAnnotationService } from '../document-annotation/document-annotation-service';
import pLimit from 'p-limit';
import { PdfExport, PdfExportData } from '@common/exports/pdf-export/pdf-export.service';
import { DownloadService } from '@common/exports/download/download.service';
import { PDFDownloadPayload } from '@common/exports/export-widget/export-widget.component';
import { CustomFontsSelectors } from '@common/custom-fonts/custom-fonts-store';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { AnnotationLoader } from '../../composer/annotations-loader';
import { DocumentService } from '../document.service';
import { DocumentItemService } from '../document-item/document-item.service';
import { DocumentColorService } from '../document-color/document-color.service';
const limit = pLimit(10);

@Injectable({
  providedIn: 'root',
})
export class DocumentExportService {
  private readonly EXPORT_TIMEOUT = 60000 * 10; // 10min
  private customFonts;
  constructor(
    private authService: AuthService,
    private documentAnnotationService: DocumentAnnotationService,
    private pdfExport: PdfExport,
    private downloadService: DownloadService,
    private documentService: DocumentService,
    private documentItemService: DocumentItemService,
    private documentColorService: DocumentColorService,
    private store: Store<RootStoreState.State>,
  ) {
    this.store
      .select(CustomFontsSelectors.customFonts)
      .pipe(
        tap((customFonts) => {
          this.customFonts = customFonts;
        }),
      )
      .subscribe();
  }

  /**
   * Export frames from the current document to PDF
   * @param jobId
   * @param payload
   */
  public async exportFramesAsPdf(jobId: string, payload: PDFDownloadPayload) {
    if (!payload?.frames?.length) {
      this.downloadService.setError(jobId);
      return;
    }
    const startTime = performance.now();
    this.downloadService.addPendingJob(jobId);
    const d = new Date();
    const date = `${('0' + (d.getMonth() + 1)).slice(-2)}-${('0' + d.getDate()).slice(-2)}-${d.getFullYear()}`;
    from(this.saveFramesAsPdf(payload))
      .pipe(timeout(this.EXPORT_TIMEOUT))
      .subscribe({
        next: (blob) => {
          if (blob) {
            this.downloadService.completePendingJob(jobId, {
              path: blob,
              jobId,
              name: `${payload?.name || 'Export'} ${date}`,
            });
          } else {
            console.error('Error in PDF export: could not generate blob', blob);
            this.downloadService.setError(jobId);
          }
        },
        error: (e) => {
          console.error('Error in PDF export', e);
          this.downloadService.setError(jobId);
        },
        complete: () => {
          const endTime = performance.now();
          console.log(`PDF export took ${Math.round(endTime - startTime) * 0.001} seconds`);
        },
      });
  }

  /**
   * Get frames from the current document. Filter by @selectedIds
   * @param payload
   * @returns
   */
  public async saveFramesAsPdf(payload: PDFDownloadPayload): Promise<Blob> {
    let frames: DocumentElement[] = ObjectUtil.cloneDeep(payload.frames);
    if (payload?.selectedIds?.length > 0) {
      frames = frames.filter((frame) => payload.selectedIds.indexOf(frame.id) !== -1);
    }
    const annotatedElements = (await firstValueFrom(this.documentAnnotationService.annotatedElements$)) || [];
    return await this.createSnapshots(
      frames,
      annotatedElements,
      AnnotationLoader?.annotations,
      this.customFonts,
      payload.restrictedViewablePropertySlugs,
    );
  }

  /**
   * Create high quality PNG image from @elements
   * @elements is either edited (crop, border, opacity, rotate) PNG or masked PNG/SVG
   * @param elements
   * @returns
   */
  public async createImageFromElements(elements: DocumentElement[]) {
    const annotatedElements = (await firstValueFrom(this.documentAnnotationService.annotatedElements$)) || [];
    const commonBounds = this.documentService.getCommonBounds(elements);

    return await this.createSnapshot(
      {
        position: { x: commonBounds.x, y: commonBounds.y },
        size: { width: commonBounds.width, height: commonBounds.height },
        style: {
          backgroundColor: 'rgba(0,0,0,0)',
        },
      },
      elements,
      annotatedElements,
      AnnotationLoader?.annotations,
      this.customFonts,
      null,
      null,
      {
        type: 'image/png',
        encoderOptions: 1.0,
      },
      {
        loadOriginal: true,
      },
    );
  }

  /**
   * Create snapshots of each frame by drawing frame elements onto canvas
   * and converting it to data url.
   * Draw each data url to PDF page and convert the PDF document to Blob.
   * @param frames
   * @param annotatedElements
   * @returns
   */
  public async createSnapshots(
    frames: DocumentElement[],
    annotatedElements: AnnotatedElement[] = [],
    annotationOptions,
    customFonts,
    restrictedViewablePropertySlugs,
  ): Promise<Blob> {
    const canvasScale = 4;
    const promises = [];
    for (let i = 0; i < frames?.length; i++) {
      const frame = frames[i];
      const promise = limit(async () => {
        return await this.createSnapshot(
          frame,
          frame.elements,
          annotatedElements,
          annotationOptions,
          customFonts,
          restrictedViewablePropertySlugs,
          canvasScale,
        );
      });
      promises.push(promise);
    }

    return await Promise.all(promises).then((data: PdfExportData[]) => {
      data.forEach((d) => {
        d?.document?.clear();
      });
      return this.pdfExport.dataUrlsToBlob(data);
    });
  }

  /**
   * Create CanvasDocument for frame @element
   * Set frame @c positions relative to @element position.
   * Preload all async @elements (text, image), draw all elements
   * onto canvas synchronously.
   * Convert canvas document to data URL.
   * @param element
   * @param elements
   * @param annotatedElements
   * @returns
   */
  private async createSnapshot(
    element: DocumentElement,
    elements: DocumentElement[],
    annotatedElements: AnnotatedElement[],
    annotationOptions,
    customFonts,
    restrictedViewablePropertySlugs = null,
    canvasScale = null,
    imageOptions = {
      type: 'image/jpeg',
      encoderOptions: 1.0,
    },
    drawOptions: DrawOptions = {},
  ): Promise<PdfExportData> {
    const framePosition = element.position;
    const frameSize = element.size;
    const backgroundColor = element?.style?.backgroundColor || '#ffffff';
    const documentDefinition = {
      id: element.id,
      background:
        element?.background?.length > 0
          ? element.background
          : [
              {
                type: 'rectangle',
                position: { x: 0, y: 0 },
                size: { ...frameSize },
                style: {
                  backgroundColor,
                },
              },
            ],
      elements: elements.map((e) => {
        const element = ObjectUtil.cloneDeep(e);
        if (element.type === 'line') {
          element.lineDefinition.x1 = element.lineDefinition.x1 - framePosition.x;
          element.lineDefinition.y1 = element.lineDefinition.y1 - framePosition.y;
          element.lineDefinition.x2 = element.lineDefinition.x2 - framePosition.x;
          element.lineDefinition.y2 = element.lineDefinition.y2 - framePosition.y;
        } else {
          element.position = {
            x: element.position.x - framePosition.x,
            y: element.position.y - framePosition.y,
          };
        }
        return element;
      }),
      size: { ...frameSize },
    };
    let viewBox = {
      width: documentDefinition.size.width,
      height: documentDefinition.size.height,
      x: 0,
      y: 0,
    };

    const document = new CanvasDocument(
      null,
      documentDefinition,
      new DocumentService(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        this.documentItemService,
        this.documentColorService,
        null,
        null,
      ),
      CANVAS_MODE.SNAPSHOT,
      this.authService,
      { imageHost: environment.imageHost },
      { annotationOptions, customFonts, restrictedViewablePropertySlugs, hasSvgRecolorFeatureFlag: false, canvasScale },
    );
    await document.preloadElements(drawOptions);

    if (drawOptions?.loadOriginal) {
      const minOriginalSizeScale = document.getMinOriginalSizeScale();
      drawOptions.scaleBy = minOriginalSizeScale;
      // Set original resolution size after elements are preloaded
      const commonBounds = document.getCommonBounds(elements, {
        scaleBy: minOriginalSizeScale,
        forceOuterEdgeDimensions: true,
      });
      documentDefinition.size = { width: commonBounds.width, height: commonBounds.height };
      viewBox = { width: commonBounds.width, height: commonBounds.height, x: commonBounds.x, y: commonBounds.y };
    }
    document.setSize(documentDefinition.size, viewBox, documentDefinition.size, viewBox);
    document.setAnnotations(annotatedElements);
    document.draw(drawOptions);
    const dataUrl = document.toDataURL(imageOptions.type, imageOptions.encoderOptions);
    return {
      document,
      imageData: dataUrl,
      width: documentDefinition.size.width,
      height: documentDefinition.size.height,
    };
  }
}
