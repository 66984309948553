import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Types, Entities } from '@contrail/sdk';
import { AuthService } from 'src/app/common/auth/auth.service';

export interface Assortment {
  name: string;
  items?: any;
  [key: string]: any;
}

export interface Type {
  name: string;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class ShowcaseAssortmentsService {
  private assortmentsObject: Array<Assortment> = [];
  private assortmentsSubject: Subject<Array<Assortment>> = new BehaviorSubject(this.assortmentsObject);
  public assortments: Observable<Array<Assortment>> = this.assortmentsSubject.asObservable();
  public filteredAssortments: Array<Assortment> = [];

  constructor(private authService: AuthService) {
    this.authService.authContext.subscribe((context) => {
      if (context && context.user) {
        this.loadSourceAssortments();
      }
    });
  }
  public async loadSourceAssortments() {
    console.log('ShowcaseAssortmentsService: loadSourceAssortments');
    const client = new Types();
    const type = await client.getByRootAndPath({ root: 'assortment', path: 'assortment:season' });
    const filtered = await new Entities().get({ entityName: 'assortment', criteria: { typeId: type.id } });

    this.filteredAssortments = Object.assign([], filtered);
    this.assortmentsSubject.next(this.filteredAssortments);
  }
}
