import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Entities } from '@contrail/sdk';
import { Subscription } from 'rxjs';
import { PDFToSVGTransformationPollingService } from './pdfTosvg-polling-service';

@Component({
  selector: 'app-upload-file-frame-modal',
  templateUrl: './upload-file-frame-modal.component.html',
  styleUrls: ['./upload-file-frame-modal.component.scss'],
})
export class UploadFileFrameModalComponent implements OnInit, OnDestroy {
  message = `Uploading your file. Please wait`;
  uploadInProgress = false;

  noOfPendingJobs = 0;
  noOfCompletedJobs = 0;
  noOfErrorJbs = 0;

  pendingJobsObservable: Subscription;
  completedJobsObservable: Subscription;
  errorObservable: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<UploadFileFrameModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private pollingService: PDFToSVGTransformationPollingService,
  ) {
    console.log(`data is ${JSON.stringify(data)}`);
  }

  validFileSelected = false;
  uploadError = false;
  fileName = '';

  // in app.component.ts
  files: File[] = [];

  onSelect(event) {
    console.log(`event`);
    console.log(event);
    if (event!.rejectedFiles!.length) {
      console.log(event!.rejectedFiles);
      this.fileName = event!.rejectedFiles[0].name;
      this.uploadError = true;
      this.validFileSelected = false;
    } else {
      this.fileName = event!.addedFiles[0].name;
      this.uploadError = false;
      this.validFileSelected = true;
    }
    this.files.push(...event.addedFiles);
  }

  closeModal(obj = {}) {
    this.dialogRef.close(obj);
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  allowFileInput() {
    this.files.pop();
    this.uploadError = false;
    this.validFileSelected = false;
  }

  handleClose() {
    this.closeModal();
  }
  async uploadFile() {
    this.uploadInProgress = true;
    //console.log(`data ${JSON.stringify(this.data)}`);

    const file: File = this.files[0];

    const object = {
      ownedByReference: this.data?.presentationObject?.ownedByReference,
      contentType: file.type,
      fileName: file.name,
    };

    console.log(`object is ${JSON.stringify(object)}`);

    const fileEntity = await new Entities().create({ entityName: 'file', object });

    // upload the file
    const uploadPost = fileEntity.uploadPost;
    const formData = new FormData();

    formData.append('Content-Type', file.type);

    Object.entries(uploadPost.fields).forEach(([k, v]) => {
      // console.log(`k ${JSON.stringify(k)} - v ${JSON.stringify(v)}`);
      formData.append(k, v as string);
    });

    formData.append('file', file);

    this.http.post(uploadPost.url, formData).subscribe(
      async (res) => {
        console.log(`download url is ${fileEntity.downloadUrl}`);
        this.message = `File upload complete. Please wait while the system auto generate the frames`;
        this.initPDF2SVGTransformation(fileEntity.id);
      },
      (err) => {
        console.log(`error uploading file ${JSON.stringify(err)}`);
      },
    );
  }

  ngOnInit(): void {
    this.pollingService.prepForNewJob();

    this.pendingJobsObservable = this.pollingService.pendingJobsObservable.subscribe((data) => {
      console.log(` ********* DATA FROM PENDING JOBS ********* ${data?.length}`);

      console.log(data);

      if (data) {
        this.noOfPendingJobs = data.length;
        this.handleCloseModal();
        return;
      }
      console.log(`download service has received ${JSON.stringify(data)}  - ${data?.length} job ids`);
    });

    this.completedJobsObservable = this.pollingService.completedJobsObservable.subscribe((data) => {
      if (data) {
        console.log(`2. processing completed. Closing the modal`);
        this.noOfCompletedJobs = data.length;
        this.handleCloseModal(true);
        return;
      }
    });

    this.errorObservable = this.pollingService.errorObservable.subscribe((data) => {
      if (!data) {
        console.log(` from error ${data}`);
        // this.noOfErrorJbs++;
        return;
      } else {
        this.message = 'Failed to process the uploaded file. Please contact system administrator';
        this.handleCloseModal();
      }
    });

    this.subscriptions.push(this.pendingJobsObservable);
    this.subscriptions.push(this.errorObservable);
    this.subscriptions.push(this.completedJobsObservable);
  }
  handleCloseModal(reload = false) {
    console.log(
      ` noOfPendingJobs ${this.noOfPendingJobs} - this.noOfCompletedJobs ${this.noOfCompletedJobs}  - this.noOfErrorJbs ${this.noOfErrorJbs}`,
    );

    if (this.noOfPendingJobs === 0 && this.noOfCompletedJobs + this.noOfErrorJbs > this.noOfPendingJobs) {
      this.message = 'Done Processing';
      this.uploadInProgress = false;
      console.log(`processing completed. Closing the modal`);
      this.pollingService.prepForNewJob();
      this.closeModal({ reload });
      // if (reload) {
      //   //window.location.reload();
      // }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    console.log(`unsubscribed from all subscriptions`);
  }

  async initPDF2SVGTransformation(pdfFileId) {
    const object = {
      pdfFileReference: pdfFileId,
    };
    const options = {
      entityName: 'presentation',
      relation: 'imports',
      id: this.data!.presentationObject!.ownedByReference,
      object,
    };
    const job = await new Entities().create(options);

    console.log(`job data after initiating the pdf2svg conversion.. ${JSON.stringify(job)}`);

    this.pollingService.initPolling(job.id, this.data!.presentationObject!.ownedByReference);
  }
  getPDF2SVGStatus() {}
}
