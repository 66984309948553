import { Component, OnInit } from '@angular/core';
import { ActionRequest } from '@contrail/actions';
import { ComposerToolbarService } from '../composer-toolbar.service';

@Component({
  selector: 'app-composer-toolbar-copy-paste',
  templateUrl: './composer-toolbar-copy-paste.component.html',
  styleUrls: ['./composer-toolbar-copy-paste.component.scss'],
})
export class ComposerToolbarCopyPasteComponent implements OnInit {
  constructor(private composerToolbarService: ComposerToolbarService) {}

  ngOnInit(): void {}

  copy() {
    this.composerToolbarService.handleCopyRequest(new ActionRequest('copy_elements'));
  }

  paste() {
    this.composerToolbarService.showPasteWarningModal();
  }
}
