import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface Item {
  id: string;
  [key: string]: any;
}

interface ItemState extends EntityState<Item> {}

export interface State {
  items: ItemState;
}

export const itemAdapter: EntityAdapter<Item> = createEntityAdapter<Item>({});

export const itemInitialState = {
  items: itemAdapter.getInitialState({}),
};
