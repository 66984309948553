import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Document, DocumentElement, DocumentElementFactory, PositionDefinition } from '@contrail/documents';
import { UntypedFormControl, Validators } from '@angular/forms';
import { GridSpaceDefinition } from '@contrail/document-generation';
import { Subscription } from 'rxjs';
import { FrameTemplateCanvas } from '../frame-template-canvas/frame-template-canvas.component';
import { ObjectUtil } from '@contrail/util';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AuthService } from '@common/auth/auth.service';
import { IntercomService } from '@common/analytics/intercom';
import { FrameTemplate } from '@common/frame-templates/frame-template';
import { Entities } from '@contrail/sdk';
import { DocumentService } from '../../document/document.service';

const excludedElementTypes = ['iframe', 'component', 'group'];

@Component({
  selector: 'app-frame-template-editor',
  templateUrl: './frame-template-editor.component.html',
  styleUrls: ['./frame-template-editor.component.scss'],
})
export class FrameTemplateEditor implements OnInit, OnDestroy {
  @ViewChild('frameTemplateEditor') frameTemplateEditor: FrameTemplateCanvas;
  document: Document;
  ownerReference: string;
  templateNameControl = new UntypedFormControl('', Validators.required);
  templateTypeControl = new UntypedFormControl('private');
  lineboardOptionControl = new UntypedFormControl(false);
  frameTemplate: FrameTemplate;
  isOrgAdmin = false;
  isEditAllowed = true;
  authContext: any;
  private messageListener: any;
  private templateType = 'FRAME';

  gridSpaceDefinition: GridSpaceDefinition;
  loading = false;
  subscriptions: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private intercom: IntercomService,
    private documentService: DocumentService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.messageListener = this.handleMessage.bind(this);
    window.addEventListener('message', this.messageListener);
    this.subscriptions.add(
      this.lineboardOptionControl.valueChanges.subscribe((value) => {
        setTimeout(() => {
          if (value) {
            this.toggleDrawGridSpace();
          } else {
            this.toggleDrawGridSpace(false);
          }
        }, 10);
      }),
    );

    this.subscriptions.add(
      this.templateNameControl.valueChanges.subscribe((value) => {
        if (this.templateNameControl.hasError('duplicateName')) {
          this.templateNameControl.setErrors(null);
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    window.removeEventListener('message', this.messageListener);
  }

  private handleMessage(event) {
    setTimeout(() => {
      this.intercom.hide();
    }, 1000);

    if (event.data?.authContext) {
      if (event.data.frameTemplate) {
        this.isEditAllowed = event.data.isEditAllowed;
        this.frameTemplate = event.data.frameTemplate;
        this.templateNameControl.setValue(event.data.frameTemplate.name);
        if (event.data.frameTemplate.gridSpaceDefinition) {
          this.gridSpaceDefinition = event.data.frameTemplate.gridSpaceDefinition;
          this.lineboardOptionControl.setValue(true);
        }
        this.templateTypeControl.setValue(event.data.frameTemplate.private ? 'private' : 'admin');
        if (!this.isEditAllowed) {
          this.templateTypeControl.disable();
          this.templateNameControl.disable();
          this.lineboardOptionControl.disable();
        }
      }
      this.ownerReference = event.data.ownerReference;
      if (this.ownerReference?.startsWith('presentation')) {
        this.templateType = 'SHOWCASE-FRAME';
      }
      const frame = event.data.frame;
      this.isOrgAdmin = event.data.isOrgAdmin;
      this.authContext = event.data.authContext;
      this.authService.setLocalAuthContext(this.authContext);
      frame.name = '';
      const positionAdjustments: PositionDefinition = { x: 0 - frame.position.x, y: 0 - frame.position.y };
      frame.position.x = 0;
      frame.position.y = 0;
      let elements = event.data.elements.filter((element) => !excludedElementTypes.includes(element.type));
      elements = this.generateTemplateElements(elements, positionAdjustments);
      elements.unshift(frame);
      this.document = {
        size: frame.size,
        elements,
        style: ObjectUtil.cloneDeep(frame.style),
        clipContent: frame.clipContent,
      };
      this.documentService.init(this.document, this.ownerReference);
    }
  }

  private generateTemplateElements(elements: Array<any>, positionAdjustments: PositionDefinition) {
    const createdElements: Array<DocumentElement> = [];
    const elementMapping = {};
    for (let element of elements) {
      const origElementId = ObjectUtil.cloneDeep(element.id);
      delete element.id;
      delete element.specifiedId;
      delete element.updatedOn;
      delete element.updatedById;
      delete element.createdOn;
      delete element.createdById;

      const documentElement: DocumentElement = DocumentElementFactory.createElement(element.type, element);
      elementMapping[origElementId] = documentElement.id;
      documentElement.isLocked = true;
      if (documentElement.type === 'line') {
        documentElement.lineDefinition.x1 += positionAdjustments.x;
        documentElement.lineDefinition.x2 += positionAdjustments.x;
        documentElement.lineDefinition.y1 += positionAdjustments.y;
        documentElement.lineDefinition.y2 += positionAdjustments.y;
      } else {
        documentElement.position.x += positionAdjustments.x;
        documentElement.position.y += positionAdjustments.y;
      }
      if (documentElement.elementIds) {
        documentElement.elementIds = [elementMapping[documentElement.elementIds[0]]];
      }
      createdElements.push(documentElement);
    }
    return createdElements;
  }

  async submit() {
    const duplicateName = await this.checkForDuplicateNames();
    if (duplicateName) {
      this.templateNameControl.setErrors({ duplicateName: true });
      this.snackBar.open('A frame template with the same name already exists. Please use a different name.', '', {
        duration: 3000,
      });
    } else {
      if (this.frameTemplate) {
        this.updateFrameTemplate();
      } else {
        this.createFrameTemplate();
      }
    }
  }

  isValid() {
    return (
      ((this.lineboardOptionControl.value && this.gridSpaceDefinition) || !this.lineboardOptionControl.value) &&
      this.templateNameControl.value &&
      this.templateNameControl.value !== ''
    );
  }

  cancel() {
    window.parent.postMessage({ closeDialog: true });
  }

  setGridSpaceDefinition(gridSpaceDefinition) {
    this.gridSpaceDefinition = gridSpaceDefinition;
    if (!this.isGridSpaceInFrame(this.gridSpaceDefinition)) {
      this.gridSpaceDefinition = null;
      this.snackBar.open('Grid space must be within the frame.', '', { duration: 3000 });
    }
  }

  private isGridSpaceInFrame(gridSpaceDefinition: GridSpaceDefinition) {
    const gridSpaceDimension = {
      x1: gridSpaceDefinition.position.x,
      y1: gridSpaceDefinition.position.y,
      x2: gridSpaceDefinition.position.x + gridSpaceDefinition.size.width,
      y2: gridSpaceDefinition.position.y + gridSpaceDefinition.size.height,
    };
    const frameDimension = {
      x1: 0,
      y1: 0,
      x2: this.document.size.width,
      y2: this.document.size.height,
    };
    return (
      gridSpaceDimension.x1 >= frameDimension.x1 &&
      gridSpaceDimension.y1 >= frameDimension.y1 &&
      gridSpaceDimension.x2 <= frameDimension.x2 &&
      gridSpaceDimension.y2 <= frameDimension.y2
    );
  }

  toggleDrawGridSpace(turnOn = true) {
    if (turnOn) {
      this.frameTemplateEditor.addGridSpace(this.gridSpaceDefinition);
    } else {
      this.gridSpaceDefinition = null;
      this.frameTemplateEditor.removeGridSpace();
    }
  }

  private async createFrameTemplate() {
    this.loading = true;
    const templateDocument: Document = this.frameTemplateEditor.getDocumentData();
    const canvas: HTMLCanvasElement = await this.frameTemplateEditor.getCanvas();
    const dataURL = canvas.toDataURL('image/png', 1.0);
    const canvasBlob = this.dataURLtoBlob(dataURL);
    const fileEntity = await this.documentService.fileHandler.fileService.createAndUploadFile(
      new File([canvasBlob], `Template-${new Date().getTime()}.png`, {
        type: 'image/png',
      }),
    );
    const templateData = {
      templateType: this.templateType,
      name: this.templateNameControl.value,
      previewFileId: fileEntity.id,
      gridSpaceDefinition: this.gridSpaceDefinition,
      document: ObjectUtil.cloneDeep(templateDocument),
      admin: false,
      private: false,
    };
    if (this.templateTypeControl.value === 'admin') {
      templateData.admin = true;
    } else {
      templateData.private = true;
    }
    window.parent.postMessage({ templateData, closeDialog: true });
  }

  private async updateFrameTemplate() {
    this.loading = true;
    const templateData = {
      name: this.templateNameControl.value,
      gridSpaceDefinition: this.gridSpaceDefinition,
      admin: false,
      private: false,
    };
    if (this.templateTypeControl.value === 'admin') {
      templateData.admin = true;
    } else {
      templateData.private = true;
    }
    window.parent.postMessage({ templateData, closeDialog: true });
  }

  private dataURLtoBlob(dataurl) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  private async checkForDuplicateNames() {
    this.loading = true;
    const criteria = { templateType: this.templateType, includePrivate: true };
    const frameTemplates = await new Entities().get({ entityName: 'document-template', criteria });
    const existingFrameTemplate = frameTemplates.find(
      (template) => template.name.toLowerCase() === this.templateNameControl.value.toLowerCase(),
    );
    this.loading = false;
    if (existingFrameTemplate?.id === this.frameTemplate?.id) {
      return null;
    }
    return existingFrameTemplate;
  }
}
