import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { ConfirmationBoxService } from '@components/confirmation-box/confirmation-box';
import { AssortmentHistoryComponent } from 'src/app/common/assortment-history/assortment-history.component';
import { AssignSourceAssortmentModalComponent } from './assign-source-assortment-modal/assign-source-assortment-modal.component';

@Component({
  selector: 'app-source-assortment',
  templateUrl: './source-assortment.component.html',
  styleUrls: ['./source-assortment.component.scss'],
})
export class SourceAssortmentComponent {
  @Input() sourceAssortment: any;
  @Input() workspaceId: any;
  @Output() assignSourceAssortment = new EventEmitter();
  @Output() removeSourceAssortment = new EventEmitter();
  constructor(
    private matDialog: MatDialog,
    private confirmationBoxService: ConfirmationBoxService,
  ) {}

  viewHistory() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '1200px';
    dialogConfig.data = { assortmentId: this.sourceAssortment?.id };
    dialogConfig.panelClass = 'no-padding'; // defined in _dialog.scss,

    const dialogRef = this.matDialog.open(AssortmentHistoryComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }

  assign() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '700px';
    dialogConfig.data = {
      defaultSourceAssortmentId: this.sourceAssortment?.id,
      defaultCurrentWorkspaceId: this.sourceAssortment?.workspaceId || this.workspaceId,
    };

    const dialogRef = this.matDialog.open(AssignSourceAssortmentModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      this.assignSourceAssortment.emit(result);
    });
  }

  async remove() {
    const confirm = await this.confirmationBoxService.open(
      'Remove source assortment',
      'This action will remove source assortment change notifications and history from this document. Are you sure you want to proceed?',
    );
    if (confirm) {
      this.removeSourceAssortment.emit();
    }
  }
}
