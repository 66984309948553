<div class="mainView" *ngIf="selectedViewable">
  <img *ngIf="selectedViewable.type === 'image'" [src]="selectedViewable.location" />

  <ng-container *ngIf="is3d">
    <app-webgl-viewer *ngIf="useVibeViewer" [size]="'100%'" [content]="selectedViewable?.content"></app-webgl-viewer>

    <iframe
      *ngIf="!useVibeViewer"
      [src]="selectedViewable?.content?.embedLink | safe: 'resourceUrl'"
      class="w-full h-full"
      frameborder="0"
      mozallowfullscreen="true"
      webkitallowfullscreen="true"
    ></iframe>
  </ng-container>
</div>
<div class="tray narrow-scroll">
  <div *ngFor="let viewable of viewables" (click)="selectContent(viewable)" class="viewableThumb hover:scale-105">
    <img *ngIf="viewable.type === 'image'" [src]="viewable.thumbnailLocation || viewable.location" />
    <div *ngIf="viewable.type === 'glb'" class="flex-center"><mat-icon class="scale-125">3d_rotation</mat-icon></div>
  </div>
</div>
