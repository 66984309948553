<button class="toggle-icon" (click)="copy()" matTooltip="Copy (Command + C or Ctrl + C on Windows)" mat-icon-button>
  <mat-icon>content_copy</mat-icon>
</button>

<button
  class="toggle-icon"
  mat-icon-button
  #tooltip="matTooltip"
  (click)="paste()"
  matTooltip="Please press Command + V (or Ctrl + V on Windows) to paste"
  matTooltipPosition="below"
>
  <mat-icon>content_paste</mat-icon>
</button>
