import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { ItemChooserActions } from '.';
import { ItemChooserService } from '../item-chooser.service';

@Injectable()
export class ItemsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private itemChooserService: ItemChooserService,
  ) {}

  loadItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ItemChooserActions.ItemActionTypes.LOAD_ITEMS),
      switchMap((action: any) => {
        return from(this.itemChooserService.loadItems()).pipe(
          map((data) => ItemChooserActions.loadItemsSuccess({ data })),
          catchError((error) => observableOf(ItemChooserActions.loadItemsFailure({ error }))),
        );
      }),
    ),
  );
}
