<div class="widget-tray">
  <div class="widgets-list">
    <!-- used to position the board tool options -->
    <button mat-button [matMenuTriggerFor]="toolMenu" id="toolMenuBtn" style="display: none"></button>

    <!--<button [color]="getActiveColor('select')" mat-icon-button  (click)="onContextMenu($event);setInteractionMode('select')"><mat-icon svgIcon="app-action-options"></mat-icon></button>-->
    <button mat-icon-button (click)="showItemChooser(); setInteractionMode('select')">
      <mat-icon svgIcon="app-action-options"></mat-icon>
    </button>
    <button [color]="getActiveColor('text')" mat-icon-button (click)="setInteractionMode('text')">
      <mat-icon svgIcon="app-text-tool"></mat-icon>
    </button>
    <!--
    <button [color]="getActiveColor('select')" mat-icon-button (click)="setInteractionMode('image')"><mat-icon svgIcon="app-image-tool"></mat-icon></button>
    -->
    <button [color]="getActiveColor('shape')" mat-icon-button [matMenuTriggerFor]="shapeMenu">
      <mat-icon>{{ getActiveShapeIcon() || shapeMenus[0].icon }}</mat-icon>
    </button>
    <!--
    <button [color]="getActiveColor('select')" mat-icon-button (click)="setInteractionMode('select')"><mat-icon svgIcon="app-layouts"></mat-icon></button>
    <button [color]="getActiveColor('select')" mat-icon-button (click)="setInteractionMode('select');showComments()"><mat-icon svgIcon="app-comments"></mat-icon></button>
    <button [color]="getActiveColor('select')" mat-icon-button (click)="setInteractionMode('select')"><mat-icon svgIcon="app-more-options"></mat-icon></button>
    -->
    <app-paint-formatter></app-paint-formatter>
  </div>
</div>

<mat-menu #shapeMenu="matMenu">
  <ng-template matMenuContent let-frame="frame">
    <button *ngFor="let menu of shapeMenus" mat-menu-item (click)="setInteractionMode(menu.id)">
      <mat-icon class="icons">{{ menu.icon }}</mat-icon>
      <span>{{ menu.label }}</span>
    </button>
  </ng-template>
</mat-menu>

<mat-menu #toolMenu="matMenu" (close)="onToolMenuClosed($event)">
  <ng-template matMenuContent let-frame="frame">
    <app-document-tools (selectionCompleted)="closeToolMenu()"></app-document-tools>
  </ng-template>
</mat-menu>
