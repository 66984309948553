<canvas [id]="canvasId" [ngStyle]="{ width: size, height: size, padding: '1px' }"></canvas>

<div class="canvas-buttons flex-center">
  <div (click)="reset()" class="canvas-btn reset-btn">RESET</div>
  <img
    src="/assets/images/rotate.svg"
    draggable="false"
    (mousedown)="startRotate()"
    (mouseup)="stopRotate()"
    (mouseout)="stopRotate()"
    class="canvas-btn"
  />
  <img
    src="/assets/images/zoom_in.svg"
    draggable="false"
    (mousedown)="startZoomIn()"
    (mouseup)="stopZoom()"
    (mouseout)="stopZoom()"
    class="canvas-btn"
  />
  <img
    src="/assets/images/zoom_out.svg"
    draggable="false"
    (mousedown)="startZoomOut()"
    (mouseup)="stopZoom()"
    (mouseout)="stopZoom()"
    class="canvas-btn"
  />
</div>

<div class="canvas-loading-screen" *ngIf="viewerLoading">
  <app-mat-spinner
    class="flex-center h-full"
    [message]="'Loading 3D Model'"
    [overlay]="false"
    [loading]="true"
  ></app-mat-spinner>
</div>
