import { Component, Input } from '@angular/core';
import { PresentationCollectionElement } from 'src/app/presentation/presentation';
import { Subscription } from 'rxjs';
import { ComposerItemService } from '../../composer-item/composer-item.service';

@Component({
  selector: 'app-composer-toolbar-item',
  templateUrl: './composer-toolbar-item.component.html',
  styleUrls: ['./composer-toolbar-item.component.scss'],
})
export class ComposerToolbarItemComponent {
  @Input() frameType: string;
  gridFrameActiveContainer: PresentationCollectionElement;
  subscriptions = new Subscription();
  constructor(private composerItemService: ComposerItemService) {}

  async promoteItems() {
    await this.composerItemService.promoteItems(this.frameType);
  }
}
