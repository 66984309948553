import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { EVENT_CATEGORY } from '@common/analytics/user-analytics.service';
import { AuthService } from '@common/auth/auth.service';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { Feature } from '@common/feature-flags/feature-flag';
import { RoutingService } from '@common/routing/routing.service';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { CreateShowcaseModalLauncher } from 'src/app/showcases/create-showcase/create-showcase-modal-launcher';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  navOptions = [];
  framesRouteSet = false;

  constructor(
    private authService: AuthService,
    private createShowcaseModelLauncher: CreateShowcaseModalLauncher,
    private analyticsService: AnalyticsService,
    private store: Store<RootStoreState.State>,
    private routingService: RoutingService,
  ) {
    this.authService.authContext.subscribe((context) => {
      if (context) {
        this.populateNavOptions();
      }
    });
  }

  async ngOnInit() {
    this.store.select(FeatureFlagsSelectors.featureFlags).subscribe((flags) => {
      let templatesGroupIndex = this.navOptions.findIndex((navOption) => navOption.label === 'Templates');
      if (!this.navOptions.find((navOption) => navOption.label === 'Frames')) {
        if (templatesGroupIndex === -1) {
          templatesGroupIndex = 1;
          this.navOptions.splice(templatesGroupIndex, 0, { icon: 'dashboard', label: `Templates`, group: true });
        }
        this.navOptions.splice(templatesGroupIndex + 1, 0, {
          child: true,
          routerLink: this.routingService.getRouterLink('/frame-template-library'),
          label: `Frames`,
        });
      }
    });
  }

  private async populateNavOptions() {
    const authContext = await this.authService.getAuthContext();
    const navOptions = [];
    navOptions.push({
      icon: 'auto_awesome',
      routerLink: this.routingService.getRouterLink('/showcases/showcases-list'),
      label: `All showcases`,
    });
    if (authContext.currentOrg.role === 'ADMIN') {
      navOptions.push({ icon: 'dashboard', label: `Templates`, group: true });
    }

    if (authContext.currentOrg.role === 'ADMIN') {
      navOptions.push({
        child: true,
        routerLink: this.routingService.getRouterLink('/showcases/showcases-list'),
        label: `Showcases`,
        queryParams: { templates: true },
      });
    }
    if (['demo', 'archergrey'].includes(authContext.currentOrg.orgSlug)) {
      navOptions.push({
        icon: 'insights',
        routerLink: this.routingService.getRouterLink('/showcases/analytics'),
        label: `Insights`,
      });
    }
    navOptions.push({
      icon: 'archive',
      routerLink: this.routingService.getRouterLink('/showcases/showcases-list'),
      label: `Archived`,
      queryParams: { archived: true },
    });
    this.navOptions = navOptions;
  }

  // createShowcase() {
  //   this.createShowcaseModelLauncher.openModal();
  // }

  goHome() {
    this.routingService.go('/showcases');
  }
  helpCenter() {
    window.open('https://intercom.help/vibesolutions/en/', '_blank');
  }
}
