import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

const TITLE_OPTIONS = ['Create Showcase', 'Connect to assortment'];
@Component({
  selector: 'app-create-showcase-modal',
  templateUrl: './create-showcase-modal.component.html',
  styleUrls: ['./create-showcase-modal.component.scss'],
})
export class CreateShowcaseModalComponent implements OnInit {
  currentPage = 0;
  title = 'Create Showcase';
  constructor(public dialogRef: MatDialogRef<CreateShowcaseModalComponent>) {}

  ngOnInit(): void {}

  closeModal() {
    this.dialogRef.close();
  }

  onPageChanged(page) {
    this.currentPage = page;
    this.title = TITLE_OPTIONS[page];
  }
}
