import { Component, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ComposerService } from '../../composer.service';
import { COLORS_PICKLIST } from './composer-frame-toolbar-backgroundcolor-preset-colors';
import { PresentationFrame } from '../../../presentation';
import { DocumentElementFactory } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';

@Component({
  selector: 'app-composer-frame-toolbar-backgroundcolor',
  templateUrl: './composer-frame-toolbar-backgroundcolor.component.html',
  styleUrls: ['./composer-frame-toolbar-backgroundcolor.component.scss'],
})
export class ComposerFrameToolbarBackgroundcolorComponent {
  presetColors = COLORS_PICKLIST;
  @Input() matIconType: string;

  defaultColor = 'rgba(0,0,0,1)';
  color = this.defaultColor;
  currentFrame: PresentationFrame;
  @Input() colorType: string;

  isOpened = false;
  fg: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private composerService: ComposerService,
  ) {
    this.composerService.currentFrame.subscribe((frame) => {
      this.currentFrame = frame;
      if (!this.currentFrame) {
        return;
      }
      if (this.currentFrame.document?.background?.filter((el) => el.type === 'rectangle').length > 0) {
        this.currentFrame.document?.background
          ?.filter((el) => el.type === 'rectangle')
          .map((el) => (this.color = el.style.backgroundColor || this.defaultColor));
      } else {
        this.color = this.defaultColor;
      }
    });

    this.fg = this.fb.group({
      color: [this.color, Validators.required],
    });
  }
  public toggleDisplay() {
    this.isOpened = !this.isOpened;
  }

  async handleChange(color: string) {
    const existingFrameData = ObjectUtil.cloneDeep(this.currentFrame);
    await this.setBackgroundDefinition();
    this.composerService.handleFrameChanges(this.currentFrame, existingFrameData);
    this.composerService.refreshCurrentFrame();
    this.color = color;
  }

  private async setBackgroundDefinition() {
    this.currentFrame.document.background = this.currentFrame.document.background || [];
    let backgroundRect = this.currentFrame.document?.background?.find((el) => el.type === 'rectangle');
    if (!backgroundRect) {
      backgroundRect = DocumentElementFactory.createShapeElement('rectangle', {
        size: { width: 1200, height: 675 },
        position: { x: 0, y: 0 },
      });
      this.currentFrame.document.background.push(backgroundRect);
    }
    backgroundRect.position = { x: 0, y: 0 };
    ObjectUtil.setByPath(backgroundRect, 'style.backgroundColor', this.color);
  }

  closePicker() {
    this.isOpened = false;
  }
}
