import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, debounceTime, map, Observable, Subject, Subscription } from 'rxjs';
import { AuthService } from '@common/auth/auth.service';
import { DocumentContentEditorService } from '../document-content-editor.service';
import { DocumentAction, DocumentChangeType, DocumentElement, SizeDefinition } from '@contrail/documents';
import { ContextualEntityHelper } from '../../contextual-entity-helper';
import { ColorDetail, ColorWidgetComponent } from '@common/color/color-widget/color-widget.component';
import { ContentEntity, ContentService } from '@common/content/content.service';
import { DocumentService } from '../../document.service';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { State } from 'src/app/root-store/root-state';
import { DocumentSelectors } from '../../document-store';
import { ObjectUtil } from '@contrail/util';
import pLimit from 'p-limit';
import { ColorService } from '@common/color/color.service';
import { UndoRedoService } from '@common/undo-redo/undo-redo-service';
import { DocumentContentAction } from '../document-content-action';
import { DocumentContentChangeType } from '../document-content-change';
import { DocumentItemService } from '../../document-item/document-item.service';
import { AssortmentsActions } from '@common/assortments/assortments-store';
import { SVGHelper } from '@contrail/canvas';
const limit = pLimit(10);

export interface IReplaceColor {
  color: ColorDetail;
  newColor: ColorDetail;
  origColor: ColorDetail;
}

@Component({
  selector: 'app-document-content-svg-editor',
  templateUrl: './document-content-svg-editor.component.html',
  styleUrls: ['./document-content-svg-editor.component.scss'],
})
export class DocumentContentSvgEditorComponent implements OnInit, OnDestroy {
  private subject: Subject<string> = new Subject();
  private subscriptions = new Subscription();
  private element: DocumentElement;
  private svgRootElement: SVGElement;
  private svgElementSelection: SVGElement[] = [];

  private svgHtmlString: string;
  private newSvgHtmlString: string;

  public item?: any;
  public content: ContentEntity;
  public fills: Array<IReplaceColor>;
  public currentColor: IReplaceColor;
  public dimensions: string;
  public createdByName: string;
  public loadingSubject: Subject<boolean> = new BehaviorSubject(true);
  public loading$: Observable<boolean> = this.loadingSubject.asObservable();

  private currentHighlightedColors: IReplaceColor[] = [];

  @ViewChild('colorWidget') colorPicker: ColorWidgetComponent;
  @ViewChild('colorTarget') colorTarget: ElementRef;
  constructor(
    private store: Store<State>,
    private authService: AuthService,
    private documentService: DocumentService,
    private contentService: ContentService,
    private contentEditorService: DocumentContentEditorService,
    private contextualEntityHelper: ContextualEntityHelper,
    private undoRedoService: UndoRedoService,
  ) {}

  ngOnInit(): void {
    const selectedElements = this.documentService
      .getSelectedElements()
      .filter((element) => this.contentEditorService.isContentEditable(element));
    this.subscriptions.add(
      this.documentService.documentElementEvents.subscribe((event) => {
        if (!event) {
          return;
        }

        if (event.eventType === 'selected' && event.element) {
          const selectedElements = this.documentService.getSelectedElements();
          if (selectedElements?.length !== 1) {
            this.contentEditorService.hideEditorOrStay();
          } else {
            if (!event.element.isLocked && this.contentEditorService.isContentEditable(event.element)) {
              // If selecting different SVG element - check for unsaved changes, and keep current element selected if user wants to save
              if (
                this.element &&
                event.element.id != this.element.id &&
                this.contentEditorService.isDirty &&
                !this.contentEditorService.exitUnsaved()
              ) {
                this.documentService.selectElement(this.element);
              } else if (!this.element || event.element.id != this.element.id) {
                // this.contentEditorService.loadElement(event.element);
              }
              this.documentService.setInteractionMode('select');
            } else {
              this.contentEditorService.hideEditorOrStay();
            }
          }
        }
      }),
    );

    this.subscriptions.add(
      this.documentService.documentActions
        .pipe(
          map((actions) =>
            actions?.filter(
              (action) =>
                action.changeDefinition.changeType === DocumentChangeType.DELETE_ELEMENT ||
                action.changeDefinition.changeType === DocumentChangeType.MODIFY_ELEMENT,
            ),
          ),
        )
        .subscribe((actions: DocumentAction[]) => {
          if (this.element) {
            if (
              actions
                ?.filter((action) => action.changeDefinition.changeType === DocumentChangeType.DELETE_ELEMENT)
                ?.findIndex((action) => action?.changeDefinition?.elementId === this.element.id) !== -1
            ) {
              this.contentEditorService.hide();
            }

            const modifiedElement = actions
              ?.filter((action) => action.changeDefinition.changeType === DocumentChangeType.MODIFY_ELEMENT)
              ?.find((action) => action?.changeDefinition?.elementId === this.element.id)
              ?.changeDefinition?.elementData;
            if (modifiedElement) {
              const imageElement =
                this.element?.type === 'component'
                  ? this.element?.elements?.find((e) => e.type === 'image')
                  : this.element;
              const modifiedImageElement =
                this.element?.type === 'component'
                  ? modifiedElement?.elements?.find((e) => e.type === 'image')
                  : modifiedElement;
              if (
                (modifiedImageElement.url && imageElement.url !== modifiedElement?.url) ||
                (modifiedElement?.alternateUrls?.originalFile &&
                  imageElement?.alternateUrls?.originalFile &&
                  modifiedElement.alternateUrls.originalFile !== imageElement.alternateUrls.originalFile)
              ) {
                // this.contentEditorService.loadElement(ObjectUtil.mergeDeep(ObjectUtil.cloneDeep(this.element), modifiedElement));
              } else if (!this.element.isLocked && modifiedElement.isLocked) {
                this.contentEditorService.hideUnsaved();
              }
            }
          }
        }),
    );

    this.subscriptions.add(
      this.contentEditorService.element$.subscribe((elementContainer) => {
        if (elementContainer && (!this.element || this.element.id !== elementContainer?.element?.id)) {
          this.loadNew(elementContainer.element, elementContainer.svgRootElement);
        } else {
          if (elementContainer) {
            this.load(elementContainer.element, elementContainer.svgRootElement);
          }
        }
      }),
    );

    this.subscriptions.add(
      this.store.select(DocumentSelectors.toggleChooser).subscribe((toggleChooser) => {
        if (!(toggleChooser?.slug === 'contentEditor' && toggleChooser.showChooser === true)) {
          // If closing content editor sidebar check for unsaved changes. Keep sidebar open if user wants to save.
          if (this.contentEditorService.isDirty) {
            if (!this.contentEditorService.exitUnsaved()) {
              this.contentEditorService.show();
            }
          }
        }
      }),
    );

    this.subscriptions.add(this.subject.pipe(debounceTime(800)).subscribe((values) => this.handleValueChange(values)));

    this.subscriptions.add(
      this.documentService.documentSVGElementEvents.subscribe(async (event) => {
        if (event.eventType == 'selectionUpdated') {
          this.svgElementSelection = event.selectedComponents;

          const colors = event.selectedComponents.length
            ? SVGHelper.getColorsInElements(this.svgElementSelection)
            : SVGHelper.getColorsInSvg(this.svgRootElement);

          this.fills = await this.loadColors(colors?.fills);
        } else if (event.eventType == 'onSvgReset') {
          this.svgRootElement = event.svgRootElement;
        } else if (event.eventType == 'svgEditorExit') {
          const closeContentOptions = event.closeContentOptions != null ? event.closeContentOptions : true;
          this.hide(closeContentOptions);
        }
      }),
    );

    this.subscriptions.add(
      this.contentEditorService.contentActions.subscribe((actions) => {
        this.applyContentActions(actions);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public async loadNew(element: DocumentElement, svgRootElement: SVGElement) {
    this.loadingSubject.next(true);
    await this.load(element, svgRootElement);
    this.loadingSubject.next(false);
  }

  public async load(element: DocumentElement, svgRootElement: SVGElement) {
    this.element = element;
    this.svgRootElement = svgRootElement;
    this.svgHtmlString = svgRootElement.outerHTML;
    const context = await this.contextualEntityHelper.getContextualEntityFromDocumentElement(element, true);
    if (context?.reference?.entityType === 'item') {
      this.item = context.entity;
      this.content = context.viewableEntity;
    } else if (context?.reference?.entityType === 'file') {
      this.content = {
        primaryFile: context.entity,
      };
    } else {
      this.content = context.entity;
      if (!this.content) {
        // Content might return 404 in case element was just recolored
        this.content = {
          id: context.reference.id,
        };
      }
    }

    const colors = SVGHelper.getColorsInSvg(svgRootElement);
    this.fills = await this.loadColors(colors?.fills);
    console.log(this.fills);

    if (this.svgHtmlString) {
      const size = SVGHelper.getSize(this.svgHtmlString);
      if (size) {
        this.dimensions = `${Math.round(size.width)}x${Math.round(size.height)}`;
      }
    }
    this.createdByName = this.content?.createdBy?.email;
  }

  public applyContentActions(action) {
    console.log('Applying content action', action);
    if (action?.changeType && action?.elementId === this.element.id) {
      switch (action?.changeType) {
        case DocumentContentChangeType.REPLACE_COLOR_SVG:
          const openColorPicker = false;
          const skipUndo = true;
          if (action.svgElementSelectionIds?.length > 0) {
            // Set selection that was being edited
            const svgElementSelection = this.documentService.setSvgElementSelection(
              action.elementId,
              action.svgElementSelectionIds,
            );
            if (svgElementSelection?.length > 0) {
              this.svgElementSelection = svgElementSelection;
            } else {
              break;
            }
          } else {
            this.documentService.clearSvgElementSelection(action.elementId);
            this.svgElementSelection = [];
          }

          this.fills = ObjectUtil.cloneDeep(action.fills);
          const color = this.fills.find(
            (c) => c.origColor.backgroundColor === action.currentColor.origColor.backgroundColor,
          );
          if (color) {
            this.editColor(color, openColorPicker);
            this.handleValueChange(action.currentColor.color, skipUndo);
          }
          break;
        default:
          break;
      }
    }
  }

  public editColor(color, openColorPicker = true) {
    this.currentColor = color; // reference to one of the colors in this.fills
    if (openColorPicker && !this.colorPicker.isOpened) {
      // do not open again if it's already open
      this.colorPicker.toggleDisplay(this.colorTarget, [
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
        },
      ]);
    }
    setTimeout(() => {
      if (!this.colorPicker.tabGroup) {
        return;
      }
      if (
        (this.currentColor?.newColor?.backgroundColor && this.currentColor?.newColor?.name) ||
        this.currentColor?.color?.name
      ) {
        this.colorPicker.tabGroup.selectedIndex = 1;
      } else {
        this.colorPicker.tabGroup.selectedIndex = 0;
      }
    });
  }

  public handleDelayedValueChange(values) {
    this.subject.next(values);
  }

  public handleValueChange(values, skipUndo = false) {
    // For undo/redo:
    // currentColor - the color we are replacing with (specifically currentColor.color)
    // fills - current setup of all colors, including the color we want to replace
    // svgElementSelectionIds - current svg selection. User may press undo/redo after they changes color and then switched selection
    // If we change color A to B:
    // Undo:  currentColor is color A, fills is @this.fills after we update it with @values
    // Redo:  currentColor is color B - we set currentColor.color to @values, and currentColor.newColor to color A (which is the color we are replacing)
    //        fills are original @this.fills before we apply @values here
    const currentColorUndo = ObjectUtil.cloneDeep(this.currentColor);
    const currentColorRedo: IReplaceColor = ObjectUtil.cloneDeep(this.currentColor);
    currentColorRedo.newColor = ObjectUtil.cloneDeep(this.currentColor.color);
    currentColorRedo.color = ObjectUtil.cloneDeep(values);
    const fillsRedo = ObjectUtil.cloneDeep(this.fills);

    const svgElementSelectionIds =
      this.svgElementSelection?.length > 0 ? this.svgElementSelection.map((svgElement) => svgElement.id) : null;
    this.currentColor.newColor = values;
    for (let c of this.fills) {
      if (c.newColor?.backgroundColor) {
        if (this.svgElementSelection.length) {
          SVGHelper.replaceColorInElements(
            this.svgElementSelection,
            c.color.backgroundColor,
            c.newColor.backgroundColor,
          );
          c.color = c.newColor;
        } else {
          SVGHelper.replaceColorInSvg(this.svgRootElement, c.color.backgroundColor, c.newColor.backgroundColor);
          c.color = c.newColor;
        }
      }
    }

    if (!skipUndo) {
      this.undoRedoService.addUndo([
        new DocumentContentAction(
          'content',
          {
            changeType: DocumentContentChangeType.REPLACE_COLOR_SVG,
            elementId: this.element.id,
            fills: fillsRedo,
            currentColor: currentColorRedo,
            svgElementSelectionIds,
          },
          {
            changeType: DocumentContentChangeType.REPLACE_COLOR_SVG,
            elementId: this.element.id,
            fills: ObjectUtil.cloneDeep(this.fills),
            currentColor: currentColorUndo,
            svgElementSelectionIds,
          },
        ),
      ]);
    }

    // this.newSvgHtmlString = svgHtmlString;
    // this.contentEditorService.applyRasterizedSVG(this.element, this.newSvgHtmlString);
    this.detectColorChange();
  }

  private detectColorChange() {
    let noColorChange = true;
    for (let c of this.fills) {
      if (c.newColor?.backgroundColor != null && c.newColor?.backgroundColor != c.origColor?.backgroundColor) {
        noColorChange = false;
        break;
      }
    }

    this.contentEditorService.setIsDirty(!noColorChange);
  }

  public hide(closeContentOptions = true): boolean {
    if (this.contentEditorService.isDirty) {
      this.save();
    }

    if (closeContentOptions) {
      this.contentEditorService.hide();
    }

    this.documentService.handleDocumentSvgElementEvent({
      element: this.element,
      eventType: 'contentEditorClosed',
    });

    return true;
  }

  public async save() {
    this.fills = this.fills.map((c) => {
      if (c.newColor?.backgroundColor) {
        c.color.backgroundColor = c.newColor.backgroundColor;
        c.color.name = c.newColor.name;

        c.origColor.backgroundColor = c.color.backgroundColor;
        c.origColor.name = c.color.name;
      }
      c.newColor = null;
      return c;
    });

    this.contentEditorService.setIsDirty(false);

    this.documentService.handleDocumentSvgElementEvent({
      element: this.element,
      eventType: 'clearSelection',
    });

    this.svgHtmlString = this.svgRootElement.outerHTML;

    const element = await this.documentService.fileHandler.handleUpdateContentElement(
      this.element,
      this.svgRootElement.outerHTML,
      this.content.id,
    );
    if (element && element?.modelBindings?.item) {
      const itemId = element.modelBindings.item.split(':')[1];
      const imageElement = element.elements.find((e) => e.type === 'image');
      const originalFile = imageElement.alternateUrls.originalFile;
      const highResolution = imageElement.alternateUrls.highResolution;
      const object = {
        largeViewableDownloadUrl: highResolution,
        mediumLargeViewableDownloadUrl: highResolution,
        mediumViewableDownloadUrl: highResolution,
        smallViewableDownloadUrl: highResolution,
        tinyViewableDownloadUrl: highResolution,
        primaryFileUrl: originalFile,
      };
      // Update backing assortment so item has correct latest properties
      this.store.dispatch(AssortmentsActions.syncBackingAssortmentItems({ id: itemId, changes: object }));
    }
  }

  public restore() {
    this.fills = this.fills.map((c) => {
      c.newColor = null;
      c.color = c.origColor;
      return c;
    });

    this.contentEditorService.setIsDirty(false);

    this.newSvgHtmlString = this.svgHtmlString;
    // this.contentEditorService.applyOriginalSVG(this.element);

    this.documentService.handleDocumentSvgElementEvent({
      element: this.element,
      eventType: 'contentEditorRestore',
      svgHtmlString: this.svgHtmlString,
    });
  }

  public download() {
    this.contentService.downloadContent(this.content);
  }

  private findColor(color: string) {
    return this.fills?.find((c) => c.color?.backgroundColor === color);
  }

  private async loadColors(colors: string[]): Promise<Array<IReplaceColor>> {
    const promises = [];
    for (let i = 0; i < colors?.length; i++) {
      const color = colors[i];
      const promise = limit(async () => {
        return {
          color: {
            backgroundColor: color,
            name: this.findColor(color)?.color?.name || (await ColorService.getColor(color))?.name,
          },
          origColor: {
            backgroundColor: color,
            name: this.findColor(color)?.color?.name || (await ColorService.getColor(color))?.name,
          },
          newColor: null,
        };
      });
      promises.push(promise);
    }

    return await Promise.all(promises).catch(() => {
      return colors?.map((color) => ({
        color: {
          backgroundColor: color,
          name: null,
        },
        origColor: {
          backgroundColor: color,
          name: null,
        },
        newColor: null,
      }));
    });
  }

  highlightColorInSvg(color: IReplaceColor) {
    // If we have any elements selected, don't do any additional highlighting
    if (this.svgElementSelection.length > 0) return;

    const elements = SVGHelper.getChildElementsWithColor(this.svgRootElement, color.color.backgroundColor);

    let elementIds: string[] = [];
    for (let i = 0; i < elements.length; i++) {
      elementIds.push(elements[i].id);
    }
    this.documentService.setSvgElementHighlights(this.element.id, elementIds);

    this.currentHighlightedColors.push(color);
  }

  clearHighlightedColorInSvg(color: IReplaceColor) {
    let colorIndex = this.currentHighlightedColors.indexOf(color);
    if (colorIndex >= 0) {
      this.currentHighlightedColors.splice(colorIndex, 1);
    }

    if (!this.currentHighlightedColors.length) {
      this.documentService.clearSvgElementHighlights(this.element.id);
    }
  }
}
