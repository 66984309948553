import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AppExtensionModalLauncherComponent,
  SafeIFramePipe,
} from './app-extension-modal-launcher/app-extension-model-launcher.component';

@NgModule({
  declarations: [AppExtensionModalLauncherComponent, SafeIFramePipe],
  imports: [CommonModule],
})
export class AppExtensionsModule {}
