<div class="message">Please use keyboard shortcuts to paste:</div>
<div class="details">
  <div class="shortcut">
    <div class="key">
      <span>Ctrl+V</span>
    </div>
    for Windows
  </div>

  <div class="shortcut">
    <div class="key">
      <span> <mat-icon>keyboard_command_key</mat-icon>+V </span>
    </div>
    for Mac
  </div>
</div>
