import { EntityReference } from '@contrail/sdk';
import { Item } from '../common/assortments/item';
import { Presentation } from './presentation';
export class PresentationItemExtractor {
  constructor() {}

  public static extractItemsFromPresentation(presentation: Presentation) {
    const items: Array<Item> = [];
    for (const frame of presentation.frames) {
      if (frame?.collection) {
        items.push(...this.getItemReferencesFromCollectionSet(frame.collection.set));
      } else if (frame?.document) {
        items.push(...this.getItemReferencesFromElements(frame.document.elements));
      }
    }
    return items;
  }

  public static getItemReferencesFromElements(elements) {
    if (!elements) {
      return;
    }
    const itemReferences: Array<Item> = elements.reduce((items: Array<Item>, el) => {
      if (!el?.modelBindings) {
        return items;
      }
      const bindings = el.modelBindings;
      if (!bindings?.item) {
        return items;
      }
      const itemEles =
        el.elements?.filter(
          (element) =>
            element.propertyBindings.text === 'item.name' || element.propertyBindings.text === 'item.optionName',
        ) || [];

      const itemName = itemEles
        .map((ele) => {
          return ele.text;
        })
        .join('-');
      items.push({ ...new EntityReference('' + bindings.item), name: itemName });
      return items;
    }, []);
    return itemReferences || [];
  }

  public static getItemReferencesFromCollectionSet(collectionSet) {
    if (!collectionSet) {
      return;
    }
    const itemReferences: Array<Item> = collectionSet.reduce((items: Array<Item>, set) => {
      if (!set?.children) {
        return items;
      }
      if (!set.enabled) {
        return items;
      }
      if (set.enabled === true) {
        items.push({ ...new EntityReference('item:' + set.value), name: set.label });
      }
      for (const child of set.children) {
        if (child.enabled === true) {
          items.push({ ...new EntityReference('item:' + child.value), name: child.label });
        }
      }
      return items;
    }, []);
    return itemReferences || [];
  }
}
