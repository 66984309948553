<div class="widgets-list">
  <button data-test="composer-toolbar-add-section" mat-button matTooltip="Add section" (click)="addSection()">
    <mat-icon svgIcon="add_section"></mat-icon>
    <div>Add section</div>
  </button>
  <!--
    <button *ngIf="(gridFrameActiveContainer$ | async)" data-test='composer-toolbar-add-sub-section' mat-button matTooltip="Add sub-section" (click)="addSubSection()">
      <mat-icon svgIcon="add_subsection"></mat-icon>
      <div>Add sub-section</div>
    </button>
  -->
</div>
