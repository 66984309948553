import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { DocumentSelectors } from '../../document-store';
import { DocumentViewSize } from '../../document-store/document.state';
import { DocumentService } from '../../document.service';
import { CanvasUtil } from 'src/app/presentation/canvas-lib';
@Injectable({
  providedIn: 'root',
})
export class ClipboardActionsDispatchers {
  private viewSize: DocumentViewSize;
  constructor(
    private store: Store<RootStoreState.State>,
    private documentService: DocumentService,
  ) {
    this.store.select(DocumentSelectors.viewSize).subscribe((viewSize) => {
      this.viewSize = viewSize;
    });
    this.documentService.actionRequests.subscribe((request) => {
      if (request?.actionType === 'copy_elements') {
        this.documentService.documentClipboard.copySelectedElementsToClipboard();
      } else if (request?.actionType === 'duplicate_elements') {
        this.documentService.documentClipboard.copySelectedElementsToClipboard();
        this.documentService.documentClipboard.pasteDocumentElementsFromClipboard();
      } else if (request?.actionType === 'paste_elements' || request?.actionType === 'paste_as_svg') {
        this.documentService.documentClipboard.dispatchPasteEvent(
          {
            position: CanvasUtil.toDocumentPosition(
              request.sourceEvent.sourceMouseEvent.clientX,
              request.sourceEvent.sourceMouseEvent.clientY,
              this.viewSize.viewBox,
              this.viewSize.viewScale,
              this.viewSize.boundingClientRect,
            ),
          },
          request?.actionType === 'paste_as_svg',
        );
      }
    });
  }
}
