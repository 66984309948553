import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-chooser-entity-card',
  templateUrl: './chooser-entity-card.component.html',
  styleUrls: ['./chooser-entity-card.component.scss'],
})
export class ChooserEntityCardComponent implements OnInit {
  @Input() entity: any;
  @Input() entityType: any;
  @Input() allowAddMultiple = false;
  public name: string;
  public image: string;
  @Output() entityClicked = new EventEmitter();
  constructor() {}

  ngOnInit(): void {
    if (this.entity) {
      this.name = this.entity.name || this.entity.fileName;
      if (this.entity.entityType === 'workspace') {
        this.image = 'assets/images/folder_icon.svg';
      } else {
        this.image = this.entity.thumbnail || this.entity.smallViewableDownloadUrl || this.entity.smallViewableUrl;
      }
    }
  }

  handleClick() {
    this.entityClicked.emit(this.entity);
  }
}
