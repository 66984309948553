import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
@Component({
  selector: 'app-showcase-analytics-dashboard',
  templateUrl: './showcase-analytics-dashboard.component.html',
  styleUrls: ['./showcase-analytics-dashboard.component.scss'],
})
export class ShowcaseAnalyticsDashboardComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
