import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ObjectUtil } from '@contrail/util';
import { PresentationFrame } from 'src/app/presentation/presentation';

@Component({
  selector: 'app-composer-showroom-frame-preview',
  templateUrl: './composer-showroom-frame-preview.component.html',
  styleUrls: ['./composer-showroom-frame-preview.component.scss'],
})
export class ComposerShowroomFramePreviewComponent implements OnInit {
  @Input() frame: PresentationFrame;
  public label;
  public itemCount = 0;
  public optionCount = 0;
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.frame = ObjectUtil.cloneDeep(changes.frame.currentValue);
    this.setValues();
  }

  async ngOnInit() {
    this.setValues();
  }

  private setValues() {}
}
