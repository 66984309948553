import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, Pipe, PipeTransform } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthSelectors } from '@common/auth/auth-store';
import { AuthService } from '@common/auth/auth.service';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { filter, take, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppExtensionMessageHandler } from '../app-extension-message-handler.interface';

@Pipe({ name: 'safeIframeURL' })
export class SafeIFramePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-app-extension-modal-launcher',
  templateUrl: './app-extension-modal-launcher.component.html',
  styleUrls: ['./app-extension-modal-launcher.component.scss'],
})
export class AppExtensionModalLauncherComponent implements OnInit, OnDestroy {
  public loading = true;
  public iframeUrl;
  @Output() closeWidget = new EventEmitter();
  public selectedIds: Array<any>;
  public iframeData: any;
  public authToken: string;
  public authOrg: string;
  public user;
  private messageHandler: AppExtensionMessageHandler;
  private messageListener: any;

  constructor(
    private store: Store<RootStoreState.State>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
  ) {}

  async ngOnInit() {
    this.authToken = await this.authService.getToken();
    this.store
      .select(AuthSelectors.selectAuthContext)
      .pipe(
        filter((res) => res !== null),
        take(1),
        tap((context) => {
          this.authOrg = context.currentOrg?.orgSlug;
          this.user = context.user;
        }),
      )
      .subscribe();

    // Bind to the message handler provided on launch
    this.messageHandler = this.data.messageHandler;

    /** Once the web app has loaded into the iframe, we can securely send the authentication information */
    document.getElementById('localFrame').onload = () => {
      this.loading = false;

      // We likely want to change the flow here so that the launched window sends a message
      // to let us know its loaded, so that we can gaurantee that the app has loaded before
      // sending the contextual information
      setTimeout(() => {
        this.initFrameConfig();
      }, 200);
      this.messageListener = this.handleChildFrameMessage.bind(this);
      window.addEventListener('message', this.messageListener);
    };
    this.iframeUrl = this.data.app?.iframeUrl;
  }

  handleChildFrameMessage(event) {
    console.log('event:', event);
    console.log('window.location:', window.location);
    console.log("' ' + window.location:", '' + window.location);
    // Ignore messages coming from the local window
    console.log('event:', event);
    console.log('window.location:', window.location);
    console.log("' ' + window.location:", '' + window.location);
    // Ignore messages coming from the local window
    if (('' + window.location).indexOf(event.origin) > -1 && ('' + window.location).indexOf('localhost')) {
      return;
    }
    console.log('XXX Recived message from embedded iframe: ', event.origin, window.location);
    const type = event.type;
    const data = event.data;
    this.messageHandler.handleMessage(event.data);
  }

  ngOnDestroy(): void {
    console.log('onDestroy.. removing listener');
    window.removeEventListener('message', this.messageListener);
  }

  initFrameConfig() {
    const frameContentWindow = (document.getElementById('localFrame') as any).contentWindow;
    const url = this.iframeUrl;

    //console.log("intiFrameConfig: ", this.data)
    const body = {
      context: {
        appContext: this.data?.context,
        authToken: this.authToken,
        authOrg: this.authOrg,
        user: this.user,
        apiGateway: environment.thumbnailBaseUrl || 'https://api.vibeiq.com/prod/api',
      },
    };
    console.log('initFrameConfig: message: ', body);
    frameContentWindow.postMessage(body, url);
  }
}
