<div class="border border-solid border-neutral-200 flex items-center justify-around w-[112px] h-[25px] mx-2.5">
  <div class="flex flex-row items-center content-center">
    <button mat-icon-button [matMenuTriggerFor]="markerStartMenu" matTooltip="Line start">
      <span class="text-xs font-normal" *ngIf="!markerStart">None</span
      ><mat-icon *ngIf="markerStart" class="rotate-180">{{ getActiveIcon(markerStart) }}</mat-icon>
    </button>
  </div>

  <button class="!w-4 !h-4" (click)="swapMarkers()" mat-icon-button matTooltip="Swap">
    <mat-icon class="!w-4 !h-4 !text-base">cached</mat-icon>
  </button>

  <div class="flex flex-row items-center content-center">
    <button mat-icon-button [matMenuTriggerFor]="markerEndMenu" matTooltip="Line end">
      <span class="text-xs font-normal" *ngIf="!markerEnd">None</span
      ><mat-icon *ngIf="markerEnd">{{ getActiveIcon(markerEnd) }}</mat-icon>
    </button>
  </div>

  <mat-menu #markerStartMenu="matMenu">
    <div class="relative bg-white border-none min-w-[100px]">
      <ng-container *ngFor="let marker of markers">
        <button mat-menu-item (click)="setMarkerStart(marker.value)">
          <span class="text-black60" *ngIf="marker.label">{{ marker.label }}</span
          ><mat-icon *ngIf="marker.icon" class="!mr-3 rotate-180">{{ marker.icon }}</mat-icon>
          <mat-icon class="!ml-1.5" *ngIf="isMatch(marker.value, markerStart)">done</mat-icon>
        </button>
      </ng-container>
    </div>
  </mat-menu>

  <mat-menu #markerEndMenu="matMenu">
    <div class="relative bg-white border-none min-w-[100px]">
      <ng-container *ngFor="let marker of markers">
        <button mat-menu-item (click)="setMarkerEnd(marker.value)">
          <span class="text-black60" *ngIf="marker.label">{{ marker.label }}</span
          ><mat-icon *ngIf="marker.icon" class="!mr-3">{{ marker.icon }}</mat-icon>
          <mat-icon class="!ml-1.5" *ngIf="isMatch(marker.value, markerEnd)">done</mat-icon>
        </button>
      </ng-container>
    </div>
  </mat-menu>
</div>
