import { Component, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DownloadService } from '../download.service';

@Component({
  selector: 'app-download-content-indicator',
  templateUrl: './download-content-indicator.component.html',
  styleUrls: ['./download-content-indicator.component.scss'],
})
export class DownloadContentIndicatorComponent implements OnInit {
  private PDF_GEN_TIMEOUT_MESSAGE = 'Error while exporting data. Please try again.';

  public numberOfPendingJobs = 0;

  constructor(
    private downloadService: DownloadService,
    private snackBar: MatSnackBar,
  ) {
    // reset the completed jobs
    this.downloadService.resetJobQueue();
  }

  ngOnInit(): void {
    this.downloadService.pendingJobsObservable.subscribe((data) => {
      if (!data) {
        this.numberOfPendingJobs = 0;
        return;
      }
      console.log(`download service has received ${JSON.stringify(data)}  - ${data?.length} job ids`);
      this.numberOfPendingJobs = data?.length;
    });

    this.downloadService.completedJobsObservable$.subscribe((data) => {
      if (!data?.path) {
        return;
      } else {
        // window.open(data[0].path, '_blank)
        const path = data.path;
        if (path instanceof Blob) {
          const url = URL.createObjectURL(path);
          this.download(url, data.name);
          setTimeout(() => {
            URL.revokeObjectURL(url);
          }, 1000);
        } else {
          this.openInNewTab(path);
        }
      }
    });

    this.downloadService.errorObservable$.subscribe((data) => {
      if (!data) {
        return;
      } else {
        this.openSnackBar(this.PDF_GEN_TIMEOUT_MESSAGE, 'Error');
      }
    });
  }

  private download(data, name) {
    const link = document.createElement('a');
    link.download = `${name ?? 'export'}.pdf`;
    link.href = data;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private openInNewTab(data) {
    window.open(data, '_blank');
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
