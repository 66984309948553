<div class="configurator" data-test="composer-frame-settings">
  <div class="header">
    <div class="title">Frame Options</div>
    <div>
      <mat-icon (click)="hide()">close</mat-icon>
    </div>
  </div>
  <div class="accordion">
    <mat-expansion-panel class="mat-elevation-z0" expanded>
      <mat-expansion-panel-header>
        <mat-panel-title> Frame Settings </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="settings">
        <span class="label">Visibilty</span>
        <app-composer-frame-toolbar-visibility></app-composer-frame-toolbar-visibility>
      </div>
    </mat-expansion-panel>
    <mat-divider></mat-divider>
    <mat-expansion-panel class="mat-elevation-z0" expanded>
      <mat-expansion-panel-header>
        <mat-panel-title> Frame Background </mat-panel-title>
      </mat-expansion-panel-header>
      <app-composer-frame-toolbar-backgroundcolor
        class="backgroundcolor-icon"
        #backgroundColorPicker
        [matIconType]="'format_color_fill'"
        [colorType]="'backgroundColor'"
      >
      </app-composer-frame-toolbar-backgroundcolor>
    </mat-expansion-panel>
  </div>
</div>
