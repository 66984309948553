import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import { debounce } from 'rxjs/operators';
interface DashboardConfg {
  startDate?: string;
  endDate?: string;
}
@Injectable({
  providedIn: 'root',
})
export class ShowcaseAnalyticsDashboardService {
  private dashboardConfigObject: DashboardConfg = {
    startDate: moment().subtract(4, 'week').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  };
  private dashboardConfigSubject: Subject<DashboardConfg> = new BehaviorSubject(this.dashboardConfigObject);
  public dashboardConfig: Observable<DashboardConfg> = this.dashboardConfigSubject
    .asObservable()
    .pipe(debounce(() => interval(1000)));
  constructor() {}

  updateDashboardConfig(obj: DashboardConfg) {
    console.log('updateDashboardConfig: ', obj);
    let changeDetected = false;
    for (const key of Object.keys(obj)) {
      if (this.dashboardConfigObject[key] !== obj[key]) {
        changeDetected = true;
        console.log('changeDetected', key, this.dashboardConfigObject[key], obj[key]);
      }
    }
    if (changeDetected) {
      this.dashboardConfigObject = Object.assign(this.dashboardConfigObject, obj);
      this.dashboardConfigSubject.next(this.dashboardConfigObject);
    }
  }

  getDashboardConfig(): DashboardConfg {
    return this.dashboardConfigObject;
  }
}
