import { Injectable } from '@angular/core';
import { ViewDefinition } from '@contrail/client-views';
import { Entities, Types } from '@contrail/sdk';
import { ObjectUtil } from '@contrail/util';
import { AuthContext, AuthService } from '../auth/auth.service';
import { VIEWS } from './view-definitions/views';

@Injectable({
  providedIn: 'root',
})
export class ViewManagerService {
  private viewMap: Map<string, any> = new Map();

  constructor(private authService: AuthService) {
    this.authService.authContext.subscribe((ctx) => {
      this.initViewMap();
    });
  }

  private async initViewMap() {
    // Defaults
    await this.loadViewMap(VIEWS);

    // Load `presets` and overrides defaults if exists
    // if multiple presets are defined, the last one will override the previous ones
    const viewTypes = ['showcase:item_details', 'showcase:item_card'];
    viewTypes.forEach((applicationViewSlug) => {
      this.loadViewDefinitions(applicationViewSlug);
    });
  }

  private async loadViewDefinitions(applicationViewSlug: string) {
    const views = await this.getViewDefinitions({ applicationViewSlug }); // `/view-definition` api call
    await this.loadViewMap(views);
  }

  private async loadViewMap(views: Array<ViewDefinition>) {
    await this.hydrateViewDefinitions(views);
    views.forEach((view) => {
      let key = view.applicationViewSlug;
      if (view.typePath) {
        key += '_' + view.typePath;
      }
      this.viewMap.set(key, view);
    });
  }

  private async hydrateViewDefinitions(views: Array<ViewDefinition>) {
    views.forEach(async (view) => {
      if (view.hydrated) {
        return;
      }
      view.properties.forEach(async (viewProp) => {
        const root = viewProp.typeRootSlug;
        let path = root;
        if (root && view.typePath?.startsWith(root)) {
          path = view.typePath;
        }
        const type = await new Types().getType({ root, path });
        const property = type.typeProperties.find((p) => p.slug === viewProp.slug);
        viewProp.propertyDefinition = property;
      });
      view.hydrated = true;
      console.log('Hydrated view:', view);
    });
  }

  async getView(viewSlug: string, typeId = null, contextReference = null) {
    console.log('getView: ', { viewSlug, typeId, contextReference });
    let view;
    let viewKey = viewSlug;
    if (typeId) {
      const type = await new Types().getType({ id: typeId });
      console.log('getView: type', type?.typePath);
      viewKey = viewKey + '_' + type.typePath;
    }
    if (contextReference) {
      viewKey = viewKey + '_' + contextReference;
    }
    view = this.viewMap.get(viewKey);
    if (!view) {
      //    ----------------------- >>>
      // I can't find the use case for typeId in getViewDefinitions' criteria
      // Why do we need to add type.typePath to viewKey ???
      const tempViewKey = viewSlug + (contextReference ? '_' + contextReference : '');
      const checkView = this.viewMap.get(tempViewKey);
      if (checkView) {
        view = checkView;
        this.viewMap.set(viewKey, view);
        return view;
      }
      //   ------------------------ <<<
      const views = await this.getViewDefinitions({ applicationViewSlug: viewSlug, contextReference });

      if (views?.length) {
        view = views[0];
        this.viewMap.set(viewKey, views[0]);
      } else {
        console.log('======== No view found for:', viewKey);
        this.viewMap.set(viewKey, {});
      }
    }

    // Fall back, or no type
    if (!view) {
      view = this.viewMap.get(viewSlug);
    }
    return view;
  }

  async getViewDefinitions(criteria): Promise<ViewDefinition[]> {
    // api call
    const viewDefinition = await new Entities().get({ entityName: 'view-definition', criteria });
    let views = [];

    if (Array.isArray(viewDefinition)) {
      views = viewDefinition;
    } else if (viewDefinition?.viewDefinitions) {
      views = viewDefinition.viewDefinitions;
    } else if (viewDefinition?.viewDefinitionsURL) {
      const response = await fetch(viewDefinition.viewDefinitionsURL);
      const viewDefinitions = await response.json();
      views = viewDefinitions;
    }

    return views;
  }

  async createViewDefinition(viewDefinition) {
    const view = await new Entities().create({ entityName: 'view-definition', object: viewDefinition });
    const key = view.applicationViewSlug + (view.contextReference ? '_' + view.contextReference : '');
    const clonedView = ObjectUtil.cloneDeep(view);
    await this.hydrateViewDefinitions([clonedView]);
    this.viewMap.set(key, clonedView);
    return view;
  }

  async updateViewDefinition(id, changes) {
    const updatedView = await new Entities().update({ entityName: 'view-definition', id, object: changes });
    const clonedUpdatedView = ObjectUtil.cloneDeep(updatedView);
    await this.hydrateViewDefinitions([clonedUpdatedView]);
    for (const [viewKey, view] of this.viewMap.entries()) {
      if (view.id === id) {
        this.viewMap.set(viewKey, clonedUpdatedView);
      }
    }
    return updatedView;
  }

  async deleteViewDefinition(id) {
    await new Entities().delete({ entityName: 'view-definition', id });
    for (const [viewKey, view] of this.viewMap.entries()) {
      if (view.id === id) {
        this.viewMap.delete(viewKey);
      }
    }
  }
}
