<app-modal [title]="'New embed frame'" [dialogRef]="dialogRef">
  <div class="mb-4">Embed any website or application that supports iframes.</div>
  <mat-form-field class="w-full">
    <mat-label>Frame name</mat-label>
    <input matInput #name type="test" [formControl]="nameControl" />
  </mat-form-field>
  <mat-form-field class="w-full">
    <mat-label>Paste embed code</mat-label>
    <input matInput #url type="url" [formControl]="urlControl" />
    <mat-error *ngIf="error"> Please enter a valid embed code </mat-error>
  </mat-form-field>
  <div class="flex justify-end">
    <div class="flex justify-between mt-12">
      <div>
        <button mat-flat-button (click)="cancel()">CANCEL</button>
        <button [disabled]="!urlControl.value" mat-flat-button color="primary" (click)="create()">OK</button>
      </div>
    </div>
  </div>
</app-modal>
