import { PhotoDome, Scene, Vector3, Animation, MeshBuilder, StandardMaterial, Observable } from '@babylonjs/core';
import { EntityHotspot360, BaseHotspot360, LocationHotspot360 } from './hotspot-360';
import { Hotspot360Definition, Location360Definition } from './360-interfaces';

export class Location360 {
  public scene: Scene;

  public photoDome: PhotoDome;
  public domeRadius: number;
  public locationName: string;
  public hotspotArray: Array<BaseHotspot360> = [];

  public location360Obs = new Observable();

  constructor(
    imageUrl: string,
    domeRadius: number,
    scene: Scene,
    startInvisible: boolean = false,
    locationDef?: Location360Definition,
  ) {
    this.scene = scene;
    this.domeRadius = domeRadius;

    let dome = new PhotoDome(
      '',
      imageUrl,
      {
        resolution: 64,
        size: this.domeRadius * 2,
      },
      scene,
    );

    // Initialize dome rotation such that center of image lies along the forward Z axis
    dome.rotation = new Vector3(0, Math.PI / 2, 0);

    // Initialize dome to be invisible so it can be animated in
    this.photoDome = dome;

    if (locationDef) {
      this.locationName = locationDef.refName;

      for (let i = 0; i < locationDef.hotspotArray.length; i++) {
        let hotspotDef = locationDef.hotspotArray[i];
        let hotspot = this.createHotSpot(hotspotDef);
        this.hotspotArray.push(hotspot);
      }
    }

    if (startInvisible) {
      dome.mesh.material.alpha = 0;
    } else {
      for (let i = 0; i < this.hotspotArray.length; i++) {
        this.hotspotArray[i].enableHotspot(false);
      }
    }
  }

  public isVisible(): boolean {
    return this.photoDome.mesh.material.alpha == 1;
  }

  public fadeIn(fadeOut?: boolean): void {
    let start = 0;
    let end = 1;

    if (fadeOut) {
      start = 1;
      end = 0;
    }

    // If we're already at the desired visibility don't animate
    if (!!end == this.isVisible()) {
      return;
    }

    Animation.CreateAndStartAnimation(
      this.locationName + 'fade',
      this.photoDome.mesh,
      'material.alpha',
      60,
      45,
      start,
      end,
      0,
    );

    for (let i = 0; i < this.hotspotArray.length; i++) {
      this.hotspotArray[i].enableHotspot(fadeOut);
    }
  }

  public fadeOut(): void {
    this.fadeIn(true);
  }

  protected createHotSpot(hotspotDef: Hotspot360Definition): BaseHotspot360 {
    let bounds = hotspotDef.hotspotBounds;

    let originDir = new Vector3(bounds.originDirection.y, bounds.originDirection.z, bounds.originDirection.x);
    let boxSize = new Vector3(bounds.size.y, bounds.size.z, bounds.size.x);
    let authoredDistance = bounds.distance;

    // Unsure why distance proportion needs to be calculated using dome diameter instead of radius
    let newSize = boxSize.scale(1 / authoredDistance).scale(this.domeRadius * 0.9 * 2); // Multiply by 0.9 to prevent clipping with dome

    // Make sure to just use babylon axes here, the unreal axes have already been translated
    const boxOptions = {
      height: newSize.y,
      width: newSize.x,
      depth: newSize.z,
    };

    let box = MeshBuilder.CreateBox('box', boxOptions);
    box.setAbsolutePosition(originDir.scale(this.domeRadius * 0.9)); // Multiply radius by 0.9 to keep boxes from clipping
    box.material = new StandardMaterial('', this.scene);
    box.material.wireframe = true;
    box.visibility = 0;
    box.isVisible = false;

    let hotspot: LocationHotspot360 | EntityHotspot360;
    if (hotspotDef.refName) {
      hotspot = new LocationHotspot360(hotspotDef.refName, box, this.scene);
    } else if (hotspotDef.entityReference) {
      hotspot = new EntityHotspot360(hotspotDef.entityReference, box, this.scene);
    }

    hotspot.hotspotObs.add((eventData: any, eventState) => {
      this.location360Obs.notifyObservers(eventData);
    });

    return hotspot;
  }

  public clear() {
    this.location360Obs.clear();
  }
}
