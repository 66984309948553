<div data-test="assortment-history-panel" class="container">
  <div class="header" *ngIf="history$ | async">
    <div class="title">
      Assortment History: {{ assortment?.name }}
      <button
        data-test="publish-history-button"
        mat-button
        (click)="setView('publishHistory')"
        matTooltip="Change History"
      >
        <mat-icon>change_history</mat-icon>
      </button>
      <button
        data-test="aggregate-history-button"
        mat-button
        (click)="setView('aggregateHistory')"
        matTooltip="Metrics History"
      >
        <mat-icon>timeline</mat-icon>
      </button>
    </div>
    <div class="actions">
      <mat-icon (click)="close()">close</mat-icon>
    </div>
  </div>
  <div class="publish-history" *ngIf="view === 'publishHistory'">
    <div class="history-list">
      <app-assortment-history-item
        *ngFor="let historyItem of history$ | async"
        (click)="selectHistoryItem(historyItem)"
        [assortmentHistoryItem]="historyItem"
      >
      </app-assortment-history-item>
    </div>

    <div *ngIf="!loading" data-test="assortment-history-detail-list" class="history-detail">
      <app-assortment-history-detail
        [assortmentHistoryItem]="selectedHistoryItem"
        [assortment]="assortment"
      ></app-assortment-history-detail>
    </div>
    <div *ngIf="loading" class="history-detail flex w-full justify-center items-center">
      <app-mat-spinner [message]="'Loading Detail'" [overlay]="false" [loading]="true"></app-mat-spinner>
    </div>
  </div>
  <div class="aggregate-history" *ngIf="view === 'aggregateHistory'">
    <app-assortment-aggregate-history
      [assortment]="assortment"
      [history]="history$ | async"
    ></app-assortment-aggregate-history>
  </div>
</div>
