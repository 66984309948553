import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { ShowcasePresentationConfiguration } from './presentation-configuration';

@Injectable({
  providedIn: 'root',
})
export class ShowcasePresentationService {
  public assortment: any;
  private showcase;
  public itemOutlineProperty = 'collection';
  private presentationConfg: ShowcasePresentationConfiguration;
  private showcasePresentation;
  constructor(private store: Store<RootStoreState.State>) {
    this.store.select(ShowcasesSelectors.currentShowcase).subscribe((showcase) => {
      if (showcase && showcase.id !== this.showcase?.id) {
        this.showcase = showcase;
      }
      this.presentationConfg = null;
      this.showcasePresentation = null;
      this.assortment = null;
    });
  }
  async initialize() {
    if (!this.showcase) {
      return null;
    }
    if (this.presentationConfg) {
      return this.presentationConfg;
    }
    this.presentationConfg = null;
    this.showcasePresentation = null;
    console.log('Presentation Service: ', this.showcase.id, this.showcase.sourceAssortmentId);
    if (this.showcase.sourceAssortmentId) {
      this.assortment = await new Entities().get({
        entityName: 'assortment',
        id: this.showcase.sourceAssortmentId,
        relations: ['items', 'itemColors'],
        cacheMode: 'USE',
      });
      console.log('assortment: ', this.assortment);
      this.presentationConfg = await this.loadShowcasePresentationConfig();
    }
    return this.presentationConfg;
  }

  getPresentationConfig(): ShowcasePresentationConfiguration {
    return this.presentationConfg;
  }

  async loadShowcasePresentationConfig(): Promise<ShowcasePresentationConfiguration> {
    let presentationConfig: ShowcasePresentationConfiguration;

    // THERE WAS A SAVED PRESENTATION, USE THIS ONE.
    if (this.showcase.showcasePresentations && this.showcase.showcasePresentations.length) {
      console.log('Using existing showcase presentation: ', this.showcasePresentation);
      this.showcasePresentation = this.showcase.showcasePresentations[0];
      presentationConfig = Object.assign(
        new ShowcasePresentationConfiguration(this.assortment),
        this.showcasePresentation.configuration,
      );

      // NO PRESENTATION EXISTS YET, CREATE ONE AND INITIALIZE
    } else if (this.assortment) {
      console.log('Creating new showcase presentation');
      // Create showcasePresentation
      this.showcasePresentation = await new Entities().create({
        entityName: 'showcase',
        id: this.showcase.id,
        relation: 'showcase-presentations',
        object: {},
      });
      presentationConfig = Object.assign(new ShowcasePresentationConfiguration(this.assortment));
    }

    if (!presentationConfig.outline?.length) {
      presentationConfig.itemDisplayProperties = ['name', 'atts.division'];
      presentationConfig.itemColorDisplayProperties = ['atts.colorCode', 'name'];
      presentationConfig.itemOutlineProperty = this.itemOutlineProperty;
      presentationConfig.initialize();
    }
    return presentationConfig;
  }

  async save() {
    this.showcasePresentation.configuration = {
      itemOutlineProperty: this.itemOutlineProperty,
      outline: this.presentationConfg.outline,
    };
    await new Entities().update({
      entityName: 'showcase',
      id: this.showcase.id,
      relation: 'showcase-presentations',
      relationId: this.showcasePresentation.id,
      object: this.showcasePresentation,
    });
  }
}
