<ng-container *ngIf="imageURL || imageBlob">
  <!-- [style.transform]="transform" -->
  <image-cropper
    [class.opacity-0]="!imageReady"
    [imageFile]="imageBlob"
    [imageURL]="imageURL"
    [cropper]="cropper"
    [maintainAspectRatio]="false"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded($event)"
    (cropperReady)="cropperReady($event)"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>
</ng-container>

<app-mat-spinner *ngIf="!imageReady" [loading]="!imageReady" [overlay]="true" [message]="'Image Loading ...'">
</app-mat-spinner>
