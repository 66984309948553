import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { ShowcasesActions, ShowcasesSelectors } from '../showcases/showcases-store';
import { Subject, combineLatest, takeUntil, filter } from 'rxjs';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
import { ShowcasesService } from '../showcases/showcases.service';
import { DocumentHistorySelectors } from '@common/document-history/document-history-store';
import { FrameTemplatesService } from '@common/frame-templates/frame-templates.service';
import { CustomFontsActions, CustomFontsSelectors } from '@common/custom-fonts/custom-fonts-store';
import { ClipboardActions } from '@common/clipboard/clipboard-store';

@Component({
  selector: 'app-showcase-details',
  templateUrl: './showcase-details.component.html',
  styleUrls: ['./showcase-details.component.scss'],
})
export class ShowcaseDetailsComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  showcase: any;
  loaded = false;
  navOptions: any;
  currentEntitySnapshot: any;
  constructor(
    private route: ActivatedRoute,
    private store: Store<RootStoreState.State>,
    private titleService: Title,
    private analyticsService: AnalyticsService,
    private showcasesService: ShowcasesService,
    private frameTemplatesService: FrameTemplatesService,
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.store.select(ShowcasesSelectors.currentShowcase),
      this.store.select(EditorModeSelectors.editorMode),
      this.store.select(DocumentHistorySelectors.currentEntitySnapshot),
      this.store.select(CustomFontsSelectors.customFontsLoaded),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([showcase, editorMode, currentEntitySnapshot, customFontsLoaded]: [any, any, any, any]) => {
        if (!showcase || !customFontsLoaded) {
          return;
        }
        this.showcase = showcase;
        this.analyticsService.emitEvent({
          eventName: 'SHOWCASE_ACCESS',
          eventCategory: 'ACCESS',
          eventContextProjectId: showcase.rootWorkspaceId,
          eventContextWorkspaceId: showcase.workspaceId,
          eventLabel: `Showcase ${showcase.name}`,
          eventTarget: `showcase:${showcase.id}`,
          eventContext: `showcase:${showcase.id}`,
        });
        if (showcase.id) {
          if (editorMode === 'VIEW' && !this.currentEntitySnapshot && !currentEntitySnapshot) {
            // make sure user is not merely looking at a snapshot
            this.showcasesService.openShowcaseViewer(this.showcase, false);
          } else {
            this.loaded = true;
            this.titleService.setTitle(showcase.name);
          }
          this.currentEntitySnapshot = currentEntitySnapshot;
        } else {
          this.loaded = false;
        }
      });

    this.route.params.subscribe((params) => {
      const showcaseId = params.id;
      this.store.dispatch(ShowcasesActions.loadCurrentShowcase({ id: showcaseId }));
      this.store.dispatch(CustomFontsActions.loadCustomFonts());
      this.store.dispatch(ClipboardActions.loadClipboardItems());
    });
    this.frameTemplatesService.initialize(['SHOWCASE-FRAME']);
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
