import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AssortmentsSelectors } from 'src/app/common/assortments/assortments-store';
import { SearchBarComponent } from 'src/app/common/components/search-bar/search-bar.component';
import { ItemChooserSelectors } from 'src/app/common/item-chooser/item-chooser-store';
import { RootStoreState } from 'src/app/root-store';
import { HotSpotService } from '../hot-spot.service';

@Component({
  selector: 'app-add-hot-spot-modal',
  templateUrl: './add-hot-spot-modal.component.html',
  styleUrls: ['./add-hot-spot-modal.component.scss'],
})
export class AddHotSpotModalComponent implements AfterViewInit {
  constructor(
    private store: Store<RootStoreState.State>,
    private hotSpotService: HotSpotService,
    public dialogRef: MatDialogRef<AddHotSpotModalComponent>,
  ) {}

  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;

  public currentData$: Observable<Array<any>>;

  subscribe() {
    this.currentData$ = combineLatest([
      this.searchBar.valueChange.pipe(startWith('')),
      // this.store.select(ItemChooserSelectors.items)
      this.store.select(AssortmentsSelectors.sourceAssortmentItemData),
    ]).pipe(
      map(([searchTerm, properties]) => {
        const keys = 'name, item.name, item.atts.styleNumber, atts.colorCode, item.atts.collection, item.atts.division';
        return properties.filter((item) =>
          keys
            .split(',')
            .some((key) => item && new RegExp(searchTerm, 'gi').test(ObjectUtil.getByPath(item, key.trim()))),
        );
      }),
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.subscribe(), 0);
  }

  select(entity: any) {
    this.hotSpotService.addHotSpot(entity);
    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }

  getImage(itemData) {
    return itemData.properties.thumbnail;
  }
}
