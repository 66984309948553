import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { ShowcaseAnalyticsDashboardService } from '../showcase-analytics-dashboard/showcase-analytics-dashboard.service';
declare var google: any;
import * as moment from 'moment';
import { ScriptLoaderService } from '@common/angular-google-charts/services/script-loader.service';
import { getPackageForChart } from '@common/angular-google-charts/helpers/chart.helper';
import { ChartType } from '@common/angular-google-charts/types/chart-type';
@Component({
  selector: 'app-access-report',
  templateUrl: './access-report.component.html',
  styleUrls: ['./access-report.component.scss'],
})
export class AccessReportComponent implements OnInit {
  constructor(
    private loaderService: ScriptLoaderService,
    private dashboardService: ShowcaseAnalyticsDashboardService,
  ) {}
  public loading = true;
  chartOptions = {
    chart: {},
    colors: ['#1976d2', '#414891'],
  };

  chartType: any = 'Line';
  chartData: Array<any>;
  chartColumns = ['Date', 'Sessions', 'Users'];
  totalUsers = 0;
  totalSessions = 0;

  async ngOnInit() {
    this.loaderService.loadChartPackages(getPackageForChart(ChartType.Line)).subscribe(async () => {
      this.chartOptions = google.charts.Line.convertOptions(this.chartOptions);
      //this.loadData();
    });

    this.dashboardService.dashboardConfig.subscribe((config) => {
      console.log('config change in chart: ', config);
      this.loadData();
    });
  }

  async loadData() {
    this.loading = true;
    const config = this.dashboardService.getDashboardConfig();
    //const reportData = await new Entities().get({ entityName: 'showcase-analytics-report', id: 'access-report', criteria: { startDate: config.startDate, endDate: config.endDate } });
    const reportData = this.getStaticData();
    const labels = [];
    const accessData = [];
    let accessDataItem: Array<any>;
    for (const dataItem of reportData) {
      const sessions = parseInt(dataItem.access_count, 10);
      const users = parseInt(dataItem.unique_users, 10);
      this.totalSessions += sessions;
      accessDataItem = [new Date(dataItem.date), sessions, users];
      accessData.push(accessDataItem);
    }
    this.chartData = accessData;
    this.loading = false;
  }

  public getStaticData() {
    const numDays = 28;
    const data = [];
    let users = 25;
    let access = 100;
    for (let i = 0; i < numDays; i++) {
      if (users < 10) {
        users = 10;
      }
      if (access < 10) {
        access = 10;
      }
      users = this.getRandomInt(users - 8, users + 10);
      access = this.getRandomInt(access - 8, access + 10);

      data.push({
        date: moment().subtract(numDays - i, 'day'),
        access_count: access,
        unique_users: access / this.getRandomInt(3, 5),
      });
    }
    return data;
  }
  private getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
  }
}
