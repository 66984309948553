import { Injectable } from '@angular/core';
import { DocumentAction, DocumentChangeType, DocumentElement } from '@contrail/documents';
import { Content, Entities, EntityReference } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { setLoading } from 'src/app/common/loading-indicator/loading-indicator-store/loading-indicator.actions';
import { RootStoreState } from 'src/app/root-store';
import { DocumentComponentService } from '../../document-component/document-component-service';
import { DocumentService } from '../../document.service';
import { ObjectUtil } from '@contrail/util';
import { ImageAssignmentOptionsService } from './image-assignment-options.component';
import { AssortmentsActions } from '@common/assortments/assortments-store';
import { OrgMembership } from '@common/auth/auth.service';
import { AuthSelectors } from '@common/auth/auth-store';
import { GetContentPolicies } from '@common/content/content-policy-helper';
import { DocumentExportService } from '../../document-export/document-export.service';
import { DocumentFileService } from '../../document-files/document-file.service';
import { nanoid } from 'nanoid';
import { DocumentItemService } from '../../document-item/document-item.service';
import { SVGCombiner } from 'src/app/presentation/canvas-lib';

@Injectable({
  providedIn: 'root',
})
export class ImageAssignmentInteractionService {
  private currentOrg: OrgMembership;

  constructor(
    private documentService: DocumentService,
    private documentExportService: DocumentExportService,
    private imageAssignmentOptionsService: ImageAssignmentOptionsService,
    private componentService: DocumentComponentService,
    private store: Store<RootStoreState.State>,
  ) {
    this.store.select(AuthSelectors.selectAuthContext).subscribe((authContext) => {
      this.currentOrg = authContext?.currentOrg;
    });
  }

  /** Handles the interaction whereby a user drags an 'image' onto an item card. */
  public async handleImageItemAssignment(itemComponentElement: DocumentElement, selectedElements: DocumentElement[]) {
    if (!DocumentItemService.isItemComponet(itemComponentElement)) {
      return;
    }
    const item = await this.componentService.getItemFromComponentElement(itemComponentElement);

    const assignmentOption = await this.imageAssignmentOptionsService.open(item);
    if (!assignmentOption) {
      return;
    }

    this.store.dispatch(setLoading({ loading: true, message: 'Please wait...' }));

    const content = await this.createNewContent(selectedElements, item);
    if (!content) {
      console.log('Invalid element, cannot get content from element: ', selectedElements);
      this.store.dispatch(setLoading({ loading: false, message: '' }));
      return;
    }

    await this.assignContentToItem(assignmentOption, itemComponentElement, item, content);

    this.store.dispatch(setLoading({ loading: false, message: '' }));
  }

  private async createNewContent(selectedElements: DocumentElement[], item): Promise<Content> {
    if (this.shouldKeepExistingFile(selectedElements)) {
      console.log('ImageAssignmentInteractionService: using existing File');
      return await this.createContentFromFileId(selectedElements[0], item);
    } else {
      if (this.shouldCreateSVG(selectedElements)) {
        console.log('ImageAssignmentInteractionService: creating new SVG image File');
        const combinedSvgString = await this.documentService.documentRenderer.combineElementsIntoSvg(selectedElements);
        const newFile = new File([combinedSvgString], 'image.svg', { type: 'image/svg+xml' });
        return await this.createContentFromFile(newFile, item);
      } else {
        console.log('ImageAssignmentInteractionService: creating new PNG image File');
        const newImage = await this.documentExportService.createImageFromElements(selectedElements);
        const newFile = await DocumentFileService.dataUrlToFile(newImage.imageData, 'image.png');
        return await this.createContentFromFile(newFile, item);
      }
    }
  }

  private shouldKeepExistingFile(selectedElements: DocumentElement[]) {
    const uneditedSvgOrImageElement = selectedElements?.filter(
      (e) => ['svg', 'image'].indexOf(e.type) !== -1 && !this.isEditedImage(e),
    );
    return selectedElements?.length === 1 && uneditedSvgOrImageElement?.length === 1;
  }

  private isEditedImage(element: DocumentElement) {
    return (
      (element?.cropDefinition?.widthPercent && element?.cropDefinition?.heightPercent
        ? element?.cropDefinition?.widthPercent < 1 || element?.cropDefinition?.heightPercent < 1
        : element?.cropDefinition?.width && element?.cropDefinition?.height) ||
      (element?.style?.border?.color && element?.style?.border?.color !== 'rgba(0,0,0,0)') ||
      (element?.style?.opacity && element?.style?.opacity !== 1) ||
      (element?.rotate?.angle && element?.rotate?.angle !== 0)
    );
  }

  private shouldCreateSVG(selectedElements: DocumentElement[]) {
    return SVGCombiner.areElementsEligibleForCombine(selectedElements);
  }

  private async createContentFromFileId(imgElement: DocumentElement, item): Promise<Content> {
    // The outcome of all these use cases is content is created or or
    // existing content that will be assigned.
    let content;
    if (imgElement.modelBindings.image) {
      // Document elements with 'image' set in their model bindings are pointing at a file entity (this is assumed)
      // When we have a file reference, we need to add that file to the item/etc, which means we need
      // to create a content entity.
      const fileReference = new EntityReference(imgElement.modelBindings.image);
      const entityPolicyIds = await GetContentPolicies(item, this.currentOrg);

      content = await new Content().create({
        fileId: fileReference.id,
        contentHolderReference: `item:${item.id}`,
        entityPolicyIds,
      });
    } else if (imgElement.modelBindings.content) {
      const contentReference = new EntityReference(imgElement.modelBindings.content);
      content = await new Entities().get({
        entityName: 'content',
        id: contentReference.id,
      });

      const entityPolicyIds = await GetContentPolicies(item, this.currentOrg);
      content = await new Content().create({
        fileId: content.primaryFileId,
        contentHolderReference: `item:${item.id}`,
        entityPolicyIds,
      });
    }
    return content;
  }

  private async createContentFromFile(file: File, item): Promise<any> {
    // @ts-ignore
    if (window.Cypress || new URLSearchParams(window.location.search)?.get('DEBUG')) {
      // Download new file for testing validation
      const link = document.createElement('a');
      link.download = file.name;
      link.href = URL.createObjectURL(file);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    const contentId = nanoid(16);
    const entityPolicyIds = await GetContentPolicies(item, this.currentOrg);
    const newContent = await new Entities().create({
      entityName: 'content',
      object: {
        specifiedId: contentId,
        file: file,
        fileName: file.name,
        contentType: file.type,
        contentHolderReference: `item:${item.id}`,
        entityPolicyIds,
      },
    });

    await this.documentService.fileHandler.uploadFile(newContent.primaryFile.uploadPost, file);

    return newContent;
  }

  public async assignContentToItem(assignmentOption, itemComponentElement, item, content) {
    await new Entities().create({
      entityName: 'content-holder-content',
      object: {
        contentHolderReference: `item:${item.id}`,
        contentId: content.id,
      },
    });

    if (['replacePrimary', 'replacePrimaryAndDeleteCurrent'].includes(assignmentOption.action)) {
      // Remove the prior content from the content holder
      // const priorPrimaryViewableId = assignmentOption.priorPrimaryViewableId;
      // const priorPrimaryContent = assignmentOption.content.find(c => c.id === priorPrimaryViewableId);
      // console.log("priorPrimaryContent: ", priorPrimaryContent)
      // if (priorPrimaryContent) {
      //   const contentHolderContent = priorPrimaryContent.contentHolderContent;
      //   await new Entities().delete({ entityName: 'content-holder-content', id: contentHolderContent.id });
      // }

      // Set the primary viewable id on the target entity (item)
      const object = {
        primaryViewableId: content.id,
        largeViewableDownloadUrl: content.primaryFileUrl,
        mediumLargeViewableDownloadUrl: content.primaryFileUrl,
        mediumViewableDownloadUrl: content.primaryFileUrl,
        smallViewableDownloadUrl: content.primaryFileUrl,
        tinyViewableDownloadUrl: content.primaryFileUrl,
        primaryFileUrl: content.primaryFileUrl,
        fileName: content.fileName,
        contentType: content.contentType,
      };
      await new Entities().update({ entityName: 'item', id: item.id, object });

      // Update backing assortment so item has correct latest properties
      this.store.dispatch(AssortmentsActions.syncBackingAssortmentItems({ id: item.id, changes: object }));

      // Assign the content to the document element.
      await this.updateComponentWithNewPrimaryViewable(ObjectUtil.cloneDeep(itemComponentElement), content, item);
      if (assignmentOption.action === 'replacePrimaryAndDeleteCurrent') {
        await new Entities().delete({ entityName: 'content', id: assignmentOption.priorPrimaryViewableId });
      }
    } else if ('addNew' === assignmentOption.action) {
      // Add new content
    } else if ('assignFirstContent' === assignmentOption.action) {
      const object = {
        primaryViewableId: content.id,
        largeViewableDownloadUrl: content.primaryFileUrl,
        mediumLargeViewableDownloadUrl: content.primaryFileUrl,
        mediumViewableDownloadUrl: content.primaryFileUrl,
        smallViewableDownloadUrl: content.primaryFileUrl,
        tinyViewableDownloadUrl: content.primaryFileUrl,
        primaryFileUrl: content.primaryFileUrl,
        fileName: content.fileName,
        contentType: content.contentType,
      };
      // Set the primary viewable id on the target entity (item)
      await new Entities().update({ entityName: 'item', id: item.id, object });

      // Update backing assortment so item has correct latest properties
      this.store.dispatch(AssortmentsActions.syncBackingAssortmentItems({ id: item.id, changes: object }));

      // Assign the content to the document element.
      await this.updateComponentWithNewPrimaryViewable(ObjectUtil.cloneDeep(itemComponentElement), content, item);
    } else if ('replaceContent' === assignmentOption.action) {
      // not implemented
    }
  }

  public async handleImageItemAssignmentFromContent(itemComponentElement: DocumentElement, contentArray) {
    if (contentArray?.length === 0) {
      return;
    }

    const item = await this.componentService.getItemFromComponentElement(itemComponentElement);

    const assignmentOption = await this.imageAssignmentOptionsService.open(item);
    if (!assignmentOption) {
      return;
    }

    this.documentService.toggleLoading(true, 'Uploading. Please wait....');

    console.log('Applying content to component:');
    console.log('   content', contentArray);
    console.log('   assignmentOption:', assignmentOption);
    console.log('   item:', item);

    for (let i = 0; i < contentArray.length; i++) {
      const content = contentArray[i];
      if (content?.id) {
        await this.assignContentToItem(
          contentArray?.length > 1 && i !== 0 ? 'addNew' : assignmentOption,
          itemComponentElement,
          item,
          content,
        );
      }
    }

    this.documentService.toggleLoading(false, '');
  }

  public async handleImageItemAssignmentFromFiles(itemComponentElement: DocumentElement, files: File[]) {
    const imageFiles = [];
    for (const file of files) {
      if (file?.type?.indexOf('image') !== -1) {
        imageFiles.push(file);
      }
    }
    if (imageFiles?.length === 0) {
      return;
    }

    const item = await this.componentService.getItemFromComponentElement(itemComponentElement);

    const assignmentOption = await this.imageAssignmentOptionsService.open(item);
    if (!assignmentOption) {
      return;
    }

    this.documentService.toggleLoading(true, 'Uploading. Please wait....');

    const contentArray = await this.documentService.fileHandler.createContentsFromFiles(imageFiles, item);

    console.log('Applying content to component:');
    console.log('   content', contentArray);
    console.log('   assignmentOption:', assignmentOption);
    console.log('   item:', item);

    for (let i = 0; i < contentArray.length; i++) {
      const content = contentArray[i];
      if (content?.id) {
        await this.assignContentToItem(
          contentArray?.length > 1 && i !== 0 ? 'addNew' : assignmentOption,
          itemComponentElement,
          item,
          content,
        );
      }
    }

    this.documentService.toggleLoading(false, '');
  }

  private async deriveContentFromDocumentElement(imgElement, newOwnerReference) {
    let content;
    if (imgElement.modelBindings.image) {
      // Document elements with 'image' set in their model bindings are pointing at a file entity (this is assumed)
      // When we have a file reference, we need to add that file to the item/etc, which means we need
      // to create a content entity.
      const fileReference = new EntityReference(imgElement.modelBindings.image);
      content = await new Content().create({ fileId: fileReference.id, contentHolderReference: newOwnerReference });
    } else if (imgElement.modelBindings.content) {
      // If the document element we are assigning has a content reference, then all
      // we need to do is add that content to the target content holder (item, etc.)
      // Note that in this situation, the element and the item(etc) will both be referencing
      // the same content entity. This may cause issues later if we start to support
      // updating content on document elements, or updating content via the item modal..
      // The content will update in both locations.
      const contentReference = new EntityReference(imgElement.modelBindings.content);
      content = await new Entities().get({
        entityName: 'content',
        id: contentReference.id,
      });
    }
    return content;
  }

  /**
   * Updates a component element with a viewable to
   * @param targetElement
   * @param content
   */
  private updateComponentWithNewPrimaryViewable(targetElement: DocumentElement, content, item) {
    const undoElementData = ObjectUtil.cloneDeep(targetElement);
    targetElement.modelBindings.viewable = 'item:' + item.id; // Important: this means component's viewable is tied to current primary image for this item
    const imageElement = targetElement.elements.filter((element) => element.type === 'image')[0];

    // If a file was already a content then there is largeViewableUrl otherwise get downloadUrl
    imageElement.url = content?.mediumViewableUrl || content?.primaryFile?.fileUrl;
    imageElement.propertyBindings.url = 'viewable.mediumViewableDownloadUrl'; // If viewable is to item property binding needs to be mediumViewableDownloadUrl, if it's content - mediumViewableUrl

    imageElement.alternateUrls = {};
    let originalFile = content?.primaryFileUrl;
    if (originalFile) {
      imageElement.alternateUrls.originalFile = originalFile;
    }
    let highResUrl = content?.largeViewableUrl || content?.primaryFileUrl;
    if (content?.contentType === 'image/svg+xml') {
      highResUrl = content.primaryFileUrl;
    }
    if (highResUrl) {
      imageElement.alternateUrls.highResolution = highResUrl;
    }
    let lowResUrl = content?.smallViewableUrl || content?.primaryFileUrl;
    if (lowResUrl) {
      imageElement.alternateUrls.lowResolution = lowResUrl;
    }

    const action = new DocumentAction(
      {
        changeType: DocumentChangeType.MODIFY_ELEMENT,
        elementId: targetElement.id,
        elementData: targetElement,
      },
      {
        changeType: DocumentChangeType.MODIFY_ELEMENT,
        elementId: targetElement.id,
        elementData: undoElementData,
      },
    );
    this.documentService.handleDocumentActions([action]);
  }
}
