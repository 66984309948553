import { AfterViewInit, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { ShowcasesActions, ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { Presentation, PresentationFrame } from '../../../presentation';
import { tap, debounceTime } from 'rxjs/operators';
import { ComposerToolbarService } from '../../composer-toolbar/composer-toolbar.service';
import { DocumentService } from 'src/app/presentation/document/document.service';
import { AnnotationOption } from 'src/app/presentation/document/document-annotation/document-annotation-options';
import { ComposerService } from '../../composer.service';
import { Subscription } from 'rxjs';
import {
  COLLECTION_VIEW_APPLICATION_SLUG,
  ComposerFrameLayoutService,
} from '../composer-frame-layout/composer-frame-layout.service';
import { DocumentStatusMessageService } from 'src/app/presentation/document/document-status/document-status-message.service';
import { SearchReplaceSelectors } from '@common/search-replace/search-replace-store';

@Component({
  selector: 'app-composer-collection-frame',
  templateUrl: './composer-collection-frame.component.html',
  styleUrls: ['./composer-collection-frame.component.scss'],
})
export class ComposerCollectionFrameComponent implements OnDestroy, OnChanges {
  annotationType;
  annotationOptions: AnnotationOption[];
  cursorClass;
  private subscriptions = new Subscription();
  searchResults: any[];
  activeSearchResultElement: any;

  public filterLabel;
  public icon = 'item-collection-placeholder';
  public title = 'Add items to your collection';
  public footer =
    'Bring in items using the chooser. Use filters to narrow down your product set and sorts to sequence your slideshow.';
  statusMessages: any[];

  @Input() frame: PresentationFrame;

  constructor(
    private composerToolbarService: ComposerToolbarService,
    private store: Store<RootStoreState.State>,
    private composerService: ComposerService,
    private layoutService: ComposerFrameLayoutService,
    private documentService: DocumentService,
    private documentStatusMessageService: DocumentStatusMessageService,
  ) {
    this.annotationOptions = this.documentService.getAnnotationOptions();
    this.store
      .select(ShowcasesSelectors.annotationType)
      .pipe(
        tap((annotationType) => {
          this.annotationType = annotationType;
          if (annotationType) {
            const currentAnnotationOption = this.annotationOptions.find(
              (annotationOption) => annotationOption.type === annotationType,
            );
            this.cursorClass = currentAnnotationOption.type + '-cursor';
          } else {
            this.cursorClass = null;
          }
        }),
      )
      .subscribe();
    this.subscriptions.add(
      this.documentStatusMessageService.statusMessages.subscribe((statusMessages) => {
        this.statusMessages = statusMessages;
      }),
    );
    this.subscriptions.add(
      this.store.select(SearchReplaceSelectors.searchResults).subscribe((searchResults) => {
        this.searchResults = searchResults;
      }),
    );
    this.subscriptions.add(
      this.store.select(SearchReplaceSelectors.activeSearchResultElement).subscribe((activeSearchResultElement) => {
        this.activeSearchResultElement = activeSearchResultElement;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.frame && this.frame.collection.set.length === 0) {
      this.openItemChooser();
    }
    if (changes.frame && changes.frame.previousValue?.id !== changes.frame.currentValue?.id) {
      const contextReference = this.composerService.currentFrameContextReference;
      await this.layoutService.getViewDefinition(contextReference, COLLECTION_VIEW_APPLICATION_SLUG);
    }
  }

  onClick(event) {
    this.store.dispatch(ShowcasesActions.setCollectionFrameActiveContainer({ collectionFrameActiveContainer: null }));
  }

  openItemChooser() {
    this.composerToolbarService.showItemChooser();
  }
}
