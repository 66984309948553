import {
  Component,
  EventEmitter,
  Inject,
  OnChanges,
  OnInit,
  SimpleChanges,
  Input,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppExtensionMessageHandler } from '@common/app-extensions/app-extension-message-handler.interface';
import { AuthSelectors } from '@common/auth/auth-store';
import { AuthService } from '@common/auth/auth.service';
import { ItemData } from '@common/item-data/item-data';
import { AppExtensionDefinition } from '@contrail/entity-types';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { filter, take, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Pipe({ name: 'safeIframeURL' })
export class SafeIFramePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-extension-widget',
  templateUrl: './extension-widget.component.html',
  styleUrls: ['./extension-widget.component.scss'],
})
export class ExtensionWidgetComponent implements OnInit, OnChanges {
  @Input() assortmentItemData: Array<ItemData>;
  @Input() private messageHandler: AppExtensionMessageHandler;
  @Input() public appDefinition: AppExtensionDefinition;
  @Input() private appContext: any;

  public loading = true;
  public iframeUrl;
  public selectedIds: Array<any>;
  public iframeData: any;
  public authToken: string;
  public authOrg: string;
  public user;

  private messageListener: any;

  constructor(
    private store: Store<RootStoreState.State>,
    private authService: AuthService,
  ) {}

  async ngOnInit() {
    this.authToken = await this.authService.getToken();
    this.store
      .select(AuthSelectors.selectAuthContext)
      .pipe(
        filter((res) => res !== null),
        take(1),
        tap((context) => {
          this.authOrg = context.currentOrg?.orgSlug;
          this.user = context.user;
        }),
      )
      .subscribe();

    /** Once the web app has loaded into the iframe, we can securely send the authentication information */
    document.getElementById('localFrame').onload = () => {
      this.loading = false;

      // We likely want to change the flow here so that the launched window sends a message
      // to let us know its loaded, so that we can gaurantee that the app has loaded before
      // sending the contextual information
      setTimeout(() => {
        this.initFrameConfig();
      }, 200);
      this.messageListener = this.handleChildFrameMessage.bind(this);
      window.addEventListener('message', this.messageListener);
    };
    this.iframeUrl = this.appDefinition?.iframeUrl;
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('ExtensionWidgetComponent: changes: ', changes);
    if (changes.assortmentItemData) {
      const message = {
        messageType: 'state_change',
        stateChanges: [
          {
            assortmentItemData: this.assortmentItemData,
          },
        ],
      };
      this.postMessageToExtension(message);
    }
  }

  handleChildFrameMessage(event) {
    // Ignore messages coming from the local window
    if (('' + window.location).indexOf(event.origin) > -1 && ('' + window.location).indexOf('localhost')) {
      return;
    }
    console.log('ExtensionWidgetComponent Recived message from embedded iframe: ', event.origin, window.location);
    const type = event.type;
    const data = event.data;
    this.messageHandler.handleMessage(event.data);
  }

  ngOnDestroy(): void {
    console.log('ExtensionWidgetComponent: onDestroy.. removing listener');
    window.removeEventListener('message', this.messageListener);
  }

  initFrameConfig() {
    const initContextMessage = {
      messageType: 'initialization',
      context: {
        appContext: this.appContext,
        authToken: this.authToken,
        authOrg: this.authOrg,
        user: this.user,
        apiGateway: environment.thumbnailBaseUrl || 'https://api.vibeiq.com/prod/api',
      },
      state: {
        assortmentItemData: this.assortmentItemData,
        selectedElements: [],
      },
    };
    this.postMessageToExtension(initContextMessage);
  }

  postMessageToExtension(message) {
    const frameContentWindow = (document.getElementById('localFrame') as any).contentWindow;
    const url = this.iframeUrl;
    console.log('postMessageToExtension: message: ', message);

    frameContentWindow.postMessage(message, url);
  }
}
