import { AfterViewInit, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { PresentationFrame } from '../../../presentation';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-composer-iframe-frame',
  templateUrl: './composer-iframe-frame.component.html',
  styleUrls: ['./composer-iframe-frame.component.scss'],
})
export class ComposerIframeFrameComponent implements AfterViewInit, OnChanges, OnDestroy {
  private subscriptions = new Subscription();
  @Input() frame: PresentationFrame;
  url: any;

  constructor(private sanitizer: DomSanitizer) {}

  async ngOnChanges() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.frame.url);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async ngAfterViewInit() {}
}
