import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { CommentsActions, CommentsSelectors } from 'src/app/common/comments/comments-store';
import { DocumentElement, PositionDefinition, LineDefinition, SizeDefinition } from '@contrail/documents';
import { DocumentService } from '../../document/document.service';
import { ObjectUtil } from '@contrail/util';
import { ShowcasesService, Showcase } from 'src/app/showcases/showcases.service';
import { DocumentSelectors } from '../../document/document-store';
import { DocumentViewSize } from '../../document/document-store/document.state';
import { ComposerService } from '../composer.service';
import { PresentationFrame } from '../../presentation';
import { Comment, CommentOwnerInfo } from '@common/comments/comments.service';
import { FRAME_PINNED_COMMENT_ID_SUFFIX } from '../composer-pinned-comments/composer-frame-pinned-comments/composer-frame-pinned-comments.component';
import { ITEM_PINNED_COMMENT_ID_SUFFIX } from './composer-collection-item-pinned-comments/composer-collection-item-pinned-comments.component';
import { ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { EntityReference } from '@contrail/sdk';
import { PINNED_COMMENT_ID_SUFFIX } from './composer-grid-section-pinned-comments/composer-grid-section-pinned-comments.component';
import { CanvasElement, CanvasUtil } from 'src/app/presentation/canvas-lib';

@Injectable({
  providedIn: 'root',
})
export class AddPinnedCommentsService {
  private showcase: Showcase;
  private viewSize: DocumentViewSize;
  private frame: PresentationFrame;
  private lastKnownMousePosition: PositionDefinition;

  private readonly PINNED_COMMENT_OFFSET = 4;

  public commentOverlayPosition: PositionDefinition;

  constructor(
    private store: Store<RootStoreState.State>,
    private documentService: DocumentService,
    private composerService: ComposerService,
  ) {
    this.store.select(ShowcasesSelectors.currentShowcase).subscribe((showcase) => (this.showcase = showcase));
    this.composerService.currentFrame.subscribe((frame) => (this.frame = frame));
    this.store.select(DocumentSelectors.viewSize).subscribe((viewSize) => {
      this.viewSize = viewSize;
    });
    this.store
      .select(DocumentSelectors.lastKnownMousePosition)
      .subscribe((lastKnownMousePosition) => (this.lastKnownMousePosition = lastKnownMousePosition));
    this.store.select(CommentsSelectors.showCommentOverlay).subscribe((bool) => {
      if (!bool) {
        this.commentOverlayPosition = null;
      }
    });
  }

  /**
   * Adds comment overlay pinned to an HTML element with id @elementId
   * @param ownerInfo
   * @param elementId
   */
  public addCommentToHTMLElement(ownerInfo: CommentOwnerInfo, elementId: string) {
    const pinnedCommentEl = document.getElementById(elementId);
    if (!pinnedCommentEl) {
      return;
    }
    const pinnedCommentPosition = pinnedCommentEl.getBoundingClientRect();

    this.store.dispatch(
      CommentsActions.showCommentOverlay({
        ownerInfo,
        position: {
          x: pinnedCommentPosition.left,
          y: pinnedCommentPosition.bottom + this.PINNED_COMMENT_OFFSET,
        },
      }),
    );
  }

  public addItemComment(itemValue, frameId) {
    this.addCommentToHTMLElement(
      {
        entityType: 'item',
        id: itemValue,
        subContextReference: `presentation-frame:${frameId}`,
      },
      `${ITEM_PINNED_COMMENT_ID_SUFFIX}-${itemValue}`,
    );
  }

  public addGridSectionComment(frameId, gridSectionId) {
    this.addCommentToHTMLElement(
      {
        entityType: 'showcase',
        id: this.showcase.id,
        subContextReference: `presentation-frame:${frameId}`,
        gridSectionId,
      },
      `${PINNED_COMMENT_ID_SUFFIX}-${gridSectionId}`,
    );
  }

  /**
   * Adds comment overlay to a specific frame preview
   * @param frameId
   */
  public addFramePreviewComment(frameId: string) {
    this.addCommentToHTMLElement(
      {
        entityType: 'showcase',
        id: this.showcase.id,
        subContextReference: `presentation-frame:${frameId}`,
      },
      `${FRAME_PINNED_COMMENT_ID_SUFFIX}-${frameId}`,
    );
  }

  public updateCommentsPosition(windowPosition: PositionDefinition, comments: Array<Comment>) {
    const documentPosition = this.toDocumentPosition(windowPosition);

    let overlappedWithMousePosition: DocumentElement;
    const elements = this.documentService.currentDocument?.elements;
    for (let i = elements?.length - 1; i >= 0; i--) {
      const element = elements[i];
      if (this.isInRange(documentPosition, element)) {
        overlappedWithMousePosition = element;
        break;
      }
    }

    let documentElementId = null;
    if (overlappedWithMousePosition) {
      const { size, position } = this.documentService.getElementSizeAndPosition(overlappedWithMousePosition);
      documentPosition.x = documentPosition.x - position.x;
      documentPosition.y = documentPosition.y - position.y;
      documentElementId = overlappedWithMousePosition.id;
    }

    const updateComments = [];
    for (let i = 0; i < comments.length; i++) {
      const comment = comments[i];
      let updateComment = ObjectUtil.cloneDeep(comment);
      updateComment.documentPosition = documentPosition;
      if (documentElementId) {
        updateComment.documentElementId = documentElementId;
      } else {
        updateComment.documentElementId = null;
      }
      updateComments.push(updateComment);
    }

    this.store.dispatch(CommentsActions.updateCommentsSuccess({ comments: updateComments }));
    this.store.dispatch(CommentsActions.updateComments({ comments: updateComments }));
  }

  private toDocumentPosition(windowPosition) {
    if (this.viewSize) {
      return CanvasUtil.toDocumentPosition(
        windowPosition?.x,
        windowPosition?.y,
        this.viewSize.viewBox,
        this.viewSize.viewScale,
        this.viewSize.boundingClientRect,
      );
    }
  }

  private toWindowPosition(position: PositionDefinition): PositionDefinition {
    if (this.viewSize) {
      return CanvasUtil.toWindowPosition(
        position?.x,
        position?.y,
        this.viewSize.viewBox,
        this.viewSize.viewScale,
        this.viewSize.boundingClientRect,
      );
    }
  }

  /**
   * Adds comment overlay pinned to a @documentPosition converted into window position
   * @param ownerInfo
   * @param documentPosition
   */
  public addCommentToDocumentElement(ownerInfo: CommentOwnerInfo, documentPosition: PositionDefinition) {
    this.commentOverlayPosition = {
      x: documentPosition.x,
      y: documentPosition.y,
    };

    const windowPosition = this.toWindowPosition(documentPosition);

    this.store.dispatch(
      CommentsActions.showCommentOverlay({
        ownerInfo,
        position: {
          x: windowPosition.x,
          y: windowPosition.y,
        },
      }),
    );
  }

  /**
   * Add comment overlay at a certain @windowPosition for a document element
   * @param windowPosition
   * @param element
   */
  public addComments(windowPosition: PositionDefinition, element?: DocumentElement) {
    let entityType = 'showcase';
    let id = this.showcase.id;
    const documentPosition = this.toDocumentPosition(windowPosition);

    this.commentOverlayPosition = {
      x: documentPosition.x,
      y: documentPosition.y,
    };

    if (element) {
      documentPosition.x = documentPosition.x - element.position.x;
      documentPosition.y = documentPosition.y - element.position.y;

      if (element?.modelBindings?.item) {
        const entityReference = new EntityReference(element.modelBindings.item);
        entityType = 'item';
        id = entityReference.id;
      }
    }

    this.store.dispatch(
      CommentsActions.showCommentOverlay({
        ownerInfo: {
          entityType,
          id,
          subContextReference: `presentation-frame:${this.frame.id}`,
          documentPosition,
          documentElementId: element?.id,
        },
        position: {
          x: windowPosition.x,
          y: windowPosition.y,
        },
      }),
    );
  }

  /**
   * Add comment overlay when Ctrl M is pressed at the current mouse position.
   */
  public addCommentsFromKeyEvent() {
    if (!this.lastKnownMousePosition) {
      return;
    }

    const windowPosition = this.lastKnownMousePosition;
    const documentPosition = this.toDocumentPosition(windowPosition);
    const selectedDocumentElements = this.documentService.getSelectedElements();

    if (
      !this.isRectInRange(documentPosition, {
        position: {
          x: 0,
          y: 0,
        },
        size: {
          width: this.viewSize.viewBox.width / this.viewSize.viewScale.x,
          height: this.viewSize.viewBox.height / this.viewSize.viewScale.y,
        },
      })
    ) {
      return;
    }

    // If no elements are selected comment on the board at the position
    if (selectedDocumentElements?.length === 0) {
      this.addComments(windowPosition);
      return;
    }

    // Filter from selected elements those that overlap with the mouse position
    const overlappedWithMousePosition = selectedDocumentElements.filter((el) =>
      this.isOnDocumentElement(documentPosition, el),
    );
    if (overlappedWithMousePosition?.length >= 1) {
      this.addComments(windowPosition, overlappedWithMousePosition[0]);
    } else {
      this.addComments(windowPosition);
    }
  }

  private isOnDocumentElement(position: PositionDefinition, documentElement: DocumentElement) {
    const clonedElement: DocumentElement = ObjectUtil.cloneDeep(documentElement);
    this.setElementSizeAndPosition(clonedElement);

    return this.isInRange(position, clonedElement);
  }

  private isRectInRange(
    position: PositionDefinition,
    boundingRectange: { position: PositionDefinition; size: SizeDefinition },
  ) {
    return (
      position.x >= boundingRectange.position.x &&
      position.x <= boundingRectange.position.x + boundingRectange.size.width &&
      position.y >= boundingRectange.position.y &&
      position.y <= boundingRectange.position.y + boundingRectange.size.height
    );
  }

  private isInRange(position: PositionDefinition, documentElement: DocumentElement) {
    const clonedElement: DocumentElement = ObjectUtil.cloneDeep(documentElement);
    this.setElementSizeAndPosition(clonedElement);

    return (
      position.x >= clonedElement.position.x &&
      position.x <= clonedElement.position.x + clonedElement.size.width &&
      position.y >= clonedElement.position.y &&
      position.y <= clonedElement.position.y + clonedElement.size.height
    );
  }

  private setElementSizeAndPosition(element: DocumentElement) {
    const canvasElement: CanvasElement = this.documentService.documentRenderer.getCanvasElementById(element.id);
    const { x, y, width, height } = canvasElement.getBoundingClientRect();
    element.size = {
      width,
      height,
    };
    element.position = {
      x,
      y,
    };
  }
}
