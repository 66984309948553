import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MatIconModule } from '@angular/material/icon';
import { SummaryMetricPanelComponent } from './summary-metric-panel/summary-metric-panel.component';
import { PipesModule } from '@pipes/pipes.module';
import { DashboardPanelComponent } from './dashboard-panel/dashboard-panel.component';
import { DashboardWidgetComponent } from './dashboard-widget/dashboard-widget.component';
import { AssortmentSummaryDashboardWidgetComponent } from './widgets/assortment-summary-dashboard-widget/assortment-summary-dashboard-widget.component';
import { AssortmentPivotDashboardWidgetComponent } from './widgets/assortment-pivot-dashboard-widget/assortment-pivot-dashboard-widget.component';
import { ExtensionWidgetComponent, SafeIFramePipe } from './extension-widget/extension-widget.component';
import { AssortmentSummaryChartDashboardWidgetComponent } from './widgets/assortment-summary-chart-dashboard-widget/assortment-summary-chart-dashboard-widget.component';
import { ComponentsModule } from '@components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { AssortmentAnnotationDashboardWidgetComponent } from './widgets/assortment-annotation-dashboard-widget/assortment-annotation-dashboard-widget.component';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GoogleChartsModule } from '@common/angular-google-charts/google-charts.module';
import { DirectivesModule } from '@directives/directives.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
  declarations: [
    DashboardComponent,
    SummaryMetricPanelComponent,
    DashboardPanelComponent,
    DashboardWidgetComponent,
    AssortmentSummaryDashboardWidgetComponent,
    AssortmentPivotDashboardWidgetComponent,
    ExtensionWidgetComponent,
    SafeIFramePipe,
    AssortmentSummaryChartDashboardWidgetComponent,
    AssortmentAnnotationDashboardWidgetComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    PipesModule,
    GoogleChartsModule,
    ComponentsModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    DragDropModule,
    DirectivesModule,
    MatTooltipModule,
  ],
  exports: [DashboardPanelComponent],
})
export class DashboardModule {}
