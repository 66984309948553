<div class="flex-center">
  <form [formGroup]="form" class="h-[45px] mb-1 !mr-1">
    <mat-form-field *ngIf="!editName">
      <mat-select
        data-test="composer-frame-toolbar-select"
        formControlName="selectedFrame"
        (selectionChange)="onSelectedFrameChange($event)"
      >
        <mat-option *ngFor="let frame of frames" [value]="frame">{{ frame.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="editName">
      <input
        #editNameInput
        matInput
        id="name"
        formControlName="name"
        data-test="composer-frame-toolbar-name-edit-input"
        maxlength="100"
        type="text"
        name="name"
        (keydown.enter)="endEditName()"
        (blur)="endEditName()"
      />
    </mat-form-field>
  </form>
  <mat-icon
    *ngIf="editorMode === 'EDIT'"
    class="!ml-1 cursor-pointer"
    data-test="composer-frame-toolbar-name-edit-button"
    matTooltip="Edit name"
    (click)="startNameEdit()"
    >edit</mat-icon
  >
</div>
