import { Component, OnInit } from '@angular/core';
import { ShowcaseAnalyticsDashboardService } from '../showcase-analytics-dashboard/showcase-analytics-dashboard.service';

@Component({
  selector: 'app-item-report',
  templateUrl: './item-report.component.html',
  styleUrls: ['./item-report.component.scss'],
})
export class ItemReportComponent implements OnInit {
  constructor(private dashboardService: ShowcaseAnalyticsDashboardService) {}
  public loading = true;
  public items;
  async ngOnInit() {
    this.dashboardService.dashboardConfig.subscribe((config) => {
      this.loadData();
    });
  }

  async loadData() {
    this.loading = true;
    const config = this.dashboardService.getDashboardConfig();
    //this.users = await new Entities().get({ entityName: 'showcase-analytics-report', id: 'user-access-report', criteria: { startDate: config.startDate, endDate: config.endDate } });
    this.items = this.getRandomData();
    this.loading = false;
  }

  private getRandomData() {
    return [
      {
        image: 'https://contrail-temp-images.s3.amazonaws.com/hoka_medium/1113513-WEPR_1.png',
        views: 543,
        showcases: 87,
        name: 'Bondi X',
        assortments: 123,
      },
      {
        image: 'https://contrail-temp-images.s3.amazonaws.com/hoka_medium/1113531-MBSF_1.png',
        views: 458,
        showcases: 19,
        name: 'Mafate Speed 3',
        assortments: 112,
      },
      {
        image: 'https://contrail-temp-images.s3.amazonaws.com/hoka_medium/1115010-HMAQ_1.png',
        views: 423,
        showcases: 8,
        name: 'Ahari 5',
        assortments: 90,
      },
      {
        image: 'https://contrail-temp-images.s3.amazonaws.com/hoka_medium/1119393-RTAR_1.png',
        views: 418,
        showcases: 15,
        name: 'Clifton 8',
        assortments: 123,
      },
    ];
  }
}
