import { createAction, props } from '@ngrx/store';
import { Item } from 'src/app/common/assortments/item';
import { PresentationCollectionElement } from 'src/app/presentation/presentation';
import { Showcase } from './showcases.state';

export enum ShowcasesActionTypes {
  LOAD_SHOWCASES = '[Showcases] Load Showcases',
  LOAD_SHOWCASES_SUCCESS = '[Showcases] Load Showcases Success',
  LOAD_SHOWCASES_FAILURE = '[Showcases] Load Showcases Failure',

  CREATE_SHOWCASE = '[Showcases] Create Showcase',
  CREATE_SHOWCASE_SUCCESS = '[Showcases] Create Showcase Success',
  CREATE_SHOWCASE_FAILURE = '[Showcases] Create Showcase Failure',

  COPY_SHOWCASE = '[Showcases] Copy Showcase',
  COPY_SHOWCASE_SUCCESS = '[Showcases] Copy Showcase Success',
  COPY_SHOWCASE_FAILURE = '[Showcases] Copy Showcase Failure',

  ASYNC_COPY_SHOWCASE = '[Showcases] Async Copy Showcase',
  ASYNC_COPY_SHOWCASE_SUCCESS = '[Showcases] Async Copy Showcase Success',

  DELETE_SHOWCASE = '[Showcases] Delete Showcase',
  DELETE_SHOWCASE_SUCCESS = '[Showcases] Delete Showcase Success',
  DELETE_SHOWCASE_FAILURE = '[Showcases] Delete Showcase Failure',

  UPDATE_SHOWCASE = '[Showcases] Update Showcase',
  UPDATE_SHOWCASE_SUCCESS = '[Showcases] Update Showcase Success',
  UPDATE_SHOWCASE_FAILURE = '[Showcases] Update Showcase Failure',

  ASSIGN_SHOWCASE_SOURCE_ASSORTMENT = '[Showcases] Assign Showcase Source Assortment',
  ASSIGN_SHOWCASE_SOURCE_ASSORTMENT_SUCCESS = '[Showcases] Assign Showcase Source Assortment Success',
  CLEAR_SHOWCASE_SOURCE_ASSORTMENT = '[Showcases] Clear Showcase Source Assortment',
  CLEAR_SHOWCASE_SOURCE_ASSORTMENT_SUCCESS = '[Showcases] Clear Showcase Source Assortment Success',

  LOAD_CURRENT_SHOWCASE = '[Showcases] Load Current Showcase',
  LOAD_CURRENT_SHOWCASE_SUCCESS = '[Showcases] Load Current Showcase Success',
  LOAD_CURRENT_SHOWCASE_FAILURE = '[Showcases] Load Current Showcase Failure',

  CLEAR_CURRENT_SHOWCASE = '[Showcases] Clear Current Showcase',
  SET_ITEMS_USED_IN_SHOWCASE = '[Showcases] Load Item ids added to the showcase',

  SET_GRID_FRAME_ACTIVE_CONTAINER = '[Showcases] Set Grid Frame Active Container',
  SET_COLLECTION_FRAME_ACTIVE_CONTAINER = '[Showcases] Set Collection Frame Active Container',

  SET_ANNOTATION_TYPE = '[Showcases] Set Annotation Type',

  SET_STATUS_MESSAGE_ELEMENT = '[Showcases] Set Status Message Element',

  SET_SHOW_SOURCE_ASSORTMENT_WARNING = '[Showcases] Set Show Source Assortment Warning',
}

////////////////////////////////////////////////////////////////////////////////
export const loadShowcases = createAction(ShowcasesActionTypes.LOAD_SHOWCASES);

export const loadShowcasesSuccess = createAction(
  ShowcasesActionTypes.LOAD_SHOWCASES_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadShowcasesFailure = createAction(ShowcasesActionTypes.LOAD_SHOWCASES_FAILURE, props<{ error: any }>());

export const createShowcase = createAction(ShowcasesActionTypes.CREATE_SHOWCASE, props<{ showcase: Showcase }>());

export const createShowcaseSuccess = createAction(
  ShowcasesActionTypes.CREATE_SHOWCASE_SUCCESS,
  props<{ showcase: Showcase }>(),
);

export const createShowcaseFailure = createAction(
  ShowcasesActionTypes.CREATE_SHOWCASE_FAILURE,
  props<{ error: any }>(),
);

export const copyShowcase = createAction(
  ShowcasesActionTypes.COPY_SHOWCASE,
  props<{ name: string; sourceId: string }>(),
);

export const copyShowcaseSuccess = createAction(
  ShowcasesActionTypes.COPY_SHOWCASE_SUCCESS,
  props<{ showcase: Showcase }>(),
);

export const copyShowcaseFailure = createAction(ShowcasesActionTypes.COPY_SHOWCASE_FAILURE, props<{ error: any }>());

export const asyncCopyShowcase = createAction(
  ShowcasesActionTypes.ASYNC_COPY_SHOWCASE,
  props<{ name: string; sourceId: string }>(),
);

export const asyncCopyShowcaseSuccess = createAction(
  ShowcasesActionTypes.ASYNC_COPY_SHOWCASE_SUCCESS,
  props<{ jobId: string; path: string }>(),
);

export const updateShowcase = createAction(
  ShowcasesActionTypes.UPDATE_SHOWCASE,
  props<{ id: string; changes: Showcase }>(),
);

export const updateShowcaseSuccess = createAction(
  ShowcasesActionTypes.UPDATE_SHOWCASE_SUCCESS,
  props<{ id: string; changes: Showcase }>(),
);

export const updateShowcaseFailure = createAction(
  ShowcasesActionTypes.UPDATE_SHOWCASE_FAILURE,
  props<{ error: any }>(),
);

export const deleteShowcase = createAction(ShowcasesActionTypes.DELETE_SHOWCASE, props<{ showcase: Showcase }>());

export const deleteShowcaseSuccess = createAction(
  ShowcasesActionTypes.DELETE_SHOWCASE_SUCCESS,
  props<{ showcase: Showcase }>(),
);

export const deleteShowcaseFailure = createAction(
  ShowcasesActionTypes.DELETE_SHOWCASE_FAILURE,
  props<{ error: any }>(),
);

export const loadCurrentShowcase = createAction(ShowcasesActionTypes.LOAD_CURRENT_SHOWCASE, props<{ id: string }>());

export const loadCurrentShowcaseSuccess = createAction(
  ShowcasesActionTypes.LOAD_CURRENT_SHOWCASE_SUCCESS,
  props<{ showcase: Showcase }>(),
);

export const loadCurrentShowcaseFailure = createAction(
  ShowcasesActionTypes.LOAD_CURRENT_SHOWCASE_FAILURE,
  props<{ error: any }>(),
);

export const clearCurrentShowcase = createAction(ShowcasesActionTypes.CLEAR_CURRENT_SHOWCASE);

export const setItemsUsedInShowcase = createAction(
  ShowcasesActionTypes.SET_ITEMS_USED_IN_SHOWCASE,
  props<{ itemsUsedInShowcase: Array<Item> }>(),
);

export const setGridFrameActiveContainer = createAction(
  ShowcasesActionTypes.SET_GRID_FRAME_ACTIVE_CONTAINER,
  props<{ gridFrameActiveContainer: PresentationCollectionElement }>(),
);

export const setCollectionFrameActiveContainer = createAction(
  ShowcasesActionTypes.SET_COLLECTION_FRAME_ACTIVE_CONTAINER,
  props<{ collectionFrameActiveContainer: PresentationCollectionElement }>(),
);

export const setAnnotationType = createAction(
  ShowcasesActionTypes.SET_ANNOTATION_TYPE,
  props<{ annotationType: string }>(),
);

export const setStatusMessageElement = createAction(
  ShowcasesActionTypes.SET_STATUS_MESSAGE_ELEMENT,
  props<{ statusMessageElement: any }>(),
);

export const assignShowcaseAssortment = createAction(
  ShowcasesActionTypes.ASSIGN_SHOWCASE_SOURCE_ASSORTMENT,
  props<{ id: string; sourceAssortmentId: string }>(),
);

export const assignShowcaseAssortmentSuccess = createAction(
  ShowcasesActionTypes.ASSIGN_SHOWCASE_SOURCE_ASSORTMENT_SUCCESS,
  props<{ showcase: any }>(),
);

export const clearShowcaseSourceAssortment = createAction(
  ShowcasesActionTypes.CLEAR_SHOWCASE_SOURCE_ASSORTMENT,
  props<{ id: string }>(),
);

export const clearShowcaseSourceAssortmentSuccess = createAction(
  ShowcasesActionTypes.CLEAR_SHOWCASE_SOURCE_ASSORTMENT_SUCCESS,
  props<{ showcase: any }>(),
);

export const setShowSourceAssortmentWarning = createAction(
  ShowcasesActionTypes.SET_SHOW_SOURCE_ASSORTMENT_WARNING,
  props<{ showSourceAssortmentWarning: boolean }>(),
);
