import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { ListComponent } from './list.component';
import { ListItemCellComponent } from './list-item-cell/list-item-cell.component';
import { PipesModule } from '@pipes/pipes.module';
import { DirectivesModule } from '@directives/directives.module';
import { VirtualScrollListComponent } from './virtual-scroll-list/virtual-scroll-list.component';

@NgModule({
  declarations: [ListComponent, ListItemCellComponent, VirtualScrollListComponent],
  imports: [CommonModule, PipesModule, DirectivesModule, VirtualScrollerModule, MatTooltipModule],
  exports: [ListComponent, VirtualScrollListComponent],
})
export class ListModule {}
