import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export interface SidePanelView {
  label: string;
  slug: string;
  icon: string;
}
@Injectable({
  providedIn: 'root',
})
export class SidePanelService {
  public sidePanelViews: Array<SidePanelView> = [
    {
      label: 'Summary',
      slug: 'summary',
      icon: 'bar_chart',
    },
    {
      label: 'Add Product',
      slug: 'addProduct',
      icon: 'add',
    },
    {
      label: 'Comments',
      slug: 'comments',
      icon: 'comment',
    },
  ];

  private currentViewObject: SidePanelView = this.sidePanelViews[0];
  private currentViewSubject: Subject<SidePanelView> = new BehaviorSubject(this.currentViewObject);
  public currentView: Observable<SidePanelView> = this.currentViewSubject.asObservable();

  constructor() {}

  public setCurrentView(view: SidePanelView) {
    this.currentViewObject = view;
    this.currentViewSubject.next(this.currentViewObject);
  }

  public removeView(viewSlug: string) {
    if (this.currentViewObject && this.currentViewObject.slug === viewSlug) {
      this.currentViewObject = null;
      this.currentViewSubject.next(null);
    }
  }
}
