import { Component, OnInit } from '@angular/core';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PresentationCollectionElement } from 'src/app/presentation/presentation';
import { ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { ComposerGridFrameService } from '../../composer-frame/composer-grid-frame/composer-grid-frame.service';

@Component({
  selector: 'app-composer-toolbar-grid-frame',
  templateUrl: './composer-toolbar-grid-frame.component.html',
  styleUrls: ['./composer-toolbar-grid-frame.component.scss'],
})
export class ComposerToolbarGridFrameComponent implements OnInit {
  gridFrameActiveContainer$: Observable<PresentationCollectionElement>;
  constructor(
    private store: Store<RootStoreState.State>,
    private composerGridFrameService: ComposerGridFrameService,
  ) {
    this.gridFrameActiveContainer$ = this.store.select(ShowcasesSelectors.gridFrameActiveContainer);
  }

  ngOnInit(): void {}

  addSection() {
    this.composerGridFrameService.addSection();
  }

  addSubSection() {}
}
