import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { combineLatest, fromEvent, Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { CommentsActions, CommentsSelectors } from 'src/app/common/comments/comments-store';
import { Comment, CommentsService } from 'src/app/common/comments/comments.service';
import { DocumentService } from '../../document/document.service';
import { DocumentElement, PositionDefinition, DocumentElementEvent, DocumentChangeType } from '@contrail/documents';
import { ComposerService } from '../composer.service';
import { Showcase } from 'src/app/showcases/showcases-store/showcases.state';
import { DocumentViewSize } from '../../document/document-store/document.state';
import { PresentationFrame } from '../../presentation';
import { DocumentSelectors } from '../../document/document-store';
import { CoordinateHelper } from '../../svg/handlers/coordinate-helper';
import { AddPinnedCommentsService } from './add-pinned-comments-service';
import { ShowPinnedCommentsListenerService } from './show-pinned-comments-listener-service';
import { ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { EntityReference } from '@contrail/sdk';
import { CanvasUtil } from 'src/app/presentation/canvas-lib';

export interface PinnedComment {
  groupKey: string; // key by which comments are grouped, for example 'showcase:300:400', 'elementId:100:50'
  count: number;
  firstComment: Comment;
  comments: Array<Comment>;
  hidden: boolean;
  disableDrag: boolean;
  documentElement?: {
    id: string;
    position: PositionDefinition;
    itemId?: string;
  };
}

@Component({
  selector: 'app-composer-pinned-comments',
  templateUrl: './composer-pinned-comments.component.html',
  styleUrls: ['./composer-pinned-comments.component.scss'],
})
export class ComposerPinnedComments implements OnInit {
  private showcase: Showcase;
  private frame: PresentationFrame;
  private elements: Array<DocumentElement>;
  private accessLevel: string = 'EDIT';
  private subscription: Subscription = new Subscription();
  private wheelEvent$: Subscription;
  private readonly PINNED_COMMENT_WIDTH = 34;

  public viewSize: DocumentViewSize;
  public pinnedComments$: Observable<Array<PinnedComment>>;
  public showPinnedComments: boolean;
  public isDragging: boolean = false;
  public mainCanvas: HTMLElement;

  constructor(
    private store: Store<RootStoreState.State>,
    private documentService: DocumentService,
    private composerService: ComposerService,
    private addPinnedCommentsService: AddPinnedCommentsService,
    private showPinnedCommentsListenerService: ShowPinnedCommentsListenerService,
    private commentsService: CommentsService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store.select(ShowcasesSelectors.currentShowcase).subscribe((showcase) => (this.showcase = showcase)),
    );
    this.store.select(DocumentSelectors.viewSize).subscribe((viewSize) => {
      this.viewSize = viewSize;
    });
    this.subscription.add(
      this.documentService.documentElements.subscribe((elements) => {
        this.elements = elements;
        this.mainCanvas = document.getElementById('mainCanvas');
      }),
    ); // Need to subscribe to document elements because document.elements is not updated on changes

    this.subscription.add(
      this.documentService.documentElementEvents
        .pipe(filter((event) => !event || event?.eventType === 'dragStarted'))
        .subscribe((event) => {
          this.initPinnedComments(event);
        }),
    );

    this.subscription.add(
      this.documentService.documentElementEvents
        .pipe(filter((event) => !event || event?.eventType === 'dragEnded'))
        .subscribe((event) => {
          this.initPinnedComments(event);
        }),
    );

    this.subscription.add(
      this.documentService.documentActions
        .pipe(
          map((actions) => {
            !actions?.length ||
              actions.filter(
                (action) =>
                  action.changeDefinition.changeType === DocumentChangeType.DELETE_ELEMENT ||
                  action.changeDefinition.changeType === DocumentChangeType.ADD_ELEMENT,
              );
          }),
        )
        .subscribe((actions) => {
          this.initPinnedComments();
        }),
    );

    this.subscription.add(
      this.store.select(CommentsSelectors.showPinnedComments).subscribe((showPinnedComments) => {
        this.showPinnedComments = showPinnedComments;
      }),
    );

    this.subscription.add(
      this.store.select(CommentsSelectors.showCommentOverlay).subscribe((bool) => {
        if (!bool) {
          this.wheelEvent$?.unsubscribe();
        } else {
          this.subscribeToWheelEvent();
        }
      }),
    );

    this.initPinnedComments();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // Pinned comments - combine latest:
  // - all comments
  // - when elements are dragged (need to change comments position)
  // - when elements are deleted (need to hide elements from the showcase)
  // Filter comments that have either documentElementId or documentPosition
  // Group comments by each position on the screen or element
  // showcase:500:400, elementId:100:200, etc
  private initPinnedComments(event?: DocumentElementEvent) {
    this.pinnedComments$ = combineLatest([
      this.store.select(CommentsSelectors.selectContextComments),
      this.composerService.currentFrame,
    ]).pipe(
      map(([comments, frame]) => {
        this.frame = frame;
        if (!this.frame) {
          return [];
        }
        return comments.filter((comment) => comment.status !== 'closed' && this.isDocumentComment(comment, frame.id));
      }),
      map((comments) => Object.values(this.groupByDocumentElement(comments, event))),
    );
  }

  private isDocumentComment(comment: Comment, frameId) {
    return CommentsService.isDocumentComment(comment, `presentation-frame:${frameId}`);
  }

  /**
   * Group a list of @comments into buckets of each comment group. For example:
   * 'showcase:300:500': {},
   * 'showcase:100:500': {},
   * 'elementId123:50:300': {},
   * 'elementId123:150:200': {},
   * 'elementId456:100:20': {}
   * @param comments
   * @param event
   */
  private groupByDocumentElement(comments: Array<Comment>, event?: DocumentElementEvent) {
    return comments.reduce((counter, comment) => {
      const groupKey = `${comment.documentElementId || 'showcase'}:${comment.documentPosition.x},${comment.documentPosition.y}`;
      if (counter.hasOwnProperty(groupKey)) {
        counter[groupKey].comments.push(comment);
        counter[groupKey].count = counter[groupKey].count + 1;
      } else {
        const element = this.getDocumentElement(comment.documentElementId);
        if (comment.documentElementId && element === undefined) {
          // if element was deleted do not show the comment on the showcase
          return counter;
        }
        let itemId;
        if (element?.modelBindings?.item) {
          const entityReference = new EntityReference(element.modelBindings.item);
          itemId = entityReference.id;
        }
        counter[groupKey] = {
          groupKey,
          count: 1,
          firstComment: comment,
          comments: [comment],
          hidden:
            event &&
            event.selectedElements?.length > 0 &&
            event.selectedElements.findIndex((e) => e.id === element?.id) !== -1,
          disableDrag: !this.commentsService.canUpdateComment(comment, this.accessLevel),
          documentElement: element
            ? {
                id: element.id,
                position: element.position,
                itemId,
              }
            : null,
        };
      }
      return counter;
    }, {});
  }

  private getDocumentElement(documentElementId?: string): DocumentElement {
    return documentElementId ? (this.elements || []).find((element) => element.id === documentElementId) : null;
  }

  private toWindowPosition(position: PositionDefinition): PositionDefinition {
    if (this.viewSize) {
      return CanvasUtil.toWindowPosition(
        position?.x,
        position?.y,
        this.viewSize.viewBox,
        this.viewSize.viewScale,
        this.viewSize.boundingClientRect,
      );
    }
  }

  /**
   * If comment overlay is open adjust it's position when window position is changed
   */
  private adjustCommentOverlayPosition() {
    const commentOverlayPosition = this.addPinnedCommentsService.commentOverlayPosition;
    if (commentOverlayPosition) {
      const windowPosition: PositionDefinition = this.toWindowPosition(commentOverlayPosition);
      this.store.dispatch(
        CommentsActions.updateCommentOverlayPosition({
          position: {
            x: windowPosition.x,
            y: windowPosition.y,
          },
        }),
      );
    }
  }

  private onMouseWheel(event) {
    this.adjustCommentOverlayPosition();
  }

  private subscribeToWheelEvent() {
    if (!this.wheelEvent$ || this.wheelEvent$.closed) {
      this.wheelEvent$ = fromEvent(window, 'wheel', { passive: true })
        .pipe(
          tap((event: any) => {
            this.onMouseWheel(event);
          }),
        )
        .subscribe();
    }
  }
  /**
   * Get top and left style for the comment bubble
   * @param comment
   * @param documentElement
   */
  getStyle(comment: Comment, documentElement?: { id: string; position: PositionDefinition }) {
    const position = {
      x: comment.documentPosition.x + (documentElement ? documentElement.position.x : 0),
      y: comment.documentPosition.y + (documentElement ? documentElement.position.y : 0),
    };
    const windowPosition: PositionDefinition = this.toWindowPosition(position);
    return {
      top: `${windowPosition.y - this.PINNED_COMMENT_WIDTH}px`,
      left: `${windowPosition.x}px`,
    };
  }

  /**
   * Show comment overlay with comment bubble is clicked
   * @param comment
   * @param documentElement
   */
  showComments(comment: Comment, documentElement?: { id: string; position: PositionDefinition; itemId?: string }) {
    if (this.isDragging) {
      this.isDragging = false;
      return;
    }
    const commentOverlayDocumentPosition = {
      x: comment.documentPosition.x + (documentElement ? documentElement.position.x : 0),
      y: comment.documentPosition.y + (documentElement ? documentElement.position.y : 0),
    };

    const ownerInfo = {
      entityType: 'showcase',
      id: this.showcase.id,
      documentPosition: comment.documentPosition,
      documentElementId: comment.documentElementId,
      subContextReference: comment.subContextReference,
    };

    if (documentElement?.itemId) {
      ownerInfo.entityType = 'item';
      ownerInfo.id = documentElement.itemId;
    }

    this.addPinnedCommentsService.addCommentToDocumentElement(ownerInfo, commentOverlayDocumentPosition);
  }

  trackByGroupKey(index, item) {
    return item.groupKey;
  }

  public cdkDragStarted($event) {
    this.isDragging = true;
    this.store.dispatch(CommentsActions.hideCommentOverlay());
  }

  public cdkDragEnded($event, comments: Array<Comment>) {
    const commentBubbleRect = $event.source.element.nativeElement.getBoundingClientRect();
    this.addPinnedCommentsService.updateCommentsPosition(
      {
        x: commentBubbleRect.x,
        y: commentBubbleRect.y + 34,
      },
      comments,
    );
    this.isDragging = false;
    this.initPinnedComments();
  }
}
