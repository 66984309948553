import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Feature, FeatureFlag } from './feature-flag';
import { Entities } from '@contrail/sdk';
import { v4 as uuid } from 'uuid';
import { FeatureFlagsSelectors } from '.';
import { Observable } from 'rxjs';
export interface FeatureFlagRegistry {
  [key: string]: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagRegistryService {
  constructor(private store: Store<RootStoreState.State>) {}

  public async getFeatureFlags(): Promise<Array<FeatureFlag>> {
    let featureFlags = await new Entities().get({ entityName: 'feature-flag' });
    if (!environment.production && environment.name !== 'STAGING') {
      console.log({ featureFlags });
      console.log('Ignoring feature flags since we are in a development environment. Enabling all beta features');
      featureFlags = [];
      for (const key of Object.keys(Feature)) {
        featureFlags.push({
          featureName: key,
          id: 'generated' + uuid(),
        });
      }
    }
    console.log('enabled feature flags:', featureFlags);
    return featureFlags;
  }

  /** Used to get an observable that watches a given feature flag */
  public getFeatureObservable(feature: Feature): Observable<boolean> {
    return this.store.select(FeatureFlagsSelectors.featureFlags).pipe(
      map((flags: FeatureFlag[]) => {
        for (const flag of flags) {
          if (flag.featureName === feature.valueOf()) {
            return true;
          }
        }
        return false;
      }),
    );
  }
}
