<div>
  <div
    id="canvas-container"
    (dragenter)="dragEnter($event)"
    (dragover)="dragOver($event)"
    (dragleave)="dragLeave($event)"
    (drop)="drop($event)"
  >
    <div id="slideCanvas"></div>
    <div width="100%" height="100%" id="mainCanvas"></div>
    <!-- <div (click)="debug()">debug</div> -->
  </div>
</div>
<div class="debug" *ngIf="sizePositionHandler">
  <div>Document Size: w:{{ document?.size.width }}, h:{{ document?.size.height }}</div>
  <div>
    Slide Size: w: {{ sizePositionHandler.slideCanvasSize?.width | number: '1.00-0' }}, h:
    {{ sizePositionHandler.slideCanvasSize?.height | number: '1.00-0' }}
  </div>
  <div>
    Displayable Size: w:{{ sizePositionHandler.displayableSize?.width }}, h:{{
      sizePositionHandler.displayableSize?.height
    }}
  </div>
  <div>
    ViewBox: w:{{ sizePositionHandler.viewBox.width | number: '1.00-0' }}, h:{{
      sizePositionHandler.viewBox.height | number: '1.00-0'
    }}, x:{{ sizePositionHandler.viewBox?.x | number: '1.00-0' }}, y:{{
      sizePositionHandler.viewBox?.y | number: '1.00-0'
    }}
  </div>
  <div>
    SVG Size: w:{{ sizePositionHandler.svgSize?.width | number: '1.00-0' }}, h:{{
      sizePositionHandler.svgSize?.height | number: '1.00-0'
    }}
  </div>
  <div>
    Pan Offset: x: {{ sizePositionHandler.panOffset?.x | number: '1.00-0' }}, y:
    {{ sizePositionHandler.panOffset?.y | number: '1.00-0' }}
  </div>
  <div>Zoom Factor: {{ sizePositionHandler.zoomFactor }}</div>
</div>

<!-- <app-composer-canvas-navigation-bar (zoomIn)="sizePositionHandler.zoomIn()" (zoomOut)="sizePositionHandler.zoomOut()" (center)="sizePositionHandler.center()" class="floating-menu"></app-composer-canvas-navigation-bar> -->
