const DELETE_KEY = 'Delete';
const BACKSPACE_KEY = 'Backspace';
const ESCAPE_KEY = 'Escape';
const SPACE_KEY = 'Space';
const ALT_LEFT_KEY = 'AltLeft';
const ALT_RIGHT_KEY = 'AltRight';
const AKEY = 'KeyA';
const BKEY = 'KeyB';
const CKEY = 'KeyC';
const DKEY = 'KeyD';
const FKEY = 'KeyF';
const GKEY = 'KeyG';
const IKEY = 'KeyI';
const KKey = 'KeyK';
const LKey = 'KeyL';
const MKey = 'KeyM';
const PKey = 'KeyP';
const TKey = 'KeyT';
const UKEY = 'KeyU';
const VKEY = 'KeyV';
const XKEY = 'KeyX';
const YKEY = 'KeyY';
const ZKEY = 'KeyZ';
const UP_KEY = 'ArrowUp';
const DOWN_KEY = 'ArrowDown';
const LEFT_KEY = 'ArrowLeft';
const RIGHT_KEY = 'ArrowRight';
const EQUAL = 'Equal';
const MINUS = 'Minus';
const ADD = 'NumpadAdd';
const SUBTRACT = 'NumpadSubtract';
const PgUp_KEY = 'PageUp';
const PgDn_KEY = 'PageDown';
const ENTER_KEY = 'Enter';

const KEY_EVENT_IGNORE_PATHS = [
  'app-property-configurator-color-picker',
  'collection-frame-create-template-view',
  'app-comment-overlay',
  'app-comment-form',
  'app-create-grid-frame-section',
  'app-update-grid-frame-section',
  'app-item-data-chooser',
  'color-picker',
  'app-component-editor',
  'app-item-details',
  'app-new-grid-frame-modal',
  'app-search-bar',
  'app-page-header',
  'mat-selection-list',
  'app-search-replace',
  'mat-dialog-container', // added to cover full item details modal
  'app-composer-frame-toolbar-name',
  'textarea',
  'input',
];

import { Injectable } from '@angular/core';
import { ActionRequest } from '@contrail/actions';
import { ComposerService } from '../composer/composer.service';
import { DocumentService } from '../document/document.service';
import { UndoRedoHandler } from './undo-redo-handler';
import { AddPinnedCommentsService } from '../composer/composer-pinned-comments/add-pinned-comments-service';
import { ShowcasesActions } from 'src/app/showcases/showcases-store';
import { Store } from '@ngrx/store';
import { State } from '../document/document-store/document.state';
import { SearchReplaceActions } from '@common/search-replace/search-replace-store';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';

@Injectable({
  providedIn: 'root',
})
export class KeyboardHandler {
  public isLoading: boolean;
  public editorMode: EditorMode;
  constructor(
    private documentService: DocumentService,
    private composerService: ComposerService,
    private undoRedoHandler: UndoRedoHandler,
    private addPinnedCommentsService: AddPinnedCommentsService,
    private store: Store<State>,
  ) {
    this.store.select(EditorModeSelectors.editorMode).subscribe((m) => (this.editorMode = m));
    this.init();
  }
  private init() {
    document.onkeydown = this.handleKeyDown.bind(this);
    document.onkeyup = this.handleKeyUp.bind(this);
  }

  handleKeyUp(event: KeyboardEvent) {
    if (!this.isEventAllowed(event)) {
      return;
    }
    switch (event.code) {
      case SPACE_KEY:
        this.composerService?.sizePositionHandler?.stopGrabMode(event);
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  handleKeyDown(event) {
    //console.log('KeyboardHandler: handleKeyDown: ', event);
    if (!this.isEventAllowed(event)) {
      return;
    }

    switch (event.code) {
      case BACKSPACE_KEY:
      case DELETE_KEY: {
        if (this.editorMode !== EditorMode.EDIT) {
          return;
        }
        const selectedFrameObject = this.composerService.getSelectedFrameObject();
        if (selectedFrameObject) {
          const ids = this.composerService.selectedFrameIdsSubject.value;
          if (ids.length > 1) {
            let frames = [];
            this.composerService.getPresentationFrames().forEach((f) => {
              if (ids.includes(f.id)) frames.push(f);
            });
            this.composerService.deleteFrames(frames);
          } else {
            this.composerService.deleteFrame(selectedFrameObject);
          }
        } else {
          this.documentService.handleActionRequest(new ActionRequest('delete_element'));
        }
        break;
      }
      case ALT_LEFT_KEY:
      case ALT_RIGHT_KEY:
        this.composerService?.sizePositionHandler?.stopGrabMode(event);
        break;
      case ESCAPE_KEY:
        this.documentService.cancelCrop();
        if (this.documentService.getSelectedElements()?.length > 0) {
          this.documentService.deselectAllElements();
        }
        this.documentService.setInteractionMode('select');
        this.store.dispatch(ShowcasesActions.setAnnotationType({ annotationType: null }));
        break;
      case SPACE_KEY:
        if (!event.repeat) {
          this.composerService?.sizePositionHandler?.startGrabMode(event);
          event.preventDefault();
        }
        break;
      case AKEY:
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
          this.documentService.selectAllElements();
          this.documentService.handleActionRequest(new ActionRequest('copy_elements'));
        }
        break;
      case BKEY: {
        if (event.ctrlKey || event.metaKey) {
          this.documentService.emitTextElementKeyEvent({ decoratorType: 'bold' });
        }
        break;
      }
      case CKEY: {
        if (event.ctrlKey || event.metaKey) {
          if (event.altKey) {
            event.preventDefault();
            this.documentService.handleActionRequest(new ActionRequest('copy_properties'));
          } else {
            this.documentService.handleActionRequest(new ActionRequest('copy_elements'));
            this.composerService.clearClipboardFrames();
            if (
              this.composerService.getSelectedFrameIds().length > 0 &&
              this.documentService.getSelectedElements().length === 0
            ) {
              // key event from `app-composer-frame-tray`
              this.documentService.documentClipboard.clear();
              this.documentService.documentClipboard.clearElementFormatContent();
              this.composerService.setClipboardFrames();
              // we need to rely on copying texts to the machine's clipboard for c/p across showcases
              this.composerService.composerClipboard.copyFrames();
            } else {
              // app-composer-frame
              this.documentService.handleActionRequest(new ActionRequest('copy_elements'));
            }
          }
        }
        break;
      }
      case DKEY: {
        if (event.ctrlKey || event.metaKey) {
          this.documentService.handleActionRequest(new ActionRequest('duplicate_elements', event));
          event.preventDefault();
        }
        break;
      }
      case FKEY: {
        if (event.ctrlKey || event.metaKey) {
          this.documentService.submitCrop();
          this.store.dispatch(SearchReplaceActions.toggleSearch());
          event.stopPropagation();
          event.preventDefault();
        }
        break;
      }
      case GKEY: {
        if (event.ctrlKey || event.metaKey) {
          this.documentService.handleActionRequest(new ActionRequest('toggle_group_elements', event));
          event.preventDefault();
        }
        break;
      }
      case IKEY: {
        if (event.ctrlKey || event.metaKey) {
          this.documentService.emitTextElementKeyEvent({ decoratorType: 'italic' });
        }
        break;
      }
      case KKey: {
        if (this.editorMode !== EditorMode.EDIT) {
          return;
        }
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
          this.documentService.handleActionRequest(new ActionRequest('toggle_mask_elements'));
        }
        break;
      }
      case LKey: {
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
          this.documentService.submitCrop();
          this.documentService.handleActionRequest(new ActionRequest('lock_elements'));
        }
        break;
      }
      case MKey: // add comments
        if (this.editorMode === EditorMode.VIEW) {
          return;
        }

        if (event.ctrlKey || event.metaKey) {
          this.addPinnedCommentsService.addCommentsFromKeyEvent();
          event.preventDefault();
        }
        break;
      case PKey: {
        if (this.editorMode === EditorMode.VIEW) {
          return;
        }
        this.documentService.setInteractionMode('pen');
        break;
      }
      case TKey:
        if (this.editorMode !== EditorMode.EDIT) {
          return;
        }
        this.documentService.setInteractionMode('create_text_element');
        break;
      case UKEY: {
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
          this.documentService.emitTextElementKeyEvent({ decoratorType: 'underline' });
        }
        break;
      }
      case VKEY: {
        if (!event.ctrlKey && !event.metaKey) {
          if (this.documentService.getSelectedElements()?.length > 0) {
            this.documentService.deselectAllElements();
          }
          this.documentService.setInteractionMode('select');
          this.store.dispatch(ShowcasesActions.setAnnotationType({ annotationType: null }));
        } else if (event.ctrlKey || event.metaKey) {
          if (event.altKey) {
            this.documentService.handleActionRequest(new ActionRequest('paste_properties'));
          }
        }
        break;
      }
      case XKEY: {
        if (event.ctrlKey || event.metaKey) {
          const selectedFrameObject = this.composerService.getSelectedFrameObject();
          if (selectedFrameObject) {
            this.composerService.composerClipboard.copyFrame(null);
            this.composerService.deleteFrame(selectedFrameObject);
          } else {
            this.documentService.handleActionRequest(new ActionRequest('copy_elements'));
            this.documentService.handleActionRequest(new ActionRequest('delete_element'));
          }
        }
        break;
      }
      case YKEY: {
        if (this.editorMode !== EditorMode.EDIT) {
          return;
        }
        if (event.ctrlKey || event.metaKey) {
          this.documentService.cancelCrop();
          this.undoRedoHandler.redoActions();
          event.preventDefault();
        }
        break;
      }
      case ZKEY: {
        if (this.editorMode !== EditorMode.EDIT) {
          return;
        }
        if ((event.ctrlKey && event.shiftKey) || (event.metaKey && event.shiftKey)) {
          this.documentService.cancelCrop();
          this.undoRedoHandler.redoActions();
        } else if (event.ctrlKey || event.metaKey) {
          this.documentService.cancelCrop();
          this.undoRedoHandler.undoActions();
        }
        break;
      }
      case PgUp_KEY: {
        const sourceEvent = { selectedElements: this.documentService.getSelectedElements() };
        if (event.shiftKey) {
          event.preventDefault();
          this.documentService.handleActionRequest(new ActionRequest('order.bring_forward', sourceEvent));
        } else {
          this.documentService.handleActionRequest(new ActionRequest('order.bring_to_front', sourceEvent));
        }
        break;
      }
      case PgDn_KEY: {
        const sourceEvent = { selectedElements: this.documentService.getSelectedElements() };
        if (event.shiftKey) {
          event.preventDefault();
          this.documentService.handleActionRequest(new ActionRequest('order.send_backward', sourceEvent));
        } else {
          this.documentService.handleActionRequest(new ActionRequest('order.send_to_back', sourceEvent));
        }
        break;
      }
      case UP_KEY:
      case DOWN_KEY:
      case RIGHT_KEY:
      case LEFT_KEY:
        const selected = this.documentService.getSelectedElements();
        this.documentService.submitCrop();
        if (selected?.length > 0) {
          this.documentService.handleActionRequest(new ActionRequest('move_element', event));
        } else {
          this.navigateDocumentFrames(event);
        }
        break;
      case PgUp_KEY:
      case PgDn_KEY:
        this.navigateDocumentFrames(event);
        break;
      case EQUAL:
      case ADD:
        if (!event.shiftKey && !event.metaKey && !event.ctrlKey) {
          this.composerService?.sizePositionHandler?.zoomIn();
        }
        break;
      case MINUS:
      case SUBTRACT:
        if (!event.shiftKey && !event.metaKey && !event.ctrlKey) {
          this.composerService?.sizePositionHandler?.zoomOut();
        }
        break;
      case ENTER_KEY:
        this.documentService.submitCrop();
        break;
    }
  }

  private navigateDocumentFrames(event) {
    switch (event.code) {
      case PgUp_KEY:
      case UP_KEY:
      case LEFT_KEY:
        this.composerService.navigateToPreviousFrame();
        break;
      case PgDn_KEY:
      case DOWN_KEY:
      case RIGHT_KEY:
        this.composerService.navigateToNextFrame();
        break;
    }
  }

  isEventAllowed(event: any) {
    for (const el of event.composedPath()) {
      if (KEY_EVENT_IGNORE_PATHS.includes(el.tagName?.toLowerCase())) {
        return false;
      }
    }
    return true;
  }
}
