<app-modal [title]="'Item Collection'" [dialogRef]="dialogRef">
  <div>Define a filter to use for this item collection.</div>
  <div class="filter-properties">
    <div class="filter-property-row">
      <mat-form-field appearance="standard">
        <mat-label>Property</mat-label>
        <mat-select>
          <mat-option
            [value]="property"
            *ngFor="let property of properties"
            (onSelectionChange)="selectFilterProperty($event)"
          >
            {{ property.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="standard" *ngIf="currentProperty">
        <mat-label>Value</mat-label>
        <mat-select>
          <mat-option
            [value]="option"
            *ngFor="let option of currentProperty.options"
            (onSelectionChange)="selectFilterValue($event)"
          >
            {{ option.display }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="actions">
    <button mat-raised-button color="primary" (click)="submit()">OK</button>
  </div>
</app-modal>
