import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ChooserSourceOption } from '@common/item-data-chooser/source-option';
import { Types } from '@contrail/sdk';
import { PropertyType, TypeProperty, TypePropertyOption } from '@contrail/types';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ComposerGridFrameService } from '../composer-grid-frame.service';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { AssortmentsService } from '@common/assortments/assortments.service';
import { AssortmentsSelectors } from '@common/assortments/assortments-store';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';

@Component({
  selector: 'app-new-grid-frame-modal',
  templateUrl: './new-grid-frame-modal.component.html',
  styleUrls: ['./new-grid-frame-modal.component.scss'],
})
export class NewGridFrameModalComponent implements OnInit, OnDestroy {
  public currentProperty: TypeProperty;
  public typeProperties = [];
  public filteredProperties1: Observable<any[]>;
  public filteredProperties2: Observable<any[]>;
  public dimension1Options: TypePropertyOption[] = [];
  public dimension2Options: TypePropertyOption[] = [];

  frameType = new UntypedFormControl('manual');
  sourceAssortmentId: any;
  currentWorkspaceId: any;
  public assortmentFormControl = new UntypedFormControl({ value: null, disabled: false }, Validators.required);
  public sourceWorkspaceControl = new UntypedFormControl({ value: null, disabled: false }, Validators.required);
  public dimensionControl1 = new UntypedFormControl(null, {});
  public dimensionControl2 = new UntypedFormControl(null, {});
  public dimensionValueControl1 = new UntypedFormControl({ value: null, disabled: true }, []);
  public dimensionValueControl2 = new UntypedFormControl({ value: null, disabled: true }, []);
  public dimension1Values: string[] = [];
  public dimension2Values: string[] = [];

  public filter = {};
  public selectedAssortmentObject: any;
  page = 1;
  splitDimension = false;
  emptyValueSection = false;
  subscriptions = new Subscription();
  constructor(
    private store: Store<RootStoreState.State>,
    public dialogRef: MatDialogRef<NewGridFrameModalComponent>,
    private composerGridFrameService: ComposerGridFrameService,
  ) {
    this.dimensionControl2.disable();
    this.filteredProperties1 = this.dimensionControl1.valueChanges.pipe(
      startWith(''),
      map((property) => (property ? this.filterProperties(property) : this.typeProperties.slice())),
    );
    this.subscriptions.add(
      this.dimensionControl1.valueChanges.subscribe((valueChanges) => {
        if (valueChanges === '') {
          this.dimensionValueControl1.disable();
          this.dimensionValueControl1.setValue(null);
          this.dimension1Values = [];
        }
      }),
    );
    this.filteredProperties2 = this.dimensionControl2.valueChanges.pipe(
      startWith(''),
      map((property) => (property ? this.filterProperties(property) : this.typeProperties.slice())),
    );
    this.subscriptions.add(
      this.dimensionControl2.valueChanges.subscribe((valueChanges) => {
        if (valueChanges === '') {
          this.dimensionValueControl2.disable();
          this.dimensionValueControl2.setValue(null);
          this.dimension2Values = [];
        }
      }),
    );
  }

  async ngOnInit() {
    this.subscriptions.add(
      this.store.select(AssortmentsSelectors.sourceAssortment).subscribe((sourceAssortment) => {
        this.sourceAssortmentId = sourceAssortment.id;
        this.selectedAssortmentObject = sourceAssortment;
        this.setAssortment(sourceAssortment);
      }),
    );
    this.subscriptions.add(
      this.store
        .select(WorkspacesSelectors.currentWorkspace)
        .subscribe((currentWorkspace) => (this.currentWorkspaceId = currentWorkspace.id)),
    );
    this.typeProperties = await this.composerGridFrameService.getTypeProperties();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async setAssortment(assortment) {
    console.log('setAssortment: ', assortment);
    if (assortment) {
      if (assortment.id === this.selectedAssortmentObject?.id) {
        // don't load assortment again if it already exists.
        return;
      }
      this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));
      this.selectedAssortmentObject = await AssortmentsService.getAssortment(assortment.id);
      this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
    } else {
      this.selectedAssortmentObject = null;
    }
  }

  toggleSplitDimension(event) {
    this.splitDimension = event.checked;
    this.dimensionControl2.enable();
    if (!event.checked) {
      this.dimensionControl2.setValue(null);
      this.dimensionControl2.disable();
    }
  }

  toggleEmptyValueSection(event) {
    this.emptyValueSection = event.checked;
  }

  async create() {
    if (this.frameType.value === 'manual') {
      this.composerGridFrameService.createGridFrame();
      this.dialogRef.close();
    } else {
      let invalid = false;
      const dimension1 = this.dimensionControl1.value;
      if (!this.dimensionControl1.value?.slug) {
        this.dimensionControl1.markAsTouched();
        this.dimensionControl1.setErrors(['Dimension 1 is required.']);
        invalid = true;
      }
      if (this.dimension1Values.length === 0) {
        this.dimensionValueControl1.markAsTouched();
        this.dimensionValueControl1.setErrors(['Dimension 1 Values cannot be empty.']);
        invalid = true;
      }
      if (this.dimensionControl2.value?.slug && this.dimension2Values.length === 0) {
        this.dimensionValueControl2.markAsTouched();
        this.dimensionValueControl2.setErrors(['Dimension 2 Values cannot be empty.']);
        invalid = true;
      }
      if (!invalid) {
        let dimension2 = null;
        if (this.splitDimension) {
          dimension2 = this.dimensionControl2.value || {};
          if (dimension2.slug && this.dimension2Values.length === 0) {
          }
        }

        const result = await this.composerGridFrameService.createGridFrameByAssortment(
          this.selectedAssortmentObject,
          dimension1,
          dimension2,
          this.dimension1Values,
          this.dimension2Values,
          this.emptyValueSection,
        );
        if (result.status === 'success') {
          this.dialogRef.close();
        } else {
          if (result.dimension === '1') {
            this.dimensionControl1.setErrors([result.message]);
          } else {
            this.dimensionControl2.setErrors([result.message]);
          }
        }
      }
    }
  }

  async onDimensionSelected(event, dimension) {
    dimension === 1 ? this.dimensionValueControl1.enable() : this.dimensionValueControl2.enable();
    if (event.option.value) {
      const property = event.option.value;
      const options = await this.setDimensionOptions(property);
      if (dimension === 1) {
        this.dimension1Options = options;
      } else {
        this.dimension2Options = options;
      }
    }
  }

  async setDimensionOptions(property) {
    const options = await this.composerGridFrameService.getPropertyOptions(this.selectedAssortmentObject, property);
    if (property.propertyDefinition.propertyType === 'choice') {
      return property.propertyDefinition.options.filter((option) => options.includes(option.value));
    } else {
      options.sort();
      return options.map((option) => {
        return {
          value: option,
          display: option,
        };
      });
    }
  }

  setDimension1Values(event) {
    this.dimension1Values = event.selectedValues;
    if (event.selectedValues?.length > 0) {
      this.dimensionValueControl1.setValue(event.selectedValues?.length + ' Selected');
    } else {
      this.dimensionValueControl1.setValue(null);
    }
  }

  setDimension2Values(event) {
    this.dimension2Values = event.selectedValues;
    if (event.selectedValues?.length > 0) {
      this.dimensionValueControl2.setValue(event.selectedValues?.length + ' Selected');
    } else {
      this.dimensionValueControl2.setValue(null);
    }
  }

  getErrorMessage() {
    if (this.dimensionControl1.hasError('required')) {
      return 'Dimension 1 is required';
    }

    return '';
  }

  next() {
    this.page = 2;
  }

  back() {
    // restore selected workspace & assortment
    if (this.selectedAssortmentObject) {
      this.currentWorkspaceId =
        this.selectedAssortmentObject.rootWorkspaceId || this.selectedAssortmentObject.workspaceId;
      this.sourceAssortmentId = this.selectedAssortmentObject.id;
    }
    this.page = 1;
    this.dimensionControl1.setValue(null);
    this.dimensionControl2.setValue(null);
    this.dimensionValueControl1.setValue(null);
    this.dimensionValueControl2.setValue(null);
  }

  cancel() {
    this.dialogRef.close();
  }

  displayFn(property: any): string {
    return property && property.propertyDefinition ? property.propertyDefinition.label : '';
  }

  getPropertyIcon(propertyType: string) {
    switch (propertyType) {
      case 'string':
        return 'title';
      case 'date':
        return 'today';
      case 'currency':
        return 'attach_money';
      case 'number':
        return 'tag';
      default:
        return 'arrow_drop_down_circle';
    }
  }

  private filterProperties(value: any): any[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.typeProperties.filter((property) =>
        property.propertyDefinition.label.toLowerCase().includes(filterValue),
      );
    }
  }
}
