import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-toolbar-paste-modal',
  templateUrl: './composer-toolbar-paste-modal-component.html',
  styleUrls: ['./composer-toolbar-paste-modal-component.scss'],
})
export class ComposerToolbarPasteModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ComposerToolbarPasteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  closeModal(obj = {}) {
    this.dialogRef.close(obj);
  }

  handleClose() {
    this.closeModal();
  }
}
