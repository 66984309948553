<div
  *ngIf="object && objectName"
  class="object-chip"
  [style.cursor]="clickable ? 'pointer' : 'default'"
  (click)="viewDetails()"
>
  <span
    class="value"
    *ngIf="hasStatus"
    [ngClass]="{ released: colorStage === 'released', development: colorStage === 'development' }"
    class="status-circle"
  ></span>
  <span class="value" *ngIf="objectNumber">{{ objectNumber }}&nbsp;:&nbsp;</span>
  <span class="value">{{ this.objectName }}</span>
  <mat-icon *ngIf="edit" class="remove" (click)="remove()">close</mat-icon>
</div>
