<app-mat-spinner [loading]="isLoading" [overlay]="true" *ngIf="isLoading"></app-mat-spinner>

<app-page-section>
  <app-showcase-presentation-breadcrumb
    [breadCrumb]="breadCrumb"
    (levelClicked)="goToLevel($event)"
    *ngIf="!isLoading"
  ></app-showcase-presentation-breadcrumb>
  <div *ngIf="optionList && !isLoading" cdkDropList class="sortable-list" (cdkDropListDropped)="drop($event)">
    <div class="list-value" *ngFor="let option of optionList" cdkDrag>
      <div class="left">
        <mat-icon cdkDragHandle>drag_handle</mat-icon>
        {{ option.label }}
      </div>
      <div class="actions">
        <mat-slide-toggle
          class="example-margin"
          [labelPosition]="'before'"
          [checked]="option.enabled"
          (change)="toggleEnabled(option)"
        >
          Include
        </mat-slide-toggle>
        <div class="counts" *ngIf="selectedOptionType === productsOnOutlinePage">
          {{ (option.children | filter: { key: 'enabled', value: true }).length }}/{{ option.children?.length }}
          Products Selected
        </div>
        <div class="counts" *ngIf="selectedOptionType === colorwaysOnProductsPage">
          {{ (option.children | filter: { key: 'enabled', value: true }).length }}/{{ option.children?.length }}
          Colorways Selected
        </div>
        <button *ngIf="!leafNode" mat-button [disabled]="!option.enabled" (click)="expandOption(option)">
          <mat-icon>arrow_forward_ios</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="!isLoading && !presentationConfg">
    You must set a source assortment on the showcase before configuring its presentation.
  </div>
</app-page-section>
