<div
  class="composer-toolbar flex-between-center"
  #composerToolbar
  [ngStyle]="{ paddingRight: toggleChooser ? '348px' : '0px' }"
>
  <div class="flex items-center">
    <app-add-frame-widget data-test="add-frame-widget" *ngIf="editorMode === 'EDIT'" />
    <app-composer-frame-toolbar #frameToolbar />
    <mat-divider vertical="true" class="divider"></mat-divider>

    <ng-container *ngIf="entitySnapshot$ | async">
      <button mat-flat-button>
        <div class="flex items-center text-primary">
          <mat-icon>content_copy</mat-icon>
          <div data-test="paste-as-new-frame-button" class="ml-1" (click)="pasteAsNewFrame()">PASTE AS NEW FRAME</div>
        </div>
      </button>
      <mat-divider vertical="true" class="divider"></mat-divider>
    </ng-container>

    <ng-container *ngIf="editorMode === 'EDIT'">
      <app-composer-toolbar-undo-redo />
      <ng-container *ngIf="isDocumentFrame">
        <app-paint-formatter data-test="composer-toolbar-paint-formatter" />
        <button class="!p-1" mat-button [matMenuTriggerFor]="zoomPresets" data-test="zoom-menu-button">
          <span>{{ 100 * (viewSize$ | async)?.viewScale?.x | number: '1.0-0' }}%</span>
          <span class="material-icons m-auto"> arrow_drop_down </span>
        </button>
      </ng-container>

      <mat-divider vertical="true" class="divider"></mat-divider>

      <!-- <app-composer-toolbar-copy-paste *ngIf="!isCollectionFrame"/> -->
      <!-- <mat-divider vertical="true" class="divider" *ngIf="!isCollectionFrame"></mat-divider> -->

      <!-- Always >>>>> -->
      <ng-container *ngIf="!miniChooserPanel">
        <ng-container *ngTemplateOutlet="chooserPanel" />
      </ng-container>
      <button (click)="resetInteractionMode()" matTooltip="Select" mat-icon-button data-test="select-button">
        <mat-icon svgIcon="pointer_icon" class="toggle-icon scale-[.85]" [class.active]="isActive()"></mat-icon>
      </button>
      <app-composer-toolbar-annotation *ngIf="isCollectionFrame || isGridFrame" />
      <app-document-annotation-tool *ngIf="isDocumentFrame" />
      <app-composer-toolbar-item *ngIf="promoteFeatureFlag" [frameType]="frameType" />
      <!-- >>>>> Always -->

      <!-- CANVAS TOOLBAR OPTIONS ------------------------------------------------------------------------------------------------ -->
      <!-- Text-Image-Pen-Shapes-Line-Sticky-Embed -->
      <ng-container *ngIf="isDocumentFrame && !miniNewElement">
        <ng-container *ngTemplateOutlet="newElement" />
      </ng-container>

      <button mat-icon-button [matMenuTriggerFor]="advancedMenu" [matTooltip]="'Advanced'">
        <!-- ADVANCED MENU - LINEBOARD | APP EXTENSIONS | Always show -->
        <mat-icon svgIcon="magic" />
      </button>
      <mat-divider vertical="true" class="divider"></mat-divider>

      <!-- More Styling if Element is unlocked. -->
      <ng-container *ngIf="isDocumentFrame && !isLocked">
        <!-- STICKY NOTES -->
        <ng-container *ngIf="!miniStickyStyles">
          <ng-container *ngTemplateOutlet="stickyNotes" />
        </ng-container>
        <!-- TEXT OPTIONS -->
        <ng-container *ngIf="!miniTextStyles">
          <ng-container *ngTemplateOutlet="textStyle" />
        </ng-container>
        <!-- MORE STYLES -->
        <ng-container *ngIf="!miniMoreStyles">
          <ng-container *ngTemplateOutlet="moreStyles" />
        </ng-container>
        <!-- Component Format Options -->
        <ng-container *ngIf="!miniComponentButton">
          <ng-container *ngTemplateOutlet="componentFormat" />
        </ng-container>
      </ng-container>

      <!-- GRID || COLLECTION FRAME -------- `Add Section | Layout Options`   |||  `Preview | Layout Options` -------------- -->
      <ng-container *ngIf="(isGridFrame || isCollectionFrame) && !miniTextButtons">
        <ng-container *ngTemplateOutlet="textButtons" />
      </ng-container>

      <ng-container *ngIf="documentGenerationConfigId && !miniEditLineBoard">
        <ng-container *ngTemplateOutlet="editLineboard" />
      </ng-container>

      <!-- EXPORT PDF _ POWERPOINT _ EXCEL -->
      <ng-container *ngIf="!miniExport">
        <ng-container *ngTemplateOutlet="exportButton" />
      </ng-container>

      <button
        *ngIf="showMoreButton"
        mat-icon-button
        [matMenuTriggerFor]="minibar"
        data-test="composer-toolbar-more-button"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #minibar="matMenu" xPosition="before" class="mini-bar">
        <ng-container *ngIf="miniChooserPanel">
          <ng-container *ngTemplateOutlet="chooserPanel" />
        </ng-container>
        <ng-container *ngIf="isDocumentFrame">
          <ng-container *ngIf="miniNewElement">
            <ng-container *ngTemplateOutlet="newElement" />
          </ng-container>
          <ng-container *ngIf="!isLocked">
            <ng-container *ngIf="miniStickyStyles">
              <ng-container *ngTemplateOutlet="stickyNotes" />
            </ng-container>
            <ng-container *ngIf="miniTextStyles">
              <ng-container *ngTemplateOutlet="textStyle" />
            </ng-container>
            <ng-container *ngIf="miniMoreStyles">
              <ng-container *ngTemplateOutlet="moreStyles" />
            </ng-container>
            <ng-container *ngIf="miniComponentButton">
              <ng-container *ngTemplateOutlet="componentFormat" />
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="(isGridFrame || isCollectionFrame) && miniTextButtons">
          <ng-container *ngTemplateOutlet="textButtons" />
        </ng-container>

        <ng-container *ngIf="documentGenerationConfigId && miniEditLineBoard">
          <ng-container *ngTemplateOutlet="editLineboard"></ng-container>
        </ng-container>

        <ng-container *ngIf="miniExport">
          <ng-container *ngTemplateOutlet="exportButton"></ng-container>
        </ng-container>
      </mat-menu>

      <!-- ALERT at bottom right -->
      <app-download-content-snack
        class="fixed bottom-2 z-[60]"
        [ngClass]="{ 'right-96': toggleChooser, 'right-6': !toggleChooser }"
      />
    </ng-container>
  </div>

  <!-- Right Side - 80px - dashboard-search -->
  <div class="flex items-center pl-2">
    <div [matTooltip]="'Dashboard'" *ngIf="dashboardFeatureFlag">
      <button mat-icon-button (click)="toggleDashboard()" data-test="app-dashboard-button">
        <mat-icon svgIcon="dashboard_icon"></mat-icon>
      </button>
    </div>
    <div [matTooltip]="'Search (Ctrl-F)'">
      <button mat-icon-button (click)="toggleSearch()" data-test="app-search-button">
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </div>
</div>

<!-- Chooser Panel 'ITEM' | 'ASSET' | 'COLOR' -->
<ng-template #chooserPanel>
  <button data-test="composer-toolbar-add-item" mat-icon-button matTooltip="Add Items" (click)="showItemChooser()">
    <mat-icon svgIcon="app-action-options"></mat-icon>
  </button>
  <ng-container *ngIf="libraryFeatureFlag">
    <img
      src="/assets/images/item.svg"
      alt=""
      class="select-chooser"
      (click)="showItemChooser(true)"
      matTooltip="Item Library"
      data-test="composer-toolbar-item-library"
    />
    <img
      src="/assets/images/color.svg"
      alt=""
      class="select-chooser"
      (click)="showColorChooser()"
      matTooltip="Color Library"
    />
    <img
      src="/assets/images/asset.svg"
      alt=""
      class="select-chooser"
      (click)="showAssetChooser()"
      matTooltip="Asset Library"
    />
    <mat-divider vertical="true" class="divider"></mat-divider>
  </ng-container>
</ng-template>

<!-- Canvas Tools `Add Elements` 252px | 7 Items -->
<ng-template #newElement>
  <app-composer-toolbar-add-element (click)="prevent($event)" />
  <mat-divider *ngIf="miniNewElement" vertical="true" class="divider"></mat-divider>
</ng-template>

<!-- MASK | LINE | Background Color | Border | Round -->
<ng-template #moreStyles>
  <!-- Mask - image+shape -->
  <app-composer-toolbar-mask-element [elements]="elements" data-test="composer-toolbar-mask-elements" />
  <!-- Line-maker | 2 way direction -->
  <app-property-configurator-line-marker
    data-test="composer-toolbar-line-marker"
    *ngIf="properties.includes('line-marker')"
    class="m-auto"
    (valueChange)="updateValues($event)"
    [currentMarkerStart]="getCurrentValue('lineDefinition.markerStart')"
    [currentMarkerEnd]="getCurrentValue('lineDefinition.markerEnd')"
  />
  <!-- background-color -->
  <app-property-configurator-color-picker
    matTooltip="Fill color"
    data-test="composer-toolbar-fill-color"
    *ngIf="properties.includes('background-color')"
    (click)="prevent($event)"
    (valueChange)="handleDelayedValueChange($event)"
    [currentValue]="getCurrentValue('style.backgroundColor')"
    [matIconType]="'format_color_fill'"
    [colorType]="'backgroundColor'"
  />
  <!-- border color size style -->
  <button
    class="border-style-button"
    *ngIf="
      properties.includes('border-color') || properties.includes('border-size') || properties.includes('border-style')
    "
    data-test="composer-toolbar-border-group"
    (click)="prevent($event)"
    mat-icon-button
    [matMenuTriggerFor]="borderStyle"
    matTooltip="Border"
  >
    <mat-icon>border_style</mat-icon>
  </button>
  <!-- border-radius -->
  <app-property-configurator-border-radius
    matTooltip="Rounded corners"
    data-test="composer-toolbar-border-radius"
    *ngIf="properties.includes('border-radius')"
    (click)="prevent($event)"
    (valueChange)="updateValues($event)"
    [currentValue]="getCurrentValue('style.border.radius')"
  />

  <app-property-configurator-color-picker
    *ngIf="properties.includes('pen-border-color')"
    matTooltip="Line color"
    (click)="$event.preventDefault(); $event.stopPropagation()"
    (valueChange)="updateValues($event)"
    [currentValue]="getCurrentValue('style.border.color')"
    [matIconType]="'border_color'"
    [colorType]="'borderColor'"
    [closeOnSelect]="true"
    [colorChip]="true"
  />
  <app-property-configurator-border-size
    *ngIf="properties.includes('pen-border-size')"
    matTooltip="Line thickness"
    (click)="$event.preventDefault(); $event.stopPropagation()"
    (valueChange)="updateValues($event)"
    [currentValue]="getCurrentValue('style.border.width')"
    [borderSizes]="[2, 4, 6, 8, 10, 12, 14, 16, 24, 48, 96]"
  />
</ng-template>

<ng-template #stickyNotes>
  <app-property-configurator-sticky-note-background
    matTooltip="Edit background"
    data-test="sticky-note-background"
    (click)="prevent($event)"
    (valueChange)="handleDelayedValueChange($event)"
    [currentValue]="getCurrentValue('style.backgroundColor')"
    [selectedElement]="elements[0]"
    *ngIf="properties.includes('sticky-note-background')"
  />
  <app-property-configurator-sticky-note-size
    matTooltip="Edit size"
    data-test="sticky-note-size"
    (click)="prevent($event)"
    (valueChange)="updateStickyNoteElement($event)"
    [currentValue]="getCurrentValue('size')"
    [selectedElement]="elements[0]"
    *ngIf="properties.includes('sticky-note-size')"
  />
  <app-property-configurator-font-family-selector
    data-test="font-family-selector"
    matTooltip="Font"
    *ngIf="properties.includes('sticky-note-font-family')"
    (click)="prevent($event)"
    (valueChange)="updateStickyNoteElement($event)"
    [currentValue]="getTextAttributeValue('fontFamily')"
  />
  <app-property-configurator-font-size
    class="property-configurator-font-size-sticky-note mx-1"
    data-test="font-size"
    matTooltip="Font size"
    *ngIf="properties.includes('sticky-note-font-size')"
    (click)="prevent($event)"
    (valueChange)="updateStickyNoteElement($event)"
    [defaultValue]="'10'"
    [sizes]="['Auto', '4', '8', '10', '12', '14', '18', '24', '32', '48', '64']"
    [autoSize]="true"
    [autoSizeValue]="getTextAttributeValue('fontSizeMode')"
    [maxValue]="getTextAttributeValue('maxFontSize')"
    [minValue]="4"
    [currentValue]="getTextAttributeValue('fontSize')"
  />
</ng-template>

<ng-template #textStyle>
  <app-property-configurator-text-type
    *ngIf="properties.includes('font-family')"
    data-test="text-type-selector"
    (valueChange)="updateTextElement($event)"
    [currentValue]="getTextAttributeValue('textType')"
  />

  <mat-divider *ngIf="properties.includes('font-family')" vertical="true" class="divider"></mat-divider>

  <app-property-configurator-font-family-selector
    data-editor="tinymce"
    data-test="composer-toolbar-font-family"
    matTooltip="Font"
    *ngIf="properties.includes('font-family')"
    (click)="prevent($event)"
    (valueChange)="updateTextElement($event)"
    [currentValue]="getTextAttributeValue('fontFamily')"
  />
  <app-property-configurator-font-size
    data-editor="tinymce"
    data-test="composer-toolbar-font-size"
    matTooltip="Font size"
    *ngIf="properties.includes('font-size')"
    (click)="prevent($event)"
    (valueChange)="updateTextElement($event)"
    [currentValue]="getTextAttributeValue('fontSize')"
  />
  <app-property-configurator-color-picker
    data-test="composer-toolbar-text-color"
    matTooltip="Text color"
    *ngIf="properties.includes('font-color')"
    (click)="prevent($event)"
    (valueChange)="updateTextElement($event)"
    [currentValue]="getTextAttributeValue('textColor')"
    [matIconType]="'format_color_text'"
    [colorType]="'fontColor'"
  />

  <button
    *ngIf="
      properties.includes('font-bold') ||
      properties.includes('font-italic') ||
      properties.includes('font-underline') ||
      properties.includes('font-strikethrough') ||
      properties.includes('font-color') ||
      properties.includes('clear-format')
    "
    data-test="composer-toolbar-text-group"
    (click)="prevent($event)"
    mat-icon-button
    [matMenuTriggerFor]="textFormatting"
    matTooltip="Text formatting"
  >
    <mat-icon svgIcon="text-formatting"></mat-icon>
  </button>
  <button
    *ngIf="
      properties.includes('text-alignment') || properties.includes('list-type') || properties.includes('indentation')
    "
    data-test="composer-toolbar-paragraph"
    (click)="prevent($event)"
    mat-icon-button
    [matMenuTriggerFor]="paragraph"
    matTooltip="Paragraph"
  >
    <mat-icon svgIcon="format-paragraph"></mat-icon>
  </button>

  <app-property-configurator-link
    *ngIf="properties.includes('link') && this.textFormat?.position"
    matTooltip="Add link"
    data-editor="tinymce"
    data-test="composer-toolbar-link"
    (click)="prevent($event)"
    (valueChange)="updateTextElement($event)"
    [element]="textElement"
    [position]="getTextAttributeValue('position')"
    [currentValue]="getTextAttributeValue('link')"
  />
</ng-template>

<ng-template #componentFormat>
  <ng-container *ngIf="elementType === 'component'">
    <mat-divider vertical="true" class="divider"></mat-divider>
    <button mat-button data-test="composer-toolbar-format-options" (click)="showComponentConfigurator()">
      Format options
    </button>
  </ng-container>
</ng-template>

<ng-template #editLineboard>
  <button mat-button (click)="editLineboardConfig()" data-test="composer-toolbar-lineboard-options">
    <div class="flex items-center">Lineboard options</div>
  </button>
</ng-template>

<ng-template #textButtons>
  <mat-divider vertical="true" class="divider"></mat-divider>
  <!-- Add Section | Layout Options -->
  <ng-container *ngIf="isGridFrame">
    <app-composer-toolbar-grid-frame *ngIf="isGridFrame" />
    <button mat-button (click)="showGridFrameLayout()" data-test="composer-toolbar-layout-options">
      Layout Options
    </button>
  </ng-container>
  <!-- Preview | Layout Options -->
  <ng-container *ngIf="isCollectionFrame">
    <button mat-button (click)="showCollectionFramePreview()">Preview</button>
    <button mat-button (click)="showCollectionFrameLayout()" data-test="composer-toolbar-layout-options">
      Layout Options
    </button>
  </ng-container>
</ng-template>

<ng-template #exportButton>
  <app-composer-export (click)="prevent($event)" />
</ng-template>

<mat-menu #textFormatting="matMenu" class="text-menu">
  <app-property-configurator-text-decorator
    data-editor="tinymce"
    data-test="composer-toolbar-font-bold"
    matTooltip="Bold"
    decoratorType="bold"
    [matIconType]="'format_bold'"
    *ngIf="properties.includes('font-bold')"
    (valueChange)="updateTextElement($event)"
    [currentValue]="getTextAttributeValue('bold')"
  />
  <app-property-configurator-text-decorator
    data-editor="tinymce"
    data-test="composer-toolbar-font-italic"
    matTooltip="Italic"
    decoratorType="italic"
    [matIconType]="'format_italic'"
    *ngIf="properties.includes('font-italic')"
    (valueChange)="updateTextElement($event)"
    [currentValue]="getTextAttributeValue('italic')"
  />
  <app-property-configurator-text-decorator
    data-editor="tinymce"
    data-test="composer-toolbar-font-underline"
    matTooltip="Underline"
    decoratorType="underline"
    [matIconType]="'format_underline'"
    *ngIf="properties.includes('font-underline')"
    (valueChange)="updateTextElement($event)"
    [currentValue]="getTextAttributeValue('underline')"
  />
  <app-property-configurator-text-decorator
    data-editor="tinymce"
    data-test="composer-toolbar-font-strikethrough"
    matTooltip="Strikethrough"
    decoratorType="strikethrough"
    [matIconType]="'strikethrough_s'"
    *ngIf="properties.includes('font-strikethrough')"
    (valueChange)="updateTextElement($event)"
    [currentValue]="getTextAttributeValue('strikethrough')"
  />
  <app-property-configurator-color-picker
    data-test="composer-toolbar-highlight-color"
    matTooltip="Highlight text"
    *ngIf="properties.includes('font-color')"
    (click)="prevent($event)"
    (valueChange)="updateTextElement($event)"
    [currentValue]="getTextAttributeValue('textBackgroundColor')"
    [svgIconType]="'highlight'"
    [colorType]="'textBackgroundColor'"
  />
  <button
    class="tinymce-format-button"
    *ngIf="properties.includes('clear-format')"
    (click)="clearFormat($event)"
    matTooltip="Clear format"
    mat-icon-button
  >
    <mat-icon class="toggle-icon">format_clear</mat-icon>
  </button>
</mat-menu>

<mat-menu #paragraph="matMenu" class="paragraph-menu">
  <app-property-configurator-text-alignment
    data-editor="tinymce"
    data-test="composer-toolbar-text-align"
    matTooltip="Text align"
    *ngIf="properties.includes('text-alignment')"
    (click)="prevent($event)"
    (valueChange)="updateTextElement($event)"
    [currentValue]="getTextAttributeValue('textAlign')"
  />
  <app-property-configurator-list-type
    data-editor="tinymce"
    data-test="composer-toolbar-list-type"
    matTooltip="Bullet lists"
    *ngIf="properties.includes('list-type')"
    (click)="prevent($event)"
    (valueChange)="updateTextElement($event)"
    [currentValue]="getTextAttributeValue('listType')"
  />
  <app-property-configurator-indentation
    data-editor="tinymce"
    data-test="composer-toolbar-indentation"
    *ngIf="properties.includes('indentation')"
    (valueChange)="updateTextElement($event)"
    [currentValue]="getTextAttributeValue('indentation')"
  />
</mat-menu>

<mat-menu #borderStyle="matMenu" class="border-style-menu">
  <app-property-configurator-color-picker
    matTooltip="{{ elementType === 'line' ? 'Line color' : 'Border color' }}"
    data-test="composer-toolbar-border-color"
    *ngIf="properties.includes('border-color')"
    (click)="prevent($event)"
    (valueChange)="handleDelayedValueChange($event)"
    [currentValue]="getCurrentValue('style.border.color')"
    [matIconType]="'border_color'"
    [colorType]="'borderColor'"
  />
  <app-property-configurator-border-size
    matTooltip="{{ elementType === 'line' ? 'Line weight' : 'Border weight' }}"
    data-test="composer-toolbar-border-weight"
    *ngIf="properties.includes('border-size')"
    (click)="prevent($event)"
    (valueChange)="updateValues($event)"
    [currentValue]="getCurrentValue('style.border.width')"
  />
  <app-property-configurator-border-style
    matTooltip="{{ elementType === 'line' ? 'Line style' : 'Border style' }}"
    data-test="composer-toolbar-border-style"
    *ngIf="properties.includes('border-style')"
    (click)="prevent($event)"
    (valueChange)="updateValues($event)"
    [currentValue]="getCurrentValue('style.border.style')"
  />
</mat-menu>

<!-- ZOOM PRESETS MENU -->
<mat-menu #zoomPresets="matMenu" xPosition="before" class="menu-md">
  <button mat-menu-item class="!px-5" (click)="zoomToFit()" data-test="zoom-to-fit">Zoom to fit</button>
  <button mat-menu-item class="!px-5" (click)="setZoomScale(0.5)">Zoom to 50%</button>
  <button mat-menu-item class="!px-5" (click)="setZoomScale(1)">Zoom to 100%</button>
  <button mat-menu-item class="!px-5" (click)="setZoomScale(2)">Zoom to 200%</button>
  <button mat-menu-item class="!px-5" (click)="setZoomScale(3)">Zoom to 300%</button>
</mat-menu>

<!-- ADVANCED MENU - LINEBOARD | APP EXTENSIONS -->
<mat-menu #advancedMenu="matMenu" xPosition="before" class="menu-md">
  <button mat-menu-item (click)="generateLineboard()">
    <mat-icon class="material-icons-outlined !text-black60">space_dashboard</mat-icon>
    <span class="!text-black60">Lineboard</span>
  </button>
</mat-menu>

<!--
<div class="flex-center" *ngIf="isDocumentFrame">
  <mat-divider *ngIf="elementType && elementType !== 'select'" vertical="true" class="divider"></mat-divider>
  <app-property-configurator-locked-element
    *ngIf="elementType && elementType !== 'select'"
    data-test="composer-toolbar-locked-element"
    (valueChange)="updateValues($event)"
    [currentValue]="getCurrentValue('isLocked')"
    [element]="elements[0]"
  />
</div>
-->
