<div (click)="focusItemColor($event)" class="itemColor">
  <div class="top-widgets">
    <app-comments-count *ngIf="includeCommentWidget" [ownerInfo]="getCommentOwnerInfo()"></app-comments-count>
    <div><ng-template dataSelectionToggle #vv></ng-template></div>
  </div>

  <div *ngIf="image" class="image-holder" [ngClass]="{ 'opacity-60': itemMissingFromSource }">
    <img [attr.src]="image | secureImage | async" />
    <div class="absolute" *ngIf="itemMissingFromSource" matTooltip="Missing from source assortment">
      <mat-icon svgIcon="warning-alert"></mat-icon>
    </div>
  </div>
  <div class="image-placeholder" *ngIf="!image" [ngClass]="{ 'opacity-60': itemMissingFromSource }">
    <div class="h-14 w-14"><mat-icon>panorama</mat-icon></div>
    <div class="absolute" *ngIf="itemMissingFromSource" matTooltip="Missing from source assortment">
      <mat-icon svgIcon="warning-alert"></mat-icon>
    </div>
  </div>
  <div class="meta">
    <div class="option-name">
      {{ itemColor?.properties.optionName }}
      <img
        src="\assets\primary_color_indicator.png"
        *ngIf="itemColor?.properties.primary === 'Yes'"
        class="primary_color_indicator"
      />
    </div>
    <div class="item-meta">
      <app-property-value
        *ngFor="let prop of metaViewDefinition?.properties"
        [value]="itemColor?.properties[prop.slug]"
        [property]="prop.propertyDefinition"
        [propertyType]="prop.propertyType"
      ></app-property-value>
    </div>
  </div>
</div>
