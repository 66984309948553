import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { ComposerService } from '../../composer.service';
import { DocumentGeneratorConfigComponent } from 'src/app/presentation/document-generator/document-generator-config/document-generator-config.component';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { DocumentService } from 'src/app/presentation/document/document.service';
import { ShowcaseGenerateFramesUtil } from 'src/app/presentation/document-generator/showcase-generate-frames-util';
import { nanoid } from 'nanoid';
import { ConfirmationBoxService } from '@components/confirmation-box/confirmation-box';
import { ShowcaseFrame } from '@contrail/document-generation/lib/frames/frame';
import { DocumentElement } from '@contrail/documents';
import { DocumentItemService } from 'src/app/presentation/document/document-item/document-item.service';
import { AssortmentsActions } from '@common/assortments/assortments-store';
import { DocumentGenerationResults } from 'src/app/presentation/document-generator/document-generator.interfaces';
import { Entities } from '@contrail/sdk';
import { Presentation } from 'src/app/presentation/presentation';
import { FrameTemplatesService } from '@common/frame-templates/frame-templates.service';

@Injectable({
  providedIn: 'root',
})
export class ComposerGenerateCanvasFramesService {
  public currentFrame;
  presentation: Presentation;
  constructor(
    private store: Store<RootStoreState.State>,
    private composerService: ComposerService,
    private documentService: DocumentService,
    private confirmationBoxService: ConfirmationBoxService,
    private frameTemplatesService: FrameTemplatesService,
    private matDialog: MatDialog,
  ) {
    this.composerService.currentFrame.subscribe((frame) => {
      this.currentFrame = frame;
    });
    this.composerService.presentation.subscribe((pres) => {
      this.presentation = pres;
    });
  }

  showCreateShowroomFrame() {
    //this.dialog.open(NewShowroomFrameModalComponent, {width: '688px'});
  }

  async generateFrames() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '730px';
    dialogConfig.data = {};
    const dialogRef = this.matDialog.open(DocumentGeneratorConfigComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));
        const documentGenerationConfig = await new Entities().create({
          entityName: 'document-generation-config',
          object: result.generationConfig,
        });
        const newFrames = await this.addFrames(result.generationOptions, documentGenerationConfig);
        await this.composerService.generateFrames(newFrames);
        this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
      }
    });
  }

  async regenerateFrames(data: any, skipUndoRedo = false) {
    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));
    let generationOptions = data.generationOptions;
    if (!generationOptions) {
      generationOptions = await ShowcaseGenerateFramesUtil.generationConfigToOptions(
        data.generationConfig,
        this.store,
        this.frameTemplatesService,
      );
    }
    const newFrames = await this.addFrames(generationOptions, data.generationConfig);
    await this.composerService.regenerateFrames(data, newFrames, skipUndoRedo);
    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
  }

  private async addFrames(generationOptions: any, documentGenerationConfig) {
    if (generationOptions.assortmentItems.length > 0 && generationOptions.groupingProperties) {
      const results: DocumentGenerationResults =
        await ShowcaseGenerateFramesUtil.generateShowcaseFrames(generationOptions);
      if (results.frames.length > 0) {
        results.frames.forEach((frame) => {
          frame.documentGenerationConfigId = documentGenerationConfig.id;
        });
        return await this.addFramesToPresentation(results.frames);
      }
    }
  }

  private async addFramesToPresentation(frames: Array<ShowcaseFrame>): Promise<any[]> {
    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Generating Frames' }));
    const backingAssortmentItems = [];
    const createFrames = [];
    for (let frame of frames) {
      const newFrame = {
        name: '',
        type: 'document',
        ownedByReference: `presentation:${this.presentation.id}`,
        document: {
          size: {
            width: 1200,
            height: 675,
          },
          id: nanoid(),
          elements: frame.toDocumentElements(),
        },
        documentGenerationConfigId: frame.documentGenerationConfigId,
      };
      createFrames.push(newFrame);
      this.getItemsFromDocumentElements(newFrame.document.elements, backingAssortmentItems);
    }
    //if (backingAssortmentItems.length) {
    //this.store.dispatch(AssortmentsActions.addItemsToBackingAssortment({ itemIds: backingAssortmentItems }));
    //}
    return createFrames;
  }

  getItemsFromDocumentElements(elements: DocumentElement[], backingAssortmentItems: any[]) {
    let assortmentItems = elements.filter(DocumentItemService.isItemComponet);
    assortmentItems
      .map((element) => element.modelBindings.item.split(':')[1])
      .forEach((item) => {
        backingAssortmentItems.push(item);
      });
  }
}
