import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { escapeRegExp } from 'lodash';
import { SortObjects } from '../../components/sort/sort-objects';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { ItemData } from '../../item-data/item-data';
import { SortDefinition } from '../../components/sort/sort-definition';
import { Types } from '@contrail/sdk';
import { PropertyType, Type } from '@contrail/types';
import { FilterDefinition } from '@common/types/filters/filter-definition';
import { FilterObjects } from '@contrail/filters';

export interface ChooserFilterConfig {
  searchTerm?: string;
  filterDefinition?: FilterDefinition;
  auth?: any;
  baseCriteria?: any;
  criteria?: any;
}

export abstract class ItemDataChooserDataSource {
  protected filteredDataSubscription: Subscription;
  protected filteredDataSubject: Subject<Array<ItemData>> = new BehaviorSubject([]);
  protected filteredData$: Observable<Array<ItemData>> = this.filteredDataSubject.asObservable();

  private dataSubscription: Subscription;
  private resultsSubject: Subject<Array<ItemData>> = new BehaviorSubject([]);
  public results$: Observable<Array<ItemData>> = this.resultsSubject.asObservable();
  protected assortmentSubject: Subject<Array<ItemData>> = new BehaviorSubject([]);
  public assortmentItemData$ = this.assortmentSubject.asObservable();
  protected searchableProperties = [];

  protected loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$: Observable<boolean> = this.loadingSubject.asObservable();

  public resultsCount$: Subject<number> = new BehaviorSubject(null);
  public id = Math.random();

  constructor(
    protected store: Store<RootStoreState.State>,
    protected filterConfigSubject: Observable<ChooserFilterConfig>,
    protected sortConfigSubject: Observable<SortDefinition[]>,
    protected existingItemIdsSubject: Observable<any>,
    protected showAllSubject: Observable<any>,
    protected dateFilterSubject: Observable<FilterDefinition>,
  ) {
    this.initSearchableProperties();
  }

  private async initSearchableProperties() {
    const itemType = await new Types().getType({ root: 'item', path: 'item' });
    const projectItemType = await new Types().getType({ root: 'project-item', path: 'project-item' });
    const assortmentItemType = await new Types().getType({ root: 'assortment-item', path: 'assortment-item' });

    this.searchableProperties = [
      ...itemType.typeProperties,
      ...projectItemType.typeProperties,
      ...assortmentItemType.typeProperties,
    ]
      .filter((p) => {
        return [PropertyType.String, PropertyType.Text, PropertyType.SingleSelect, PropertyType.MultiSelect].includes(
          p.propertyType,
        );
      })
      .map((p) => p.slug);
  }

  public filterLocalItemData({ searchTerm, itemFamilyId = null }, data, additionalProperties = []) {
    const mappedNames = additionalProperties.map((p) => 'properties.' + p);
    const searchOptions = ['properties.name', 'properties.optionName', ...mappedNames];

    let filtered = [];
    if (data?.length) {
      filtered = data.filter((item) => {
        return (
          (!searchTerm?.length ||
            searchOptions.some((key) =>
              new RegExp(escapeRegExp(searchTerm), 'gi').test(ObjectUtil.getByPath(item, key.trim())),
            )) &&
          (!itemFamilyId || item.properties.itemFamilyId === itemFamilyId)
        );
      });
    }
    return Object.assign([], filtered);
  }

  protected abstract initFilteredDataObservable();

  /** Initalizes the final $data observable, which additionally filters based on existing ids in the context. */
  public initResultsObservable() {
    this.dataSubscription = combineLatest([this.filteredData$, this.existingItemIdsSubject, this.showAllSubject])
      .pipe(
        tap(async ([results, existingItemIds, showAll]) => {
          console.log(`ItemDataChooserDataSource (${this.id}): filter local ids...`);
          let data = results;
          if (!data) {
            console.log('WARN: data null in building existing filtered obsservable');
            return [];
          }
          // FILTER OUT EXISTING
          if (!showAll && existingItemIds) {
            data = data.filter((obj) => !existingItemIds.has(obj.id)); // THIS COULD BE EXPENSIVE...
          }
          console.log(`ItemDataChooserDataSource (${this.id}): data...`, data.length);
          this.resultsSubject.next(data);
        }),
      )
      .subscribe();
  }
  public cleanUp() {
    console.log('ItemDataChooserDataSource: cleanUp');
    this.dataSubscription.unsubscribe();
    this.filteredDataSubscription?.unsubscribe();
  }

  public sortData(data, sorts) {
    // Sort properties based on the given sorts
    const sortedProperties = SortObjects.sort(
      data.map((obj) => obj.properties),
      sorts,
    );

    // Create a lookup map for item IDs and their indices
    const idToIndexMap = new Map(sortedProperties.map((property, index) => [property.itemId, index]));

    // Sort data using the lookup map
    data.sort((a, b) => {
      return (idToIndexMap.get(a.id) || 0) - (idToIndexMap.get(b.id) || 0);
    });
  }

  public filterByAssortmentItems(data, filterDefinition: FilterDefinition) {
    const filteredAssortmentItems = FilterObjects.filter(
      data.map((item) => item.assortmentItem),
      filterDefinition.filterCriteria,
    );
    const filteredData = data.filter(
      (item) =>
        filteredAssortmentItems.findIndex((assortmentItem) => assortmentItem.id === item.assortmentItem.id) > -1,
    );
    return filteredData;
  }

  public getFilteredData() {
    return (this.filteredDataSubject as BehaviorSubject<ItemData[]>).getValue();
  }
}
