import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState, UserSessionSelectors } from 'src/app/root-store';

@Component({
  selector: 'app-user-session-context-avatars',
  template: `
    <div class="h-8 -mr-2" *ngFor="let session of remoteSessions$ | async; trackBy: trackByFn">
      <app-avatar [user]="session.user"></app-avatar>
    </div>
  `,
  styles: [
    `
      :host {
        @apply flex cursor-pointer;
      }
    `,
  ],
})
export class UserSessionContextAvatarsComponent implements OnInit {
  @Input() contextReference: string;
  @Input() limit: number;
  public remoteSessions$;

  constructor(private store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    if (this.contextReference) {
      this.remoteSessions$ = this.store.select(
        UserSessionSelectors.filterRemoteUsersByContext(this.contextReference, this.limit),
      );
    }
  }

  trackByFn(index, session) {
    return session?.clientId;
  }
}
