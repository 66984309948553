import { Injectable } from '@angular/core';
import { ComposerService } from '../composer/composer.service';
import { UndoRedoService } from 'src/app/common/undo-redo/undo-redo-service';
import { DocumentAction, DocumentChangeType } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { PresentationAction } from '../composer/types/presentation-action';
import { ShowcaseBackingAssortmentService } from '../composer/backing-assortment/showcase-backing-assortment-service';
import { DocumentService } from '../document/document.service';
import { ComposerGenerateCanvasFramesService } from '../composer/composer-frame/composer-generate-canvas-frames/composer-generate-canvas-frames.service';

@Injectable({
  providedIn: 'root',
})
export class UndoRedoHandler {
  constructor(
    private undoRedoService: UndoRedoService,
    private composerService: ComposerService,
    private documentService: DocumentService,
    private backingAssortmentService: ShowcaseBackingAssortmentService,
    private generateFrameService: ComposerGenerateCanvasFramesService,
  ) {}

  public undoActions() {
    this.undoRedoService.getNextUndo().subscribe((nextUndo) => {
      if (nextUndo) {
        const lineboardUndo = ObjectUtil.cloneDeep(nextUndo).filter((undo) => undo.actionType === 'lineboard');
        if (nextUndo[0].actionType === 'document') {
          const actions = ObjectUtil.cloneDeep(nextUndo).map((undo) => {
            return new DocumentAction({
              documentId: undo.undoChangeDefinition.documentId,
              elementId: undo.undoChangeDefinition.elementId,
              changeType: undo.undoChangeDefinition.changeType,
              elementData: undo.undoChangeDefinition.elementData,
            });
          });
          this.updateDocumentElements(actions);
        } else if (nextUndo[0].actionType === 'presentation' || nextUndo[0].actionType === 'frame') {
          const actions = ObjectUtil.cloneDeep(nextUndo)
            .filter((undo) => undo.actionType !== 'lineboard')
            .map((undo) => {
              return new PresentationAction(nextUndo[0].actionType, {
                changeType: undo.undoChangeDefinition.changeType,
                frameData: undo.undoChangeDefinition.frameData,
                presentationData: undo.undoChangeDefinition.presentationData,
              });
            });
          this.composerService.handlePresentationFrameActions(actions);
        } else if (nextUndo[0].actionType === 'backing-assortment') {
          this.backingAssortmentService.updateAssortmentItems(nextUndo[0].undoChangeDefinition);
        }
        if (lineboardUndo.length > 0) {
          this.composerService.updateLineboardConfig(lineboardUndo[0].undoChangeDefinition.generationConfigData);
        }
      }
    });
  }

  public redoActions() {
    this.undoRedoService.getNextRedo().subscribe((nextRedo) => {
      if (nextRedo) {
        const lineboardRedo = ObjectUtil.cloneDeep(nextRedo).filter((undo) => undo.actionType === 'lineboard');
        if (nextRedo[0].actionType === 'document') {
          const actions = ObjectUtil.cloneDeep(nextRedo).map((redo) => {
            return new DocumentAction({
              documentId: redo.undoChangeDefinition.documentId,
              elementId: redo.changeDefinition.elementId,
              changeType: redo.changeDefinition.changeType,
              elementData: redo.changeDefinition.elementData,
            });
          });
          this.updateDocumentElements(actions);
        } else if (nextRedo[0].actionType === 'presentation' || nextRedo[0].actionType === 'frame') {
          const actions = ObjectUtil.cloneDeep(nextRedo)
            .filter((redo) => redo.actionType !== 'lineboard')
            .map((redo) => {
              return new PresentationAction(nextRedo[0].actionType, {
                changeType: redo.changeDefinition.changeType,
                frameData: redo.changeDefinition.frameData,
                presentationData: redo.changeDefinition.presentationData,
              });
            });
          this.composerService.handlePresentationFrameActions(actions);
        } else if (nextRedo[0].actionType === 'backing-assortment') {
          this.backingAssortmentService.updateAssortmentItems(nextRedo[0].changeDefinition);
        }
        if (lineboardRedo.length > 0) {
          this.composerService.updateLineboardConfig(lineboardRedo[0].changeDefinition.generationConfigData);
        }
      }
    });
  }

  private updateDocumentElements(actions) {
    const selectedElements = this.documentService.getSelectedExpandedElements();
    let sendSelectedEvent = false;
    if (selectedElements?.length > 0 && actions?.length > 0) {
      for (let i = 0; i < actions?.length; i++) {
        const action = actions[i];
        const element = action.changeDefinition.elementData;
        const currentElement = selectedElements.find((e) => e.id === action.changeDefinition.elementId);
        if (
          action.changeDefinition.changeType === DocumentChangeType.MODIFY_ELEMENT &&
          element?.isLocked != null &&
          element?.isLocked !== currentElement?.isLocked
        ) {
          sendSelectedEvent = true;
          break;
        }
      }
    }

    this.documentService.handleDocumentActions(actions, { id: actions[0].changeDefinition?.documentId });
    if (sendSelectedEvent) {
      const selectedElements = this.documentService.getSelectedExpandedElements(); // get selected elements again so they have latest element data
      // Trigger toolbar to get updated
      this.documentService.handleDocumentElementEvent({
        element: selectedElements[0],
        selectedElements,
        eventType: 'selected',
      });
    }
  }
}
