<div class="context-menu" #contextMenu>
  <button mat-button [matMenuTriggerFor]="menu">
    <span>Arrange</span>
  </button>
  <mat-menu #menu="matMenu">
    <button class="context-menu-button" mat-menu-item [matMenuTriggerFor]="order">Order</button>
    <button
      class="context-menu-button"
      mat-menu-item
      [matMenuTriggerFor]="alignment"
      [disabled]="!areMultipleElementsSelected(1)"
    >
      Align
    </button>
    <button
      class="context-menu-button"
      mat-menu-item
      [matMenuTriggerFor]="distribution"
      [disabled]="!areMultipleElementsSelected(2)"
    >
      Distribute
    </button>
  </mat-menu>

  <mat-menu #order="matMenu">
    <button class="context-menu-button justify-between" mat-menu-item (click)="arrangeElements('order.bring_forward')">
      Bring forward
      <div class="text-black60">Shift-PgUp</div>
    </button>
    <button class="context-menu-button justify-between" mat-menu-item (click)="arrangeElements('order.bring_to_front')">
      Bring to front
      <div class="text-black60">PgUp</div>
    </button>
    <button class="context-menu-button justify-between" mat-menu-item (click)="arrangeElements('order.send_backward')">
      Send backward
      <div class="text-black60">Shift-PgDn</div>
    </button>
    <button class="context-menu-button justify-between" mat-menu-item (click)="arrangeElements('order.send_to_back')">
      Send to back
      <div class="text-black60">PgDn</div>
    </button>
  </mat-menu>
  <mat-menu #alignment="matMenu">
    <button class="context-menu-button" mat-menu-item (click)="arrangeElements('align.horizontal_left')">
      <mat-icon>align_horizontal_left</mat-icon>Left
    </button>
    <button class="context-menu-button" mat-menu-item (click)="arrangeElements('align.horizontal_center')">
      <mat-icon>align_horizontal_center</mat-icon>Center
    </button>
    <button class="context-menu-button" mat-menu-item (click)="arrangeElements('align.horizontal_right')">
      <mat-icon>align_horizontal_right</mat-icon>Right
    </button>
    <mat-divider></mat-divider>
    <button class="context-menu-button" mat-menu-item (click)="arrangeElements('align.vertical_top')">
      <mat-icon>align_vertical_top</mat-icon>Top
    </button>
    <button class="context-menu-button" mat-menu-item (click)="arrangeElements('align.vertical_middle')">
      <mat-icon>align_vertical_center</mat-icon>Middle
    </button>
    <button class="context-menu-button" mat-menu-item (click)="arrangeElements('align.vertical_bottom')">
      <mat-icon>align_vertical_bottom</mat-icon>Bottom
    </button>
  </mat-menu>
  <mat-menu #distribution="matMenu">
    <button class="context-menu-button" mat-menu-item (click)="arrangeElements('distribute.horizontal')">
      <mat-icon>horizontal_distribute</mat-icon>Horizontal
    </button>
    <button class="context-menu-button" mat-menu-item (click)="arrangeElements('distribute.vertical')">
      <mat-icon>vertical_distribute</mat-icon>Vertical
    </button>
  </mat-menu>
</div>
