import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowroomViewerComponent } from './showroom-viewer.component';
import { ShowroomItemModalComponent } from './showroom-item-modal/showroom-item-modal.component';
import { PipesModule } from '@pipes/pipes.module';
import { WebglModule } from '@common/webgl/webgl.module';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';

@NgModule({
  declarations: [ShowroomViewerComponent, ShowroomItemModalComponent],
  imports: [CommonModule, PipesModule, WebglModule, MatTabsModule],
  exports: [ShowroomViewerComponent],
})
export class ShowroomViewerModule {}
