<div
  class="container"
  [ngClass]="{ 'hovered-over': hoveredOver }"
  (click)="onClick($event)"
  (mouseover)="onDragOver($event)"
  (mouseout)="onDragLeave($event)"
  (drop)="onDrop($event)"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
  (contextmenu)="onGridSectionContextMenu($event, section.value)"
>
  <div *ngIf="section.value !== 'placeholder'" class="title-container">
    <mat-icon *ngIf="editorMode === 'EDIT'" [matMenuTriggerFor]="actionMenu" (click)="$event.stopPropagation()"
      >more_horiz</mat-icon
    >
    <div class="count">{{ section.children.length }}</div>
    <div *ngIf="section.value !== 'placeholder'" class="title" [matTooltip]="section.label">
      {{ section.label }}
    </div>
  </div>

  <div class="w-full" *ngIf="section.children.length > 0">
    <div
      class="item-container"
      id="{{ section.value + '|' + i }}"
      cdkDropList
      cdkDropListOrientation="horizontal"
      [cdkDropListData]="sectionGroup"
      (cdkDropListDropped)="drop($event)"
      *ngFor="let sectionGroup of sectionGroups; let i = index"
    >
      <ng-container *ngFor="let item of sectionGroup; trackBy: trackByItem; let x = index">
        <div
          *ngIf="itemMap[item]"
          (contextmenu)="onItemContextMenu($event, item)"
          class="option"
          [ngStyle]="!cursorClass ? { cursor: 'move' } : ''"
          cdkDrag
          [cdkDragDisabled]="editorMode !== 'EDIT'"
          [cdkDragData]="{ item }"
          [cdkDragPreviewClass]="optionPreviewClass"
          [attr.id]="item"
          (click)="handleItemClick(item)"
          [ngClass]="{ inSearchResults: inSearchResults(item), inSearchResultsActive: inSearchResultsActive(item) }"
        >
          <div class="option-action">
            <div class="annotations">
              <mat-icon *ngFor="let annotation of itemMap[item]['annotations']" [svgIcon]="annotation"></mat-icon>
            </div>
            <div class="remove-option" *ngIf="editorMode === 'EDIT'">
              <mat-icon
                [attr.data-test]="'app-presentation-collection-option-remove-' + item"
                (click)="removeOption($event, item)"
                >close</mat-icon
              >
            </div>
          </div>
          <div
            class="thumbnail"
            [ngClass]="{ 'opacity-60': itemMap[item]['statusMessage'] }"
            *ngIf="itemMap[item]['thumbnail']"
          >
            <img [attr.src]="itemMap[item]['thumbnail'] | secureImage | async" />
            <div
              class="absolute"
              *ngIf="itemMap[item]['statusMessage']"
              [matTooltip]="itemMap[item].statusMessage.message"
            >
              <mat-icon svgIcon="warning-alert"></mat-icon>
            </div>
          </div>
          <div
            class="thumbnail-placeholder"
            [ngClass]="{ 'opacity-60': itemMap[item]['statusMessage'] }"
            *ngIf="itemMap[item]['thumbnail'] === undefined"
          >
            <mat-icon
              class="!w-full !h-full"
              svgIcon="placeholder-item-option-image"
              *ngIf="itemMap[item]['roles']?.indexOf('family') === -1"
            ></mat-icon>
            <mat-icon
              class="!w-full !h-full"
              svgIcon="placeholder-item-family-image"
              *ngIf="itemMap[item]['roles']?.indexOf('family') !== -1"
            ></mat-icon>
            <div
              class="absolute"
              *ngIf="itemMap[item]['statusMessage']"
              [matTooltip]="itemMap[item].statusMessage.message"
            >
              <mat-icon svgIcon="warning-alert"></mat-icon>
            </div>
          </div>
          <ng-container *ngFor="let property of properties">
            <div
              *ngIf="property.propertyDefinition"
              class="option-label"
              [style.line-height.px]="property.style?.font?.size"
              [style.font-size.px]="property.style?.font?.size"
              [style.color]="property.style?.color"
              [matTooltip]="itemMap[item] ? itemMap[item][property.slug] : ''"
            >
              {{ property?.includeLabel ? property.propertyDefinition?.label + ': ' : ''
              }}{{ itemMap[item][property.slug] }}
            </div>
          </ng-container>
          <div class="absolute top-4 right-7" *ngIf="editorMode !== 'VIEW'">
            <app-composer-collection-item-pinned-comments
              [item]="{ value: item }"
            ></app-composer-collection-item-pinned-comments>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="absolute top-1 right-5" *ngIf="editorMode !== 'VIEW'">
      <app-composer-grid-section-pinned-comments
        [sectionId]="section.value"
      ></app-composer-grid-section-pinned-comments>
    </div>
  </div>

  <div
    class="item-container"
    *ngIf="section.children.length === 0"
    [attr.id]="section.value + '-0'"
    cdkDropList
    cdkDropListOrientation="horizontal"
    (cdkDropListDropped)="drop($event)"
  >
    <div cdkDrag class="item-placeholder">
      <div class="flex justify-center items-center flex-col" *ngIf="selected">
        <button mat-icon-button (click)="openItemChooser()">
          <mat-icon color="primary" class="text-[35px]">add_circle_outline</mat-icon>
        </button>
        <div><span class="text-primary text-xs">Add or drag and drop</span></div>
        <div><span class="text-primary text-xs">items in this section</span></div>
      </div>
    </div>
  </div>

  <mat-menu #actionMenu="matMenu" class="menu-sm">
    <ng-template matMenuContent let-target="target">
      <button *ngFor="let action of contextMenuActions" mat-menu-item (click)="handleMenuAction(action.actionName)">
        <mat-icon *ngIf="action?.icon">{{ action.icon }}</mat-icon>
        <span>{{ action.label }}</span>
      </button>
    </ng-template>
  </mat-menu>

  <div
    #optionMenuTrigger
    id="optionMenuTrigger"
    style="visibility: hidden; position: fixed"
    [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y"
    [matMenuTriggerFor]="contextMenu"
  ></div>

  <mat-menu #contextMenu="matMenu" class="collection-item-context-menu" id="optionMenuTrigger">
    <ng-template matMenuContent let-item="item" let-sectionId="sectionId">
      <button
        mat-menu-item
        (click)="addGridSectionComment(sectionId)"
        data-test="collection-grid-section-add-comment"
        *ngIf="sectionId"
      >
        <span>Comment</span>
      </button>
      <button mat-menu-item (click)="viewItem(item)" data-test="collection-item-view" *ngIf="item">
        <span>View Item</span>
      </button>
      <button mat-menu-item (click)="addItemComment(item)" data-test="collection-item-add-comment" *ngIf="item">
        <span>Comment</span>
      </button>
    </ng-template>
  </mat-menu>
</div>
