import { Injectable, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { ViewPropertyConfiguration } from '@contrail/client-views';
import { Types } from '@contrail/sdk';
import { NumberFormat, PropertyType, PropertyValueFormatter } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { PresentationCollectionElement } from 'src/app/presentation/presentation';
import { ShowcasesActions, ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { v4 as uuid } from 'uuid';
import { ComposerService } from '../../composer.service';
import { CreateGridFrameSectionComponent } from './composer-grid-frame-section/create-grid-frame-section/create-grid-frame-section.component';
import { UpdateGridFrameSectionComponent } from './composer-grid-frame-section/update-grid-frame-section/update-grid-frame-section.component';
import { NewGridFrameModalComponent } from './new-grid-frame-modal/new-grid-frame-modal.component';
import { Subject, takeUntil } from 'rxjs';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

const ELIGIBLE_PROPERTIES = [
  PropertyType.SingleSelect,
  PropertyType.String,
  PropertyType.Number,
  PropertyType.Currency,
];
const propertyFormatter = new PropertyValueFormatter();

@Injectable()
export class ComposerGridFrameService implements OnDestroy {
  private destroy$ = new Subject();
  private currentContainer: any;
  private gridFrameActiveContainer: PresentationCollectionElement;
  private typeProperties;

  private currentFrame;
  constructor(
    private store: Store<RootStoreState.State>,
    private composerService: ComposerService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
    this.composerService.currentFrame.pipe(takeUntil(this.destroy$)).subscribe((frame) => {
      this.currentFrame = frame;
    });
    this.store
      .select(ShowcasesSelectors.gridFrameActiveContainer)
      .pipe(takeUntil(this.destroy$))
      .subscribe((gridFrameActiveContainer) => {
        this.gridFrameActiveContainer = gridFrameActiveContainer;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public setCurrentContainer(container: any) {
    this.currentContainer = container;
  }

  public getCurrentContainer() {
    return this.currentContainer;
  }

  public addSection() {
    const dialogRef = this.dialog.open(CreateGridFrameSectionComponent, {
      disableClose: false,
      autoFocus: true,
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  public editSectionName(section: any) {
    const dialogRef = this.dialog.open(UpdateGridFrameSectionComponent, {
      disableClose: false,
      autoFocus: true,
      data: { section },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  public createSection(name: string) {
    const undoChangeDefinition = ObjectUtil.cloneDeep(this.currentFrame);
    const index = this.currentFrame.collection.set.length;
    const newSection = { value: uuid(), label: name, enabled: true, type: 'section', children: [] };
    this.currentFrame.collection.set.splice(index, 0, newSection);
    this.composerService.handleFrameChanges(ObjectUtil.cloneDeep(this.currentFrame), undoChangeDefinition);
    this.store.dispatch(ShowcasesActions.setGridFrameActiveContainer({ gridFrameActiveContainer: newSection }));
  }

  public updateSectionName(section: any, name: string) {
    const undoChangeDefinition = ObjectUtil.cloneDeep(this.currentFrame);
    const index = this.currentFrame.collection.set.findIndex((set) => set.value === section.value);
    const clonedSection = ObjectUtil.cloneDeep(section);
    clonedSection.label = name;
    this.currentFrame.collection.set.splice(index, 1, clonedSection);
    this.composerService.handleFrameChanges(ObjectUtil.cloneDeep(this.currentFrame), undoChangeDefinition);
  }

  public deleteSection(section: any) {
    const undoChangeDefinition = ObjectUtil.cloneDeep(this.currentFrame);
    const index = this.currentFrame.collection.set.findIndex((set) => set.value === section.value);
    this.currentFrame.collection.set.splice(index, 1);
    this.composerService.handleFrameChanges(ObjectUtil.cloneDeep(this.currentFrame), undoChangeDefinition);
    if (this.gridFrameActiveContainer?.value === section?.value) {
      this.store.dispatch(ShowcasesActions.setGridFrameActiveContainer({ gridFrameActiveContainer: null }));
    }
  }

  public addAssortmentSections() {
    const undoChangeDefinition = ObjectUtil.cloneDeep(this.currentFrame);
    this.currentFrame.collection.type = 'assortment';
    this.composerService.handleFrameChanges(ObjectUtil.cloneDeep(this.currentFrame), undoChangeDefinition);
  }

  async createGridFrame(frameName?: string, set?: any[]) {
    const newFrame = {
      name: frameName,
      type: 'grid',
      collection: {
        set: set || [],
        type: 'item',
        filter: {},
      },
    };

    await this.composerService.addPresentationFrame(newFrame);
  }

  public async getPropertyOptions(assortmentObject: any, property: any) {
    console.log('getPropertyOptions: ', assortmentObject, property);
    const options = this.groupBy(assortmentObject.assortmentItems, property);
    return Object.keys(options);
  }

  public async createGridFrameByAssortment(
    assortment: any,
    dimension1: any,
    dimension2: any,
    dimension1Values: string[],
    dimension2Values: string[],
    includeEmptySections: boolean,
  ) {
    console.log('createGridFrameByAssortment: assortment: ', assortment);
    console.log('createGridFrameByAssortment: dimension1: ', dimension1);
    console.log('createGridFrameByAssortment: dimension2: ', dimension2);
    console.log('createGridFrameByAssortment: dimension1Values: ', dimension1Values);
    console.log('createGridFrameByAssortment: dimension2Values: ', dimension2Values);

    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Please wait....' }));
    let allGroups = {};
    const typeProperties = await this.getTypeProperties();
    const property = typeProperties.find((typeProperty) => typeProperty.slug === dimension1.slug);
    allGroups = this.groupBy(assortment.assortmentItems, property);
    const dimension1Groupings = this.assignGroups(dimension1Values, allGroups);

    console.log('createGridFrameByAssortment: groups: ', dimension1Groupings);

    // Currently, we indicate that multiple frames should be created
    // by supplying a second dimension. This is confusing.
    const singleFrame = !dimension2;

    const dimensionOneKeys = Object.keys(dimension1Groupings);
    if (dimensionOneKeys.length > 1000) {
      this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
      return {
        status: 'failure',
        message: 'Error: Number of values cannot exceed 1000. Please select a different property.',
        dimension: '1',
      };
    }
    if (singleFrame) {
      const sections = [];
      console.log('single frame: dimension1Groupings: ', dimension1Groupings);
      for (const dimensionOneKeyValue of dimensionOneKeys) {
        if (dimensionOneKeyValue !== '' || (dimensionOneKeyValue === '' && includeEmptySections)) {
          const dimensionDisplayName =
            dimension1.propertyDefinition.options?.find((option) => option.value === dimensionOneKeyValue)?.display ||
            dimensionOneKeyValue;
          const children = dimension1Groupings[dimensionOneKeyValue].map((object) => {
            const item = object.item || object;
            return { value: item.id, label: item.optionName, enabled: true };
          });
          if (children.length > 0) {
            const newSection = { value: uuid(), label: dimensionDisplayName, enabled: true, type: 'section', children };
            sections.push(newSection);
          }
        }
      }
      console.log('single dimension: ', sections);
      await this.createGridFrame(null, sections);
    } else {
      console.log('multiple frames: dimension1Groupings: ', dimension1Groupings);
      console.log('multiple frames: dimension2.slug: ', dimension2?.slug);

      for (const dimensionOneKeyValue of dimensionOneKeys) {
        console.log('property: ', dimensionOneKeyValue);
        const sections = [];

        const dimensionDisplayName =
          dimension1.propertyDefinition.options?.find((option) => option.value === dimensionOneKeyValue)?.display ||
          dimensionOneKeyValue;

        if (!dimensionOneKeyValue) {
          continue;
        }
        const dimension1Items = dimension1Groupings[dimensionOneKeyValue];
        // construct set with inner groupings based on dimension 2
        if (dimension2?.slug) {
          const property2 = typeProperties.find((typeProperty) => typeProperty.slug === dimension2.slug);
          let dimension2Groupings = this.groupBy(dimension1Items, property2);
          dimension2Groupings = this.assignGroups(dimension2Values, dimension2Groupings);
          const dimensionTwoKeys = Object.keys(dimension2Groupings);

          if (dimensionTwoKeys.length > 1000) {
            this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            return {
              status: 'failure',
              message: 'Error: Number of values cannot exceed 1000. Please select a different property.',
              dimension: '2',
            };
          }
          for (const dimension2Key of dimensionTwoKeys) {
            if (dimension2Key !== '' || (dimension2Key === '' && includeEmptySections)) {
              const propertyDisplay2 =
                dimension2.propertyDefinition.options?.find((option) => option.value === dimension2Key)?.display ||
                dimension2Key;
              const dimension2Items = dimension2Groupings[dimension2Key].map((object) => {
                const item = object.item || object;
                return { value: item.id, label: item.optionName, enabled: true };
              });
              if (dimension2Items.length > 0) {
                // Exclude empty section
                const newSection = {
                  value: uuid(),
                  label: propertyDisplay2,
                  enabled: true,
                  type: 'section',
                  children: dimension2Items,
                };
                sections.push(newSection);
              }
            }
          }
        } else {
          // Not using dimension 2, create section from dimension one items.
          const items = dimension1Items.map((object) => {
            const item = object.item || object;
            return { value: item.id, label: item.optionName, enabled: true };
          });
          if (items.length > 0) {
            // Exclude empty section
            const newSection = {
              value: uuid(),
              label: dimensionDisplayName,
              enabled: true,
              type: 'section',
              children: items,
            };
            sections.push(newSection);
          }
        }
        if (sections.length > 0) {
          // Exclude empty frame
          await this.createGridFrame(dimensionDisplayName, sections);
        } else {
          this.snackBar.open(`No items found for ${dimensionDisplayName}`, 'Close', { duration: 5000 });
        }
      }
    }
    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
    return { status: 'success' };
  }

  async getTypeProperties() {
    if (this.typeProperties) {
      return this.typeProperties;
    }
    const types = new Types();
    const [itemType, assortmentItemType] = await Promise.all([
      types.getType({ root: 'item', path: 'item' }),
      types.getType({ root: 'assortment-item', path: 'assortment-item' }),
    ]);
    let typeProperties = itemType.typeProperties.map((property) => {
      return {
        enabled: false,
        slug: property.slug,
        typeRootSlug: 'item',
        style: null,
        includeLabel: false,
        propertyDefinition: property,
      };
    });

    const assortmentItemProperties = assortmentItemType.typeProperties
      .filter((property) => property.slug !== 'createdOn' && property.slug !== 'updatedOn')
      .map((property) => {
        return {
          enabled: false,
          slug: property.slug,
          typeRootSlug: 'assortment-item',
          style: null,
          includeLabel: false,
          propertyDefinition: property,
        };
      });
    typeProperties = typeProperties
      .concat(assortmentItemProperties)
      .filter((property) => ELIGIBLE_PROPERTIES.includes(property.propertyDefinition.propertyType));
    typeProperties.sort((property1, property2) => {
      if (property1.propertyDefinition.label < property2.propertyDefinition.label) {
        return -1;
      }
      if (property1.propertyDefinition.label > property2.propertyDefinition.label) {
        return 1;
      }
      return 0;
    });
    this.typeProperties = typeProperties;
    console.log(this.typeProperties);
    return typeProperties;
  }

  private assignGroups(dimensionValues: string[], allGroups: any) {
    let groups = {};
    if (dimensionValues.length > 0) {
      dimensionValues.forEach((value) => {
        if (allGroups[value]) {
          groups[value] = allGroups[value];
        }
      });
    } else {
      groups = ObjectUtil.cloneDeep(allGroups);
    }
    if (allGroups['']) {
      // still keep the empty group for the "Create sections for empty values" option
      groups[''] = allGroups[''];
    }
    return groups;
  }

  showCreateGridFrame() {
    this.dialog.open(NewGridFrameModalComponent, { width: '688px' });
  }

  private groupBy(assortmentItems, property: ViewPropertyConfiguration) {
    let propertyType = property.propertyDefinition.propertyType;
    let numberFormat = property.propertyDefinition.numberFormat;
    if (propertyType === PropertyType.Formula) {
      propertyType = PropertyType.Currency; // THIS IS WRONG... BUT NOT A BAD SHORT TERM SOLUTION
      numberFormat = { currency: 'USD', format: NumberFormat.Currency, precision: 0 };
    }

    return assortmentItems.reduce((cell, assortmentItem) => {
      const value = (property.typeRootSlug === 'assortment-item' ? assortmentItem : assortmentItem.item)[property.slug];
      const formattedValue = propertyFormatter.formatValue(value, propertyType, numberFormat);
      (cell[formattedValue || ''] = cell[formattedValue] || []).push(assortmentItem);
      return cell;
    }, {});
  }
}
