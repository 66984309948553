import { Component, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ShareLink } from '../../interfaces';
import { ShareShowcaseService } from '../../share-showcase-service';

@Component({
  selector: 'app-legacy-links',
  templateUrl: './legacy-links.component.html',
  styleUrls: ['./legacy-links.component.scss'],
})
export class LegacyLinksComponent implements OnDestroy {
  public links: ShareLink[];

  private showcaseId: string;
  private orgSlug: string;
  private showcaseAppHost: string;

  @Output() close = new EventEmitter();

  constructor(
    private shareShowcaseService: ShareShowcaseService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<LegacyLinksComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      links: ShareLink[];
      showcaseId: string;
      orgSlug: string;
      showcaseAppHost: string;
    },
  ) {
    this.showcaseId = data.showcaseId;
    this.orgSlug = data.orgSlug;
    this.showcaseAppHost = data.showcaseAppHost;
    this.links = data?.links?.map((l) => {
      l.shareUrl = this.getShareUrl(l);
      return l;
    });
  }

  getShareUrl(link) {
    if (!link) {
      return '';
    }
    return `${this.showcaseAppHost}/org/${this.orgSlug}/sc/${this.showcaseId}?st=${link.id}`;
  }

  showClipboardMessage() {
    this.snackBar.open('Link copied to clipboard', '', { duration: 2000 });
  }

  async deleteLink(link) {
    if (!link?.id) {
      return;
    }
    await this.shareShowcaseService.deleteShareLink(link.id);
    this.links = this.links.filter((l) => l.id !== link.id);
    this.snackBar.open('Removed share link', '', { duration: 2000 });
  }

  async handleAccessLevelChange($event, link) {
    const accessLevel = $event.value;
    if (!link?.id) {
      return;
    }
    const updatedLink = await this.shareShowcaseService.updateShareLinkAccessLevel(link, accessLevel);
    this.links = this.links.map((l) => (l.id === updatedLink.id ? { ...l, ...updatedLink } : l));
    this.snackBar.open('Updated access level', '', { duration: 2000 });
  }

  ngOnDestroy(): void {
    this.close.emit(this.links);
  }
}
