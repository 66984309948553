<div class="layout" (click)="hidePropertyWidget()">
  <div class="header">
    <div class="title">Layout Options</div>
    <div>
      <mat-icon (click)="hide()">close</mat-icon>
    </div>
  </div>

  <mat-expansion-panel class="mat-elevation-z0" expanded>
    <mat-expansion-panel-header>
      <mat-panel-title class="text-sm font-normal"> Presets </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="view-name">
      <form [formGroup]="form">
        <mat-form-field *ngIf="!editName">
          <mat-select
            data-test="collection-frame-layout-views"
            formControlName="selectedViewDefTemplate"
            (selectionChange)="onSelectedViewDefChange($event)"
          >
            <mat-select-trigger>
              <div class="flex-between-center">
                <span class="overflow-hidden whitespace-nowrap text-ellipsis">{{
                  selectedViewDefinitionTemplate?.label
                }}</span>
                <mat-icon *ngIf="selectedViewDefinitionTemplate?.admin">group</mat-icon>
              </div>
            </mat-select-trigger>
            <mat-option *ngFor="let view of viewDefinitionTemplates" [value]="view">
              <div class="flex-between-center">
                <span class="overflow-hidden whitespace-nowrap text-ellipsis">{{ view.label }}</span>
                <mat-icon *ngIf="view.admin">group</mat-icon>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="editName">
          <input
            #viewNameInput
            data-test="composer-collection-frame-layout-name-edit-input"
            matInput
            type="text"
            maxlength="100"
            formControlName="viewName"
            id="viewName"
            name="viewName"
            (keydown.enter)="endNameEdit()"
            (blur)="endNameEdit()"
          />
        </mat-form-field>
      </form>
      <div class="flex">
        <button
          mat-button
          matTooltip="Edit name"
          disableRipple="true"
          [disabled]="!selectedViewDefinitionTemplate || selectedViewDefinitionTemplate?.id === 'null'"
          data-test="composer-collection-frame-layout-name-edit-button"
          (click)="startNameEdit()"
        >
          <mat-icon> edit </mat-icon>
        </button>
        <button
          mat-button
          matTooltip="Save preset"
          disableRipple="true"
          [disabled]="sameProperties(selectedViewDefinitionTemplate)"
          data-test="composer-collection-frame-layout-save-button"
          (click)="saveTemplate()"
        >
          <mat-icon> save </mat-icon>
        </button>
        <button
          mat-button
          matTooltip="Reset preset"
          disableRipple="true"
          [disabled]="
            !selectedViewDefinitionTemplate ||
            selectedViewDefinitionTemplate?.id === 'null' ||
            sameProperties(selectedViewDefinitionTemplate)
          "
          data-test="composer-collection-frame-layout-reset-button"
          (click)="resetPreset()"
        >
          <mat-icon> settings_backup_restore </mat-icon>
        </button>
        <button
          mat-button
          matTooltip="Delete preset"
          disableRipple="true"
          [disabled]="!selectedViewDefinitionTemplate || selectedViewDefinitionTemplate?.id === 'null'"
          data-test="composer-collection-frame-layout-delete-button"
          (click)="deleteViewTemplate()"
        >
          <mat-icon> delete </mat-icon>
        </button>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-divider></mat-divider>
  <mat-expansion-panel class="mat-elevation-z0 properties" expanded>
    <mat-expansion-panel-header>
      <mat-panel-title class="text-sm font-normal"> Properties </mat-panel-title>
    </mat-expansion-panel-header>
    <app-composer-frame-property-selector
      #propertySelector
      *ngIf="selectedViewDefinition?.properties.length > 0"
      [applicationViewSlug]="applicationViewSlug"
      [typeProperties]="selectedViewDefinition?.properties"
      (propertiesChangedEvent)="synchProperties($event)"
    >
      ></app-composer-frame-property-selector
    >
  </mat-expansion-panel>
  <mat-divider></mat-divider>

  <div class="action">
    <button
      class="!mr-[18px]"
      mat-raised-button
      color="primary"
      (click)="createPreset()"
      data-test="component-editor-save-preset"
    >
      SAVE AS PRESET
    </button>
    <button
      *ngIf="applicationViewSlug === 'showcase:item_details'"
      mat-raised-button
      color="primary"
      (click)="showPreview($event)"
      data-test="component-editor-preview"
    >
      PREVIEW
    </button>
    <button
      *ngIf="applicationViewSlug === 'showcase:grid_frame'"
      mat-raised-button
      color="primary"
      (click)="refreshFrame($event)"
      data-test="component-editor-refresh"
    >
      REFRESH
    </button>
  </div>
</div>
