import {
  DocumentAction,
  DocumentChangeType,
  DocumentElementFactory,
  SizeDefinition,
  DocumentElement,
} from '@contrail/documents';
import { DocumentService } from '../document.service';
import { nanoid } from 'nanoid';
import { take, map } from 'rxjs/operators';
import { SecurityContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Content, Entities, Files } from '@contrail/sdk';
import { ObjectUtil } from '@contrail/util';
import { FileDownloader, SVGHelper } from 'src/app/presentation/canvas-lib';
import pLimit from 'p-limit';
import { environment } from 'src/environments/environment';
import { DocumentItemService } from '../document-item/document-item.service';
const limit = pLimit(10);

const POLLING_INTERVAL = 1000;
const POLLING_RETRIES = 30000 / POLLING_INTERVAL;

export class DocumentFileHandler {
  private readonly LOADING_IMAGE_ICON = '/assets/images/image_upload.svg';

  public uploading: boolean = false;

  constructor(
    private documentService: DocumentService,
    private ownerReference: string,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {}

  public showConfirmDialog() {
    return this.uploading
      ? 'Content is still uploading in the background. If you leave, your changes will be lost!'
      : false;
  }

  public async createFile(file: File, fileId?, ttl?) {
    const object: any = {
      fileName: file.name,
      contentType: file.type,
      ownedByReference: this.ownerReference,
    };
    if (fileId) {
      object.specifiedId = fileId;
    }
    if (ttl) {
      object.ttl = ttl;
    }
    return await new Entities().create({
      entityName: 'file',
      object,
    });
  }
  public async createContent(file: File, contentId?, owner?) {
    let fileType = file.type;
    if (!fileType && file?.name?.endsWith('.glb')) {
      fileType = 'model/gltf-binary';
    }
    const object: any = {
      fileName: file.name,
      contentType: fileType,
      contentHolderReference: this.ownerReference,
    };
    if (contentId) {
      object.specifiedId = contentId;
    }
    return await new Entities().create({
      entityName: 'content',
      object,
    });
  }

  public async uploadFile(uploadPost, file: File) {
    return await new Files().uploadFile(uploadPost, file);
  }

  public async createAndUploadFile(file: File, fileId?, ttl?) {
    const fileEntity = await this.createFile(file, fileId, ttl);
    await this.uploadFile(fileEntity.uploadPost, file);
    return fileEntity;
  }
  public async createAndUploadContent(file: File, contentId?, owner?) {
    const contentEntity = await this.createContent(file, contentId, owner);
    await this.uploadFile(contentEntity.primaryFile.uploadPost, file);
    return contentEntity;
  }

  public async updateContent(id: string, file: File) {
    const fileId = nanoid(16);
    const fileEntity = await this.documentService.fileHandler.createAndUploadFile(file, fileId);

    const content = await new Entities().update({
      entityName: 'content',
      id,
      object: {
        primaryFileId: fileId,
      },
    });

    return fileEntity;
  }

  public async createAndUploadJSONFile(jsonString: string, fileName, ttl?) {
    const blob = new Blob([jsonString], { type: 'application/json' });
    const file = new File([blob], `${fileName}.json`, {
      type: 'application/json',
    });
    return await this.createAndUploadFile(file, null, ttl);
  }

  /**
   * Create a File, and returns a promise that contains a document action to updates the
   * bindings of the element with the correct URLs after upload has completed.
   * This is used elsewhere to swap the local blob URL with the correct download link.
   * @param element
   * @param file
   * @returns
   */
  private async updateModelBindingAfterFileUpload(element: DocumentElement, file: File, fileId?): Promise<any> {
    return await this.createAndUploadFile(file, fileId).then((fileEntity) => {
      return new DocumentAction({
        changeType: DocumentChangeType.MODIFY_ELEMENT,
        elementData: {
          id: element.id,
          url: fileEntity.fileUrl,
        },
        elementId: element.id,
      });
    });
  }

  /**
   * Create a Content entity, and returns a promise that contains a document action to updates the
   * bindings of the element with the correct URLs after upload has completed.
   * This is used elsewhere to swap the local blob URL with the correct download link.
   * @param element
   * @param file
   * @returns
   */
  public async updateModelBindingAfterContentUpload(element: DocumentElement, file: File, contentId?): Promise<any> {
    const documentId = this.documentService.currentDocument.id;
    return await this.createAndUploadContent(file, contentId).then((contentEntity) => {
      console.log('Created / uploaded content.. creating model binding action: ', contentEntity);
      return new DocumentAction({
        changeType: DocumentChangeType.MODIFY_ELEMENT,
        elementData: {
          id: element.id,
          alternateUrls: {
            originalFile: contentEntity.primaryFile.fileUrl,
          },
        },
        elementId: element.id,
        documentId,
      });
    });
  }

  public async getSVGTextFromDocumentElement(element: DocumentElement) {
    const authContext = await this.documentService.authService.getAuthContext();
    const fileDownLoader = new FileDownloader(
      { apiToken: authContext.token, orgSlug: authContext.currentOrg.orgSlug },
      { imageHost: environment.imageHost },
    );
    return await SVGHelper.getSVGTextFromDocumentElement(element, fileDownLoader);
  }

  public async addImageElementsFromFileUrl(
    primaryFileUrl: string,
    fileName: string,
    type: string,
    options: DocumentElement,
  ) {
    const authContext = await this.documentService.authService.getAuthContext();
    const fileDownLoader = new FileDownloader(
      { apiToken: authContext.token, orgSlug: authContext.currentOrg.orgSlug },
      { imageHost: environment.imageHost },
    );
    const blob = await fileDownLoader.downloadFileAsBlob(primaryFileUrl);
    this.documentService.fileHandler.addImageElementsFromFiles([new File([blob], fileName, { type })], options);
  }

  /**
   * Adds image (including PDF and AI files) or svg elements to the document in batch.
   * @param files
   * @param options
   */
  public async addImageElementsFromFiles(
    files: File[],
    options: DocumentElement = {},
    targetElement?: DocumentElement,
  ) {
    this.uploading = true;
    const elements = [];
    const updateModelBindingsPromises = [];
    const promises = [];
    const documentId = this.documentService.currentDocument.id;

    if (targetElement?.type === 'component' && targetElement?.modelBindings?.item) {
      let allFilesValid = true;
      for (const file of files) {
        if (file.type.indexOf('image') === -1) {
          allFilesValid = false;
          break;
        }
      }
      if (allFilesValid) {
        this.uploading = false;
        await this.uploadFilesAndHandleImageItemAssignment(files, targetElement);
        return;
      }
    }

    this.documentService.toggleLoading(true, 'Uploading. Please wait....');
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (
        ['application/postscript', 'application/pdf'].includes(file.type) ||
        file.type.indexOf('image') > -1 ||
        file.name.endsWith('.glb') ||
        file.name.endsWith('.gltf')
      ) {
        const promise = limit(async () => {
          return await this.createImageElementFromFile(file, {});
        });
        promises.push(promise);
      }
    }

    await Promise.all(promises)
      .then((results) => {
        let offset;
        results.forEach(({ element, updateModelBindingPromise }) => {
          if (element) {
            if (options?.position) {
              if (!offset) {
                offset = {
                  x: options.position.x - (element.size?.width || 300) / 2,
                  y: options.position.y - (element.size?.height || 300) / 2,
                };
              }
              element.position.x = offset.x;
              element.position.y = offset.y;
              offset.x = element.position.x + (element.size?.width || 300) + 50;
            }
            elements.push(element);
          }
          if (updateModelBindingPromise) {
            updateModelBindingsPromises.push(updateModelBindingPromise);
          }
        });
      })
      .catch(() => {
        this.uploading = false;
      });
    this.documentService.toggleLoading(false, '');

    if (elements?.length > 0) {
      this.documentService.deselectAllElements();
      const actions = elements.map((element) => {
        return new DocumentAction(
          {
            changeType: DocumentChangeType.ADD_ELEMENT,
            elementData: element,
            elementId: element.id,
          },
          {
            changeType: DocumentChangeType.DELETE_ELEMENT,
            elementId: element.id,
            elementData: ObjectUtil.cloneDeep(element),
          },
        );
      });
      this.documentService.handleDocumentActions(actions);
    } else {
      this.uploading = false;
    }

    /**
     * Wait for all promises to complete, the push the updates to the element that
     * will include the updated download URLs, usable by other browsers.
     */
    if (updateModelBindingsPromises?.length > 0) {
      Promise.all(updateModelBindingsPromises)
        .then((actions) => {
          console.log('Completing image upload: actions: ', actions);

          if (actions?.length > 0) {
            // Set file url to current session
            this.documentService.applyDocumentActions(ObjectUtil.cloneDeep(actions), true);
            // Send file url to remote sessions
            this.documentService.splitActionsAndSendSessionEvent(actions, { id: documentId });
          }
        })
        .finally(() => {
          this.uploading = false;
        });
    } else {
      this.uploading = false;
    }
  }

  /**
   * Upload @files as Content and assign as viewables to @element
   * @param files
   * @param element
   */
  public async uploadFilesAndHandleImageItemAssignment(files: File[], element: DocumentElement) {
    this.documentService.handleDocumentElementEvent({
      element: element,
      data: files,
      eventType: 'file_drag',
    });
  }

  public createFileObjectFromSvgString(svgText: string, fileName?: string) {
    const prefix = nanoid(16);
    const file = new File([svgText], !fileName || fileName == '' ? `svg-${prefix}.svg` : fileName, {
      type: 'image/svg+xml',
    });
    return file;
  }

  /**
   * Creates an SVG document element.
   * Prefixes ids and classes in the svg string with a unique id.
   * @param svgText
   * @param options
   * @param fileName
   * @returns
   */
  public async createSvgElementFromString(
    svgText: string,
    options?: DocumentElement,
    fileName?: string,
  ): Promise<{ element: DocumentElement; updateModelBindingPromise?: Promise<any> }> {
    // const fileEntity = await this.createAndUploadFile(new File([svgHtmlString], (!fileName || fileName == '') ? `svg-${prefix}.svg` : fileName, {
    //   type: 'image/svg+xml'
    // }));

    // Upload rasterized SVG as a File entity so it can be passed to remote sessions, and also used on element copy/paste.
    // This is temp URL which will be automatically replaced by API once SVG viewables are generated.
    const { canvasBlob } = await SVGHelper.getCanvasBlob(svgText);
    const fileEntity = await this.documentService.fileHandler.createAndUploadFile(
      new File([canvasBlob], `canvasImage.png`, {
        type: 'image/png',
      }),
    );

    const file = this.createFileObjectFromSvgString(svgText, fileName);
    // const fileAsDataURL = URL.createObjectURL(file);
    const contentId = nanoid(16);

    const params: any = {
      modelBindings: { content: 'content:' + contentId },
      position: {
        x: 0,
        y: 0,
      },
      propertyBindings: { url: 'content.largeViewableUrl' },
      url: fileEntity.fileUrl,
      alternateUrls: {
        originalFile: fileEntity.fileUrl,
        highResolution: fileEntity.fileUrl,
        lowResolution: fileEntity.fileUrl,
      },
      // url: fileAsDataURL, // this is a temporary URL that will automatically be replaced once file is done uploading
    };

    const size = SVGHelper.getSize(svgText);
    if (size) {
      params.size = size;
    }

    if (options?.position && params.size) {
      params.position = {
        x: options.position.x - params.size.width / 2,
        y: options.position.y - params.size.height / 2,
      };
    }

    const element = DocumentElementFactory.createElement('svg', params);
    const updateModelBindingPromise = this.updateModelBindingAfterContentUpload(element, file, contentId);

    return {
      element,
      updateModelBindingPromise: updateModelBindingPromise,
    };
  }

  /**
   * @param files
   * @returns
   */
  public async createContentsFromFiles(files: File[], owner?: any): Promise<any> {
    const promises = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const promise = limit(async () => {
        return await this.createContentFromFile(file, owner);
      });
      if (promise) {
        promises.push(promise);
      }
    }
    return await Promise.all(promises);
  }

  /**
   * Create and upload Content from uploaded @file
   * @param file
   * @returns
   */
  public async createContentFromFile(file: File, owner?: any): Promise<Content> {
    try {
      const isSvgImageFileType = file.type.indexOf('image/svg+xml') !== -1;
      const isImageFileType = file.type.indexOf('image') > -1;
      const isGeneratableViewFileType =
        ['application/postscript', 'application/pdf'].includes(file.type) ||
        file.name.endsWith('.glb') ||
        file.name.endsWith('.gltf');

      let content;
      if (isSvgImageFileType) {
        const svgText = await file.text();
        content = await this.createAndUploadContent(
          this.createFileObjectFromSvgString(svgText, file.name),
          null,
          owner,
        );
      } else if (isImageFileType) {
        content = await this.createAndUploadContent(file, null, owner);
      } else if (isGeneratableViewFileType) {
        content = await this.createAndUploadContent(file, null, owner);
      }

      return content;
    } catch (e) {
      console.log('Error creating content: ', e);
      return null;
    }
  }

  /**
   * Creates image document element.
   * Generates viewable from PDF and AI files.
   * @param file
   * @param options
   * @returns
   */
  public async createImageElementFromFile(
    file: File,
    options?: DocumentElement,
  ): Promise<{ element: DocumentElement; updateModelBindingPromise?: Promise<any> }> {
    let imageElement: DocumentElement;
    let updateModelBindingPromise: Promise<DocumentAction>;
    try {
      const isSvgImageFileType = file.type.indexOf('image/svg+xml') !== -1;
      const isImageFileType = file.type.indexOf('image') > -1;
      const isGeneratableViewFileType =
        ['application/postscript', 'application/pdf'].includes(file.type) ||
        file.name.endsWith('.glb') ||
        file.name.endsWith('.gltf');

      let imageSize: SizeDefinition;
      if (isSvgImageFileType) {
        const response = await file.text();
        const svgElementResponse = await this.createSvgElementFromString(response, options, file.name);
        imageElement = svgElementResponse.element;
        updateModelBindingPromise = svgElementResponse.updateModelBindingPromise;
      } else if (isImageFileType) {
        // ----------------------------------------
        // SHOW LOADING SPINNER UNTIL UPLOADING ----------------------------------------
        // const fileEntity = await this.createAndUploadFile(file);
        // const imageDimensions = await this.getImageDimensionsFromFile(file);
        // imageSize = this.getAdjustedImageSize(imageDimensions);

        // const imageOptions: DocumentElement = {
        //   modelBindings: { image: 'file:' + fileEntity.id },
        //   size: imageSize,
        //   propertyBindings: { url: 'image.fileUrl' },
        //   url: fileEntity.fileUrl
        //   // url: fileAsDataURL // this is a temporary URL that will automatically be replaced once file is done uploading
        // };
        // if (options?.position) {
        //   imageOptions.position = {
        //     x: options.position.x - imageOptions.size.width / 2,
        //     y: options.position.y - imageOptions.size.height / 2
        //   };
        // }
        // imageElement = DocumentElementFactory.createImageElement(imageOptions);
        // // updateModelBinding = this.updateModelBindingAfterFileUpload(imageElement, file);

        // ----------------------------------------
        // INSTANT IMAGE SHOW - NO NEED TO WAIT FOR UPLOADING ----------------------------------------
        const fileAsDataURL = URL.createObjectURL(file);
        const imageDimensions = await this.getImageDimensionsFromUrl(fileAsDataURL);
        imageSize = this.getAdjustedImageSize(imageDimensions);

        const contentId = nanoid(16);
        const imageOptions: DocumentElement = {
          modelBindings: { content: 'content:' + contentId },
          size: imageSize,
          propertyBindings: { url: 'content.largeViewableUrl' },
          url: fileAsDataURL, // this is a temporary URL that will automatically be replaced once file is done uploading
        };
        if (options?.position) {
          imageOptions.position = {
            x: options.position.x - imageOptions.size.width / 2,
            y: options.position.y - imageOptions.size.height / 2,
          };
        }
        imageElement = DocumentElementFactory.createImageElement(imageOptions);
        updateModelBindingPromise = this.updateModelBindingAfterContentUpload(imageElement, file, contentId);
      } else if (isGeneratableViewFileType) {
        const contentEntity = await this.documentService.fileHandler.createAndUploadContent(file);
        imageSize = {
          height: 300,
          width: 300,
        };
        const imageOptions: DocumentElement = {
          modelBindings: { content: 'content:' + contentEntity.id },
          size: imageSize,
          propertyBindings: { url: 'content.largeViewableUrl' },
          url: this.LOADING_IMAGE_ICON, // Temp image while generating viewable
        };
        if (options?.position) {
          imageOptions.position = {
            x: options.position.x - imageOptions.size.width / 2,
            y: options.position.y - imageOptions.size.height / 2,
          };
        }
        imageElement = DocumentElementFactory.createImageElement(imageOptions);

        // Poll for
        setTimeout(() => {
          this.pollForViewables(contentEntity.id, imageElement);
        }, POLLING_INTERVAL);
      }
    } catch (e) {
      console.log('Error uploading file: ', e);
    }

    return { element: imageElement, updateModelBindingPromise };
  }

  private async getImageDimensionsFromFile(file: File): Promise<SizeDefinition> {
    const fileAsDataURL = URL.createObjectURL(file);
    return this.getImageDimensionsFromUrl(fileAsDataURL);
  }

  private async getImageDimensionsFromUrl(url: string): Promise<SizeDefinition> {
    return new Promise((resolve) => {
      this.http
        .get(url, { responseType: 'blob' })
        .pipe(
          take(1),
          map((blob) => {
            return this.sanitizer.sanitize(
              SecurityContext.RESOURCE_URL,
              this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob)),
            );
          }),
        )
        .subscribe((url) => {
          const img = new Image();
          img.onload = () => {
            resolve({
              height: img.height,
              width: img.width,
            });
          };
          img.src = url;
        });
    });
  }

  private getAdjustedImageSize(size: SizeDefinition): SizeDefinition {
    const width = 300;
    const height = (300 / size.width) * size.height;
    return {
      height,
      width,
    };
  }

  private async pollForViewables(contentId: any, imageElement: DocumentElement, count = 0) {
    const content = await new Entities().get({
      entityName: 'content',
      id: contentId,
    });

    if (content?.largeViewableId) {
      // adjust the sizing
      const imageDimensions = await this.getImageDimensionsFromUrl(imageElement.url);
      const adjustedDimensions = this.getAdjustedImageSize(imageDimensions);

      const action = new DocumentAction({
        changeType: DocumentChangeType.MODIFY_ELEMENT,
        elementData: {
          url: content.largeViewableUrl,
          size: adjustedDimensions,
          id: imageElement.id,
        },
        elementId: imageElement.id,
      });

      this.documentService.handleDocumentActions([action]);
    } else if (count < POLLING_RETRIES) {
      setTimeout(() => {
        this.pollForViewables(contentId, imageElement, ++count);
      }, POLLING_INTERVAL);
    }
  }

  /**
   * Update @element with new @svgHtmlString
   * Apply rasterized SVG to current board for better performance.
   * @param element
   * @param svgHtmlString
   * @param contentId
   * @param fileName
   */
  public async updateContentElement(
    element: DocumentElement,
    svgHtmlString: string,
    contentId: string,
    fileName?: string,
  ): Promise<{ element: DocumentElement; updateModelBindingPromise?: Promise<any> }> {
    if (SVGHelper.isSvg(element)) {
      return await this.updateSvgElement(ObjectUtil.cloneDeep(element), svgHtmlString, fileName);
    } else if (DocumentItemService.isItemComponet(element)) {
      return await this.updateSvgElementInComponent(ObjectUtil.cloneDeep(element), contentId, svgHtmlString, fileName);
    }
    return;
  }

  /**
   * Update @element with new @svgHtmlString
   * Apply rasterized SVG to current board for better performance.
   * @param element
   * @param svgHtmlString
   * @param contentId
   * @param fileName
   */
  public async handleUpdateContentElement(
    element: DocumentElement,
    svgHtmlString: string,
    contentId: string,
    fileName?: string,
  ): Promise<DocumentElement> {
    this.documentService.toggleLoading(true, 'Uploading. Please wait....');

    const oldElement = ObjectUtil.cloneDeep(element);
    const result: { element: DocumentElement; updateModelBindingPromise?: Promise<any> } =
      await this.updateContentElement(element, svgHtmlString, contentId, fileName);
    if (result?.element) {
      this.documentService.handleDocumentActions([
        new DocumentAction(
          {
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementData: result.element,
            elementId: element.id,
          },
          {
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementId: element.id,
            elementData: oldElement,
          },
        ),
      ]);
      if (result?.updateModelBindingPromise) {
        Promise.all([result.updateModelBindingPromise])
          .then((actions) => {
            if (actions?.length > 0) {
              // Set file url to current session
              this.documentService.applyDocumentActions(ObjectUtil.cloneDeep(actions), true);
              // Send file url to remote sessions
              this.documentService.splitActionsAndSendSessionEvent(actions);
            }
          })
          .finally(() => {});
      }
    }
    this.documentService.toggleLoading(false, '');
    return result?.element;
  }

  /**
   * Update SVG image in component @element
   * This keeps existing content, and updates it with new primaryFileId
   * 1. Create new File entity
   * 2. Update existing Content with new @primaryFileId by @contentId
   * @param element
   * @param contentId
   * @param svgHtmlString
   * @param fileName
   * @returns
   */
  public async updateSvgElementInComponent(
    element: DocumentElement,
    contentId: string,
    svgHtmlString: string,
    fileName?: string,
  ) {
    // Update existing Content entity with new File entity for the SVG
    const file = this.documentService.fileHandler.createFileObjectFromSvgString(svgHtmlString, fileName);
    const newElement: DocumentElement = {
      id: element.id,
      elements: ObjectUtil.cloneDeep(element.elements),
      modelBindings: ObjectUtil.cloneDeep(element.modelBindings),
    };

    const [tempRasterizedSVGFileEntity, fileEntityFromContent] = await Promise.all([
      (async function (documentService) {
        const { canvasBlob } = await SVGHelper.getCanvasBlob(svgHtmlString);
        return await documentService.fileHandler.createAndUploadFile(
          new File([canvasBlob], `canvasImage.png`, {
            type: 'image/png',
          }),
        );
      })(this.documentService),
      await this.documentService.fileHandler.updateContent(contentId, file),
    ]);

    const imageElement = newElement.elements.find((e) => e.type === 'image');
    const originalFile = fileEntityFromContent.fileUrl;
    const highResolution = tempRasterizedSVGFileEntity.fileUrl;
    const lowResolution = tempRasterizedSVGFileEntity.fileUrl;
    imageElement.url = highResolution;
    imageElement.alternateUrls = {
      lowResolution,
      highResolution,
      originalFile,
    };

    return { element: newElement };
  }

  /**
   * Update SVG @element with new @svgHtmlString
   * Create new Content entity and set it to element's modelBindings
   * @param element
   * @param svgHtmlString
   * @param fileName
   * @returns
   */
  public async updateSvgElement(
    element: DocumentElement,
    svgHtmlString: string,
    fileName: string,
  ): Promise<{ element: DocumentElement; updateModelBindingPromise?: Promise<any> }> {
    /**
     * tempRasterizedSVGFileEntity and tempSVGFileEntity are temporary files. tempRasterizedSVGFileEntity is rasterized
     * version of the new SVG which helps with rendering performance of large SVGs.
     * tempSVGFileEntity creates a URL that can be passed to remote sessions and also fetched to get
     * SVG HTML on second recolor.
     */
    const file = this.documentService.fileHandler.createFileObjectFromSvgString(svgHtmlString, fileName);
    const [tempRasterizedSVGFileEntity, tempSVGFileEntity] = await Promise.all([
      (async function (documentService) {
        const { canvasBlob } = await SVGHelper.getCanvasBlob(svgHtmlString);
        return await documentService.fileHandler.createAndUploadFile(
          new File([canvasBlob], `canvasImage.png`, {
            type: 'image/png',
          }),
        );
      })(this.documentService),
      this.documentService.fileHandler.createAndUploadFile(file),
    ]);

    const contentId = nanoid(16);
    const updateModelBindingPromise = this.updateModelBindingAfterContentUpload(element, file, contentId);

    // Upload rasterized SVG as a File entity so it can be passed to remote sessions, and also used on element copy/paste.
    // This is temp URL which will be automatically replaces by API once SVG viewables are generated.
    // const { canvasBlob } = await SVGHelper.getCanvasBlob(svgHtmlString);
    // const tempRasterizedSVGFileEntity = await this.documentService.fileHandler.createAndUploadFile(
    //   new File([canvasBlob], `canvasImage.png`, {
    //     type: 'image/png',
    //   }),
    // );

    // // Create new content for the SVG
    // const contentId = nanoid(16);
    // const file = this.documentService.fileHandler.createFileObjectFromSvgString(svgHtmlString, fileName);
    // const contentEntity = await this.documentService.fileHandler.createAndUploadContent(file, contentId);

    // const originalFile = contentEntity.primaryFile.fileUrl;
    const originalFile = tempSVGFileEntity.fileUrl;
    const highResolution = tempRasterizedSVGFileEntity.fileUrl;
    const lowResolution = tempRasterizedSVGFileEntity.fileUrl;
    return {
      element: {
        id: element.id,
        modelBindings: {
          content: 'content:' + contentId,
        },
        propertyBindings: {
          url: 'content.largeViewableUrl',
        },
        url: highResolution,
        alternateUrls: {
          highResolution: highResolution,
          lowResolution: lowResolution,
          originalFile: originalFile,
        },
      },
      updateModelBindingPromise,
    };
  }
}
