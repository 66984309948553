import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { AssortmentsSelectors } from 'src/app/common/assortments/assortments-store';
import { Item } from 'src/app/common/assortments/item';
import { ItemData } from 'src/app/common/item-data/item-data';
import { AuthSelectors } from 'src/app/common/auth/auth-store';
import { FilterUtil } from 'src/app/common/util/filter-util';
import { RootStoreState } from 'src/app/root-store';
import { ShowcasesActions, ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { CollectionStatusMessage } from '@common/collection-status-message/collection-status-message';
import { DocumentActions } from 'src/app/presentation/document/document-store';
import { SideMenuOverlay } from 'src/app/presentation/document/document-store/document.state';
import { ShowcasesService } from 'src/app/showcases/showcases.service';
import { ObjectUtil } from '@contrail/util';

@Injectable({
  providedIn: 'root',
})
export class CollectionStatusMessageService {
  messages$: Observable<Array<CollectionStatusMessage>>;

  constructor(
    private store: Store<RootStoreState.State>,
    private showcaseService: ShowcasesService,
  ) {
    console.log(`CollectionStatusMessageService called`);
    this.initState();
  }

  private initState() {
    combineLatest([
      this.store.select(AssortmentsSelectors.sourceAssortmentItemData),
      this.store.select(AssortmentsSelectors.backingAssortmentItems),
      this.store.select(AuthSelectors.selectAuthContext),
    ])
      .pipe(
        debounceTime(1000),
        tap(([sourceItemData, backingAssortmentItems, auth]: [Array<ItemData>, Array<Item>, any]) => {
          this.validateVsSourceAssortment(backingAssortmentItems, sourceItemData, auth?.currentOrg?.orgSlug);
        }),
      )
      .subscribe();
    this.messages$ = this.store.select(ShowcasesSelectors.collectionStatusMessages) as Observable<
      Array<CollectionStatusMessage>
    >;
  }

  public containsItemOption(sourceAssortmentItemData: Array<ItemData>, entity, orgSlug) {
    const found = sourceAssortmentItemData.find((itemData) => {
      return (
        (entity.item?.roles?.includes('option')
          ? itemData['id']
          : ObjectUtil.getBySlugs(itemData, 'item', 'itemFamilyId')) === entity.itemId
      );
    });
    if (FilterUtil.isDropped(found, orgSlug)) {
      return false;
    }
    return !!found;
  }

  public validateVsSourceAssortment(collection: Array<Item>, sourceItemData: Array<ItemData>, orgSlug = '') {
    this.store.dispatch(ShowcasesActions.clearStatusMessages());
    if (!sourceItemData) {
      return [];
    }
    collection.forEach((entity: Item) => {
      if (entity.itemId && !this.containsItemOption(sourceItemData, entity, orgSlug)) {
        const entityName = entity.item.name + (entity.item.optionName ? ' / ' + entity.item.optionName : '');
        this.store.dispatch(
          ShowcasesActions.addStatusMessage({
            message: {
              id: entity.itemId + '_missing_in_source',
              type: 'WARNING',
              message: `Item is missing from this document's source assortment.`,
              collectionElementId: entity.itemId,
              entityName,
            },
          }),
        );
      }
    });
  }

  showAlertMessages() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Warnings';
    overlay.slug = 'messages';
    overlay.showChooser = true;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  setStatusMessageElement(statusMessageElement) {
    this.store.dispatch(ShowcasesActions.setStatusMessageElement(statusMessageElement));
  }

  toggleShowWarning(showSourceAssortmentWarning) {
    const id = this.showcaseService.getCurrentShowcase().id;
    this.store.dispatch(ShowcasesActions.updateShowcase({ id, changes: { showSourceAssortmentWarning } }));
    this.store.dispatch(ShowcasesActions.setShowSourceAssortmentWarning({ showSourceAssortmentWarning }));
  }
}
