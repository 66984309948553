<div #widgetTray class="widget-tray" (click)="handleClick($event)">
  <div class="widgets-list" *ngIf="sourceEvent">
    <app-property-configurator-color-picker
      (valueChange)="updateValues($event)"
      [currentValue]="getCurrentValue('style.color')"
      [matIconType]="'format_color_text'"
      [colorType]="'fontColor'"
    >
    </app-property-configurator-color-picker>
    <app-property-configurator-font-size
      (valueChange)="updateValues($event)"
      [currentValue]="getCurrentValue('style.font.size')"
    ></app-property-configurator-font-size>
  </div>
</div>
