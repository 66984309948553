<div class="showroom-item-container">
  <!-- <mat-icon [mat-dialog-close]="true" class="absolute top-5 right-5 cursor-pointer">close</mat-icon> -->

  <div class="item-contents flex">
    <div class="flex flex-col mr-7">
      <div
        *ngFor="let content of contents; index as idx"
        class="content-thumbnail flex-center"
        (click)="setSelectedContent(idx)"
        [class.border-blue-400]="idx === contentIdx"
      >
        <img
          *ngIf="imageOr3d(content) === 'image'"
          [src]="content?.mediumViewable?.downloadUrl || content?.primaryFile?.downloadUrl"
        />
        <div *ngIf="imageOr3d(content) === '3d'" class="extension">
          {{ content?.primaryFile?.fileName?.split('.')?.pop() | uppercase }}
        </div>
      </div>
    </div>

    <div class="contents-preview">
      <img *ngIf="selectedContent && isImage" [src]="downloadUrl" />
      <app-webgl-viewer [size]="'640px'" [content]="selectedContent" *ngIf="selectedContent && is3d"></app-webgl-viewer>
    </div>

    <div class="no-content flex-center w-[640px] h-[640px]" *ngIf="!contents?.length">NO PREVIEW CONTENT</div>
  </div>

  <div class="item-info">
    <h4 class="text-black-60 font-medium">{{ item?.name }}</h4>
    <h5 class="text-black-60 italic">{{ item?.colorName }}</h5>

    <div class="details-comments">
      <mat-tab-group mat-align-tabs="start">
        <mat-tab label="DETAILS">
          <div class="details-wrap">
            <div class="flex mb-2">
              <div class="info-key">Style:</div>
              <div class="info-value">{{ item?.styleNumber }}</div>
            </div>
            <div class="flex mb-2">
              <div class="info-key">Color Code:</div>
              <div class="info-value">{{ item?.colorName }}</div>
            </div>
            <div class="flex mb-2">
              <div class="info-key">Collection:</div>
              <div class="info-value">{{ item?.collection }}</div>
            </div>
            <div class="flex mb-2">
              <div class="info-key">Division:</div>
              <div class="info-value">{{ item?.division }}</div>
            </div>
            <div class="flex mb-2">
              <div class="info-key">Wholesale:</div>
              <div class="info-value">{{ item?.wholesalePrice }}</div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="COMMENTS" [disabled]="true"> </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
