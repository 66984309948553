<div class="placeholder" (contextmenu)="onContextMenu($event, frame)">
  <div [ngClass]="{ active: selectedPlaceholderFrame && selectedPlaceholderFrame.id === frame.id }"></div>
</div>
<!--<div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent let-frame="frame">
    <button mat-menu-item (click)="pasteFrame()">
      <mat-icon class="icons">content_paste</mat-icon>
      <span>Paste</span>
    </button>
  </ng-template>
</mat-menu>-->
