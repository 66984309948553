import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ShareShowcaseModalComponent } from './share-showcase-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ShareShowcaseModalLauncher {
  constructor(public dialog: MatDialog) {}

  openModal(showcase): void {
    const dialogRef = this.dialog.open(ShareShowcaseModalComponent, { data: { showcase }, autoFocus: false });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
}
