import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription, combineLatest, Subject } from 'rxjs';
import { PresentationFrame } from '../../../../presentation';
import { AuthService } from '@common/auth/auth.service';
import { DocumentAnnotationService } from 'src/app/presentation/document/document-annotation/document-annotation-service';
import { debounceTime, filter } from 'rxjs/operators';
import { CanvasDocument, CANVAS_MODE } from 'src/app/presentation/canvas-lib';
import { environment } from 'src/environments/environment';
import { DocumentService } from 'src/app/presentation/document/document.service';
import { ObjectUtil } from '@contrail/util';
import { DocumentNavigationEvent } from '@contrail/documents';
import { DocumentItemService } from 'src/app/presentation/document/document-item/document-item.service';
import { DocumentColorService } from 'src/app/presentation/document/document-color/document-color.service';

@Component({
  selector: 'app-composer-svg-frame-preview',
  templateUrl: './composer-svg-frame-preview.component.html',
  styleUrls: ['./composer-svg-frame-preview.component.scss'],
})
export class ComposerSvgFramePreviewComponent implements OnDestroy, OnChanges {
  @Input() frame: PresentationFrame;

  private canvasDocument: CanvasDocument;
  private framePreviewDocumentService: DocumentService;
  private loadPreview$: Subject<any> = new Subject();
  private subscriptions = new Subscription();
  private documentSubscriptions = new Subscription();

  constructor(
    private authService: AuthService,
    private documentAnnotationService: DocumentAnnotationService,
    private documentService: DocumentService,
    private documentItemService: DocumentItemService,
    private documentColorService: DocumentColorService,
  ) {
    this.subscriptions.add(
      this.loadPreview$.pipe(debounceTime(1000)).subscribe(() => {
        this.loadPreview();
      }),
    );
  }

  ngAfterViewInit(): void {
    this.loadPreview$.next(null);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.frame?.currentValue && document.getElementById('SVG' + this.frame.id)) {
      this.frame = ObjectUtil.cloneDeep(changes.frame.currentValue);
      this.loadPreview();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.documentSubscriptions.unsubscribe();
  }

  private loadPreview() {
    if (!this.frame?.document) {
      return;
    }

    this.documentSubscriptions.unsubscribe();

    const document = ObjectUtil.cloneDeep(this.frame.document);
    this.framePreviewDocumentService = new DocumentService(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      this.documentItemService,
      this.documentColorService,
      null,
      null,
    );
    this.canvasDocument = new CanvasDocument(
      'SVG' + this.frame.id,
      document,
      this.framePreviewDocumentService,
      CANVAS_MODE.PREVIEW,
      this.authService,
      { imageHost: environment.imageHost },
      this.documentAnnotationService.getAnnotationOptions(),
    );
    this.canvasDocument.syncState(
      this.frame.document.size,
      { width: 160, height: 90, x: 0, y: 0 },
      { width: 160, height: 90 },
      { width: this.frame.document.size.width, height: this.frame.document.size.height, x: 0, y: 0 },
    );

    this.framePreviewDocumentService.setRenderer(this.canvasDocument);
    this.framePreviewDocumentService.init(document, this.frame.ownedByReference);
    this.subscriptions.add(
      combineLatest(
        this.framePreviewDocumentService.elementsAdded$.pipe(
          filter((b) => b === true),
          debounceTime(1000),
        ),
        this.documentAnnotationService.annotatedElements$,
      ).subscribe(([elementsAdded, annotatedElements]) => {
        if (annotatedElements?.length > 0 && this.canvasDocument && this.framePreviewDocumentService) {
          this.framePreviewDocumentService.setAnnotations(annotatedElements);
        }
      }),
    );

    this.documentSubscriptions.add(
      this.documentService.documentActions.pipe(debounceTime(600)).subscribe(this.handleDocumentActions.bind(this)),
    );
    this.documentSubscriptions.add(
      this.documentService.remoteDocumentActions
        .pipe(debounceTime(600))
        .subscribe(this.handleDocumentActions.bind(this)),
    );

    this.documentSubscriptions.add(
      this.documentService.documentNavigationEvents.subscribe((event: DocumentNavigationEvent) => {
        if (this.canvasDocument && event?.eventType === 'visibilitychange' && event?.navigationType === 'visible') {
          this.canvasDocument.queueDraw();
        }
      }),
    );
  }

  private handleDocumentActions(actions) {
    if (!actions || actions?.length === 0 || !this.frame?.document) {
      return;
    }

    const documentId = actions[0].changeDefinition?.documentId;

    if (this.frame?.document?.id === documentId) {
      this.framePreviewDocumentService.applyDocumentActions(actions);
    }
  }
}
