import { ObjectUtil } from '@contrail/util';
import { Injectable } from '@angular/core';
import { DocumentService } from '../document.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AnnotatedElement } from './document-annotation-options';
import { DocumentActions } from '../document-store';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';

export const ANNOTATION_IMG_SIZE = 15;
export const ANNOTATION_PADDING_Y = 8;

@Injectable({
  providedIn: 'root',
})
export class DocumentAnnotationService {
  private annotationEventsSubject: BehaviorSubject<any> = new BehaviorSubject({});
  public annotationEvents: Observable<any> = this.annotationEventsSubject.asObservable();

  public annotatedElementsSubject: BehaviorSubject<Array<AnnotatedElement>> = new BehaviorSubject(null);
  public annotatedElements$: Observable<Array<AnnotatedElement>> = this.annotatedElementsSubject.asObservable();

  constructor(
    private store: Store<RootStoreState.State>,
    private documentService: DocumentService,
  ) {
    this.init();
  }

  init() {
    this.documentService.documentElementEvents.subscribe((event) => {
      const annotationModes = this.documentService.annotationOptions.map((annotationOption) => annotationOption.type);
      const interactionMode = this.documentService.getInteractionMode();
      if (annotationModes.includes(interactionMode)) {
        // This event's "element" is not null during a right-click but is null when selecting multiple
        // elements by dragging. We are looking for elements that were selected by dragging.
        if (event?.eventType === 'selected' && event?.element === null) {
          const selectedElements = ObjectUtil.cloneDeep(this.documentService.getSelectedElements());
          if (selectedElements.length > 0) {
            this.annotate(selectedElements);
            this.documentService.deselectAllElements();
          }
        } else if (event?.eventType === 'click') {
          // listening to left-button click event
          this.annotate([event.element]);
        }
      }
    });
  }

  restoreDefaultCursor() {
    setTimeout(() => {
      this.documentService.setInteractionMode('select');
    }, 50);
  }

  annotate(selectedElements) {
    this.annotationEventsSubject.next({
      selectedElements: ObjectUtil.cloneDeep(selectedElements?.filter((e) => !e.isLocked)),
      eventType: this.documentService.getInteractionMode(),
    });
  }

  getAnnotationOptions() {
    return this.documentService.annotationOptions;
  }

  setAnnotatedElements(annotatedElements) {
    this.store.dispatch(DocumentActions.setAnnotatedElements({ annotatedElements }));
  }
}
