import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-item-test',
  templateUrl: './item-test.component.html',
  styleUrls: ['./item-test.component.scss'],
})
export class ItemTestComponent implements OnInit {
  public itemId;
  constructor(
    private activatedRoute: ActivatedRoute,
    private _location: Location,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      console.log('params: ', params);
      if (params.itemId && this.itemId !== params.itemId) {
        this.itemId = params.itemId;
      }
    });
  }

  cancel() {
    this._location.back();
  }
}
