import { Component, Input } from '@angular/core';
import { PresentationFrame } from '../../../presentation';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';

@Component({
  selector: 'app-composer-frame-preview',
  templateUrl: './composer-frame-preview.component.html',
  styleUrls: ['./composer-frame-preview.component.scss'],
})
export class ComposerFramePreviewComponent {
  @Input() frame: PresentationFrame;
  @Input() editorMode: EditorMode;
  @Input() selected = false;
  @Input() lineboardSelected = false;

  constructor() {}
}
