<div id="sidenav">
  <img class="logo" (click)="goHome()" src="assets/images/vibeiq_large.jpg" />

  <!-- <button mat-raised-button color="primary" class="new-btn" [matMenuTriggerFor]="newMenu">
    <mat-icon class="mr-2">add</mat-icon> NEW
  </button>
  <mat-menu #newMenu="matMenu" class="add-new-menu">
    <button mat-menu-item (click)="createShowcase()">
      <div class="flex items-center">
        <img src="assets/images/showcase_icon.svg" class="w-6 h-6 object-contain" alt="">
        <span class="text-title-2 ml-6">Showcase</span>
      </div>
    </button>
  </mat-menu> -->

  <div class="w-full pl-4 mb-4 mt-[10px]">
    <app-workspace-selector></app-workspace-selector>
  </div>

  <ng-container *ngFor="let navOption of navOptions">
    <div *ngIf="navOption.group" class="nav-link">
      <mat-icon class="mr-6">{{ navOption.icon }}</mat-icon>
      <span class="text-title-2">{{ navOption.label }}</span>
    </div>
    <div
      *ngIf="!navOption.group"
      class="nav-link"
      [routerLink]="[navOption.routerLink]"
      [queryParams]="navOption?.queryParams"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      [ngStyle]="{ 'margin-left': navOption.child ? '5rem' : '1rem' }"
    >
      <mat-icon *ngIf="navOption.icon" class="mr-6">{{ navOption.icon }}</mat-icon>
      <span class="text-title-2">{{ navOption.label }}</span>
    </div>
  </ng-container>

  <mat-divider class="!mt-3 pb-3"></mat-divider>

  <div class="ml-4 flex flex-col" *ngIf="false">
    <!-- <div class="nav-item text-title-2">
      <span class="material-icons text-black60 mr-6"> settings </span>
      Settings
    </div> -->
    <div class="nav-item text-title-2" (click)="helpCenter()">
      <span class="material-icons text-black60 mr-6"> help_outline </span>
      Get Help
    </div>
  </div>
</div>
