import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowcaseDetailsRoutingModule } from './showcase-details.routing.module';
import { ComponentsModule } from '../common/components/components.module';
import { ShowcaseDetailsSummaryComponent } from './showcase-details-summary/showcase-details-summary.component';
import { ShowcaseDetailsAssortmentsComponent } from './showcase-details-assortments/showcase-details-assortments.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShowcaseDetailsComponent } from './showcase-details.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ShowcasePresentationComponent } from './showcase-presentation/showcase-presentation.component';
import { ShowcasePresentationService } from './showcase-presentation/showcase-presentation.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { ShowcasePresentationBreadcrumbComponent } from './showcase-presentation/showcase-presentation-breadcrumb/showcase-presentation-breadcrumb.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { ComposerModule } from '../presentation/composer/composer.module';
import { ShowcaseConfigurationComponent } from './showcase-configuration/showcase-configuration.component';
import { MatBadgeModule } from '@angular/material/badge';
import { AssortmentHistoryModule } from '../common/assortment-history/assortment-history.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { PipesModule } from '@pipes/pipes.module';
import { NoConnectionOverlayComponent } from '@common/web-socket/no-connection-overlay.component';
import { CanDeactivateGuard } from '@common/can-deactivate/can-deactivate.guard';

@NgModule({
  declarations: [
    ShowcaseDetailsSummaryComponent,
    ShowcaseDetailsAssortmentsComponent,
    ShowcaseDetailsComponent,
    ShowcasePresentationComponent,
    ShowcasePresentationBreadcrumbComponent,
    ShowcaseConfigurationComponent,
  ],
  imports: [
    CommonModule,
    ShowcaseDetailsRoutingModule,
    FormsModule,
    ComponentsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    DragDropModule,
    MatSlideToggleModule,
    MatCardModule,
    MatIconModule,
    MatBadgeModule,
    ComposerModule,
    AssortmentHistoryModule,
    MatTooltipModule,
    PipesModule,
    NoConnectionOverlayComponent,
  ],
  exports: [ShowcaseConfigurationComponent],
  providers: [ShowcasePresentationService, CanDeactivateGuard],
})
export class ShowcaseDetailsModule {}
