import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { PresentationFrame } from '../../presentation';

@Component({
  selector: 'app-composer-frame-remote-sessions',
  template: `
    <app-user-session-context-avatars
      *ngIf="contextReference"
      [contextReference]="contextReference"
      [limit]="limit"
    ></app-user-session-context-avatars>
  `,
  styles: [
    `
      :host {
        @apply absolute left-0.5 bottom-0.5;
      }
    `,
  ],
})
export class ComposerFrameRemoteSessions implements OnInit {
  @Input() frame: PresentationFrame;
  public contextReference: string;
  public limit = 5;

  constructor(private store: Store<RootStoreState.State>) {}

  ngOnInit(): void {
    if (this.frame) {
      this.contextReference = `presentation-frame:${this.frame.id}`;
    }
  }
}
