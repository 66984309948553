import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ItemData } from 'src/app/common/item-data/item-data';
import { ItemsService } from '../items.service';
@Component({
  selector: 'app-item-image-viewer',
  templateUrl: './item-image-viewer.component.html',
  styleUrls: ['./item-image-viewer.component.scss'],
})
export class ItemImageViewerComponent implements OnInit, OnChanges {
  @Input() itemData: ItemData;
  @Input() itemColor: any;

  public viewables: Array<any>;

  constructor(private itemsService: ItemsService) {}

  ngOnInit(): void {
    this.setViewables();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.itemData?.currentValue !== this.itemData || changes?.itemColor?.currentValue !== this.itemColor) {
      this.setViewables();
    }
  }
  getItemColor() {
    return this.itemColor;
  }
  async setViewables() {
    this.viewables = null;
    let itemId;
    if (!this.itemData && !this.itemColor) {
      return;
    }
    const contextualItem = this.itemColor || this.itemData;
    itemId = contextualItem.id;
    this.viewables = [];

    const itemContent: any =
      this.itemColor?.content || this.itemData?.content || (await this.getItemColorContent(itemId));
    if (this.itemColor) {
      this.itemColor.content = itemContent;
    } else if (this.itemData) {
      this.itemData.content = itemContent;
    }

    this.viewables = [];
    itemContent.sort((c1, c2) => {
      return new Date(c1.createdOn).getTime() - new Date(c2.createdOn).getTime();
    });
    for (const contentObj of itemContent) {
      const is3d = contentObj?.primaryFile?.contentType?.indexOf('gltf-binary') > -1;
      if (is3d) {
        this.viewables.push({
          type: 'glb',
          content: contentObj,
        });
      } else {
        const isPrimary = contentObj.id === contextualItem.item?.primaryViewable?.id;

        this.viewables.push({
          type: 'image',
          primary: isPrimary,
          location:
            // obj.mediumViewable?.downloadUrl ||
            contentObj.primaryFile.downloadUrl,
          thumbnailLocation: this.getThumbnailFromContent(contentObj),
        });
      }
    }
    const modelUrl = this.itemColor ? this.itemColor.properties['3Durl'] : this.itemData.properties['3Durl'];
    if (modelUrl) {
      this.viewables.push({
        location: modelUrl,
        type: '3D',
      });
    }
    this.viewables.sort((v1, v2) => (v1.primary ? -1 : 1));
  }
  getThumbnailFromContent(viewable) {
    let thumb;
    if (viewable) {
      thumb =
        // viewable.smallViewable?.downloadUrl ||
        viewable.mediumViewable?.downloadUrl || viewable.primaryFile?.downloadUrl;
    }
    return thumb;
  }

  getItemColorContent(itemId: any) {
    return this.itemsService.getItemContent(itemId);
  }
}
