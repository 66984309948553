import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Entities, EntityReference } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { from, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { WorkspacesActions, WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { ObjectUtil } from '@contrail/util';
import { WorkspaceEntitiesHelperService } from '@common/workspaces/workspace-entities-helper.service';
import { WorkspaceEntity } from '@common/workspaces/workspaces-store/workspaces.state';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ChooserSourceOption } from '../chooser-source/source-option';

@Component({
  selector: 'app-chooser-data-source-selector',
  templateUrl: './chooser-data-source-selector.component.html',
  styleUrls: ['./chooser-data-source-selector.component.scss'],
})
export class ChooserDataSourceSelectorComponent implements OnInit, OnDestroy, OnChanges {
  private destroy$ = new Subject();

  public transformedEntities$: Observable<Array<any>>;
  public loading$: Observable<boolean>;

  @Input() entityType: string; // color | asset;
  @Output() cancel = new EventEmitter();
  @Output() selectSource: EventEmitter<ChooserSourceOption> = new EventEmitter(null);
  //  public assortmentFormControl = new UntypedFormControl({ value: null, disabled: true }, Validators.required);
  public sourceWorkspaceControl = new UntypedFormControl({ value: null, disabled: false }, Validators.required);
  //  public sourceAssortments$: Observable<Array<any>>;
  public sourceWorkspaces$: Observable<Array<any>>;
  public sourceWorkspaces: any[];
  public selectedWorkspace: any;
  public selectedSource: ChooserSourceOption;
  public step = 'OPTION_TYPE';
  public optionTypesList: ChooserSourceOption[];

  constructor(
    private store: Store<RootStoreState.State>,
    private workspaceEntitiesHelper: WorkspaceEntitiesHelperService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.sourceWorkspaces$ = this.store.select(WorkspacesSelectors.workspaces);
    this.sourceWorkspaces$.subscribe((sourceWorkspaces) => (this.sourceWorkspaces = sourceWorkspaces));
    this.store
      .select(WorkspacesSelectors.currentWorkspace)
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentWorkspace) => {
        if (this.sourceWorkspaces) {
          const workspace = this.sourceWorkspaces.find((sourceWorkspace) => currentWorkspace.id === sourceWorkspace.id);
          this.sourceWorkspaceControl.setValue(workspace);
          //this.initSourceAssortments(workspace.id);
          //this.assortmentFormControl.enable();
        }
      });
    this.initOptionsTypeList();
    this.loading$ = this.store.select(WorkspacesSelectors.currentWorkspaceFolderLoading);
  }

  ngOnChanges() {
    this.initOptionsTypeList();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  setIcon(entity) {
    this.workspaceEntitiesHelper.setIcon(entity);
  }
  private initOptionsTypeList() {
    this.optionTypesList = [
      {
        name: this.entityType,
        sourceType: 'LIBRARY',
        icon: `assets/images/${this.entityType}.svg`,
        description: `Import any ${this.entityType}s from the entire library`,
      },
      {
        name: 'Browse Documents',
        sourceType: 'DOCUMENT',
        icon: 'assets/images/folder_icon.svg',
        description: `Import ${this.entityType}s from any documents you have access to`,
      },
    ];
  }
  handleCancel() {
    if (this.step === 'DOCUMENT_SELECT') {
      this.step = 'WORKSPACE_SELECT';
      return;
    }
    if (this.step === 'WORKSPACE_SELECT') {
      this.step = 'OPTION_TYPE';
      return;
    }
    this.cancel.emit();
  }

  selectSourceOptionType(optionType) {
    if (optionType.sourceType === 'LIBRARY') {
      // this.step = 'LIBRARY_FOLDER';
      this.selectSource.emit({
        sourceType: 'LIBRARY',
        name: this.entityType.toUpperCase(),
        icon: `assets/images/${this.entityType}.svg`,
      });
    } else if (optionType.sourceType === 'DOCUMENT') {
      this.step = 'WORKSPACE_SELECT';
    }
  }

  handleWorkspaceChange(workspace) {}

  selectWorkspace() {
    this.selectedWorkspace = this.sourceWorkspaceControl.value;
    this.store.dispatch(WorkspacesActions.setCurrentWorkspaceFolder({ workspace: this.selectedWorkspace }));
    this.workspaceEntitiesHelper.rootWorkspace = this.selectedWorkspace;
    this.step = 'DOCUMENT_SELECT';

    this.transformedEntities$ = this.store.select(WorkspacesSelectors.workspaceFolderEntities).pipe(
      map((entities: any[]) => {
        const transformed = [];
        entities.forEach((e) => {
          const listItem: any = ObjectUtil.cloneDeep(e);
          const ref = new EntityReference(listItem.entityReference);
          this.setIcon(listItem);
          if (['board', 'workspace'].includes(ref.entityType)) {
            // Limit to folders/boards curently...
            transformed.push(listItem);
          }
        });
        transformed.sort((ent1, ent2) => {
          if (this.isFolder(ent1) && !this.isFolder(ent2)) {
            return -1;
          }
          if (this.isFolder(ent1) && this.isFolder(ent2)) {
            return ent1.name < ent2.name ? -1 : 1;
          }
          return ent2.name < ent1.name ? 1 : -1;
        });
        return transformed;
      }),
    );
  }

  private isFolder(workspaceEntity: WorkspaceEntity) {
    return workspaceEntity?.entityReference?.indexOf('workspace') > -1;
  }

  async selectDocumentEntity(entity) {
    let entityReference: string;

    if (entity?.entity?.workspaceType === 'FOLDER') {
      this.workspaceEntitiesHelper.goToEntity(entity);
      return;
    }
    const ref = new EntityReference(entity.entityReference);
    entityReference = ref.reference;
    this.selectedSource = {
      workspaceId: entity.workspaceId,
      sourceType: 'DOCUMENT',
      entityReference,
      name: entity.name,
      icon: entity.icon,
    };
    this.selectSource.emit(this.selectedSource);
  }

  handleAssortmentChange() {
    // const assortment = this.assortmentFormControl.value;
    // console.log('handleAssortmentChange: ', assortment);
    // this.selectAssortment(assortment);
  }
  selectAssortment(assortment) {
    // this.selectedSource = {
    //   workspaceId: assortment.workspaceId,
    //   sourceType: 'ASSORTMENT',
    //   name: assortment.name,
    //   entityReference: 'assortment:' + assortment.id,
    //   id: assortment.id,
    //   icon: 'assets/images/assortment_icon.svg'
    // };
  }

  /** Finish the selection. */
  handleSelectSource() {
    if (!this.selectedSource) {
      return;
    }
    this.selectSource.emit(this.selectedSource);
  }
}
