import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommentsSelectors } from 'src/app/common/comments/comments-store';
import { Comment, CommentsService } from 'src/app/common/comments/comments.service';
import { PresentationFrame } from '../../../presentation';
import { PinnedComment } from '../composer-pinned-comments.component';
import { AddPinnedCommentsService } from '../add-pinned-comments-service';
import { Showcase } from 'src/app/showcases/showcases-store/showcases.state';
import { ShowcasesSelectors } from 'src/app/showcases/showcases-store';

export const FRAME_PINNED_COMMENT_ID_SUFFIX = 'frame-pinned-comment';

@Component({
  selector: 'app-composer-frame-pinned-comments',
  templateUrl: './composer-frame-pinned-comments.component.html',
  styleUrls: ['./composer-frame-pinned-comments.component.scss'],
})
export class ComposerFramePinnedComments implements OnInit {
  @Input() frame: PresentationFrame;

  private subscription: Subscription = new Subscription();
  private showcase: Showcase;
  public pinnedComment$: Observable<PinnedComment>;
  public elementId: string;
  public showPinnedComments: boolean;

  constructor(
    private store: Store<RootStoreState.State>,
    private addPinnedCommentsService: AddPinnedCommentsService,
  ) {}

  ngOnInit(): void {
    this.elementId = `${FRAME_PINNED_COMMENT_ID_SUFFIX}-${this.frame.id}`;
    this.subscription.add(
      this.store.select(ShowcasesSelectors.currentShowcase).subscribe((showcase) => (this.showcase = showcase)),
    );
    this.pinnedComment$ = this.store.select(CommentsSelectors.selectContextComments).pipe(
      map((comments) => comments.filter((comment) => comment.status !== 'closed' && this.isFrameComment(comment))),
      map((comments) => this.groupByDocumentElement(comments)[`presentation-frame:${this.frame.id}`]),
    );
    this.subscription.add(
      this.store.select(CommentsSelectors.showPinnedComments).subscribe((showPinnedComments) => {
        this.showPinnedComments = showPinnedComments;
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Frame comments is defined by:
   * subContextReference: 'presentation-frame:<frameId>'
   * ownedByReference: 'showcase:<showcaseId>' - important to check, it can be 'item'
   * documentElementId and documentPositions should be undefined
   * @param comment
   */
  private isFrameComment(comment: Comment) {
    return CommentsService.isEntityOwnedComment(
      comment,
      `showcase:${this.showcase.id}`,
      `presentation-frame:${this.frame.id}`,
    );
  }

  private groupByDocumentElement(comments: Array<Comment>) {
    return comments.reduce((counter, comment) => {
      const groupKey = comment.subContextReference;
      if (counter.hasOwnProperty(groupKey)) {
        counter[groupKey].count = counter[groupKey].count + 1;
      } else {
        counter[groupKey] = {
          groupKey,
          count: 1,
          firstComment: comment,
        };
      }
      return counter;
    }, {});
  }

  /**
   * Show comment overlay with comment bubble is clicked
   * @param comment
   */
  showComments(comment: Comment) {
    this.addPinnedCommentsService.addFramePreviewComment(this.frame.id);
  }
}
