import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyConfiguratorModule } from './property-configurator/property-configurator.module';
import { DocumentWidgetTrayComponent } from './document-widget-tray/document-widget-tray.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { PropertyConfiguratorBarComponent } from './property-configurator/property-configurator-bar/property-configurator-bar.component';
import { DocumentToolsComponent } from './document-tools/document-tools.component';
import { ComponentsModule } from 'src/app/common/components/components.module';
import { ItemDataChooserModule } from 'src/app/common/item-data-chooser/item-data-chooser.module';
import { PaintFormatComponent } from './document-paint-formatter/paint-formatter.component';
import { DocumentAnnotationToolComponent } from './document-annotation/document-annotation-tool/document-annotation-tool.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { AnnotationModule } from '@common/annotation/annotation.module';
import { PipesModule } from '@pipes/pipes.module';
import { AddHotSpotModalComponent } from './action-dispatchers/hot-spot/add-hot-spot-modal/add-hot-spot-modal.component';
import { CropImageElementComponent } from './action-dispatchers/crop-image-element/crop-image-element.component';
import { ImageCropperModule } from './action-dispatchers/crop-image-element/lib/image-cropper.module';
import { TextHyperlinkComponent } from './document-text/text-hyperlink/text-hyperlink.component';
import { AddIFrameElementModalComponent } from './document-iframe/add-iframe-element-modal/add-iframe-element-modal.component';
import { DocumentAddIframeComponent } from './document-iframe/document-add-iframe.component';
import { ViewIFrameElementModalComponent } from './document-iframe/view-iframe-element-modal/view-iframe-element-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { DocumentContentEditorModule } from './document-content-editor/document-content-editor.module';
import { DocumentContentEditorComponent } from './document-content-editor/document-content-editor-component/document-content-editor.component';
import { DocumentContentSvgEditorComponent } from './document-content-editor/document-content-svg-editor-component/document-content-svg-editor.component';
import { ContentModule } from '@common/content/content.module';

@NgModule({
  declarations: [
    DocumentWidgetTrayComponent,
    DocumentToolsComponent,
    AddHotSpotModalComponent,
    PaintFormatComponent,
    DocumentAnnotationToolComponent,
    CropImageElementComponent,
    TextHyperlinkComponent,
    DocumentAddIframeComponent,
    AddIFrameElementModalComponent,
    ViewIFrameElementModalComponent,
  ],
  imports: [
    CommonModule,
    PropertyConfiguratorModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    ComponentsModule,
    ItemDataChooserModule,
    MatTooltipModule,
    AnnotationModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    ImageCropperModule,
    DocumentContentEditorModule,
  ],
  exports: [
    DocumentWidgetTrayComponent,
    PropertyConfiguratorBarComponent,
    AddHotSpotModalComponent,
    PaintFormatComponent,
    DocumentAnnotationToolComponent,
    CropImageElementComponent,
    TextHyperlinkComponent,
    DocumentAddIframeComponent,
    DocumentContentEditorComponent,
    DocumentContentSvgEditorComponent,
    ContentModule,
  ],
})
export class DocumentModule {}
