import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { DocumentAnnotationService } from 'src/app/presentation/document/document-annotation/document-annotation-service';
import { State } from 'src/app/root-store/root-state';
import { ShowcasesActions, ShowcasesSelectors } from 'src/app/showcases/showcases-store';

@Component({
  selector: 'app-composer-toolbar-annotation',
  template: `<app-annotation-tool
    [annotationOptions]="annotationMenus"
    [annotationType]="annotationType"
    (annotationTypeChange)="setInteractionMode($event)"
  ></app-annotation-tool>`,
})
export class ComposerToolbarAnnotationComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger, { static: false }) menu: MatMenuTrigger;

  annotationMenus;
  annotationType;
  annotationTypeSub: Subscription;
  constructor(
    private documentAnnotationService: DocumentAnnotationService,
    private store: Store<State>,
  ) {
    this.annotationTypeSub = this.store.select(ShowcasesSelectors.annotationType).subscribe((annotationType) => {
      this.annotationType = annotationType;
    });
  }

  ngOnInit(): void {
    this.annotationMenus = this.documentAnnotationService.getAnnotationOptions();
  }

  ngOnDestroy(): void {
    this.annotationTypeSub.unsubscribe();
  }

  setInteractionMode(annotationType) {
    this.store.dispatch(ShowcasesActions.setAnnotationType({ annotationType }));
  }
}
