import { Component, EventEmitter, Input, OnInit, Output, Type, ViewChild } from '@angular/core';
import { CommentOwnerInfo } from 'src/app/common/comments/comments.service';
import { ItemData } from 'src/app/common/item-data/item-data';
import { DataSelectionToggleDirective } from '../data-selection-toggle/data-selection-toggle.directive';
import { DataSelectionToggleComponent } from '../data-selection-toggle/data-selection-toggle.component';

@Component({
  selector: 'app-item-color',
  templateUrl: './item-color.component.html',
  styleUrls: ['./item-color.component.scss'],
})
export class ItemColorComponent implements OnInit {
  @ViewChild(DataSelectionToggleDirective, { static: true }) dataSelectionToggle!: DataSelectionToggleDirective;
  @Input() itemColor: ItemData;
  @Input() image: any;
  @Input() dataSelectionToggleComponent: Type<any>;
  @Input() metaViewDefinition: any;
  @Output() itemColorFocusEvent = new EventEmitter();
  @Input() includeCommentWidget = false;
  @Input() itemMissingFromSource = false;

  ngOnInit(): void {
    this.loadComponent();
  }

  loadComponent() {
    if (this.dataSelectionToggleComponent) {
      const viewContainerRef = this.dataSelectionToggle.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent<DataSelectionToggleComponent>(
        this.dataSelectionToggleComponent,
      );
      console.log(componentRef);
      componentRef.instance.itemData = this.itemColor;
    }
  }

  focusItemColor($event) {
    $event.stopPropagation();
    this.itemColorFocusEvent.emit(this.itemColor);
  }

  getCommentOwnerInfo(): CommentOwnerInfo {
    return {
      id: this.itemColor.id,
      name: this.itemColor?.getName(),
      entityType: 'item',
      modalTitle: this.itemColor?.getName(),
    };
  }
}
