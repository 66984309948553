import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

// https://material.angular.io/components/datepicker/overview#choosing-a-date-implementation-and-date-format-settings
import * as moment from 'moment';
import { ShowcaseAnalyticsDashboardService } from '../showcase-analytics-dashboard/showcase-analytics-dashboard.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class DatePickerComponent implements OnInit {
  @Output() startDateChange: EventEmitter<string> = new EventEmitter();
  @Output() endDateChange: EventEmitter<string> = new EventEmitter();
  startDateFormControl: UntypedFormControl;
  endDateFormControl: UntypedFormControl;
  datePickerForm: UntypedFormGroup;

  constructor(private dashboardService: ShowcaseAnalyticsDashboardService) {}
  ngOnInit(): void {
    const config = this.dashboardService.getDashboardConfig();
    this.startDateFormControl = new UntypedFormControl(moment(config.startDate));
    this.endDateFormControl = new UntypedFormControl(moment(config.endDate));
    this.startDateFormControl.valueChanges.subscribe((val) => {
      if (this.datePickerForm.valid && val) {
        this.dashboardService.updateDashboardConfig({ startDate: val.format('YYYY-MM-DD') });
      }
    });
    this.endDateFormControl.valueChanges.subscribe((val) => {
      if (this.datePickerForm.valid && val) {
        this.dashboardService.updateDashboardConfig({ endDate: val.format('YYYY-MM-DD') });
      }
    });
    this.datePickerForm = new UntypedFormGroup({
      startDate: this.startDateFormControl,
      endDate: this.endDateFormControl,
    });
  }

  areDatesEqual(moment1: moment.Moment, moment2: moment.Moment) {
    return moment1.format('YYYY-MM-DD') === moment2.format('YYYY-MM-DD');
  }
}
