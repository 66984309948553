import { Component, OnInit } from '@angular/core';
import { ShowcasePresentationConfiguration } from './presentation-configuration';
import { ShowcasePresentationService } from './showcase-presentation.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

interface BreadCrumbItem {
  label: string;
  optionType: OptionType;
}
enum OptionType {
  OUTLINE,
  ITEMS,
  ITEMCOLORS,
}

@Component({
  selector: 'app-showcase-presentation',
  templateUrl: './showcase-presentation.component.html',
  styleUrls: ['./showcase-presentation.component.scss'],
})
export class ShowcasePresentationComponent implements OnInit {
  public presentationConfg: ShowcasePresentationConfiguration;
  public optionList;
  public selectedOptionType: OptionType;
  public leafNode = false;
  public breadCrumb: Array<BreadCrumbItem>;
  public selectedOutlineOption;
  public selectedItemOption;
  public productsOnOutlinePage: OptionType;
  public colorwaysOnProductsPage: OptionType;

  // Vibe 180 : Loading Widget for 'Presentation' Configuration Screen (Showcase Manager)
  public isLoading = true;

  constructor(private showcasePresentationService: ShowcasePresentationService) {}

  async ngOnInit() {
    this.presentationConfg = await this.showcasePresentationService.initialize();
    this.isLoading = false;
    if (this.presentationConfg) {
      this.optionList = this.presentationConfg.outline;
      this.selectedOptionType = OptionType.OUTLINE;
      this.setBreadCrumb();
      this.productsOnOutlinePage = OptionType.OUTLINE;
      this.colorwaysOnProductsPage = OptionType.ITEMS;
    }
  }

  expandOption(option) {
    switch (this.selectedOptionType) {
      case OptionType.OUTLINE: {
        this.selectedOutlineOption = option;
        this.optionList = option.children;
        this.selectedOptionType = OptionType.ITEMS;
        break;
      }
      case OptionType.ITEMS: {
        this.selectedItemOption = option;
        this.optionList = option.children;
        this.selectedOptionType = OptionType.ITEMCOLORS;
        this.leafNode = true;
      }
    }
    this.setBreadCrumb();
  }
  goToLevel(optionType: OptionType) {
    switch (optionType) {
      case OptionType.OUTLINE: {
        this.optionList = this.presentationConfg.outline;
        this.selectedItemOption = null;
        this.selectedOutlineOption = null;
        this.selectedOptionType = OptionType.OUTLINE;
        this.leafNode = false;
        break;
      }
      case OptionType.ITEMS: {
        this.optionList = this.selectedOutlineOption.children;
        this.leafNode = false;
        this.selectedItemOption = null;
        this.selectedOptionType = OptionType.ITEMS;
      }
    }
    this.setBreadCrumb();
  }

  setBreadCrumb() {
    console.log('setBreadCrumb');
    this.breadCrumb = [{ label: 'Collections', optionType: OptionType.OUTLINE }];
    if (this.selectedOutlineOption) {
      this.breadCrumb.push({ label: this.selectedOutlineOption.label, optionType: OptionType.ITEMS });
    }
    if (this.selectedItemOption) {
      this.breadCrumb.push({ label: this.selectedItemOption.label, optionType: OptionType.ITEMCOLORS });
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.optionList, event.previousIndex, event.currentIndex);
    this.showcasePresentationService.save();
  }

  toggleEnabled(obj) {
    obj.enabled = !obj.enabled;
    this.showcasePresentationService.save();
  }
}
