import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShowcaseDetailsComponent } from './showcase-details.component';
import { ShowcaseDetailsSummaryComponent } from './showcase-details-summary/showcase-details-summary.component';
import { ShowcaseDetailsAssortmentsComponent } from './showcase-details-assortments/showcase-details-assortments.component';
import { ShowcasePresentationComponent } from './showcase-presentation/showcase-presentation.component';
import { ComposerComponent } from '../presentation/composer/composer.component';
import { ShowcaseEditorModeResolver } from '../showcases/showcase-editor-mode-resolver';
import { CanDeactivateGuard } from '@common/can-deactivate/can-deactivate.guard';

const routes: Routes = [
  {
    path: ':id',
    resolve: { editorMode: ShowcaseEditorModeResolver },
    component: ShowcaseDetailsComponent,
    children: [
      { path: '', redirectTo: 'composer', pathMatch: 'full' },
      { path: 'summary', component: ShowcaseDetailsSummaryComponent },
      { path: 'assortments', component: ShowcaseDetailsAssortmentsComponent },
      { path: 'presentation', component: ShowcasePresentationComponent },
      {
        path: 'composer',
        component: ComposerComponent,
        runGuardsAndResolvers: 'always',
        canDeactivate: [CanDeactivateGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ShowcaseDetailsRoutingModule {}
