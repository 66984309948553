import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ContentService } from '@common/content/content.service';
import { WebglViewerComponent } from '@common/webgl/webgl-viewer/webgl-viewer.component';

@Component({
  selector: 'app-content-modal-editor',
  templateUrl: './content-modal-editor.component.html',
  styleUrls: ['./content-modal-editor.component.scss'],
})
export class ContentModalEditorComponent implements OnInit {
  @Input() content;
  @ViewChild(WebglViewerComponent) webglView: WebglViewerComponent;

  sideNavOptions = [
    { label: 'full', svgIcon: 'pointer_icon' },
    { label: 'config', svgIcon: 'showroom_icon' },
    { label: 'image', icon: 'image' },
    { label: 'pattern', icon: 'texture' },
    { label: 'recolor', icon: 'format_color_fill' },
  ];
  currentSideNav = 'full';

  presetColors = [
    'rgba(0,0,0,0)',
    '#00B0FF',
    '#00C853',
    '#FFC000',
    '#BB6BD9',
    '#FF0000',
    '#FFA500',
    '#FFFF00',
    '#009900',
    '#0000FF',
    '#FFFFFF',
    '#BDBDBD',
    '#616161',
    '#424242',
    '#000000',
  ];
  color = 'rgba(0,0,0,1)';

  get configNavTitle() {
    switch (this.currentSideNav) {
      case 'config':
        return 'Showroom Configuration';
      case 'image':
        return 'Image Overlay';
      case 'pattern':
        return 'Pattern Overlay';
      case 'recolor':
        return 'Recolor';
    }
  }

  constructor(
    private sanitizer: DomSanitizer,
    private contentService: ContentService,
  ) {}

  ngOnInit(): void {}

  colorChange(evt) {
    // console.log('color picker', evt)
    this.color = evt;
    this.webglView.recolorAsset(this.color);
  }

  async generateColorVariant() {
    const blob = await this.webglView.takeScreen();

    const glbName = this.content.primaryFile.fileName?.split('.')[0];
    const hex = this.color.split('#')?.pop();
    const fileName = `${glbName}-${hex}.png`;
    const response = await fetch(blob);
    const blobFile = await response.blob();
    const file = new File([blobFile], fileName, { type: 'image/png' });
    this.contentService.contentColorVariant$.next(file);
    return;
  }
}
