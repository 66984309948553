import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-share-showcase-modal',
  template: `<app-modal [title]="'Share Showcase'" [dialogRef]="dialogRef">
    <app-share-showcase [showcase]="showcase" (close)="closeModal()"></app-share-showcase>
  </app-modal>`,
  styleUrls: [],
})
export class ShareShowcaseModalComponent {
  public showcase;
  constructor(
    public dialogRef: MatDialogRef<ShareShowcaseModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.showcase = data.showcase;
  }

  closeModal() {
    this.dialogRef.close();
  }
}
