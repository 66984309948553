import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

export interface PageHeaderConfig {
  title: string;
  navOptions?: any;
}

@Injectable({
  providedIn: 'root',
})
export class PageHeaderService {
  private pageHeaderConfigSubject: Subject<PageHeaderConfig> = new BehaviorSubject({ title: '' });
  public pageHeaderConfig: Observable<PageHeaderConfig> = this.pageHeaderConfigSubject.asObservable();

  constructor(private store: Store<RootStoreState.State>) {}

  setPageConfig(config: PageHeaderConfig) {
    // console.log("Setting page config: ", config);
    this.pageHeaderConfigSubject.next(config);
  }
}
