import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Entities } from '@contrail/sdk';
import { ContentService } from '../content.service';

@Component({
  selector: 'app-content-modal',
  template: `
    <div class="flex flex-col w-full h-full" *ngIf="content">
      <app-content-modal-header
        [contentType]="contentType"
        [fileName]="content?.fileName"
        (cancelView)="cancel()"
        (navigate)="setNavigation($event)"
      ></app-content-modal-header>

      <div class="body w-full" style="height: calc(100% - 60px);">
        <ng-container [ngSwitch]="currentNav">
          <ng-container *ngSwitchCase="'DETAILS'">
            <app-content-modal-details [contentType]="contentType" [content]="content"></app-content-modal-details>
          </ng-container>
          <ng-container *ngSwitchCase="'EDITOR'">
            <app-content-modal-editor [content]="content"></app-content-modal-editor>
          </ng-container>
          <ng-container *ngSwitchCase="'COMMENTS'">
            <app-content-modal-comments></app-content-modal-comments>
          </ng-container>
          <ng-container *ngSwitchCase="'VERSIONS'">
            <app-content-modal-versions></app-content-modal-versions>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <app-content-preview [content]="content"></app-content-preview>
          </ng-container>
        </ng-container>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        @apply flex flex-col w-full h-full;
      }
      :host ::ng-deep {
        .mat-expansion-panel-header,
        .mat-expansion-panel-body {
          @apply px-3 pb-0;
        }
        .mat-expansion-panel-header {
          height: 36px !important;
        }
      }
    `,
  ],
})
export class ContentModalComponent implements OnInit {
  @Output() updated = new EventEmitter();
  public defaults: any;

  public contentId: string;
  public accessLevel = 'EDIT';
  public content;

  contentType: 'is3d' | 'isImage' | 'isFile';
  currentNav = 'DETAILS';

  constructor(
    public dialogRef: MatDialogRef<ContentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.contentId = data.contentId;
    this.accessLevel = data.accessLevel;
  }

  async ngOnInit() {
    if (this.data?.content) {
      this.content = this.data.content;
    } else {
      await this.loadContent();
    }

    if (this.content?.contentType.includes('gltf-binary')) {
      this.contentType = 'is3d';
    } else if (ContentService.isNonViewableImage(this.content?.fileName)) {
      this.contentType = 'isFile';
    } else if (this.content?.contentType.includes('image')) {
      this.contentType = 'isImage';
    } else {
      alert('contentType is missing !!!');
    }
  }

  private async loadContent() {
    this.content = await new Entities().get({ entityName: 'content', id: this.contentId, relations: ['primaryFile'] });
  }

  setNavigation(evt) {
    this.currentNav = evt;
  }

  complete(item) {
    this.dialogRef.close(item);
  }

  cancel() {
    this.dialogRef.close();
  }

  emitUpdate($event) {
    this.updated.emit($event);
  }
}
