import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Types } from '@contrail/sdk';
import { PropertyType, TypeProperty } from '@contrail/types';
import { ComposerService } from 'src/app/presentation/composer/composer.service';

@Component({
  selector: 'app-new-collection-frame-modal',
  templateUrl: './new-collection-frame-modal.component.html',
  styleUrls: ['./new-collection-frame-modal.component.scss'],
})
export class NewCollectionFrameModalComponent implements OnInit {
  public currentProperty: TypeProperty;
  public properties;

  public filter = {};
  constructor(
    public dialogRef: MatDialogRef<NewCollectionFrameModalComponent>,
    private composerService: ComposerService,
  ) {}

  async ngOnInit() {
    const type = await new Types().getType({ root: 'item', path: 'item', relations: ['typeProperties'] });
    this.properties = type.typeProperties.filter((prop) => prop.propertyType === PropertyType.SingleSelect);
  }

  closeModal() {
    this.dialogRef.close();
  }

  selectFilterProperty(event) {
    if (!event.source.selected) {
      return;
    }
    console.log('selectFilterProperty: ', event.source.value);
    this.currentProperty = event.source.value;
    this.filter = {};
  }

  selectFilterValue(event) {
    if (!event.source.selected) {
      return;
    }
    this.filter[this.currentProperty.slug] = [event.source.value.value];
  }

  getFilter() {
    return '' + JSON.stringify(this.filter);
  }

  submit() {
    if (this.filter && Object.keys(this.filter).length > 0) {
      const frame = {
        type: 'collection',
        collection: {
          set: [],
          type: 'item',
          filter: this.filter,
        },
      };
      this.composerService.addPresentationFrame(frame);
      this.closeModal();
    }
  }
}
