import { DocumentAction, DocumentChangeType, DocumentElement, DocumentElementFactory } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { DocumentService } from '../../document/document.service';
import { PresentationFrame } from '../../presentation';
import { ComposerService } from '../composer.service';
import { v4 as uuid } from 'uuid';
import { AuthService } from '@common/auth/auth.service';
import { ShowcaseBackingAssortmentService } from '../backing-assortment/showcase-backing-assortment-service';

export class ComposerClipboard {
  private copiedFrame;

  constructor(
    private composerService: ComposerService,
    private documentService: DocumentService,
    private backingAssortmentService: ShowcaseBackingAssortmentService,
    private authService: AuthService,
  ) {}

  public copyFrames() {
    const copiedFrames = this.composerService.getClipboardFrames();
    if (copiedFrames.length > 0) {
      this.copyToMachineClipboard(copiedFrames);
    }
  }

  public copyFrame(frame: PresentationFrame) {
    let copiedFrame = frame || this.composerService.getSelectedFrameObject();
    copiedFrame = ObjectUtil.cloneDeep(copiedFrame);
    delete copiedFrame.documentGenerationConfigId;
    if (copiedFrame?.type === 'document') {
      copiedFrame.document.elements.map((element) => {
        delete element.annotations;
      });
    }
    this.copiedFrame = copiedFrame;
    if (this.copiedFrame) {
      this.copyToMachineClipboard(this.copiedFrame);
    }
  }
  private copyToMachineClipboard(data: any) {
    const selectionText = this.documentService.documentClipboard.getSelectionText();

    // Do not override clipboard content if there is any text in clipboard - user might be trying to
    // copy simple text (for ex., item names from alerts)
    if (selectionText && selectionText != '') {
      return;
    }

    const mainLayout = document.querySelector('.main-theme');
    const copyTarget = document.createElement('input');
    copyTarget.setAttribute('type', 'text');
    copyTarget.value = JSON.stringify(data);
    mainLayout.appendChild(copyTarget);
    copyTarget.select();
    document.execCommand('copy');
    mainLayout.removeChild(copyTarget);
  }

  public getCopiedFrame() {
    return this.copiedFrame;
  }

  public setCopiedFrame(copiedFrame) {
    this.copiedFrame = copiedFrame;
  }

  public async pasteFrame() {
    const copiedFrame = this.getCopiedFrame();
    if (!copiedFrame) {
      return;
    }
    if (copiedFrame.type === 'document') {
      await this.pasteDocumentFrame(copiedFrame);
    } else if (copiedFrame.type === 'collection') {
      await this.pasteCollectionFrame(copiedFrame);
    } else if (copiedFrame.type === 'grid') {
      await this.pasteGridFrame(copiedFrame);
    } else if (copiedFrame.type === 'iframe') {
      await this.pasteEmbedFrame(copiedFrame);
    } else if (copiedFrame.type === 'showroom') {
      await this.pasteShowcaseFrame(copiedFrame);
    }
  }

  private async pasteEmbedFrame(copiedFrame: any) {
    let newFrame;
    const frameDetails: any = {
      type: 'iframe',
      url: copiedFrame.url,
    };
    newFrame = await this.composerService.addPresentationFrame(frameDetails);
    this.composerService.setSelectedFrame(newFrame);
    this.composerService.resetSelectedPlaceholderFrame();
  }

  private async pasteShowcaseFrame(copiedFrame: any) {
    let newFrame;
    const frameDetails: any = {
      type: 'showroom',
      showroomId: copiedFrame.showroomId,
    };
    newFrame = await this.composerService.addPresentationFrame(frameDetails);
    this.composerService.setSelectedFrame(newFrame);
    this.composerService.resetSelectedPlaceholderFrame();
  }

  private async pasteGridFrame(copiedFrame: any) {
    const selectedFrameObject: PresentationFrame = this.composerService.getSelectedFrameObject();
    let useExistingFrame = false;
    let newFrame;
    let frameDetails;
    if (selectedFrameObject?.type === 'grid' && selectedFrameObject?.collection.set.length === 0) {
      frameDetails = ObjectUtil.cloneDeep(selectedFrameObject);
      useExistingFrame = true;
    } else {
      frameDetails = {
        type: 'grid',
        collection: {
          set: [],
          type: 'item',
          filter: {},
        },
      };
    }
    copiedFrame.collection.set.forEach((set) => {
      const copiedSet = ObjectUtil.mergeDeep({}, set);
      copiedSet.value = uuid();
      frameDetails.collection.set.push(copiedSet);
    });
    if (frameDetails.collection.set.length === 0) {
      frameDetails.collection.set.push({ label: '', value: uuid(), type: 'section', enabled: true, children: [] });
    }
    frameDetails.collection.filter = ObjectUtil.mergeDeep({}, copiedFrame.collection.filter);
    if (!useExistingFrame) {
      newFrame = await this.composerService.addPresentationFrame(frameDetails);
      this.composerService.setSelectedFrame(newFrame);
    } else {
      this.composerService.updateGridCollectionFrame(frameDetails);
    }
    this.composerService.resetSelectedPlaceholderFrame();
  }

  private async pasteCollectionFrame(copiedFrame: any) {
    const selectedFrameObject = this.composerService.getSelectedFrameObject();
    let useExistingFrame = false;
    let frameDetails;
    if (selectedFrameObject?.type === 'collection' && selectedFrameObject?.collection.set.length === 0) {
      frameDetails = ObjectUtil.cloneDeep(selectedFrameObject);
      useExistingFrame = true;
    } else {
      frameDetails = {
        type: 'collection',
        collection: {
          set: [],
          type: 'item',
          filter: {},
        },
      };
    }
    copiedFrame.collection.set.forEach((set) => {
      const copiedSet = ObjectUtil.mergeDeep({}, set);
      frameDetails.collection.set.push(copiedSet);
    });
    frameDetails.collection.filter = ObjectUtil.mergeDeep({}, copiedFrame.collection.filter);
    if (!useExistingFrame) {
      const newFrame = await this.composerService.addPresentationFrame(frameDetails);
      this.composerService.setSelectedFrame(newFrame);
    } else {
      this.composerService.updateGridCollectionFrame(frameDetails);
    }
    this.composerService.resetSelectedPlaceholderFrame();
  }

  public async pasteDocumentFrame(copiedFrame: any) {
    const selectedFrameObject = this.composerService.getSelectedFrameObject();
    let useExistingFrame = false;
    let newFrame;
    let frameDetails;
    const sourceCopiedMap: Map<string, string> = new Map();
    const newElements = copiedFrame.document.elements.map((element) => {
      const copiedItem = ObjectUtil.mergeDeep({}, element);
      delete copiedItem.id;
      const newElement = DocumentElementFactory.createElement(copiedItem.type, ObjectUtil.mergeDeep({}, copiedItem));
      sourceCopiedMap.set(element.id, newElement.id);
      return newElement;
    });
    newElements
      .filter((newElement) => newElement.type === 'group')
      .forEach((groupElement) => {
        groupElement.elementIds = groupElement.elementIds.map((elementId) => sourceCopiedMap.get(elementId));
      });
    newElements
      ?.filter((newElement) => this.documentService?.documentRenderer?.isMask(newElement))
      ?.forEach((maskElement) => {
        const newElementIds = maskElement.elementIds
          .map((elementId) => sourceCopiedMap.get(elementId))
          .filter((id) => !!id);
        maskElement.elementIds = newElementIds?.length > 0 ? newElementIds : null;
      });
    if (selectedFrameObject?.type === 'document' && selectedFrameObject?.document.elements.length === 0) {
      frameDetails = selectedFrameObject;
      this.addNewElements(newElements);
      useExistingFrame = true;
    } else {
      frameDetails = {
        type: copiedFrame.type,
        document: {
          size: {
            width: 1200,
            height: 675,
          },
          id: uuid(),
          elements: newElements,
        },
      };
    }

    if (copiedFrame.document.background) {
      const copiedBackground = [];
      copiedFrame.document.background.forEach((background) => {
        const copiedBackgroundDetails = ObjectUtil.cloneDeep(background);
        delete copiedBackgroundDetails.id;
        let backgroundElem = DocumentElementFactory.createElement(
          copiedBackgroundDetails.type,
          copiedBackgroundDetails,
        );
        copiedBackground.push(backgroundElem);
      });
      frameDetails.document = Object.assign(frameDetails.document, { background: copiedBackground });
    }
    if (!useExistingFrame) {
      newFrame = await this.composerService.addPresentationFrame(frameDetails);
    } else {
      newFrame = await this.composerService.updateFrame(frameDetails);
      this.composerService.setCurrentFrame(newFrame);
    }
    this.composerService.setSelectedFrame(newFrame);
    this.composerService.resetSelectedPlaceholderFrame();
    this.backingAssortmentService.syncBackingAssortment();
  }

  private addNewElements(elements: DocumentElement[]) {
    this.documentService.deselectAllElements();
    const actions = elements.map((element) => {
      return new DocumentAction(
        {
          elementId: element.id,
          changeType: DocumentChangeType.ADD_ELEMENT,
          elementData: element,
        },
        {
          elementId: element.id,
          changeType: DocumentChangeType.DELETE_ELEMENT,
          elementData: element,
        },
      );
    });

    this.documentService.handleDocumentActions(actions);
  }
}
