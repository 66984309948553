import { Injectable } from '@angular/core';

import { DocumentElement, PositionDefinition } from '@contrail/documents';
import { SVG_COMPONENT_PADDING_X, SVG_COMPONENT_PADDING_T } from '../../svg/constants';

// Exportable padding constants if needed
const VERTICAL_PADDING = 0;
const HORIZONTAL_PADDING = 5;
const ELEMENT_WIDTH_MARGIN = 15;
const ELEMENT_HEIGHT_MARGIN = 5;

interface LayoutCalculations {
  elementSize: { width: number; height: number };
  columns: number;
  rows: number;
  xSpaceBetween: number;
  ySpaceBetween: number;
  usableWidth: number;
  usableHeight: number;
}

@Injectable({
  providedIn: 'root', // Makes the service available throughout the app
})
export class DocumentComponentFramePositionHandlerService {
  calculateActualElementHeight(elements: any[]): number {
    let maxBottom = 0;
    for (const element of elements) {
      const bottom = (element.position?.y || 0) + element.size.height;
      if (bottom > maxBottom) {
        maxBottom = bottom;
      }
    }
    return maxBottom;
  }

  // Main function for multi-add layout calculations
  calculateMultiAddLayoutToDocumentFrame(
    components: any[],
    documentWidth: number,
    documentHeight: number,
    initialX: number,
    initialY: number,
  ): LayoutCalculations {
    // Calculate the usable area considering initial x and y
    const usableWidth = documentWidth;
    const usableHeight = documentHeight - 2 * initialY;

    const elementSize = {
      width: ELEMENT_WIDTH_MARGIN + components[0].elements[0].size.width,
      height: components[0].size?.height || this.calculateActualElementHeight(components[0].elements),
    };

    // Calculate how many columns can fit within the usable width
    const columns = Math.floor((usableWidth + HORIZONTAL_PADDING) / (elementSize.width + HORIZONTAL_PADDING));
    const availableHeight = usableHeight - VERTICAL_PADDING;

    // Calculate the maximum number of rows that can fit within the usable height
    const maxRows = Math.floor(availableHeight / elementSize.height);

    // Calculate the actual number of rows needed for all components
    const neededRows = Math.ceil(components.length / columns);

    // Use the smaller of maxRows and neededRows
    const rows = Math.min(maxRows, neededRows);

    const totalHeight = rows * elementSize.height;
    const totalAvailableSpace = availableHeight - totalHeight;
    const spacesCount = rows;
    const ySpaceBetween = totalAvailableSpace / spacesCount;

    return {
      elementSize,
      columns,
      rows,
      xSpaceBetween: HORIZONTAL_PADDING,
      ySpaceBetween,
      usableWidth,
      usableHeight,
    };
  }

  getInitialPosition(
    frameId: string,
    lastComponentPositionByFrame: Record<string, PositionDefinition>,
  ): PositionDefinition {
    if (lastComponentPositionByFrame && lastComponentPositionByFrame[frameId]) {
      return { ...lastComponentPositionByFrame[frameId] };
    }
    return { x: SVG_COMPONENT_PADDING_X, y: SVG_COMPONENT_PADDING_T };
  }

  async calculateElementSize(element: DocumentElement): Promise<{ width: number; height: number }> {
    return {
      width: ELEMENT_WIDTH_MARGIN + element.elements[0].size.width,
      height: this.calculateActualElementHeight(element.elements),
    };
  }

  // Next two functions can be modified for more advanced placing logic
  // Determine if a new frame is needed based on position and element size to be added and space available
  isNewFrameNeeded(
    position: PositionDefinition,
    elementSize: { width: number; height: number },
    documentWidth: number,
    documentHeight: number,
  ): boolean {
    const nextY = position.y + elementSize.height;
    return nextY > documentHeight;
  }

  // Calculate the next position for an element when quick adding.
  calculateNextPosition(
    currentPosition: PositionDefinition,
    elementSize: { width: number; height: number },
    documentWidth: number,
  ): PositionDefinition {
    let nextX = currentPosition.x + elementSize.width + 5;
    let nextY = currentPosition.y;

    if (nextX + elementSize.width > documentWidth) {
      nextX = SVG_COMPONENT_PADDING_X;
      nextY += elementSize.height + VERTICAL_PADDING;
    }

    return { x: nextX, y: nextY };
  }
}
