<div id="data-chooser">
  <div class="data-pane">
    <app-item-data-chooser
      [showHeader]="true"
      [showFilter]="true"
      [draggable]="true"
      [resultsHeight]="resultsHeight"
      [existingItemIds]="existingItemIds$ | async"
      [hideSource]="hideSource"
      [chooserTitle]="title"
      [allowAddEntity]="allowAddEntity"
      [allowAddMultipleEntities]="allowAddMultipleEntities"
      [allowAddDuplicate]="allowAddDuplicate"
      [targetSourceType]="targetSourceType"
      [allowItemLevelSelection]="true"
      [enableInfiniteScroll]="true"
      [shouldOnlyPersistDocumentSources]="true"
      [shouldHydrateAddedItemsRelativeToSource]="true"
      (close)="hide()"
      (addEntity)="addItemData($event)"
      (addEntities)="addMultipleItemData($event)"
    ></app-item-data-chooser>
  </div>
</div>
