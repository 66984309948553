import { UserSession } from '../user-session';
export interface State {
  userSessions: UserSession[];
  currentSessionId: string;
  syncingRemoteUsers: boolean;
  remoteUserCurrentContext: any;
}
export const initialState: State = {
  userSessions: [],
  currentSessionId: null,
  syncingRemoteUsers: false,
  remoteUserCurrentContext: {},
};
