import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ComposerService } from '../../../composer.service';
import { PresentationCollectionElement, PresentationFrame } from '../../../../presentation';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { AssortmentsSelectors } from 'src/app/common/assortments/assortments-store';
import { ObjectUtil } from '@contrail/util';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ItemDetailsModalComponent } from '@common/items/item-details-modal/item-details-modal.component';
import { DocumentSelectors } from 'src/app/presentation/document/document-store';
import { ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { ViewManagerService } from '@common/views/view-manager.service';
import { ComposerGridFrameSectionComponent } from '../composer-grid-frame-section/composer-grid-frame-section.component';
import { PropertyValueFormatter } from '@contrail/types';
import { map, takeUntil } from 'rxjs/operators';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
import { VirtualScrollerComponent } from '@iharbeck/ngx-virtual-scroller';
import { ComposerItemService } from '../../../composer-item/composer-item.service';
import { GRID_VIEW_APPLICATION_SLUG } from '../../composer-frame-layout/composer-frame-layout.service';
import { ItemData } from '@common/item-data/item-data';
@Component({
  selector: 'app-composer-grid-frame-editor',
  templateUrl: './composer-grid-frame-editor.component.html',
  styleUrls: ['./composer-grid-frame-editor.component.scss'],
})
export class ComposerGridFrameEditorComponent implements OnInit, OnDestroy, OnChanges {
  private destroy$ = new Subject();

  public currentProperty;
  public collectionElements: Array<PresentationCollectionElement>;
  public viewDefinition;
  public editorMode$: Observable<EditorMode>;
  public backingAssortmentItems$: Observable<Array<ItemData>>;
  backingAssortmentItems: Array<any>;
  sourceAssortmentItems: Array<any>;
  private showSourceAssortmentWarning = true;
  optionPreviewClass = 'option';
  gridFrameActiveContainer$: Observable<PresentationCollectionElement>;
  sectionElements: PresentationCollectionElement[];
  countTotal = 0;
  annotationOptionMap: any;

  @Input() frame: PresentationFrame;
  @Input() annotationType;
  @Input() annotationOptions: any[];
  @Input() statusMessages: any[];
  @Input() searchResults: any[];
  @Input() activeSearchResultElement: any;

  itemMap: any = {};
  @ViewChild(VirtualScrollerComponent) private virtualScroller: VirtualScrollerComponent;
  @ViewChildren('section') sections: QueryList<ComposerGridFrameSectionComponent>;

  constructor(
    private composerService: ComposerService,
    private composerItemService: ComposerItemService,
    private store: Store<RootStoreState.State>,
    private matDialog: MatDialog,
    private viewService: ViewManagerService,
  ) {
    this.gridFrameActiveContainer$ = this.store.select(ShowcasesSelectors.gridFrameActiveContainer);
    this.backingAssortmentItems$ = this.store.select(AssortmentsSelectors.backingAssortmentItemData);
    combineLatest([
      this.backingAssortmentItems$,
      this.store.select(AssortmentsSelectors.sourceAssortmentItemData),
      this.store.select(ShowcasesSelectors.showSourceAssortmentWarning),
    ])
      .pipe(
        map(([backingAssortmentItems, sourceAssortmentItems, showSourceAssortmentWarning]) => {
          this.backingAssortmentItems = backingAssortmentItems;
          this.sourceAssortmentItems = sourceAssortmentItems;
          this.showSourceAssortmentWarning = showSourceAssortmentWarning;
          this.generateItemMap();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.editorMode$ = this.store.select(EditorModeSelectors.editorMode);
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.frame) {
      this.collectionElements = null;
      await this.initCollection(changes);
    }
    // this will scroll to the element in the search result
    if (changes.activeSearchResultElement?.currentValue) {
      if (this.sectionElements?.length > 0) {
        const index = this.sectionElements.findIndex((sectionElement) =>
          sectionElement.children
            .map((child) => child.value)
            .includes(changes.activeSearchResultElement?.currentValue?.id),
        );
        let offset = Math.floor(
          this.sectionElements[index].children.findIndex(
            (child) => child.value === changes.activeSearchResultElement?.currentValue?.id,
          ) / 10,
        );
        offset = offset * 150;
        this.virtualScroller.scrollToIndex(index, true, offset);
      }
    }
  }

  private async initCollection(changes) {
    if (changes.frame) {
      const contextReference = this.composerService.currentFrameContextReference;
      this.viewDefinition = await this.viewService.getView(GRID_VIEW_APPLICATION_SLUG, null, contextReference);
      this.collectionElements = ObjectUtil.cloneDeep(this.frame.collection.set);
      this.sectionElements = this.collectionElements.filter((element) => element.type === 'section');
      this.countTotal = this.sectionElements.reduce(
        (previousValue, sectionElement) => previousValue + sectionElement.children.length,
        0,
      );
      this.generateItemMap();
    }

    if (changes.annotationOptions && !this.annotationOptionMap) {
      this.annotationOptionMap = changes.annotationOptions.currentValue.reduce((map, option) => {
        map[option.type] = option;
        return map;
      }, {});
    }

    if (changes.statusMessages?.currentValue) {
      this.generateItemMap();
    }
  }

  private generateItemMap() {
    const formatter = new PropertyValueFormatter();
    this.sectionElements?.forEach((section) => {
      section.children.forEach((child) => {
        const backingAssortmentItem = this.backingAssortmentItems.find((itemData) => itemData.id === child.value);
        const sourceAssortmentItem = this.sourceAssortmentItems?.find((itemData) => itemData.id === child.value);
        const itemObj = backingAssortmentItem?.properties;
        if (itemObj) {
          const projectItemObj = backingAssortmentItem.projectItem;
          const keyValueMap = { annotations: [], statusMessage: null, roles: backingAssortmentItem?.item?.roles || [] };
          this.viewDefinition?.properties.forEach((property) => {
            if (property.typeRootSlug === 'item' && itemObj) {
              keyValueMap[property.slug] = formatter.getDisplayValue(itemObj, property.propertyDefinition);
            } else if (property.typeRootSlug === 'project-item' && projectItemObj) {
              keyValueMap[property.slug] = formatter.getDisplayValue(projectItemObj, property.propertyDefinition);
            } else if (property.typeRootSlug === 'assortment-item' && sourceAssortmentItem) {
              keyValueMap[property.slug] = formatter.getDisplayValue(
                sourceAssortmentItem.properties,
                property.propertyDefinition,
              );
            }
          });
          this.itemMap[itemObj.id] = keyValueMap;
          if (itemObj?.thumbnail) {
            // tslint:disable-next-line: no-string-literal
            keyValueMap['thumbnail'] = itemObj.thumbnail;
          }
          this.annotationOptions?.forEach((annotationOption) => {
            if (backingAssortmentItem.assortmentItem[annotationOption.property]) {
              keyValueMap.annotations.push(annotationOption.svgIcon);
            }
          });

          if (this.statusMessages?.length > 0 && this.showSourceAssortmentWarning) {
            const statusMessage = this.statusMessages.find((message) => message.collectionElementId === itemObj.itemId);
            keyValueMap.statusMessage = statusMessage;
          }
        }
      });
    });
  }

  public updateCollectionItem(itemData: any) {
    const undoChangeDefinition = ObjectUtil.cloneDeep(this.frame);
    const changedItemIndex = this.frame.collection.set.findIndex((item) => item.value === itemData.item.value);
    if (itemData.removedItemDataInfo) {
      const removedItemDataContainerIndex = this.frame.collection.set.findIndex(
        (item) => item.value === itemData.removedItemDataInfo.containerId,
      );
      const removedItemDataContainer = this.frame.collection.set[removedItemDataContainerIndex];
      const index = removedItemDataContainer.children.findIndex(
        (child) => child.value === itemData.removedItemDataInfo.item,
      );
      removedItemDataContainer.children.splice(index, 1);
      this.frame.collection.set.splice(
        removedItemDataContainerIndex,
        1,
        ObjectUtil.cloneDeep(removedItemDataContainer),
      );
    }
    if (itemData.delete) {
      this.frame.collection.set.splice(changedItemIndex, 1);
    } else {
      this.frame.collection.set.splice(changedItemIndex, 1, ObjectUtil.cloneDeep(itemData.item));
    }
    this.collectionElements = ObjectUtil.cloneDeep(this.frame.collection.set);
    this.composerService.handleFrameChanges(ObjectUtil.cloneDeep(this.frame), undoChangeDefinition);
  }

  public viewItemDetail(data: any) {
    const itemId = data.itemId;
    const container: PresentationCollectionElement = data.container;
    const accessLevel = 'EDIT';
    const config = {
      data: { itemId, accessLevel },
      panelClass: [`no-padding`, `item-details-modal`],
      maxWidth: '95vw',
      width: '1350px',
      height: '800px',
      autoFocus: true,
    };
    const dialogRef = this.matDialog.open(ItemDetailsModalComponent, config);
    const itemModalComponent: ItemDetailsModalComponent = dialogRef.componentInstance;
    itemModalComponent.updated.subscribe((updatedData) => {
      this.updateItemDetail(container, updatedData);
    });
  }

  private async updateItemDetail(section: PresentationCollectionElement, data: any) {
    if (data.changes.optionName || data.changes.name) {
      if (data.changes.optionName) {
        const updatedItem = section.children.find((child) => child.value === data.object.id);
        updatedItem.label = data.changes.optionName;
      }
      this.updateCollectionItem({ item: section });
    }
    this.composerItemService.syncElements(data);
  }

  public drop(event: CdkDragDrop<any>) {
    const undoChangeDefinition = ObjectUtil.cloneDeep(this.frame);
    const currentSectionIndex = this.frame.collection.set.findIndex((section) => section.value === event.item.data);
    const newSectionIndex = currentSectionIndex + (event.currentIndex - event.previousIndex);
    moveItemInArray(this.frame.collection.set, currentSectionIndex, newSectionIndex);
    this.composerService.handleFrameChanges(ObjectUtil.cloneDeep(this.frame), undoChangeDefinition);
  }

  private launchItemDetails(item) {
    const itemId = item.value;
    const accessLevel = 'EDIT';
    const config = {
      data: { itemId, accessLevel },
      panelClass: [`no-padding`, `item-details-modal`],
      maxWidth: '95vw',
      width: '1350px',
      height: '800px',
      autoFocus: true,
    };
    const dialogRef = this.matDialog.open(ItemDetailsModalComponent, config);
    const itemModalComponent: ItemDetailsModalComponent = dialogRef.componentInstance;
    itemModalComponent.updated.subscribe((result) => {});
  }
}
