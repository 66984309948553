import { DocumentService } from '../document/document.service';
import { Injectable } from '@angular/core';
import { ComposerService } from '../composer/composer.service';
import { Store } from '@ngrx/store';
import { State } from '../document/document-store/document.state';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { AuthService } from '@common/auth/auth.service';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';

const KEY_EVENT_IGNORE_PATHS = [
  'collection-frame-create-template-view',
  'app-comment-overlay',
  'app-comment-form',
  'app-create-grid-frame-section',
  'app-update-grid-frame-section',
  'app-item-data-chooser',
  'color-picker',
  'app-component-editor',
  'app-item-details',
  'app-new-grid-frame-modal',
  'app-search-bar',
  'app-add-iframe-element-modal',
];

const PASTEABLE_AS_FRAME = ['document', 'grid', 'collection', 'iframe', 'showroom'];
@Injectable({
  providedIn: 'root',
})
export class PasteHandler {
  private editorMode;
  private isShiftKey = false;

  constructor(
    private store: Store<State>,
    private documentService: DocumentService,
    private authService: AuthService,
    private composerService: ComposerService,
  ) {
    this.store.select(EditorModeSelectors.editorMode).subscribe((m) => (this.editorMode = m));
    this.init();
  }

  private init() {
    // Currently, the only place we handle a paste event (vs CTRL-V, etc)
    // Maybe we could copy this pattern for copy? is there an oncopy event?
    document.onpaste = this.handlePasteEvent.bind(this);

    // document.addEventListener('keydown', (event) => {
    //   if (event.code === 'KeyV' && event.shiftKey && (event.ctrlKey || event.metaKey)) {
    //     this.isShiftKey = true;
    //   }
    // });
    // document.addEventListener('keyup', (event) => {
    //   this.isShiftKey = false;
    // });
  }

  public async handlePasteEvent(pasteEvent) {
    if (this.editorMode !== EditorMode.EDIT) {
      return;
    }
    if (!this.isEventAllowed(pasteEvent)) {
      return;
    }
    /*
    if (!this.composerService.getSelectedFrameObject() && !this.composerService.getSelectedPlaceholderFrame()) {
      return;
    }*/

    //this.pasteFrames();
    // we need to rely on copying texts to the machine's clipboard for c/p across showcases
    this.pasteFromClipboardContent(pasteEvent);
  }

  isEventAllowed(event: any) {
    for (const el of event.composedPath()) {
      // CHECK IF ORIGINATING FROM A MODAL
      if (KEY_EVENT_IGNORE_PATHS.includes(el.tagName?.toLowerCase())) {
        return false;
      }
    }
    return true;
  }

  private pasteFrames() {
    const frames = this.composerService.getClipboardFrames();
    if (!frames) {
      return;
    }

    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));
    frames.forEach((frame) => {
      this.composerService.setCurrentFrame(frame);
      this.composerService.setSelectedFrame(frame);
      this.composerService.composerClipboard.copyFrame(frame);
      this.composerService.composerClipboard.pasteFrame();
    });
    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
  }

  private async pasteFromClipboardContent(pasteEvent) {
    const items = [...pasteEvent.clipboardData.items].map((item) => item);
    const promises = [];
    for (const dataTransferItem of items) {
      if (dataTransferItem.kind === 'string') {
        promises.push(
          new Promise((res) =>
            dataTransferItem.getAsString((data) => {
              res(data);
            }),
          ),
        );
      } else {
        console.log('Unknown DataTransferItem', dataTransferItem);
      }
    }
    const contentData = await Promise.all(promises);
    if (contentData.length > 0) {
      let copiedFrames;
      try {
        copiedFrames = JSON.parse(contentData[0]);
        if (!Array.isArray(copiedFrames) || !PASTEABLE_AS_FRAME.includes(copiedFrames[0].type)) {
          return;
        }
      } catch (e) {
        console.log('Not a json string');
      }
      if (!copiedFrames?.length) {
        return;
      }
      // this.composerService.pastingFrames = true;
      const currentOrg = this.authService.getCurrentOrg();
      for (let i = 0; i < copiedFrames.length; i++) {
        const copiedFrame = copiedFrames[i];
        if (PASTEABLE_AS_FRAME.includes(copiedFrame.type) && copiedFrame.orgId === currentOrg.orgId) {
          this.composerService.composerClipboard.setCopiedFrame(copiedFrame);
          await this.composerService.composerClipboard.pasteFrame();
        }
      }
    }
  }
}
