import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ShowcasesService } from 'src/app/showcases/showcases.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ShowcaseAssortmentsService } from './showcase-assortments.service';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { AuthService } from 'src/app/common/auth/auth.service';
import { EVENT_CATEGORY } from '@common/analytics/user-analytics.service';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-showcase-details-summary',
  templateUrl: './showcase-details-summary.component.html',
  styleUrls: ['./showcase-details-summary.component.scss'],
})
export class ShowcaseDetailsSummaryComponent implements OnInit, OnDestroy {
  showcase;
  private destroy$ = new Subject();

  public assortments;
  sourceAssortmentId;
  sourceAssortment: any;
  role: string;
  public selectedPreferredCurrency;
  currencies: string[] = ['NONE', 'USD', 'CAD', 'EUR', 'GBP', 'CHF'];
  form: UntypedFormGroup = new UntypedFormGroup({
    introTitle: new UntypedFormControl(''),
    introText: new UntypedFormControl(''),
    coverPhoto: new UntypedFormControl(''),
    headerLogo: new UntypedFormControl(''),
    isTemplate: new UntypedFormControl(''),
  });

  constructor(
    private store: Store<RootStoreState.State>,
    private showcasesService: ShowcasesService,
    private showcaseAssortmentsService: ShowcaseAssortmentsService,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
  ) {
    this.authService.authContext.subscribe((context) => {
      this.role = context.currentOrg.role;
    });
  }

  selectAsst(asstId) {
    this.sourceAssortmentId = asstId;
    this.save();
  }

  selectPreferredCurrency(event) {
    console.log('event: ', event);
    this.selectedPreferredCurrency = event.value;
    this.save();
  }

  ngOnInit(): void {
    this.analyticsService.emitEvent({
      eventName: 'VIEW_SHOWCASES_SUMMARY',
      eventCategory: EVENT_CATEGORY.VIEW,
    });
    this.store
      .select(ShowcasesSelectors.currentShowcase)
      .pipe(takeUntil(this.destroy$))
      .subscribe((showcase) => {
        this.showcase = showcase;
        this.sourceAssortmentId = this.showcase.sourceAssortmentId;
        this.form.controls.headerLogo.setValue(this.showcase.headerLogo);
        this.form.controls.introText.setValue(this.showcase.introText);
        this.form.controls.introTitle.setValue(this.showcase.introTitle);
        this.form.controls.coverPhoto.setValue(this.showcase.coverPhoto);
        this.selectedPreferredCurrency = this.showcase.preferredCurrency;
      });

    this.showcaseAssortmentsService.assortments.subscribe((assortments) => {
      this.assortments = assortments;
      this.sourceAssortment = this.assortments.find((element) => element.id === this.sourceAssortmentId);
    });
    this.showcasesService.storeShowcaseId(this.showcase.id);
    this.showcaseAssortmentsService.loadSourceAssortments();
  }

  save() {
    this.showcase.coverPhoto = this.form.controls.coverPhoto.value;
    this.showcase.headerLogo = this.form.controls.headerLogo.value;
    this.showcase.introTitle = this.form.controls.introTitle.value;
    this.showcase.introText = this.form.controls.introText.value;
    this.showcase.preferredCurrency = this.selectedPreferredCurrency;
    this.showcase.sourceAssortmentId = this.sourceAssortmentId;
    this.showcasesService.updateShowcase(this.showcase.id, this.showcase);
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
