<form [formGroup]="overlayForm" class="w-full">
  <mat-expansion-panel class="mat-elevation-z0" expanded>
    <mat-expansion-panel-header>
      <mat-panel-title> {{ type === 'image' ? 'Texture File' : 'Pattern File' }} </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="my-3">
      <!-- Do we upload texture/pattern file? -->
      <!-- <app-mat-spinner [overlay]="true" [loading]="false" [message]="'Uploading file'"></app-mat-spinner> -->
      <div class="upload-container" dragFile (dragAction)="handleFileSelection($event)" *ngIf="!overlayFile">
        <div class="drop-zone">
          <div class="flex flex-col justify-center items-center pt-5">
            <mat-icon class="upload-icon"> file_upload </mat-icon>
            <div class="message py-2">
              Drag files here
              <span class="underline cursor-pointer" (click)="fileUpload.click()">or select.</span>
            </div>
          </div>
        </div>
      </div>

      <div class="file-preview flex" *ngIf="overlayFile">
        <img [src]="overlayFile?.fileUrl" class="w-32 h-24 object-contain" />
        <div class="ml-4">
          <p>{{ overlayFile.name }}</p>
          <p>{{ overlayFile.size }}</p>

          <p class="mt-3 cursor-pointer text-primary font-medium" (click)="fileUpload.click()">Change file</p>
        </div>
        <p class="mt-1 ml-auto cursor-pointer text-primary font-medium" (click)="handleFileCleared()">Clear</p>
      </div>

      <input
        #fileUpload
        type="file"
        accept=".png"
        style="display: none"
        (click)="$event.target.value = null"
        (change)="handleFileSelection($event.target?.files)"
      />
    </div>
  </mat-expansion-panel>

  <mat-divider></mat-divider>
  <mat-expansion-panel class="mat-elevation-z0" expanded>
    <mat-expansion-panel-header>
      <mat-panel-title> Scale & Positioning </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="my-3">
      <mat-form-field appearance="outline" class="!w-24 !mr-4">
        <mat-label>Scale</mat-label>
        <input matInput type="number" formControlName="scale" step="0.01" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="!w-24">
        <mat-label>Rotate</mat-label>
        <input matInput type="number" formControlName="rotate" />
      </mat-form-field>
      <div *ngIf="type === 'pattern'">
        <p class="text-primary mb-1">Offset position</p>
        <mat-form-field appearance="outline" class="!w-24 mr-4">
          <mat-label>X</mat-label>
          <input matInput type="number" formControlName="offsetX" step="0.01" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="!w-24 mr-4">
          <mat-label>Y</mat-label>
          <input matInput type="number" formControlName="offsetY" step="0.01" />
        </mat-form-field>
      </div>
    </div>
  </mat-expansion-panel>
</form>

<div class="flex-center mb-4 mt-auto">
  <button mat-raised-button color="primary" class="!mx-auto !my-4 w-64" (click)="generateVariant()">
    GENERATE VARIANT
  </button>
</div>
