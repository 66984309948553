import { Component, OnInit } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { ShowcaseAnalyticsDashboardService } from '../showcase-analytics-dashboard/showcase-analytics-dashboard.service';

@Component({
  selector: 'app-user-access-report',
  templateUrl: './user-access-report.component.html',
  styleUrls: ['./user-access-report.component.scss'],
})
export class UserAccessReportComponent implements OnInit {
  constructor(private dashboardService: ShowcaseAnalyticsDashboardService) {}
  public loading = true;
  public users;
  async ngOnInit() {
    this.dashboardService.dashboardConfig.subscribe((config) => {
      this.loadData();
    });
  }

  async loadData() {
    this.loading = true;
    const config = this.dashboardService.getDashboardConfig();
    //this.users = await new Entities().get({ entityName: 'showcase-analytics-report', id: 'user-access-report', criteria: { startDate: config.startDate, endDate: config.endDate } });
    this.users = this.getRandomData();
    this.loading = false;
  }

  private getRandomData() {
    return [
      {
        image: 'https://lh3.googleusercontent.com/a-/AOh14Ggga7B8ajmgKjCBEUyGo8WSBBrwJXqjNwu1gcT2=s192-c-rg-br100',
        sessions: 56,
        showcases: 6,
        name: 'Brian Lindauer',
      },
      {
        image:
          'https://media-exp1.licdn.com/dms/image/C4D03AQHYxSrgwgPYbw/profile-displayphoto-shrink_800_800/0?e=1611187200&v=beta&t=iUxyOQvkFy_xrJWHGQopPFpX293aBaQQXZsAvuadMzw',
        sessions: 42,
        showcases: 4,
        name: 'Quac Pham',
      },
      {
        image:
          'https://media-exp1.licdn.com/dms/image/C4E03AQHJBMbchYXlcQ/profile-displayphoto-shrink_800_800/0?e=1611187200&v=beta&t=5JCOicm3FDFNMhV13u7l43vGAYrDwawkVuSXQhDMKxo',
        sessions: 23,
        showcases: 3,
        name: 'Kelly Price',
      },
    ];
  }
}
