<div class="add-frame-container">
  <button
    mat-icon-button
    data-test="composer-frame-tray-add-frame"
    matTooltip="Add frame"
    [matMenuTriggerFor]="newFrameMenu"
    class="justify-center"
  >
    <span class="material-icons" [ngClass]="largeIcon ? 'text-3xl ml-3' : 'text-2xl'"> {{ icon }} </span>
  </button>
</div>

<mat-menu #newFrameMenu="matMenu" yPosition="below" xPosition="before" class="menu-add-frame">
  <button data-test="composer-frame-tray-add-document" mat-menu-item (click)="addFrame('document')">
    <mat-icon class="icons" svgIcon="canvas"></mat-icon> <span>Canvas</span>
  </button>
  <button data-test="composer-frame-tray-add-collection" mat-menu-item (click)="addFrame('collection')">
    <mat-icon class="icons" svgIcon="collection-frame-dark"></mat-icon> <span>Collection</span>
  </button>
  <button data-test="composer-frame-tray-add-grid" mat-menu-item (click)="addFrame('grid')">
    <mat-icon class="icons">grid_on</mat-icon> <span>Grid</span>
  </button>
  <button data-test="composer-frame-tray-add-grid" mat-menu-item (click)="addFrame('showroom')" *ngIf="showShowroom">
    <mat-icon class="icons" svgIcon="showroom"></mat-icon> <span>Showroom</span>
  </button>
  <button data-test="composer-frame-tray-add-grid" mat-menu-item (click)="addFrame('iframe')">
    <mat-icon class="icons" svgIcon="iframe"></mat-icon> <span>Embed</span>
  </button>
  <button data-test="composer-frame-tray-upload-pdf" mat-menu-item (click)="addFrame('uploadPDF')">
    <mat-icon class="icons">upload</mat-icon> <span>Upload PDF</span>
  </button>
  <button [matMenuTriggerFor]="advancedMenu" data-test="composer-frame-tray-add-advanced" mat-menu-item>
    <mat-icon class="icons" svgIcon="magic"></mat-icon><span>Generate</span>
  </button>
  <button *ngIf="frameTemplates" [matMenuTriggerFor]="templates" data-test="composer-frame-templates" mat-menu-item>
    <mat-icon class="icons">dashboard</mat-icon> <span>Templates</span>
  </button>
</mat-menu>

<mat-menu #advancedMenu="matMenu" class="advanced-tool-menu-row">
  <ng-template matMenuContent>
    <button
      data-test="composer-frame-tray-add-advanced-generate-frames"
      mat-menu-item
      (click)="addFrame('document-generation')"
    >
      <mat-icon class="material-icons-outlined">space_dashboard</mat-icon>
      <span class="ml-[5px]">Lineboard</span>
    </button>
    <button mat-menu-item *ngFor="let appExtension of appExtensions" (click)="launchExtension(appExtension)">
      {{ appExtension.name }}
    </button>
  </ng-template>
</mat-menu>

<mat-menu #templates="matMenu" class="frame-templates-menu-row">
  <ng-template matMenuContent>
    <app-frame-template-chooser
      [templates]="frameTemplates"
      (templateSelected)="createFrameFromTemplate($event)"
    ></app-frame-template-chooser>
  </ng-template>
</mat-menu>
