import { Injectable } from '@angular/core';
import { ConfirmationBoxService } from '@components/confirmation-box/confirmation-box';
import { DocumentAction, DocumentChangeType, DocumentElement } from '@contrail/documents';
import { DocumentService } from '../../document.service';
import { GroupElementService } from '../group-element/group-element.service';
import { MaskElementService } from '../mask-element/mask-element.service';
@Injectable({
  providedIn: 'root',
})
export class DeleteDocumentElementService {
  constructor(
    private documentService: DocumentService,
    private groupElementService: GroupElementService,
    private confirmationBoxService: ConfirmationBoxService,
    private maskElementService: MaskElementService,
  ) {
    this.documentService.actionRequests.subscribe(async (request) => {
      if (request?.actionType === 'delete_element') {
        const selected = this.documentService.getSelectedExpandedElements();

        const deletableElements = selected.filter((e) => !e.isLocked);

        // Confirm large delete.
        if (deletableElements.length > 50) {
          const confirm = await this.confirmationBoxService.open(
            'Verify Delete',
            `Are you sure you want to delete ${deletableElements.length} elements?`,
            '',
            'DELETE',
            true,
            false,
          );
          if (confirm) {
            this.deleteElements(deletableElements);
          }
        } else {
          this.deleteElements(deletableElements);
        }
      }
    });
  }

  private deleteElements(elements: Array<DocumentElement>) {
    let actions: Array<DocumentAction> = [];
    elements.forEach((el) => {
      actions.push(
        new DocumentAction(
          {
            changeType: DocumentChangeType.DELETE_ELEMENT,
            elementId: el.id,
          },
          {
            changeType: DocumentChangeType.ADD_ELEMENT,
            elementId: el.id,
            elementData: el,
          },
        ),
      );
    });
    if (elements.length === 1 && elements[0].type === 'group') {
      this.groupElementService.updateOrDeleteGroupElement(elements[0].id, actions);
    }
    if (elements.length === 1) {
      const mask = this.documentService?.documentRenderer?.getMaskByMemberId(elements[0].id);
      if (mask?.element?.elementDefinition) {
        const maskAction = this.maskElementService.getRemoveMaskAction(mask.element.elementDefinition);
        if (maskAction) {
          actions.push(maskAction);
        }
      }
    }
    this.documentService.handleDocumentActions(actions);
  }
}
