import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { DocumentElement } from '@contrail/documents';
import { DocumentElementEvent } from '@contrail/documents';
import { DocumentService } from '../../document/document.service';
import { ActionRequest } from '@contrail/actions';
import { ClipboardActions } from '@common/clipboard/clipboard-store';
import { ComposerToolbarService } from '../composer-toolbar/composer-toolbar.service';
import { ItemDetailsModalComponent } from 'src/app/common/items/item-details-modal/item-details-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AddPinnedCommentsService } from '../composer-pinned-comments/add-pinned-comments-service';
import { ComposerService } from '../composer.service';
import { ComposerItemService } from '../composer-item/composer-item.service';
import { DocumentPropertiesService } from '../../document/document-properties/document-properties.service';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { Feature } from '@common/feature-flags/feature-flag';

@Component({
  selector: 'app-composer-context-menu',
  templateUrl: './composer-context-menu.component.html',
  styleUrls: ['./composer-context-menu.component.scss'],
})
export class ComposerContextMenuComponent implements AfterViewInit, OnDestroy {
  constructor(
    private documentService: DocumentService,
    private composerToolbarService: ComposerToolbarService,
    private addPinnedCommentsService: AddPinnedCommentsService,
    private composerService: ComposerService,
    private composerItemService: ComposerItemService,
    private documentPropertiesService: DocumentPropertiesService,
    private matDialog: MatDialog,
    private store: Store<RootStoreState.State>,
  ) {}

  @ViewChild(MatMenuTrigger) contextMenuTrigger: MatMenuTrigger;
  @ViewChild('contextMenu') contextMenu: ElementRef;
  @ViewChild('copyContextMenu', { read: ElementRef }) copyContextMenu: ElementRef;

  public element: DocumentElement;
  public selectedItemElements: Array<DocumentElement> = [];
  public sourceEvent: DocumentElementEvent;
  private subscriptions = new Subscription();
  public elementIsInGroup = false;
  public selectedGroupElements: DocumentElement[];
  public selectedGroupElementsMatchSizeEligible: DocumentElement[];

  public isMaskAllowed = false;
  public isRemoveMaskAllowed = false;
  public copiedProperties;
  public itemContextFeatureActive = false;
  public itemCreationFeatureActive = false;

  ngAfterViewInit(): void {
    this.subscribe();
  }
  ngOnDestroy() {
    this.selectedGroupElements = null;
    this.selectedGroupElementsMatchSizeEligible = null;
    this.subscriptions.unsubscribe();
  }

  subscribe() {
    this.subscriptions.add(
      this.documentService.documentElementEvents.subscribe((event) => {
        if (!event) {
          return;
        }
        if (event.eventType === 'contextmenu') {
          console.log('ComposerContextMenuComponent: event ', event);
          this.selectedGroupElements = null;
          this.selectedGroupElementsMatchSizeEligible = null;
          this.isMaskAllowed = false;
          this.isRemoveMaskAllowed = false;
          this.showMenu(event);
        }
      }),
    );

    this.subscriptions.add(this.composerService.presentation.subscribe((pres) => {}));

    this.subscriptions.add(
      this.store.select(FeatureFlagsSelectors.featureFlags).subscribe((flags) => {
        this.itemContextFeatureActive = !!flags.find((x) => x.featureName === Feature.ITEM_CONTEXT);
        this.itemCreationFeatureActive = !!flags.find((x) => x.featureName === Feature.ITEM_CREATION);
      }),
    );
  }

  showMenu(event: DocumentElementEvent) {
    console.log('showMenu: ', event.element);
    this.sourceEvent = event;
    this.element = event.element;
    const top = event.sourceMouseEvent.clientY;
    const left = event.sourceMouseEvent.clientX;
    this.contextMenu.nativeElement.setAttribute('style', `left:${left}px; top:${top}px;`);
    this.contextMenu.nativeElement.classList.add('visible');
    this.selectedGroupElements = this.documentService.getSelectedGroupElements();
    this.selectedGroupElementsMatchSizeEligible = this.selectedGroupElements?.filter(
      (e) => ['image', 'svg'].indexOf(e.type) !== -1,
    );
    this.isMaskAllowed = this.documentService.isMaskAllowed(this.selectedGroupElements);
    this.isRemoveMaskAllowed = this.documentService.isRemoveMaskAllowed(this.selectedGroupElements);
    this.copiedProperties = this.documentPropertiesService.getCopiedProperties();
    if (this.element) {
      const groupElement: DocumentElement = this.documentService.getGroupByMemberId(this.element.id);
      this.elementIsInGroup = false;
      if (groupElement) {
        this.elementIsInGroup = true;
      }
    }

    const selectedElements = this.documentService.getSelectedElements();
    this.deriveSelectedItems(selectedElements);

    this.contextMenuTrigger.openMenu();

    // Needed to get further up the call stack, for browser security.
    if (this.copyContextMenu) {
      this.copyContextMenu.nativeElement.addEventListener('click', this.copy.bind(this));
    }
  }

  private async deriveSelectedItems(selectedElements: DocumentElement[]) {
    if (!selectedElements?.length) {
      this.selectedItemElements = [];
      return;
    }

    this.selectedItemElements = selectedElements.filter((element) => !!element?.modelBindings?.item);
  }

  copy(event) {
    this.documentService.handleActionRequest(new ActionRequest('copy_elements', event));
  }

  paste() {
    this.composerToolbarService.showPasteWarningModal();
  }

  emitActionRequest(actionType: string) {
    this.documentService.handleActionRequest(new ActionRequest(actionType, this.sourceEvent));
  }

  areMultipleElementsSelected(threshold: number) {
    if (this.documentService.getSelectedElements() && this.documentService.getSelectedElements().length > threshold) {
      return true;
    }
    return false;
  }

  showComposerFrameSettings() {
    this.composerToolbarService.showComposerFrameSettings();
  }

  launchItemDetails() {
    const element = this.selectedItemElements[0];
    const itemId = element.modelBindings.item.substring(5);
    const accessLevel = 'EDIT';
    const config = {
      data: { itemId, accessLevel },
      panelClass: [`no-padding`, `item-details-modal`],
      maxWidth: '95vw',
      width: '1350px',
      height: '800px',
      autoFocus: true,
    };
    const dialogRef = this.matDialog.open(ItemDetailsModalComponent, config);
    const itemModalComponent: ItemDetailsModalComponent = dialogRef.componentInstance;
    itemModalComponent.updated.subscribe((result) => {
      this.composerItemService.syncElements(result);
    });
  }

  public showComments(event) {
    // We need source mouse event - where user clicked first to open the context menu (vs when the user clicked on the Comment menu)
    this.addPinnedCommentsService.addComments(
      {
        x: this.sourceEvent.sourceMouseEvent.clientX,
        y: this.sourceEvent.sourceMouseEvent.clientY,
      },
      this.element,
    );
  }

  removeElementFromGroup() {
    this.documentService.handleActionRequest(new ActionRequest('remove_element_from_group', event));
  }

  async addItemsToClipboard() {
    const clipboardItems = [];
    for (const itemElement of this.selectedItemElements) {
      const itemId = itemElement?.modelBindings?.item?.split('item:')[1];
      const projectItemId = itemElement?.modelBindings?.projectItem?.split('project-item:')[1];
      clipboardItems.push({ itemId, projectItemId });
    }

    this.store.dispatch(ClipboardActions.addItemsToClipboard({ clipboardItems }));
  }

  async addItemsToProject() {
    this.composerItemService.addItemsToProject();
  }
}
