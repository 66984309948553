import { createAction, props } from '@ngrx/store';

export enum ItemActionTypes {
  LOAD_ITEMS = '[Items] Load all Item colors',
  LOAD_ITEMS_SUCCESS = '[Items] Load Item Colors success',
  LOAD_ITEMS_FAILURE = '[Items] Load Item Colors failure',

  SET_ITEMS = '[Items] SET Item colors',
}

export const loadItems = createAction(ItemActionTypes.LOAD_ITEMS);
export const loadItemsSuccess = createAction(ItemActionTypes.LOAD_ITEMS_SUCCESS, props<{ data: Array<any> }>());

export const loadItemsFailure = createAction(ItemActionTypes.LOAD_ITEMS_FAILURE, props<{ error: any }>());

export const setItems = createAction(ItemActionTypes.SET_ITEMS, props<{ data: Array<any> }>());
