import { SortDefinition } from '../../components/sort/sort-definition';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { AssortmentUtil } from '../../assortments/assortment-util';
import { AssortmentsService } from '../../assortments/assortments.service';
import { ChooserFilterConfig, ItemDataChooserDataSource } from './item-data-chooser-data-source';
import { FilterDefinition } from '@common/types/filters/filter-definition';
import { FilterObjects } from '@contrail/filters';
import { AssortmentItem } from '@common/assortments/assortment-item';
import { ObjectUtil } from '@contrail/util';
export class AssortmentChooserDataSource extends ItemDataChooserDataSource {
  criteria: any;
  optionItemData: AssortmentItem[];
  familyItemData: AssortmentItem[];
  assortment: any;

  constructor(
    protected store: Store<RootStoreState.State>,
    protected filterConfigSubject: Observable<ChooserFilterConfig>,
    protected sortConfigSubject: Observable<SortDefinition[]>,
    protected existingItemIdsSubject: Observable<any>,
    protected showAllSubject: Observable<any>,
    protected dateFilterSubject: Observable<FilterDefinition>,
    protected assortmentId,
    protected itemLevelSelection,
  ) {
    super(store, filterConfigSubject, sortConfigSubject, existingItemIdsSubject, showAllSubject, dateFilterSubject);
    this.initFilteredDataObservable();
    this.initResultsObservable();
    console.log('AssortmentChooserDataSource: CONSTRUCTOR');
    this.loadAssortment();
  }

  protected async initFilteredDataObservable() {
    console.log('AssortmentChooserDataSource: initFilteredDataObservable INIT: ', this.assortmentItemData$);
    this.filteredData$ = combineLatest([
      this.filterConfigSubject,
      this.dateFilterSubject,
      this.sortConfigSubject,
      this.assortmentItemData$,
    ]).pipe(
      switchMap(async ([filterConfig, dateFilter, sortConfig, assortmentItemData]) => {
        console.log('AssortmentChooserDataSource: filteredDataObservable');

        let data;
        const searchTerm = filterConfig?.searchTerm;
        const filterDefinition = filterConfig?.filterDefinition;
        this.criteria = filterConfig?.baseCriteria;
        // SOURCE ASSORTMENT CONSTRAINT, OPTION LEVEL
        data = assortmentItemData;
        if (this.optionItemData) {
          if (this.criteria?.roles === 'family') {
            data = ObjectUtil.cloneDeep(this.familyItemData);
          } else {
            data = ObjectUtil.cloneDeep(this.optionItemData);
          }
        }
        data = this.filterLocalItemData(
          { searchTerm, itemFamilyId: this.criteria?.itemFamilyId },
          data,
          this.searchableProperties,
        );
        if (filterDefinition) {
          data = FilterObjects.filter(data, filterDefinition?.filterCriteria);
        }
        if (dateFilter) {
          data = this.filterByAssortmentItems(data, dateFilter);
        }
        // data = FilterUtil.filterDroppedItems(data, auth?.currentOrg?.orgSlug);
        console.log('AssortmentChooserDataSource: ', data);
        this.sortData(data, sortConfig);
        return data;
      }),
    );
  }

  private async loadAssortment() {
    this.loadingSubject.next(true);
    this.assortment = await AssortmentsService.getAssortment(this.assortmentId);
    if (this.itemLevelSelection) {
      this.assortment = await AssortmentsService.getAssortmentFamilyItems(this.assortment);
    }
    const assortmentItems = this.assortment.assortmentItems;
    let itemData;
    if (this.itemLevelSelection) {
      this.optionItemData = AssortmentUtil.convertAssortmentItemsToItemData(
        assortmentItems.filter((ai) => ai.item.roles.includes('color')),
      );
    } else {
      this.optionItemData = AssortmentUtil.convertAssortmentItemsToItemData(assortmentItems);
    }
    if (this.assortment.familyLevelItems) {
      this.familyItemData = AssortmentUtil.convertItemsToFamilyItemData(this.assortment.familyLevelItems);
    }
    if (this.criteria?.roles === 'family') {
      itemData = ObjectUtil.cloneDeep(this.familyItemData);
    } else {
      itemData = ObjectUtil.cloneDeep(this.optionItemData);
    }
    console.log('AssortmentChooserDataSource: itemData: ', itemData);
    this.assortmentSubject.next(itemData);
    this.loadingSubject.next(false);
  }
}
