import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HotSpotService } from './hot-spot/hot-spot.service';
import { DeleteDocumentElementService } from './delete-document-element/delete-document-element.service';
import { OrderDocumentElementService } from './order-document-element/order-document-element.service';
import { AlignDocumentElementService } from './align-document-element/align-document-element.service';
import { ClipboardActionsDispatchers } from './clipboard-action-dispatcher/clipboard-action-dispatchers.service';
import { DistributeDocumentElementService } from './align-document-element/distribute-document-element.service';
import { DocumentElementInteractionService } from './document-element-interaction/document-element-interaction.service';
import { MoveDocumentElementService } from './move-document-element/move-document-element.service';
import { LockDocumentElementService } from './lock-document-element/lock-document-element.service';
import { CropImageElementService } from './crop-image-element/crop-image-element.service';
import { GroupElementService } from './group-element/group-element.service';
import { MaskElementService } from './mask-element/mask-element.service';
import { DocumentPropertiesService } from '../document-properties/document-properties.service';
import { MatchSizeDocumentElementService } from './resize-document-element/match-size-document-element.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class ActionsModule {
  constructor(
    private hotSpotService: HotSpotService,
    private deleteDocumentElementService: DeleteDocumentElementService,
    private copyDocumentElementsService: ClipboardActionsDispatchers,
    private alignDocumentElementService: AlignDocumentElementService,
    private distributeDocumentElementService: DistributeDocumentElementService,
    private orderDocumentElementService: OrderDocumentElementService,
    private documentElementInteractionService: DocumentElementInteractionService,
    private moveDocumentElementService: MoveDocumentElementService,
    private lockDocumentElementService: LockDocumentElementService,
    private cropImageElementService: CropImageElementService,
    private editGroupElementService: GroupElementService,
    private maskElementService: MaskElementService,
    private documentPropertiesService: DocumentPropertiesService,
    private matchSizeDocumentElementService: MatchSizeDocumentElementService,
  ) {}
}
