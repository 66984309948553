<div
  class="h-8 -ml-3 first:m-0"
  *ngFor="let session of userSessions$ | async | slice: 0 : ((userSessions$ | async)?.length > 4 ? 3 : 4)"
>
  <div (click)="onAvatarClick(session)" [ngClass]="{ 'cursor-pointer': clickableAvatar }">
    <app-avatar [user]="session.user"></app-avatar>
  </div>
</div>
<div class="absolute -bottom-1 left-0" cdkOverlayOrigin #trigger="cdkOverlayOrigin"></div>
<div *ngIf="(userSessions$ | async)?.length > 4">
  <div
    class="initials bg-white h-8 w-8 non-selectable border rounded-full -ml-3 flex justify-center items-center text-black60"
    (click)="toggleDisplay()"
  >
    {{ (userSessions$ | async).length }}
  </div>
  <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpened">
    <div class="bg-white flex flex-col rounded-lg w-56 h-56 mat-elevation-z4">
      <div class="rounded bg-gray-50 flex flex-row space-x-2 m-2 mb-1 p-3 text-black60">
        <mat-icon>search</mat-icon>
        <input
          class="bg-transparent"
          matInput
          #inputField
          placeholder="Search"
          type="input"
          [formControl]="searchInput"
        />
      </div>
      <div class="overflow-y-scroll flex flex-col">
        <div
          class="flex space-x-2.5 items-center my-1 mx-2 last:mb-2"
          *ngFor="let session of filteredUserSessions$ | async"
        >
          <div (click)="onAvatarClick(session)" [ngClass]="{ 'cursor-pointer': clickableAvatar }">
            <app-avatar [user]="session.user"></app-avatar>
          </div>
          <div>
            {{ session.user.displayName | shorten: 20 }}
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
