import { Overlay } from '@angular/cdk/overlay';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DefaultLoadingScreen, Engine, Scene, UniversalCamera, Vector3 } from '@babylonjs/core';
import { Entities } from '@contrail/sdk';
import { ShowroomItemModalComponent } from '../showroom-item-modal/showroom-item-modal.component';
import { Location360Definition } from './360-interfaces';
import { Location360 } from './location-360';
import { TooltipLayerUtil } from './tooltip-overlay';

export class Showroom360Viewer {
  public canvas: HTMLCanvasElement;
  public engine: Engine;
  public scene: Scene;
  public camera: UniversalCamera;
  public matDialog: MatDialog;
  public tooltipUtil: TooltipLayerUtil;

  public domeRadius: number = 500;

  public locationArray: Array<Location360> = [];

  public lastCanvasDims: { width; height };

  private numLocationsLoaded: number = 0;

  public setupScene(
    canvas: HTMLCanvasElement,
    matDialog: MatDialog,
    overlay: Overlay,
    sourceAssortment: any,
    showroom: any,
  ): void {
    this.canvas = canvas;
    this.updateLastCanvasDims();

    this.matDialog = matDialog;
    this.tooltipUtil = new TooltipLayerUtil(overlay);

    this.engine = new Engine(this.canvas, true);
    this.engine.loadingScreen.loadingUIBackgroundColor = 'white';
    DefaultLoadingScreen.DefaultLogoUrl = 'https://hub.dev.vibeiq.com/assets/images/vibeiq_logo.jpg';
    this.engine.displayLoadingUI();

    this.scene = this.createScene();
    this.engine.runRenderLoop(() => {
      if (this.didCanvasChangeSize()) {
        this.engine.resize();
      }

      this.scene.render();
    });

    const _this = this;
    this.canvas.onresize = () => {
      _this.engine.resize();
      this.updateLastCanvasDims();
    };
    window.onresize = () => {
      _this.engine.resize();
      this.updateLastCanvasDims();
    };

    this.setupTour(sourceAssortment, showroom);
  }

  protected createScene(): Scene {
    const scene = new Scene(this.engine);
    const camera = new UniversalCamera('Camera', Vector3.Zero(), scene);
    camera.attachControl(this.canvas, true);
    camera.setTarget(new Vector3(0, 0, 1));
    camera.inputs.removeByType('FreeCameraKeyboardMoveInput'); // Remove default keyboard movement

    return scene;
  }

  protected async setupTour(sourceAssortment: any, showroom: any) {
    if (!showroom.showroomLayouts?.length) {
      console.log('No layout available');
      return;
    }

    const showroomLayoutId = showroom.showroomLayouts[0].id;
    const showroomTours: Array<any> = await new Entities().get({
      entityName: 'showroom-tour',
      criteria: { showroomLayoutId },
    });

    if (!showroomTours.length) {
      console.log('No Tour available');
      return;
    }

    const showroomTour = showroomTours[0];
    const locationPins = showroomTour.tourConfig.locationPins;
    const tourImages = showroomTour.virtualTourImages;

    for (let i = 0; i < locationPins.length; i++) {
      let locationDef: Location360Definition = locationPins[i];

      let imageUrl;
      let locationRefName = locationPins[i].refName;

      for (let j = 0; j < tourImages.length; j++) {
        if (tourImages[j].refName == locationRefName) {
          imageUrl = tourImages[j].fileDownloadURL;
          break;
        }
      }

      let startInvisible: boolean = i > 0;

      const location = new Location360(imageUrl, this.domeRadius, this.scene, startInvisible, locationDef);
      this.locationArray.push(location);

      location.photoDome.onLoadObservable.add(() => {
        this.numLocationsLoaded++;

        if (this.numLocationsLoaded == locationPins.length) {
          this.engine.hideLoadingUI();
        }
      });

      location.location360Obs.add((eventData: any, eventState) => {
        const eventTriggerKey = eventData[0];

        const itemId = eventData[1].split(':')[1];
        const assortmentItems = sourceAssortment?.assortmentItems;
        const target = assortmentItems?.find((i) => i?.itemId === itemId);
        switch (eventTriggerKey) {
          case 'PICK:LOCATION':
            this.showLocation(eventData[1]);
            break;
          case 'PICK:ENTITY':
            this.handleEntityPicked(target);
            break;
          case 'ENTER:ENTITY':
            this.handleEntityEnter(target);
            break;
          case 'LEAVE:ENTITY':
            this.handleEntityLeave(eventData[1]);
            break;
        }
      });
    }
  }

  public showLocation(locationName: string): void {
    for (let i = 0; i < this.locationArray.length; i++) {
      if (this.locationArray[i].locationName == locationName) {
        this.locationArray[i].fadeIn();
      } else {
        this.locationArray[i].fadeOut();
      }
    }
  }

  protected handleEntityPicked(target) {
    if (target) {
      this.showItemDetails(target);
    }
  }

  public showItemDetails(target): void {
    this.matDialog
      .open(ShowroomItemModalComponent, {
        data: {
          target: target,
        },
        autoFocus: true,
        width: '1240px',
        height: '700px',
        panelClass: 'no-padding',
      })
      .afterClosed()
      .subscribe(() => {
        this.tooltipUtil?.closeTooltip();
      });
  }

  public handleEntityEnter(target) {
    if (target) {
      this.tooltipUtil.showTooltip(target?.item?.name, target?.item?.colorName);
    }
  }
  public handleEntityLeave(entityReference) {
    this.tooltipUtil.closeTooltip();
  }

  public clear() {
    this.locationArray.forEach((location) => {
      location.clear();
    });
  }

  private didCanvasChangeSize(): boolean {
    if (
      this.canvas.getBoundingClientRect().width != this.lastCanvasDims.width ||
      this.canvas.getBoundingClientRect().height != this.lastCanvasDims.height
    ) {
      this.updateLastCanvasDims();

      return true;
    }

    return false;
  }

  private updateLastCanvasDims(): void {
    this.lastCanvasDims = this.canvas.getBoundingClientRect();
  }
}
