<button mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon class="icons scale-125">{{ sizeOption === 'square' ? 'crop_square' : 'crop_landscape' }}</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <ng-template matMenuContent>
    <button
      class="text-black60"
      mat-menu-item
      *ngFor="let option of sizeOptions"
      (click)="updateSize($event, option.value)"
    >
      <mat-icon>{{ option.icon }}</mat-icon>
      <span>{{ option.label }}</span>
    </button>
  </ng-template>
</mat-menu>
