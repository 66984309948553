<div class="collection-main-body" [ngClass]="cursorClass" (click)="onClick($event)">
  <div class="placeholder" *ngIf="frame.collection.set.length === 0">
    <app-empty-placeholder [title]="title" [icon]="icon" [footer]="footer"></app-empty-placeholder>
    <!--<button mat-raised-button color="primary" data-test="app-composer-collection-frame-item-chooser" (click)="openItemChooser()">OPEN CHOOSER</button>-->
  </div>
  <app-presentation-collection-editor
    [annotationType]="annotationType"
    [annotationOptions]="annotationOptions"
    [statusMessages]="statusMessages"
    [searchResults]="searchResults"
    [activeSearchResultElement]="activeSearchResultElement"
    [frame]="frame"
  >
  </app-presentation-collection-editor>
</div>
