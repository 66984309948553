import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { AssortmentsSelectors } from '@common/assortments/assortments-store';
import { ItemData } from '@common/item-data/item-data';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ComposerService } from 'src/app/presentation/composer/composer.service';
import { Presentation } from 'src/app/presentation/presentation';
import { ObjectUtil } from '@contrail/util';
import { DocumentStatusMessageService } from 'src/app/presentation/document/document-status/document-status-message.service';

@Component({
  selector: 'app-presentation-collection-preview-modal',
  templateUrl: './presentation-preview-modal.component.html',
  styleUrls: ['./presentation-preview-modal.component.scss'],
})
export class PresentationPreviewModalComponent implements OnInit, OnDestroy {
  @Output() updated = new EventEmitter();
  public defaults: any;
  public itemId: string;
  public itemData: ItemData;
  private subscriptions = new Subscription();
  currentIndex = 0;
  items: any[];
  item: any;
  type: any;
  public accessLevel = 'EDIT';
  includeCommentWidget = false;
  itemsMissingFromSource: any[];

  constructor(
    public dialogRef: MatDialogRef<PresentationPreviewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<RootStoreState.State>,
    private documentStatusMessageService: DocumentStatusMessageService,
  ) {
    this.items = data.items;
    this.item = data.currentItem || data.items[0];
    this.currentIndex = data.items.findIndex((item) => item.value === this.item.value);
    this.itemId = this.item.value;
    this.accessLevel = data.accessLevel;
  }

  ngOnInit() {
    this.loadItem(this.itemId);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadItem(itemId: string) {
    const optionIds = this.item.children.map((child) => child.value);
    this.subscriptions.add(
      combineLatest([
        this.store.select(AssortmentsSelectors.selectBackingAssortmentItemByFamily(itemId)),
        this.store.select(AssortmentsSelectors.sourceAssortmentItemData),
      ])
        .pipe(
          map(([backingAssortmentItem, sourceAssortmentItems]) => {
            const options = [];
            backingAssortmentItem.options
              .filter((option) => optionIds.includes(option.id))
              .forEach((option) => {
                if (!options.find((localOption) => option.id === localOption.id)) {
                  options.push(option);
                }
              });
            const sourceAssortmentItem = sourceAssortmentItems?.find(
              (sourceAssortmentItem) => sourceAssortmentItem.id === backingAssortmentItem.properties.itemId,
            );
            if (sourceAssortmentItem) {
              backingAssortmentItem.properties = ObjectUtil.mergeDeep(
                ObjectUtil.cloneDeep(sourceAssortmentItem.properties),
                backingAssortmentItem.properties,
              );
            }
            if (backingAssortmentItem.projectItem) {
              backingAssortmentItem.properties = ObjectUtil.mergeDeep(
                ObjectUtil.cloneDeep(backingAssortmentItem.properties),
                backingAssortmentItem.projectItem,
              );
            }
            this.itemData = backingAssortmentItem;
            this.itemData.options = options;
          }),
        )
        .subscribe(),
    );
    this.subscriptions.add(
      this.documentStatusMessageService.statusMessages.subscribe((statusMessages) => {
        this.itemsMissingFromSource = statusMessages.map((statusMessage) => statusMessage.collectionElementId);
      }),
    );
  }

  complete(item) {
    this.dialogRef.close(item);
  }
  cancel() {
    this.dialogRef.close();
  }

  emitUpdate($event) {
    this.updated.emit($event);
  }

  async navigate(increment) {
    let nextIndex = this.currentIndex + increment;
    if (nextIndex === this.items.length) {
      nextIndex = 0;
    } else if (nextIndex < 0) {
      nextIndex = this.items.length - 1;
    }
    this.item = this.items[nextIndex];
    this.itemId = this.item.value;
    this.currentIndex = nextIndex;
    await this.loadItem(this.itemId);
  }
}
