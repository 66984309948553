import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-composer-canvas-navigation-bar',
  templateUrl: './composer-canvas-navigation-bar.component.html',
  styleUrls: ['./composer-canvas-navigation-bar.component.scss'],
})
export class ComposerCanvasNavigationBarComponent {
  @Output() zoomOut = new EventEmitter();
  @Output() zoomIn = new EventEmitter();
  @Output() center = new EventEmitter();

  constructor() {}

  doZoomIn() {
    this.zoomIn.emit(null);
  }

  doZoomOut() {
    this.zoomOut.emit(null);
  }

  doCenter() {
    this.center.emit(null);
  }
}
