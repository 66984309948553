import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { DocumentElementEvent, StyleDefinition } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ComponentEditorService } from 'src/app/presentation/document/component-editor/component-editor.service';
import { DocumentComponentUtils } from 'src/app/presentation/document/document-component/document-component.utils';

@Component({
  selector: 'app-property-view-builder-format-bar',
  templateUrl: './property-view-builder-format-bar.component.html',
  styleUrls: ['./property-view-builder-format-bar.component.scss'],
})
export class PropertyViewBuilderFormatBarComponent {
  public sourceEvent: DocumentElementEvent;
  public sizes: string[];
  @ViewChild('widgetTray') widgetElement: ElementRef;
  @Output() valuesChanged = new EventEmitter<any>();

  private subject: Subject<string> = new Subject();
  private defaultStyle = {};
  constructor() {
    this.sizes = ComponentEditorService.getPtOptions();
  }

  ngOnInit(): void {
    this.subject.pipe(debounceTime(500)).subscribe((values) => {
      this.updateValues(values);
    });
  }

  show(event: DocumentElementEvent) {
    this.sourceEvent = event;
    if (!this.widgetElement) {
      return;
    }
    const top = event.renderedElementPosition.y - 70;
    const left = event.renderedElementPosition.x - 420;
    this.widgetElement.nativeElement.setAttribute('style', `left:${left}px; top:${top}px;`);
    this.widgetElement.nativeElement.classList.add('visible');
  }

  hide() {
    if (this.widgetElement) {
      this.widgetElement.nativeElement.classList.remove('visible');
    }
  }

  updateValues(values) {
    const changes = DocumentComponentUtils.convertToTextStyle(values);
    this.valuesChanged.emit(changes);
  }

  clearFormat(event) {
    this.valuesChanged.emit({ style: this.defaultStyle });
  }

  handleDelayedValueChange(values) {
    this.subject.next(values);
  }

  getCurrentValue(index) {
    const value = ObjectUtil.getByPath(this.sourceEvent.element, index);
    return value;
  }

  handleClick(event) {
    event.stopPropagation();
  }
}
