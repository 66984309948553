export const FONTS_FAMILY_PICKLIST = [
  'Arial',
  'Arial Black',
  'Arial Narrow',
  'Arial Rounded MT Bold',
  'Avenir',
  'Bradley Hand',
  'Comic Sans MS',
  'Copperplate',
  'Courier',
  'Courier New',
  'fantasy',
  'Georgia',
  'Helvetica',
  'Impact',
  'Palatino',
  'Playfair Display',
  'Roboto',
  'sans-serif',
  'Snell Roundhand',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana',
];

export const CUSTOM_FONTS_FAMILY = {
  // 'yulia-zone-1': ['Arial Nova', 'Arial Nova Light', 'Arial Nova Cond', 'Arial Nova Cond Light', 'Avenir-Light', 'Avenir-Medium', 'Avenirltstd-Black', 'Avenirltstd-Black-Oblique', 'Avenirltstd-Book', 'Avenirltstd-Book-Oblique', 'Avenirltstd-Heavy', 'Avenirltstd-Heavy-Oblique', 'Avenirltstd-Lightoblique', 'Avenirltstd-Mediumoblique', 'Avenirltstd-Oblique', 'Avenirltstd-Roman', 'Gill Sans Std', 'Gill Sans Std Italic', 'Gill Sans Std Bold', 'Gill Sans Std Bold Italic', 'Gill Sans Std Light', 'Gill Sans Std Light Italic', 'Gill Sans MT Std Bold', 'Gill Sans MT Std Bold Italic', 'Gill Sans MT Std Light', 'Gill Sans MT Std Light Italic', 'Gill Sans MT Std Medium', 'Gill Sans MT Std Medium Italic', 'Helvetica Neue Std Medium', 'Helvetica Neue Std Medium Italic', 'Helvetica Neue Std Bold', 'Helvetica Neue Std Bold Italic', 'Helvetica Neue Std Italic', 'Helvetica Neue Std Light', 'Helvetica Neue Std Light Italic', 'DiNMittelschriftStd', 'Fenice Std Bold', 'Fenice Std Bold Oblique', 'Fenice Std Light', 'Fenice Std Light Oblique', 'Fenice Std Regular', 'Fenice Std Regular Oblique', 'Fenice Std Ultra', 'Fenice Std Ultra Oblique', 'Shelley', 'Myriad Pro Bold', 'Myriad Pro Bold Italic', 'Myriad Pro Light', 'Myriad Pro Light Italic', 'Myriad Pro Regular', 'Myriad Pro Regular Italic', 'Alegre Sans', 'SegoeUI Bold', 'SegoeUI Light', 'SegoeUI Semi Bold', 'SegoeUI Semi Light', 'SegoeUI'], // ['AlegreSans', 'Avenir', 'DiNMittelschriftStd', 'FeniceStd', 'GillSansStd', 'GillSansMTStd', 'HelveticaNeueLTStd', 'SegoeUI', 'Shelley'],// ['AdihausDIN', 'AdihausDIN-Cn', 'AdiHaus', 'AdiHaus-CE', 'AdiHaus-ROM', 'AdiHaus-Turk', 'adineuePROCond-Rounded', 'adineuePROCondTT', 'adineueTEXT', 'AdiHausPS', 'AdiHausPSCon', 'adiNeue', 'adiNeue2013', 'adiNeueLogos', 'adineuePRO', 'adineuePRO-Beta', 'adineuePROCond', 'AdiTECH', 'Gotham', 'Didot', 'ITCFranklinGothicStd-BkCp', 'ITCFranklinGothicStd-Book', 'ITCFranklinGothicStd-DmCp', 'AlegreSans', 'DiNMittelschriftStd', 'FeniceStd', 'Shelley'],
  // 'vadim-zone-1': ['AdihausDIN', 'AdihausDIN-Cn', 'AdiHaus', 'AdiHaus-CE', 'AdiHaus-ROM', 'AdiHaus-Turk', 'adineuePROCond-Rounded', 'adineuePROCondTT', 'adineueTEXT', 'AdiHausPS', 'AdiHausPSCon', 'adiNeue', 'adiNeue2013', 'adiNeueLogos', 'adineuePRO', 'adineuePRO-Beta', 'adineuePROCond', 'AdiTECH', 'Gotham', 'Didot', 'AlegreSans', 'DiNMittelschriftStd', 'FeniceStd', 'Shelley'],
  'brian-dev': ['Didot', 'Gotham'],
  // 'brian-zone': ['AdihausDIN', 'AdihausDIN-Cn', 'AdiHaus', 'AdiHaus-CE', 'AdiHaus-ROM', 'AdiHaus-Turk', 'adineuePROCond-Rounded', 'adineuePROCondTT', 'adineueTEXT', 'AdiHausPS', 'AdiHausPSCon', 'adiNeue', 'adiNeue2013', 'adiNeueLogos', 'adineuePRO', 'adineuePRO-Beta', 'adineuePROCond', 'AdiTECH', 'Gotham', 'Didot'],
  agron: [
    'Denton',
    'Franklin Gothic Condensed',
    'Franklin Gothic Book',
    'AdihausDIN',
    'AdihausDIN-Cn',
    'AdiHaus',
    'AdiHaus-CE',
    'AdiHaus-ROM',
    'AdiHaus-Turk',
    'adineuePROCond-Rounded',
    'adineuePROCondTT',
    'adineueTEXT',
    'AdiHausPS',
    'AdiHausPSCon',
    'adiNeue',
    'adiNeue2013',
    'adiNeueLogos',
    'adineuePRO',
    'adineuePRO-Beta',
    'adineuePROCond',
    'AdiTECH',
    'ITCFranklinGothicStd-BkCp',
    'ITCFranklinGothicStd-Book',
    'ITCFranklinGothicStd-DmCp',
  ],
  'new-balance-prod': ['Didot', 'Gotham'],
  'new-balance-training': ['Didot', 'Gotham'],
  'nb-legacy-prod': [
    'Arial Nova',
    'Arial Nova Light',
    'Arial Nova Cond',
    'Arial Nova Cond Light',
    'ITC Garamond Condensed Book',
  ],
  demo: ['Didot', 'Gotham'],
  ralph: [
    'Avenir-Light',
    'Avenir Lt Std-Black',
    'Avenir Lt Std-Heavy',
    'Avenir Lt Std-Roman',
    'Gill Sans MT Std Bold',
    'Gill Sans MT Std Light',
    'Gill Sans MT Std Medium',
    'Helvetica Neue Std Medium',
    'Helvetica Neue Std Bold',
    'Helvetica Neue Std Light',
    'DiNMittelschriftStd',
    'Fenice Std Bold',
    'Fenice Std Light',
    'Fenice Std Regular',
    'Fenice Std Ultra',
    'Shelley',
    'Myriad Pro Bold',
    'Myriad Pro Light',
    'Myriad Pro Regular',
    'Alegre Sans',
    'SegoeUI Bold',
    'SegoeUI Light',
    'SegoeUI',
  ],
  'michael-kors': ['Montserrat'],
};
