import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { BehaviorSubject } from 'rxjs';
import { IContent } from './content-holder-details/content-holder-details.component';
import { URL } from 'url';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

const NON_VIEWABLE_EXTS = ['.ai', '.pdf', '.eps', '.ps', '.psd', '.psb', '8bps'];
const NON_IMAGE_VIEWABLE_CONTENT_TYPES = ['application/postscript', 'application/pdf'];

export interface ContentEntity {
  id?: string;
  createdOn?: Date;
  updatedOn?: Date;
  createdById?: string;
  updatedById?: string;
  createdBy?: any;
  updatedBy?: any;
  orgId?: string;

  assetId?: string;

  fileName?: string;
  contentType?: string;
  fileSize?: string;

  primaryFileId?: string;
  primaryFileUrl?: string;
  largeViewableId?: string;
  largeViewableUrl?: string;
  mediumLargeViewableId?: string;
  mediumLargeViewableUrl?: string;
  mediumViewableId?: string;
  mediumViewableUrl?: string;
  smallViewableId?: string;
  smallViewableUrl?: string;
  tinyViewableId?: string;
  tinyViewableUrl?: string;

  primaryViewable?: boolean;
  primaryFile?: any;

  contentHolderContent?: ContentHolderContent;
}

export interface ContentHolderContent {
  id?: string;

  createdOn?: Date;
  updatedOn?: Date;
  createdById?: string;
  updatedById?: string;
  createdBy?: any;
  updatedBy?: any;

  contentHolderReference?: string;
  contentId?: string;

  orgId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  constructor(public snackBar: MatSnackBar) {}

  public contentColorVariant$: BehaviorSubject<any> = new BehaviorSubject(null);

  public screenCaptures$: BehaviorSubject<any> = new BehaviorSubject(null);

  public static isNonViewableImage(fileName) {
    return NON_VIEWABLE_EXTS.indexOf(fileName.substring(fileName.lastIndexOf('.'))) > -1;
  }

  public static shouldContentTypeHavePreview(content) {
    const contentType = content?.primaryFile?.contentType;
    if (contentType.includes('image')) {
      return true;
    }
    return NON_IMAGE_VIEWABLE_CONTENT_TYPES.includes(contentType);
  }

  public static isImage(content) {
    const contentType = content?.primaryFile?.contentType;
    return contentType.includes('image') ? true : false;
  }
  public static isSVG(content) {
    const contentType = content?.primaryFile?.contentType;
    return contentType.includes('svg') ? true : false;
  }
  public static isAsyncViewableContentType(content) {
    const contentType = content?.primaryFile?.contentType;
    return NON_IMAGE_VIEWABLE_CONTENT_TYPES.includes(contentType);
  }

  public static async getContentForContentHolder(contentHolderReference: string) {
    const content = await new Entities().get({
      entityName: 'content',
      criteria: { contentHolderReference },
      relations: ['largeViewable', 'mediumViewable', 'smallViewable', 'tinyViewable', 'primaryFile'],
    });
    content.sort((c1, c2) => {
      return new Date(c1.createdOn).getTime() - new Date(c2.createdOn).getTime();
    });
    return content;
  }

  public async handleScreenCapture(content: IContent, blobUrl: URL) {
    const name = content.primaryFile.fileName?.split('.')[0];
    const fileName = `${name}.png`;
    const response = await fetch(blobUrl);
    const blobFile = await response.blob();
    const file = new File([blobFile], fileName, { type: 'image/png' });
    this.screenCaptures$.next(file);
    this.snackBar.open('Screen shot captured.', '', { duration: 2000, horizontalPosition: 'right' });
    return;
  }

  public downloadContent(content: any) {
    const fileUrl = content?.primaryFile?.downloadUrl;
    window.open(fileUrl, '_blank');
  }
}
