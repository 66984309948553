import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { ComposerGridFrameService } from '../../composer-grid-frame.service';

@Component({
  selector: 'app-update-grid-frame-section',
  templateUrl: './update-grid-frame-section.component.html',
  styleUrls: ['./update-grid-frame-section.component.scss'],
})
export class UpdateGridFrameSectionComponent implements OnInit {
  public nameFormControl = new UntypedFormControl('', Validators.required);
  constructor(
    private store: Store<RootStoreState.State>,
    private composerGridFrameService: ComposerGridFrameService,
    public dialogRef: MatDialogRef<UpdateGridFrameSectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.nameFormControl.setValue(this.data.section.label);
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.composerGridFrameService.updateSectionName(this.data.section, this.nameFormControl.value);
    this.dialogRef.close();
  }

  isValid() {
    return this.nameFormControl.valid;
  }
}
