import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ColorUtil } from '@util/color-util';
import { CanvasUtil } from '@contrail/canvas';
import { ObjectUtil } from '@contrail/util';

@Component({
  selector: 'app-remote-mouse',
  template: `
    <div class="icon">
      <mat-icon svgIcon="mouse-cursor"></mat-icon>
    </div>
    {{ displayName }}
  `,
  styles: [
    `
      :host {
        @apply absolute font-medium max-w-fit flex flex-col text-[10px] leading-[12px];
        .mat-icon {
          @apply w-3 h-3;
        }
      }
    `,
  ],
})
export class RemoteMouseComponent implements OnInit, OnChanges {
  @Input() remoteMouse;
  @Input() viewSize;

  public displayName: string;

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    this.displayName = this.getDisplayName();
    this.element.nativeElement.style.color = this.getColor();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.remoteMouse) {
      this.setPosition(true);
    } else if (changes.viewSize) {
      this.setPosition();
    }
  }

  private setPosition(animated = false) {
    const windowPosition = this.getPosition();
    this.element.nativeElement.style.transition = animated ? 'transform 300ms ease' : 'none';
    this.element.nativeElement.style.transform = `translate(${windowPosition.x}px, ${windowPosition.y}px)`;
  }

  private getPosition() {
    return CanvasUtil.toWindowPosition(
      this.remoteMouse.x,
      this.remoteMouse.y,
      this.viewSize.viewBox,
      this.viewSize.viewScale,
      this.viewSize.boundingClientRect,
    );
  }

  private getDisplayName() {
    let displayName = 'Guest';
    if (this.remoteMouse) {
      if (this.remoteMouse.firstName || this.remoteMouse.lastName) {
        displayName = [this.remoteMouse.firstName, this.remoteMouse.lastName].join(' ');
      } else if (this.remoteMouse.email) {
        displayName = this.remoteMouse.email;
      }
    }
    return displayName;
  }

  private getColor() {
    return ColorUtil.stringToHslaColor(this.remoteMouse.clientId);
  }
}
