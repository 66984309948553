import { Component, Input, OnInit } from '@angular/core';
import { AppExtensionService } from '@common/app-extensions/app-extension.service';
import { AppExtensionsSelectors } from '@common/app-extensions/app-extensions-store';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { ShowcaseWebSDKMessageHandler } from 'src/app/presentation/web-sdk/showcase-web-sdk-message-handler';
import { ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { Presentation } from '../../../presentation';
import { ComposerGenerateCanvasFramesService } from '../../composer-frame/composer-generate-canvas-frames/composer-generate-canvas-frames.service';
import { ComposerGridFrameService } from '../../composer-frame/composer-grid-frame/composer-grid-frame.service';
import { ComposerIframeFrameService } from '../../composer-frame/composer-iframe-frame/composer-iframe-frame.service';
import { ComposerShowroomFrameService } from '../../composer-frame/composer-showroom-frame/composer-showroom-frame-service';
import { ComposerService } from '../../composer.service';
import { AppExtensionDefinition, AppExtensionUserApps } from '@contrail/entity-types';
import { Feature } from '@common/feature-flags/feature-flag';
import { FrameTemplatesSelectors } from '@common/frame-templates/frame-templates-store';
import { FrameTemplate } from '@common/frame-templates/frame-template';
import { ObjectUtil } from '@contrail/util';
import { FrameTemplatesService } from '@common/frame-templates/frame-templates.service';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { DocumentElementFactory } from '@contrail/documents';

@Component({
  selector: 'app-add-frame-widget',
  templateUrl: './add-frame-widget.component.html',
  styleUrls: ['./add-frame-widget.component.scss'],
})
export class AddFrameWidgetComponent implements OnInit {
  public presentation: Presentation;
  @Input() largeIcon: boolean;
  @Input() icon = 'add_box';
  private subs: Array<Subscription> = [];
  public appExtensions: Array<AppExtensionDefinition> = [];
  public showShowroom: boolean = false;
  public frameTemplates: any[];
  public showGrid = Observable<boolean>;
  constructor(
    private store: Store<RootStoreState.State>,
    private composerService: ComposerService,
    private composerShowroomFrameService: ComposerShowroomFrameService,
    private composerGridFrameService: ComposerGridFrameService,
    private composerGenerateCanvasFramesService: ComposerGenerateCanvasFramesService,
    private composerIframeFrameService: ComposerIframeFrameService,
    private appExtensionService: AppExtensionService,
    private frameTemplatesService: FrameTemplatesService,
    private showcaseWebSDKMessageHandler: ShowcaseWebSDKMessageHandler,
  ) {
    this.composerService.presentation.subscribe((pres) => {
      this.presentation = pres;
    });
  }
  ngOnInit(): void {
    const subscription = combineLatest([
      this.store.select(FeatureFlagsSelectors.featureFlags),
      this.store.select(AppExtensionsSelectors.appExtensions),
    ])
      .pipe(
        map(([flags, extensions]) => {
          this.showShowroom = flags.map((x) => x.featureName).includes(Feature.SHOWROOM);
          if (flags.map((x) => x.featureName).includes(Feature.EXTENSIONS)) {
            this.registerExtensions(extensions);
          }
        }),
      )
      .subscribe();

    this.subs.push(
      this.store
        .select(FrameTemplatesSelectors.frameTemplates)
        .pipe(
          tap((frameTemplates: FrameTemplate[]) => {
            this.frameTemplates = ObjectUtil.cloneDeep(
              frameTemplates.filter(
                (template) => template.document?.size?.width === 1200 && template.document?.size?.height === 675,
              ),
            );
          }),
        )
        .subscribe(),
    );

    this.subs.push(subscription);
  }
  addAdvancedFeature(feature: string) {
    switch (feature) {
      case 'assortment-grid': {
        //this.createLineBoard();
        break;
      }
    }
  }
  registerExtensions(extensions: Array<AppExtensionDefinition>) {
    this.appExtensions = extensions.filter((x) => x.userApps.includes(AppExtensionUserApps.SHOWCASE));
  }
  launchExtension(app: AppExtensionDefinition) {
    let showcase;
    let presentationFrame;
    this.store
      .select(ShowcasesSelectors.currentShowcase)
      .pipe(
        take(1),
        tap((sc) => {
          showcase = sc;
        }),
      )
      .subscribe();
    this.composerService.selectedFrame
      .pipe(
        take(1),
        tap((frame) => {
          presentationFrame = frame;
        }),
      )
      .subscribe();

    const context = {
      showcase,
      currentFrame: presentationFrame,
      vibeIQApp: 'showcase',
    };
    this.appExtensionService.launchExtensionModal(app, this.showcaseWebSDKMessageHandler, context);
  }
  addFrame(frameType) {
    if (frameType === 'grid') {
      this.composerGridFrameService.showCreateGridFrame();
    } else if (frameType === 'showroom') {
      this.composerShowroomFrameService.showCreateShowroomFrame();
    } else if (frameType === 'iframe') {
      this.composerIframeFrameService.showCreateIframeFrame();
    } else if (frameType === 'document-generation') {
      this.composerGenerateCanvasFramesService.generateFrames();
    } else {
      this.composerService.initializeNewPresentationFrame(frameType);
    }
  }

  async createFrameFromTemplate(frameTemplate: FrameTemplate) {
    let selectedFrameTemplate: any = frameTemplate;
    this.store
      .select(FrameTemplatesSelectors.getFrameTemplateById(frameTemplate.id))
      .pipe(
        tap((frameTemplate: any) => {
          selectedFrameTemplate = ObjectUtil.cloneDeep(frameTemplate);
        }),
      )
      .subscribe();

    if (!frameTemplate.document.elements) {
      this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));
      selectedFrameTemplate = ObjectUtil.cloneDeep(
        await this.frameTemplatesService.getFrameTemplateById(frameTemplate.id),
      );
      this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
    }
    if (selectedFrameTemplate.document.style) {
      const backgroundRect = DocumentElementFactory.createShapeElement('rectangle', {
        size: { width: 1200, height: 675 },
        position: { x: 0, y: 0 },
        style: selectedFrameTemplate.document.style,
      });
      selectedFrameTemplate.document.background = [backgroundRect];
      delete selectedFrameTemplate.document.style;
    }
    selectedFrameTemplate.type = 'document';
    this.composerService.composerClipboard.pasteDocumentFrame(selectedFrameTemplate);
  }
}
