import { createSelector } from '@ngrx/store';
// State is the root state.. which includs a 'userSessions' sub-state
export const selectUserSessions = (state: any) => state.userSessions.userSessions;
export const currentSessionId = (state: any) => state.userSessions.currentSessionId;
export const syncingRemoteUsers = (state: any) => state.userSessions.syncingRemoteUsers;
export const remoteUserCurrentContext = (state: any) =>
  Object.values(state.userSessions.remoteUserCurrentContext) as Array<any>;
export const filterRemoteUsersByContext = (contextReference: string, limit?: number) =>
  createSelector(remoteUserCurrentContext, (remoteUserCurrentContext) => {
    if (remoteUserCurrentContext) {
      let filtered = remoteUserCurrentContext.filter((u) => u.contextReference === contextReference);
      if (limit && filtered?.length) {
        filtered = filtered.slice(0, limit);
      }
      return filtered;
    }
    return [];
  });
