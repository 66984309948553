<div class="header">
  <button
    data-test="create-showcase-button"
    *ngIf="canCreate"
    mat-raised-button
    color="primary"
    (click)="createShowcase()"
  >
    <mat-icon>add</mat-icon>NEW
  </button>
  <app-search-bar [lgGray]="true" [placeholder]="'Search'" class="w-72"></app-search-bar>
  <app-list-view-toggle (toggle)="setViewMode($event)" [mode]="viewMode" class="mr-auto"></app-list-view-toggle>
  <mat-slide-toggle
    labelPosition="before"
    class="mr-12 my-2"
    color="primary"
    [checked]="myShowCasesOnly.value"
    [formControl]="myShowCasesOnly"
    name="myShowCasesOnly"
  >
    <p class="text-sm">Created by me</p>
  </mat-slide-toggle>
  <div class="flex items-center">
    <div class="sort-by flex items-center cursor-pointer my-2" [matMenuTriggerFor]="sortByMenu">
      <p class="text-sm mr-2">Sort by:</p>
      <p class="text-sm text-primary">{{ sortByControl.value?.label }}</p>
    </div>
    <div class="cursor-pointer flex items-center" (click)="sortByDirection()">
      <mat-icon class="ml-1" *ngIf="(sortDirection$ | async) === 'asc'">arrow_drop_up</mat-icon>
      <mat-icon class="ml-1" *ngIf="(sortDirection$ | async) === 'desc'">arrow_drop_down</mat-icon>
    </div>
  </div>
  <mat-menu #sortByMenu="matMenu" yPosition="below" xPosition="before" class="menu-sm">
    <button mat-menu-item *ngFor="let option of sortOptions" (click)="sortBy(option)">
      {{ option?.label }}
    </button>
  </mat-menu>
</div>

<!-- <div *ngIf="recentShowcases$ && (recentShowcases$ | async).length && !templates && !archived">
  <div class="section-heading">
    Recent
  </div>
  <div class="grid">
    <app-showcase-card *ngFor="let showcase of (recentShowcases$ | async)" [showcase]="showcase"></app-showcase-card>
  </div>
</div> -->

<h6 class="w-100 border-b py-2 ml-3" *ngIf="archived || templates">
  <ng-container *ngIf="archived">Archived Showcases</ng-container>
  <ng-container *ngIf="templates">Template Showcases</ng-container>
</h6>

<div class="body p-3">
  <div *ngIf="viewMode === 'cards'" class="cards">
    <div *ngIf="viewMode === 'cards'" class="cards">
      <app-showcase-card
        *ngFor="let showcase of showcases$ | async"
        [showcase]="showcase"
        (openMenu)="showMenu({ mouseEvent: $event, listItem: showcase })"
        (dblclick)="goToShowcase(showcase)"
        (contextmenu)="handleContextMenu({ mouseEvent: $event, listItem: showcase })"
      >
      </app-showcase-card>
    </div>
  </div>
  <app-list
    *ngIf="(showcases$ | async)?.length && viewMode === 'list'"
    [listData]="showcases$ | async"
    [columnDefinitions]="columnDefinitions"
    (doubleClickRow)="goToShowcase($event)"
    (contextMenu)="handleContextMenu($event)"
  >
  </app-list>
</div>
<app-context-menu [actionDefinitions]="contextMenuActions" (selected)="handleMenuAction($event)"></app-context-menu>
<app-download-content-snack
  class="fixed bottom-6 left-6"
  [label]="'Copying...'"
  [errorMessage]="'An error occurred while attempting to copy this document. Please try again.'"
  useOnDownloadSuccess="true"
  (onDownloadSuccess)="onCopySuccess($event)"
></app-download-content-snack>
