import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ObjectUtil } from '@contrail/util';
import { PresentationFrame } from 'src/app/presentation/presentation';
import { ComposerService } from '../../../composer.service';

@Component({
  selector: 'app-composer-iframe-frame-preview',
  templateUrl: './composer-iframe-frame-preview.component.html',
  styleUrls: ['./composer-iframe-frame-preview.component.scss'],
})
export class ComposerIframeFramePreviewComponent implements OnInit {
  @Input() frame: PresentationFrame;
  public label;
  public itemCount = 0;
  public optionCount = 0;
  constructor(private composerService: ComposerService) {
    this.composerService.frameChanges.subscribe((changes) => {
      if (!changes) {
        return;
      }
      if (this.frame.id === changes.id) {
        this.frame = ObjectUtil.cloneDeep(changes);
        this.label = this.frame.name;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.frame = ObjectUtil.cloneDeep(changes.frame.currentValue);
    this.label = this.frame.name;
  }

  async ngOnInit() {
    this.label = this.frame.name;
  }
}
