import { Injectable } from '@angular/core';
import { AssortmentsSelectors } from '@common/assortments/assortments-store';
import { AuthService } from '@common/auth/auth.service';
import { ProjectItemService } from '@common/projects/project-item.service';
import { DocumentElement } from '@contrail/documents';
import { Entities, EntityReference } from '@contrail/sdk';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContextualEntityHelper {
  public constructor(
    private store: Store<RootStoreState.State>,
    private authService: AuthService,
    private projectItemService: ProjectItemService,
  ) {}
  public async getContextualEntityFromDocumentElement(
    documentElement: DocumentElement,
    fetchContentViewable?: boolean,
  ): Promise<{ entity: any; name: string; reference: EntityReference; viewableEntity?: any }> {
    /** Get reference from the element */
    let contextualEntityReference: EntityReference;
    let viewableContextualEntityReference: EntityReference;
    if (documentElement?.modelBindings?.item) {
      contextualEntityReference = new EntityReference(documentElement?.modelBindings?.item);
      if (documentElement?.modelBindings?.viewable) {
        viewableContextualEntityReference = new EntityReference(documentElement.modelBindings.viewable);
      }
    } else if (documentElement?.modelBindings?.color) {
      contextualEntityReference = new EntityReference(documentElement?.modelBindings?.color);
    } else if (documentElement?.modelBindings?.content) {
      contextualEntityReference = new EntityReference(documentElement?.modelBindings?.content);
    } else if (documentElement?.modelBindings?.image) {
      contextualEntityReference = new EntityReference(documentElement?.modelBindings?.image);
    } else {
      contextualEntityReference = null;
    }

    /** Hydrate the reference into the entity */
    let contextualEntity;
    let name;
    let viewableEntity;
    if (contextualEntityReference?.entityType === 'item') {
      this.store
        .select(AssortmentsSelectors.backingAssortmentItemData)
        .pipe(
          first(
            (backingAssortmentItemData) =>
              backingAssortmentItemData.some((ai) => ai.id === contextualEntityReference.id),
            [],
          ),
        )
        .subscribe(async (backingAssortmentItemData) => {
          const assortmentItem = backingAssortmentItemData.find((ai) => ai.id === contextualEntityReference.id);
          if (assortmentItem) {
            contextualEntity = assortmentItem?.item;
            name = contextualEntity?.name;
          }
        });
    } else if (documentElement?.modelBindings?.color) {
      contextualEntity = await new Entities().get({ entityName: 'color', id: contextualEntityReference.id });
      name = contextualEntity?.name;
    } else if (documentElement?.modelBindings?.content) {
      contextualEntity = await new Entities().get({
        entityName: 'content',
        id: contextualEntityReference.id,
        relations: ['primaryFile', 'createdBy'],
      });
      name = contextualEntity?.fileName || contextualEntity?.primaryFile?.fileName; // TODO: can we remove this.contextualEntity?.primaryFile?.fileName? @Yulia
    } else if (documentElement?.modelBindings?.image) {
      contextualEntity = await new Entities().get({ entityName: 'file', id: contextualEntityReference.id });
      name = contextualEntity?.fileName;
    } else {
      contextualEntity = null;
    }

    if (fetchContentViewable != null) {
      if (viewableContextualEntityReference?.entityType === 'item' && contextualEntity) {
        if (contextualEntity.primaryViewableId) {
          if (fetchContentViewable || !contextualEntity?.contentType) {
            //TODO: @Yulia - please add comments about use case
            viewableEntity = await new Entities().get({
              entityName: 'content',
              id: contextualEntity.primaryViewableId,
              relations: ['primaryFile', 'createdBy'],
            });
          } else {
            viewableEntity = ObjectUtil.cloneDeep(contextualEntity);
          }
        }
      } else if (viewableContextualEntityReference?.entityType === 'content') {
        //TODO: @Yulia - always `false` because we set viewableContextualEntityReference when `documentElement?.modelBindings?.item`
        viewableEntity = await new Entities().get({
          entityName: 'content',
          id: viewableContextualEntityReference.id,
          relations: ['primaryFile', 'createdBy'],
        });
      }
    }

    return {
      entity: contextualEntity,
      reference: contextualEntityReference,
      name,
      viewableEntity,
    };
  }

  public async getItemOptions(id, projectItemId) {
    const item = await new Entities().get({ entityName: 'item', id, relations: ['itemOptions'] });

    if (!projectItemId) {
      return item.itemOptions.color;
    }
    const projectId = projectItemId.split(':')[0];
    const ids = item.itemOptions.color.map((d) => projectId + ':' + d.id);
    if (ids.length > 0) {
      const projectItems = await this.projectItemService.getByIds(ids, ['project']);
      const optionIds = projectItems.map((p) => p.itemId);
      const filteredOptions = item.itemOptions.color.filter((option) => optionIds.includes(option.id));
      return filteredOptions;
    }
    return item.itemOptions.color;
  }

  public async getProjectItems(itemId) {
    let workspaces = await new Entities().get({ entityName: 'workspace', criteria: {}, relations: [] });
    let projectItems = await new Entities().get({
      entityName: 'project-item',
      criteria: { itemId },
      relations: ['project'],
    });
    projectItems = this.sortProjects(projectItems.filter((p) => p.project && !p.isInactive && !p.isProjectDropped));
    projectItems.forEach((p) => {
      p.project.workspace = workspaces.find((w) => w.projectId === p.projectId);
    });
    return projectItems;
  }

  public async getContextProjectItem(documentElement) {
    if (documentElement?.modelBindings?.projectItem) {
      const projectItemReference = new EntityReference(documentElement?.modelBindings?.projectItem);
      const projectItem = await new Entities().get({
        entityName: 'project-item',
        id: projectItemReference.id,
        relations: ['project'],
      });
      if (projectItem && !projectItem.isInactive && !projectItem.isProjectDropped) {
        return projectItem;
      }
    }
    return null;
  }

  public async getContextAssortment(documentElement) {
    if (documentElement?.modelBindings?.assortment && !documentElement?.modelBindings?.assortmentItem) {
      const assortmentReference = new EntityReference(documentElement?.modelBindings?.assortment);
      const assortment = await new Entities().get({ entityName: 'assortment', id: assortmentReference.id });
      return assortment;
    } else if (documentElement?.modelBindings?.assortmentItem) {
      const assortmentItemReference = new EntityReference(documentElement?.modelBindings?.assortmentItem);
      const assortmentItem = await new Entities().get({
        entityName: 'assortment-item',
        id: assortmentItemReference.id,
        relations: ['assortment'],
      });
      if (assortmentItem) {
        return assortmentItem.assortment;
      }
    }
  }

  private sortProjects(projects) {
    let projectSortField = 'name';
    let projectSortDirection = 'asc';
    const currentOrgConfig = this.authService.getCurrentOrg().orgConfig;
    if (currentOrgConfig?.projectSortField) {
      projectSortField = currentOrgConfig.projectSortField;
      projectSortDirection = currentOrgConfig.projectSortDirection;
    }
    let sortedProjects = projects.sort((a, b) =>
      a.project[projectSortField].localeCompare(b.project[projectSortField]),
    );
    if (projectSortDirection === 'desc') {
      sortedProjects = sortedProjects.reverse();
    }
    return sortedProjects;
  }
}
