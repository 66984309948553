import { Injectable } from '@angular/core';
import { chunk } from 'lodash';
import pLimit from 'p-limit';
import { Entities, Types } from '@contrail/sdk';
import { PropertyType } from '@contrail/types';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { tap } from 'rxjs/operators';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';

const limit = pLimit(3);

export interface ProjectItem {
  id?: string;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectItemService {
  public currentProjectId;
  constructor(private store: Store<RootStoreState.State>) {
    this.store
      .select(WorkspacesSelectors.currentWorkspace)
      .pipe(
        tap((ws) => {
          if (!ws) {
            return;
          }
          this.currentProjectId = ws.projectId;
        }),
      )
      .subscribe();
  }

  public async getProjectItem(itemId) {
    if (!this.currentProjectId) {
      return;
    }
    const id = `${this.currentProjectId}:${itemId}`;
    return new Entities().get({ entityName: 'project-item', id, relations: ['project'] });
  }

  public async getCurrentProjectItems(itemIds) {
    if (!this.currentProjectId) {
      return;
    }
    const ids = itemIds.map((itemId) => `${this.currentProjectId}:${itemId}`);
    return await this.getByIds(ids, ['project']);
  }

  public async upsertProjectItem(itemId, data) {
    if (!this.currentProjectId) {
      return;
    }
    const id = `${this.currentProjectId}:${itemId}`;
    return new Entities().update({ entityName: 'project-item', id, object: data });
  }

  public async batchUpsert(batchChanges: Array<{ id: string; changes: any }>) {
    const changes: Array<{ id: string; changes: any }> = [];
    for (const change of batchChanges) {
      const id = `${this.currentProjectId}:${change.id}`;
      changes.push({
        id,
        changes: change.changes,
      });
    }
    return new Entities().batchUpdate({ entityName: 'project-item', objects: changes });
  }

  public async carryoverProjectItems(projectItems) {
    const projectItemType = await new Types().getType({ root: 'project-item', path: 'project-item' });
    const projectItemsToCarryover = [];
    projectItems.forEach((projectItem) => {
      const newProjectItem: ProjectItem = {};
      if (projectItem.isInactive === false) {
        newProjectItem.isInactive = false;
      }
      const criteria = this.getFilterCriteria(projectItem.roles?.includes('option'));
      const projectItemPropsToCarryover = projectItemType.typeProperties.filter(
        (prop) =>
          ['carryover'].includes(prop.carryOverBehavior) &&
          (criteria.includes(prop.propertyLevel) || !prop.propertyLevel),
      );
      const projectItemPropsToDefault = projectItemType.typeProperties.filter(
        (prop) =>
          ['default'].includes(prop.carryOverBehavior) &&
          (criteria.includes(prop.propertyLevel) || !prop.propertyLevel),
      );
      projectItemPropsToCarryover.forEach((prop) => {
        if (projectItem.hasOwnProperty(prop.slug)) {
          if (
            [PropertyType.UserList, PropertyType.ObjectReference, PropertyType.TypeReference].includes(
              prop.propertyType,
            )
          ) {
            newProjectItem[prop.slug + 'Id'] = projectItem[prop.slug + 'Id']
              ? projectItem[prop.slug + 'Id']
              : projectItem[prop.slug]?.id;
          } else {
            newProjectItem[prop.slug] = projectItem[prop.slug];
          }
        }
      });
      projectItemPropsToDefault.forEach((prop) => {
        newProjectItem[prop.slug] = prop.carryOverDefault;
        if (prop.propertyType === PropertyType.Boolean) {
          newProjectItem[prop.slug] =
            prop.carryOverDefault === 'true' ? true : prop.carryOverDefault === 'false' ? false : prop.carryOverDefault;
        }
      });
      if (projectItem.projectId) {
        newProjectItem.addedFromProject = projectItem.projectId;
      }
      console.log('Carryover projectItem: ', newProjectItem);
      projectItemsToCarryover.push({ id: projectItem.itemId, changes: newProjectItem });
    });

    const createdProjectItems = await this.batchUpsert(projectItemsToCarryover);
    return createdProjectItems;
  }

  public async carryoverProjectItem(projectItem) {
    const projectItems = await this.carryoverProjectItems([projectItem]);
    if (projectItems.length > 0) {
      return projectItems[0];
    }
    return null;
  }

  private getFilterCriteria(isOption) {
    if (isOption) {
      return ['option', 'all', 'overridable'];
    }
    return ['family', 'all', 'overridable'];
  }

  public async getByIds(ids: string[], relations: string[] = []): Promise<ProjectItem[]> {
    const uniqueIds = [...new Set(ids)];
    const idChunks = chunk(uniqueIds, 100);
    const promises: Array<Promise<any>> = [];

    for (const projectItemIds of idChunks) {
      const promise = limit(async () => {
        return await new Entities().get({ entityName: 'project-item', criteria: { ids: projectItemIds }, relations });
      });
      promises.push(promise);
    }

    const projectItems = await Promise.all(promises);
    return projectItems.flat();
  }
}
