import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
@Component({
  selector: 'app-property-configurator-font-size',
  templateUrl: './property-configurator-font-size.component.html',
  styleUrls: ['./property-configurator-font-size.component.scss'],
})
export class PropertyConfiguratorFontSizeComponent implements OnChanges {
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() sizes = [
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '14',
    '16',
    '18',
    '20',
    '24',
    '30',
    '36',
    '48',
    '64',
    '72',
    '96',
    '128',
  ];
  @Input() currentValue: any;
  @Input() defaultValue: any;
  @Input() minValue = 6;
  @Input() maxValue = 999;
  @Input() autoSize = false;
  @Input() autoSizeValue = 'auto';

  defaultFontSize = 14;
  size;

  constructor() {}

  ngOnChanges() {
    console.log('size: current value: ', this.currentValue);
    this.size = this.currentValue || this.defaultValue || this.defaultFontSize;
    if (this.autoSize && (!this.autoSizeValue || this.autoSizeValue === 'auto')) {
      this.size = 'Auto';
    }
  }

  dec() {
    this.resize(-1);
  }
  inc() {
    this.resize(+1);
  }

  resize(delta: number) {
    let size = this.size === 'Auto' ? this.maxValue : +this.size;
    this.size = Math.min(this.maxValue, Math.max(this.minValue, size + delta));
    this.setFontSize(this.size);
  }
  inputResize(event) {
    const value = +event.target.value;
    if (isNaN(value)) {
      this.size = +this.size;
    } else {
      this.size = Math.min(this.maxValue, Math.max(this.minValue, value));
      this.setFontSize(this.size);
    }
  }
  matSelectResize(event) {
    if (!event.source.selected) {
      return;
    }
    if (this.autoSize && event.source.value === 'Auto') {
      this.size = event.source.value;
    } else {
      this.size = +event.source.value;
    }
    this.setFontSize(this.size);
  }

  setFontSize(size) {
    console.log('setFontSize: ', this.size);
    this.size = size;
    this.updateAllSelected();
  }

  updateAllSelected() {
    const changes = { type: this.autoSize ? 'autoFontSize' : 'fontSize', value: this.size };
    this.valueChange.emit(changes);
  }

  handleClick(event) {
    event.stopPropagation();
  }

  isDisabled(size) {
    if (this.autoSize && this.maxValue && size !== 'Auto') {
      return +size > this.maxValue;
    }
    return false;
  }
}
