import { DocumentElement, DocumentElementFactory, DocumentElementPropertyBindingHandler } from '@contrail/documents';

export class ColorComponentBuilder {
  public static async buildComponent(entity: any, options: DocumentElement): Promise<DocumentElement> {
    //console.log('options: ', options);
    const padding = 5; // DocumentColorService.padding
    const textFontSize = 8; // DocumentColorService.getDefaultTextFormat()
    const colorRectSize = 140;
    const element = DocumentElementFactory.createElement('component', options);
    element.size = {
      height: colorRectSize + padding * 2,
      width: colorRectSize + textFontSize + padding * 4,
    }; //HACK: - actually we don't use this size data - container size
    element.modelBindings = {
      color: 'color:' + entity.id,
    };
    element.elements = [
      {
        type: 'rectangle',
        position: { x: 0, y: 0 },
        size: {
          width: colorRectSize + padding * 2,
          height: colorRectSize + textFontSize + padding * 4,
        },
        style: {
          backgroundColor: '#FFFFFF',
          border: {
            width: 0,
            color: 'rgba(0,0,0,.1)',
            radius: 5,
          },
        },
      },
      {
        type: 'rectangle',
        position: { x: padding, y: padding },
        size: { height: colorRectSize, width: colorRectSize },
        style: {
          backgroundColor: entity?.hexCode,
          border: {
            width: 0,
            color: '#FFFFFF',
          },
        },
        propertyBindings: { 'style.backgroundColor': 'color.hexCode' },
      },
      {
        type: 'text',
        position: { x: padding, y: colorRectSize + padding * 2 },
        size: {
          height: textFontSize + padding, //TODO: Edge case - if color name is too long, then it will be multiple text lines.
          width: colorRectSize,
        },
        style: {
          font: {
            size: textFontSize,
          },
        },
        propertyBindings: { text: 'color.name' },
      },
    ];

    const model = {
      color: entity,
    };

    DocumentElementPropertyBindingHandler.bindPropertiesToElements(element.elements, model);

    return element;
  }
}
