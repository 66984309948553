import { Component, Input, OnDestroy } from '@angular/core';
import { ExportDownloadOptions } from '@common/exports/export-widget/export-widget.component';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { Subject, tap, takeUntil } from 'rxjs';
import { ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { ComposerPropertyPoliciesService } from '../composer-property-policies/composer-property-policies.service';
import { ComposerService } from '../composer.service';

@Component({
  selector: 'app-composer-export',
  template: `
    <app-export-widget
      label="Export"
      [options]="options"
      [selectedIds]="selectedIds"
      [buttonType]="''"
      [buttonColor]="''"
      hideFrameOptions="true"
    ></app-export-widget>
  `,
  styleUrls: ['./composer-export.component.scss'],
})
export class ComposerExportComponent implements OnDestroy {
  @Input() selectedIds: string[];
  public options: ExportDownloadOptions = {};
  private destroy$ = new Subject();

  constructor(
    private store: Store<RootStoreState.State>,
    private composerService: ComposerService,
    private composerPropertyPoliciesService: ComposerPropertyPoliciesService,
  ) {
    this.composerService.presentation.pipe(takeUntil(this.destroy$)).subscribe((presentation) => {
      if (presentation) {
        this.setOption(
          'pdf_snapshots',
          'frames',
          ObjectUtil.cloneDeep(presentation.frames)
            .filter((presentationFrame) => presentationFrame.type === 'document' && presentationFrame.document)
            .map((presentationFrame) => {
              presentationFrame.document.position = { x: 0, y: 0 };
              return presentationFrame.document;
            }),
        );
      }
    });

    this.store
      .select(ShowcasesSelectors.currentShowcase)
      .pipe(takeUntil(this.destroy$))
      .subscribe((showcase) => {
        if (showcase?.id) {
          this.setOption('pdf_snapshots', 'name', showcase.name);
          this.setOption('pdf_snapshots', 'entityReference', `showcase:${showcase.id}`);
          this.setOption(
            'pdf_snapshots',
            'restrictedViewablePropertySlugs',
            this.composerPropertyPoliciesService.restrictedViewablePropertySlugs,
          );

          this.setOption('powerpoint', 'entityReference', `showcase:${showcase.id}`);
          this.setOption('excel', 'entityReference', `showcase:${showcase.id}`);
        }
      });

    this.store
      .select(ShowcasesSelectors.showSourceAssortmentWarning)
      .pipe(takeUntil(this.destroy$))
      .pipe(
        tap((showSourceAssortmentWarning) => {
          this.setOption('powerpoint', 'showWarnings', showSourceAssortmentWarning);
        }),
      )
      .subscribe();
  }

  private setOption(type, key, value) {
    if (!this.options[type]) {
      this.options[type] = {};
    }
    this.options[type][key] = value;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
