import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AssortmentsSelectors } from 'src/app/common/assortments/assortments-store';
import { RootStoreState } from 'src/app/root-store';
import { ComposerService } from '../composer/composer.service';

interface Entity {
  id: string;
  [key: string]: any;
}
@Injectable({
  providedIn: 'root',
})
export class EntityChooserService {
  private visibleSubject: Subject<boolean> = new BehaviorSubject(false);
  public visible: Observable<boolean> = this.visibleSubject.asObservable();
  public currentData$: Observable<any>;
  constructor(
    private composerService: ComposerService,
    private store: Store<RootStoreState.State>,
  ) {
    this.init();

    this.composerService.currentFrame.subscribe((frame) => {
      // HIDE CHOOSER ON FRAME CHANGE
      this.hideChooser();
    });

    this.currentData$ = this.store.select(AssortmentsSelectors.sourceAssortmentItemData);
  }

  async init() {}

  async showChooser() {
    this.visibleSubject.next(true);
  }
  async hideChooser() {
    this.visibleSubject.next(false);
  }
}
