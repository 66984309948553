import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsService } from './analytics.service';
import { IntercomModule } from './ng-intercom';
import { environment } from '../../../environments/environment';
import { GoogleAnalyticsService } from './google-analytics';
import { IntercomService } from './intercom';
import { UserAnalyticsService } from './user-analytics.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    IntercomModule.forRoot({
      appId: environment.intercomAppId, // from your Intercom config
      updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
    }),
  ],
  providers: [AnalyticsService],
})
export class AnalyticsModule {
  constructor(
    private analyticsService: AnalyticsService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private intercomService: IntercomService,
    private userAnalyticsService: UserAnalyticsService,
  ) {
    this.analyticsService.registerService(this.googleAnalyticsService);
    this.analyticsService.registerService(this.userAnalyticsService);
    this.analyticsService.registerService(this.intercomService);
  }
}
