import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { RootStoreState } from 'src/app/root-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RemoteMouseTrackerSelectors } from '../remote-mouse-tracker-store';

export class RemoteMouseTracker {
  x: number;
  y: number;
  clientId: string;
  contextReference?: string;
  firstName: string;
  lastName: string;
  email: string;
}

@Component({
  selector: 'app-mouse-tracker',
  template: `
    <app-remote-mouse
      *ngFor="let remoteMouse of remoteUserMouseTracker$ | async; trackBy: trackByFn"
      [remoteMouse]="remoteMouse"
      [viewSize]="viewSize"
    >
    </app-remote-mouse>
  `,
  styles: [
    `
      :host {
        @apply z-[3];
      }
    `,
  ],
})
export class MouseTrackerComponent implements OnChanges {
  remoteUserMouseTracker$: Observable<Array<RemoteMouseTracker>>;

  @Input() viewSize;
  @Input() contextReference: string;

  constructor(private store: Store<RootStoreState.State>) {}

  ngOnChanges(changes): void {
    if (changes?.contextReference) {
      this.remoteUserMouseTracker$ = this.store.select(
        RemoteMouseTrackerSelectors.filterRemoteUserMouseTracker(this.contextReference),
      );
    }
  }

  trackByFn(index, session) {
    return session?.clientId;
  }
}
