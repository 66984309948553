import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DocumentElement } from '@contrail/documents';
import { MaskElementService } from 'src/app/presentation/document/action-dispatchers/mask-element/mask-element.service';
import { DocumentService } from 'src/app/presentation/document/document.service';

@Component({
  selector: 'app-composer-toolbar-mask-element',
  templateUrl: './composer-toolbar-mask-element.component.html',
  styleUrls: ['./composer-toolbar-mask-element.component.scss'],
})
export class ComposerToolbarMaskElementComponent implements OnChanges {
  @Input() elements: DocumentElement[];

  public isMasked = false;
  public isMaskAllowed = false;
  public isRemoveMaskAllowed = false;

  constructor(
    private documentService: DocumentService,
    private maskElementService: MaskElementService,
  ) {}

  ngOnChanges(): void {
    this.isMaskAllowed = this.documentService.isMaskAllowed(this.elements);
    this.isRemoveMaskAllowed = this.documentService.isRemoveMaskAllowed(this.elements);
    this.isMasked = this.elements?.length === 1 && this.elements[0].elementIds?.length > 0;
  }

  toggleMask() {
    if (this.isMasked) {
      this.maskElementService.removeMask(this.elements);
    } else {
      this.maskElementService.createMask(this.elements);
    }
    this.isMasked = !this.isMasked;
  }
}
