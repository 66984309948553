<app-mat-spinner [overlay]="true" [loading]="uploadInProgress" [message]="message"></app-mat-spinner>
<div>
  <div class="header">
    <span>Create from PDF</span>
    <div class="close-modal">
      <mat-icon (click)="handleClose()" title="Close">close</mat-icon>
    </div>
  </div>
  <div class="upload-container">
    <div *ngIf="uploadError" class="error">Uploaded document is not supported. Please upload PDF document only!</div>

    <div class="drag-space" *ngIf="!validFileSelected">
      <ngx-dropzone class="drop-zone" (change)="onSelect($event)" [multiple]="false" accept="application/pdf">
        <ngx-dropzone-label>
          <div>
            <div>
              <mat-icon class="upload-icon"> file_upload </mat-icon>
            </div>
            <div class="title">Select a PDF file to upload</div>
            <div class="subtitle">or drag and drop</div>
          </div>
        </ngx-dropzone-label>
        <!-- <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)" >
          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label> 
      </ngx-dropzone-preview> -->
      </ngx-dropzone>
    </div>

    <div class="drag-space" *ngIf="validFileSelected">
      <div class="list-file">
        <div>
          {{ fileName }}
        </div>
        <div class="delete" (click)="allowFileInput()" title="Delete File">
          <mat-icon>delete</mat-icon>
        </div>
      </div>
      <div class="submit">
        <button mat-raised-button color="primary" (click)="uploadFile()">Upload</button>
      </div>
    </div>
  </div>
</div>
