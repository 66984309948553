<form [formGroup]="form">
  <app-page-section *ngIf="role === 'ADMIN'">
    <app-page-section-header>
      <app-page-section-title title="Template Showcase"></app-page-section-title>
      <!-- <app-page-section-sub-title>Specifcy if this showcase should be available for use as a template when creating new showcases.</app-page-section-sub-title> -->
    </app-page-section-header>
    <app-page-section-body>
      <mat-slide-toggle
        class="example-margin"
        [labelPosition]="'before'"
        formControlName="isTemplate"
        (change)="save()"
      >
        Template
      </mat-slide-toggle>
    </app-page-section-body>
  </app-page-section>

  <app-page-section>
    <app-page-section-header>
      <app-page-section-title title="Showcase Creative"></app-page-section-title>
    </app-page-section-header>
    <app-page-section-body>
      <div class="imageWidget">
        <mat-form-field>
          <mat-label>Header Logo Url</mat-label>
          <input matInput type="text" formControlName="headerLogo" id="headerLogo" name="headerLogo" (blur)="save()" />
        </mat-form-field>
        <img class="headerLogo" [src]="showcase.headerLogo" />
      </div>
    </app-page-section-body>
  </app-page-section>
  <app-page-section>
    <app-page-section-header>
      <app-page-section-title title="Preferred Currency"></app-page-section-title>
      <!-- <app-page-section-sub-title>Select which currency you would like to present as part of the showcase.</app-page-section-sub-title> -->
    </app-page-section-header>
    <app-page-section-body>
      <mat-form-field appearance="standard">
        <mat-label>Currency Code</mat-label>
        <mat-select [(value)]="selectedPreferredCurrency" (selectionChange)="selectPreferredCurrency($event)">
          <mat-option [value]="currency" *ngFor="let currency of currencies">
            {{ currency }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </app-page-section-body>
  </app-page-section>

  <!-- <app-page-section>
    <app-page-section-header>
      <app-page-section-title title="Showcase Home Page"></app-page-section-title>
    </app-page-section-header>
    <app-page-section-body>
      <div class="imageWidget">
        <mat-form-field>
          <mat-label>Cover Photo Url</mat-label>
          <input matInput type="text" formControlName="coverPhoto" id="coverPhoto" name="coverPhoto" (blur)="save()" />
        </mat-form-field>
        <img class="coverPhoto" [src]="showcase.coverPhoto">
      </div>
      <div>
        <mat-form-field>
          <mat-label>Title</mat-label>
          <input matInput type="text" formControlName="introTitle" id="introTitle" name="introTitle" (blur)="save()" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="textArea">
          <mat-label>Text</mat-label>
          <textarea matInput rows="5" type="text" formControlName="introText" id="introText" name="introText" (blur)="save()"></textarea>
        </mat-form-field>
      </div>
    </app-page-section-body>
  </app-page-section> -->
</form>
