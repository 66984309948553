<div class="context-menu" #contextMenu>
  <div mat-button [matMenuTriggerFor]="menu"></div>
  <mat-menu #menu="matMenu">
    <button
      class="context-menu-button"
      *ngIf="selectedItemElements.length === 1"
      mat-menu-item
      [matMenuTriggerFor]="item"
      data-test="context-menu-item"
    >
      Item
    </button>
    <mat-menu #item="matMenu">
      <button
        class="context-menu-button"
        mat-menu-item
        (click)="launchItemDetails()"
        data-test="context-menu-view-item"
      >
        View Item
      </button>
      <button
        class="context-menu-button"
        mat-menu-item
        (click)="addItemsToClipboard()"
        data-test="context-menu-add-item-to-clipboard"
      >
        Add to clipboard
      </button>
      <button
        *ngIf="itemContextFeatureActive && itemCreationFeatureActive"
        class="context-menu-button"
        mat-menu-item
        (click)="addItemsToProject()"
        data-test="context-menu-button-item-add-to-project"
      >
        Switch / add to project
      </button>
    </mat-menu>

    <button
      class="context-menu-button"
      *ngIf="selectedItemElements.length > 1"
      mat-menu-item
      [matMenuTriggerFor]="items"
      data-test="context-menu-items"
    >
      Items
    </button>
    <mat-menu #items="matMenu">
      <button
        class="context-menu-button"
        mat-menu-item
        (click)="addItemsToClipboard()"
        data-test="context-menu-add-item-to-clipboard"
      >
        Add to clipboard
      </button>
      <button
        *ngIf="itemContextFeatureActive && itemCreationFeatureActive"
        class="context-menu-button"
        mat-menu-item
        (click)="addItemsToProject()"
        data-test="context-menu-button-item-add-to-project"
      >
        Switch / add to project
      </button>
    </mat-menu>

    <!--<button class="context-menu-button" mat-menu-item *ngIf="element?.type === 'image'" (click)="emitActionRequest('init_hot_spot')" data-test="context-menu-add-hotspot"><mat-icon>adjust</mat-icon>Add Hotspot</button>-->
    <button class="justify-between context-menu-button" mat-menu-item (click)="showComments($event)">
      Comment
      <div class="text-black60">Ctrl-M</div>
    </button>
    <button
      class="justify-between context-menu-button"
      *ngIf="element"
      (click)="emitActionRequest('copy_elements')"
      mat-menu-item
      data-test="context-menu-copy"
    >
      Copy
      <div class="text-black60">Ctrl-C</div>
    </button>
    <button
      *ngIf="element && element.type === 'text'"
      class="justify-between context-menu-button"
      mat-menu-item
      data-test="context-menu-copy-properties"
      [matMenuTriggerFor]="copyProperties"
    >
      Copy/paste as
    </button>
    <button
      class="justify-between context-menu-button"
      mat-menu-item
      data-test="context-menu-paste"
      (click)="emitActionRequest('paste_elements')"
    >
      Paste
      <div class="text-black60">Ctrl-V</div>
    </button>
    <button
      class="justify-between context-menu-button"
      *ngIf="element"
      (click)="emitActionRequest('duplicate_elements')"
      mat-menu-item
      data-test="context-menu-duplicate"
    >
      Duplicate
      <div class="text-black60">Ctrl-D</div>
    </button>
    <button
      class="justify-between context-menu-button"
      *ngIf="element"
      (click)="emitActionRequest('lock_elements')"
      mat-menu-item
      data-test="context-menu-lock"
    >
      {{ element?.isLocked ? 'Unlock' : 'Lock' }}
      <div class="text-black60">Ctrl-L</div>
    </button>
    <button
      class="justify-between context-menu-button"
      *ngIf="element"
      (click)="emitActionRequest('delete_element')"
      [disabled]="element?.isLocked"
      mat-menu-item
      data-test="context-menu-delete"
    >
      Delete
      <div class="text-black60">Del</div>
    </button>
    <!-- <button class="context-menu-button" *ngIf="!element" mat-menu-item (click)="showComposerFrameSettings()" data-test="context-menu-format-frame">Format Options</button> -->
    <!-- <button class="context-menu-button" *ngIf="!element" mat-menu-item (click)="paste()" data-test="context-menu-paste">Paste</button> -->
    <button
      *ngIf="element && selectedGroupElements?.length > 1"
      class="justify-between context-menu-button"
      mat-menu-item
      data-test="context-menu-group"
      (click)="emitActionRequest('toggle_group_elements')"
    >
      Group
      <div class="text-black60">Ctrl-G</div>
    </button>

    <button
      *ngIf="element && selectedGroupElements?.length === 1 && selectedGroupElements[0].type === 'group'"
      class="justify-between context-menu-button"
      mat-menu-item
      data-test="context-menu-group"
      (click)="emitActionRequest('toggle_group_elements')"
    >
      Ungroup
      <div class="text-black60">Ctrl-G</div>
    </button>

    <button
      *ngIf="element && isMaskAllowed"
      class="justify-between context-menu-button"
      mat-menu-item
      data-test="context-menu-create-mask"
      (click)="emitActionRequest('toggle_mask_elements')"
    >
      Mask
      <div class="text-black60">Ctrl-K</div>
    </button>

    <button
      *ngIf="element && isRemoveMaskAllowed"
      class="justify-between context-menu-button"
      mat-menu-item
      data-test="context-menu-remove-mask"
      (click)="emitActionRequest('toggle_mask_elements')"
    >
      Remove Mask
      <div class="text-black60">Ctrl-K</div>
    </button>

    <button
      class="context-menu-button"
      *ngIf="element"
      mat-menu-item
      [matMenuTriggerFor]="order"
      data-test="context-menu-order"
    >
      Order
    </button>
    <button
      class="context-menu-button"
      mat-menu-item
      [matMenuTriggerFor]="alignment"
      *ngIf="areMultipleElementsSelected(1)"
    >
      Align
    </button>
    <button
      class="context-menu-button"
      mat-menu-item
      [matMenuTriggerFor]="distribution"
      *ngIf="areMultipleElementsSelected(2)"
    >
      Distribute
    </button>
    <button
      *ngIf="element"
      class="context-menu-button"
      mat-menu-item
      [matMenuTriggerFor]="matchsize"
      [disabled]="selectedGroupElementsMatchSizeEligible?.length <= 1"
      data-test="context-menu-match-size"
    >
      Resize images
    </button>
    <button
      class="context-menu-button"
      *ngIf="!element"
      mat-menu-item
      (click)="emitActionRequest('unlock_all_elements')"
    >
      Unlock all
    </button>
  </mat-menu>

  <mat-menu #order="matMenu">
    <button
      class="context-menu-button justify-between"
      mat-menu-item
      (click)="emitActionRequest('order.bring_forward')"
    >
      Bring forward
      <div class="text-black60">Shift-PgUp</div>
    </button>
    <button
      class="context-menu-button justify-between"
      mat-menu-item
      (click)="emitActionRequest('order.bring_to_front')"
    >
      Bring to front
      <div class="text-black60">PgUp</div>
    </button>
    <button
      class="context-menu-button justify-between"
      mat-menu-item
      (click)="emitActionRequest('order.send_backward')"
    >
      Send backward
      <div class="text-black60">Shift-PgDn</div>
    </button>
    <button class="context-menu-button justify-between" mat-menu-item (click)="emitActionRequest('order.send_to_back')">
      Send to back
      <div class="text-black60">PgDn</div>
    </button>
  </mat-menu>
  <mat-menu #alignment="matMenu">
    <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.horizontal_left')">
      <mat-icon>align_horizontal_left</mat-icon>Left
    </button>
    <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.horizontal_center')">
      <mat-icon>align_horizontal_center</mat-icon>Center
    </button>
    <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.horizontal_right')">
      <mat-icon>align_horizontal_right</mat-icon>Right
    </button>
    <mat-divider></mat-divider>
    <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.vertical_top')">
      <mat-icon>align_vertical_top</mat-icon>Top
    </button>
    <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.vertical_middle')">
      <mat-icon>align_vertical_center</mat-icon>Middle
    </button>
    <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.vertical_bottom')">
      <mat-icon>align_vertical_bottom</mat-icon>Bottom
    </button>
  </mat-menu>
  <mat-menu #distribution="matMenu">
    <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('distribute.horizontal')">
      <mat-icon>horizontal_distribute</mat-icon>Horizontal
    </button>
    <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('distribute.vertical')">
      <mat-icon>vertical_distribute</mat-icon>Vertical
    </button>
  </mat-menu>
  <mat-menu #matchsize="matMenu">
    <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('match.height')">Match height</button>
    <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('match.width')">Match width</button>
  </mat-menu>
  <mat-menu #copyProperties="matMenu">
    <button
      class="context-menu-button justify-between"
      mat-menu-item
      (click)="emitActionRequest('copy_properties')"
      data-test="copy-properties"
    >
      Copy text format
      <div class="text-black60">Ctrl-Alt-C</div>
    </button>
    <button
      class="context-menu-button justify-between"
      [disabled]="!copiedProperties"
      mat-menu-item
      (click)="emitActionRequest('paste_properties')"
      data-test="paste-properties"
    >
      Paste text format
      <div class="text-black60">Ctrl-Alt-V</div>
    </button>
  </mat-menu>
</div>
