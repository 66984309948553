import { Injectable } from '@angular/core';
import { DocumentService } from '../document/document.service';
import { DocumentAction, DocumentChangeType } from '@contrail/documents';
import {
  AppExtensionMessageHandler,
  WebSDKMessage,
} from '@common/app-extensions/app-extension-message-handler.interface';
import { nanoid } from 'nanoid';
import { ComposerService } from '../composer/composer.service';

@Injectable({
  providedIn: 'root',
})
export class ShowcaseWebSDKMessageHandler implements AppExtensionMessageHandler {
  constructor(
    private documentService: DocumentService,
    private composerService: ComposerService,
  ) {}

  public async handleMessage(message: WebSDKMessage) {
    console.log('HERE handleMessage: ', message);
    const command = message.command;
    if (!command || !command.startsWith('showcase:')) {
      return;
    }
    switch (command) {
      case 'showcase:add_elements': {
        const elements = message.data;
        this.addElementsToCurrentFrame(elements);
        break;
      }
      case 'showcase:add_canvas_frame': {
        const elements = message.data;
        this.addElementsToNewFrame(elements);
        break;
      }
    }
  }

  private async addElementsToNewFrame(elements) {
    const newFrame = {
      name: '',
      type: 'document',
      document: {
        size: {
          width: 1200,
          height: 675,
        },
        id: nanoid(),
        elements,
      },
    };
    //this.getItemsFromDocumentElements(newFrame.document.elements, backingAssortmentItems);
    await this.composerService.addPresentationFrame(newFrame);
  }

  private addElementsToCurrentFrame(elements) {
    if (!elements) {
      // VALIDATE???
      return;
    }

    this.documentService.deselectAllElements();
    const actions = [];
    for (let element of elements) {
      actions.push(
        new DocumentAction(
          {
            elementId: element.id,
            changeType: DocumentChangeType.ADD_ELEMENT,
            elementData: element,
          },
          {
            elementId: element.id,
            changeType: DocumentChangeType.DELETE_ELEMENT,
            elementData: element,
          },
        ),
      );
    }
    this.documentService.handleDocumentActions(actions);
  }
}
