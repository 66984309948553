import { Component, OnDestroy, OnInit } from '@angular/core';
import { ComposerService } from '../composer.service';
import { Store } from '@ngrx/store';
import { State } from '../../../root-store/root-state';
import { combineLatest, Subscription } from 'rxjs';
import { ComposerSearchService } from '../composer-search/composer-search.service';
import { map } from 'rxjs/operators';
import { SearchReplaceActions, SearchReplaceSelectors } from '@common/search-replace/search-replace-store';
@Component({
  selector: 'app-composer-frame',
  templateUrl: './composer-frame.component.html',
  styleUrls: ['./composer-frame.component.scss'],
})
export class ComposerFrameComponent implements OnInit, OnDestroy {
  public currentFrame;
  public searchActive = false;
  searchActive$ = this.store.select(SearchReplaceSelectors.searchActive);
  searchResults$ = this.store.select(SearchReplaceSelectors.searchResults);
  subscriptions = new Subscription();
  activeSearchResultElementIndex = 0;

  constructor(
    private composerService: ComposerService,
    private store: Store<State>,
    private composerSearchService: ComposerSearchService,
  ) {
    this.subscriptions.add(
      this.composerService.currentFrame.subscribe((frame) => {
        this.currentFrame = frame;
      }),
    );

    this.subscriptions.add(
      combineLatest([
        this.store.select(SearchReplaceSelectors.searchResults),
        this.store.select(SearchReplaceSelectors.activeSearchResultElement),
      ])
        .pipe(
          map(([searchResults, activeSearchResultElement]) => {
            this.activeSearchResultElementIndex = searchResults.findIndex(
              (searchResult) =>
                searchResult.id === activeSearchResultElement?.id &&
                searchResult.frameId === activeSearchResultElement?.frameId,
            );
          }),
        )
        .subscribe(),
    );
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  searchForElements(event) {
    this.composerSearchService.search(event);
  }

  setSearchActiveElement(event) {
    this.store.dispatch(SearchReplaceActions.setActiveSearchResultElement({ activeSearchResultElement: event }));
  }

  closeSearchPanel() {
    this.store.dispatch(SearchReplaceActions.toggleSearch());
  }
}
