import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { BackingAssortmentItemActionTypes } from './backing-assortment.actions';
import { AssortmentsService } from '../../assortments.service';
import { AssortmentsActions } from '..';
import { ObjectUtil } from '@contrail/util';
import { WebSocketService } from '@common/web-socket/web-socket.service';

@Injectable()
export class BackingAssortmentEffects {
  constructor(
    private actions$: Actions,
    private assortmentService: AssortmentsService,
    private store: Store<RootStoreState.State>,
    private webSocketService: WebSocketService,
    private snackBar: MatSnackBar,
  ) {}

  loadBackingAssortmentItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BackingAssortmentItemActionTypes.LOAD_BACKING_ASSORTMENT),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(AssortmentsService.getAssortment(action.assortmentId)).pipe(
          map((assortment) => AssortmentsActions.loadBackingAssortmentSuccess({ assortment })),
          catchError((error) => observableOf(AssortmentsActions.loadBackingAssortmentFailure({ error }))),
        );
      }),
    ),
  );

  addItemsToBackingAssortment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BackingAssortmentItemActionTypes.ADD_ITEMS_TO_BACKING_ASSORTMENT),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(
          this.assortmentService.addItemsToAssortment(store.assortments.backingAssortment.id, action.itemIds),
        ).pipe(
          map((assortmentItems) => AssortmentsActions.addItemsToBackingAssortmentSuccess({ assortmentItems })),
          tap((addAction) => {
            this.webSocketService.sendSessionEvent({
              eventType: 'ADD_ASSORTMENT_ITEMS',
              changes: ObjectUtil.cloneDeep(addAction.assortmentItems),
            });
          }),
          catchError((error) => observableOf(AssortmentsActions.addItemsToBackingAssortmentFailure({ error }))),
        );
      }),
    ),
  );

  removeItemsFromBackingAssortment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BackingAssortmentItemActionTypes.REMOVE_ITEMS_FROM_BACKING_ASSORTMENT),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.assortmentService.removeItemsFromAssortment(action.itemIds)).pipe(
          switchMap(async (removedItemIds) => {
            const assortmentItemids = Object.values(store.assortments.backingAssortmentItems.entities)
              .filter((ai) => removedItemIds.includes(ai.id))
              .map((ai) => ai.id);
            return assortmentItemids;
          }),
          map((ids) => AssortmentsActions.removeItemsFromBackingAssortmentSuccess({ ids })),
          tap((removeAction) => {
            this.webSocketService.sendSessionEvent({
              eventType: 'REMOVE_ASSORTMENT_ITEMS',
              changes: ObjectUtil.cloneDeep(removeAction.ids),
            });
          }),
          catchError((error) => observableOf(AssortmentsActions.removeItemsFromBackingAssortmentFailure({ error }))),
        );
      }),
    ),
  );

  updateBackingAssortmentItems$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BackingAssortmentItemActionTypes.UPDATE_BACKING_ASSORTMENT_ITEMS),
        withLatestFrom(this.store),
        tap(([action, store]: [any, RootStoreState.State]) => {
          // update assortment item first before persisting for performance
          this.store.dispatch(
            AssortmentsActions.updateBackingAssortmentItemsSuccess({ changes: ObjectUtil.cloneDeep(action.changes) }),
          );
          try {
            this.assortmentService.updateItems(ObjectUtil.cloneDeep(action.changes));
            this.webSocketService.sendSessionEvent({
              eventType: 'UPDATE_ASSORTMENT_ITEMS',
              changes: ObjectUtil.cloneDeep(action.changes),
            });
          } catch (error) {
            this.snackBar.open(error, '', { duration: 2000 });
          }
        }),
      ),
    { dispatch: false },
  );

  syncBackingAssortmentItems$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BackingAssortmentItemActionTypes.SYNC_BACKING_ASSORTMENT_ITEMS),
        withLatestFrom(this.store),
        tap(([action, store]: [any, RootStoreState.State]) => {
          const assortmentItems = Object.values(store.assortments.backingAssortmentItems.entities);
          const affectedAssortmentItems = ObjectUtil.cloneDeep(
            assortmentItems.filter((ai) => action.id === ai.item.id || action.id === ai.item.itemFamilyId),
          );
          const changes = affectedAssortmentItems.map((assortmentItem) => {
            const changes = { ...action.changes };
            const filteredChanges =
              assortmentItem.item.id === action.id
                ? changes
                : Object.fromEntries(
                    Object.entries(changes).filter(
                      ([key]) =>
                        [
                          'contentType',
                          'fileName',
                          'largeViewableDownloadUrl',
                          'mediumLargeViewableDownloadUrl',
                          'mediumViewableDownloadUrl',
                          'primaryFileUrl',
                          'primaryViewableId',
                          'smallViewableDownloadUrl',
                          'tinyViewableDownloadUrl',
                        ].indexOf(key) === -1,
                    ),
                  );
            return { id: assortmentItem.id, changes: { item: Object.assign(assortmentItem.item, filteredChanges) } };
          });
          this.store.dispatch(
            AssortmentsActions.updateBackingAssortmentItemsSuccess({ changes: ObjectUtil.cloneDeep(changes) }),
          );

          try {
            this.webSocketService.sendSessionEvent({
              eventType: 'UPDATE_ASSORTMENT_ITEMS',
              changes: ObjectUtil.cloneDeep(changes),
            });
          } catch (error) {
            this.snackBar.open(error, '', { duration: 2000 });
          }
        }),
      ),
    { dispatch: false },
  );
}
