import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { LoadingIndicatorActions } from 'src/app/common/loading-indicator/loading-indicator-store';
import { RootStoreState } from 'src/app/root-store';

@Injectable({
  providedIn: 'root',
})
export class ItemChooserService {
  constructor(private store: Store<RootStoreState.State>) {}

  async loadItems() {
    this.store.dispatch(
      LoadingIndicatorActions.setLoading({ loading: true, message: 'Please wait. Loading Items...' }),
    );
    const data = await new Entities().get({
      entityName: 'item',
      //  criteria: { id: '57f7c0e6-5373-4fc0-a7ed-8172e9a0348c' },
      relations: ['item', 'primaryViewable', 'primaryViewable.mediumViewable', 'primaryViewable.primaryFile'],
    });
    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false, message: 'Item Load complete' }));
    return data;
  }
}
