<app-chooser
  (entitiesSelected)="handleSelection($event)"
  [entityType]="typeProperty.referencedTypeRootSlug"
  [typePath]="typeProperty.referencedTypePath"
  [showHeader]="false"
  [showFilter]="false"
  [allowCreateNew]="true"
  [context]="context"
  [allowSourceChange]="false"
  (close)="handleClose()"
>
</app-chooser>
