import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-showcase-presentation-breadcrumb',
  templateUrl: './showcase-presentation-breadcrumb.component.html',
  styleUrls: ['./showcase-presentation-breadcrumb.component.scss'],
})
export class ShowcasePresentationBreadcrumbComponent implements OnInit, OnChanges {
  @Input() breadCrumb;
  @Output() levelClicked = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {}

  goToLevel(option) {
    this.levelClicked.emit(option);
  }
}
