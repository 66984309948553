import { Injectable, OnDestroy } from '@angular/core';
import { DocumentElementEvent } from '@contrail/documents';
import { Subscription } from 'rxjs';
import { DocumentComponentService } from '../../document-component/document-component-service';
import { DocumentItemService } from '../../document-item/document-item.service';
import { DocumentService } from '../../document.service';
import { ImageAssignmentInteractionService } from './image-assignment-interaction.service';
import { ColorAssignmentOptionsService } from './color-assignment-options.component';

@Injectable({
  providedIn: 'root',
})
export class DocumentElementInteractionService implements OnDestroy {
  private eventSub: Subscription;

  constructor(
    private documentService: DocumentService,
    private imageAssignmentInteractionService: ImageAssignmentInteractionService,
    private componentService: DocumentComponentService,
    private colorAssignmentOptionsService: ColorAssignmentOptionsService,
  ) {
    this.subscribe();
  }
  ngOnDestroy(): void {
    this.unsubscribe();
  }

  subscribe() {
    this.eventSub = this.documentService.documentElementEvents.subscribe(async (event) => {
      if (!event) {
        return;
      }
      if (event.eventType === 'interaction' && event.element && event.selectedElements?.length > 0) {
        this.handleElementInteraction(event);
      } else if (event.eventType === 'file_drag' && event.data && event.element) {
        this.handleFileDragInteraction(event);
      } else if (event.eventType === 'content_drag' && event.data && event.element) {
        this.handleContentDragInteraction(event);
      }
    });
  }

  private async handleFileDragInteraction(event: DocumentElementEvent) {
    await this.imageAssignmentInteractionService.handleImageItemAssignmentFromFiles(event.element, event.data);
  }

  private async handleContentDragInteraction(event: DocumentElementEvent) {
    await this.imageAssignmentInteractionService.handleImageItemAssignmentFromContent(event.element, event.data);
  }

  private async handleElementInteraction(event: DocumentElementEvent) {
    const targetElement = event?.element;
    const selectedElement = event?.selectedElements[0];
    if (
      selectedElement?.type === 'component' &&
      selectedElement?.modelBindings?.color &&
      DocumentComponentService.hasSVGViewable(targetElement)
    ) {
      if (targetElement?.type === 'component') {
        const item = await this.componentService.getItemFromComponentElement(targetElement);

        const assignmentOption = await this.colorAssignmentOptionsService.open(item);
        if (!assignmentOption) {
          return;
        }
      }

      const color = await this.componentService.getColorFromComponentElement(selectedElement);
      if (color) {
        this.componentService.recolorAndUpdateSVGElements([targetElement], `#${color.hex}`);
      }
    } else if (DocumentItemService.isItemComponet(targetElement)) {
      const selectedElements = this.documentService
        .getSelectedExpandedElements()
        ?.filter((e) => ['component', 'frame'].indexOf(e.type));
      await this.imageAssignmentInteractionService.handleImageItemAssignment(targetElement, selectedElements);
    }
  }

  unsubscribe() {
    this.eventSub.unsubscribe();
  }
}
