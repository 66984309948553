import { Assortment } from '../showcase-details-summary/showcase-assortments.service';

interface ShowcasePresentationSection {
  value: string;
  label: string;
  children?: Array<ShowcasePresentationSection>;
  enabled: boolean;
}

export class ShowcasePresentationConfiguration {
  public itemOutlineProperty;
  public outline;
  public itemColorDisplayProperties = ['name'];
  public itemDisplayProperties = ['name'];

  constructor(private assortment: Assortment) {}
  public initialize() {
    this.outline = this.outline || []; // ALLOWS FOR SETTING AN EXISTING VALUE
    this.initializeOutlineSections();
    this.initializeItemSections();
  }

  private initializeOutlineSections() {
    const outlineValueMap = {};

    // COMPUTE DISTINCT VALUES FOR OUTLINE
    this.assortment.items.map((item) => {
      outlineValueMap[item.atts[this.itemOutlineProperty]] = true;
    });

    // ADD SECTION TO OUTLINE FOR EACH VALUE
    Object.keys(outlineValueMap).map((key) => {
      // APPENDS SECTION IF NOT ALREADY IN THE MAP
      if (!this.includesValue(key, this.outline)) {
        this.outline.push({ value: key, label: key, enabled: true });
      }
    });
  }
  private includesValue(value, section) {
    let found = false;
    section.map((s) => {
      if (s.value === value) {
        found = true;
      }
    });
    return found;
  }

  private initializeItemSections() {
    this.outline.map((section) => {
      // GET THE ITEMS THAT MATCH THIS OUTLINE VALUE
      const items = this.assortment.items
        .filter((item) => item.atts[this.itemOutlineProperty] === section.value)
        .sort((i1, i2) => {
          return i1.name.localeCompare(i2.name);
        });
      section.children = this.getItemsConfig(items, section.children);
    });
  }
  /** Generates the outline definition for a collection of items
   */
  private getItemsConfig(items, existingSection = []): Array<ShowcasePresentationSection> {
    const itemsConfig = [];
    items.forEach((item) => {
      if (this.includesValue(item.id, existingSection)) {
        return;
      }
      const itemOption: ShowcasePresentationSection = {
        value: item.id,
        label: this.getDisplayValue(item, this.itemDisplayProperties),
        enabled: true,
      };
      itemOption.children = this.getItemColorConfig(item.itemColors, itemOption.children);
      itemsConfig.push(itemOption);
    });
    return itemsConfig;
  }

  /** Generates the outline definition for a collection of items-colors
   */
  private getItemColorConfig(itemColors, existingSection = []): Array<ShowcasePresentationSection> {
    if (!itemColors) {
      return [];
    }
    const itemColorsConfig = [];
    itemColors.map((itemColor) => {
      if (this.includesValue(itemColor.id, existingSection)) {
        return;
      }
      const itemColorOption: ShowcasePresentationSection = {
        value: itemColor.id,
        label: this.getDisplayValue(itemColor, this.itemColorDisplayProperties),
        enabled: true,
      };
      itemColorsConfig.push(itemColorOption);
    });
    return itemColorsConfig;
  }

  public getDisplayValue(object, properties: Array<string>): string {
    let value = '';
    for (const key of properties) {
      if (value.length) {
        value += ' - ';
      }
      if (key.indexOf('atts') > -1) {
        value += object.atts[key.substring(5, key.length)];
      } else {
        value += object[key];
      }
    }
    return value;
  }
}
