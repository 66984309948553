import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-list-view-toggle',
  template: `
    <div class="options flex items-center">
      <img [src]="mode === 'list' ? listIconBlue : listIcon" (click)="setViewMode('list')" />
      <img [src]="mode === 'cards' ? thumbIconBlue : thumbIcon" (click)="setViewMode('cards')" />
    </div>
  `,
  styles: [
    `
      img {
        @apply w-7 h-7 object-contain cursor-pointer mr-2 hover:scale-110;
      }
    `,
  ],
})
export class ListViewToggleComponent implements OnInit {
  @Input() mode: string;
  @Output() toggle: EventEmitter<string> = new EventEmitter();
  listIcon = 'assets/images/list-view.svg';
  listIconBlue = 'assets/images/list-view-selected.svg';
  thumbIcon = 'assets/images/thumbnail-view.svg';
  thumbIconBlue = 'assets/images/thumbnail-view-selected.svg';
  constructor() {}

  ngOnInit(): void {}

  setViewMode(mode) {
    this.toggle.emit(mode);
  }
}
