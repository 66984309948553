<app-page-section>
  <app-page-section-header>
    <app-page-section-title title="Top Users"></app-page-section-title>
  </app-page-section-header>
  <app-page-section-body>
    <table stlye="width: 100%">
      <tr>
        <!-- <td class="header"></td> -->
        <td class="header">Name</td>
        <td class="header">Showcases</td>
        <td class="header">Sessions</td>
      </tr>
      <tr *ngFor="let user of users">
        <!-- <td class="data image">
          <img class="avatar" [src]="user.image" />
        </td> -->
        <td class="data name">{{ user.name }}</td>
        <td class="data showcases">{{ user.showcases }}</td>
        <td class="data sessions">{{ user.sessions }}</td>
      </tr>
    </table>
  </app-page-section-body>
</app-page-section>
