<div *ngIf="viewSize != null && showPinnedComments">
  <span
    class="!absolute flex items-center justify-center cursor-pointer w-8 h-8 bg-white rounded-tl-[50%] rounded-tr-[50%] rounded-br-[50%] rounded-bl-sm shadow-md"
    [ngClass]="{ hidden: pinnedComment.hidden }"
    matBadge="{{ pinnedComment.count }}"
    matBadgeColor="primary"
    [matBadgeSize]="'small'"
    [matBadgeHidden]="pinnedComment.count <= 1"
    *ngFor="let pinnedComment of pinnedComments$ | async; trackBy: trackByGroupKey"
    [style]="getStyle(pinnedComment.firstComment, pinnedComment.documentElement)"
    (click)="showComments(pinnedComment.firstComment, pinnedComment.documentElement)"
    data-test="canvas-pinned-comment"
    cdkDrag
    [cdkDragBoundary]="mainCanvas"
    [cdkDragDisabled]="pinnedComment.disableDrag"
    (cdkDragEnded)="cdkDragEnded($event, pinnedComment.comments)"
    (cdkDragStarted)="cdkDragStarted($event)"
  >
    <app-avatar [user]="pinnedComment.firstComment.createdBy" size="24px"></app-avatar>
  </span>
</div>
