<app-modal title="Edit section" [dialogRef]="dialogRef">
  <div>
    <mat-form-field style="width: 100%" appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput [formControl]="nameFormControl" />
    </mat-form-field>
  </div>
  <div class="actions">
    <button mat-flat-button (click)="cancel()">Cancel</button>
    <button mat-flat-button color="primary" [disabled]="!isValid()" (click)="save()">Ok</button>
  </div>
</app-modal>
