<div class="main-container">
  <div class="mx-4 pt-4 mb-2">
    <div class="flex items-center">
      <h6>{{ frame.name }}</h6>
      <div class="count">{{ countTotal }}</div>
    </div>
  </div>
  <virtual-scroller
    #scroll
    [items]="sectionElements"
    cdkDropList
    (cdkDropListDropped)="drop($event)"
    class="editor"
    cdkDropListGroup
  >
    <app-composer-grid-frame-section
      #section
      cdkDrag
      [cdkDragDisabled]="(editorMode$ | async) !== 'EDIT'"
      [cdkDragData]="element.value"
      class="list-value"
      [id]="element.value"
      [ngClass]="{ selected: (gridFrameActiveContainer$ | async)?.value === element.value }"
      [selected]="(gridFrameActiveContainer$ | async)?.value === element.value"
      *ngFor="let element of scroll.viewPortItems"
      [backingAssortmentItemData]="backingAssortmentItems$ | async"
      [section]="element"
      (updateCollectionItem)="updateCollectionItem($event)"
      (viewItemDetail)="viewItemDetail($event)"
      [itemMap]="itemMap"
      [viewDefinition]="viewDefinition"
      [annotationType]="annotationType"
      [editorMode]="editorMode$ | async"
      [annotationOptionMap]="annotationOptionMap"
      [searchResults]="searchResults"
      [activeSearchResultElement]="activeSearchResultElement"
      [frameId]="frame?.id"
    ></app-composer-grid-frame-section>
  </virtual-scroller>
</div>
