import { Injectable, OnDestroy } from '@angular/core';
import { Types } from '@contrail/sdk';
import { ViewDefinition } from '@contrail/client-views';
import { Store } from '@ngrx/store';
import { State } from 'src/app/root-store/root-state';
import { setLoading } from '@common/loading-indicator/loading-indicator-store/loading-indicator.actions';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ViewManagerService } from '@common/views/view-manager.service';
import { ComposerService } from '../../composer.service';
import { PresentationPreviewModalComponent } from '../composer-collection-frame/composer-collection-frame-presentation-preview/presentation-preview-modal.component';
import { ObjectUtil } from '@contrail/util';
import { firstValueFrom } from 'rxjs';

export const COLLECTION_VIEW_APPLICATION_SLUG = 'showcase:item_details';
export const GRID_VIEW_APPLICATION_SLUG = 'showcase:grid_frame';
@Injectable()
export class ComposerFrameLayoutService {
  // composer.module.ts -> ComposerModule -> providers

  constructor(
    private store: Store<State>,
    private composerService: ComposerService,
    private viewService: ViewManagerService,
    private dialog: MatDialog,
  ) {}

  public async getTypeProperties() {
    const type = await new Types().getType({ root: 'item', path: 'item' });
    return type.typeProperties;
  }

  public async getViewDefinitionTemplates(applicationViewSlug) {
    this.store.dispatch(setLoading({ loading: true, message: 'Please wait...' }));

    const views = await this.viewService.getViewDefinitions({ applicationViewSlug, includePrivate: true });

    let templates = views;
    templates.sort((t1, t2) => (t1.label > t2.label ? 1 : -1));
    this.store.dispatch(setLoading({ loading: false }));
    return templates;
  }

  public async getViewDefinition(contextReference, applicationViewSlug, binding = false) {
    // contextReference `showcase:showcaseID` | if frame level custom view exists `presentation-frame:presentationFrameID`
    let viewDefinition: ViewDefinition = ObjectUtil.cloneDeep(
      await this.viewService.getView(applicationViewSlug, null, contextReference),
    );
    if (!viewDefinition || !viewDefinition.contextReference) {
      // create default view for this showcase
      viewDefinition = await this.createDefaultView(contextReference, applicationViewSlug);
    } else if (binding) {
      await this.bindPropertiesToView(viewDefinition);
    }
    return viewDefinition;
  }

  private async createDefaultView(contextReference, applicationViewSlug) {
    const viewDefinition: ViewDefinition = {
      applicationViewSlug,
      contextReference,
      label: 'Default',
      viewType: 'properties_list',
      properties: [
        {
          enabled: true,
          slug: 'name',
          typeRootSlug: 'item',
          includeLabel: applicationViewSlug === COLLECTION_VIEW_APPLICATION_SLUG ? true : false,
          style: {
            font: {
              size: applicationViewSlug === COLLECTION_VIEW_APPLICATION_SLUG ? 14 : 8,
            },
          },
        },
        {
          enabled: true,
          slug: 'itemNumber',
          typeRootSlug: 'item',
          includeLabel: applicationViewSlug === COLLECTION_VIEW_APPLICATION_SLUG ? true : false,
          style: {
            font: {
              size: applicationViewSlug === COLLECTION_VIEW_APPLICATION_SLUG ? 14 : 8,
            },
          },
        },
        {
          enabled: true,
          slug: 'optionName',
          typeRootSlug: 'item',
          includeLabel: applicationViewSlug === COLLECTION_VIEW_APPLICATION_SLUG ? true : false,
          style: {
            font: {
              size: applicationViewSlug === COLLECTION_VIEW_APPLICATION_SLUG ? 14 : 8,
            },
          },
        },
      ],
    };
    return await this.createViewDefinition(viewDefinition, false);
  }

  private async bindPropertiesToView(viewDefinition: ViewDefinition, includeInactiveProperty = true) {
    const types = new Types();
    const [itemType, assortmentItemType, projectItemType] = await Promise.all([
      types.getType({ root: 'item', path: 'item' }),
      types.getType({ root: 'assortment-item', path: 'assortment-item' }),
      types.getType({ root: 'project-item', path: 'project-item' }),
    ]);

    let typeProperties = itemType.typeProperties.map((property) => {
      return {
        enabled: false,
        slug: property.slug,
        typeRootSlug: 'item',
        style: null,
        includeLabel: false,
        propertyDefinition: property,
      };
    });

    const assortmentItemProperties = assortmentItemType.typeProperties
      .filter((property) => property.slug !== 'createdOn' && property.slug !== 'updatedOn')
      .map((property) => {
        return {
          enabled: false,
          slug: property.slug,
          typeRootSlug: 'assortment-item',
          style: null,
          includeLabel: false,
          propertyDefinition: property,
        };
      });
    typeProperties = typeProperties.concat(assortmentItemProperties);

    const projectItemProperties = projectItemType.typeProperties
      .filter((property) => property.slug !== 'createdOn' && property.slug !== 'updatedOn')
      .map((property) => {
        return {
          enabled: false,
          slug: property.slug,
          typeRootSlug: 'project-item',
          style: null,
          includeLabel: false,
          propertyDefinition: property,
        };
      });
    typeProperties = typeProperties.concat(projectItemProperties);

    let existingProperties = viewDefinition.properties
      .filter((property) => typeProperties.findIndex((p) => p.slug === property.slug) > -1)
      .map((property) => {
        return {
          enabled: true,
          slug: property.slug,
          style: property.style,
          includeLabel: property.includeLabel,
          typeRootSlug: property.typeRootSlug,
          propertyDefinition: typeProperties.find((p) => p.slug === property.slug).propertyDefinition,
        };
      });

    typeProperties = typeProperties.filter(
      (property) => existingProperties.findIndex((p) => p.slug === property.slug) === -1,
    );
    if (includeInactiveProperty) {
      existingProperties = existingProperties.concat(typeProperties);
    }
    viewDefinition.properties = existingProperties;
  }

  async createViewDefinition(viewDefinition: ViewDefinition, includeInactiveProperty = true) {
    this.store.dispatch(setLoading({ loading: true, message: 'Please wait...' }));
    const newView = await this.viewService.createViewDefinition(viewDefinition);
    await this.bindPropertiesToView(newView, includeInactiveProperty);
    this.store.dispatch(setLoading({ loading: false }));
    return newView;
  }

  async updateViewDefinition(id, changes) {
    const updatedView = await this.viewService.updateViewDefinition(id, changes);
    await this.bindPropertiesToView(updatedView);
    return updatedView;
  }

  async createViewDefinitionTemplate(viewDefinition: ViewDefinition) {
    this.store.dispatch(setLoading({ loading: true, message: 'Please wait...' }));
    const newView = await this.viewService.createViewDefinition(viewDefinition);
    this.store.dispatch(setLoading({ loading: false }));
    return newView;
  }

  async updateViewDefinitionTemplate(id, changes) {
    const updatedView = await this.viewService.updateViewDefinition(id, changes);
    return updatedView;
  }

  async deleteViewDefinitionTemplate(id) {
    this.store.dispatch(setLoading({ loading: true, message: 'Please wait...' }));
    await this.viewService.deleteViewDefinition(id);
    this.store.dispatch(setLoading({ loading: false }));
  }

  async showPreview(currentItem?: any) {
    const items = (await firstValueFrom(this.composerService.currentFrame)).collection.set;
    const params = {
      items,
      currentItem,
      accessLevel: 'VIEW',
    };
    const dialogRef = this.dialog.open(PresentationPreviewModalComponent, {
      disableClose: false,
      autoFocus: true,
      width: '90vw',
      maxWidth: '90vw',
      height: '90vh',
      maxHeight: '90vh',
      data: params,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
}
