import { SortDefinition } from '../../components/sort/sort-definition';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { AssortmentUtil } from '../../assortments/assortment-util';
import { AssortmentsService } from '../../assortments/assortments.service';
import { ChooserFilterConfig, ItemDataChooserDataSource } from './item-data-chooser-data-source';
import { FilterDefinition } from '@common/types/filters/filter-definition';
import { FilterObjects } from '@contrail/filters';
import { AssortmentItem } from '@common/assortments/assortment-item';

export class AssortmentChooserDataSource extends ItemDataChooserDataSource {
  criteria: any;
  optionItemData: AssortmentItem[];
  familyItemData: AssortmentItem[];

  constructor(
    protected store: Store<RootStoreState.State>,
    protected filterConfigSubject: Observable<ChooserFilterConfig>,
    protected sortConfigSubject: Observable<SortDefinition[]>,
    protected existingItemIdsSubject: Observable<any>,
    protected showAllSubject: Observable<any>,
    protected dateFilterSubject: Observable<FilterDefinition>,
    protected assortmentId,
  ) {
    super(store, filterConfigSubject, sortConfigSubject, existingItemIdsSubject, showAllSubject, dateFilterSubject);
    this.initFilteredDataObservable();
    this.initResultsObservable();
    console.log('AssortmentChooserDataSource: CONSTRUCTOR');
    this.loadAssortment();
  }

  protected async initFilteredDataObservable() {
    console.log('AssortmentChooserDataSource: initFilteredDataObservable INIT: ', this.assortmentItemData$);
    this.filteredData$ = combineLatest([
      this.filterConfigSubject,
      this.dateFilterSubject,
      this.sortConfigSubject,
      this.assortmentItemData$,
    ]).pipe(
      switchMap(async ([filterConfig, dateFilter, sortConfig, assortmentItemData]) => {
        console.log('AssortmentChooserDataSource: filteredDataObservable');

        let data;
        const searchTerm = filterConfig?.searchTerm;
        const filterDefinition = filterConfig?.filterDefinition;
        this.criteria = filterConfig?.baseCriteria;
        // SOURCE ASSORTMENT CONSTRAINT, OPTION LEVEL
        data = assortmentItemData;
        if (this.optionItemData) {
          if (this.criteria?.roles === 'family') {
            data = this.familyItemData;
          } else {
            data = this.optionItemData;
          }
        }
        this.allUnfilteredSourceItemDataSubject.next(data); // return unfiltered data

        data = this.filterLocalItemData(
          { searchTerm, itemFamilyId: this.criteria?.itemFamilyId },
          data,
          this.searchableProperties,
        );
        if (filterDefinition?.filterCriteria) {
          data = FilterObjects.filter(data, filterDefinition.filterCriteria);
        }
        if (dateFilter) {
          data = this.filterByAssortmentItems(data, dateFilter);
        }

        console.log('AssortmentChooserDataSource: ', data);
        this.sortData(data, sortConfig);
        return data;
      }),
    );
  }

  private async loadAssortment() {
    this.loadingSubject.next(true);
    const assortment = await AssortmentsService.getAssortment(this.assortmentId, ['assortmentItems.item.itemFamily']);
    const assortmentItems = assortment.assortmentItems;
    let itemData;

    this.optionItemData = AssortmentUtil.convertAssortmentItemsToItemData(
      assortmentItems.filter((ai) => ai.item?.roles.includes('color')),
    );
    if (assortment.familyLevelItems) {
      this.familyItemData = AssortmentUtil.convertItemsToFamilyItemData(assortment.familyLevelItems);
    }
    if (this.criteria?.roles === 'family') {
      itemData = this.familyItemData;
    } else {
      itemData = this.optionItemData;
    }
    console.log('AssortmentChooserDataSource: itemData: ', itemData);
    this.allUnfilteredSourceItemDataSubject.next(itemData); // return unfiltered data
    this.assortmentSubject.next(itemData);
    this.loadingSubject.next(false);
  }
}
