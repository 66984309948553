import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NumberFormat, PropertyValueFormatter } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { ListColumnDefinition } from '../list-column-definition';
import { ListItemData } from '../list-item-data';

@Component({
  selector: 'app-list-item-cell',
  templateUrl: './list-item-cell.component.html',
  styleUrls: ['./list-item-cell.component.scss'],
})
export class ListItemCellComponent implements OnChanges {
  private propertyValueFormatter = new PropertyValueFormatter();
  @Input() listItemData: ListItemData;
  @Input() columnDefinition: ListColumnDefinition;
  public value;
  public display;
  constructor() {}

  ngOnChanges(): void {
    if (this.columnDefinition.propertyType && this.columnDefinition.index) {
      this.value = ObjectUtil.getByPath(this.listItemData, this.columnDefinition.index);
      this.display = this.propertyValueFormatter.formatValue(this.value, this.columnDefinition.propertyType, {
        format: this.columnDefinition.numberFormat?.format,
        precision: this.columnDefinition.numberFormat?.decimalPlaces,
      });
    }
  }
}
