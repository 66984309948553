import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { ObjectUtil } from '@contrail/util';
import { ComposerFramePropertyConfiguratorComponent } from '../composer-frame-property-configurator/composer-frame-property-configurator.component';
import { COLLECTION_VIEW_APPLICATION_SLUG } from '../composer-frame-layout.service';

@Component({
  selector: 'app-composer-frame-property-selector',
  templateUrl: './composer-frame-property-selector.component.html',
  styleUrls: ['./composer-frame-property-selector.component.scss'],
})
export class ComposerFramePropertySelectorComponent implements OnInit, OnChanges {
  control = new UntypedFormControl();
  filteredProperties$: Observable<any[]>;
  selectorProperties: any[] = [];
  selectedProperties: any[] = [];
  private selectedProperty: any;
  @Input() typeProperties: any[] = [];
  @Input() applicationViewSlug: string;
  @Output() propertiesChangedEvent = new EventEmitter();
  @ViewChild('propertyWidget') propertyWidgetElement: ComposerFramePropertyConfiguratorComponent;
  @ViewChild('propertyInput') propertyInputElement: ElementRef;
  constructor() {}

  ngOnInit(): void {
    this.initProperties();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initProperties();
  }

  initProperties() {
    this.control = new UntypedFormControl();
    this.getProperties();
    this.control.valueChanges.forEach((value) => {
      this.filteredProperties$ = new Observable((observer) => {
        observer.next(this._filter(value));
        observer.complete();
      });
    });
  }

  async getProperties() {
    this.selectorProperties = ObjectUtil.cloneDeep(this.typeProperties);
    this.selectedProperties = this.selectorProperties.filter((property) => property.enabled);
    this.setFilteredProperties();
  }

  showPropertyWidget(property, event) {
    this.selectedProperty = property;
    const renderedEvent = {
      property,
      renderedPosition: {
        x: event.x,
        y: event.y,
      },
      element: null,
    };
    this.propertyWidgetElement.show(renderedEvent);
    event.stopPropagation();
  }

  hidePropertyWidget() {
    this.selectedProperty = null;
    if (this.propertyWidgetElement) {
      this.propertyWidgetElement.hide();
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedProperties, event.previousIndex, event.currentIndex);
    this.updateProperties();
  }

  updateTextProperties(values) {
    ObjectUtil.mergeDeep(this.selectedProperty, values);
    this.updateProperties();
  }

  private updateProperties() {
    this.propertiesChangedEvent.emit(this.selectedProperties);
  }

  getActiveColor(element) {
    return element === this.selectedProperty ? 'accent' : '';
  }

  addProperty(property) {
    property.enabled = true;
    property.includeLabel = this.applicationViewSlug === COLLECTION_VIEW_APPLICATION_SLUG ? true : false;
    property.style = {
      font: {
        size: this.applicationViewSlug === COLLECTION_VIEW_APPLICATION_SLUG ? 14 : 8,
      },
    };
    this.selectedProperties.push(property);
    this.updateProperties();
    this.control.setValue('');
    setTimeout(() => {
      this.propertyInputElement.nativeElement.blur();
    }, 10);
    this.setFilteredProperties();
  }

  removeProperty(property) {
    const index = this.selectedProperties.findIndex((selectedProp) => selectedProp.slug === property.slug);
    property.enabled = false;
    this.selectedProperties.splice(index, 1);
    this.setFilteredProperties();
    this.updateProperties();
  }

  toggleLabel(property) {
    if (property.includeLabel) {
      property.includeLabel = false;
    } else {
      property.includeLabel = true;
    }
    this.updateProperties();
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.selectorProperties.filter(
      (property) => this._normalizeValue(property.propertyDefinition.label).includes(filterValue) && !property.enabled,
    );
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  private setFilteredProperties() {
    this.filteredProperties$ = new Observable((observer) => {
      observer.next(
        this.selectorProperties
          .filter((property) => !property.enabled)
          .sort((a, b) => {
            if (a.propertyDefinition.label < b.propertyDefinition.label) {
              return -1;
            } else if (a.propertyDefinition.label > b.propertyDefinition.label) {
              return 1;
            } else {
              return 0;
            }
          }),
      );
      observer.complete();
    });
  }

  trackByFn(index, property) {
    return property?.displayName;
  }

  public openPanel(e) {
    e.stopPropagation();
    this.propertyInputElement.nativeElement.focus();
  }
}
