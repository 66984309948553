<div class="header">
  <div class="title">Alerts</div>
  <div (click)="handleClose()"><mat-icon>close</mat-icon></div>
</div>
<div class="m-2">
  <app-search-bar data-test="alerts-search-bar" [placeholder]="'Search alerts'"></app-search-bar>
</div>
<div
  class="flex items-center border-b py-[10px] px-[12px] text-[12px]"
  [ngClass]="{ 'justify-between': showWarningToggle, 'justify-end': !showWarningToggle }"
>
  <mat-slide-toggle
    *ngIf="showWarningToggle"
    color="primary"
    labelPosition="before"
    [checked]="showWarning"
    (change)="toggleShowWarning($event)"
    data-test="toggle-source-assortment-warning-button"
  >
    Show alerts on item cards
  </mat-slide-toggle>
  <div class="count">
    {{ ((filteredAlerts$ | async)?.length | number) || 0 }}
  </div>
</div>

<div class="warning-message-holder narrow-scroll">
  <div class="message" *ngFor="let message of filteredAlerts$ | async" (click)="handleOnClick(message)">
    <div class="flex items-center mb-[11px]">
      <mat-icon *ngIf="warningTypes.includes(message.type)">warning</mat-icon>
      <span class="font-medium">{{ message.entityName }}</span>
    </div>
    <div class="text">{{ message.message }}</div>
  </div>

  <app-empty-placeholder
    *ngIf="messages?.length === 0"
    [title]="title"
    [icon]="icon"
    [footer]="footer"
    [actionLabel]="actionLabel"
  ></app-empty-placeholder>
</div>
