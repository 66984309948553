<app-composer-svg-frame *ngIf="currentFrame?.type === 'document'" [frame]="currentFrame"></app-composer-svg-frame>
<app-composer-collection-frame
  *ngIf="currentFrame?.type === 'collection'"
  [frame]="currentFrame"
></app-composer-collection-frame>
<app-composer-grid-frame *ngIf="currentFrame?.type === 'grid'" [frame]="currentFrame"></app-composer-grid-frame>
<app-composer-showroom-frame
  *ngIf="currentFrame?.type === 'showroom'"
  [frame]="currentFrame"
></app-composer-showroom-frame>
<app-composer-iframe-frame *ngIf="currentFrame?.type === 'iframe'" [frame]="currentFrame"></app-composer-iframe-frame>
<app-text-hyperlink *ngIf="currentFrame?.type === 'document'"></app-text-hyperlink>

<app-search-replace
  *ngIf="searchActive$ | async"
  class="search-replace"
  [searchReplaceElements]="searchResults$ | async"
  [currentElementIndex]="activeSearchResultElementIndex"
  (searchHandler)="searchForElements($event)"
  (setActiveElement)="setSearchActiveElement($event)"
  (closePanel)="closeSearchPanel()"
></app-search-replace>
