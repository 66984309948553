import { CommonModule } from '@angular/common';
import { Component, Inject, Injectable } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Item } from '@common/items/item';

interface ColorAssignmentOptionsData {
  item: Item;
}

@Injectable({
  providedIn: 'root',
})
export class ColorAssignmentOptionsService {
  constructor(public dialog: MatDialog) {}

  public open(item: Item): Promise<any> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(ColorAssignmentOptionsComponent, {
        data: { item },
      });
      dialogRef.afterClosed().subscribe((value) => {
        resolve(value);
      });
    });
  }

  public close() {
    this.dialog?.closeAll();
  }
}

@Component({
  standalone: true,
  selector: 'app-color-assignment-options',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatRadioModule,
    MatIconModule,
    MatProgressBarModule,
    MatButtonModule,
  ],
  template: `
    <div class="modal-container mb-2 flex flex-col" style="min-width: 400px;">
      <div class="flex justify-between">
        <h6 data-test="app-confirmation-box-title">Assign Color</h6>
        <mat-icon [mat-dialog-close]="false" class="cursor-pointer -mr-2 ml-3">close</mat-icon>
      </div>

      <div class="my-5">
        <div class="font-bold">{{ item.name }}</div>
        <div *ngIf="item.optionName">{{ item.optionName }}</div>
      </div>

      <div class="my-5">
        <span>This will recolor the item's primary content, do you want to continue?</span>
      </div>

      <div mat-dialog-actions class="flex items-center justify-end mt-3">
        <button
          data-test="app-confirmation-box-cancel-button"
          mat-stroked-button
          color="primary"
          [mat-dialog-close]="false"
          class="!mr-3"
        >
          Cancel
        </button>
        <button
          data-test="app-confirmation-box-ok-button"
          mat-raised-button
          color="primary"
          (click)="complete()"
          cdkFocusInitial
        >
          OK
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .modal-container {
        max-width: 600px;
      }
    `,
  ],
})
export class ColorAssignmentOptionsComponent {
  public item;
  constructor(
    public dialogRef: MatDialogRef<ColorAssignmentOptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ColorAssignmentOptionsData,
  ) {
    this.item = data.item;
  }

  public complete() {
    const event = {
      priorPrimaryViewableId: this.item.primaryViewableId,
      item: this.item,
    };
    this.dialogRef.close(event);
  }
}
