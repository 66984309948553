import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowcasesComponent } from './showcases.component';
import { ShowcasesRoutingModule } from './showcase-routing.module';
import { ComponentsModule } from '../common/components/components.module';
import { ShowcasesListComponent } from './showcases-list/showcases-list.component';
import { ShowcaseCardComponent } from './showcases-list/showcase-card/showcase-card.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { CreateShowcaseModalLauncher } from './create-showcase/create-showcase-modal-launcher';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CreateShowcaseFormComponent } from './create-showcase/create-showcase-form/create-showcase-form.component';
import { CreateShowcaseModalComponent } from './create-showcase/create-showcase-modal/create-showcase-modal.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ShareShowcaseComponent } from './share-showcase/share-showcase.component';
import { ShareShowcaseModalComponent } from './share-showcase/share-showcase-modal.component';
import { ShareShowcaseModalLauncher } from './share-showcase/share-showcase-modal-launcher';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ShowcaseAnalyticsModule } from '../showcase-analytics/showcase-analytics.module';
import { AssortmentsModule } from '../common/assortments/assortments.module';
import { ListModule } from '../common/components/list/list.module';
import { ContextMenuModule } from '../common/components/context-menu/context-menu.module';
import { ExportsModule } from '@common/exports/exports.module';
import { LegacyLinksComponent } from './share-showcase/components/legacy-links/legacy-links.component';
import { WorkspacePrincipalsComponent } from './share-showcase/components/workspace-principals/workspace-principals.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { PipesModule } from '../common/pipes/pipes.module';

@NgModule({
  declarations: [
    ShowcasesComponent,
    ShowcasesListComponent,
    ShowcaseCardComponent,
    CreateShowcaseFormComponent,
    CreateShowcaseModalComponent,
    ShareShowcaseComponent,
    ShareShowcaseModalComponent,
    ShareShowcaseComponent,
    LegacyLinksComponent,
    WorkspacePrincipalsComponent,
  ],
  imports: [
    CommonModule,
    ShowcasesRoutingModule,
    ComponentsModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatSelectModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    ClipboardModule,
    ShowcaseAnalyticsModule,
    AssortmentsModule,
    ListModule,
    ContextMenuModule,
    ExportsModule,
    MatTableModule,
    PipesModule,
  ],
  providers: [CreateShowcaseModalLauncher, ShareShowcaseModalLauncher],
  exports: [ShowcasesComponent],
})
export class ShowcasesModule {}
