<div class="w-full h-full flex flex-col" data-test="component-editor-component">
  <div class="header !mb-0">
    <div class="title">Format Options</div>
    <mat-icon (click)="hide()">close</mat-icon>
  </div>
  <!-- <app-component-editor-preview *ngIf="currentComponentElement.type==='component'"></app-component-editor-preview> -->

  <div class="overflow-y-hidden overflow-x-hidden grow" *ngIf="currentComponentElement?.type === 'component'">
    <mat-expansion-panel class="mat-elevation-z0" expanded>
      <mat-expansion-panel-header>
        <mat-panel-title class="text-sm font-normal"> Presets </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mt-[-12px] mb-[-18px]">
        <app-property-view-definition-builder
          *ngIf="viewDefinitionApplicationViewSlug"
          #viewDefinitionBuilder
          (propertiesChangedEvent)="handleComponentPropertyChange($event)"
          [viewId]="viewId"
          [viewDefinitionApplicationViewSlug]="viewDefinitionApplicationViewSlug"
          [selectedProperties]="elements"
          [componentStyle]="currentComponentElement.style"
        />
      </div>
    </mat-expansion-panel>
    <mat-divider></mat-divider>

    <div class="properties">
      <mat-expansion-panel class="mat-elevation-z0" expanded>
        <mat-expansion-panel-header>
          <mat-panel-title class="text-sm font-normal"> Thumbnail </mat-panel-title>
        </mat-expansion-panel-header>
        <app-component-editor-thumbnail
          (componentChangedEvent)="syncComponent($event)"
          (propertiesChangedEvent)="syncProperties($event)"
          [multipleElements]="multipleElements"
        />
      </mat-expansion-panel>
      <mat-divider></mat-divider>

      <mat-expansion-panel class="mat-elevation-z0" expanded>
        <mat-expansion-panel-header>
          <mat-panel-title class="text-sm font-normal"> Properties </mat-panel-title>
        </mat-expansion-panel-header>
        <app-component-editor-property-selector
          #propertySelector
          (addProp)="addProperty = true"
          (propertiesChangedEvent)="syncProperties($event, 'properties')"
        />
      </mat-expansion-panel>
      <mat-divider></mat-divider>

      <mat-expansion-panel class="mat-elevation-z0" expanded>
        <mat-expansion-panel-header>
          <mat-panel-title class="text-sm font-normal"> Font </mat-panel-title>
        </mat-expansion-panel-header>
        <app-component-editor-font
          (clear)="fontClear($event)"
          (fontChanged)="fontChanged($event)"
          (propertiesChangedEvent)="syncProperties($event)"
        />
      </mat-expansion-panel>
      <mat-divider></mat-divider>

      <mat-expansion-panel class="mat-elevation-z0" expanded>
        <mat-expansion-panel-header>
          <mat-panel-title class="text-sm font-normal"> Text Options </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="flex-between-center text-black60">
          <span>Alignment</span>
          <app-property-configurator-text-alignment
            (valueChange)="updateTextProperties($event)"
            [currentValue]="getCurrentValue('style.text.align')"
            [layout]="'list'"
          />
        </div>
      </mat-expansion-panel>
      <mat-divider></mat-divider>

      <mat-expansion-panel class="mat-elevation-z0" expanded>
        <mat-expansion-panel-header>
          <mat-panel-title class="text-sm font-normal"> Border </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="flex text-black60">
          <app-property-configurator-color-picker
            class="basis-1/6"
            matTooltip="Border color"
            (valueChange)="updateComponentBorder($event)"
            [currentValue]="currentComponentElement.style?.border?.color"
            [matIconType]="'border_color'"
            [colorType]="'borderColor'"
          />
          <app-property-configurator-border-size
            class="basis-1/6"
            matTooltip="Border weight"
            (valueChange)="updateComponentBorder($event)"
            [currentValue]="currentComponentElement.style?.border?.width"
          />
          <app-property-configurator-border-style
            class="basis-1/6"
            matTooltip="Border style"
            (valueChange)="updateComponentBorder($event)"
            [currentValue]="currentComponentElement.style?.border?.style"
          />
        </div>
      </mat-expansion-panel>
    </div>
  </div>

  <div class="border-t border-t-solid border-t-neutral-200 flex-center p-3 gap-5">
    <button mat-raised-button color="primary" (click)="createPreset()" data-test="component-editor-save-preset">
      SAVE AS PRESET
    </button>
    <button mat-raised-button color="primary" (click)="applyChangesToAll()" data-test="component-editor-apply-all">
      APPLY TO ALL
    </button>
  </div>
</div>
