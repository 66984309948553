import { DOCUMENT as HtmlDocument } from '@angular/common';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { AppExtensionsActions } from '@common/app-extensions/app-extensions-store';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { FeatureFlagActions } from '@common/feature-flags';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ShowcasesActions } from 'src/app/showcases/showcases-store';
import { State } from '../../root-store/root-state';
import { DocumentService } from '../document/document.service';
import { KeyboardHandler } from '../handlers/key-handler';
import { PasteHandler } from '../handlers/paste-handler';
import { PresentationFrame } from '../presentation';
import { AnnotationLoader } from './annotations-loader';
import { ComposerService } from './composer.service';
import { Intercom } from '@common/analytics/ng-intercom';
import { DocumentSelectors } from '../document/document-store';
import { DocumentViewSize } from '../document/document-store/document.state';
import { CustomFontsLoader } from './custom-fonts-loader';
import { ComponentCanDeactivate } from '@common/can-deactivate/component-can-deactivate';

@Component({
  selector: 'app-composer',
  templateUrl: './composer.component.html',
  styleUrls: ['./composer.component.scss'],
})
export class ComposerComponent extends ComponentCanDeactivate {
  @ViewChild('composerFrame', { read: ElementRef }) composerFrameElementRef: ElementRef;

  public currentFrame: PresentationFrame;
  public editorMode: EditorMode;
  public viewSize: DocumentViewSize;
  public contextReference: string;

  private subscriptions = new Subscription();
  constructor(
    private annotationLoader: AnnotationLoader,
    private composerService: ComposerService,
    private documentService: DocumentService,
    private keyBoardHandler: KeyboardHandler,
    private pasteHandler: PasteHandler,
    private customFontsLoader: CustomFontsLoader,
    private intercom: Intercom,
    private store: Store<State>,
    @Inject(HtmlDocument) private htmlDocument: HTMLDocument,
  ) {
    super();

    this.subscriptions.add(
      composerService.currentFrame.subscribe((frame) => {
        this.currentFrame = frame;
        this.contextReference = `presentation-frame:${this.currentFrame?.id}`;
        // set annotationMode to select when a frame is changed
        this.store.dispatch(ShowcasesActions.setAnnotationType({ annotationType: null }));
        /* Not sure if this is still needed
      const interactionMode = this.documentService.getInteractionMode();
      if (!['paint_format', 'paint_format_hold'].includes(interactionMode)) {
        //this.documentService.setInteractionMode('select');
      }*/
      }),
    );
    this.subscriptions.add(
      this.annotationLoader.loaded.subscribe((annotationLoaded) => {
        if (annotationLoaded) {
          this.documentService.annotationOptions = AnnotationLoader.annotations;
        }
      }),
    );

    this.subscriptions.add(
      this.store.select(EditorModeSelectors.editorMode).subscribe((mode) => (this.editorMode = mode)),
    );

    this.subscriptions.add(
      this.store.select(DocumentSelectors.viewSize).subscribe((viewSize) => {
        this.viewSize = viewSize;
      }),
    );
    /* Not sure why this is here
    this.subscriptions.add(this.documentService.documentElementEvents.subscribe(event => {
      if (event?.eventType === 'selected' || event?.eventType === 'deselect') {
        this.resetSelectedFrame();
      }
    }));
    */

    this.intercom.update({
      // hide the intercom
      hide_default_launcher: true,
    });
  }

  /**
   * Checks if there are unfinished file upload promises and warns the user about
   * unsaved changes
   * @returns
   */
  showConfirmDialog(): any {
    return this.documentService?.fileHandler?.showConfirmDialog();
  }

  ngOnInit(): void {
    this.store.dispatch(AppExtensionsActions.loadAppExtensions());
    this.store.dispatch(FeatureFlagActions.loadFeatureFlags());

    this.htmlDocument.body.classList.add('overscroll-none'); // avoid showing white space on scroll and disable default browser scroll behavior (for ex. scrolling right to go back a page on Chrome)
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      this.htmlDocument.documentElement.classList.add('overflow-hidden');
      this.htmlDocument.documentElement.classList.add('touch-none');
      this.htmlDocument.body.classList.add('overflow-hidden');
      this.htmlDocument.body.classList.add('touch-none');
    }
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.htmlDocument.body.classList.remove('overscroll-none');
    this.htmlDocument.documentElement.classList.remove('overflow-hidden');
    this.htmlDocument.body.classList.remove('overflow-auto');
  }

  ngAfterViewInit(): void {}

  resetSelectedFrame() {
    this.composerService.resetSelectedFrame();
    this.composerService.resetSelectedPlaceholderFrame();
  }
}
