import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ItemData } from 'src/app/common/item-data/item-data';

@Component({
  selector: 'app-item-properties-panel',
  templateUrl: './item-properties-panel.component.html',
  styleUrls: ['./item-properties-panel.component.scss'],
})
export class ItemPropertiesPanelComponent implements OnInit, OnChanges {
  @Input() viewDefinition: any;
  @Input() itemData: ItemData;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    console.log('ItemPropertiesPanelComponent: changes: ', this.viewDefinition);
  }
}
