import { Component, Input, ViewChild } from '@angular/core';
import { ComposerService } from '../../composer.service';
import { PresentationFrame } from '../../../presentation';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';

@Component({
  selector: 'app-composer-frame-placeholder',
  templateUrl: './composer-frame-placeholder.component.html',
  styleUrls: ['./composer-frame-placeholder.component.scss'],
})
export class ComposerFramePlaceholderComponent {
  @Input() frame: PresentationFrame;
  @Input() selectedPlaceholderFrame: PresentationFrame;
  public currentFrame;
  public selectedFrame;
  constructor(private composerService: ComposerService) {}

  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };
  onContextMenu(event: MouseEvent, frame: PresentationFrame) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { frame: frame };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  pasteFrame() {
    this.composerService.composerClipboard.pasteFrame();
  }
}
