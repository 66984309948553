import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  OnChanges,
  EventEmitter,
  HostListener,
  Output,
  Directive,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { AppNotificationsService } from '@common/app-notifications/app-notifications.service';
import { ContentHolderService } from '@common/content/content-holder.service';
import { Subject } from 'rxjs';
import { TextureTransform } from '../material/texture-transform';
import { ImageUtils } from '../util/image-utils';
import { WebGLViewer } from '../webgl-viewer/webgl-viewer';
import { pairwise, takeUntil } from 'rxjs/operators';
import { ContentService } from '@common/content/content.service';

@Component({
  selector: 'app-webgl-overlay-configurator',
  templateUrl: './webgl-overlay-configurator.component.html',
  styleUrls: ['./webgl-overlay-configurator.component.scss'],
})
export class WebglOverlayConfiguratorComponent implements OnInit, OnChanges, OnDestroy {
  private destroy$ = new Subject();

  @Input() content;
  @Input() type: 'image' | 'pattern';
  @Input() webglViewer: WebGLViewer;
  overlayFile: any = null;
  public overlayForm: UntypedFormGroup = new UntypedFormGroup({});

  constructor(
    private fb: UntypedFormBuilder,
    private sanitizer: DomSanitizer,
    private contentHolderService: ContentHolderService,
    private notificationService: AppNotificationsService,
    private contentService: ContentService,
  ) {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.init();
  }

  init() {
    this.overlayForm = this.fb.group({
      scale: [1, [Validators.required]],
      rotate: [0, [Validators.required]],
      offsetX: [0, [Validators.required]],
      offsetY: [0, [Validators.required]],
    });

    this.overlayForm.valueChanges.pipe(pairwise(), takeUntil(this.destroy$)).subscribe(([prev, next]) => {
      if (this.type == 'pattern') {
        let textureTransform = new TextureTransform(next.offsetX, next.offsetY, next.rotate, 1 / next.scale);

        this.webglViewer.setAssetTextureTransform(textureTransform);
      } else if (this.type == 'image') {
        if (prev.scale != next.scale) {
          this.webglViewer.updateDecalScale(next.scale);
        }
        if (prev.rotate != next.rotate) {
          this.webglViewer.updateDecalAngle(next.rotate);
        }
      }
    });

    this.overlayFile = null;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  async handleFileSelection(files) {
    const file = files[0];
    if (file?.type !== 'image/png') {
      this.notificationService.showConfirmationMessage('We only support PNG file now.');
      return;
    }

    this.overlayFile = file;
    this.overlayFile.fileUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));

    let fileStr = await ImageUtils.fileToBase64(this.overlayFile);

    if (this.type == 'pattern') {
      // Texture replacement
      this.webglViewer.setAssetTexture(fileStr);
    } else if (this.type == 'image') {
      // Decal placement
      this.webglViewer.startDecalPlacement(fileStr);
    }

    this.resetForm();
  }

  handleFileCleared() {
    this.overlayFile = null;

    this.resetForm();

    if (this.type == 'pattern') {
      this.webglViewer.resetAssetTextures();
    } else if (this.type == 'image') {
      this.webglViewer.deleteDecal();
    }
  }

  async generateVariant() {
    const blob = await this.webglViewer.takeScreenshot();

    const glbName = this.content.primaryFile.fileName?.split('.')[0];
    const fileName = `${glbName}.png`;
    const response = await fetch(blob);
    const blobFile = await response.blob();
    const file = new File([blobFile], fileName, { type: 'image/png' });
    this.contentService.contentColorVariant$.next(file);
    return;
  }

  resetForm() {
    this.overlayForm.patchValue({
      scale: 1,
      rotate: 0,
      offsetX: 0,
      offsetY: 0,
    });
  }
}

@Directive({
  selector: '[dragFile]',
})
export class DragFileDirective {
  @Output() dragAction: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  @HostListener('dragover', ['$event']) async onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
  }
  @HostListener('drop', ['$event']) async onDrop(event) {
    event.stopPropagation();
    event.preventDefault();
    if (event.dataTransfer?.files?.length) {
      const files: FileList = event.dataTransfer?.files;
      this.dragAction.emit(files);
    }
  }
}
