import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Item } from '@common/items/item';

@Component({
  selector: 'app-showroom-item-modal',
  templateUrl: './showroom-item-modal.component.html',
  styleUrls: ['./showroom-item-modal.component.scss'],
})
export class ShowroomItemModalComponent implements OnInit {
  sourceAssortment: any;
  item: Item;
  contents: any[];
  selectedContent: any;

  isImage: boolean;
  is3d: boolean;
  extension: string;
  downloadUrl: string;
  contentIdx: number = -2;

  constructor(
    public dialogRef: MatDialogRef<ShowroomItemModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.sourceAssortment = this.data?.target;

    this.contents = this.data?.target?.item?.content;
    this.item = this.data?.target?.item;
    this._getSelectedContentIndex();
    this.setSelectedContent(this.contentIdx);
  }

  _getSelectedContentIndex() {
    const search = (content) => content?.primaryFile?.ownedByReference?.split(':')[1] === this.item.primaryViewableId;
    this.contentIdx = this.contents?.findIndex(search);
    console.log('modal data ---', this.contentIdx, this.sourceAssortment);
  }

  setSelectedContent(index?: number) {
    if (index !== this.contentIdx) {
      this.contentIdx = index;
    }
    this.selectedContent = this.contents[this.contentIdx];

    this.isImage = this.selectedContent?.primaryFile?.contentType?.indexOf('image') > -1;
    this.downloadUrl =
      this.selectedContent?.largeViewable?.downloadUrl || this.selectedContent?.primaryFile?.downloadUrl;
    if (!this.isImage) {
      this.is3d = this.selectedContent?.primaryFile?.contentType?.indexOf('gltf-binary') > -1;
      this.downloadUrl = this.selectedContent?.primaryFile.downloadUrl;
    } else {
      this.is3d = false;
    }
  }

  imageOr3d(content) {
    if (content?.primaryFile?.contentType?.indexOf('image') > -1) {
      return 'image';
    } else if (content?.primaryFile?.contentType?.indexOf('gltf-binary') > -1) {
      return '3d';
    } else {
      return false;
    }
  }
}
