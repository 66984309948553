import { Injectable, OnDestroy } from '@angular/core';
import { DocumentAction, DocumentElement, DocumentElementPropertyBindingHandler } from '@contrail/documents';
import { Types } from '@contrail/sdk';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { setLoading } from 'src/app/common/loading-indicator/loading-indicator-store/loading-indicator.actions';
import { Store } from '@ngrx/store';
import { DocumentService } from '../document.service';
import { State } from 'src/app/root-store/root-state';
import { ObjectUtil } from '@contrail/util';
import {
  DocumentComponentModelBinding,
  DocumentComponentService,
} from '../document-component/document-component-service';
import { SideMenuOverlay } from '../document-store/document.state';
import { DocumentActions } from '../document-store';
import { DocumentItemService } from '../document-item/document-item.service';
import { DocumentColorService } from '../document-color/document-color.service';
import { ANNOTATION_IMG_SIZE } from '../document-annotation/document-annotation-service';
import { CanvasUtil } from '../../canvas-lib';
import pLimit from 'p-limit';
import { ConfirmationBoxService } from '@components/confirmation-box/confirmation-box';
import { AssortmentsActions, AssortmentsSelectors } from '@common/assortments/assortments-store';
const limit = pLimit(3);
const ptToPx = {
  '4': '6',
  '5': '7',
  '6': '8',
  '7': '9',
  '7.5': '10',
  '8': '10.5',
  '8.5': '11',
  '9': '12',
  '10': '13',
  '10.5': '14',
  '11': '14.5',
  '12': '16',
  '13': '18',
  '14': '18.5',
  '15': '20',
  '16': '21.5',
  '18': '24',
  '20': '26.5',
  '22.5': '30',
  '24': '32',
  '27': '36',
  '30': '40',
  '35': '47.5',
  '36': '48',
  '47': '63.5',
  '48': '64',
  '54': '72',
  '64': '85',
  '72': '96',
};

const pxToPt = {
  '6': '4',
  '7': '5',
  '8': '6',
  '9': '7',
  '10': '7.5',
  '10.5': '8',
  '11': '8.5',
  '12': '9',
  '13': '10',
  '14': '10.5',
  '14.5': '11',
  '16': '12',
  '18': '13',
  '18.5': '14',
  '20': '15',
  '21.5': '16',
  '24': '18',
  '26.5': '20',
  '30': '22.5',
  '32': '24',
  '36': '27',
  '40': '30',
  '47.5': '35',
  '48': '36',
  '63.5': '47',
  '64': '48',
  '72': '54',
  '85': '64',
  '96': '72',
};

interface ComponentObjectDetails {
  entity: any;
  elements: any[];
  rawEntityData: any;
}

@Injectable({
  providedIn: 'root',
})
export class ComponentEditorService implements OnDestroy {
  private componentObjectDetails: ComponentObjectDetails;
  private componentObjectDetailsSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public componentObjectDetailsObs: Observable<ComponentObjectDetails> =
    this.componentObjectDetailsSubject.asObservable();
  private loadedComponentModelEntitys: any;
  private loadedComponentObject: DocumentElement;
  private loadedComponentObjectSubject: BehaviorSubject<DocumentElement> = new BehaviorSubject(null);
  public loadedComponentObjectObs: Observable<DocumentElement> = this.loadedComponentObjectSubject.asObservable();
  private subscription: Subscription = new Subscription();
  // this cache is used so that subsequent updates in the editor will not retrieve the same objects from db.
  public cachedEntities = [];
  backingAssortmentItemData: any[];

  // ITEM COMPONENT -------------------------------------
  public static projectNameProp = {
    type: 'text',
    displayName: 'Project',
    propertyBindings: { text: 'projectItem.project.name' },
    propertyDefinition: { id: 'projectItem.project.name', slug: 'projectItem.project.name', label: 'Project' },
  };

  /*
  public static assortmentNameProp = {
    type: 'text',
    displayName: 'Assortment',
    propertyBindings: {text: 'assortmentItem.assortment.name'},
    propertyDefinition: {id: 'assortmentItem.assortment.name', slug: 'assortmentItem.assortment.name', label: 'Assortment'}
  };*/

  constructor(
    private documentService: DocumentService,
    private documentComponentService: DocumentComponentService,
    private documentItemService: DocumentItemService,
    private documentColorService: DocumentColorService,
    private confirmationBoxService: ConfirmationBoxService,
    private store: Store<State>,
  ) {
    this.subscribe();
  }

  subscribe() {
    this.subscription.add(
      this.documentService?.documentElementEvents.subscribe((event) => {
        if (!event) {
          return;
        }
        if (event.element) {
          if (
            !event.element.isLocked &&
            (DocumentItemService.isItemComponet(event.element) ||
              DocumentColorService.isColorComponent(event.element)) &&
            event.eventType === 'dblclick'
          ) {
            this.showComponentConfigurator();
          }
        }
      }),
    );

    this.subscription.add(
      this.store.select(AssortmentsSelectors.backingAssortmentItemData).subscribe((backingAssortmentItemData) => {
        this.backingAssortmentItemData = backingAssortmentItemData;
      }),
    );
  }

  unsubscribe() {
    this.subscription.unsubscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  /** Called from component editor.  Loads the service with in formation about the current component */
  public async loadObjects(documentElements: DocumentElement[], currentSelectedComponentId?: string) {
    if (documentElements.length === 1 && this.loadedComponentObject?.id === documentElements[0].id) {
      return;
    }

    this.store.dispatch(setLoading({ loading: true, message: 'Please wait...' }));
    // Fetches (an array of) information needed to process the selected component.
    const entityModelsMap = await this.getEntityModelForComponentElements(documentElements);

    // Sets the related 'entityInfo' for the selected component
    this.loadedComponentObject = currentSelectedComponentId
      ? documentElements.find((el) => el.id === currentSelectedComponentId)
      : documentElements[0];
    this.loadedComponentModelEntitys = entityModelsMap.get(this.loadedComponentObject.id);
    // Generate a set of document-elements for each available property for the current
    // component element.  These are later inserted (or remove) from the component
    // using the property selector in the component editor
    const elements = await this.generatePropertyComponentElements(
      this.loadedComponentModelEntitys,
      this.loadedComponentObject,
    );
    console.log('loadObject: elements: ', elements);

    this.loadedComponentObjectSubject.next(this.loadedComponentObject);

    // This objects supplies: the relevant model entities and pre-computed
    // available document elements for the loaded component element.
    const componentObjectDetails: ComponentObjectDetails = {
      entity: this.loadedComponentModelEntitys,
      elements,
      rawEntityData: this.getRawEntityData(), // raw data that has not been formatted //TODO: do we still need this line?
    };
    this.componentObjectDetails = componentObjectDetails;
    this.componentObjectDetailsSubject.next(componentObjectDetails);
    this.store.dispatch(setLoading({ loading: false, message: '' }));
  }

  getRawEntityData() {
    const rawEntityData = {};
    Object.keys(this.loadedComponentModelEntitys).forEach((key) => {
      const rawData = this.cachedEntities.find((entity) => entity.id === this.loadedComponentModelEntitys[key]?.id);
      if (rawData) {
        rawEntityData[key] = rawData;
      }
    });
    return rawEntityData;
  }

  /**
   * Loads all bound entities within a set of documents elements
   * @return :  An array of maps (objects) that included an id that matches the documentElements id
   */
  async getEntityModelForComponentElements(
    documentElements: DocumentElement[],
  ): Promise<Map<string, DocumentComponentModelBinding>> {
    const entities = await this.documentComponentService.fetchEntitiesExceptFor(documentElements, this.cachedEntities);
    this.cachedEntities = this.cachedEntities.concat(entities);
    return await this.documentComponentService.buildModelBindingsMapForElements(
      documentElements,
      ObjectUtil.cloneDeep(this.cachedEntities),
    );
  }

  getLoadedEntity() {
    return this.loadedComponentModelEntitys;
  }

  updateLoadedEntity(entity) {
    if (this.loadedComponentModelEntitys) {
      Object.keys(this.loadedComponentModelEntitys).forEach((key) => {
        if (this.loadedComponentModelEntitys[key]?.id === entity.id) {
          this.loadedComponentModelEntitys[key] = ObjectUtil.cloneDeep(entity);
        } else if (this.loadedComponentModelEntitys[key]?.itemFamilyId === entity.id) {
          // NEED TO ADDRESS OVERRIDABLE PROPERTIES////////
          this.loadedComponentModelEntitys[key].itemFamily = ObjectUtil.cloneDeep(entity);
          this.loadedComponentModelEntitys[key].name = entity.name;
        }
      });
    }
  }

  /**
   * Applies component property changes to all selected elements in the document
   * based on the property/elements selection in the component editor.
   * @param selectedComponentElement
   * @param propertyElementsToApply
   * @param applyToAllElements
   */
  async updateComponentElements(
    selectedComponentElement: DocumentElement,
    propertyElementsToApply: any[],
    applyToAllElements: boolean,
  ) {
    let componentsToModify = applyToAllElements
      ? this.documentService.currentDocument.elements
      : this.documentService.getSelectedElements();

    if (selectedComponentElement?.modelBindings?.item) {
      componentsToModify = componentsToModify.filter(DocumentItemService.isItemComponet);
    } else if (selectedComponentElement?.modelBindings?.color) {
      componentsToModify = componentsToModify.filter(DocumentColorService.isColorComponent);
    }

    let confirm = true;
    if (componentsToModify?.length > 100) {
      confirm = await this.confirmationBoxService.open(
        'Verify Update',
        `Are you sure you want to update ${componentsToModify.length} elements? It might take a while.`,
        '',
        'UPDATE',
        true,
        false,
      );
    }
    if (!confirm) {
      return;
    }

    console.log('updateComponentElements: selectedComponentElement:', selectedComponentElement);
    console.log('updateComponentElements: elements', propertyElementsToApply);
    if (propertyElementsToApply) {
      propertyElementsToApply = ObjectUtil.cloneDeep(propertyElementsToApply);
      propertyElementsToApply.forEach((element) => {
        delete element.enabled;
        delete element.displayName;
        delete element.id;
      });
    }

    this.store.dispatch(setLoading({ loading: true, message: 'Updating elements. Please wait...' }));

    const elementsGroups = [];
    const groupSize = 50;
    for (let i = 0; i < componentsToModify.length; i += groupSize) {
      const chunk = componentsToModify.slice(i, i + groupSize);
      elementsGroups.push(chunk);
    }

    const promises = [];
    for (let i = 0; i < elementsGroups.length; i++) {
      const promise = limit(async () => {
        const actions = await this.updateComponents(
          selectedComponentElement,
          propertyElementsToApply,
          elementsGroups[i],
        );
        this.documentService.handleDocumentActions(actions);
        return actions;
      });
      promises.push(promise);
    }
    await Promise.all(promises);
    this.store.dispatch(setLoading({ loading: false, message: '' }));

    // Set the last edited element so that we can apply this format to the next element
    const lastComponentDocumentElement = {
      style: ObjectUtil.cloneDeep(selectedComponentElement.style),
      size: ObjectUtil.cloneDeep(selectedComponentElement.size),
      elements: ObjectUtil.cloneDeep(propertyElementsToApply),
    };

    //TODO: apply to 'ITEM' componentType only
    if (selectedComponentElement?.modelBindings?.item) {
      this.documentComponentService.setLastComponentDocumentElement(lastComponentDocumentElement);
    }
  }

  private async updateComponents(
    selectedComponentElement: DocumentElement,
    propertyElementsToApply: any[],
    componentsToModify: DocumentElement[],
  ): Promise<DocumentAction[]> {
    const entityModelsMap = await this.getEntityModelForComponentElements(componentsToModify);
    const promises = [];
    for (const componentElementToModify of componentsToModify) {
      // The service holds only a single model mapping for the currently 'single' selected
      // element.  Here we pick from using that local copy or using what was
      // just loaded.  This is confusing.
      let entityModel;
      const editingCurrentlyLoadedElement = componentElementToModify.id === this.loadedComponentObject?.id;
      if (editingCurrentlyLoadedElement) {
        entityModel = this.loadedComponentModelEntitys;
        // Setting an image updates the local modelBindings, we need to apply these model bindings
        // the the component about to be updated.
        componentElementToModify.modelBindings = ObjectUtil.cloneDeep(this.loadedComponentObject.modelBindings);
      } else {
        entityModel = entityModelsMap.get(componentElementToModify.id);
      }
      const promise = this.documentComponentService.createUpdateDocumentActionForComponentUpdate(
        ObjectUtil.cloneDeep(componentElementToModify),
        propertyElementsToApply,
        entityModel,
        selectedComponentElement.size,
        selectedComponentElement.style,
      );
      promises.push(promise);
    }
    return await Promise.all(promises);
  }

  async updateComponentElement(componentElementToModify: DocumentElement, bindingChanges?: any) {
    console.log('updateComponentElement: componentElementToModify:', componentElementToModify);
    this.batchUpdateComponentElements([{ documentElement: componentElementToModify, bindingChanges }]);
  }

  async batchUpdateComponentElements(
    changes: Array<{ documentElement: DocumentElement; bindingChanges?: any }>,
    skipLoading = false,
    skipUndo = false,
  ) {
    if (!skipLoading) {
      this.store.dispatch(setLoading({ loading: true, message: 'Updating elements. Please wait...' }));
    }
    const actions = [];
    const models = changes.map((change) =>
      change.bindingChanges
        ? { id: change.documentElement.id, modelBindings: change.bindingChanges }
        : change.documentElement,
    );
    const entityModelsMap = await this.getEntityModelForComponentElements(models);
    for (const change of changes) {
      let entityModel;
      const editingCurrentlyLoadedElement = change.documentElement.id === this.loadedComponentObject?.id;
      if (editingCurrentlyLoadedElement) {
        entityModel = this.loadedComponentModelEntitys;
        // Setting an image updates the local modelBindings, we need to apply these model bindings
        // the the component about to be updated.
        change.documentElement.modelBindings = ObjectUtil.cloneDeep(this.loadedComponentObject.modelBindings);
      } else {
        entityModel = entityModelsMap.get(change.documentElement.id);
      }
      const action = await this.documentComponentService.createUpdateDocumentActionForComponentUpdate(
        change.documentElement,
        change.documentElement.elements,
        entityModel,
        change.documentElement.size,
        change.documentElement.style,
        change.bindingChanges,
      );
      actions.push(action);
    }
    this.documentService.handleDocumentActions(actions);
    if (!skipLoading) {
      this.store.dispatch(setLoading({ loading: false, message: '' }));
    }
    return actions;
  }

  async updatePropertyValuesAndUpdateComponents(propertyElements: any, selectedComponentElement: DocumentElement) {
    await this.setLoadedComponent(propertyElements, selectedComponentElement);
    this.updateComponentElements(selectedComponentElement, propertyElements, false);
  }

  /**
   * Sync loaded component element and its property elements so other editor components (font, border, etc)
   * have up-to-date data and do not undo values.
   * @param element
   */
  async setLoadedComponent(propertyElements: any, selectedComponentElement: DocumentElement) {
    if (this.componentObjectDetails) {
      selectedComponentElement.elements = propertyElements;
      const elements = await this.generatePropertyComponentElements(
        this.loadedComponentModelEntitys,
        selectedComponentElement,
      );
      this.loadedComponentObject = selectedComponentElement;
      this.loadedComponentObjectSubject.next(this.loadedComponentObject);

      this.componentObjectDetails.elements = elements;
      this.componentObjectDetailsSubject.next(this.componentObjectDetails);
    }
  }

  updateLoadedComponentViewable(content, item): DocumentElement {
    const imageElement = this.loadedComponentObject.elements.find((el) => el.type === 'image');
    const isPrimaryContent = content.id === item.primaryViewableId;
    if (imageElement) {
      imageElement.propertyBindings = {
        url: isPrimaryContent ? 'viewable.mediumViewableDownloadUrl' : 'viewable.mediumViewableUrl',
      };
    }
    this.loadedComponentObject.modelBindings.viewable = isPrimaryContent ? `item:${item.id}` : `content:${content.id}`;
    this.loadedComponentModelEntitys.viewable = isPrimaryContent ? item : content;
    console.log(
      `Setting content (primary - ${isPrimaryContent})`,
      content,
      'and item',
      item,
      'to loaded component',
      this.loadedComponentObject,
    );
    return this.loadedComponentObject;
  }

  /** Generates a list of document elmeent objects that will be used
   * to provide options for selection in the component property editor.
   * Document elements are added for each type available in the component
   * document elements modelBinding
   */
  private async generatePropertyComponentElements(modelBindings: any, documentElement: DocumentElement) {
    console.log('generatePropertyComponentElements: modelBindings: ', modelBindings);
    console.log('generatePropertyComponentElements: documentElement: ', documentElement);

    const propertyKeysAlreadyUsed: any[] = [];
    const existingElements: any[] = [];
    const componentDocumentElements = documentElement.elements;
    componentDocumentElements.forEach((propertyDocumentElement) => {
      const clonedElement = ObjectUtil.cloneDeep(propertyDocumentElement);
      delete clonedElement.id;
      clonedElement.enabled = true; // Indicate that the component already has this element.
      existingElements.push(clonedElement);
      if (clonedElement?.propertyBindings) {
        // color component wrap element hasn't propertyBindings - just rectangle.
        const attName = Object.values(clonedElement.propertyBindings)[0];
        propertyKeysAlreadyUsed.push(attName);
      }
    });

    let elements: any[] = [];
    const processedPropertyIds = new Set();
    const excludedProps = ['createdOn', 'updatedOn', 'itemFamily', 'itemOption'];

    const addTypeProperties = (typeProperties, modelKey) => {
      for (const property of typeProperties) {
        // Prevent duplicate addition of properties.
        if (
          processedPropertyIds.has(property.id) ||
          (modelKey === 'assortmentItem' && excludedProps.includes(property.slug))
        ) {
          continue;
        }
        processedPropertyIds.add(property.id);
        const attName = modelKey + '.' + property.slug;
        const propertyBindings = { text: attName };
        if (propertyKeysAlreadyUsed.indexOf(attName) === -1) {
          elements.push({
            type: 'text', // property.propertyType,
            displayName: property.label,
            propertyBindings,
            propertyDefinition: property,
          });
        } else {
          const existingElement = existingElements.find(
            (element) => JSON.stringify(element?.propertyBindings) === JSON.stringify(propertyBindings),
          );
          if (existingElement) {
            existingElement.displayName = property.label;
            existingElement.propertyDefinition = property;
          }
        }
      }
    };

    if (documentElement?.modelBindings?.item) {
      /** The thumnail is an outlier as it is not a typeProperty
       * we need to add a label to the element that already exists
       * or create a new element.
       */
      const existingImage = existingElements.find((el) => el.type === 'image');
      if (existingImage) {
        existingImage.displayName = 'Thumbnail';
      } else {
        elements.push({
          type: 'image',
          displayName: 'Thumbnail',
        });
      }

      const existingAnnotation = existingElements.find((el) => el.type === 'annotation');
      if (existingAnnotation) {
        existingAnnotation.displayName = 'Annotations';
      } else {
        elements.push({
          enabled: true,
          displayName: 'Annotations',
          isHidden: false,
          size: { width: existingElements[0].width, height: ANNOTATION_IMG_SIZE },
          style: { font: { size: ANNOTATION_IMG_SIZE } },
          type: 'annotation',
          propertyBindings: { annotation: 'annotation' },
        });
      }
    }

    // Loop over each bound entity in the model, and add available properties.
    for (const modelKey of Object.keys(modelBindings)) {
      const entity = modelBindings[modelKey];
      if (!entity?.typeId) {
        continue;
      }
      const type = await new Types().getType({ id: entity.typeId });
      addTypeProperties(type.typeProperties, modelKey);
    }

    if (propertyKeysAlreadyUsed.includes('projectItem.project.name')) {
      const projectExistingElement = existingElements.find((ele) =>
        Object.values(ele.propertyBindings).some((binding) => binding === 'projectItem.project.name'),
      );
      projectExistingElement.displayName = ComponentEditorService.projectNameProp.displayName;
    }
    /*
    if (propertyKeysAlreadyUsed.includes('assortmentItem.assortment.name')) {
      const assortmentExistingElement = existingElements.find((ele) =>
        Object.values(ele.propertyBindings).some((binding) => binding === 'assortmentItem.assortment.name'),
      );
      assortmentExistingElement.displayName = ComponentEditorService.assortmentNameProp.displayName;
    }
    */

    // Add existing elements to the list.
    elements = elements.concat(existingElements);

    if (documentElement?.modelBindings?.item) {
      if (!elements.find((element) => element.propertyDefinition?.id === 'projectItem.project.name')) {
        elements.push(ObjectUtil.cloneDeep(ComponentEditorService.projectNameProp));
      }

      if (!modelBindings?.projectItem) {
        // Always add project item properties
        const projectItemType = await new Types().getType({ path: 'project-item' });
        if (projectItemType && projectItemType?.typeProperties?.length > 0) {
          addTypeProperties(projectItemType.typeProperties, 'projectItem');
        }
      }

      if (!modelBindings?.assortmentItem) {
        // Always add assortment item properties
        const assortmentItemType = await new Types().getType({ path: 'assortment-item' });
        if (assortmentItemType && assortmentItemType?.typeProperties?.length > 0) {
          addTypeProperties(assortmentItemType.typeProperties, 'assortmentItem');
        }
      }

      /*
      if (!elements.find(element => element.propertyDefinition?.id === 'assortmentItem.assortment.name')) {
        elements.push(ObjectUtil.cloneDeep(ComponentEditorService.assortmentNameProp));
      }
      */
    }

    // Handle legacy image bindings, which never had a real model binding
    let legacyImageUrl = null; // ITEM ONLY
    const imageElement = elements.find((el) => el.type === 'image');
    if (imageElement?.propertyBindings?.url === 'thumbnail.downloadUrl') {
      legacyImageUrl = imageElement.url;
    }

    DocumentElementPropertyBindingHandler.bindPropertiesToElements(elements, { ...modelBindings });

    // Replace legacy image url.
    if (legacyImageUrl) {
      imageElement.url = legacyImageUrl;
    }
    return elements;
  }

  clearObjects() {
    this.cachedEntities = [];
    this.loadedComponentModelEntitys = null;
    this.componentObjectDetails = null;
    this.componentObjectDetailsSubject.next(null);
    this.loadedComponentObject = null;
    this.loadedComponentObjectSubject.next(this.loadedComponentObject);
  }

  public showComponentConfigurator() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Format Properties';
    overlay.slug = 'componentEditor';
    overlay.showChooser = true;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  public static toPx(pt) {
    const px = ptToPx[pt];
    if (!px) {
      return CanvasUtil.toPx(pt);
    } else {
      return parseFloat(px);
    }
  }

  public static toPt(px) {
    const pt = pxToPt[px];
    if (!pt) {
      return CanvasUtil.toPt(px);
    } else {
      return parseFloat(pt);
    }
  }

  public static getPtOptions() {
    return Object.keys(ptToPx).sort((a, b) => {
      return parseFloat(a) - parseFloat(b);
    });
  }

  public async saveEntitiesAndSyncElements(data: any) {
    //TODO: RORY - check with Choon
    // data = {changes: propValueChangeObj, object: originalEntity}
    let entities: any[] = []; // item option or item family entity that is in data | or color entity
    let elementsToSync: DocumentElement[] = [];
    const selectedElements = this.documentService
      .getSelectedElements()
      .filter((element) => element.type === 'component' && !element.isLocked);
    this.store.dispatch(setLoading({ loading: true }));
    const componentElements = this.documentService
      .getDocument()
      .elements.filter((element) => element.type === 'component');

    const entityType = data.object.entityType;
    if (entityType === 'color') {
      entities = [data.object];
      elementsToSync = componentElements.filter(
        (element) => element?.modelBindings?.color === `color:${data.object.id}`,
      );
    } else if (entityType === 'item') {
      if (data.object.id === data.object.itemFamilyId) {
        // update happened on an item family
        // find all item/options that are affected by changes to item family.
        const filteredBackingAssortmentItems = this.backingAssortmentItemData.filter(
          (backingAssortmentItem) => backingAssortmentItem.item.itemFamilyId === data.object.id,
        );
        entities = ObjectUtil.cloneDeep(filteredBackingAssortmentItems).map((assortmentItem) => assortmentItem.item);
        elementsToSync = componentElements.filter((element) =>
          entities.some((entity) => element.modelBindings.item === `item:${entity.id}`),
        );
      } else {
        // update happened on an item option
        entities = [data.object];
        elementsToSync = componentElements.filter((element) => element.modelBindings.item === `item:${data.object.id}`);
      }
    } else if (entityType === 'project-item') {
      entities = [data.object];
      elementsToSync = componentElements.filter(
        (element) => element.modelBindings.projectItem === `project-item:${data.object.id}`,
      );
    } else if (entityType === 'assortment-item') {
      entities = [data.object];
      elementsToSync = componentElements.filter(
        (element) => element.modelBindings.assortmentItem === `assortment-item:${data.object.id}`,
      );
    }

    //TODO: Choon I don't understand this part : blocked this part for colors
    if (selectedElements.length > 1 && entityType !== 'color') {
      selectedElements.forEach((element) => {
        if (elementsToSync.findIndex((el) => el.id === element.id) === -1) {
          elementsToSync.push(element);
        }
      });
    }
    entities = entities.concat(await this.documentComponentService.fetchEntitiesExceptFor(elementsToSync, entities));

    const changeDefinitions = await this.generateChangeDefinitions(data, entities);
    const entityData = ObjectUtil.cloneDeep(entities[0]);
    await this.documentItemService.formatData(entityData);
    this.updateLoadedEntity(entityData);
    await this.documentComponentService.updateValuesForComponentElements(
      this.documentService.getDocument(),
      ObjectUtil.cloneDeep(elementsToSync),
      entities,
      changeDefinitions,
    );
    if (entityType !== 'color') {
      // update items that in the backingAssortment
      this.store.dispatch(AssortmentsActions.syncBackingAssortmentItems({ id: data.object.id, changes: data.changes }));
    }
    this.store.dispatch(setLoading({ loading: false }));
  }

  private async generateChangeDefinitions(data: any, entities: any[]) {
    const changeDefinitions = [];
    const entityType = data.object.entityType;
    let type;
    if (entityType === 'color') {
      type = await new Types().getType({ path: 'color' });
    } else if (entityType === 'item') {
      type = await new Types().getType({ path: 'item' });
    } else {
      type = await new Types().getType({ path: 'project-item' });
    }

    const dataObjectItemLevel =
      entityType === 'color' ? null : data.object.roles?.includes('option') ? 'option' : 'family';
    entities.forEach((entity) => {
      let undoChanges = {};
      let id = entity.id;
      if (entity.entityType === entityType) {
        // update happened on the same entity type
        let entityItemLevel = entity.roles?.includes('option') ? 'option' : 'family';
        const origEntity = ObjectUtil.cloneDeep(entity);
        const entityChanges = {};
        Object.keys(data.changes).forEach((propSlug) => {
          let prop = type.typeProperties.find((prop) => prop.slug === propSlug);
          if (entityType === 'color') {
            entityChanges[propSlug] = data.changes[propSlug];
            undoChanges[propSlug] = origEntity[propSlug] || null;
          } else if (
            // item only for now.
            dataObjectItemLevel === entityItemLevel ||
            (dataObjectItemLevel === 'family' && entityItemLevel === 'option') ||
            (!entity.roles && entityType === 'project-item') || // some old project-item data has no roles
            (dataObjectItemLevel !== entityItemLevel && ['overridable', 'all'].includes(prop.propertyLevel)) ||
            ['name', 'optionName'].includes(prop.slug)
          ) {
            entityChanges[propSlug] = data.changes[propSlug];
            undoChanges[propSlug] = origEntity[propSlug] || null;
            if (dataObjectItemLevel === 'family' && entityType === 'item') {
              // update happened on an item family
              undoChanges[propSlug] = origEntity.itemFamily ? origEntity.itemFamily[propSlug] : origEntity[propSlug];
              id = origEntity.itemFamilyId;
            }
          }
        });

        if (Object.keys(entityChanges).length > 0) {
          if (dataObjectItemLevel === 'family') {
            Object.keys(data.changes).forEach((propSlug) => {
              const prop = type.typeProperties.find((prop) => prop.slug === propSlug);
              if (['overridable', 'all'].includes(prop.propertyLevel)) {
                // update options only when value is the same as family
                if (entity[propSlug] === data.object[propSlug]) {
                  Object.assign(entity, entityChanges);
                }
              } else {
                Object.assign(entity, entityChanges);
              }
            });
          } else {
            Object.assign(entity, entityChanges);
          }
          if (changeDefinitions.findIndex((changeDefinition) => changeDefinition.id === id) === -1) {
            changeDefinitions.push({ id, entityType: origEntity.entityType, changes: data.changes, undoChanges });
          }
        }
      }
    });
    return changeDefinitions;
  }
}
