import { Injectable } from '@angular/core';
import { DocumentAction, DocumentChangeType, DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { Mask } from 'src/app/presentation/canvas-lib';
import { OrderUtil } from '../../../util/OrderUtil';
import { DocumentService } from '../../document.service';

@Injectable({
  providedIn: 'root',
})
export class OrderDocumentElementService {
  constructor(private documentService: DocumentService) {
    console.log('OrderDocumentElementService: constructor');
    this.documentService.actionRequests.subscribe((request) => {
      if (request?.actionType.startsWith('order')) {
        console.log('OrderDocumentElementService: selected elements: ', request?.sourceEvent.selectedElements, event);
        this.orderAllSelected(request?.sourceEvent.selectedElements, request.actionType);
      }
    });
  }
  private orderAllSelected(
    selectedElements: Array<DocumentElement>,
    orderType: string,
    undoable = true,
    index?: number,
    elementsIndexMap?: { string: number },
  ) {
    const undoElements = ObjectUtil.cloneDeep(this.documentService.getDocument().elements);
    let adjustedSelectedElements: DocumentElement[] = [];
    selectedElements.forEach((selectedElement) => {
      const mask: Mask = this.documentService?.documentRenderer?.isMaskOrMaskMember(selectedElement.id);
      if (selectedElement.type === 'group') {
        adjustedSelectedElements = adjustedSelectedElements.concat(
          this.documentService.getAllElementsInGroup(selectedElement.id),
        );
      } else if (mask) {
        // If ordering mask or its members - reorder mask and its members
        adjustedSelectedElements = adjustedSelectedElements.concat(
          this.documentService.documentRenderer.getMaskMembers(mask.id)?.map((e) => e.elementDefinition),
        );
        adjustedSelectedElements.push(mask.element.elementDefinition);
      } else {
        adjustedSelectedElements.push(selectedElement);
      }
    });

    let elementsToReorder =
      ['order.send_backward', 'order.send_to_back'].indexOf(orderType) !== -1
        ? adjustedSelectedElements.reverse()
        : adjustedSelectedElements;
    for (const element of elementsToReorder) {
      const elements = this.documentService.getDocument().elements;
      const from = elements.findIndex((el) => el.id === element.id);
      switch (orderType) {
        case 'order.bring_forward': {
          this.move(from, from + 1);
          break;
        }
        case 'order.bring_to_front': {
          this.move(from, elements.length - 1);
          break;
        }
        case 'order.send_backward': {
          this.move(from, from - 1);
          break;
        }
        case 'order.send_to_back': {
          this.move(from, 0);
          break;
        }
      }
    }
    const action = new DocumentAction(
      {
        changeType: DocumentChangeType.REORDER_ELEMENT,
        elementData: this.documentService.getDocument().elements,
      },
      {
        changeType: DocumentChangeType.REORDER_ELEMENT,
        elementData: undoElements,
      },
    );
    this.documentService.handleDocumentActions([action]);
  }

  private move(from, to) {
    OrderUtil.move(this.documentService.getDocument().elements, from, to);
  }
}
