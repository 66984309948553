import { Component, Input, OnChanges, Type, ViewChild } from '@angular/core';
import { EVENT_CATEGORY } from '@common/analytics/user-analytics.service';
import { AnalyticsService } from '@common/analytics/analytics.service';
import { ItemData } from 'src/app/common/item-data/item-data';
import { ViewManagerService } from 'src/app/common/views/view-manager.service';
import { ItemColorsListComponent } from '../item-colors-list/item-colors-list.component';
import { ComposerService } from 'src/app/presentation/composer/composer.service';
import { COLLECTION_VIEW_APPLICATION_SLUG } from 'src/app/presentation/composer/composer-frame/composer-frame-layout/composer-frame-layout.service';

@Component({
  selector: 'app-presentation-item-details',
  templateUrl: './presentation-item-details.component.html',
  styleUrls: ['./presentation-item-details.component.scss'],
})
export class PresentationItemDetailsComponent implements OnChanges {
  public focusedItemColor: any;
  constructor(
    private analyticsService: AnalyticsService,
    private viewService: ViewManagerService,
    private composerService: ComposerService,
  ) {}
  @Input() dataSelectionToggleComponent: Type<any>;
  @Input() itemPresentationElement: any;
  @Input() itemData: ItemData;
  @Input() includeCommentWidget = false;
  @Input() visible = true;
  @Input() itemsMissingFromSource: any[];

  @ViewChild(ItemColorsListComponent, { static: false }) itemColorListComponent: ItemColorsListComponent;
  public viewDefinition;
  selectedItemsColors = [];

  itemColors = [];

  async ngOnChanges() {
    const contextReference = this.composerService.currentFrameContextReference;
    this.viewDefinition = await this.viewService.getView(
      COLLECTION_VIEW_APPLICATION_SLUG,
      this.itemData.item.typeId,
      contextReference,
    );
    this.focusedItemColor = null;
    this.selectedItemsColors = [];
    if (this.itemData && this.itemData.id) {
      this.setFocusedItemColor();

      this.analyticsService.emitEvent({
        eventName: 'VIEW_ITEM_DETAILS',
        eventCategory: EVENT_CATEGORY.VIEW,
        eventLabel: `Item ${this.itemData.getName()}`,
        eventTarget: `item:${this.itemData.properties.itemId}`,
      });
      if (this.focusedItemColor) {
        this.analyticsService.emitEvent({
          eventName: 'VIEW_ITEM_COLOR_DETAILS',
          eventCategory: EVENT_CATEGORY.VIEW,
          eventLabel: this.itemData.getName(),
          eventTarget: this.focusedItemColor.id,
        });
      }
    }
  }

  setFocusedItemColor() {
    if (this.itemData.options.length) {
      const primary = this.itemData.options.filter((ic) => ic.properties.primary === 'Yes');

      if (primary && primary.length) {
        this.focusedItemColor = primary[0];
      } else {
        const firstEnabled = this.itemPresentationElement?.children?.find((el) => el.enabled);
        const option = this.itemData.options.find((opt) => opt.id === firstEnabled?.value);
        if (option) {
          this.focusedItemColor = option;
        } else if (this.itemData.options?.length) {
          this.focusedItemColor = this.itemData.options[0];
        }
      }
      this.handleItemColorFocusEvent(this.focusedItemColor);
    }
  }

  handleItemColorFocusEvent(itemColor) {
    if (!itemColor) {
      return;
    }
    this.focusedItemColor = itemColor;
    this.itemData.properties = Object.assign(this.itemData.properties, this.focusedItemColor.properties);
    this.analyticsService.emitEvent({
      eventName: 'VIEW_ITEM_COLOR_DETAILS',
      eventCategory: EVENT_CATEGORY.VIEW,
      eventLabel: this.itemData.getName(),
      eventTarget: this.focusedItemColor.id,
    });
  }
}
