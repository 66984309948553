import { createSelector } from '@ngrx/store';
import { RemoteMouseTracker } from '../mouse-tracker/mouse-tracker.component';

export const remoteUserMouseTracker = (state: any) =>
  Object.values(state.remoteMouseTrackers.remoteUserMouseTracker) as Array<RemoteMouseTracker>;
export const filterRemoteUserMouseTracker = (contextReference: string) =>
  createSelector(remoteUserMouseTracker, (remoteUserMouseTracker) => {
    if (remoteUserMouseTracker) {
      return remoteUserMouseTracker.filter((u) => u.contextReference === contextReference);
    }
    return [];
  });
