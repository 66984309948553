import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContextMenuComponent } from '@common/components/context-menu/context-menu.component';

@Component({
  selector: 'app-content-tray',
  template: `
    <div class="flex-between-center">
      <div class="tray-thumbs">
        <div
          class="content-item flex-center"
          *ngFor="let content of contentList"
          (click)="selectContent(content)"
          (contextmenu)="handleContextMenu({ mouseEvent: $event, listItem: content })"
        >
          <div class="primary-content-indicator" *ngIf="isPrimaryViewable(content)"></div>
          <app-content-thumbnail [content]="content"></app-content-thumbnail>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .tray-thumbs {
        @apply flex items-center max-w-[500px] overflow-x-auto overflow-y-hidden mb-3 mt-3;
      }
      .content-item {
        @apply relative cursor-pointer pt-1 pl-1;
      }
      .primary-content-indicator {
        @apply w-[10px] h-[10px] top-0 left-0 absolute bg-primary rounded-md z-[500];
      }
    `,
  ],
})
export class ContentTrayComponent implements OnInit {
  @Input() contentList: Array<any>;
  @Input() editable: boolean = true;
  @Input() primaryViewableId: string;
  @Output() select = new EventEmitter();
  @Input() contextMenu: ContextMenuComponent;
  constructor() {}

  ngOnInit(): void {}

  public selectContent(content) {
    this.select.emit(content);
  }

  isPrimaryViewable(content) {
    if (this.primaryViewableId === content.id) {
      return true;
    }
  }

  handleContextMenu($event) {
    console.log('handleContextMenu: ', $event);
    this.contextMenu.show($event.mouseEvent, $event.listItem);
  }
}
