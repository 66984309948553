import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityChooserComponent } from './entity-chooser.component';
import { MatIconModule } from '@angular/material/icon';
import { ItemDataChooserModule } from '../../common/item-data-chooser/item-data-chooser.module';

@NgModule({
  declarations: [EntityChooserComponent],
  imports: [CommonModule, MatIconModule, ItemDataChooserModule],
  exports: [EntityChooserComponent],
})
export class EntityChooserModule {}
