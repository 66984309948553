import { Injectable } from '@angular/core';
import { API_VERSION, Entities, EntityReference } from '@contrail/sdk';
import { Assortment } from './assortments-store/assortments.state';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { FeatureFlag, Feature } from '@common/feature-flags/feature-flag';
import { tap } from 'rxjs';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { ObjectUtil } from '@contrail/util';

@Injectable({
  providedIn: 'root',
})
export class AssortmentsService {
  static includeFamilyLevelItem = false;

  constructor(private store: Store<RootStoreState.State>) {
    this.store
      .select(FeatureFlagsSelectors.featureFlags)
      .pipe(
        tap((flags: FeatureFlag[]) => {
          if (flags.map((x) => x.featureName).includes(Feature.ITEM_CHOOSER_LEVEL_SELECTION)) {
            AssortmentsService.includeFamilyLevelItem = true;
          }
        }),
      )
      .subscribe();
  }

  public static async getAssortment(id: string) {
    let assortment: any;
    const params: any = {
      entityName: 'assortment',
      id,
      relations: ['assortmentItems', 'assortmentItems.item', 'assortmentItems.projectItem', 'workspace'],
    };
    // params.cacheMode = 'USE';
    assortment = await new Entities().get(params);
    if (assortment.itemsDownloadURL) {
      const response = await fetch(assortment.itemsDownloadURL);
      const assortmentItems = await response.json();
      assortment.assortmentItems = assortmentItems;
    }
    // used when assigning value to the component element for 'Project' and 'Assortment'.
    assortment.assortmentItems.forEach((assortmentItem) => {
      if (assortment.workspace?.workspaceType === 'PROJECT' && assortmentItem.projectItem) {
        assortmentItem.projectItem.project = assortment.workspace;
      }
      assortmentItem.assortment = { name: assortment.name, id: assortment.id };
    });
    return assortment;
  }

  public static async getAssortmentFamilyItems(assortment: any) {
    const clonedAssortment = ObjectUtil.cloneDeep(assortment);
    const familyProjectItemMap = new Map();
    if (AssortmentsService.includeFamilyLevelItem) {
      const ids = [];
      const familyLevelOnlyAssortmentItems = [];
      clonedAssortment.assortmentItems.forEach((assortmentItem) => {
        if (assortmentItem.item?.roles.includes('family')) {
          familyLevelOnlyAssortmentItems.push(assortmentItem);
        } else {
          if (assortmentItem.item?.itemFamilyId && !ids.includes(assortmentItem.item.itemFamilyId)) {
            familyProjectItemMap.set(
              assortmentItem.item.itemFamilyId,
              ObjectUtil.cloneDeep(assortmentItem.familyProjectItem),
            );
            ids.push(assortmentItem.item.itemFamilyId);
          }
        }
      });
      let familyLevelItems = [];
      if (ids.length > 0) {
        const entityReferences = [];
        for (let id of ids) {
          entityReferences.push(new EntityReference(`item:${id}`));
        }
        const entityMap: Map<string, any> = await new Entities().getAllReferences(entityReferences, [
          'item',
          'primaryViewable',
          'primaryViewable.mediumViewable',
          'primaryViewable.primaryFile',
        ]);
        familyLevelItems = Array.from(entityMap.values());
      }
      clonedAssortment.familyLevelItems = familyLevelItems.concat(familyLevelOnlyAssortmentItems);
      clonedAssortment.familyLevelItems.forEach((item) => {
        item.assortmentId = clonedAssortment.id;
        if (familyProjectItemMap.get(item.id)) {
          item.projectItem = familyProjectItemMap.get(item.id);
        }
      });
    }
    return clonedAssortment;
  }

  public async getAllAssortmentsBatched(workspaceId: string) {
    const initialPaginatedAssortments = await this.getAssortmentsPaginated(workspaceId);
    const assortments = [...initialPaginatedAssortments.results];
    let assortmentsNextPageKey = initialPaginatedAssortments.nextPageKey;
    while (assortmentsNextPageKey) {
      const morePaginatedAssortments = await this.getAssortmentsPaginated(workspaceId, assortmentsNextPageKey);
      assortmentsNextPageKey = morePaginatedAssortments.nextPageKey;
      assortments.push(...morePaginatedAssortments.results);
    }

    const sortedAssortments = assortments.sort((a1, a2) => (a1.name?.toUpperCase() > a2.name?.toUpperCase() ? 1 : -1));
    return sortedAssortments;
  }

  public async getAssortmentsPaginated(workspaceId: string, nextPageKey?: string) {
    const numberOfResultsPerPage = 75;
    const criteria = { rootWorkspaceId: workspaceId, isArchived: false };
    const data = await new Entities().get({
      entityName: 'assortment',
      criteria,
      relations: ['sourceAssortments'],
      take: numberOfResultsPerPage,
      apiVersion: API_VERSION.V2,
      paginate: true,
      nextPageKey,
    });

    const newNextPageKey = data.results.length < numberOfResultsPerPage ? undefined : data.nextPageKey;

    const filteredResults = data.results?.length
      ? data.results
          .filter((a) => a.name?.indexOf('Backing') < 0)
          .filter((a) => a?.assortmentType !== 'BACKING' && a?.assortmentType !== 'SHOWCASE')
          .filter((a) => a?.isTrashed !== true)
      : [];

    return { results: filteredResults, nextPageKey: newNextPageKey };
  }

  public async getAssortmentById(id: string) {
    return new Entities().get({ entityName: 'assortment', id });
  }

  public async createAssortment(assortment: Assortment) {
    return new Entities().create({ entityName: 'assortment', object: assortment });
  }
  public async deleteAssortment(assortment: Assortment) {
    await new Entities().delete({ entityName: 'assortment', id: assortment.id });
    return assortment;
  }
  public async updateAssortment(id: string, changes: Assortment) {
    return new Entities().update({ entityName: 'assortment', id, object: changes });
  }

  public async addItemsToAssortment(assortmentId, itemIds) {
    const options = {
      entityName: 'assortment',
      id: assortmentId,
      relation: 'items',
      object: { itemIds },
    };

    return new Entities().create(options);
  }

  public async removeItemsFromAssortment(assortmentItemIds) {
    await new Entities().batchDelete({ entityName: 'assortment-item', ids: assortmentItemIds });
    console.log('removedItemIds:  ', assortmentItemIds);
    return assortmentItemIds;
  }

  public async updateItems(changes) {
    return new Entities().batchUpdate({ entityName: 'assortment-item', objects: changes });
  }
}
