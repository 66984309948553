import { Action, createReducer, on } from '@ngrx/store';
import { RemoteMouseTracker } from '../mouse-tracker/mouse-tracker.component';
import * as RemoteMouseTrackerActions from './remote-mouse-tracker-store.actions';
import { remoteMouseTrackerInitialState, State } from './remote-mouse-tracker-store.state';

const remoteUserMouseMoved = (state: State, { data }) => {
  const remoteMouseData: RemoteMouseTracker = {
    x: data.event.mousePosition.x,
    y: data.event.mousePosition.y,
    clientId: data.context.user.clientId,
    contextReference: data.event.contextReference,
    firstName: data.context.user.firstName,
    lastName: data.context.user.lastName,
    email: data.context.user.email,
  };
  const remoteMouseObj: { string?: RemoteMouseTracker } = {};
  remoteMouseObj[data.context.user.clientId] = { ...remoteMouseData };
  const remoteUserMouseTracker = { ...state.remoteUserMouseTracker, ...remoteMouseObj };
  return {
    ...state,
    remoteUserMouseTracker,
  };
};

const removeRemoteUserFromMouseSession = (state: State, { userSession }) => {
  const clientId = userSession.user.clientId;
  const { [clientId]: value, ...remoteUserMouseTrackerFiltered } = state.remoteUserMouseTracker;
  return {
    ...state,
    remoteUserMouseTracker: remoteUserMouseTrackerFiltered,
  };
};

export const remoteMouseTrackerReducers = createReducer(
  remoteMouseTrackerInitialState,
  on(RemoteMouseTrackerActions.remoteUserMouseMoved, remoteUserMouseMoved),
  on(RemoteMouseTrackerActions.removeRemoteUserFromMouseSession, removeRemoteUserFromMouseSession),
);

export function reducer(state: State | undefined, action: Action): any {
  return remoteMouseTrackerReducers(state, action);
}
