import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { CommentsModalLauncher } from '../comments-modal-launcher';
import { CommentsSelectors } from '../comments-store';
import { Comment, CommentOwnerInfo, CommentsService } from '../comments.service';

@Component({
  selector: 'app-comments-count',
  templateUrl: './comments.count.component.html',
  styleUrls: ['./comments.count.component.scss'],
})
export class CommentsCountComponent implements OnInit {
  comments$: Observable<Array<Comment>>;
  commentsSubscription: Subscription;
  @Input() ownerInfo: CommentOwnerInfo;
  constructor(
    public commentService: CommentsService,
    private store: Store<RootStoreState.State>,
    public launcher: CommentsModalLauncher,
  ) {}

  ngOnInit(): void {
    const ownerRef = this.ownerInfo.entityType + ':' + this.ownerInfo.id;

    this.comments$ = this.store.select(CommentsSelectors.selectContextComments).pipe(
      map((comments: any) => {
        return comments.filter((obj) => ownerRef && ownerRef === obj.ownedByReference);
      }),
    );
  }
  showComments() {
    const params = {
      ownerInfo: this.ownerInfo,
    };
    // this.launcher.openModal(params);
  }
}
