<div>
  <div class="header" [attr.data-test]="'collection-frame-header-' + frame.id">
    <mat-icon svgIcon="grid-frame"></mat-icon>
    <div class="label">{{ label }}</div>
  </div>
  <div class="items" [attr.data-test]="'collection-frame-items-' + frame.id">
    <div>
      <span class="text-lg text-[#F2994A] font-medium">{{ itemCount }}</span>
      <span class="text-xs">Sections</span>
    </div>
    <div>
      <span class="text-lg text-[#F2994A] font-medium">{{ optionCount }}</span>
      <span class="text-xs">Options</span>
    </div>
  </div>
  <mat-icon class="hidden-icon" *ngIf="frame?.disabled">visibility_off</mat-icon>
</div>
