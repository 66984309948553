<button
  class="toggle-icon"
  [disabled]="isDisabled()"
  [color]="getActiveColor()"
  (click)="paint()"
  (dblclick)="holdPaint()"
  matTooltip="Format paint"
  mat-icon-button
>
  <mat-icon>format_paint</mat-icon>
</button>
