import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { Observable, Subscription } from 'rxjs';
import { AssortmentsSelectors } from '@common/assortments/assortments-store';
import { take, tap } from 'rxjs/operators';
import { DocumentService } from '../../document/document.service';
import { ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { AnnotationLoader } from '../annotations-loader';
import { ComposerService } from '../composer.service';
import { ObjectUtil } from '@contrail/util';

@Component({
  selector: 'app-composer-dashboard-panel',
  templateUrl: './composer-dashboard-panel.component.html',
  styleUrls: ['./composer-dashboard-panel.component.scss'],
})
export class ComposerDashboardPanelComponent implements OnInit, OnDestroy {
  @Output() panelClosed = new EventEmitter();
  public appContext: any;
  public annotationOptions: any[];
  currentFrame: any;
  assortmentItemData: any[];
  scope = 'Showcase';
  filteredAssortmentItemData: any[];
  private subscriptions = new Subscription();

  constructor(
    public store: Store<RootStoreState.State>,
    private annotationLoader: AnnotationLoader,
    private composerService: ComposerService,
  ) {
    this.composerService.currentFrame.subscribe((currentFrame) => {
      this.currentFrame = currentFrame;
      if (this.scope === 'Frame') {
        this.filterAssortmentData();
      }
    });

    this.store.select(AssortmentsSelectors.backingAssortmentItemData).subscribe((assortmentItemData) => {
      this.assortmentItemData = assortmentItemData;
      this.filteredAssortmentItemData = assortmentItemData;
      this.filterAssortmentData();
    });
    this.subscriptions.add(
      this.annotationLoader.loaded.subscribe((annotationLoaded) => {
        if (annotationLoaded) {
          this.annotationOptions = AnnotationLoader.annotations.filter((annotation) => !annotation.dashboardDisabled);
        }
      }),
    );
  }

  ngOnInit(): void {
    let showcase;
    this.store
      .select(ShowcasesSelectors.currentShowcase)
      .pipe(
        take(1),
        tap((b) => {
          showcase = b;
        }),
      )
      .subscribe();
    const context = {
      showcase,
      ownerReferenceId: showcase.id,
      vibeIQApp: 'showcases',
    };
    this.appContext = context;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleClose() {
    this.panelClosed.emit();
  }

  handleScopeChange(event) {
    this.scope = event.value;
    this.filterAssortmentData();
  }

  filterAssortmentData() {
    if (this.scope === 'Frame') {
      let currentFrameElementIds = [];
      if (this.currentFrame.type === 'document') {
        currentFrameElementIds = this.currentFrame.document.elements
          .filter((element) => element.type === 'component')
          .map((element) => element.modelBindings.item.substring(5));
      } else if (['collection', 'grid'].includes(this.currentFrame.type)) {
        currentFrameElementIds = this.currentFrame.collection.set.reduce(
          (previousValue, currentSet) =>
            (previousValue = previousValue.concat(currentSet.children.map((child) => child.value))),
          [],
        );
      }
      this.filteredAssortmentItemData = this.assortmentItemData.filter((itemData) =>
        currentFrameElementIds.includes(itemData.id),
      );
    } else {
      this.filteredAssortmentItemData = ObjectUtil.cloneDeep(this.assortmentItemData);
    }
  }
}
