import { Injectable } from '@angular/core';
import { DocumentElement } from '@contrail/documents';
import { Entities } from '@contrail/sdk';

@Injectable({
  providedIn: 'root',
})

/**
 * This service is a helper service for items and component document elements.
 * Important: do not inject DocumentService or other service that injects DocumentService
 * to avoid circular dependency.
 */
export class DocumentColorService {
  constructor() {}

  /**
   * size: {width: number, height: number}
   * {width, height} : new colorRect size | `square`
   * if size is null : Property updates - no resize
   * color card (container) width = width + 5 + 5; (5px padding), and no propertyBindings
   * color card has 2 rectangles (container and colorRect) and text props
   * colorRect is alternative of image section in item card and it uses hexCode as propertyBindings
   * fyi : item card hasn't container rectangle.
   */
  public updateSizeAndPositionForColorElements(propertyElements: any[], size?: any): any {
    const padding = 5;
    const container = propertyElements.find((ele) => ele.type === 'rectangle' && !ele?.propertyBindings);
    const colorRect = propertyElements.find((ele) => ele.type === 'rectangle' && ele?.propertyBindings);
    const width = size?.width ?? colorRect?.size?.width;
    const height = size?.height ?? colorRect?.size?.height;

    let newElements = [];
    let lastYPosition = 0;
    propertyElements.forEach((element, index) => {
      if (element?.enabled === false) {
        return;
      }

      element.position = { x: padding, y: lastYPosition };
      if (element.type === 'rectangle') {
        if (!element?.propertyBindings) {
          // this is container and must be first element of propertyElements array
          lastYPosition = lastYPosition + padding;
          return;
        }

        element.size = { width: width, height: height };
        lastYPosition = lastYPosition + height + padding;
      } else if (element.type === 'text') {
        if (!element.style?.font) {
          element.style = {
            ...element.style,
            font: { size: 8 },
          };
        }
        if (!element?.size) {
          element.size = {};
        }

        element.size.height = element.style.font.size + padding;
        element.size.width = width;
        lastYPosition = lastYPosition + element.size.height;
      }

      newElements.push(element);
    });

    container.size = {
      width: width + padding * 2,
      height: lastYPosition + padding,
    };
    container.position = { x: 0, y: 0 };
    newElements.unshift(container);

    return newElements;
  }

  public getDefaultTextFormat(element) {
    let style: any = {};
    if (element.type === 'text') {
      style = {
        backgroundColor: 'rgba(0,0,0,0)',
        color: 'rgba(0,0,0,0.8)',
        font: { family: 'Roboto', size: 8, weight: 'normal', style: 'normal' },
        text: { align: 'left', decoration: 'none' },
      };
    }
    return style;
  }

  public clearTextFormat(propertyElements: any[]) {
    for (let i = 0; i < propertyElements?.length; i++) {
      const element = propertyElements[i];
      element.style = this.getDefaultTextFormat(element);
    }
    return propertyElements;
  }

  public static isColorComponent(element: DocumentElement): boolean {
    return element?.type === 'component' && element?.modelBindings?.color;
  }

  //TODO: undo/redo for color changes - check in Board app
  public performComponentEntityUpdates(colorChanges: any[], entityType: string = 'color') {
    const map = new Map();
    colorChanges.forEach((itemChange) => {
      map.set(itemChange.changeDefinition.entityId, {
        id: itemChange.changeDefinition.entityId,
        changes: itemChange.changeDefinition.entityData,
      });
    });
    return new Entities().batchUpdate({ entityName: entityType, objects: [...map.values()] });
  }
}
