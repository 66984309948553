import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AssortmentsSelectors } from '@common/assortments/assortments-store';
import { ItemData } from '@common/item-data/item-data';
import { DocumentAction, DocumentChangeType, DocumentElementEvent, PositionDefinition } from '@contrail/documents';
import { Entities } from '@contrail/sdk';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, EMPTY } from 'rxjs';
import { debounceTime, tap, filter, map, concatMap, withLatestFrom } from 'rxjs/operators';
import { CollectionStatusMessage } from 'src/app/common/collection-status-message/collection-status-message';
import { setLoading } from 'src/app/common/loading-indicator/loading-indicator-store/loading-indicator.actions';
import { UndoRedoService } from 'src/app/common/undo-redo/undo-redo-service';
import { WebSocketService } from 'src/app/common/web-socket/web-socket.service';
import { ShowcasesActions, ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { v4 as uuid } from 'uuid';
import { State } from '../../root-store/root-state';
import { Showcase } from '../../showcases/showcases.service';
import { DocumentStatusMessageService } from '../document/document-status/document-status-message.service';
import { DocumentService } from '../document/document.service';
import { Presentation, PresentationCollectionElement, PresentationFrame } from '../presentation';
import { DebounceUtil } from '../util/DebounceUtil';
import { ShowcaseAnnotationService } from './annotation/showcase-annotation.service';
import { ShowcaseBackingAssortmentService } from './backing-assortment/showcase-backing-assortment-service';
import { ComposerClipboard } from './composer-clipboard/composer-clipboard';
import { NewCollectionFrameModalComponent } from './composer-frame/composer-collection-frame/new-collection-frame-modal/new-collection-frame-modal.component';
import { UploadFileFrameModalComponent } from './composer-frame/upload-file-frame-modal/upload-file-frame-modal.component';
import { PresentationAction } from './types/presentation-action';
import { DocumentComponentService } from '../document/document-component/document-component-service';
import { DocumentComponentFramePositionHandlerService } from '../document/document-component/document-component-frame-position-handler-service';
import { AuthService } from '@common/auth/auth.service';
import { DocumentHistorySelectors } from '@common/document-history/document-history-store';
import { DocumentDataBinder } from '../document/document-data-binder';
import { ConfirmationBoxService } from '@common/components/confirmation-box/confirmation-box';
import { SVG_COMPONENT_PADDING_X, SVG_COMPONENT_PADDING_T } from '../svg/constants';
import { CanvasSizeSizePositionHandler } from './composer-frame/composer-svg-frame/composer-canvas/canvas-size-position.handler';
import { ActivatedRoute, Router } from '@angular/router';
import { ComposerPropertyPoliciesService } from './composer-property-policies/composer-property-policies.service';
import {
  UserSessionActionTypes,
  navigateToSessionLocation,
} from '@common/user-sessions/user-sessions-store/user-sessions.actions';
import { Actions, ofType } from '@ngrx/effects';
import { DocumentGenerationConfig } from '../document-generator/document-generator.interfaces';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { remoteUserCurrentContext } from '@common/user-sessions/user-sessions-store/user-session.selectors';
import pLimit from 'p-limit';
import * as _ from 'lodash';
const limit = pLimit(10);

export interface ComposerActionEvent {
  action: string;
  sourceDocumentEvent: DocumentElementEvent;
}
@Injectable({
  providedIn: 'root',
})
export class ComposerService {
  // The entire presentation
  private presentationObject: Presentation;
  private presentationSubject: BehaviorSubject<Presentation> = new BehaviorSubject(null);
  public presentation: Observable<Presentation> = this.presentationSubject.asObservable();

  // Currently shown frame
  private currentFrameObject: PresentationFrame;
  private currentFrameSubject: BehaviorSubject<PresentationFrame> = new BehaviorSubject(null);
  public currentFrame: Observable<PresentationFrame> = this.currentFrameSubject.asObservable();
  public get currentFrameContextReference() {
    // `showcase:showcaseID` | `presentation-frame:frameID`
    return this.currentFrameObject?.hasCustomViewDefinition
      ? `presentation-frame:${this.currentFrameObject.id}`
      : this.presentationObject.ownedByReference;
  }

  // Select Frame Ids
  public selectedFrameIdsSubject: BehaviorSubject<string[]> = new BehaviorSubject([]);
  private selectedFrameIds: Array<string>;
  public selectedFrameIdsObservable: Observable<string[]> = this.selectedFrameIdsSubject.asObservable();
  private clipboardFrames: PresentationFrame[];
  // Currently selected frame
  private selectedFrameObject: PresentationFrame;
  private selectedFrameSubject: BehaviorSubject<PresentationFrame> = new BehaviorSubject(null);
  public selectedFrame: Observable<PresentationFrame> = this.selectedFrameSubject.asObservable();

  // Currently selected frame
  private selectedFramePlaceholderObject: PresentationFrame;
  private selectedFramePlaceholderSubject: BehaviorSubject<PresentationFrame> = new BehaviorSubject(null);
  public selectedPlaceholderFrame: Observable<PresentationFrame> = this.selectedFramePlaceholderSubject.asObservable();

  // Currently selected lineboard frames
  public selectedLineboardFrameIdsSubject: BehaviorSubject<string[]> = new BehaviorSubject([]);
  public selectedLineboardFrameIdsObservable: Observable<string[]> =
    this.selectedLineboardFrameIdsSubject.asObservable();

  // Changes to frames (add / delete / disable, etc)
  private frameChangesSubject: BehaviorSubject<PresentationFrame> = new BehaviorSubject(null);
  public frameChanges: Observable<PresentationFrame> = this.frameChangesSubject.asObservable();

  // presentation load indicator
  private presentationLoadInProgressSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public presentationLoadInProgress: Observable<boolean> = this.presentationLoadInProgressSubject.asObservable();

  private showcase: Showcase;
  public composerClipboard: ComposerClipboard;

  private backingAssortmentItems: any[];
  private statusMessages: any[];
  private lastComponentPositionByFrame: {
    string?: PositionDefinition;
  } = {};

  // debounces the generatePreview call so that it is not called repeatedly within a short period of time
  private generatePreview = DebounceUtil.debounce(
    () => {
      new Entities().update({ entityName: 'preview', id: this.presentationObject.ownedByReference, object: {} });
    },
    5000,
    false,
  );

  public sizePositionHandler: CanvasSizeSizePositionHandler;

  private lastAppliedTextFormat;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private documentService: DocumentService,
    private authService: AuthService,
    private dialog: MatDialog,
    private store: Store<State>,
    private actions$: Actions,
    private webSocketService: WebSocketService,
    private undoRedoService: UndoRedoService,
    private backingAssortmentService: ShowcaseBackingAssortmentService,
    private showcaseAnnotationService: ShowcaseAnnotationService,
    private annotationMessageService: DocumentStatusMessageService,
    private documentComponentService: DocumentComponentService,
    private confirmationBoxService: ConfirmationBoxService,
    private composerPropertyPoliciesService: ComposerPropertyPoliciesService,
    private framePositionHandlerService: DocumentComponentFramePositionHandlerService,
  ) {
    combineLatest([
      this.store.select(ShowcasesSelectors.currentShowcase),
      this.store.select(DocumentHistorySelectors.currentEntitySnapshot),
    ])
      .pipe(
        tap(([showcase, snapshot]) => {
          this.sizePositionHandler = null;
          if (showcase || snapshot) {
            this.init(showcase, snapshot?.snapshot);
          }
        }),
      )
      .subscribe();

    this.documentService.documentActions.subscribe(this.handleDocumentActions.bind(this));
    this.documentService.backgroundUpdateDocumentActions.subscribe(
      this.handleBackgroundUpdateDocumentActions.bind(this),
    );
    this.store.select(ShowcasesSelectors.statusMessageElement).subscribe((statusMessageElement) => {
      if (statusMessageElement) {
        this.navigateToFrameWithItem(statusMessageElement);
      }
    });
    this.selectedFrameIdsSubject.subscribe((selectedFrameIds) => {
      this.selectedFrameIds = selectedFrameIds;
    });
    this.actions$
      .pipe(
        ofType(UserSessionActionTypes.ADD_REMOTE_USER_TO_SESSION),
        tap((action: any) => {
          if (this.currentFrameObject) {
            this.broadcastCurrentFrame(this.currentFrameObject);
          }
        }),
      )
      .subscribe();
    this.listenForRemoteUserSessionNavigation();
  }

  addFrameIdQuery(id) {
    this.router.navigate([], { relativeTo: this.route, fragment: id, skipLocationChange: false });
  }

  private async handleBackgroundUpdateDocumentActions(actions) {
    if (!actions || actions.length === 0 || !this.presentation) {
      return;
    }

    const documentId = actions[0].changeDefinition.documentId;
    const changeToCurrentFrame = this.documentService.currentDocument?.id === documentId;
    let changedFrame;
    if (changeToCurrentFrame) {
      changedFrame = this.currentFrameObject;
      // Set file url to current session
      this.documentService.applyDocumentActions(actions, true);
      // Send file url to remote sessions
      this.documentService.sendSessionEvent(actions, { id: documentId });
    } else {
      changedFrame = this.presentationObject.frames.find((frame) => frame.document?.id === documentId);

      actions.forEach((documentAction) => {
        if (documentAction.changeDefinition.changeType === 'MODIFY_ELEMENT') {
          let element = changedFrame.document.elements.find(
            (el) => el.id === documentAction.changeDefinition.elementId,
          );
          if (element) {
            element = ObjectUtil.mergeDeep(element, documentAction.changeDefinition.elementData);
          }
        }
      });
      this.frameChangesSubject.next(changedFrame);
    }

    // Update presentation object's frames so they have latest elements
    const frameIndex = this.presentationObject.frames.findIndex((f) => f.id === changedFrame.id);
    if (frameIndex > -1) {
      this.presentationObject.frames.splice(frameIndex, 1, ObjectUtil.cloneDeep(changedFrame));
      this.presentationSubject.next(this.presentationObject);
    }
  }

  /** Handles document actions:
   * - Adds to undo / redo stack
   * - Applys changes to the underlying document (via document service)
   * - Broadcasts the actions via websocket.
   * - Updates the board
   */
  private async handleDocumentActions(actions) {
    if (!actions || !this.presentation) {
      return;
    }
    const actionsForBackingAssortment = ObjectUtil.cloneDeep(actions);
    // This makes sure that changes are done to the correct frame
    if (actions.length > 0) {
      const affectedFrame = this.presentationObject.frames.find(
        (frame) => frame.document?.id === actions[0].changeDefinition?.documentId,
      );
      if (affectedFrame && affectedFrame.id !== this.currentFrameObject.id) {
        this.setCurrentFrame(affectedFrame);
      }
    }
    this.composerPropertyPoliciesService.handleDocumentActions(actions);
    this.documentService.applyDocumentActions(actions);
    if (actions?.length) {
      for (const action of actions) {
        if (action?.changeDefinition?.elementData) {
          if (action?.changeDefinition?.changeType === 'REBIND_MODEL') {
            action.changeDefinition.changeType = DocumentChangeType.MODIFY_ELEMENT;
          }
          if (
            action?.changeDefinition?.changeType === DocumentChangeType.MODIFY_ELEMENT &&
            ((action?.changeDefinition?.elementData?.isTextTool &&
              action?.changeDefinition?.elementData?.style?.border?.width != null) ||
              (action?.changeDefinition?.elementData?.type === 'sticky_note' &&
                action?.changeDefinition?.elementData?.style?.border?.width != null))
          ) {
            action.changeDefinition.elementData.style.border.width = 0;
          }
        }
      }

      // Send request to API to patch the presentation frame document
      new Entities().create({
        entityName: 'presentation-frame',
        relation: 'patch',
        id: this.currentFrameObject.id,
        object: actions,
      });

      // Broadcast (internally) that this frame was modified
      this.frameChangesSubject.next(this.currentFrameObject);

      // Update presentation object's frames so they have latest elements
      const frameIndex = this.presentationObject.frames.findIndex((f) => f.id === this.currentFrameObject.id);
      this.presentationObject.frames.splice(frameIndex, 1, ObjectUtil.cloneDeep(this.currentFrameObject));
      this.presentationSubject.next(this.presentationObject);

      // Check if need to trigger preview
      if (frameIndex === 0) {
        this.generatePreview();
      }

      if (actions.length > 0 && actions[0].undoChangeDefinition) {
        // only add change with undoChangeDefinition to the undo stack
        this.undoRedoService.addUndo(ObjectUtil.cloneDeep(actions));
      }
    }

    // Adjust backing assortment based on document actions
    // Note that this logic needs to use the 'currentFrameObj' vs
    // the fames in the current presentation as the current frame
    // may not have been updated in the presetnation when this code runs.
    const currentFrame = this.currentFrameObject;
    this.backingAssortmentService.debounceSyncBackingAssortment({
      frame: currentFrame,
      actions: actionsForBackingAssortment,
    });

    this.webSocketService.sendSessionEvent({
      eventType: 'DOCUMENT_ELEMENT_CHANGED',
      changes: { actions },
    });
  }

  async handlePresentationFrameActions(actions) {
    const frame = actions[0].changeDefinition.frameData;
    let presentationData;
    if (actions[0].actionType === 'frame') {
      presentationData = actions[0].changeDefinition.presentationData;
      await this.backingAssortmentService.syncBackingAssortment(frame);
      await this.updateFrame(frame);
      const index = this.presentationObject.frames.findIndex((f) => {
        return f.id === frame.id;
      });
      this.presentationObject.frames.splice(index, 1, frame);
      this.presentationSubject.next(this.presentationObject);
      this.frameChangesSubject.next(frame);
      if (this.currentFrameObject && this.currentFrameObject.id === frame.id) {
        this.setCurrentFrame(frame);
      }
      this.webSocketService.sendSessionEvent({
        eventType: 'UPDATE_PRESENTATION_FRAME',
        changes: {
          updatedFrame: frame,
        },
      });
    } else {
      // actionType = 'presentation'
      const framesToBeDeleted = [];
      if (actions[0].changeDefinition.changeType === 'CREATE_FRAME') {
        presentationData = actions[0].changeDefinition.presentationData;
      } else {
        presentationData = actions[actions.length - 1].changeDefinition.presentationData;
      }
      this.store.dispatch(setLoading({ loading: true, message: 'Updating your Presentation. Please wait...' }));
      for (let action of actions) {
        let frameData = action.changeDefinition.frameData;
        if (action.changeDefinition.changeType === 'CREATE_FRAME') {
          const newFrame = await new Entities().create({
            entityName: 'presentation',
            id: this.presentationObject.id,
            relation: 'presentation-frames',
            object: frameData,
          });
          this.presentationObject.frames.push(newFrame);
          this.setCurrentFrame(newFrame);
          this.webSocketService.sendSessionEvent({
            eventType: 'CREATE_PRESENTATION_FRAME',
            changes: {
              createdFrame: ObjectUtil.cloneDeep(newFrame),
            },
          });
        } else {
          this.presentationObject.frames = this.presentationObject.frames.filter((f) => f.id !== frameData.id);
          const clonedFrameData = ObjectUtil.cloneDeep(frameData);
          framesToBeDeleted.push(clonedFrameData);
        }
        await this.backingAssortmentService.syncBackingAssortment(frameData, [action]);
      }
      if (framesToBeDeleted.length) {
        await this.deleteFrames(framesToBeDeleted, true, true);
      }
      this.store.dispatch(setLoading({ loading: false, message: '' }));
    }
    await new Entities().update({
      entityName: 'presentation',
      id: this.presentationObject.id,
      object: presentationData,
    });
    await this.reorderFrames({
      updatedPresentation: presentationData,
    });

    this.webSocketService.sendSessionEvent({
      eventType: 'UPDATE_PRESENTATION_FRAME',
      changes: {
        updatedPresentation: presentationData,
      },
    });
  }

  private async init(showcase, snapshot = null) {
    await this.composerPropertyPoliciesService.getTypes();

    if (!showcase?.id) {
      return;
    }

    // Lazy creation of backing assortment if needed
    if (showcase?.id && !snapshot) {
      this.backingAssortmentService.initBackingAssortment(this.presentation, showcase);
    }

    this.documentService.setElementTypesWithContextMenu(['image', 'svg', 'component', 'group']);
    this.showcase = showcase;

    this.setCurrentFrame(null);
    this.presentationObject = null;
    this.presentationSubject.next(null);

    await this.loadPresentation(snapshot);
    this.composerClipboard = new ComposerClipboard(
      this,
      this.documentService,
      this.backingAssortmentService,
      this.authService,
    );
    this.annotationMessageService.init(
      this.store.select(ShowcasesSelectors.collectionStatusMessages) as Observable<Array<CollectionStatusMessage>>,
    );
    this.showcaseAnnotationService.init(this.presentation);

    // use debounce to reduce the number of times the canvas is reloaded
    combineLatest(
      this.documentService.elementsAdded$.pipe(
        debounceTime(1000),
        filter((b) => b === true),
      ),
      this.store.select(ShowcasesSelectors.showSourceAssortmentWarning),
      this.annotationMessageService.statusMessages.pipe(debounceTime(1000)),
    ).subscribe(([elementsAdded, showSourceAssortmentWarning, statusMessages]) => {
      this.statusMessages = statusMessages;
      this.setElementAnnotations(true);
    });

    // Everytime new elements are added and when backing assortment items are changes,
    // recalculate annotations for all component elements. We recalculate them for entire
    // presentation so frame previews could be updated too
    combineLatest(
      this.documentService.elementsAdded$.pipe(
        debounceTime(1000),
        filter((b) => b === true),
      ),
      this.store.select(AssortmentsSelectors.backingAssortmentItems),
    ).subscribe(([elementsAdded, backingAssortmentItems]) => {
      this.backingAssortmentItems = backingAssortmentItems;
      this.setElementAnnotations();
    });

    this.documentService.elementsAdded$.next(true);

    // Subscribe to last applied text formats (font family, font size)
    // so they are applied when switching between frames too.
    this.documentService.lastAppliedTextFormat$.subscribe((format) => {
      this.lastAppliedTextFormat = format;
    });
  }

  async loadPresentation(snapshot = null) {
    try {
      let presentation: Presentation = null;
      if (snapshot) {
        // rebind images... as needed.
        presentation = ObjectUtil.cloneDeep(snapshot.presentation);
        const documents = presentation.frames.filter((f) => f?.document).map((f) => f.document);

        await new DocumentDataBinder().rebindDocuments(documents, ['file']);
      } else {
        this.store.dispatch(setLoading({ loading: true, message: 'Loading your Presentation. Please wait...' }));

        const presentations = await new Entities().get({
          entityName: 'presentation',
          criteria: {
            ownedByReference: `showcase:${this.showcase.id}`,
          },
        });
        if (presentations?.length) {
          await Promise.all(presentations.map((presentation) => this.hydratePresentation(presentation)));
          presentation = presentations[0];
        }
      }
      // If no presentation is found, do not continue.
      if (!this.presentation) {
        return;
      }
      this.presentationObject = presentation;

      if (this.presentationObject.frames?.length) {
        this.setCurrentFrame(this.presentationObject.frames[0]);
        this.setSelectedFrame(this.presentationObject.frames[0]); // set the first frame as the selected frame
      } // ***presentationSubject.next()*** MUST be next this func because we update currentFrame based on slideId from url query
      this.presentationSubject.next(this.presentationObject);

      if (!this.presentationObject.frames) {
        this.presentationObject.frames = [];
      }
      if (!this.presentationObject.presentationFrameOrder?.length) {
        this.presentationObject.presentationFrameOrder = [];
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.store.dispatch(setLoading({ loading: false, message: '' }));
    }
  }

  /**
   * extracts the presentation frame order from presentation frames
   */
  private extractPresentationFrameOrder() {
    return this.presentationObject.frames.reduce((frameIds, frame) => {
      if (frame.id) {
        frameIds.push(frame.id);
      }
      return frameIds;
    }, []);
  }
  public savePresentation(
    action: string = null,
    frames: PresentationFrame[] = null,
    skipRedoUndo = false,
    undoRedoArray: Array<any> = null,
  ) {
    const currentFrameOrder = ObjectUtil.cloneDeep(this.presentationObject.presentationFrameOrder);
    if (action === 'DELETE_FRAME') {
      this.presentationObject.frames = this.presentationObject.frames.filter((f) => f.id !== frames[0].id);
      this.presentationSubject.next(this.presentationObject);
    } else if (action === 'DELETE_FRAMES') {
      this.presentationObject.frames = this.presentationObject.frames.filter(
        (f) => !frames.map((f) => f.id).includes(f.id),
      );
      this.presentationSubject.next(this.presentationObject);
    }
    this.presentationObject.presentationFrameOrder = this.extractPresentationFrameOrder();
    const data = { presentationFrameOrder: this.presentationObject.presentationFrameOrder };
    new Entities().update({ entityName: 'presentation', id: this.presentationObject.id, object: data }).then(() => {
      if (
        currentFrameOrder.length > 0 &&
        data.presentationFrameOrder.length > 0 &&
        currentFrameOrder[0] !== data.presentationFrameOrder[0]
      ) {
        // only generate if the first frame is changed
        this.generatePreview();
      }
    });
    this.webSocketService.sendSessionEvent({
      eventType: 'UPDATE_PRESENTATION_FRAME',
      changes: {
        updatedPresentation: data,
      },
    });
    if (!skipRedoUndo) {
      let undoAction = 'UPDATE_FRAME';
      let redoAction = 'UPDATE_FRAME';
      if (action === 'CREATE_FRAME') {
        undoAction = 'DELETE_FRAME';
        redoAction = 'CREATE_FRAME';
      } else if (action === 'DELETE_FRAME' || action === 'DELETE_FRAMES') {
        undoAction = 'CREATE_FRAME';
        redoAction = 'DELETE_FRAME';
      }

      const presentationActions = frames.map((frame) => {
        const undoPresentationChange = {
          changeType: undoAction,
          presentationData: { presentationFrameOrder: currentFrameOrder },
          frameData: frame,
        };
        const redoPresentationChange = {
          changeType: redoAction,
          presentationData: data,
          frameData: frame,
        };
        return new PresentationAction('presentation', redoPresentationChange, undoPresentationChange);
      });
      if (undoRedoArray) {
        // just add to undoRedoArray without adding to the stack
        presentationActions.forEach((action) => {
          undoRedoArray.push(ObjectUtil.cloneDeep(action));
        });
      } else {
        this.undoRedoService.addUndo(ObjectUtil.cloneDeep(presentationActions));
      }
    }
  }

  public async createFrame(presentationFrame: PresentationFrame, idx: number = null) {
    const frame = await new Entities().create({
      entityName: 'presentation',
      id: this.presentationObject.id,
      relation: 'presentation-frames',
      object: presentationFrame,
    });
    this.webSocketService.sendSessionEvent({
      eventType: 'CREATE_PRESENTATION_FRAME',
      changes: {
        createdFrame: frame,
      },
    });
    this.setCurrentFrame(frame);
    const index = this.getFrameInsertLocation(idx);
    if (index > -1) {
      this.presentationObject.frames.splice(index, 0, frame);
    } else {
      this.presentationObject.frames.push(frame);
    }
    this.presentationSubject.next(this.presentationObject);
    this.savePresentation('CREATE_FRAME', [frame]);
    this.setSelectedFrame(frame);

    return frame;
  }

  public async updateFrame(frame: PresentationFrame) {
    // annotations shouldn't be saved or it could affect other apps.
    const frameForUpdate = ObjectUtil.cloneDeep(frame);
    if (frameForUpdate.document) {
      frameForUpdate.document.elements.forEach((element) => {
        if (element.type === 'component') {
          const componentImageElement = element.elements?.find((componentElement) => componentElement.type === 'image');
          delete componentImageElement?.annotations;
          delete element.annotations;
        }
      });
    }
    const updateFrameIndex = this.presentationObject.frames
      .map((currentFrame) => {
        return currentFrame.id;
      })
      .indexOf(frameForUpdate.id);

    const updatedFrame = await new Entities().update({
      entityName: 'presentation',
      id: this.presentationObject.id,
      relation: 'presentation-frames',
      relationId: frame.id,
      object: frameForUpdate,
    });
    if (!frame.isDeleted && updateFrameIndex > -1) {
      this.presentationObject.frames.splice(updateFrameIndex, 1, frame);
      this.presentationSubject.next(this.presentationObject);
    }

    const previewFrameIndex = this.presentationObject.frames
      .filter((currentFrame) => currentFrame.type === 'document')
      .map((currentFrame) => currentFrame.id)
      .indexOf(frameForUpdate.id);
    if (previewFrameIndex === 0) {
      // modified frame is the first one or the deleted one
      this.generatePreview();
    }
    return updatedFrame;
  }

  public async deleteFrame(frame: PresentationFrame) {
    const confirm = await this.confirmationBoxService.open(
      `Delete frame`,
      `Are you sure you want to delete this frame from the showcase?`,
    );
    if (!confirm) {
      return;
    }
    const idx = this.presentationObject.frames.findIndex((f) => f.id === frame.id);
    if (idx === this.presentationObject.frames.length - 1) {
      // if last frame
      this.navigateToPreviousFrame();
    } else {
      this.navigateToNextFrame(); // navigate to next frame after deleting this frame
    }
    this.deleteFrames([frame], true);
  }

  public async deleteFrames(
    frames: any[],
    skipConfirmationAndNavigateFrame = false,
    skipRedoUndo = false,
    undoRedoArray: Array<any> = null,
    skipSyncBackingAssortment = false,
  ) {
    if (!skipConfirmationAndNavigateFrame) {
      const confirm = await this.confirmationBoxService.open(
        `Delete frames`,
        `Are you sure you want to delete these frames from the showcase?`,
      );
      if (!confirm) {
        return;
      }
    }
    const ids = [];
    const originalFrames = [];

    frames.forEach(async (frame) => {
      const idx = this.presentationObject.frames.findIndex((f) => f.id === frame.id);
      if (!skipConfirmationAndNavigateFrame) {
        if (idx === this.presentationObject.frames.length - 1) {
          // if last frame
          this.navigateToPreviousFrame();
        } else {
          this.navigateToNextFrame(); // navigate to next frame after deleting this frame
        }
      }

      this.webSocketService.sendSessionEvent({
        eventType: 'DELETE_PRESENTATION_FRAME',
        changes: {
          deletedFrame: ObjectUtil.cloneDeep(frame),
        },
      });
      ids.push(frame.id);
      originalFrames.push(ObjectUtil.cloneDeep(frame));
    });
    this.savePresentation('DELETE_FRAMES', originalFrames, skipRedoUndo, undoRedoArray);
    await new Entities().batchDelete({ entityName: 'presentation-frame', ids });
    if (this.presentationObject.frames.length === 0) {
      this.setCurrentFrame(null);
    }
    if (!skipSyncBackingAssortment) {
      frames.forEach(async (frame) => {
        frame.isDeleted = true;
        await this.backingAssortmentService.syncBackingAssortment(frame, [
          new DocumentAction({
            changeType: DocumentChangeType.DELETE_ELEMENT,
          }),
        ]);
      });
    }
  }

  public setClipboardFrames() {
    const ids = this.selectedFrameIds;
    const frames = ObjectUtil.cloneDeep(this.presentationObject.frames.filter((f) => ids.includes(f?.id))).sort(
      (frame1, frame2) => {
        return ids.indexOf(frame1.id) - ids.indexOf(frame2.id);
      },
    );
    this.clipboardFrames = frames.map((frame) => {
      delete frame.documentGenerationConfigId;
      if (frame?.type === 'document') {
        frame.document.elements.map((element) => {
          delete element.annotations;
        });
      }
      return frame;
    });
  }

  public clearClipboardFrames() {
    this.clipboardFrames = null;
  }
  public getClipboardFrames() {
    return this.clipboardFrames;
  }

  setCurrentFrame(frame: PresentationFrame) {
    this.currentFrameObject = frame;
    this.currentFrameSubject.next(this.currentFrameObject);
    this.broadcastCurrentFrame(frame);
  }
  refreshCurrentFrame() {
    this.currentFrameSubject.next(this.currentFrameObject);
  }

  setSelectedFrame(frame: PresentationFrame) {
    this.selectedFrameObject = frame;
    this.selectedFrameSubject.next(this.selectedFrameObject);

    // Set last applied text format to current document service
    // so text elements use last text format when switching between frames
    if (this.lastAppliedTextFormat) {
      this.documentService?.saveLastAppliedTextFormat(this.lastAppliedTextFormat);
    }

    // Scroll to the selected frame
    this.scrollToFrame(frame?.id); // Assuming `frame.id` is the unique identifier
  }

  private scrollToFrame(frameId: string, containerId: string = 'frameTray'): void {
    if (!frameId) {
      return;
    }
    const container = document.getElementById(containerId); // Adjust container ID as needed
    const frameElement = document.getElementById(frameId);

    if (container && frameElement) {
      const containerRect = container.getBoundingClientRect();
      const frameRect = frameElement.getBoundingClientRect();

      const scrollPosition = frameRect.top - containerRect.top + container.scrollTop;

      container.scrollTo({
        top: scrollPosition,
        behavior: 'smooth', // Smooth scrolling
      });
    } else {
      console.warn(`Container or frame not found for ID: ${frameId}`);
    }
  }

  getPresentationFrames() {
    return this.presentationObject?.frames;
  }
  getSelectedFrameObject() {
    return this.selectedFrameObject;
  }

  resetSelectedFrame() {
    this.selectedFrameObject = null;
    this.selectedFrameSubject.next(this.selectedFrameObject);
    if (this.selectedFrameIds.length > 1) {
      this.selectedFrameIds = [this.selectedFrameIds[0]];
      this.selectedFrameIdsSubject.next([this.selectedFrameIds[0]]);
      if (this.currentFrameObject.id !== this.selectedFrameIds[0]) {
        this.setCurrentFrame(this.presentationObject.frames.find((frame) => frame.id === this.selectedFrameIds[0]));
      }
    }
  }

  getSelectedPlaceholderFrame() {
    return this.selectedFramePlaceholderObject;
  }

  setSelectedPlaceholderFrame(frame: PresentationFrame) {
    this.selectedFramePlaceholderObject = frame;
    this.selectedFramePlaceholderSubject.next(this.selectedFramePlaceholderObject);
  }

  resetSelectedPlaceholderFrame() {
    this.selectedFramePlaceholderObject = null;
    this.selectedFramePlaceholderSubject.next(this.selectedFramePlaceholderObject);
  }

  navigateToNextFrame() {
    if (this.currentFrameObject) {
      const idx = this.presentationObject.frames.findIndex((frame) => frame.id === this.currentFrameObject.id);
      if (idx >= 0 && idx < this.presentationObject.frames.length - 1) {
        const nextFrame = this.presentationObject.frames[idx + 1];
        this.setCurrentFrame(nextFrame);
        this.setSelectedFrame(nextFrame);
        this.addFrameIdQuery(nextFrame.id);
      }
    }
  }

  navigateToPreviousFrame() {
    if (this.currentFrameObject) {
      const idx = this.presentationObject.frames.findIndex((frame) => frame.id === this.currentFrameObject.id);
      if (idx >= 1 && idx < this.presentationObject.frames.length) {
        const previousFrame = this.presentationObject.frames[idx - 1];
        this.setCurrentFrame(previousFrame);
        this.setSelectedFrame(previousFrame);
        this.addFrameIdQuery(previousFrame.id);
      }
    }
  }

  navigateToFrame(frameId) {
    const frame = this.presentationObject.frames.find((frame) => frame.id === frameId);
    if (frame) {
      this.setCurrentFrame(frame);
      this.setSelectedFrame(frame);
    }
  }

  /**
   * Navigate to the first frame that has @itemId
   * @param itemId
   */
  navigateToFrameWithItem(itemId) {
    let frameId = null;
    for (const frame of this.presentationObject.frames) {
      if (frame.type === 'document') {
        const item = frame.document.elements.findIndex(
          (element) => element.type === 'component' && element.modelBindings.item.split(':')[1] === itemId,
        );
        if (item !== -1) {
          frameId = frame.id;
          break;
        }
      } else if (frame.type === 'collection' || frame.type === 'grid') {
        const item =
          frame?.collection?.set.find((itemFamily) => itemFamily.value === itemId) ||
          []
            .concat(...frame?.collection?.set.map((itemFamily) => itemFamily.children))
            .find((itemOption) => itemOption.value === itemId);
        if (item) {
          frameId = frame.id;
          break;
        }
      }
    }

    if (frameId) {
      this.navigateToFrame(frameId);
    }
  }

  /////////////////
  // New frame handling.. could move to its own service or services...
  // YES: This should be moved to its own service, and there can be a different service
  // for each frame type, so increase decoupling.  Services can listen to the
  // actions subject and response accordingly.
  /////////////////
  async initializeNewPresentationFrame(frameType, idx?: number) {
    let newFrame;
    switch (frameType) {
      case 'document': {
        newFrame = await this.addPresentationFrame(
          {
            type: frameType,
            document: {
              size: {
                width: 1200,
                height: 675,
              },
              id: uuid(),
              elements: [],
            },
          },
          idx,
        );
        break;
      }
      case 'collection': {
        newFrame = await this.addPresentationFrame({
          type: frameType,
          collection: {
            set: [],
            type: 'item',
            filter: {},
          },
        });
        break;
      }

      case 'grid': {
        newFrame = await this.addPresentationFrame({
          type: frameType,
          collection: {
            set: [{ label: '', value: uuid(), type: 'section', enabled: true, children: [] }],
            type: 'item',
            filter: {},
          },
        });
        break;
      }
      case 'uploadPDF':
        // open file upload modal
        this.openFileUploadModal();
        break;
    }
    if (newFrame) {
      this.setSelectedFrame(newFrame);
      this.resetSelectedPlaceholderFrame();
    }
  }

  openFileUploadModal() {
    const data = { data: { presentationObject: this.presentationObject }, autoFocus: false, disableClose: true };
    const dialogRef = this.dialog.open(UploadFileFrameModalComponent, data);
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.reload) {
        this.loadPresentation();
      }
    });
  }

  openCollectionFrameModal() {
    const dialogRef = this.dialog.open(NewCollectionFrameModalComponent, { data: {}, autoFocus: false });

    dialogRef.afterClosed().subscribe((result) => {});
  }
  async addPresentationFrame(frame: PresentationFrame, idx?: number) {
    frame.ownedByReference = `presentation:${this.presentationObject.id}`;
    this.setCurrentFrame(frame);

    const newFrame = await this.createFrame(this.currentFrameObject, idx);
    if (['grid', 'collection'].includes(newFrame.type)) {
      this.backingAssortmentService.syncBackingAssortment(newFrame);
      this.store.dispatch(
        ShowcasesActions.setGridFrameActiveContainer({ gridFrameActiveContainer: newFrame.collection.set[0] }),
      );
    }
    return newFrame;
  }

  async addPresentationFrames(frames: PresentationFrame[], idx?: number, undoRedoArray: Array<any> = null) {
    const newFrames = [];
    const promises = [];
    const batchesOfFrames = _.chunk(frames, 10);
    for (let batchOfFrames of batchesOfFrames) {
      const promise = limit(async () => {
        const createdFrames = await new Entities().batchCreate({
          entityName: 'presentation-frame',
          objects: batchOfFrames,
        });

        for (let createdFrame of createdFrames) {
          newFrames.push(createdFrame);
          this.webSocketService.sendSessionEvent({
            eventType: 'CREATE_PRESENTATION_FRAME',
            changes: {
              createdFrame: createdFrame,
            },
          });
        }
      });
      promises.push(promise);
    }
    await Promise.all(promises);

    // reorder frames
    const startingFrameOrderByName = frames.map((frame) => frame.name);
    const newFramesSorted = newFrames.sort((a, b) => {
      return startingFrameOrderByName.indexOf(a.name) - startingFrameOrderByName.indexOf(b.name);
    });

    let index = this.getFrameInsertLocation(idx);

    if (index > -1) {
      this.presentationObject.frames.splice(index, 0, ...newFramesSorted);
    } else {
      newFramesSorted.forEach((newFrame) => {
        this.presentationObject.frames.push(newFrame);
      });
    }
    this.savePresentation('CREATE_FRAME', newFramesSorted, false, undoRedoArray);
    this.setCurrentFrame(newFramesSorted[newFramesSorted.length - 1]);
    this.setSelectedFrame(newFramesSorted[newFramesSorted.length - 1]);
  }

  private getFrameInsertLocation(idx: number) {
    let index = -1;
    if (idx !== null) {
      index = idx;
    } else if (this.selectedFramePlaceholderObject) {
      if (this.selectedFramePlaceholderObject.type === 'home') {
        index = 0;
      } else {
        const frameIndex = this.presentationObject.frames.findIndex(
          (frame) => frame.id === this.selectedFramePlaceholderObject.id,
        );
        index = frameIndex + 1;
      }
    } else if (this.selectedFrameObject) {
      const frameIndex = this.presentationObject.frames.findIndex((frame) => frame.id === this.selectedFrameObject.id);
      index = frameIndex + 1;
    }
    return index;
  }

  addNewFrame(presentationFrame: PresentationFrame) {
    this.presentationObject.frames.push(presentationFrame);
    this.presentationSubject.next(this.presentationObject);
  }

  removeFrame(presentationFrame: PresentationFrame) {
    const index = this.presentationObject.frames.findIndex((frame) => {
      return frame.id === presentationFrame.id;
    });
    this.presentationObject.frames.splice(index, 1);
    this.presentationSubject.next(this.presentationObject);
  }

  async reorderFrames(changes: any) {
    if (changes.updatedPresentation?.presentationFrameOrder) {
      this.presentationObject.frames.sort((a, b) => {
        return (
          changes.updatedPresentation.presentationFrameOrder.indexOf(a.id) -
          changes.updatedPresentation.presentationFrameOrder.indexOf(b.id)
        );
      });
      this.presentationSubject.next(this.presentationObject);
    }
    if (changes.updatedFrame) {
      const index = this.presentationObject.frames.findIndex((frame) => {
        return frame.id === changes.updatedFrame.id;
      });
      if (index > -1) {
        this.presentationObject.frames.splice(index, 1, changes.updatedFrame);
      } else {
        this.presentationObject.frames.push(changes.updatedFrame);
      }
      this.presentationSubject.next(this.presentationObject);
      this.frameChangesSubject.next(changes.updatedFrame);
      await this.backingAssortmentService.initBackingAssortment(this.presentation, this.showcase);
      if (this.currentFrameObject && this.currentFrameObject.id === changes.updatedFrame.id) {
        this.setCurrentFrame(changes.updatedFrame);
      }
    }
  }

  /** Believe this is only being called when changes come in on the websocket
   */
  handleDocumentElementsUpdated(actions: DocumentAction[]) {
    const skipSelect = true;
    if (!actions?.length) {
      return;
    }

    const documentId = actions[0].changeDefinition.documentId;
    const changeToCurrentFrame = this.documentService.currentDocument?.id === documentId;

    if (changeToCurrentFrame) {
      /* Handling of the current frame works by simply routing the actions
       * to the document service and appling the actions as if they came
       * from the local editor.
       */
      // Why would we ever add to the undo stack when a remote changes comes in?
      if (actions.length > 0 && actions[0].undoChangeDefinition) {
        this.undoRedoService.addUndo(ObjectUtil.cloneDeep(actions));
      }
      this.documentService.applyDocumentActions(actions, skipSelect);
      const changeType = actions[0].changeDefinition.changeType;
      if (
        [DocumentChangeType.MODIFY_ELEMENT, DocumentChangeType.ADD_ELEMENT, DocumentChangeType.DELETE_ELEMENT].indexOf(
          changeType,
        ) !== -1
      ) {
        this.documentService.handleDocumentElementEvent({
          element: null,
          eventType: 'dragEnded',
        });
      }

      // Update presentation object's frames so they have latest elements
      const frameIndex = this.presentationObject.frames.findIndex((f) => f.id === this.currentFrameObject.id);
      this.presentationObject.frames.splice(frameIndex, 1, ObjectUtil.cloneDeep(this.currentFrameObject));
      this.presentationSubject.next(this.presentationObject);
    } else {
      /** When the changes are not to the current frame, we can't use the document service
       * Rather, we need to patch the presentation frame with the updates.
       */

      const changedFrame = this.presentationObject.frames.find((frame) => frame.document?.id === documentId);
      actions.forEach((documentAction) => {
        if (documentAction.changeDefinition.changeType === 'REORDER_ELEMENT') {
          this.handleReorderFrameElements(changedFrame, documentAction);
        } else if (documentAction.changeDefinition.changeType === 'MODIFY_ELEMENT') {
          let docElement = changedFrame.document.elements.find(
            (el) => el.id === documentAction.changeDefinition.elementId,
          );
          docElement = ObjectUtil.mergeDeep(docElement, documentAction.changeDefinition.elementData);
        } else if (documentAction.changeDefinition.changeType === 'DELETE_ELEMENT') {
          changedFrame.document.elements = changedFrame.document.elements.filter(
            (el) => el.id !== documentAction.changeDefinition.elementId,
          );
        } else if (documentAction.changeDefinition.changeType === 'ADD_ELEMENT') {
          changedFrame.document.elements.push(documentAction.changeDefinition.elementData);
        }
      });
      this.frameChangesSubject.next(changedFrame);

      // Update presentation object's frames so they have latest elements
      const frameIndex = this.presentationObject.frames.findIndex((f) => f.id === changedFrame.id);
      if (frameIndex > -1) {
        this.presentationObject.frames.splice(frameIndex, 1, ObjectUtil.cloneDeep(changedFrame));
        this.presentationSubject.next(this.presentationObject);
      }
    }

    this.documentService.handleRemoteDocumentActions(actions);
  }

  /** This is a copy of the routine we use on the server side to
   * apply a reordered set of element ids without modifying the local elements
   */
  private handleReorderFrameElements(frame, documentAction) {
    // Generated an ordered list of element ids, for the new order
    const elementOrder = documentAction.changeDefinition.elementData.map((el) => el.id);

    // Map existing elements based on id
    const allExistingElementsMap = new Map();
    for (const el of frame.document.elements) {
      allExistingElementsMap.set(el.id, el);
    }

    // Build a new array, using the ordered ids list.
    const newElementArray = [];
    for (const id of elementOrder) {
      const existing = allExistingElementsMap.get(id);
      if (existing) {
        newElementArray.push(existing);
        allExistingElementsMap.delete(id);
      }
    }

    // Anything in the map which was not in the ordered id array needs to be added
    // to the end.. this is an edge case that suggests incorrect client state.
    newElementArray.push(...allExistingElementsMap.values());

    // Check sum on length of elements..
    if (newElementArray.length !== frame.document.elements.length) {
      throw Error('Did not resort correctly... count mismatch');
    }
    frame.document.elements = newElementArray;
  }

  /** Believe this is for updating frames visibility or deleting (and undo) */
  async handleFrameChanges(frame: PresentationFrame, undoFrame: PresentationFrame = null) {
    if (!frame.id) {
      return;
    }
    if (undoFrame) {
      const undoPresentationChange = {
        changeType: 'UPDATE_FRAME',
        frameData: ObjectUtil.cloneDeep(undoFrame),
      };
      const redoPresentationChange = {
        changeType: 'UPDATE_FRAME',
        frameData: ObjectUtil.cloneDeep(frame),
      };
      const presentationAction = new PresentationAction('frame', redoPresentationChange, undoPresentationChange);
      this.undoRedoService.addUndo(ObjectUtil.cloneDeep([presentationAction]));
    }
    await this.backingAssortmentService.syncBackingAssortment(frame);
    this.frameChangesSubject.next(frame);
    this.setCurrentFrame(frame);
    await this.updateFrame(frame);
    this.webSocketService.sendSessionEvent({
      eventType: 'UPDATE_PRESENTATION_FRAME',
      changes: {
        updatedFrame: frame,
      },
    });
  }

  async addItemsToCollectionFrame(items: ItemData[]) {
    const currentFrameObject = ObjectUtil.cloneDeep(this.currentFrameObject);
    items.forEach((itemData) => {
      let collectionSet;
      const itemIndex = currentFrameObject.collection.set.findIndex(
        (item) => item.value === itemData.item.itemFamilyId,
      );

      if (itemIndex === -1) {
        collectionSet = {
          label: itemData.item.name,
          enabled: true,
          value: itemData.item.itemFamilyId,
          children: [],
        };
      } else {
        collectionSet = ObjectUtil.cloneDeep(currentFrameObject.collection.set[itemIndex]);
      }
      if (itemData.item.optionName) {
        if (collectionSet.children.findIndex((item) => item.value === itemData.item.id) === -1) {
          collectionSet.children.push({
            label: itemData.item.optionName,
            enabled: true,
            value: itemData.item.id,
          });
        }
      }
      if (itemIndex > -1) {
        currentFrameObject.collection.set.splice(itemIndex, 1, collectionSet);
      } else {
        currentFrameObject.collection.set.push(collectionSet);
      }
    });
    this.updateGridCollectionFrame(currentFrameObject);
  }

  addItemsToGridFrame(items: ItemData[], activeContainer: PresentationCollectionElement) {
    const currentFrameObject = ObjectUtil.cloneDeep(this.currentFrameObject);
    let selectedContainer;
    let selectedContainerIndex = -1;
    if (activeContainer) {
      const activeContainerValue = activeContainer.value;
      selectedContainerIndex = currentFrameObject.collection.set.findIndex((set) => set.value === activeContainerValue);
      if (selectedContainerIndex > -1) {
        selectedContainer = currentFrameObject.collection.set[selectedContainerIndex];
      } else {
        selectedContainer = ObjectUtil.cloneDeep(activeContainer);
      }
      items.forEach((itemData) => {
        if (selectedContainer.children.findIndex((item) => item.value === itemData.item.id) === -1) {
          selectedContainer.children.push({
            label: itemData.item.optionName || itemData.item.name,
            enabled: true,
            value: itemData.item.id,
          });
        }
      });

      if (selectedContainerIndex > -1) {
        currentFrameObject.collection.set.splice(selectedContainerIndex, 1, selectedContainer);
      } else {
        currentFrameObject.collection.set.push(selectedContainer);
      }

      this.updateGridCollectionFrame(currentFrameObject);
    }
  }

  public updateGridCollectionFrame(currentFrameObject: any) {
    const undoChangeDefinition = ObjectUtil.cloneDeep(this.currentFrameObject);
    const index = this.presentationObject.frames.findIndex((f) => {
      return f.id === currentFrameObject.id;
    });
    this.presentationObject.frames.splice(index, 1, currentFrameObject);
    this.presentationSubject.next(this.presentationObject);
    this.handleFrameChanges(currentFrameObject, undoChangeDefinition);
  }

  /**
   * Add @items to the document frame and distribute them on the canvas.
   * @param items
   */
  async addItemsToDocumentFrame(items: any[]) {
    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));
    //Batch convert entities to component elements
    let components = await this.documentComponentService.batchConvertEntitiesToComponentElements(items);
    let insertIndex = this.presentationObject.frames.findIndex((f) => f.id === this.currentFrameObject.id);
    while (components.length > 0) {
      const documentWidth = this.currentFrameObject?.document?.size?.width;
      const documentHeight = this.currentFrameObject?.document?.size?.height;
      const initialPosition = { x: SVG_COMPONENT_PADDING_X, y: SVG_COMPONENT_PADDING_T };
      // Add Items to current frame. Return the remaining items that do not fit on current frame
      components = await this.documentComponentService.addItemsToDocument(
        components,
        documentWidth,
        documentHeight,
        initialPosition,
      );
      // If there are remaining items, initialize a new frame
      if (components.length > 0) {
        insertIndex++;
        await this.initializeNewPresentationFrame('document', insertIndex);
      }
    }

    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
  }

  /**
   * Add @item to the document frame and place it after last know component position on the frame.
   * @param item
   */
  async addItemToDocumentFrame(item: any) {
    const frameId = this.currentFrameObject?.id;
    if (!frameId) {
      console.error('No current frame object');
      return;
    }

    const position = this.framePositionHandlerService.getInitialPosition(frameId, this.lastComponentPositionByFrame);
    const element = await this.documentComponentService.createComponentElement(item, {
      position: { x: 0, y: 0 },
    });
    const elementSize = await this.framePositionHandlerService.calculateElementSize(element);

    if (
      this.framePositionHandlerService.isNewFrameNeeded(
        position,
        elementSize,
        this.currentFrameObject.document.size.width,
        this.currentFrameObject.document.size.height,
      )
    ) {
      const insertIndex = this.presentationObject.frames.findIndex((f) => f.id === this.currentFrameObject.id) + 1;
      await this.initializeNewPresentationFrame('document', insertIndex);
      return this.addItemToDocumentFrame(item); // Recursively call with the new frame
    }

    const addedElement = await this.documentComponentService.addComponentElement(item, { position });
    const nextPosition = this.framePositionHandlerService.calculateNextPosition(
      position,
      elementSize,
      this.currentFrameObject.document.size.width,
    );
    this.lastComponentPositionByFrame[frameId] = nextPosition;
  }
  /**
   * Sets element annotations for entire presentation
   * @param statusMessagesOnly
   */
  setElementAnnotations(statusMessagesOnly = false) {
    if (statusMessagesOnly) {
      this.showcaseAnnotationService.setElementAnnotations(this.statusMessages);
    } else {
      this.showcaseAnnotationService.setElementAnnotationByAssortmentItems(this.backingAssortmentItems);
    }
  }

  initSizePositionHandler(sizePositionHandler: CanvasSizeSizePositionHandler) {
    this.sizePositionHandler = sizePositionHandler;
  }

  public async hydratePresentation(presentation: Presentation) {
    if (presentation?.framesDownloadURL) {
      const response = await fetch(presentation.framesDownloadURL);
      const frames = await response.json();
      presentation.frames = frames || [];
    }
  }

  public getSelectedFrameIds() {
    return this.selectedFrameIds;
  }

  public broadcastCurrentFrame(frame: PresentationFrame) {
    if (frame?.id) {
      this.webSocketService.sendSessionEvent({
        eventType: 'REMOTE_USER_CURRENT_CONTEXT',
        changes: {
          contextReference: `presentation-frame:${frame.id}`,
        },
      });
    }
  }

  public broadcastMouseMove(mousePosition: PositionDefinition) {
    if (this.currentFrameObject) {
      this.webSocketService.sendSessionEvent({
        eventType: 'REMOTE_USER_MOUSE_MOVED',
        changes: {
          mousePosition,
          contextReference: `presentation-frame:${this.currentFrameObject.id}`,
        },
      });
    }
  }

  async generateFrames(newFrames: any[]) {
    const undoRedoArray: Array<any> = [];
    await this.addPresentationFrames(newFrames, null, undoRedoArray);
    this.undoRedoService.addUndo(ObjectUtil.cloneDeep(undoRedoArray));
    this.backingAssortmentService.syncBackingAssortment();
  }

  async regenerateFrames(data: any, newFrames: any[], skipUndoRedo = false) {
    const undoRedoArray: Array<any> = !skipUndoRedo ? [] : null;
    const frames = this.presentationObject.frames.filter(
      (frame) => frame.documentGenerationConfigId === data.generationConfig.id,
    );
    const frameIndex = this.presentationObject.presentationFrameOrder.indexOf(frames[0].id);
    const frameNames = frames.map((frame) => frame.name);
    await this.deleteFrames(frames, true, false, undoRedoArray, true);
    newFrames.forEach((frame, index) => {
      if (!frame.name) {
        let name = frameNames.shift();
        if (name) {
          frame.name = name;
        } else {
          frame.name = `Frame ${frameIndex + index}`;
        }
      }
    });
    await this.addPresentationFrames(newFrames, frameIndex, undoRedoArray);
    await new Entities().update({
      entityName: 'document-generation-config',
      id: data.generationConfig.id,
      object: data.generationConfig,
    });
    if (undoRedoArray) {
      const undoPresentationChange = {
        changeType: 'REGENERATE_LINEBOARD',
        generationConfigData: ObjectUtil.cloneDeep(data.originalGenerationConfig),
      };
      const redoPresentationChange = {
        changeType: 'REGENERATE_LINEBOARD',
        generationConfigData: ObjectUtil.cloneDeep(data.generationConfig),
      };
      undoRedoArray.push(new PresentationAction('lineboard', redoPresentationChange, undoPresentationChange));
      this.undoRedoService.addUndo(ObjectUtil.cloneDeep(undoRedoArray));
    }
    this.backingAssortmentService.syncBackingAssortment();
  }

  async updateLineboardConfig(documentGenerationConfig: DocumentGenerationConfig) {
    await new Entities().update({
      entityName: 'document-generation-config',
      id: documentGenerationConfig.id,
      object: documentGenerationConfig,
    });
  }

  private listenForRemoteUserSessionNavigation() {
    this.actions$
      .pipe(
        ofType(navigateToSessionLocation),
        map((action) => action.session),
        withLatestFrom(this.store.select(remoteUserCurrentContext)),
        concatMap(([session, remoteUsers]) => {
          const remoteUser = remoteUsers.find((user) => user?.user?.clientId === session.user.clientId);

          if (remoteUser) {
            // Extract frameId from contextReference, removing the 'presentation-frame:' prefix
            const frameId = remoteUser.contextReference.replace(/^presentation-frame:/, '');

            // Only navigate if the frameId differs from the current one
            if (frameId !== this.currentFrameObject.id) {
              this.navigateToFrame(frameId);
            }
          } else {
            console.error('Remote user not found');
          }

          // Return an empty observable to complete the concatMap operation
          return EMPTY;
        }),
      )
      .subscribe();
  }
}
