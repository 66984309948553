<div
  *ngIf="selectorProperties"
  cdkDropList
  class="flex flex-col space-y-[5px]"
  (cdkDropListDropped)="drop($event)"
  data-test="composer-frame-property-selector"
>
  <ng-template ngFor let-property [ngForOf]="selectedProperties" [ngForTrackBy]="trackByFn">
    <div class="list-value" *ngIf="property.enabled" cdkDrag data-test="composer-frame-property-selector-option">
      <div class="flex items-center w-full text-black/60">
        <mat-icon class="cursor-move mr-2.5" cdkDragHandle>drag_handle</mat-icon>
        {{ property.propertyDefinition.label }}
        <div class="flex items-center justify-end grow">
          <mat-icon
            class="icon"
            matTooltip="Include label"
            *ngIf="!property.includeLabel"
            (click)="toggleLabel(property)"
            >label_outline</mat-icon
          >
          <mat-icon
            class="icon"
            matTooltip="Include label"
            *ngIf="property.includeLabel"
            (click)="toggleLabel(property)"
            >label</mat-icon
          >
          <div matTooltip="Settings" (click)="showPropertyWidget(property, $event)">
            <app-composer-frame-property-configurator #propertyWidget (valuesChanged)="updateTextProperties($event)">
            </app-composer-frame-property-configurator>
            <mat-icon class="icon" [color]="getActiveColor(property)">text_fields</mat-icon>
          </div>
          <mat-icon class="icon" matTooltip="Remove" (click)="removeProperty(property)">delete</mat-icon>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="h-[26px] bg-gray-50 flex flex-row items-center justify-between text-xs rounded-[5px] px-2">
    <form class="flex items-center w-full h-full">
      <mat-icon class="text-black24 !w-6 !h-6 !text-2xl !leading-6 cursor-pointer" (click)="openPanel($event)"
        >add_box</mat-icon
      >
      <input
        #propertyInput
        type="text"
        [formControl]="control"
        placeholder="Add property"
        [matAutocomplete]="auto"
        size="30"
      />
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addProperty($event.option.value)">
        <mat-option *ngFor="let property of filteredProperties$ | async" [value]="property">
          {{ property.propertyDefinition.label }}
        </mat-option>
      </mat-autocomplete>
    </form>
  </div>
</div>
