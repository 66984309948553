<div #widgetTray class="widget-tray" (click)="handleClick($event)">
  <app-property-configurator-element-icon
    *ngIf="(!contextualEntityReference && elementType && elementType !== 'iframe') || elements?.length > 1"
    [elementType]="elementType"
    [selectedElements]="elements"
    [resetDrag]="reset"
  ></app-property-configurator-element-icon>
  <app-property-configurator-entity-details
    *ngIf="contextualEntityReference && elements?.length === 1"
    [selectedElement]="elements[0]"
    [resetDrag]="reset"
  ></app-property-configurator-entity-details>

  <div class="widgets-list text-black60" *ngIf="sourceEvent">
    <div class="widgets-list" *ngIf="!isLocked">
      <app-property-configurator-color-picker
        matTooltip="Text color"
        *ngIf="properties.includes('font-color')"
        (valueChange)="updateTextElement($event)"
        [currentValue]="getTextAttributeValue('textColor')"
        [matIconType]="'format_color_text'"
        [colorType]="'fontColor'"
        [resetPos]="reset"
      >
      </app-property-configurator-color-picker>
      <app-property-configurator-color-picker
        *ngIf="
          properties.includes('background-color') || (properties.includes('recolor-svg') && hasSvgRecolorFeatureFlag)
        "
        (valueChange)="handleDelayedValueChange($event)"
        [currentValue]="getCurrentValue('style.backgroundColor')"
        [matIconType]="'format_color_fill'"
        [colorType]="'backgroundColor'"
      >
      </app-property-configurator-color-picker>
      <app-property-configurator-text-decorator
        matTooltip="Bold"
        decoratorType="bold"
        [matIconType]="'format_bold'"
        *ngIf="properties.includes('font-bold')"
        (valueChange)="updateTextElement($event)"
        [currentValue]="getTextAttributeValue('bold')"
      >
      </app-property-configurator-text-decorator>
      <app-property-configurator-text-decorator
        data-test="text-italic"
        matTooltip="Italic"
        decoratorType="italic"
        [matIconType]="'format_italic'"
        *ngIf="properties.includes('font-italic')"
        (valueChange)="updateTextElement($event)"
        [currentValue]="getTextAttributeValue('italic')"
      >
      </app-property-configurator-text-decorator>
      <app-property-configurator-text-decorator
        data-test="text-underline"
        matTooltip="Underline"
        decoratorType="underline"
        [matIconType]="'format_underline'"
        *ngIf="properties.includes('font-underline')"
        (valueChange)="updateTextElement($event)"
        [currentValue]="getTextAttributeValue('underline')"
      >
      </app-property-configurator-text-decorator>
      <app-property-configurator-text-decorator
        data-test="text-strikethrough"
        matTooltip="Strikethrough"
        decoratorType="strikethrough"
        [matIconType]="'strikethrough_s'"
        *ngIf="properties.includes('font-strikethrough')"
        (valueChange)="updateTextElement($event)"
        [currentValue]="getTextAttributeValue('strikethrough')"
      >
      </app-property-configurator-text-decorator>
      <app-property-configurator-font-family-selector
        matTooltip="Font"
        *ngIf="properties.includes('font-family')"
        (valueChange)="updateTextElement($event)"
        [currentValue]="getTextAttributeValue('fontFamily')"
      >
      </app-property-configurator-font-family-selector>
      <app-property-configurator-font-size
        matTooltip="Font size"
        *ngIf="properties.includes('font-size')"
        (valueChange)="updateTextElement($event)"
        [currentValue]="getTextAttributeValue('fontSize')"
      >
      </app-property-configurator-font-size>
      <button
        data-test="border-style-menu"
        class="border-style-button"
        *ngIf="
          properties.includes('border-color') ||
          properties.includes('border-size') ||
          properties.includes('border-style')
        "
        mat-icon-button
        [matMenuTriggerFor]="borderStyle"
        matTooltip="Border"
        matTooltipPosition="above"
      >
        <mat-icon>border_style</mat-icon>
      </button>
      <mat-menu #borderStyle="matMenu" class="border-style-menu">
        <app-property-configurator-color-picker
          matTooltip="{{ elementType === 'line' ? 'Line color' : 'Border color' }}"
          matTooltipPosition="above"
          *ngIf="properties.includes('border-color')"
          (click)="$event.preventDefault(); $event.stopPropagation()"
          (valueChange)="handleDelayedValueChange($event)"
          [currentValue]="getCurrentValue('style.border.color')"
          [matIconType]="'border_color'"
          [colorType]="'borderColor'"
          [resetPos]="reset"
        >
        </app-property-configurator-color-picker>
        <app-property-configurator-border-size
          matTooltip="{{ elementType === 'line' ? 'Line weight' : 'Border weight' }}"
          matTooltipPosition="above"
          *ngIf="properties.includes('border-size')"
          (click)="$event.preventDefault(); $event.stopPropagation()"
          (valueChange)="updateValues($event)"
          [currentValue]="getCurrentValue('style.border.width')"
        >
        </app-property-configurator-border-size>
        <app-property-configurator-border-style
          matTooltip="{{ elementType === 'line' ? 'Line style' : 'Border style' }}"
          matTooltipPosition="above"
          data-test="composer-toolbar-border-style"
          *ngIf="properties.includes('border-style')"
          (click)="$event.preventDefault(); $event.stopPropagation()"
          (valueChange)="updateValues($event)"
          [currentValue]="getCurrentValue('style.border.style')"
        >
        </app-property-configurator-border-style>
      </mat-menu>

      <app-property-configurator-text-alignment
        matTooltip="Text align"
        *ngIf="properties.includes('text-alignment')"
        (valueChange)="updateTextElement($event)"
        [currentValue]="getTextAttributeValue('textAlign')"
      >
      </app-property-configurator-text-alignment>

      <app-property-configurator-image-transparency
        matTooltip="Transparency"
        matTooltipPosition="above"
        *ngIf="properties.includes('image-transparency')"
        (valueChange)="handleDelayedValueChange($event)"
        [currentValue]="getCurrentValue('style.opacity')"
      ></app-property-configurator-image-transparency>

      <button
        mat-icon-button
        data-test="crop"
        class="crop-button"
        *ngIf="properties.includes('crop') && !isLocked"
        (click)="cropImage(sourceEvent?.element)"
        matTooltip="Crop"
      >
        <mat-icon class="cursor-pointer">crop</mat-icon>
      </button>

      <app-property-configurator-group-element
        *ngIf="!isLocked"
        data-test="composer-toolbar-group-element"
        [elements]="selectedElements"
      >
      </app-property-configurator-group-element>
    </div>
    <mat-divider *ngIf="!isLocked" vertical="true" class="divider"></mat-divider>

    <button
      *ngIf="
        properties.includes('component-editor') &&
        (this.elements[0]?.modelBindings?.item || this.elements[0]?.modelBindings?.color) &&
        !isLocked
      "
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      (click)="showComponentConfigurator()"
      mat-icon-button
      matTooltip="Format options"
      matTooltipPosition="above"
      data-test="component-format-options"
    >
      <mat-icon class="toggle-icon">settings</mat-icon>
    </button>

    <app-property-configurator-download
      *ngIf="properties.includes('download')"
      matTooltip="Download"
      matTooltipPosition="above"
      [element]="elements[0]"
    >
    </app-property-configurator-download>

    <app-property-configurator-locked-element
      *ngIf="elementType && isLockingActionAllowed()"
      data-test="composer-toolbar-locked-element"
      (valueChange)="updateValues($event)"
      [currentValue]="getCurrentValue('isLocked')"
      [element]="elements[0]"
    >
    </app-property-configurator-locked-element>

    <button
      *ngIf="this.selectedGroupElements?.length > 1 && !isLocked"
      mat-icon-button
      [matMenuTriggerFor]="alignment"
      matTooltip="Align"
      matTooltipPosition="above"
    >
      <mat-icon class="cursor-pointer">align_horizontal_left</mat-icon>
    </button>
    <button
      *ngIf="this.selectedGroupElements?.length > 2 && !isLocked"
      mat-icon-button
      [matMenuTriggerFor]="distribution"
      matTooltip="Distribute"
      matTooltipPosition="above"
    >
      <mat-icon class="cursor-pointer">horizontal_distribute</mat-icon>
    </button>

    <button
      data-test="match-size"
      *ngIf="selectedGroupElementsMatchSizeEligible?.length > 1 && !isLocked"
      mat-icon-button
      [matMenuTriggerFor]="matchsize"
      matTooltip="Resize images"
      matTooltipPosition="above"
    >
      <mat-icon class="icons material-symbols-outlined">select_all</mat-icon>
    </button>

    <mat-divider
      *ngIf="this.selectedGroupElements?.length > 1 && !isLocked"
      vertical="true"
      class="divider"
    ></mat-divider>

    <button
      mat-icon-button
      data-test="composer-toolbar-context-menu"
      (click)="openContextMenu($event)"
      *ngIf="properties?.length > 0"
    >
      <mat-icon>more_vert</mat-icon>
    </button>

    <!-- <app-property-configurator-drop-item
      *ngIf="properties.includes('entity-details') && elements?.length === 1"
      [currentValue]="elements[0]">
    </app-property-configurator-drop-item> -->
  </div>
</div>

<mat-menu #alignment="matMenu">
  <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.horizontal_left')">
    <mat-icon>align_horizontal_left</mat-icon> Left
  </button>
  <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.horizontal_center')">
    <mat-icon>align_horizontal_center</mat-icon> Center
  </button>
  <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.horizontal_right')">
    <mat-icon>align_horizontal_right</mat-icon> Right
  </button>
  <mat-divider></mat-divider>
  <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.vertical_top')">
    <mat-icon>align_vertical_top</mat-icon> Top
  </button>
  <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.vertical_middle')">
    <mat-icon>align_vertical_center</mat-icon> Middle
  </button>
  <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('align.vertical_bottom')">
    <mat-icon>align_vertical_bottom</mat-icon> Bottom
  </button>
</mat-menu>

<mat-menu #distribution="matMenu">
  <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('distribute.horizontal')">
    <mat-icon>horizontal_distribute</mat-icon> Horizontal
  </button>
  <button class="context-menu-button" mat-menu-item (click)="emitActionRequest('distribute.vertical')">
    <mat-icon>vertical_distribute</mat-icon> Vertical
  </button>
</mat-menu>

<mat-menu #matchsize="matMenu">
  <button
    data-test="match-height"
    class="context-menu-button"
    mat-menu-item
    (click)="emitActionRequest('match.height')"
  >
    Match height
  </button>
  <button data-test="match-width" class="context-menu-button" mat-menu-item (click)="emitActionRequest('match.width')">
    Match width
  </button>
</mat-menu>
