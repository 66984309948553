import { Injectable } from '@angular/core';
import { DocumentAction, DocumentChangeType, DocumentElement, DocumentElementFactory } from '@contrail/documents';
import { DocumentService } from '../document.service';
import { Entities } from '@contrail/sdk';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { AuthService } from '@common/auth/auth.service';

import { DocumentSelectors } from '../document-store';
import { CanvasUtil } from 'src/app/presentation/canvas-lib';
import { CanvasImageLoader } from 'src/app/presentation/canvas-lib';
import { TextMetrics } from 'src/app/presentation/canvas-lib';
import { FileDownloader } from 'src/app/presentation/canvas-lib';
import { environment } from 'src/environments/environment';

export interface EmbedInfo {
  thumbnail?: string;
  title: string;
  description?: string;
  entityType?: string;
  isIframe?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DocumentIframeService {
  ownerReference;
  private viewSize: any;
  constructor(
    private store: Store<RootStoreState.State>,
    private authService: AuthService,
    private documentService: DocumentService,
  ) {
    this.ownerReference = this.documentService.ownerReference;
    this.store.select(DocumentSelectors.viewSize).subscribe((viewSize) => (this.viewSize = viewSize));
  }

  async createIframeElement(element: DocumentElement, skipPreview = false, isIframe = false) {
    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));
    element.embedInfo.isIframe = isIframe;
    if (!skipPreview) {
      let url = element.url;
      if (element.url.indexOf('<iframe') > -1) {
        const htmlElement = new DOMParser().parseFromString(url, 'text/html');
        const iframe = htmlElement.querySelector('iframe');
        url = iframe.src;
      }
      const embedInfo: EmbedInfo =
        (await new Entities().create({
          entityName: 'web-page-preview',
          object: { url, entityReferenceId: this.ownerReference },
        })) || {};
      element.embedInfo = Object.assign(element.embedInfo, embedInfo);
      const height = Math.round(await this.calculateElementHeight(element)) + 40;
      element.size.height = height;
    }

    // set the position to the center of screen
    const xPos = (this.viewSize.boundingClientRect.width - element.size.width * this.viewSize.viewScale.x) * 0.5;
    const ypos = (this.viewSize.boundingClientRect.height - element.size.height * this.viewSize.viewScale.x) * 0.5;
    const position = CanvasUtil.toDocumentPosition(
      xPos,
      ypos,
      this.viewSize.viewBox,
      this.viewSize.viewScale,
      this.viewSize.boundingClientRect,
    );
    element.position = position;

    const newElement = DocumentElementFactory.createElement('iframe', element);
    const action = new DocumentAction(
      {
        changeType: DocumentChangeType.ADD_ELEMENT,
        elementData: newElement,
      },
      {
        elementId: newElement.id,
        changeType: DocumentChangeType.DELETE_ELEMENT,
        elementData: element,
      },
    );
    this.documentService.handleDocumentActions([action]);
    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
  }

  private async calculateElementHeight(element: DocumentElement) {
    const width = element.size.width;
    const textLineWidth = width - 70;
    let calculatedHeight = (element.size.height * 2) / 3;
    if (element.embedInfo?.thumbnail) {
      const authContext = await this.authService.getAuthContext();
      const fileDownLoader = new FileDownloader(
        { apiToken: authContext.token, orgSlug: authContext.currentOrg.orgSlug },
        { imageHost: environment.imageHost },
      );
      const image = await CanvasImageLoader.getLoadImagePromise(
        element.embedInfo.thumbnail,
        { url: element.embedInfo.thumbnail, type: '' },
        fileDownLoader,
        null,
        false,
      );
      const aspectRatio = image.imageSize.height / image.imageSize.width;
      const thumbnailHeight = width * aspectRatio;
      calculatedHeight = thumbnailHeight;
    }

    // with title
    const canvasElement = document.createElement('canvas');
    const ctx = canvasElement.getContext('2d');
    let wrappedTitle: string[] = [element.url];
    ctx.font = '500 30px Roboto';
    if (element.embedInfo?.title) {
      wrappedTitle = TextMetrics.wordWrap(ctx, element.embedInfo.title, textLineWidth);
    }
    calculatedHeight = calculatedHeight + wrappedTitle.length * 33;

    // with description
    ctx.font = '21px Roboto';
    let wrappedDescription: string[] = [];
    if (element.embedInfo?.description) {
      calculatedHeight = calculatedHeight + 40;
      wrappedDescription = TextMetrics.wordWrap(ctx, element.embedInfo.description, textLineWidth);
    }
    calculatedHeight = calculatedHeight + (wrappedDescription.length > 3 ? 56 : 28);
    return calculatedHeight;
  }
}
