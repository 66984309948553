import { TableMergeService, TablePasteService, TableRange, TableService } from '@contrail/document-table';
import { DocumentAction, DocumentChangeType, DocumentElement } from '@contrail/documents';
import { DocumentService } from '../document.service';

export class DocumentTableService {
  constructor(private documentService: DocumentService) {}

  public add(direction: 'row' | 'column', index, tableElement: DocumentElement, childElements: DocumentElement[]) {
    this.documentService.handleDocumentElementChanges(
      direction === 'column'
        ? TableService.addColumn(tableElement, childElements, index)
        : TableService.addRow(tableElement, childElements, index),
    );
  }

  public move(
    direction: 'column' | 'row',
    tableElement: DocumentElement,
    childElements: DocumentElement[],
    from: number,
    to: number,
  ) {
    const isColumn = direction === 'column';
    const key = isColumn ? 'columnIds' : 'rowIds';

    let data;

    try {
      data = TableService.move(key, tableElement, childElements, from, to);
    } catch (e) {
      console.error(e);
      this.documentService.snackBar.open(e.message, '', { duration: 5000 });
      throw e;
    }
    this.documentService.handleDocumentElementChanges(data);
  }

  public clear(cells: DocumentElement[]) {
    const actions = [];
    cells.forEach((cell) => {
      const undoElementData: DocumentElement = {
        id: cell.id,
        text: cell.text,
        type: cell.type,
        size: { ...cell.size },
      };
      TableService.clearCell(cell);
      actions.push(
        new DocumentAction(
          {
            elementId: cell.id,
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementData: {
              id: cell.id,
              text: cell.text,
              type: cell.type,
              size: { ...cell.size },
            },
          },
          {
            elementId: cell.id,
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementData: undoElementData,
          },
        ),
      );
    });
    if (actions.length > 0) {
      this.documentService.handleDocumentActions(actions);
    }
  }

  public delete(
    direction: 'column' | 'row',
    tableElement: DocumentElement,
    childElements: DocumentElement[],
    indexes: number[],
  ) {
    const isColumn = direction === 'column';
    let data;
    try {
      data = isColumn
        ? TableService.deleteColumns(tableElement, childElements, indexes)
        : TableService.deleteRows(tableElement, childElements, indexes);
    } catch (e) {
      console.error(e);
      this.documentService.snackBar.open(e.message, '', { duration: 5000 });
      throw e;
    }

    if (data) {
      this.documentService.handleDocumentElementChanges(data);
    }
  }

  public deleteTable(tableElement: DocumentElement, childElements: DocumentElement[]) {
    this.documentService.handleDocumentElementChanges(TableService.deleteTable(tableElement, childElements));
  }

  public paste(
    tableElement: DocumentElement,
    childElements: DocumentElement[],
    elementsToPaste: DocumentElement[],
    targetRow: number,
    targetColumn: number,
  ) {
    this.documentService.handleDocumentElementChanges(
      TablePasteService.pasteTableCells(tableElement, childElements, elementsToPaste, targetRow, targetColumn),
    );
  }

  public merge(tableElement: DocumentElement, childElements: DocumentElement[], tableRange: TableRange) {
    this.documentService.handleDocumentElementChanges(TableMergeService.merge(tableElement, childElements, tableRange));
  }

  public unmerge(tableElement: DocumentElement, childElements: DocumentElement[], tableRange: TableRange) {
    this.documentService.handleDocumentElementChanges(
      TableMergeService.unmerge(tableElement, childElements, tableRange),
    );
  }
}
