import { State } from '../showcases.state';

import { ActionCreator, on } from '@ngrx/store';
import * as CollectionElementActions from './collection-status-messages.actions';
import { collectionStatusEntityAdapter } from './collection-status-messages.state';

const setData = (state: State, { data }) => {
  return {
    ...state,
    collectionStatusMessages: collectionStatusEntityAdapter.setAll(data, state.collectionStatusMessages),
  };
};
const clearData = (state: State, {}) => {
  return {
    ...state,
    collectionStatusMessages: collectionStatusEntityAdapter.removeAll(state.collectionStatusMessages),
  };
};
const add = (state: State, { message }) => {
  // console.log(`received add message request for ${JSON.stringify(message)}`);

  return {
    ...state,
    collectionStatusMessages: collectionStatusEntityAdapter.addOne(message, state.collectionStatusMessages),
  };
};
const update = (state: State, { id, changes }) => {
  return {
    ...state,
    collectionStatusMessages: collectionStatusEntityAdapter.updateOne({ id, changes }, state.collectionStatusMessages),
  };
};
const remove = (state: State, { id }) => {
  return {
    ...state,
    collectionStatusMessages: collectionStatusEntityAdapter.removeOne(id, state.collectionStatusMessages),
  };
};
const removeMultiple = (state: State, { ids }) => {
  return {
    ...state,
    collectionElements: collectionStatusEntityAdapter.removeMany(ids, state.collectionStatusMessages),
  };
};

export const collectionStatusMessagesReducers: ActionCreator<any, any>[] = [
  on(CollectionElementActions.clearStatusMessages, clearData),
  on(CollectionElementActions.addStatusMessage, add),
  on(CollectionElementActions.removeStatusMessage, remove),
];
