import { Injectable } from '@angular/core';
import { Entities, EntityReference } from '@contrail/sdk';
import { Collaborators, SharedLinkPrincipal } from './interfaces';
import { Group, User } from '@contrail/entity-types';

@Injectable({
  providedIn: 'root',
})
export class SharedLinkCollaboratorService {
  constructor() {}

  public loadCollaborators(sharedLinkId: string): Promise<Collaborators[]> {
    return new Entities().get({
      entityName: 'shared-link-principal',
      criteria: {
        sharedLinkId,
      },
      relations: ['principal'],
    });
  }

  public addCollaborator(collaborator: SharedLinkPrincipal): Promise<Collaborators> {
    if (!collaborator.principalReference && !collaborator.userEmail) {
      console.error('Either principal reference or userEmail is neccessary');
      return;
    }
    if (!collaborator.sharedLinkId) {
      console.error('sharedLinkId is neccessary');
      return;
    }
    if (!collaborator.accessLevel) {
      console.error('accessLevel is neccessary');
      return;
    }

    const object: SharedLinkPrincipal = {
      sharedLinkId: collaborator.sharedLinkId,
      accessLevel: collaborator.accessLevel,
    };

    if (collaborator.principalReference) {
      object['principalReference'] = collaborator.principalReference;
    } else {
      object['userEmail'] = collaborator.userEmail;
    }

    return new Entities().create({
      entityName: 'shared-link-principal',
      object,
    });
  }

  public updateCollaborator(accessLevel: string, id: string): Promise<Collaborators> {
    return new Entities().update({
      entityName: 'shared-link-principal',
      id,
      object: {
        accessLevel,
      },
    });
  }

  public removeCollaborator(id: string): Promise<Collaborators> {
    return new Entities().delete({
      entityName: 'shared-link-principal',
      id,
    });
  }

  async getPrincipalDetails(ref: string): Promise<User | Group> {
    const entityRef = new EntityReference(ref);
    return new Entities().get({
      entityName: entityRef.entityType,
      id: entityRef.id,
    });
  }
}
