import { AfterViewInit, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { tap, debounceTime, takeUntil } from 'rxjs/operators';
import { ShowcasesActions, ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { Presentation, PresentationFrame } from '../../../presentation';
import { ComposerToolbarService } from '../../composer-toolbar/composer-toolbar.service';
import { ComposerGridFrameService } from './composer-grid-frame.service';
import { AnnotationOption } from 'src/app/presentation/document/document-annotation/document-annotation-options';
import { DocumentService } from 'src/app/presentation/document/document.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { ComposerService } from '../../composer.service';
import {
  ComposerFrameLayoutService,
  GRID_VIEW_APPLICATION_SLUG,
} from '../composer-frame-layout/composer-frame-layout.service';
import { DocumentStatusMessageService } from 'src/app/presentation/document/document-status/document-status-message.service';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { SearchReplaceSelectors } from '@common/search-replace/search-replace-store';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
import { CollectionStatusMessage } from '@common/collection-status-message/collection-status-message';

@Component({
  selector: 'app-composer-grid-frame',
  templateUrl: './composer-grid-frame.component.html',
  styleUrls: ['./composer-grid-frame.component.scss'],
})
export class ComposerGridFrameComponent implements OnChanges, OnDestroy {
  private destroy$ = new Subject();

  annotationType;
  annotationOptions: AnnotationOption[];
  searchResults$: Observable<any[]>;
  activeSearchResultElement$: Observable<any>;
  statusMessages$: Observable<CollectionStatusMessage[]>;
  editorMode$: Observable<EditorMode>;

  cursorClass = '';
  public filterLabel;
  public icon = 'item-collection-placeholder';
  public title = 'Add your first section to get started!';
  public footer =
    'Grids are organized in sections that hold items. Create the first one to start adding items to your grid.';

  @Input() frame: PresentationFrame;

  constructor(
    private store: Store<RootStoreState.State>,
    private composerToolbarService: ComposerToolbarService,
    private composerGridFrameService: ComposerGridFrameService,
    private documentService: DocumentService,
    private composerService: ComposerService,
    private layoutService: ComposerFrameLayoutService,
    private documentStatusMessageService: DocumentStatusMessageService,
  ) {
    this.editorMode$ = this.store.select(EditorModeSelectors.editorMode);
    this.searchResults$ = this.store.select(SearchReplaceSelectors.searchResults);
    this.activeSearchResultElement$ = this.store.select(SearchReplaceSelectors.activeSearchResultElement);
    this.statusMessages$ = this.documentStatusMessageService.statusMessages.pipe(debounceTime(1000));

    this.annotationOptions = this.documentService.getAnnotationOptions();
    this.store
      .select(ShowcasesSelectors.annotationType)
      .pipe(
        tap((annotationType) => {
          this.annotationType = annotationType;
          if (annotationType) {
            const currentAnnotationOption = this.annotationOptions.find(
              (annotationOption) => annotationOption.type === annotationType,
            );
            this.cursorClass = currentAnnotationOption.type + '-cursor';
          } else {
            this.cursorClass = null;
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.frame && changes.frame.previousValue?.id !== changes.frame.currentValue?.id) {
      const contextReference = this.composerService.currentFrameContextReference;
      await this.layoutService.getViewDefinition(contextReference, GRID_VIEW_APPLICATION_SLUG);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  openItemChooser() {
    this.composerToolbarService.showItemChooser();
  }

  openAddSection() {
    this.composerGridFrameService.addSection();
  }

  onClick(event) {
    this.store.dispatch(ShowcasesActions.setGridFrameActiveContainer({ gridFrameActiveContainer: null }));
  }
}
