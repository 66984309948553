<app-modal [title]="page === 1 ? 'New grid frame' : 'Select grouping'" [dialogRef]="dialogRef">
  <div *ngIf="page === 1">
    <div class="mb-7">
      You can create a grid frame from scratch or generate it automatically from an existing assortment and edit the
      layout afterwards.
    </div>
    <div class="mb-5">
      <mat-radio-group class="flex flex-col" [formControl]="frameType">
        <mat-radio-button class="mb-2" value="manual">
          <span class="font-medium">Start from scratch. </span><span>Create a layout and add items manually</span>
        </mat-radio-button>
        <mat-radio-button class="" value="assortment">
          <span class="font-medium">Generate from assortment. </span>Automatically create grid from source
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <app-assortment-selector
      [outputType]="'ENTITY'"
      *ngIf="frameType.value === 'assortment'"
      [spanWorkspaces]="true"
      (selectedAssortmentEvent)="setAssortment($event)"
      [defaultCurrentWorkspaceId]="currentWorkspaceId"
      [defaultSourceAssortmentId]="sourceAssortmentId"
    ></app-assortment-selector>
  </div>

  <div *ngIf="page === 2" class="mb-3">
    <div class="mb-7">Select one or multiple properties to create sections within the grid frame.</div>
    <div *ngIf="frameType.value === 'assortment'" class="mt-4">
      <div class="flex justify-between">
        <mat-form-field class="w-[49%]" appearance="fill">
          <mat-label>Dimension 1</mat-label>
          <input matInput [matAutocomplete]="dimension1" [formControl]="dimensionControl1" />
          <mat-autocomplete
            #dimension1="matAutocomplete"
            (optionSelected)="onDimensionSelected($event, 1)"
            [displayWith]="displayFn"
          >
            <ng-container *ngFor="let property of filteredProperties1 | async">
              <mat-option class="text-[14px]" [value]="property" *ngIf="dimensionControl2.value !== property">
                <mat-icon class="text-[20px]">{{ getPropertyIcon(property.propertyDefinition.propertyType) }}</mat-icon
                >{{ property.propertyDefinition.label }}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
          <mat-error *ngIf="dimensionControl1.errors">{{ dimensionControl1.errors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-[49%]" appearance="fill">
          <mat-label>Dimension 1 Values</mat-label>
          <input matInput [readonly]="!dimensionControl1.value" [formControl]="dimensionValueControl1" />
          <app-grid-frame-dimension-value-picker
            *ngIf="dimensionControl1.value"
            [dimensionValueOptions]="dimension1Options"
            (selectedValuesEmitter)="setDimension1Values($event)"
          >
          </app-grid-frame-dimension-value-picker>
          <mat-error *ngIf="dimensionValueControl1.errors">{{ dimensionValueControl1.errors }}</mat-error>
        </mat-form-field>
      </div>
      <div class="flex justify-between mb-2">
        <span>Create a separate frame for each dimension 1 values</span>
        <mat-slide-toggle
          class="mr-4"
          color="primary"
          (change)="toggleSplitDimension($event)"
          [checked]="splitDimension"
        ></mat-slide-toggle>
      </div>
      <div class="flex justify-between">
        <mat-form-field class="w-[49%]" appearance="fill">
          <mat-label>Dimension 2</mat-label>
          <input matInput [matAutocomplete]="dimension2" [formControl]="dimensionControl2" />
          <mat-autocomplete
            #dimension2="matAutocomplete"
            (optionSelected)="onDimensionSelected($event, 2)"
            [displayWith]="displayFn"
          >
            <ng-container *ngFor="let property of filteredProperties2 | async">
              <mat-option class="text-[14px]" [value]="property" *ngIf="dimensionControl1.value !== property">
                <mat-icon class="text-[20px]">{{ getPropertyIcon(property.propertyDefinition.propertyType) }}</mat-icon
                >{{ property.propertyDefinition.label }}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
          <mat-error *ngIf="dimensionControl2.errors">{{ dimensionControl2.errors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="w-[49%]" appearance="fill">
          <mat-label>Dimension 2 Values</mat-label>
          <input matInput [readonly]="!dimensionControl2.value" [formControl]="dimensionValueControl2" />
          <app-grid-frame-dimension-value-picker
            *ngIf="dimensionControl2.value"
            [dimensionValueOptions]="dimension2Options"
            (selectedValuesEmitter)="setDimension2Values($event)"
          >
          </app-grid-frame-dimension-value-picker>
          <mat-error *ngIf="dimensionValueControl2.errors">{{ dimensionValueControl2.errors }}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="flex justify-between">
      <span>Create sections for empty values</span>
      <mat-slide-toggle
        class="mr-4"
        color="primary"
        (change)="toggleEmptyValueSection($event)"
        [checked]="emptyValueSection"
        labelPosition="before"
      ></mat-slide-toggle>
    </div>
  </div>

  <div class="flex justify-between mt-12">
    <div>
      <button *ngIf="frameType.value === 'assortment' && page === 2" mat-flat-button (click)="back()">BACK</button>
    </div>
    <div>
      <button mat-flat-button (click)="cancel()">CANCEL</button>
      <button
        *ngIf="frameType.value === 'assortment' && page === 1 && selectedAssortmentObject"
        mat-flat-button
        color="primary"
        (click)="next()"
      >
        NEXT
      </button>
      <button *ngIf="frameType.value === 'manual' || page === 2" mat-flat-button color="primary" (click)="create()">
        CREATE
      </button>
    </div>
  </div>
</app-modal>
