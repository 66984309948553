export class RecentShowcaseStore {
  /** Filters a list of showcases to set that matches the
   * list of ids that were recently accessed.
   */
  public static getRecentShowcases(showcases) {
    const recentShowCaseIds = localStorage.getItem('showCaseIds') || '';
    let recentShowcases = [];
    recentShowCaseIds.split(',').forEach((id) => {
      const filtered = showcases.filter((showcase) => showcase.id === id);
      recentShowcases = recentShowcases.concat(filtered);
    });
    return recentShowcases;
  }

  /** Updates localstorate to refelect recently accessed showcases.
   * Limits storage to 4 items.
   */
  public static storeShowcaseId(showcaseId) {
    let showcaseIds = localStorage.getItem('showCaseIds') || showcaseId;
    const showcaseIdsArr = showcaseIds.split(',').filter((id) => id !== showcaseId);
    showcaseIdsArr.unshift(showcaseId);
    showcaseIds = showcaseIdsArr.slice(0, 5).join();
    console.log('Stored showcase ids value :  ', showcaseIds);
    localStorage.setItem('showCaseIds', showcaseIds);
  }
}
