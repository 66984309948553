import { Component, OnDestroy, OnInit } from '@angular/core';
import { trigger, state, style } from '@angular/animations';
import { SidePanelView } from './side-panel.service';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { RootStoreState } from 'src/app/root-store';
import { DocumentSelectors, DocumentActions } from '../../document/document-store';
import { SideMenuOverlay } from '../../document/document-store/document.state';
import { ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { CollectionStatusMessageService } from './status-messages/collection-status-message.service';
import { CollectionStatusMessage } from '@common/collection-status-message/collection-status-message';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { Feature } from '@common/feature-flags/feature-flag';
import { ComposerService } from '../composer.service';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          opacity: 1,
          'margin-right': 0,
        }),
      ),
      state(
        'closed',
        style({
          opacity: 0,
          'margin-right': -1000,
        }),
      ),
      /*
      transition('open => closed', [
        animate('10s ease-in-out', style({ opacity: 0.5, 'margin-right': -1000 }))
      ]),
      transition('closed => open', [
        animate('10.0s ease-in-out', style({ 'margin-right': 0 , opacity: 0.5}))
      ]),*/
    ]),
  ],
})
export class SidePanelComponent implements OnInit, OnDestroy {
  toggleChooser$: Observable<any>;
  showcase$: Observable<any>;
  public currentView: SidePanelView;
  messages$: Observable<Array<CollectionStatusMessage>>;
  showSourceAssortmentWarning$: Observable<boolean>;
  public itemSourceChooserLoaded = false;
  public itemChooserLoaded = false;
  public colorChooserLoaded = false;
  public assetChooserLoaded = false;
  editorMode$: Observable<EditorMode>;
  public editorMode: EditorMode;
  subscription = new Subscription();

  constructor(
    private store: Store<RootStoreState.State>,
    private collectionMessageStatusService: CollectionStatusMessageService,
    private composerService: ComposerService,
  ) {}

  ngOnInit(): void {
    this.toggleChooser$ = this.store.select(DocumentSelectors.toggleChooser);
    this.showcase$ = this.store.select(ShowcasesSelectors.currentShowcase);
    this.messages$ = this.collectionMessageStatusService.messages$;
    this.showSourceAssortmentWarning$ = this.store.select(ShowcasesSelectors.showSourceAssortmentWarning);
    this.editorMode$ = this.store.select(EditorModeSelectors.editorMode);
    this.subscription.add(this.store.select(EditorModeSelectors.editorMode).subscribe((m) => (this.editorMode = m)));
    this.toggleChooser$.subscribe((chooser) => {
      // this.itemChooserLoaded = false;
      // this.itemSourceChooserLoaded = false;
      // this.colorChooserLoaded = false;
      // this.assetChooserLoaded = false;
      if (chooser?.slug === 'addProduct' && !this.itemChooserLoaded && chooser?.targetSourceType) {
        this.itemChooserLoaded = true;
      }
      if (chooser?.slug === 'addProduct' && !this.itemSourceChooserLoaded && !chooser?.targetSourceType) {
        this.itemSourceChooserLoaded = true;
      }
      if (chooser?.slug === 'addColor' && !this.colorChooserLoaded) {
        this.colorChooserLoaded = true;
      }
      if (chooser?.slug === 'addAsset' && !this.assetChooserLoaded) {
        this.assetChooserLoaded = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  handleClose() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = '';
    overlay.slug = '';
    overlay.showChooser = false;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  handleMessageClick(event) {
    this.collectionMessageStatusService.setStatusMessageElement(event);
  }

  handleToggleShowWarning(event) {
    this.collectionMessageStatusService.toggleShowWarning(event);
  }

  handleAddEntities(event) {
    if (this.editorMode !== EditorMode.EDIT) {
      return;
    }
    if (event.length === 1) {
      this.composerService.addItemToDocumentFrame(event[0]);
    } else {
      this.composerService.addItemsToDocumentFrame(event);
    }
  }
}
