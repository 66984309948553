import { Component, OnInit } from '@angular/core';
import { DocumentActions } from '../../../document/document-store';
import { SideMenuOverlay } from '../../../document/document-store/document.state';
import { ComposerService } from '../../composer.service';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';

@Component({
  selector: 'app-composer-frame-settings',
  templateUrl: './composer-frame-settings.component.html',
  styleUrls: ['./composer-frame-settings.component.scss'],
})
export class ComposerFrameSettingsComponent implements OnInit {
  public currentFrame;
  constructor(private store: Store<RootStoreState.State>) {}

  ngOnInit(): void {}

  hide() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Property Editor';
    overlay.slug = 'componentEditor';
    overlay.showChooser = false;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }
}
