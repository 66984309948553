<div class="w-full" (click)="onClick($event)" [ngClass]="cursorClass">
  <div class="h-full flex flex-col bg-white" *ngIf="frame.collection.set.length === 0">
    <app-empty-placeholder [title]="title" [icon]="icon" [footer]="footer"></app-empty-placeholder>
    <div class="flex justify-center mt-3" *ngIf="(editorMode$ | async) === 'EDIT'">
      <button
        mat-raised-button
        color="primary"
        data-test="app-composer-grid-frame-item-add-section"
        (click)="openAddSection()"
      >
        ADD SECTION
      </button>
    </div>
  </div>
  <div class="collection-main-body" *ngIf="frame.collection.set.length > 0">
    <app-composer-grid-frame-editor
      [frame]="frame"
      [annotationType]="annotationType"
      [annotationOptions]="annotationOptions"
      [statusMessages]="statusMessages$ | async"
      [searchResults]="searchResults$ | async"
      [activeSearchResultElement]="activeSearchResultElement$ | async"
    ></app-composer-grid-frame-editor>
  </div>
</div>
