import { Injectable } from '@angular/core';
import { CopiedProperties } from '../../canvas-lib';
import { DocumentService } from '../document.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentPropertiesService {
  private copiedProperties: CopiedProperties;

  constructor(private documentService: DocumentService) {
    this.documentService.actionRequests.subscribe((request) => {
      if (request?.actionType === 'copy_properties') {
        this.copiedProperties = this.documentService.copySelectedElementProperties();
      } else if (request?.actionType === 'paste_properties') {
        const selectedTextElements = this.documentService
          .getSelectedElements()
          ?.filter((e) => !e.isLocked && e.type === 'text');
        if (this.copiedProperties && selectedTextElements?.length > 0) {
          this.documentService.applyCopiedProperties(this.copiedProperties);
        }
      }
    });
  }

  public getCopiedProperties() {
    return this.copiedProperties;
  }
}
