import * as CollectionStatusMessagesActions from './collection-status-messages/collection-status-messages.actions';
import * as CollectionStatusMessagesSelectors from './collection-status-messages/collection-status-messages.selectors';

import * as ShowcasesActions from './showcases.actions';
import * as ShowcasesSelectors from './showcases.selectors';
import * as ShowcasesStoreState from './showcases.state';

export { ShowcasesStoreModule } from './showcases-store.module';

const actions = {
  ...ShowcasesActions,
  ...CollectionStatusMessagesActions,
};

const selectors = {
  ...ShowcasesSelectors,
  ...CollectionStatusMessagesSelectors,
};
export { actions as ShowcasesActions, selectors as ShowcasesSelectors, ShowcasesStoreState };
