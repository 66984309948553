<div class="w-full h-full flex">
  <div class="option-container">
    <div
      *ngFor="let nav of sideNavOptions"
      (click)="currentSideNav = nav.label"
      class="cursor-pointer flex-center w-10 h-10"
    >
      <mat-icon
        *ngIf="nav?.svgIcon"
        [svgIcon]="nav.svgIcon"
        class="nav-icon"
        [class.active]="currentSideNav === nav.label"
      >
      </mat-icon>
      <mat-icon *ngIf="nav?.icon" class="nav-icon" [class.active]="currentSideNav === nav.label">
        {{ nav.icon }}
      </mat-icon>
    </div>
  </div>
  <div class="placeholder-content flex-center">
    <div class="wrap w-[680px] h-[680px] flex-center">
      <app-webgl-viewer *ngIf="content && currentSideNav !== 'config'" [size]="'678px'" [content]="content">
      </app-webgl-viewer>
      <div class="flex-center relative" *ngIf="currentSideNav === 'config'">
        <canvas id="renderCanvasConfig" class="w-[678px] h-[678px]"></canvas>

        <div class="canvas-buttons flex-center">
          <img src="/assets/images/3d_coordinates.svg" id="webglMove" matTooltip="Offset" matTooltipPosition="above" />
          <img src="/assets/images/3d_rotate.svg" id="webglRotate" matTooltip="Rotation" matTooltipPosition="above" />
          <img src="/assets/images/zoom_in.svg" id="webglZoomIn" matTooltip="Zoom in" matTooltipPosition="above" />
          <img src="/assets/images/zoom_out.svg" id="webglZoomOut" matTooltip="Zoom out" matTooltipPosition="above" />
        </div>
      </div>
    </div>
  </div>

  <div class="config-container w-[340px]" *ngIf="currentSideNav !== 'full'">
    <div class="flex flex-col h-full">
      <h6 class="py-3 px-2">{{ configNavTitle }}</h6>
      <mat-divider></mat-divider>
      <ng-container [ngSwitch]="currentSideNav">
        <ng-template *ngSwitchCase="'config'" [ngTemplateOutlet]="showroomConfig"></ng-template>
        <ng-template *ngSwitchCase="'image'" [ngTemplateOutlet]="overlay"></ng-template>
        <ng-template *ngSwitchCase="'pattern'" [ngTemplateOutlet]="overlay"></ng-template>
        <ng-template *ngSwitchCase="'recolor'" [ngTemplateOutlet]="recolorConfig"></ng-template>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #showroomConfig>
  <app-webgl-content-configurator
    [content]="content"
    [size]="'339px'"
    [viewMode]="'modal'"
    [contentModal]="true"
  ></app-webgl-content-configurator>
</ng-template>

<ng-template #overlay>
  <app-webgl-overlay-configurator
    [content]="content"
    [type]="currentSideNav"
    [webglViewer]="webglView.viewer"
  ></app-webgl-overlay-configurator>
</ng-template>

<ng-template #recolorConfig>
  <div class="mt-1">
    <span
      [style.background]="color"
      [cpWidth]="'320px'"
      autocomplete="off"
      [(colorPicker)]="color"
      [cpSaveClickOutside]="true"
      [cpPresetColors]="presetColors"
      [cpPositionOffset]="'10%'"
      [cpAlphaChannel]="'disabled'"
      [cpPresetColorsClass]="'preset-colors'"
      [cpToggle]="true"
      [cpDialogDisplay]="'inline'"
      (colorPickerChange)="colorChange($event)"
      placeholder=""
    >
    </span>
  </div>
  <button
    mat-raised-button
    color="primary"
    class="!mx-auto !my-4 w-64"
    appDebounceClick
    [debounceTime]="1000"
    (debounceClick)="generateColorVariant()"
  >
    GENERATE COLOR VARIANT
  </button>
</ng-template>
