import { Action, createReducer, on } from '@ngrx/store';
import * as ShowcasesActions from './showcases.actions';
import { showcasesEntityAdapter, showcasesInitialState, State } from './showcases.state';
import { collectionStatusMessagesReducers } from './collection-status-messages/collection-status-messages.reducers';

const setData = (state: State, { data }) => {
  return {
    ...state,
    showcases: showcasesEntityAdapter.setAll(data, state.showcases),
  };
};
const clearData = (state: State, {}) => {
  return {
    ...state,
    showcases: showcasesEntityAdapter.removeAll(state.showcases),
  };
};
const add = (state: State, { showcase }) => {
  return {
    ...state,
    showcases: showcasesEntityAdapter.addOne(showcase, state.showcases),
  };
};
const update = (state: State, { id, changes }) => {
  return {
    ...state,
    showcases: showcasesEntityAdapter.updateOne({ id, changes }, state.showcases),
  };
};
const remove = (state: State, { showcase }) => {
  return {
    ...state,
    showcases: showcasesEntityAdapter.removeOne(showcase.id, state.showcases),
  };
};
const setCurrentShowcase = (state: State, { showcase }) => {
  return {
    ...state,
    currentShowcase: showcase,
  };
};
const clearCurrentShowcase = (state: State) => {
  return {
    ...state,
    currentShowcase: null,
  };
};
const setItemsUsedInShowcase = (state: State, { itemsUsedInShowcase }) => {
  console.log(`itemsUsedInShowcase ${JSON.stringify(itemsUsedInShowcase)}`);

  return {
    ...state,
    itemsUsedInShowcase,
  };
};

const setGridFrameActiveContainer = (state: State, { gridFrameActiveContainer }) => {
  return {
    ...state,
    gridFrameActiveContainer,
  };
};

const setCollectionFrameActiveContainer = (state: State, { collectionFrameActiveContainer }) => {
  return {
    ...state,
    collectionFrameActiveContainer,
  };
};

const setAnnotationType = (state: State, { annotationType }) => {
  return {
    ...state,
    annotationType,
  };
};

const setStatusMessageElement = (state: State, { statusMessageElement }) => {
  return {
    ...state,
    statusMessageElement,
  };
};

const setShowSourceAssortmentWarning = (state: State, { showSourceAssortmentWarning }) => {
  return {
    ...state,
    showSourceAssortmentWarning,
  };
};

export const showcasesReducers = createReducer(
  showcasesInitialState,
  ...collectionStatusMessagesReducers,
  on(ShowcasesActions.loadShowcasesSuccess, setData),
  on(ShowcasesActions.createShowcaseSuccess, add),
  on(ShowcasesActions.copyShowcaseSuccess, add),
  on(ShowcasesActions.deleteShowcaseSuccess, remove),
  on(ShowcasesActions.updateShowcaseSuccess, update),
  on(ShowcasesActions.clearCurrentShowcase, clearCurrentShowcase),
  on(ShowcasesActions.loadCurrentShowcaseSuccess, setCurrentShowcase),
  on(ShowcasesActions.setItemsUsedInShowcase, setItemsUsedInShowcase),
  on(ShowcasesActions.setGridFrameActiveContainer, setGridFrameActiveContainer),
  on(ShowcasesActions.setCollectionFrameActiveContainer, setCollectionFrameActiveContainer),
  on(ShowcasesActions.setAnnotationType, setAnnotationType),
  on(ShowcasesActions.setStatusMessageElement, setStatusMessageElement),
  on(ShowcasesActions.setShowSourceAssortmentWarning, setShowSourceAssortmentWarning),
);

export function reducer(state: State | undefined, action: Action): any {
  return showcasesReducers(state, action);
}
