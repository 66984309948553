<div class="widgets-list">
  <app-composer-frame-toolbar-name></app-composer-frame-toolbar-name>
  <!-- <button
      *ngIf="editorMode === 'EDIT'" 
      (click)="showComposerFrameSettings()" 
      matTooltip="Frame Options" 
      mat-icon-button
      data-test="composer-frame-settings-button"
    >
      <mat-icon>settings</mat-icon>
    </button> -->
</div>
