<div class="flex items-center justify-between mr-2.5">
  <span class="text-xs">Background Color</span>
  <button
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    (click)="toggleDisplay()"
    mat-icon-button
    matTooltip="Frame background"
  >
    <mat-icon class="toggle-icon">format_color_fill</mat-icon>
  </button>
</div>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpened">
  <span
    #backgroundColorPicker
    [style.background]="color"
    autocomplete="off"
    [(colorPicker)]="color"
    [cpCancelButton]="false"
    [cpCloseClickOutside]="true"
    [cpPresetColors]="presetColors"
    [cpToggle]="true"
    [cpDialogDisplay]="'inline'"
    (colorPickerChange)="handleChange($event)"
    placeholder=""
  >
  </span>
</ng-template>
