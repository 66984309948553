import { Component } from '@angular/core';
import { ComposerToolbarService } from '../composer-toolbar.service';

@Component({
  selector: 'app-composer-toolbar-undo-redo',
  templateUrl: './composer-toolbar-undo-redo.component.html',
  styleUrls: ['./composer-toolbar-undo-redo.component.scss'],
})
export class ComposerToolbarUndoRedoComponent {
  constructor(private documentToolbarService: ComposerToolbarService) {}

  undo() {
    this.documentToolbarService.undoActions();
  }

  redo() {
    this.documentToolbarService.redoActions();
  }
}
