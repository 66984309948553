import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { IContent } from '../content-holder-details/content-holder-details.component';
import { AuthService } from '@common/auth/auth.service';
import { UntypedFormControl } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';

@Component({
  selector: 'app-content-info-panel',
  template: `
    <div>
      <div class="flex-between-center h-14">
        <h6 [matTooltip]="contentFileName" matTooltipPosition="above" class="ml-4">
          {{ contentFileName | shorten: 25 }}
        </h6>
      </div>
      <mat-divider></mat-divider>

      <mat-expansion-panel class="mat-elevation-z0" expanded>
        <mat-expansion-panel-header>
          <mat-panel-title> Details </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="my-3 content-details">
          <div class="info-label-value">
            <p>Extension</p>
            <p>{{ contentExtension | uppercase }}</p>
          </div>
          <div class="info-label-value">
            <p>Type</p>
            <p>{{ contentType | uppercase }}</p>
          </div>
          <div class="info-label-value">
            <p>Size</p>
            <p>{{ contentSize | number }} KB</p>
          </div>
          <div class="info-label-value">
            <p>Dimensions</p>
            <p>{{ 'N/A' }}</p>
          </div>
          <div class="info-label-value">
            <p>Created on</p>
            <p data-test="created-on">{{ contentCreatedOn | date: 'MM/dd/yyyy' }}</p>
          </div>
          <div class="info-label-value">
            <p>Last updated on</p>
            <p>{{ contentCreatedOn | date: 'MM/dd/yyyy' }}</p>
          </div>
        </div>

        <button
          *ngIf="(!downloadBtn || configureBtn) && is3d"
          mat-raised-button
          color="primary"
          class="!mx-6 !my-4 w-64"
          (click)="emitConfigure()"
        >
          CONFIGURE
        </button>
        <div *ngIf="downloadBtn && configureBtn && is3d" class="-mt-4"></div>
        <button *ngIf="downloadBtn" mat-raised-button color="primary" class="!mx-6 !my-4 w-64" (click)="download()">
          <mat-icon data-test="download">download</mat-icon> <span class="ml-2">DOWNLOAD</span>
        </button>

        <ng-container *ngIf="showVibeViewer">
          <mat-divider></mat-divider>
          <div class="p-2">
            <mat-slide-toggle
              color="primary"
              labelPosition="before"
              [checked]="useVibeViewer"
              (change)="toggleViewOption($event)"
            >
              <mat-panel-title class="!mr-28"> VibeIQ 3D Viewer </mat-panel-title>
            </mat-slide-toggle>
            <p class="mt-2">Toggle on to override external 3D viewer.</p>
          </div>
        </ng-container>
      </mat-expansion-panel>
      <mat-divider></mat-divider>
    </div>
  `,
  styles: [
    `
      .info-label-value {
        @apply text-black60 text-sm flex;
        p:first-child {
          @apply min-w-[140px] font-medium;
        }
      }
    `,
  ],
})
export class ContentInfoPanelComponent implements OnChanges, OnInit, OnDestroy {
  private destroy$ = new Subject();

  public is3d = false;
  private enableCustomAssetViewer: boolean = false; // org managed - can update in ADMIN app.
  public useVibeViewer = true; // if enableCustomAssetViewer is false, useVibeViewer must be TRUE

  public showVibeViewer = false;
  public useVibeViewerForm = new UntypedFormControl(false);

  @Output() openConfigure = new EventEmitter();
  @Output() downloadContent = new EventEmitter();
  @Output() updateContent = new EventEmitter();

  @Input() content: IContent;
  @Input() downloadBtn: boolean = false;
  @Input() configureBtn: boolean = false;

  constructor(private authService: AuthService) {
    this.enableCustomAssetViewer = this.authService.getCurrentOrg().orgConfig?.enableCustomAssetViewer;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.content && changes.content.previousValue?.id !== changes.content.currentValue?.id) {
      this.is3d = this.content?.primaryFile?.contentType?.indexOf('gltf-binary') > -1;
      this.showVibeViewer = this.is3d && this.enableCustomAssetViewer && this.content?.embedLink ? true : false;
      this.useVibeViewer =
        this.is3d && this.enableCustomAssetViewer && this.content?.embedLink && !this.content?.useVibeViewer
          ? false
          : true;
      this.useVibeViewerForm.setValue(this.useVibeViewer);
    }
  }

  ngOnInit() {
    this.useVibeViewerForm.valueChanges
      .pipe(debounceTime(900), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((value) => {
        if (this.showVibeViewer && this.content?.useVibeViewer !== value) {
          this.content = { ...this.content, useVibeViewer: value };
          this.updateContent.emit(this.content);
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  get contentFileName() {
    return this.content?.primaryFile?.fileName || '';
  }
  get contentExtension() {
    return this.contentFileName?.split('.')?.pop();
  }
  get contentSize() {
    return Math.round(this.content?.primaryFile?.size / 1000);
  }
  get contentUpdatedOn() {
    return this.content?.primaryFile?.updatedOn;
  }
  get contentCreatedOn() {
    return this.content?.primaryFile?.createdOn;
  }
  get contentType() {
    return this.content?.primaryFile?.contentType;
  }

  emitConfigure() {
    this.openConfigure.emit(true);
  }
  download() {
    this.downloadContent.emit(this.content);
  }

  toggleViewOption(evt) {
    this.useVibeViewerForm.setValue(evt.checked);
  }
}
