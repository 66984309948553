import { createAction, props } from '@ngrx/store';
import { DocumentViewSize, SideMenuOverlay } from './document.state';
import { PositionDefinition } from '@contrail/documents';

export enum DocumentActionTypes {
  TOGGLE_CHOOSER = '[Document] Toggle Chooser',
  SET_LAST_KNOWN_MOUSE_POSITION = '[Document] Set Last Known Mouse Position',
  SET_VIEW_SIZE = '[Document] Set View Size',
  NAVIGATE_TO_POSITION = '[Document] Navigate To Position On The Board',
  SET_ANNOTATED_ELEMENTS = '[Document] Set Annotated Elements',
  SET_DESIGN_MODE = '[Document] Set Design Mode',
}

////////////////////////////////////////////////////////////////////////////////

export const toggleChooser = createAction(DocumentActionTypes.TOGGLE_CHOOSER, props<{ overlay: SideMenuOverlay }>());

export const setLastKnownMousePosition = createAction(
  DocumentActionTypes.SET_LAST_KNOWN_MOUSE_POSITION,
  props<{ lastKnownMousePosition: PositionDefinition }>(),
);

export const setViewSize = createAction(DocumentActionTypes.SET_VIEW_SIZE, props<DocumentViewSize>());

export const navigateToPosition = createAction(
  DocumentActionTypes.NAVIGATE_TO_POSITION,
  props<{ position: PositionDefinition }>(),
);

export const setAnnotatedElements = createAction(
  DocumentActionTypes.SET_ANNOTATED_ELEMENTS,
  props<{ annotatedElements: any[] }>(),
);

export const setDesignMode = createAction(DocumentActionTypes.SET_DESIGN_MODE, props<{ designMode: boolean }>());
