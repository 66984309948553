import { Component, ViewChild, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { ShowcasesService } from '../showcases.service';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { ShowcasesActions, ShowcasesSelectors } from '../showcases-store';
import { AuthSelectors } from 'src/app/common/auth/auth-store';
import { SearchBarComponent } from 'src/app/common/components/search-bar/search-bar.component';
import { filter, map, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { ListColumnDefinition } from 'src/app/common/components/list/list-column-definition';
import { RoutingService } from 'src/app/common/routing/routing.service';
import { ContextMenuComponent } from 'src/app/common/components/context-menu/context-menu.component';
import { ShareShowcaseModalLauncher } from '../share-showcase/share-showcase-modal-launcher';
import { ConfirmationBoxService } from 'src/app/common/components/confirmation-box/confirmation-box';
import { SortByOptions } from '@common/util/filter-util';
import { CreateShowcaseModalLauncher } from '../create-showcase/create-showcase-modal-launcher';
import { AccessManagedType, Types } from '@contrail/sdk';
import { Request } from '@contrail/sdk';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { WorkspacePrincipalRole } from '@common/workspaces/workspace-principal';
import { CopyRenameEntityModalLauncher } from '@components/copy-rename-entity/copy-rename-entity-modal-launcher';

@Component({
  selector: 'app-showcases-list',
  templateUrl: './showcases-list.component.html',
  styleUrls: ['./showcases-list.component.scss'],
})
export class ShowcasesListComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$ = new Subject();

  public viewMode = 'cards';
  public columnDefinitions: Array<ListColumnDefinition>;
  sortOptions = SortByOptions;
  private sortDirectionSubject = new BehaviorSubject('desc');
  public sortDirection$ = this.sortDirectionSubject.asObservable();
  public sortByControl = new UntypedFormControl({ label: 'Date Modified', property: 'updatedOn' });
  public myShowCasesOnly = new UntypedFormControl(false);
  public showcases$: Observable<any>;
  public recentShowcases$: Observable<any>;
  public archived: boolean;
  public templates: boolean;
  public canCreate = true;
  editorMode = 'EDIT';

  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;
  @ViewChild(ContextMenuComponent) contextMenu: ContextMenuComponent;
  contextMenuActions = [];
  private launchContextMenuAction = {
    actionName: 'launch',
    icon: 'play_arrow',
    label: 'Launch',
    criteriaTest: (obj) => {
      return !obj.isArchived;
    },
  };
  private editAccessContextMenuActions = [
    { actionName: 'open', icon: 'open_in_new', label: 'Open' },
    { actionName: 'rename', icon: 'drive_file_rename_outline', label: 'Rename' },
    {
      actionName: 'copy',
      icon: 'content_copy',
      label: 'Copy',
      criteriaTest: (obj) => {
        return !obj.isArchived;
      },
    },
  ];

  constructor(
    private store: Store<RootStoreState.State>,
    private routingService: RoutingService,
    private showcasesService: ShowcasesService,
    private copyRenameEntityModalLauncher: CopyRenameEntityModalLauncher,
    private shareShareShowcaseModalLauncher: ShareShowcaseModalLauncher,
    private confirmationBoxService: ConfirmationBoxService,
    private createShowcaseModelLauncher: CreateShowcaseModalLauncher,
    private route: ActivatedRoute,
  ) {
    this.columnDefinitions = [
      {
        constantValue: 'assets/images/showcase_icon.svg',
        label: '',
        alignment: 'left',
        propertyType: 'icon',
      },
      {
        index: 'name',
        label: 'Name',
        alignment: 'left',
        propertyType: 'text',
      },
      {
        index: 'sourceAssortment.name',
        label: 'Source Assortment',
        alignment: 'left',
        propertyType: 'text',
      },
      {
        index: 'updatedBy.email',
        label: 'Updated By',
        alignment: 'right',
        propertyType: 'string',
      },
      {
        index: 'updatedOn',
        label: 'Updated',
        alignment: 'right',
        propertyType: 'date',
      },
      {
        index: 'createdBy.email',
        label: 'Created By',
        alignment: 'right',
        propertyType: 'string',
      },
      {
        index: 'createdOn',
        label: 'Created',
        alignment: 'right',
        propertyType: 'date',
      },
    ];
  }
  async ngOnInit(): Promise<void> {
    const showcaseType: AccessManagedType = await new Types().getType({ path: 'showcase' });
    this.canCreate = showcaseType.canCreate;
  }

  async ngAfterViewInit() {
    setTimeout(() => {
      this.subscribe();
    }, 0); // SOLVES STATE CHANGE ERROR
  }

  async subscribe() {
    this.showcasesService.clearSelectedShowcase();

    this.showcases$ = combineLatest([
      this.searchBar.valueChange.pipe(startWith('')),
      this.store.select(ShowcasesSelectors.showcases),
      this.route.queryParams.pipe(startWith('')),
      this.myShowCasesOnly.valueChanges.pipe(startWith(false)),
      this.sortByControl.valueChanges.pipe(startWith({ label: 'Date Modified', property: 'updatedOn' })),
      this.sortDirection$,
      this.store.select(AuthSelectors.selectAuthContext),
    ]).pipe(
      map(([searchTerm, showcases, queryParams, myShowcases, sortByOption, sortDirection, authContext]) => {
        console.log('queryParams: ', queryParams, myShowcases, authContext, sortByOption);
        const sortByField = sortByOption?.property;
        return showcases
          .filter((sc) => this.testSearch(sc, searchTerm))
          .filter((sc) => this.testQueryParams(sc, queryParams))
          .filter((sc) => this.testMyShowcases(sc, myShowcases, authContext))
          .sort((showcase1, showcase2) => {
            const value1 = ['createdOn', 'updatedOn'].includes(sortByField)
              ? new Date(showcase1[sortByField]).getTime()
              : showcase1[sortByField].toLowerCase();
            const value2 = ['createdOn', 'updatedOn'].includes(sortByField)
              ? new Date(showcase2[sortByField]).getTime()
              : showcase2[sortByField].toLowerCase();

            if (['createdOn', 'updatedOn'].includes(sortByField)) {
              if (sortDirection === 'asc') {
                return value1 - value2;
              } else {
                return value2 - value1;
              }
            } else {
              if (sortDirection === 'asc') {
                return value2 < value1 ? 1 : -1;
              } else {
                return value2 > value1 ? 1 : -1;
              }
            }
          });
      }),
    );

    this.store
      .select(WorkspacesSelectors.currentWorkspace)
      .pipe(
        filter((ws) => ws?.id),
        tap(async (ws) => {
          this.store.dispatch(ShowcasesActions.loadShowcases());
          const userRole = await Request.request(`/workspaces/${ws.id}/callerRole`, {});
          if (userRole.role === WorkspacePrincipalRole.VIEWER) {
            this.editorMode = EditorMode.VIEW;
            this.canCreate = false;
          }

          this.setContextMenuActions();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.route.queryParams.subscribe((params) => {
      this.archived = params.archived === 'true';
      this.templates = params.templates === 'true';

      this.setContextMenuActions();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  sortBy(option) {
    this.sortByControl.setValue(option);
  }

  setContextMenuActions() {
    const actions = [];
    if (this.editorMode !== EditorMode.EDIT) {
      this.contextMenuActions = [this.launchContextMenuAction];
      return;
    }

    actions.push(...this.editAccessContextMenuActions);

    if (this.archived) {
      actions.push({
        actionName: 'restore',
        icon: 'settings_backup_restore',
        label: 'Restore',
        criteriaTest: (obj) => {
          return obj.isArchived;
        },
      });
    } else {
      actions.push({
        actionName: 'archive',
        icon: 'delete',
        label: 'Archive',
        criteriaTest: (obj) => {
          return !obj.isArchived;
        },
      });
    }

    if (!this.templates && !this.archived) {
      actions.push({
        actionName: 'set_template',
        icon: 'description',
        label: 'Set as Template',
        criteriaTest: (obj) => {
          return !obj.isTemplate;
        },
      });
    }

    this.contextMenuActions = [...actions, this.launchContextMenuAction];
  }

  private testSearch(obj, searchTerm) {
    const keys = 'name';
    return keys.split(',').some((key) => obj.hasOwnProperty(key) && new RegExp(searchTerm, 'gi').test(obj[key]));
  }
  private testMyShowcases(obj, myShowcases, authContext) {
    if (!myShowcases) {
      return true;
    }
    return obj.createdById === authContext.user.id;
  }
  private testQueryParams(sc, queryParams) {
    const query: any = queryParams as Object;
    let test = true;
    if (query.archived === 'true') {
      test = test && sc.isArchived;
    } else {
      test = test && !sc.isArchived;
    }
    if (query.templates === 'true') {
      test = test && sc.isTemplate;
    }
    return test;
  }

  setViewMode(mode) {
    this.viewMode = mode;
  }
  goToShowcase(showcase) {
    this.routingService.go(`/showcase-details/${showcase.id}`);
  }
  handleContextMenu($event) {
    this.contextMenu.show($event.mouseEvent, $event.listItem);
  }
  handleMenuAction(action) {
    console.log('handleMenuAction: ', action);
    switch (action.action) {
      case 'open':
        this.goToShowcase(action.target);
        break;
      case 'copy': {
        this.copy(action.target);
        break;
      }
      case 'rename': {
        this.rename(action.target);
        break;
      }
      case 'share': {
        this.share(action.target);
        break;
      }
      case 'launch': {
        this.preview(action.target);
        break;
      }
      case 'archive': {
        this.archive(action.target);
        break;
      }
      case 'restore': {
        this.restore(action.target);
        break;
      }
      case 'set_template': {
        this.setTemplate(action.target, true);
        break;
      }
    }
  }

  rename(plan) {
    const modalRef = this.copyRenameEntityModalLauncher.openModal({
      entityId: plan.id,
      entityName: plan.name,
      entityType: 'Showcase',
      isRename: true,
    });

    modalRef.componentInstance.renameEntity.subscribe((showcase) => {
      this.store.dispatch(ShowcasesActions.updateShowcase({ id: showcase.id, changes: { name: showcase.name } }));
    });
  }

  copy(plan) {
    const modalRef = this.copyRenameEntityModalLauncher.openModal({
      entityId: plan.id,
      entityName: plan.name,
      entityType: 'Showcase',
      isRename: false,
    });

    modalRef.componentInstance.copyEntity.subscribe(async (showcase) => {
      this.store.dispatch(ShowcasesActions.asyncCopyShowcase({ name: showcase.name, sourceId: showcase.id }));
    });
  }

  async onCopySuccess(data) {
    this.store.dispatch(ShowcasesActions.asyncCopyShowcaseSuccess(data));
  }

  preview(showcase) {
    this.showcasesService.openShowcaseViewer(showcase);
    this.showcasesService.storeShowcaseId(showcase.id);
    this.showcasesService.loadShowcases();
  }

  share(showcase) {
    this.shareShareShowcaseModalLauncher.openModal(showcase);
  }

  async restore(showcase) {
    this.store.dispatch(ShowcasesActions.updateShowcase({ id: showcase.id, changes: { isArchived: false } }));
  }
  async setTemplate(showcase, template) {
    this.store.dispatch(ShowcasesActions.updateShowcase({ id: showcase.id, changes: { isTemplate: template } }));
  }
  async archive(showcase) {
    const confirm = await this.confirmationBoxService.open(
      'Archive Showcase?',
      'Are you sure you want to archive this showcase?',
    );
    if (confirm) {
      this.store.dispatch(ShowcasesActions.updateShowcase({ id: showcase.id, changes: { isArchived: true } }));
    }
  }
  public showMenu($event) {
    this.contextMenu.show($event.mouseEvent, $event.listItem);
  }

  createShowcase() {
    this.createShowcaseModelLauncher.openModal();
  }

  sortByDirection() {
    this.sortDirection$.pipe(take(1)).subscribe((sortDirection: string) => {
      if (sortDirection === 'asc') {
        this.sortDirectionSubject.next('desc');
      } else {
        this.sortDirectionSubject.next('asc');
      }
    });
  }
}
