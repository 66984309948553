<div class="object-details w-full" (click)="handleClick()">
  <div class="selector-checkbox" *ngIf="allowAddMultiple">
    <!-- <mat-checkbox
      color="primary"
      [attr.data-test]="'chooser-checkbox-' + entity.id"
      (change)="toggleCheckbox($event)"
      [checked]="selectedItemIndex > 0"
    ></mat-checkbox> -->
  </div>
  <div class="image-placeholder" *ngIf="!image && !entity.hex">
    <mat-icon class="!w-full !h-full" svgIcon="placeholder-item-option-image"></mat-icon>
  </div>

  <div *ngIf="image" class="image-holder">
    <img [attr.src]="image | secureImage | async" class="w-full h-full object-contain" />
  </div>
  <div *ngIf="!entity.thumbnail && entity.hex" class="image-holder">
    <div class="color-card" [style.backgroundColor]="'#' + entity.hex"></div>
  </div>

  <div class="flex flex-col ml-3">
    <div class="line-ellipsis font-bold mb-1 max-w-[220px]">{{ name | shorten: 30 }}</div>
    <ng-container *ngIf="entity?.entityType === 'color'">
      <span class="hex text-[12px]">Hex: #{{ entity?.hex | uppercase }}</span>
      <span class="rgb text-[12px]">RGB: {{ entity?.red }}-{{ entity?.green }}-{{ entity?.blue }}</span>
    </ng-container>
    <ng-container *ngIf="entity?.entityType === 'asset'">
      <span class="type text-[12px]">Type: {{ entity?.contentType }}</span>
    </ng-container>
  </div>

  <div class="actions ml-auto mr-2 flex-center">
    <span class="material-icons" *ngIf="entity?.entityType === 'workspace'"> chevron_right </span>
    <!-- <button mat-button [attr.data-test]="'add-single-item-button-' + dataObj.id" *ngIf="allowAdd && selectedItemIndex === 0" (click)="addItemData(dataObj)"><mat-icon>{{ getAddIcon() }}</mat-icon></button>
    <div [attr.data-test]="'selected-item-index' + dataObj.id" class="selected-item" *ngIf="selectedItemIndex > 0">{{selectedItemIndex}}</div> -->
  </div>
</div>
