import { Injectable } from '@angular/core';
import { DocumentAction, DocumentChangeType, DocumentElement, DocumentSVGElementEvent } from '@contrail/documents';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { DocumentService } from '../document.service';
import { State } from 'src/app/root-store/root-state';
import { SideMenuOverlay } from '../document-store/document.state';
import { DocumentActions } from '../document-store';
import { ObjectUtil } from '@contrail/util';
import { DocumentItemService } from '../document-item/document-item.service';
import { nanoid } from 'nanoid';
import { SVGElementContainer } from './svg-element-type';
import { Feature } from '@common/feature-flags/feature-flag';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { SVGHelper } from '@contrail/canvas';

@Injectable({
  providedIn: 'root',
})
export class DocumentContentEditorService {
  public element: DocumentElement;
  public svgRootElement: SVGElement;
  private elementSubject: BehaviorSubject<SVGElementContainer> = new BehaviorSubject(null);
  public element$: Observable<SVGElementContainer> = this.elementSubject.asObservable();

  private contentActionsSubject: Subject<any> = new Subject();
  public contentActions: Observable<any> = this.contentActionsSubject.asObservable();

  svgRecolorFeatureFlag$: Observable<boolean>;
  private hasSvgRecolorFeatureFlag: boolean;

  public isDirty = false;

  private readonly MESSAGE = 'Your changes will not be saved. Are you sure you want to leave?';

  private canvasUrl: string;

  constructor(
    private documentService: DocumentService,
    private store: Store<State>,
  ) {
    this.documentService.documentSVGElementEvents.subscribe((event) => {
      this.handleSvgElementEvent(event);
    });

    this.store.select(FeatureFlagsSelectors.featureFlags).subscribe((flags) => {
      this.hasSvgRecolorFeatureFlag = !!flags.find((x) => x.featureName === Feature.SVG_RECOLORING);
    });
  }

  public isContentEditable(element: DocumentElement): boolean {
    return SVGHelper.isSvg(element) || DocumentItemService.isItemComponet(element);
  }

  public showConfirmDialog() {
    return this.isDirty ? this.MESSAGE : false;
  }

  public async loadElement(element: DocumentElement, svgRoot: SVGElement) {
    console.log('DocumentContentEditorService.loadElement', element);
    this.setIsDirty(false);
    this.element = element;
    this.svgRootElement = svgRoot;
    this.elementSubject.next({ element: this.element, svgRootElement: this.svgRootElement });
  }

  /**
   * Hide content editor if no unsaved changes, or keep it open
   * and re-select current element.
   */
  public hideEditorOrStay() {
    if (!this.hideContentEditor()) {
      if (this.element) {
        this.documentService.selectElement(this.element);
      }
    }
  }

  /**
   * Hide content editor if no unsaved changes or if ignore unsaved changes.
   * @returns
   */
  public hideContentEditor(): boolean {
    if (this.isDirty) {
      if (this.exitUnsaved()) {
        this.hide();
        return true;
      }
      return false;
    } else {
      this.hide();
      return true;
    }
  }

  public hideUnsaved() {
    this.applyUnsaved();
    this.hide();
  }

  public exitUnsaved(): boolean {
    if (confirm(this.MESSAGE)) {
      this.applyUnsaved();
      this.setIsDirty(false);
      return true;
    }
    return false;
  }

  private applyUnsaved() {
    if (this.element) {
      this.documentService.applyDocumentActions([
        new DocumentAction({
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementData: SVGHelper.isSvg(this.element)
            ? {
                id: this.element.id,
                url: this.element.url,
                alternateUrls: this.element.alternateUrls,
              }
            : {
                id: this.element.id,
                elements: this.element.elements,
              },
          elementId: this.element.id,
        }),
      ]);
    }
  }

  public openSvgEditor(element: DocumentElement) {
    if (this.hasSvgRecolorFeatureFlag) {
      this.documentService.handleDocumentSvgElementEvent({ eventType: 'showSvgEditor', element: element });
    } else {
      this.show();
    }
  }

  public show() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Image Options';
    overlay.slug = 'contentEditor';
    overlay.showChooser = true;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  public hide() {
    this.clear();
    this.setIsDirty(false);
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Image Options';
    overlay.slug = 'contentEditor';
    overlay.showChooser = false;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  public setIsDirty(isDirty) {
    this.isDirty = isDirty;
    this.store.dispatch(DocumentActions.setDesignMode({ designMode: isDirty }));
  }

  private clear() {
    this.element = null;
    this.elementSubject.next(null);
    this.canvasUrl && URL.revokeObjectURL(this.canvasUrl);
  }

  /**
   * Convert @svgHtmlString to SVG URL and Rasterized SVG URL
   * @param svgHtmlString
   * @returns
   */
  private async getCanvasUrl(svgHtmlString: string): Promise<{ canvasUrl: string }> {
    const { canvasBlob } = await SVGHelper.getCanvasBlob(svgHtmlString);
    const canvasUrl = URL.createObjectURL(canvasBlob);
    // const canvasUrl = await ImageElement.toCanvasDataUrl(url, 'image/svg+xml', 1, 8, true);
    return { canvasUrl };
  }

  /**
   * Convert @svgHtmlString into raster image and apply to current board.
   * Set alternateUrls of the @element to include raster image. If @originalFile
   * is passed set it to alternateUrls.originalFile
   *
   * Before user saves the SVG @originalFile is undefined and we only use raster image.
   * When user saves the SVG we also set @originalFile so the @element has it for future actions.
   * @param element
   * @param svgHtmlString
   * @param originalFile
   */
  public async applyRasterizedSVG(element: DocumentElement, svgHtmlString: string) {
    const { canvasUrl } = await this.getCanvasUrl(svgHtmlString);
    const elementData: DocumentElement = {
      id: element.id,
    };
    if (DocumentItemService.isItemComponet(element)) {
      elementData.elements = ObjectUtil.cloneDeep(element.elements);
      const imageElement = elementData.elements.find((e) => e.type === 'image');
      imageElement.url = canvasUrl;
      imageElement.alternateUrls = null;
    } else if (SVGHelper.isSvg(element)) {
      elementData.url = canvasUrl;
      elementData.alternateUrls = null;
    }
    this.documentService.applyDocumentActions([
      new DocumentAction({
        changeType: DocumentChangeType.MODIFY_ELEMENT,
        elementData,
        elementId: element.id,
      }),
    ]);

    this.canvasUrl && URL.revokeObjectURL(this.canvasUrl);
    this.canvasUrl = canvasUrl;
  }

  public applyOriginalSVG(element: DocumentElement) {
    const elementData: DocumentElement = {
      id: element.id,
    };
    if (DocumentItemService.isItemComponet(element)) {
      elementData.elements = ObjectUtil.cloneDeep(element.elements);
    } else if (SVGHelper.isSvg(element)) {
      elementData.url = element.url;
      elementData.alternateUrls = ObjectUtil.cloneDeep(element.alternateUrls);
    }
    this.documentService.applyDocumentActions([
      new DocumentAction({
        changeType: DocumentChangeType.MODIFY_ELEMENT,
        elementData,
        elementId: element.id,
      }),
    ]);
    this.canvasUrl && URL.revokeObjectURL(this.canvasUrl);
  }

  private async handleSvgElementEvent(event: DocumentSVGElementEvent) {
    if (event.eventType == 'onSvgEditorShown') {
      this.show();
      this.loadElement(event.element, event.svgRootElement);
    }
  }

  public handleContentAction(action) {
    this.contentActionsSubject.next(action);
  }
}
