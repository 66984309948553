import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { Observable, from, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { Request } from '@contrail/sdk';

import { AuthSelectors } from '@common/auth/auth-store';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { EditorModeActions } from '@common/editor-mode/editor-mode-store';
import { ItemService } from '@common/items/item.service';
import { LibrariesService } from '@common/workspaces/libraries.service';
import { WorkspacePrincipalRole } from '@common/workspaces/workspace-principal';

@Injectable({
  providedIn: 'root',
})
export class ShowcaseEditorModeResolver {
  constructor(
    private store: Store<RootStoreState.State>,
    private itemService: ItemService,
    private libraryService: LibrariesService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    const showcaseId = route.params.id;
    this.itemService.checkItemChooserCriteria(showcaseId);
    this.libraryService.checkLibChooserCriteria(showcaseId);

    return from(this.getEditorModeForUser(route)).pipe(
      switchMap((mode) => {
        if (mode) {
          this.store.dispatch(EditorModeActions.setEditorMode({ editorMode: mode }));
        }
        return of(mode);
      }),
    );
  }

  private async getEditorModeForUser(route: ActivatedRouteSnapshot): Promise<EditorMode> {
    let sharedLink;
    this.store
      .select(AuthSelectors.sharedLink)
      .pipe(
        take(1),
        tap((link) => (sharedLink = link)),
      )
      .subscribe();

    if (sharedLink) {
      return EditorMode[sharedLink.accessLevel];
    }

    const workspaceId = await this.getWorkspaceId(route);
    const userRole = await Request.request(`/workspaces/${workspaceId}/callerRole`, {});
    if (userRole.role === WorkspacePrincipalRole.VIEWER) {
      return EditorMode.VIEW;
    }

    return route.queryParams.mode || EditorMode.EDIT;
  }

  private async getWorkspaceId(route: ActivatedRouteSnapshot): Promise<string> {
    if (route?.queryParams?.workspaceId) {
      return route.queryParams.workspaceId;
    }

    const showcase = await Request.request(`/showcases/${route.params.id}`, {});
    return showcase.workspaceId;
  }
}
