<!--
<app-summary-view *ngIf="currentView?.slug === 'summary'"></app-summary-view>
<app-side-nav-comments  *ngIf="currentView?.slug === 'comments'" ></app-side-nav-comments>
<app-chooser *ngIf="currentView?.slug === 'addProduct'"></app-chooser>
-->

<div
  class="select-none"
  *ngIf="toggleChooser$ | async as toggleChooser"
  [ngClass]="{
    'display-item-chooser': toggleChooser?.showChooser,
    'hide-item-chooser': !toggleChooser
  }"
  [@openClose]="toggleChooser ? 'open' : 'closed'"
>
  <app-entity-chooser
    *ngIf="itemSourceChooserLoaded"
    [hidden]="!toggleChooser?.showChooser || 'addProduct' !== toggleChooser?.slug || toggleChooser?.targetSourceType"
    [hideSource]="toggleChooser?.hideSource"
    [title]="toggleChooser?.title"
  ></app-entity-chooser>

  <app-entity-chooser
    *ngIf="itemChooserLoaded"
    [hidden]="!toggleChooser?.showChooser || 'addProduct' !== toggleChooser?.slug || !toggleChooser?.targetSourceType"
    [targetSourceType]="toggleChooser?.targetSourceType"
    [hideSource]="toggleChooser?.hideSource"
    [title]="toggleChooser?.title"
  ></app-entity-chooser>

  <app-chooser
    *ngIf="assetChooserLoaded"
    class="w-full h-full"
    [style.display]="toggleChooser?.showChooser && 'addAsset' === toggleChooser?.slug ? 'flex' : 'none'"
    [title]="'Asset Chooser'"
    [entityType]="'asset'"
    [allowSourceChange]="true"
    [draggable]="true"
    [enableInfiniteScroll]="true"
    (close)="handleClose()"
  ></app-chooser>

  <app-chooser
    *ngIf="colorChooserLoaded"
    class="w-full h-full"
    [style.display]="toggleChooser?.showChooser && 'addColor' === toggleChooser?.slug ? 'flex' : 'none'"
    [title]="'Color Chooser'"
    [entityType]="'color'"
    [allowSourceChange]="true"
    [allowAddMultipleEntities]="true"
    [allowAddEntity]="true"
    [draggable]="true"
    [enableInfiniteScroll]="true"
    (close)="handleClose()"
    (addEntities)="this.handleAddEntities($event)"
  ></app-chooser>

  <ng-container *ngIf="toggleChooser?.showChooser">
    <app-status-messages-list
      *ngIf="toggleChooser?.slug === 'messages'"
      [messages]="messages$ | async"
      [showWarning]="showSourceAssortmentWarning$ | async"
      (onClose)="handleClose()"
      (onMessageClick)="handleMessageClick($event)"
      (onToggleShowWarning)="handleToggleShowWarning($event)"
    ></app-status-messages-list>

    <app-document-history
      *ngIf="['sourceChangeHistory', 'versionHistory'].includes(toggleChooser?.slug)"
      [document]="showcase$ | async"
      [documentReference]="'showcase:' + (showcase$ | async)?.id"
      [historyType]="toggleChooser?.slug"
      (panelClosed)="handleClose()"
    ></app-document-history>

    <app-component-editor *ngIf="'componentEditor' === toggleChooser?.slug"></app-component-editor>

    <app-composer-frame-settings *ngIf="'composerFrameSettings' === toggleChooser?.slug"></app-composer-frame-settings>

    <app-composer-frame-layout
      *ngIf="['gridFrameLayout', 'collectionFrameLayout'].includes(toggleChooser?.slug)"
      [applicationViewSlug]="
        toggleChooser?.slug === 'collectionFrameLayout' ? 'showcase:item_details' : 'showcase:grid_frame'
      "
    ></app-composer-frame-layout>

    <app-composer-comments-list
      *ngIf="'showComments' === toggleChooser?.slug"
      class="w-full h-full"
      (panelClosed)="handleClose()"
    ></app-composer-comments-list>

    <app-document-generator-config-editor
      *ngIf="'lineboardEditor' === toggleChooser?.slug"
      (panelClosed)="handleClose()"
    ></app-document-generator-config-editor>

    <app-composer-dashboard-panel
      *ngIf="'dashboard' === toggleChooser?.slug"
      (panelClosed)="handleClose()"
    ></app-composer-dashboard-panel>
  </ng-container>
</div>
