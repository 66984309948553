import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CommentsContextReferenceFilter } from '@common/comments/context-comments-list/context-comments-list.component';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { ComposerService } from '../composer.service';

@Component({
  selector: 'app-composer-comments-list',
  templateUrl: './composer-comments-list.component.html',
  styleUrls: ['./composer-comments-list.component.scss'],
})
export class ComposerCommentsListComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  @Output() panelClosed = new EventEmitter();
  public contextReferenceTypes$: Observable<Array<CommentsContextReferenceFilter>>;
  public commentTypeOptions = [
    { value: 'showcase', display: 'Showcase' },
    { value: 'frame', display: 'Frame' },
    { value: 'item', display: 'Item' },
    { value: 'other', display: 'Other' },
  ];

  private selectedContextReference: string;

  constructor(
    private store: Store<RootStoreState.State>,
    private composerService: ComposerService,
  ) {}

  ngOnInit(): void {
    this.contextReferenceTypes$ = combineLatest([
      this.store.select(ShowcasesSelectors.currentShowcase),
      this.composerService.currentFrame,
    ]).pipe(
      switchMap(async ([showcase, currentFrame]) => {
        if (showcase && currentFrame) {
          return [
            {
              key: 'contextReference',
              value: `showcase:${showcase?.id}`,
              label: 'Showcase',
              selected: this.selectedContextReference && this.selectedContextReference.indexOf('Showcase') !== -1,
              ownerInfo: {
                entityType: 'showcase',
                id: showcase?.id,
              },
            },
            {
              key: 'subContextReference',
              value: `presentation-frame:${currentFrame?.id}`,
              label: 'Frame',
              selected: !this.selectedContextReference || this.selectedContextReference.indexOf('Frame') !== -1,
              ownerInfo: {
                entityType: 'showcase',
                id: showcase?.id,
                subContextReference: `presentation-frame:${currentFrame?.id}`,
              },
            },
          ];
        }
      }),
      takeUntil(this.destroy$),
    );
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  handleClose() {
    this.panelClosed.emit();
  }

  public getCommentType(comment) {
    const isOwnedByItem = comment?.ownedByReference?.indexOf('item') !== -1;
    let commentType;
    if (isOwnedByItem) {
      commentType = 'item';
    } else if (comment?.subContextReference && comment?.documentElementId) {
      commentType = 'other';
    } else if (comment?.subContextReference && !comment?.documentElementId) {
      commentType = 'frame';
    } else if (!comment.subContextReference) {
      commentType = 'showcase';
    }
    return commentType;
  }

  public updateSelectedContextReference(selectedContextReference) {
    this.selectedContextReference = selectedContextReference;
  }
}
