import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShowcasesComponent } from './showcases.component';
import { ShowcasesListComponent } from './showcases-list/showcases-list.component';
import { ShowcaseAnalyticsDashboardComponent } from '../showcase-analytics/showcase-analytics-dashboard/showcase-analytics-dashboard.component';
import { ComposerComponent } from '../presentation/composer/composer.component';

const routes: Routes = [
  {
    path: '',
    component: ShowcasesComponent,
    children: [
      { path: '', redirectTo: 'showcases-list', pathMatch: 'full' },
      { path: 'showcases-list', component: ShowcasesListComponent },
      { path: 'analytics', component: ShowcaseAnalyticsDashboardComponent },
      { path: 'composer', component: ComposerComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ShowcasesRoutingModule {}
