import { Comment, CommentOwnerInfo } from '../comments.service';

export interface State {
  contextReference: string;
  contextComments: Array<Comment>;
  currentComment: Comment;
  commentOwnerInfo: CommentOwnerInfo;
  showCommentOverlay: boolean;
  showCommentsSideBar: boolean;
  commentOverlayPosition: { x: number; y: number };
  commentsAccessLevel: string;
  selectedComment: Comment;
  showPinnedComments: boolean;
}
export const initialState: State = {
  contextReference: null,
  currentComment: null,
  contextComments: [],
  commentOwnerInfo: null,
  showCommentOverlay: false,
  showCommentsSideBar: false,
  commentOverlayPosition: null,
  commentsAccessLevel: 'EDIT',
  selectedComment: null,
  showPinnedComments:
    localStorage.getItem('showPinnedComments') == undefined
      ? true
      : localStorage.getItem('showPinnedComments') === 'true',
};
