import { Component, Input } from '@angular/core';
import { ExportsService } from '../exports.service';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
})
export class ExportComponent {
  @Input() types: any[];
  @Input() entityReference: string;
  @Input() label = 'Export';
  @Input() options = {};

  constructor(private service: ExportsService) {}

  initDownload(reportType) {
    const payload = {
      entityReference: this.entityReference,
      ...this.options,
    };

    this.service.initDownloadEntityReference(
      {
        reportType,
      },
      payload,
    );
  }
}
