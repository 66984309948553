import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AssortmentsActions, AssortmentsSelectors } from '@common/assortments/assortments-store';
import { backingAssortmentItemData } from '@common/assortments/assortments-store/backing-assortment/backing-assortment.selectors';
import { ItemData } from '@common/item-data/item-data';
import { setLoading } from '@common/loading-indicator/loading-indicator-store/loading-indicator.actions';
import { DocumentElement } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { tap } from 'rxjs/operators';
import { ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { DocumentComponentService } from '../../document/document-component/document-component-service';
import { DocumentItemService } from '../../document/document-item/document-item.service';
import { DocumentService } from '../../document/document.service';
import { Presentation, PresentationCollectionElement } from '../../presentation';
import { ComposerService } from '../composer.service';
import { Entities } from '@contrail/sdk';
import { ItemService } from '@common/items/item.service';
import { ProjectItemService } from '@common/projects/project-item.service';
import { ConfirmationBoxService } from '@components/confirmation-box/confirmation-box';
import { ComponentEditorService } from '../../document/component-editor/component-editor.service';

@Injectable({
  providedIn: 'root',
})
export class ComposerItemService {
  private presentation: Presentation;
  gridFrameActiveContainer: PresentationCollectionElement;
  collectionFrameActiveContainer: PresentationCollectionElement;
  backingAssortmentItemData: any[];
  constructor(
    private store: Store<RootStoreState.State>,
    private composerService: ComposerService,
    private documentService: DocumentService,
    private documentItemService: DocumentItemService,
    private documentComponentService: DocumentComponentService,
    private snackBar: MatSnackBar,
    private projectItemService: ProjectItemService,
    private itemService: ItemService,
    private confirmationBoxService: ConfirmationBoxService,
    private componentEditorService: ComponentEditorService,
  ) {
    this.store.select(ShowcasesSelectors.gridFrameActiveContainer).subscribe((gridFrameActiveContainer) => {
      this.gridFrameActiveContainer = gridFrameActiveContainer;
    });
    this.store.select(ShowcasesSelectors.collectionFrameActiveContainer).subscribe((collectionFrameActiveContainer) => {
      this.collectionFrameActiveContainer = collectionFrameActiveContainer;
    });
    this.composerService.presentation.subscribe((pres) => {
      this.presentation = pres;
    });
    this.store.select(AssortmentsSelectors.backingAssortmentItemData).subscribe((backingAssortmentItemData) => {
      this.backingAssortmentItemData = backingAssortmentItemData;
    });
  }

  async promoteItems(frameType: any) {
    this.store.dispatch(setLoading({ loading: true }));
    if (frameType === 'document') {
      const selectedElements = ObjectUtil.cloneDeep(this.documentService.getSelectedElements());
      this.documentService.deselectAllElements();
      await this.documentComponentService.promoteItems(selectedElements);
    } else if (frameType === 'collection') {
      // this is a collection frame
      if (this.collectionFrameActiveContainer) {
        // if a collection item is selected, add additional item options
        const optionItem = await this.documentItemService.createEmptyItemOption({
          itemFamilyId: this.collectionFrameActiveContainer.value,
        });
        await this.composerService.addItemsToCollectionFrame([new ItemData(optionItem)]);
      } else {
        // if no collection item is selected, add a new item family.
        const item = await this.documentItemService.createEmptyItemFamily();
        await this.composerService.addItemsToCollectionFrame([new ItemData(item)]);
      }
    } else if (frameType === 'grid') {
      // this is a grid frame
      if (this.gridFrameActiveContainer) {
        // a grid section must be selected.
        const item = await this.documentItemService.createEmptyItemFamily();
        await this.composerService.addItemsToGridFrame([new ItemData(item)], this.gridFrameActiveContainer);
      } else {
        this.snackBar.open('Please select at least one section to continue.', '', { duration: 2000 });
      }
    }
    this.store.dispatch(setLoading({ loading: false }));
  }

  async syncElements(data: any) {
    this.store.dispatch(setLoading({ loading: true }));
    // get all frames in the showcase to find affected component elements
    const documentFrames = this.presentation.frames.filter((frame) => frame.type === 'document');
    for (const documentFrame of documentFrames) {
      // loop through each frame in the showcase
      // get all frames in the showcase to find affected component elements
      const componentElements = documentFrame.document.elements.filter(DocumentItemService.isItemComponet);
      let elementsToSync: DocumentElement[] = [];
      let entities: any[]; // item option or item family entity that is in data

      if (data.object.id === data.object.itemFamilyId) {
        // update happened on an item family
        // find all item/options that are affected by changes to item family.
        const filteredBackingAssortmentItems = this.backingAssortmentItemData.filter(
          (backingAssortmentItem) => backingAssortmentItem.item.itemFamilyId === data.object.id,
        );
        entities = ObjectUtil.cloneDeep(filteredBackingAssortmentItems).map((assortmentItem) => {
          if (assortmentItem.id === data.object.id) {
            // item family
            return Object.assign(assortmentItem.item, data.changes);
          } else {
            // do not update option images if family image was updated
            const changes = { ...data.changes };
            const filteredChanges = Object.fromEntries(
              Object.entries(changes).filter(
                ([key]) =>
                  [
                    'contentType',
                    'fileName',
                    'largeViewableDownloadUrl',
                    'mediumLargeViewableDownloadUrl',
                    'mediumViewableDownloadUrl',
                    'primaryFileUrl',
                    'primaryViewableId',
                    'smallViewableDownloadUrl',
                    'tinyViewableDownloadUrl',
                  ].indexOf(key) === -1,
              ),
            );
            return Object.assign(assortmentItem.item, filteredChanges);
          }
        });
        elementsToSync = componentElements.filter(
          (element) => entities.findIndex((entity) => element.modelBindings.item === `item:${entity.id}`) > -1,
        );
      } else {
        // update happened on an item option
        entities = [data.object];
        elementsToSync = componentElements.filter((element) => element.modelBindings.item === `item:${data.object.id}`);
      }
      entities = entities.concat(await this.documentComponentService.fetchEntitiesExceptFor(elementsToSync, entities));
      await this.documentComponentService.updateValuesForComponentElements(
        documentFrame.document,
        ObjectUtil.cloneDeep(elementsToSync),
        entities,
      );
    }
    // update items that in the backingAssortment
    this.store.dispatch(AssortmentsActions.syncBackingAssortmentItems({ id: data.object.id, changes: data.changes }));
    this.store.dispatch(setLoading({ loading: false }));
  }

  async addItemsToProject() {
    let currentProject;
    const selectedElements = this.documentService.getSelectedElements();
    const projectItemsToCarryover = [];
    const selectedComponentElements = selectedElements.filter((e) => e.type === 'component');
    const elementsForChange = selectedComponentElements.filter(
      (e) => !e.modelBindings.projectItem?.includes(this.projectItemService.currentProjectId),
    );
    if (elementsForChange.length === 0) {
      this.snackBar.open('All selected items are already in the current project.', '', { duration: 4000 });
      return;
    }
    let itemIds = elementsForChange.map((e) => e.modelBindings.item.split('item:')[1]);
    itemIds = [...new Set(itemIds)];

    let existingProjectItemIds = elementsForChange
      .filter((e) => e.modelBindings.projectItem)
      .map((e) => e.modelBindings.projectItem.split('project-item:')[1]);
    existingProjectItemIds = [...new Set(existingProjectItemIds)];
    let existingProjectItems = [];
    if (existingProjectItemIds.length > 0) {
      existingProjectItems = await this.projectItemService.getByIds(existingProjectItemIds, ['project']);
    }
    const projectItemsInCurrentProject = (await this.projectItemService.getCurrentProjectItems(itemIds)).filter(
      (p) => !p.isInactive,
    );
    if (projectItemsInCurrentProject?.length > 0) {
      const projectItemWithCurrentProject = projectItemsInCurrentProject.find(
        (p) => p.projectId === this.projectItemService.currentProjectId,
      );
      if (projectItemWithCurrentProject) {
        currentProject = projectItemWithCurrentProject.project;
      }
    }
    const itemIdsNotInAnyProject = itemIds.filter((itemId) => !existingProjectItems.find((p) => p.itemId === itemId));
    const itemIdsNotInCurrentProject = itemIds.filter(
      (itemId) => !projectItemsInCurrentProject.find((p) => p.itemId === itemId),
    );
    if (itemIdsNotInAnyProject.length > 0) {
      const itemsNotInAnyProject = await this.itemService.getItems(itemIdsNotInAnyProject);
      // Look for inactive project items for reactivation
      const inactiveProjectItems = (
        await this.projectItemService.getCurrentProjectItems(itemIdsNotInAnyProject)
      ).filter((p) => p.isInactive);
      itemsNotInAnyProject.forEach((item) => {
        const pi: any = {
          itemId: item.id,
          roles: item.roles,
        };
        if (inactiveProjectItems.find((p) => p.itemId === item.id)) {
          pi.isInactive = false;
        }
        projectItemsToCarryover.push(pi);
      });
    }

    itemIdsNotInCurrentProject.forEach((itemId) => {
      const existingProjectItem = existingProjectItems.find((p) => p.itemId === itemId);
      if (existingProjectItem) {
        projectItemsToCarryover.push(existingProjectItem);
      }
    });
    if (!currentProject) {
      currentProject = await new Entities().get({
        entityName: 'project',
        id: this.projectItemService.currentProjectId,
      });
    }
    let confirm = true;
    if (selectedComponentElements.length > 1) {
      if (projectItemsToCarryover.length > 0) {
        confirm = await this.confirmationBoxService.open(
          'Switch / add to project',
          'This will switch the context to the current project for all selected items. ' +
            projectItemsToCarryover.length +
            ' items will be added to ' +
            currentProject.name +
            '. Are you sure you want to proceed?',
          'Cancel',
          'OK',
          true,
        );
      } else {
        confirm = await this.confirmationBoxService.open(
          'Switch / add to project',
          'This will switch the context to the current project for all selected items. Are you sure you want to proceed?',
          'Cancel',
          'OK',
          true,
        );
      }
    }
    if (confirm) {
      if (projectItemsToCarryover.length > 0) {
        await this.projectItemService.carryoverProjectItems(projectItemsToCarryover);
      }
      const changes = [];
      for (let i = 0; i < elementsForChange.length; i++) {
        const documentElement = ObjectUtil.cloneDeep(elementsForChange[i]);
        documentElement.modelBindings.projectItem = `project-item:${this.projectItemService.currentProjectId}:${documentElement.modelBindings.item.split('item:')[1]}`;
        if (documentElement.modelBindings.assortment) {
          documentElement.modelBindings.assortment = null;
        }
        if (documentElement.modelBindings.assortmentItem) {
          documentElement.modelBindings.assortmentItem = null;
        }
        const bindingChanges = documentElement.modelBindings;
        changes.push({ documentElement: documentElement, bindingChanges });
      }
      this.componentEditorService.batchUpdateComponentElements(changes);
      return true;
    } else {
      return false;
    }
  }
}
