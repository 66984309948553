import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatCommonModule } from '@angular/material/core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AuthModule } from '../common/auth/auth.module';
import { ComponentsModule } from '../common/components/components.module';
import { LoadingIndicatorModule } from '../common/loading-indicator/loading-indicator.module';
import { ComposerModule } from '../presentation/composer/composer.module';
import { ShowcaseDetailsModule } from '../showcase-details/showcase-details.module';
import { ShowcasesModule } from '../showcases/showcases.module';
import { WorkspacesModule } from '@common/workspaces/workspaces.module';
import { BodyComponent } from './body/body.component';
import { FooterComponent } from './footer/footer.component';
import { HelpSelectorComponent } from './header/header-help/help-selector.component';
import { HeaderUserMenuComponent } from './header/header-user-menu/header-user-menu.component';
import { HeaderComponent } from './header/header.component';
import { OrgSelectorComponent } from './header/org-selector/org-selector.component';
import { PageHeaderComponent } from './header/page-header/page-header.component';
import { PageHeaderService } from './header/page-header/page-header.service';
import { MainComponent } from './main.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { UserProfileModule } from '../common/user-profile/user-profile.module';
import { WorkspaceSelectorComponent } from './side-nav/workspace-selector/workspace-selector.component';
import { UserSessionsModule } from 'src/app/common/user-sessions/user-sessions.module';
import { CommentsModule } from 'src/app/common/comments/comments.module';
import { DocumentHistoryModule } from '@common/document-history/document-history.module';
import { SourceAssortmentModule } from '@common/source-assortment/source-assortment.module';
import { CollectionStatusMessageModule } from '@common/collection-status-message/collection-status-message.module';
import { RemoteMouseTrackerModule } from '@common/remote-mouse-tracker/remote-mouse-tracker.module';
import { CustomFontsModule } from '@common/custom-fonts/custom-fonts.module';
import { ClipboardModule } from '@common/clipboard/clipboard.module';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    BodyComponent,
    MainComponent,
    OrgSelectorComponent,
    HeaderUserMenuComponent,
    SideNavComponent,
    PageHeaderComponent,
    HelpSelectorComponent,
    WorkspaceSelectorComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AuthModule,
    UserSessionsModule,
    RemoteMouseTrackerModule,
    CustomFontsModule,
    MatSidenavModule,
    WorkspacesModule,
    MatCommonModule,
    BrowserAnimationsModule,
    MatListModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    ShowcasesModule,
    ShowcaseDetailsModule,
    ComponentsModule,
    LoadingIndicatorModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    ComposerModule,
    UserProfileModule,
    CommentsModule,
    DocumentHistoryModule,
    SourceAssortmentModule,
    CollectionStatusMessageModule,
    ClipboardModule,
  ],
  providers: [PageHeaderService],
  exports: [MainComponent],
})
export class MainModule {}
