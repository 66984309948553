import { Component, OnInit } from '@angular/core';
import { SidePanelService, SidePanelView } from '../side-panel.service';

@Component({
  selector: 'app-side-panel-options',
  templateUrl: './side-panel-options.component.html',
  styleUrls: ['./side-panel-options.component.scss'],
})
export class SidePanelOptionsComponent implements OnInit {
  public sidePanelViews: Array<SidePanelView>;
  constructor(private sidePanelService: SidePanelService) {
    this.sidePanelViews = this.sidePanelService.sidePanelViews;
  }

  ngOnInit(): void {}

  loadSidePanel(view) {
    this.sidePanelService.setCurrentView(view);
  }
}
