import { Injectable } from '@angular/core';
import { AssortmentsActions, AssortmentsSelectors } from '@common/assortments/assortments-store';
import { BackingAssortmentItemActionTypes } from '@common/assortments/assortments-store/backing-assortment/backing-assortment.actions';
import { DocumentHistorySelectors } from '@common/document-history/document-history-store';
import { UndoRedoService } from '@common/undo-redo/undo-redo-service';

import { DocumentAction, DocumentChangeType } from '@contrail/documents';
import { Entities } from '@contrail/sdk';
import { ObjectUtil } from '@contrail/util';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { Observable, Subject } from 'rxjs';
import { buffer, debounceTime, take, tap } from 'rxjs/operators';
import { ShowcasesActions } from 'src/app/showcases/showcases-store';
import { Showcase } from '../../../showcases/showcases.service';
import { DocumentAnnotationService } from '../../document/document-annotation/document-annotation-service';
import { DocumentItemService } from '../../document/document-item/document-item.service';
import { Presentation, PresentationFrame } from '../../presentation';
import { DocumentAssortmentHelper } from './document-assortment-helper';
import { AnnotationLoader } from '../annotations-loader';
import { DocumentService } from '../../document/document.service';
import { AssortmentItem } from '@common/assortments/assortments-store/backing-assortment/backing-assortment.state';

interface ActionData {
  actions: Array<DocumentAction>;
  frame: PresentationFrame;
}

@Injectable({
  providedIn: 'root',
})
export class ShowcaseBackingAssortmentService {
  presentationObservable: Observable<Presentation>;
  presentation: Presentation;
  currentSnapshot: any;
  private syncBackingAssortmentSubject = new Subject<any>();

  constructor(
    private store: Store<RootStoreState.State>,
    private actions$: Actions,
    private documentAnnotationService: DocumentAnnotationService,
    private documentService: DocumentService,
    private undoRedoService: UndoRedoService,
  ) {
    // load items that could be missing when the backing assortment is loaded the first time.

    actions$.pipe(ofType(BackingAssortmentItemActionTypes.LOAD_BACKING_ASSORTMENT_SUCCESS)).subscribe(() => {
      this.syncBackingAssortment();
    });

    this.documentAnnotationService.annotationEvents.subscribe((annotationEvent) => {
      this.handleAnnotationEvent(annotationEvent);
    });
    this.store
      .select(DocumentHistorySelectors.currentEntitySnapshot)
      .subscribe((snapshot) => (this.currentSnapshot = snapshot));

    /**Accumulates actions for 1000ms before calling API */
    const debounceSyncBackingAssortment$ = this.syncBackingAssortmentSubject.pipe(debounceTime(1000));
    const syncBackingAssortment$ = this.syncBackingAssortmentSubject.pipe(buffer(debounceSyncBackingAssortment$));
    syncBackingAssortment$.subscribe({
      complete: () => {},
      error: (err: any) => {
        console.log(err);
      },
      next: (data: Array<ActionData>) => {
        const actions = data.reduce((array, actionData) => array.concat(actionData.actions), []);
        this.syncBackingAssortment(data[0].frame, actions);
      },
    });
  }

  debounceSyncBackingAssortment(data: any) {
    this.syncBackingAssortmentSubject.next(data);
  }

  /**
   * Synchronizes the backing assortment with the current state of a showcase presentation.
   */
  public async syncBackingAssortment(currentFrame: PresentationFrame = null, actions: Array<DocumentAction> = []) {
    if (!actions || !this.presentation || this.currentSnapshot) {
      // do not sync if it's showing a snapshot
      return;
    }
    let doDiff = false;
    for (const action of actions) {
      if (
        ['ADD_ELEMENT', 'DELETE_ELEMENT', 'REBIND_MODEL', 'CREATE_FRAME'].includes(action.changeDefinition?.changeType)
      ) {
        doDiff = true;
      }
    }

    if (!currentFrame || currentFrame.collection) {
      // current frame is a collection
      doDiff = true;
    }

    if (doDiff) {
      const documentItemIds = this.getItemIdsFromPresentation(currentFrame, true);
      if (currentFrame) {
        if (!currentFrame.isDeleted) {
          if (currentFrame.document) {
            for (const element of currentFrame.document.elements) {
              const id = DocumentAssortmentHelper.getItemIdFromElement(element);
              if (id) {
                documentItemIds.push(id);
              }
            }
          } else if (currentFrame.collection) {
            for (const set of currentFrame.collection.set) {
              if (set.children.length > 0) {
                for (const child of set.children) {
                  documentItemIds.push(child.value);
                }
              } else {
                // allow item family to be added to backing assortment
                if (set.type !== 'section') {
                  // Do not use section's id which is not a true item
                  documentItemIds.push(set.value);
                }
              }
            }
          }
        }
      }

      this.store
        .select(AssortmentsSelectors.backingAssortmentItems)
        .pipe(
          take(1),
          tap((data) => {
            const ais = data as AssortmentItem[];
            const itemIds = ais.map((ai) => ai.itemId);
            const adds = documentItemIds.filter((id) => !itemIds.includes(id));
            const removes = ais.filter((ai) => !documentItemIds.includes(ai.itemId)).map((ai) => ai.id);
            console.log('Adds: ', adds);
            console.log('removes: ', removes);
            if (adds?.length) {
              this.store.dispatch(AssortmentsActions.addItemsToBackingAssortment({ itemIds: adds }));
            }
            if (removes?.length) {
              this.store.dispatch(AssortmentsActions.removeItemsFromBackingAssortment({ itemIds: removes }));
            }
          }),
        )
        .subscribe();
    }
  }

  public async initBackingAssortment(presentationObservable: Observable<Presentation>, showcase: Showcase) {
    let backingAssortmentId = showcase?.backingAssortmentId;

    this.presentationObservable = presentationObservable;
    this.presentationObservable.subscribe((presentation) => {
      this.presentation = presentation;
    });
    if (!showcase) {
      return;
    }
    if (!backingAssortmentId) {
      const assortment = await this.createBackingAssortment(showcase);
      backingAssortmentId = assortment.id;
    }

    // Load store with backing assortment
    this.store.dispatch(AssortmentsActions.loadBackingAssortment({ assortmentId: backingAssortmentId }));
  }

  public async updateAssortmentItemsByProperty(itemIds: string[], property) {
    const backingItems = await this.getItemsFromBackingAssortment();
    const updatingItems = ObjectUtil.cloneDeep(
      backingItems.filter((backingItem) => itemIds.includes(backingItem.itemId)),
    );
    const changes = [];
    updatingItems.forEach((updatingItem) => {
      const item = {};
      if (!updatingItem.hasOwnProperty(property)) {
        item[property] = true;
      } else {
        item[property] = !updatingItem[property];
      }

      changes.push({ id: updatingItem.id, changes: item });
    });
    const originalData = updatingItems.map((updatingItem) => {
      return { id: updatingItem.id, changes: updatingItem };
    });
    this.undoRedoService.addUndo([
      {
        actionType: 'backing-assortment',
        changeDefinition: changes,
        undoChangeDefinition: originalData,
      },
    ]);
    this.updateAssortmentItems(changes);
  }

  public updateAssortmentItems(changes) {
    this.store.dispatch(AssortmentsActions.updateBackingAssortmentItems({ changes }));
  }

  async handleAnnotationEvent(annotationEvent: any) {
    const annotationOption = AnnotationLoader.annotations?.find((option) => option.type === annotationEvent.eventType);
    let assortmentItems = annotationEvent.selectedElements?.filter(DocumentItemService.isItemComponet);
    if (assortmentItems?.length > 0 && annotationOption) {
      assortmentItems = assortmentItems.map((element) => element.modelBindings.item.split(':')[1]);
      await this.updateAssortmentItemsByProperty(assortmentItems, annotationOption.property);
      this.adjustComponentSize(annotationEvent);
    }
  }

  /** Handles init of backing assortment when there isn't one already. */
  private async createBackingAssortment(showcase: Showcase) {
    if (showcase.backingAssortmentId) {
      return;
    }
    let backingAssortment: any = { name: 'Showcase Backing Assortment: ' + showcase.id };
    backingAssortment = await new Entities().create({ entityName: 'assortment', object: backingAssortment });
    this.store.dispatch(
      ShowcasesActions.updateShowcase({ id: showcase.id, changes: { backingAssortmentId: backingAssortment.id } }),
    );
    return backingAssortment;
  }

  handleAssortmentItemChanges(changes) {
    this.store.dispatch(
      AssortmentsActions.updateBackingAssortmentItemsSuccess({ changes: ObjectUtil.cloneDeep(changes) }),
    );
  }

  handleAssortmentAddItems(changes) {
    this.store.dispatch(
      AssortmentsActions.addItemsToBackingAssortmentSuccess({ assortmentItems: ObjectUtil.cloneDeep(changes) }),
    );
  }

  handleAssortmentRemoveItems(changes) {
    this.store.dispatch(
      AssortmentsActions.removeItemsFromBackingAssortmentSuccess({ ids: ObjectUtil.cloneDeep(changes) }),
    );
  }

  private async getItemIdsFromBackingAssortment(): Promise<Array<string>> {
    const itemIds: Set<string> = new Set();
    this.store
      .select(AssortmentsSelectors.backingAssortmentItems)
      .pipe(
        take(1),
        tap((ais) => {
          ais.forEach((ai: any) => {
            itemIds.add(ai.itemId);
          });
        }),
      )
      .subscribe();
    return [...itemIds];
  }

  private async getItemsFromBackingAssortment(): Promise<Array<any>> {
    const items: Set<any> = new Set();
    this.store
      .select(AssortmentsSelectors.backingAssortmentItems)
      .pipe(
        take(1),
        tap((ais) => {
          ais.forEach((ai: any) => {
            items.add(ai);
          });
        }),
      )
      .subscribe();
    return [...items];
  }

  getItemIdsFromPresentation(currentFrame: PresentationFrame, excludeCurrentFrame = false): Array<string> {
    const itemIds: Set<string> = new Set();
    const frames =
      excludeCurrentFrame && currentFrame
        ? this.presentation.frames.filter((frame) => frame.id !== currentFrame.id)
        : this.presentation.frames;
    frames.forEach((frame) => {
      if (frame.document) {
        for (const element of frame.document.elements) {
          const id = DocumentAssortmentHelper.getItemIdFromElement(element);
          if (id) {
            itemIds.add(id);
          }
        }
      } else if (frame.collection) {
        for (const set of frame.collection.set) {
          if (set.children.length > 0) {
            set.children.forEach((child) => {
              itemIds.add(child.value);
            });
          } else {
            if (set.type !== 'section') {
              // Do not use section's id which is not a true item
              itemIds.add(set.value);
            }
          }
        }
      }
    });
    return [...itemIds];
  }

  private adjustComponentSize(annotationEvent: any) {
    const actions: DocumentAction[] = [];
    for (let element of annotationEvent.selectedElements) {
      const undoChangeElement = ObjectUtil.cloneDeep(element);
      const newElements = this.documentService.updateSizeAndPositionForPropertyElements(element.elements, element);
      element.elements = newElements;
      const action = new DocumentAction(
        {
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementId: element.id,
          elementData: element,
        },
        {
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementId: element.id,
          elementData: undoChangeElement,
        },
      );
      actions.push(action);
    }
    if (actions.length > 0) {
      this.documentService.handleDocumentActions(actions);
    }
  }
}
