import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ListCardBadge } from 'src/app/common/components/list-card/list-card.component';
import { Showcase } from '../../showcases-store/showcases.state';

@Component({
  selector: 'app-showcase-card',
  template: `<app-list-card
    *ngIf="showcase"
    [title]="showcase.name"
    [imageUrl]="photoUrl"
    [updatedOn]="showcase.updatedOn"
    [badge]="getBadge()"
    (openMenu)="showMenu($event)"
    iconUrl="assets/images/showcase_icon.svg"
  >
  </app-list-card>`,
  styles: [],
})
export class ShowcaseCardComponent implements OnChanges {
  @Input() showcase: Showcase;
  @Input() selected: boolean;
  @Output() openMenu = new EventEmitter();
  public photoUrl;
  constructor() {}

  ngOnChanges(): void {
    if (this.showcase) {
      this.photoUrl = this.showcase.previewFile?.downloadUrl || this.showcase.coverPhoto;
    }
  }

  public getBadge(): ListCardBadge {
    if (this.showcase.isTemplate) {
      return {
        text: 'Template',
      };
    }
  }

  public showMenu($event) {
    this.openMenu.emit($event);
  }
}
