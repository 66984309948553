import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';

@Component({
  selector: 'app-showcases',
  templateUrl: './showcases.component.html',
  styleUrls: ['./showcases.component.scss'],
})
export class ShowcasesComponent implements OnInit {
  constructor(private store: Store<RootStoreState.State>) {}

  ngOnInit(): void {}
}
