import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { itemChooserReducers } from './item-chooser-store.reducers';
import { ItemsEffects } from './item-chooser-store.effects';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('items', itemChooserReducers),
    EffectsModule.forFeature([ItemsEffects]),
  ],
})
export class ItemChooserStoreModule {}
