import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { CreateShowcaseModalComponent } from './create-showcase-modal/create-showcase-modal.component';

@Injectable({
  providedIn: 'root',
})
export class CreateShowcaseModalLauncher {
  constructor(public dialog: MatDialog) {}

  openModal(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '700px';
    dialogConfig.data = {};
    const dialogRef = this.dialog.open(CreateShowcaseModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
}
