import { Entities, SortOrderOptions, Types } from '@contrail/sdk';
import { SortDefinition, SortDirection } from '../../components/sort/sort-definition';
import { combineLatest, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FilterHelper } from '../../types/filters/filter-helper';
import { ChooserDataSource, ChooserFilterConfig } from './chooser-data-source';

export class LibraryChooserDataSource extends ChooserDataSource {
  constructor(
    protected entityType: string,
    private typePath: string,
    protected workspaceIdSubject: Observable<string>,
    protected filterConfigSubject: Observable<ChooserFilterConfig>,
    protected sortConfigSubject: Observable<SortDefinition[]>,
    protected existingItemIdsSubject: Observable<any>,
    protected showAllSubject: Observable<any>,
    protected searchContext: any,
  ) {
    super(entityType, filterConfigSubject, sortConfigSubject, existingItemIdsSubject, showAllSubject, null);
    this.initFilteredDataObservable();
    this.initResultsObservable();
  }

  protected async initFilteredDataObservable() {
    this.filteredData$ = combineLatest([
      this.filterConfigSubject,
      this.sortConfigSubject,
      this.workspaceIdSubject,
    ]).pipe(
      switchMap(async ([filterConfig, sortConfig, workspaceId]) => {
        if (!filterConfig || !sortConfig) {
          return;
        }
        let data;

        const relations = [];
        let searchTerm = filterConfig.searchTerm.trim() || '';
        const filterDefinition = filterConfig.filterDefinition;
        const criteria = filterConfig.baseCriteria;
        if (!searchTerm?.endsWith('*')) {
          searchTerm += '*';
        }

        // Contextual criteria allows us to append automatic filters based
        // on a pass in context.  This is useful for object references
        // which may need to be filtered based on a merch hiearchy, etc (RL, etc)
        // This could also be used for applying plan level criteria as well, such as a
        // gender / etc for item searching.
        const contextualCriteria = await this.getContextualCriteria();

        let apiCriteria = Object.assign({}, contextualCriteria, criteria);
        const filterCriteria = FilterHelper.toSimpleCriteria(filterDefinition);
        if (filterCriteria) {
          apiCriteria = Object.assign(apiCriteria, filterCriteria);
        }
        this.loadingSubject.next(true);
        const sortOrders = sortConfig.map((sortDefinition) => {
          return {
            order: sortDefinition.direction === SortDirection.ASCENDING ? SortOrderOptions.ASC : SortOrderOptions.DESC,
            orderField: sortDefinition.propertySlug,
          };
        });

        // Ignore tashed library items
        apiCriteria.isTrashed = false;

        if (this.typePath) {
          apiCriteria.typePath = this.typePath;
        }
        if (workspaceId) {
          apiCriteria.workspaceId = workspaceId;
        }
        const getConfig = {
          entityName: this.entityType,
          relations,
          criteria: apiCriteria,
          take: 100,
          search: searchTerm,
          order: sortOrders,
        };

        if (['color', 'asset'].includes(this.entityType)) {
          const [entities, workspaceEntities] = await Promise.all([
            new Entities().get(getConfig),
            new Entities().get({
              entityName: 'workspace-entity',
              relations: ['entity'],
              criteria: {
                ...getConfig.criteria,
                entityType: 'workspace',
              },
              search: getConfig.search,
              order: getConfig.order,
            }),
          ]);
          const folders = workspaceEntities.map((i) => i.entity).sort((a, b) => a.name.localeCompare(b.name));
          data = [...folders, ...entities];
        } else {
          data = new Entities().get(getConfig);
        }
        this.loadingSubject.next(false);
        return data;
      }),
    );
  }

  async getContextualCriteria() {
    if (!this.searchContext) {
      return;
    }
    const criteria: any = {};
    const type = await new Types().getType({ path: this.typePath });
    const properties = type.typeProperties.filter(
      (p) => !['name', 'createdOn', 'updatedOn', 'createdBy', 'updatedBy'].includes(p.slug),
    );
    for (let p of properties) {
      const val = this.searchContext[p.slug];
      if (val) {
        criteria[p.slug] = val;
      }
    }

    const archievableEntityTypes = ['custom-entity', 'color'];
    const shouldIgnoreArchived = archievableEntityTypes.includes(this.entityType);
    if (shouldIgnoreArchived) {
      criteria.isArchived = false;
    }

    return criteria;
  }
}
