<div
  [attr.id]="'framePreview-' + frame.id"
  class="relative border-2 border-transparent border-solid overflow-hidden"
  [class.selected]="selected || lineboardSelected"
  [class.lineboard-selected]="!selected && lineboardSelected"
>
  <app-composer-svg-frame-preview *ngIf="frame?.type === 'document'" [frame]="frame"></app-composer-svg-frame-preview>
  <app-composer-collection-frame-preview
    *ngIf="frame?.type === 'collection'"
    [frame]="frame"
  ></app-composer-collection-frame-preview>
  <app-composer-grid-frame-preview *ngIf="frame?.type === 'grid'" [frame]="frame"></app-composer-grid-frame-preview>
  <app-composer-showroom-frame-preview
    *ngIf="frame?.type === 'showroom'"
    [frame]="frame"
  ></app-composer-showroom-frame-preview>
  <app-composer-frame-pinned-comments
    *ngIf="editorMode !== 'VIEW'"
    [frame]="frame"
  ></app-composer-frame-pinned-comments>
  <app-composer-frame-remote-sessions
    *ngIf="editorMode === 'EDIT' && frame"
    [frame]="frame"
  ></app-composer-frame-remote-sessions>
  <app-composer-iframe-frame-preview
    *ngIf="frame?.type === 'iframe'"
    [frame]="frame"
  ></app-composer-iframe-frame-preview>
</div>
