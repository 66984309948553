import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { PropertyValueFormatter, TypePropertyOption } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { map, startWith } from 'rxjs/operators';
import { SearchBarComponent } from 'src/app/common/components/search-bar/search-bar.component';
@Component({
  selector: 'app-grid-frame-dimension-value-picker',

  template: ` <div
      class="select-edit"
      style="width:100%"
      #menuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="menu"
      (menuOpened)="menuOpened()"
      (menuClosed)="menuClosed()"
    >
      <div></div>
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
    <mat-menu #menu="matMenu" class="mat-panel" style="font-size: 11px; size: 11px;">
      <mat-selection-list #options (selectionChange)="setSelectionValues($event)">
        <mat-list-option
          class="filter-widget"
          (focus)="filterFocused()"
          (click)="$event.stopPropagation()"
          (keydown)="$event.stopImmediatePropagation()"
        >
          <app-search-bar class="search-bar" [placeholder]="'Search'"></app-search-bar>
          <div class="selection-options">
            <div (click)="selectAllOptions()" class="text-primary mr-1">Select all</div>
            <div class="text-primary mr-1">|</div>
            <div (click)="deselectAllOptions()" class="text-primary">Clear</div>
          </div>
        </mat-list-option>
        <mat-list-option
          class="list-option"
          [selected]="this.selectedValues?.includes(option.value)"
          [value]="option"
          checkboxPosition="before"
          (click)="$event.stopPropagation()"
          *ngFor="let option of filteredOptions"
        >
          {{ option.display }}
        </mat-list-option>
      </mat-selection-list>
    </mat-menu>`,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        justify-content: flex-end;
        text-align: right;
      }
      :host ::ng-deep .filter-widget {
        display: none !important;
      }
      ::ng-deep .mat-panel {
        min-width: 305px !important;
      }
      .select-edit {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 20px;
        cursor: pointer;
        padding-bottom: 2px;
        position: absolute;
        top: 1px;
      }
      .selection-options {
        position: absolute;
        top: 50px;
        display: flex;
        cursor: pointer;
        font-size: 12px !important;
      }
      mat-list-option.list-option {
        font-size: 12px;
        height: 35px;
        ::ng-deep .mat-list-text {
          text-overflow: ellipsis;
          overflow: hidden;
          width: 210px;
          white-space: nowrap;
          display: block;
        }
      }
      mat-list-option:first-child {
        height: 68px;
      }
      mat-list-option:first-child:hover {
        background: white !important;
      }
      mat-list-option.list-option .mat-list-base {
        padding: 0 12px;
      }
      .filter-widget ::ng-deep .mat-pseudo-checkbox {
        display: none !important;
      }
      .search-bar {
        position: absolute;
        top: 10px;
      }
    `,
  ],
})
export class GridFrameDimensionValuePickerComponent implements AfterViewInit, OnChanges {
  @Input() dimensionValueOptions: TypePropertyOption[];
  @Input() selectedValues: any[];
  @Output() selectedValuesEmitter = new EventEmitter();
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @ViewChild('options', { static: false }) options: MatSelectionList;
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;
  filteredOptions: Array<TypePropertyOption>;
  editing = false;

  constructor(private changeRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dimensionValueOptions?.currentValue) {
      this.filteredOptions = changes.dimensionValueOptions?.currentValue;
    }
  }

  subscribe() {
    this.searchBar.valueChange
      .pipe(
        startWith(''),
        map((searchTerm) => {
          const keys = 'display';
          if (!this.dimensionValueOptions) {
            this.filteredOptions = [];
          } else {
            this.filteredOptions = ObjectUtil.cloneDeep(this.dimensionValueOptions).filter((option) =>
              keys
                .split(',')
                .some((key) => option.hasOwnProperty(key) && new RegExp(searchTerm, 'gi').test(option[key])),
            );
            setTimeout(() => {
              this.searchBar.focus();
            }, 100);
          }
          this.changeRef.detectChanges();
        }),
      )
      .subscribe();
  }

  setSelectionValues($event) {
    const values = $event.source.selectedOptions.selected
      .filter((mapOption) => mapOption.value)
      .map((matOption) => matOption.value.value);
    this.selectedValues = values;
    this.selectedValuesEmitter.emit({ selectedValues: values });
  }

  stopPropagation(event) {
    if (event.key === 'Enter') {
      event.stopPropagation();
    }
  }

  menuOpened() {
    this.editing = true;
    setTimeout(() => {
      this.searchBar.focus();
    }, 1);
  }

  menuClosed() {
    this.editing = false;
  }

  filterFocused() {
    this.searchBar.focus();
  }

  selectAllOptions() {
    this.selectedValues = [];
    this.filteredOptions.forEach((option) => {
      this.selectedValues.push(option.value);
    });
  }

  deselectAllOptions() {
    this.selectedValues = [];
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent = null) {
    if (event.composedPath()[0] instanceof HTMLBodyElement && !this.menuTrigger.menuOpen && !this.editing) {
      setTimeout(() => {
        this.menuTrigger.openMenu();
        this.searchBar.focus();
      }, 100);
    }
    event.stopPropagation();
  }

  @HostListener('document:keydown.esc', ['$event'])
  handleEscapeKey(event: KeyboardEvent = null) {
    if (event.composedPath()[0] instanceof HTMLBodyElement && this.menuTrigger.menuOpen && this.editing) {
      this.menuTrigger.closeMenu();
    }
    event.stopPropagation();
  }

  @HostListener('document:keydown', ['$event'])
  handleEvents(event: KeyboardEvent = null) {
    event.stopPropagation(); // stop propagating key event to other listeners
  }
}
