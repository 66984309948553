<!-- <app-mat-spinner [overlay]="true" [loading]="isLoading" *ngIf="isLoading"></app-mat-spinner>
<div class="noTempalteMessage" *ngIf="role === 'MEMBER' && !templates?.length ">
  You can not create a showcase at this time because no template has been created for this workspace.<br><br>Contact your workspace administrator for assistance.
  <div class="actions">
    <button mat-raised-button color="primary" (click)="cancel()">OK</button>
  </div>
</div>
<div *ngIf="role === 'ADMIN' || templates?.length"> -->
<form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
  <mat-form-field style="width: 100%" appearance="fill" [ngClass]="{ elementHidden: currentPage === 1 }">
    <input matInput type="text" placeholder="Showcase Name" formControlName="name" id="name" />
    <mat-error *ngIf="hasError('name', 'required')">Name is required</mat-error>
    <mat-error *ngIf="hasError('name', 'maxlength')">You have more than 60 characters</mat-error>
  </mat-form-field>

  <div *ngIf="currentPage === 1" class="mb-2">
    <mat-radio-group
      data-test="source-assortment-option"
      formControlName="assortmentOption"
      (change)="selectAssortmentOption($event)"
      class="flex flex-col gap-2"
    >
      <mat-radio-button data-test="source-assortment-required-option" value="useAssortment">
        <b>Yes. </b>Set source assortment and get alerted of adds, drops and changes.
      </mat-radio-button>
      <mat-radio-button data-test="source-assortment-not-required-option" value="doNotUseAssortment">
        <b>No. </b>Showcase is not connected to any source.
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <app-assortment-selector
    *ngIf="sourceAssortmentRequired"
    [ngClass]="{ elementHidden: currentPage === 0 }"
    (selectedAssortmentEvent)="setAssortment($event)"
    [sourceAssortmentRequired]="sourceAssortmentRequired"
  ></app-assortment-selector>

  <div *ngIf="templates?.length && currentPage === 0" class="templateList">
    <h6>Template</h6>
    <mat-radio-group formControlName="template">
      <div class="template" *ngFor="let template of templates" (click)="selectTemplate(template)">
        <mat-radio-button class="example-radio-button" [value]="template">
          {{ template.name }}
        </mat-radio-button>
      </div>
    </mat-radio-group>
  </div>
  <div class="actions">
    <div>
      <button
        data-test="create-showcase-back-button"
        mat-button
        type="button"
        color="primary"
        (click)="back()"
        *ngIf="currentPage === 1"
      >
        BACK
      </button>
    </div>
    <div class="flex gap-2">
      <button mat-stroked-button type="button" (click)="cancel()">CANCEL</button>
      <button
        mat-flat-button
        color="primary"
        data-test="create-showcase-next-button"
        type="button"
        (click)="next()"
        *ngIf="currentPage === 0 && (!selectedTemplate || !selectedTemplate?.id)"
        [disabled]="!isValid()"
      >
        NEXT
      </button>
      <button
        mat-flat-button
        color="primary"
        type="submit"
        data-test="create-showcase-submit-button"
        *ngIf="currentPage === 1 || (currentPage === 0 && selectedTemplate?.id)"
        [disabled]="!isValid()"
      >
        CREATE
      </button>
    </div>
  </div>
</form>
<!-- </div> -->
