import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter, map, take, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/common/auth/auth.service';
import { RootStoreState } from 'src/app/root-store';
import { ShowcasesActions, ShowcasesSelectors } from '../../showcases-store';
import { ShowcasesService } from '../../showcases.service';

@Component({
  selector: 'app-create-showcase-form',
  templateUrl: './create-showcase-form.component.html',
  styleUrls: ['./create-showcase-form.component.scss'],
})
export class CreateShowcaseFormComponent implements OnInit {
  constructor(
    private showcaseService: ShowcasesService,
    private authService: AuthService,
    private store: Store<RootStoreState.State>,
  ) {
    this.authService.authContext.subscribe((context) => {
      if (context) {
        this.role = context.currentOrg.role;
        const formConfig: any = {
          name: new UntypedFormControl('', [Validators.required, Validators.maxLength(60)]),
        };
        // if (this.role !== 'ADMIN'){
        //   formConfig.template = new FormControl('', [Validators.required]);
        // } else {
        formConfig.template = new UntypedFormControl('');
        formConfig.assortmentOption = new UntypedFormControl('useAssortment');
        // }
        this.form = new UntypedFormGroup(formConfig);
      }
    });
  }

  public isLoading = false;

  @Output() formSubmitted = new EventEmitter();
  @Output() formCancelled = new EventEmitter();
  @Output() pageChanged = new EventEmitter();
  public templates = [];
  public selectedTemplate;
  public role;
  public form: UntypedFormGroup;
  public allowNext = true;
  currentPage = 0;
  selectedAssortmentId;
  sourceAssortmentRequired = true;

  ngOnInit() {
    this.loadTemplates();
  }
  private async loadTemplates() {
    this.store
      .select(ShowcasesSelectors.showcases)
      .pipe(
        filter((showcases) => !!showcases),
        take(1),
        map((showcases) => {
          return showcases.filter((sc) => sc.isTemplate);
        }),
        tap((templates) => {
          this.templates = [...templates];
          // if (this.role === 'ADMIN') {
          this.templates.unshift({ name: 'None', id: null });
          // }
          if (this.form.controls.template && this.templates?.length) {
            this.form.controls.template.setValue(this.templates[0]);
          }
        }),
      )
      .subscribe();
  }
  setAssortment(assortmentId) {
    this.selectedAssortmentId = assortmentId;
  }

  isValid() {
    if (this.currentPage === 1 && this.sourceAssortmentRequired && !this.selectedAssortmentId) {
      return false;
    }
    return this.form.valid;
  }

  hasError(controlName: string, errorName: string) {
    return this.form.controls[controlName].hasError(errorName);
  }

  async onSubmit(data) {
    const newShowcaseData: any = {
      name: data.name,
    };

    if (this.selectedAssortmentId) {
      newShowcaseData.sourceAssortmentId = this.selectedAssortmentId;
    }

    this.isLoading = true;
    if (data.template?.id) {
      this.store.dispatch(ShowcasesActions.copyShowcase({ name: data.name, sourceId: data.template.id }));
    } else {
      this.store.dispatch(ShowcasesActions.createShowcase({ showcase: newShowcaseData }));
    }
    this.formSubmitted.emit();
  }

  public selectTemplate(template) {
    this.selectedTemplate = template;
  }

  public selectAssortmentOption(assortmentOption) {
    if (assortmentOption.value === 'doNotUseAssortment') {
      this.selectedAssortmentId = null;
      this.sourceAssortmentRequired = false;
    } else {
      this.sourceAssortmentRequired = true;
    }
  }

  public next() {
    this.currentPage = 1;
    this.pageChanged.emit(this.currentPage);
  }

  public back() {
    this.currentPage = 0;
    this.pageChanged.emit(this.currentPage);
  }

  public cancel() {
    this.formCancelled.emit();
  }
}
