import { createAction, props } from '@ngrx/store';
import { UserSession } from '@common/user-sessions/user-session';

export enum RemoteMouseTrackerActionTypes {
  REMOTE_USER_MOUSE_MOVED = '[Remote Mouse Tracker] Remote User Mouse Moved',
  REMOVE_REMOTE_USER_FROM_MOUSE_SESSION = '[Remote Mouse Tracker] Remove Remote User from Mouse Session',
}

////////////////////////////////////////////////////////////////////////////////

export const remoteUserMouseMoved = createAction(
  RemoteMouseTrackerActionTypes.REMOTE_USER_MOUSE_MOVED,
  props<{ data: any }>(),
);

export const removeRemoteUserFromMouseSession = createAction(
  RemoteMouseTrackerActionTypes.REMOVE_REMOTE_USER_FROM_MOUSE_SESSION,
  (userSession: UserSession) => ({ userSession }),
);
