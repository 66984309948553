import { Injectable } from '@angular/core';
import { PropertyValueFormatter } from '@contrail/types';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { ComposerService } from '../composer.service';
import { Presentation, PresentationCollection, PresentationFrame } from '../../presentation';
import { DocumentService } from '../../document/document.service';
import { ShowcasesActions, ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { AssortmentsSelectors } from '@common/assortments/assortments-store';
import { ViewManagerService } from '@common/views/view-manager.service';
import { SearchReplaceActions, SearchReplaceSelectors } from '@common/search-replace/search-replace-store';
import { GRID_VIEW_APPLICATION_SLUG } from '../composer-frame/composer-frame-layout/composer-frame-layout.service';

const formatter = new PropertyValueFormatter();

export interface SearchResultElement {
  frameId: string;
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class ComposerSearchService {
  ACTIVE_ELEMENT = '#BBDEFB';
  currentFrameId: string;
  presentation: Presentation;
  showcase: any;
  sourceAssortmentItemData: any[];
  backingAssortmentItemData: any[];
  searchResults: SearchResultElement[] = [];
  activeSearchResultElement: SearchResultElement;
  searchActive = false;
  searchKeyword = '';

  constructor(
    private store: Store<RootStoreState.State>,
    private documentService: DocumentService,
    private viewService: ViewManagerService,
    private composerService: ComposerService,
  ) {
    this.store.select(ShowcasesSelectors.currentShowcase).subscribe((showcase) => {
      this.showcase = showcase;
    });
    this.composerService.presentation.subscribe((presentation) => {
      if (presentation) {
        this.presentation = presentation;
        this.currentFrameId = presentation.presentationFrameOrder[0];
      }
    });
    this.store.select(SearchReplaceSelectors.searchActive).subscribe((searchActive) => {
      if (this.searchActive && !searchActive) {
        this.removeAllElementHighlights();
      }
      this.searchActive = searchActive;
    });
    this.store.select(AssortmentsSelectors.sourceAssortmentItemData).subscribe((sourceAssortmentItemData) => {
      this.sourceAssortmentItemData = sourceAssortmentItemData;
    });
    this.store.select(AssortmentsSelectors.backingAssortmentItemData).subscribe((backingAssortmentItemData) => {
      this.backingAssortmentItemData = backingAssortmentItemData;
    });
    this.composerService.currentFrame.subscribe((currentFrame) => {
      this.currentFrameId = currentFrame?.id;
      if (this.searchActive && this.searchKeyword !== '') {
        setTimeout(() => {
          this.highlightElements();
        }, 500);
      }
    });
    this.store.select(SearchReplaceSelectors.activeSearchResultElement).subscribe((activeSearchResultElement) => {
      if (activeSearchResultElement) {
        if (activeSearchResultElement.frameId !== this.currentFrameId) {
          this.composerService.navigateToFrame(activeSearchResultElement.frameId);
        }
        if (this.activeSearchResultElement) {
          this.documentService.highlightElement(this.activeSearchResultElement.id); // reset to regular
        }
        setTimeout(() => {
          this.documentService.highlightElement(activeSearchResultElement.id, this.ACTIVE_ELEMENT);
        }, 100);
      } else {
        if (this.activeSearchResultElement) {
          if (this.searchResults.map((element) => element.id).includes(this.activeSearchResultElement.id)) {
            this.documentService.highlightElement(this.activeSearchResultElement.id); // reset to regular
          } else {
            this.documentService.dehighlightElement(this.activeSearchResultElement.id); // remove highlight
          }
        }
      }
      this.activeSearchResultElement = activeSearchResultElement;
    });
  }

  public async search(keyword: string) {
    this.searchKeyword = keyword;
    const searchResults: SearchResultElement[] = [];
    if (keyword.length > 0) {
      for (let i = 0; i < this.presentation.presentationFrameOrder.length; i++) {
        const frameId = this.presentation.presentationFrameOrder[i];
        const frame = this.presentation.frames.find((presentationFrame) => presentationFrame.id === frameId);
        if (frame.document) {
          this.searchSVGFrame(keyword, frame, searchResults);
        } else if (frame.type === 'collection') {
          this.searchCollectionFrame(keyword, frame, searchResults);
        } else if (frame.type === 'grid') {
          await this.searchGridFrame(GRID_VIEW_APPLICATION_SLUG, keyword, frame, searchResults);
        }
      }
    }
    // remove current highlights
    if (searchResults.length > 0) {
      const currentFrameElements = searchResults.filter((searchResult) => searchResult.frameId === this.currentFrameId);
      const index =
        currentFrameElements.length > 0
          ? searchResults.findIndex(
              (searchResult) =>
                searchResult.id === currentFrameElements[0].id &&
                searchResult.frameId === currentFrameElements[0].frameId,
            )
          : 0;
      this.store.dispatch(
        SearchReplaceActions.setActiveSearchResultElement({ activeSearchResultElement: searchResults[index] }),
      );

      this.searchResults
        .filter((searchResultItem) => searchResultItem.frameId === this.currentFrameId)
        ?.forEach((searchResultItem) => {
          this.documentService.dehighlightElement(searchResultItem.id);
        });
      searchResults
        .filter((searchResultItem) => searchResultItem.frameId === this.currentFrameId)
        ?.forEach((searchResultItem) => {
          this.documentService.highlightElement(searchResultItem.id);
        });
    }
    if (searchResults.length === 0) {
      this.removeAllElementHighlights();
      this.store.dispatch(SearchReplaceActions.setActiveSearchResultElement({ activeSearchResultElement: null }));
    }
    this.searchResults = searchResults;
    this.store.dispatch(SearchReplaceActions.setSearchResults({ searchResults }));
  }

  private removeAllElementHighlights() {
    this.searchResults
      .filter((searchResultItem) => searchResultItem.frameId === this.currentFrameId)
      ?.forEach((searchResultItem) => {
        this.documentService.dehighlightElement(searchResultItem.id);
      });
    this.searchResults = [];
  }

  private highlightElements() {
    this.searchResults
      .filter((searchResultItem) => searchResultItem.frameId === this.currentFrameId)
      ?.forEach((searchResultItem) => {
        if (this.activeSearchResultElement?.id === searchResultItem.id) {
          this.documentService.highlightElement(searchResultItem.id, this.ACTIVE_ELEMENT);
        } else {
          this.documentService.highlightElement(searchResultItem.id);
        }
      });
  }

  private searchSVGFrame(keyword: string, frame: PresentationFrame, searchResults: SearchResultElement[]) {
    const results = [];
    const componentElements = frame.document?.elements?.filter((element) => element.type === 'component');
    const textElements = frame.document?.elements?.filter(
      (element) => element.type === 'text' || element.type === 'sticky_note',
    );
    componentElements.forEach((componentElement) => {
      const filteredElems = componentElement.elements.filter(
        (element) => (element.text + '').toLowerCase().indexOf(keyword.toLowerCase()) > -1,
      );
      if (filteredElems.length > 0) {
        results.push({
          frameId: frame.id,
          id: componentElement.id,
        });
      }
    });
    textElements.forEach((textElement) => {
      const text = new DOMParser().parseFromString(textElement.text, 'text/html').body.textContent;
      if (text?.toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
        results.push({
          frameId: frame.id,
          id: textElement.id,
        });
      }
    });
    if (results.length > 0) {
      searchResults.push(...results);
    }
  }

  private async searchGridFrame(
    applicationSlug,
    keyword: string,
    frame: PresentationFrame,
    searchResults: SearchResultElement[],
  ) {
    const collection: PresentationCollection = frame.collection;
    const viewDef = await this.getViewDefinition(applicationSlug);
    const results = [];
    collection.set.forEach((section) => {
      section.children?.forEach((child) => {
        let found = false;
        const backingAssortmentItem = this.backingAssortmentItemData.find((itemData) => itemData.id === child.value);
        const sourceAssortmentItem = this.sourceAssortmentItemData.find((itemData) => itemData.id === child.value);
        const itemObj = backingAssortmentItem?.properties;
        if (itemObj) {
          const projectItemObj = sourceAssortmentItem?.properties;
          viewDef.properties.forEach((property) => {
            let value;
            if (property.typeRootSlug === 'item' && itemObj) {
              value = formatter.getDisplayValue(itemObj, property.propertyDefinition);
            } else if (property.typeRootSlug === 'project-item' && projectItemObj) {
              value = formatter.getDisplayValue(projectItemObj, property.propertyDefinition);
            }
            if ((value + '').toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
              found = true;
            }
          });
        }
        if (found) {
          results.push({
            id: itemObj.id,
            frameId: frame.id,
          });
        }
      });
    });
    searchResults.push(...results);
  }

  private searchCollectionFrame(keyword: string, frame: PresentationFrame, searchResults: SearchResultElement[]) {
    const collection: PresentationCollection = frame.collection;
    const results = [];
    collection.set.forEach((set) => {
      set.children?.forEach((child) => {
        if (child.label.toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
          results.push({
            id: child.value,
            frameId: frame.id,
          });
        }
      });
    });
    searchResults.push(...results);
  }

  private async getViewDefinition(applicationSlug) {
    return await this.viewService.getView(applicationSlug, null, 'showcase:' + this.showcase.id);
  }
}
