import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-showcase-details-assortments',
  templateUrl: './showcase-details-assortments.component.html',
  styleUrls: ['./showcase-details-assortments.component.scss'],
})
export class ShowcaseDetailsAssortmentsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
