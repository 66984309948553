export class NavigatorClipboardService {
  constructor() {}

  public static async canReadClipboard(): Promise<boolean> {
    const canReadClipboard = await navigator.permissions.query({ name: 'clipboard-read' as PermissionName });
    return canReadClipboard.state === 'granted';
  }

  public static async getBrowserClipboardData(): Promise<DataTransfer> {
    let clipboardItems: ClipboardItems;
    try {
      clipboardItems = await navigator.clipboard.read();
    } catch (e) {}

    let clipboardData = new DataTransfer();

    if (clipboardItems) {
      for (let item of clipboardItems) {
        for (let type of item.types) {
          if ('text/plain' === type || 'text/html' === type) {
            let dataType = await item.getType(type),
              data = await new Response(dataType).text();
            clipboardData.setData(type, data);
          } else {
            let dataType = await item.getType(type);
            clipboardData.items.add(
              new File([dataType], type, {
                type: type,
              }),
            );
          }
        }
      }
    }
    return clipboardData;
  }

  public static async getDataTransferItems(clipboardData: DataTransfer): Promise<any> {
    const promises = [];
    for (let i = 0; i < clipboardData.items.length; i++) {
      const dataTransferItem = clipboardData.items[i];
      if (dataTransferItem.kind === 'string') {
        promises.push(
          new Promise((res) =>
            dataTransferItem.getAsString((data) => {
              res(data);
            }),
          ),
        );
      } else if (dataTransferItem.kind === 'file') {
        promises.push(new Promise((res) => res(dataTransferItem.getAsFile())));
      }
    }
    return await Promise.all(promises);
  }
}
