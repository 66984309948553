import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowcaseAnalyticsDashboardComponent } from './showcase-analytics-dashboard/showcase-analytics-dashboard.component';
import { AccessReportComponent } from './access-report/access-report.component';
import { ComponentsModule } from '../common/components/components.module';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { UserAccessReportComponent } from './user-access-report/user-access-report.component';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { ItemReportComponent } from './item-report/item-report.component';
import { GoogleChartsModule } from '@common/angular-google-charts/google-charts.module';

@NgModule({
  declarations: [
    ShowcaseAnalyticsDashboardComponent,
    AccessReportComponent,
    DatePickerComponent,
    UserAccessReportComponent,
    ItemReportComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    MatProgressSpinnerModule,
    GoogleChartsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatListModule,
  ],
  exports: [ShowcaseAnalyticsDashboardComponent],
})
export class ShowcaseAnalyticsModule {}
