import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';

@Injectable({
  providedIn: 'root',
})
export class ItemsService {
  constructor() {}

  getImageUrl(url) {
    if (!url || !url.indexOf) {
      return;
    }
    if (url.indexOf('http') > -1) {
      return url;
    }
    url = url.substring(10);
    url = 'https://contrail-temp-images.s3.amazonaws.com' + url;
    return url;
  }

  async getItemContent(itemId: any) {
    const item: any = await new Entities().get({
      entityName: 'item',
      id: itemId,
      relations: ['content', 'content.primaryFile', 'content.smallViewable', 'content.mediumViewable'],
    });
    return item.content;
  }
}
