import { Component, Input, OnInit } from '@angular/core';
import { ExportableEntityType, ExportEntitiesOptions, ExportReportType, ExportsService } from '../exports.service';

@Component({
  selector: 'app-csv-export',
  templateUrl: './csv-export.component.html',
  styleUrls: ['./csv-export.component.scss'],
})
export class CsvExportComponent implements OnInit {
  @Input() entityType: ExportableEntityType;
  @Input() typePath?: string;
  @Input() criteria?: { [key: string]: any };

  constructor(private service: ExportsService) {}
  ngOnInit(): void {}

  downloadCSV() {
    const exportRequest: ExportEntitiesOptions = {
      entityType: this.entityType,
      typePath: this.typePath,
      criteria: this.criteria,
    };

    this.service.initDownloadAllEntities(ExportReportType.CSV, exportRequest);
  }
}
