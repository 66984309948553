import {
  AfterContentInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ComposerService } from '../../../composer.service';
import {
  Document,
  DocumentAction,
  DocumentChangeType,
  DocumentElementFactory,
  SizeDefinition,
} from '@contrail/documents';
import { Presentation } from 'src/app/presentation/presentation';
import { combineLatest, fromEvent, Subscription } from 'rxjs';
import { DocumentElement } from '@contrail/documents';
import { DocumentService } from 'src/app/presentation/document/document.service';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { DocumentSelectors, DocumentActions } from '../../../../document/document-store';
import { DocumentComponentService } from 'src/app/presentation/document/document-component/document-component-service';
import { AuthService } from '@common/auth/auth.service';
import { DocumentAnnotationService } from 'src/app/presentation/document/document-annotation/document-annotation-service';
import { tap, filter, debounceTime, take, throttleTime } from 'rxjs/operators';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
import { DOCUMENT } from '@angular/common';
import { CanvasSizeSizePositionHandler } from './canvas-size-position.handler';
import { toggleChooser } from 'src/app/presentation/document/document-store/document.actions';
import { CanvasDocument, CANVAS_MODE } from 'src/app/presentation/canvas-lib';
import { AnnotationLoader } from '../../../annotations-loader';
import { environment } from 'src/environments/environment';
import { ComposerPropertyPoliciesService } from '../../../composer-property-policies/composer-property-policies.service';
import { CustomFontsSelectors } from '@common/custom-fonts/custom-fonts-store';
import { DocumentAssetService } from 'src/app/presentation/document/document-asset/document-asset.service';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { Feature } from '@common/feature-flags/feature-flag';

@Component({
  selector: 'app-composer-canvas',
  templateUrl: './composer-canvas.component.html',
  styleUrls: ['./composer-canvas.component.scss'],
})
export class ComposerCanvasComponent implements AfterContentInit, OnDestroy {
  public canvasDocument: CanvasDocument;
  public document: Document;
  public presentation: Presentation;
  public sizePositionHandler: CanvasSizeSizePositionHandler;

  private subscriptions: Array<Subscription> = [];
  private editorMode: EditorMode;
  private isShiftKey = false;
  private dragEnterFromElement = null;

  private hasSvgRecolorFeatureFlag = false;
  private readonly SKIP_KEY_EVENTS_FOR_COMPONENTS: Array<string> = [
    'app-side-menu',
    'app-item-chooser',
    'app-context-comments-list',
    'app-document-history',
    'app-chooser',
  ];

  private customFonts: string;
  constructor(
    private store: Store<RootStoreState.State>,
    private documentService: DocumentService,
    private documentComponentService: DocumentComponentService,
    private documentAnnotationService: DocumentAnnotationService,
    private documentAssetService: DocumentAssetService,
    private composerService: ComposerService,
    private authService: AuthService,
    private composerPropertyPoliciesService: ComposerPropertyPoliciesService,
    @Inject(DOCUMENT) document: Document,
    private element: ElementRef,
  ) {}

  ngAfterContentInit(): void {
    this.subscribe();
  }

  ngOnDestroy() {
    this.canvasDocument?.clear();
    this.unsubscribe();
  }
  subscribe() {
    // Respond to side panel toggle
    this.subscriptions.push(
      this.store.select(DocumentSelectors.toggleChooser).subscribe((toggleItemChooser) => {
        if (toggleItemChooser) {
          this.sizePositionHandler?.setSidePanelOpen(toggleItemChooser.showChooser);
        }
      }),
    );

    this.subscriptions.push(
      this.store.select(CustomFontsSelectors.customFonts).subscribe((customFonts) => (this.customFonts = customFonts)),
    );

    this.store.select(FeatureFlagsSelectors.featureFlags).subscribe((flags) => {
      this.hasSvgRecolorFeatureFlag = !!flags.find((x) => x.featureName === Feature.SVG_RECOLORING);
    });

    // Respond to frame changes.
    this.subscriptions.push(
      combineLatest([this.composerService.currentFrame, this.store.select(EditorModeSelectors.editorMode)])
        .pipe(
          filter((frame) => !!frame),
          tap(async ([frame, editorMode]) => {
            this.canvasDocument?.clear();

            this.editorMode = editorMode;
            this.documentService.elementsAdded$.next(false);
            if (!frame?.document) {
              this.documentService.init(null, null);
              return;
            }

            let mode = editorMode !== EditorMode.EDIT ? CANVAS_MODE.PREVIEW : CANVAS_MODE.EDIT;

            // Load the SVG Document
            const currentInteractionMode = this.documentService.getInteractionMode();
            this.canvasDocument = new CanvasDocument(
              'mainCanvas',
              frame.document,
              this.documentService,
              mode,
              this.authService,
              { imageHost: environment.imageHost },
              AnnotationLoader?.annotations,
              this.customFonts,
              this.composerPropertyPoliciesService.restrictedViewablePropertySlugs,
              this.hasSvgRecolorFeatureFlag,
            );
            this.document = frame.document;
            this.documentService.setRenderer(this.canvasDocument);
            this.documentService.activateComponentAndImageInteraction(true); // turn on component-image-element-interaction
            this.sizePositionHandler = new CanvasSizeSizePositionHandler(
              this.canvasDocument,
              this.element,
              this.store,
              this.document,
            );
            this.composerService.initSizePositionHandler(this.sizePositionHandler);
            if (['paint_format', 'paint_format_hold'].includes(currentInteractionMode)) {
              this.documentService.setInteractionMode(currentInteractionMode);
            }
            this.documentService.init(frame.document, frame.ownedByReference);
            this.store
              .select(DocumentSelectors.toggleChooser)
              .pipe(
                take(1),
                tap((toggleChooser) => {
                  if (toggleChooser) {
                    this.sizePositionHandler.setSidePanelOpen(toggleChooser?.showChooser);
                  }
                }),
              )
              .subscribe();

            this.sizePositionHandler.center();
            this.setSizeAndPosition();
          }),
        )
        .subscribe(),
    );

    this.subscriptions.push(
      this.composerService.presentation.subscribe((pres) => {
        this.presentation = pres;
      }),
    );

    // Handles annotations
    this.subscriptions.push(
      combineLatest(
        this.documentService.elementsAdded$.pipe(
          filter((b) => b === true),
          debounceTime(1000),
        ),
        this.documentAnnotationService.annotatedElements$,
      ).subscribe(([elementsAdded, annotatedElements]) => {
        if (annotatedElements?.length > 0) {
          this.documentService.setAnnotations(annotatedElements);
        }
      }),
    );

    this.registerMouseMoveHandler();
  }

  unsubscribe() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  drop(event) {
    if (this.editorMode !== 'EDIT') {
      return;
    }

    event.stopPropagation();
    event.preventDefault();
    this.dragEnterFromElement = null;

    const options: DocumentElement = {
      position: this.sizePositionHandler.computeDocumentPositionForCoordinates({
        x: event.clientX,
        y: event.clientY,
      }),
    };

    if (event.dataTransfer.getData('dataObject')) {
      let data = event.dataTransfer.getData('dataObject');

      // COMPUTE RELATIVE LOCATION OF DROP
      if (data) {
        data = JSON.parse(data);
      }

      if (data.entityType === 'asset') {
        const targetElement: DocumentElement = this.canvasDocument.getTargetElement();
        if (targetElement && targetElement?.type === 'component' && targetElement?.modelBindings?.item) {
          this.documentAssetService.assignImageItemFromAsset(data, targetElement);
        } else {
          this.documentAssetService.createElementFromAsset(data, options);
        }
      } else if (data.entityType === 'content') {
        this.documentAssetService.createElementFromContent(data, options);
      } else if (data.entityType === 'bound_text') {
        console.log('Dropping text: ', data);
        let text = data.text;
        options.size = {
          height: 40,
          width: 150,
        };
        options.propertyBindings = data.propertyBindings;
        // options.modelBindings = data.modelBindings;  // BL: Removing because we are setting board level bindings..  May come back in time.
        const textElement = DocumentElementFactory.createTextElement(text, options);

        const actions: Array<DocumentAction> = [];
        const action: DocumentAction = new DocumentAction(
          {
            elementId: textElement.id,
            elementData: textElement,
            changeType: DocumentChangeType.ADD_ELEMENT,
          },
          {
            elementId: textElement.id,
            elementData: textElement,
            changeType: DocumentChangeType.DELETE_ELEMENT,
          },
        );
        actions.push(action);
        this.documentService.deselectAllElements();
        this.documentService.handleDocumentActions(actions);
      } else {
        // For items and colors
        this.documentComponentService.addItemDataAsComponentElement(data, options);
      }
    } else if (event.dataTransfer.files.length > 0) {
      const files = event.dataTransfer.files;
      this.documentService.fileHandler.addImageElementsFromFiles(files, options, { scaleSize: true });
    }
  }
  private setSizeAndPosition() {
    this.sizePositionHandler?.setSizeAndPosition();
  }

  dragEnter(event) {
    event.stopPropagation();
    event.preventDefault();
    if (
      !event.fromElement ||
      this.dragEnterFromElement?.classList?.contains('chooser-data-asset') ||
      (document.getElementsByClassName('chooser-data-asset') || [])[0]?.contains(event?.fromElement)
    ) {
      this.dragEnterFromElement = true;
      this.canvasDocument.sendEvent({
        eventType: 'file_drag_enter',
        sourceMouseEvent: event,
        element: null,
      });
    }
  }

  dragOver(e) {
    const event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  }

  handleFileDragOver() {
    this.subscriptions.push(
      fromEvent(document.getElementById('canvas-container'), 'dragover')
        .pipe(
          throttleTime(300, undefined, { leading: true, trailing: true }),
          tap((event: MouseEvent) => {
            if (this.dragEnterFromElement) {
              this.canvasDocument.sendEvent({
                eventType: 'file_drag_over',
                sourceMouseEvent: event,
                element: null,
              });
            }
          }),
        )
        .subscribe(),
    );
  }

  dragLeave(event) {
    event.stopPropagation();
    event.preventDefault();
    this.dragEnterFromElement = null;
    this.canvasDocument.sendEvent({
      eventType: 'file_drag_leave',
      sourceMouseEvent: event,
      element: null,
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setSizeAndPosition();
  }

  registerMouseMoveHandler() {
    this.subscriptions.push(
      fromEvent(document, 'mousemove')
        .pipe(
          throttleTime(25, undefined, { leading: true, trailing: true }),
          tap((event: MouseEvent) => {
            if (this.sizePositionHandler && this.editorMode === 'EDIT') {
              this.sizePositionHandler.handleMouseMove(event);
            }
          }),
          // Throttle broadcasting mouse move to once every 300ms because we don't need
          // to send so many events.
          throttleTime(300, undefined, { leading: true, trailing: true }),
          tap((event: MouseEvent) => {
            if (this.sizePositionHandler && this.editorMode === 'EDIT') {
              this.sizePositionHandler.setLastKnownMousePosition({
                x: event.x,
                y: event.y,
              });
              this.composerService.broadcastMouseMove(
                this.sizePositionHandler.computeDocumentPositionForMouseEvent(event),
              );
            }
          }),
        )
        .subscribe(),
    );
  }

  @HostListener('document:visibilitychange', ['$event'])
  handleVisibilityChange(event: MouseEvent) {
    if (!this.sizePositionHandler) {
      return;
    }
    this.documentService.handleDocumentNavigationEvent({
      eventType: 'visibilitychange',
      navigationType: document.visibilityState,
    });
    // Redraw canvas when visibility is restored
    // Workaround for https://issues.chromium.org/issues/328755781
    if (this.canvasDocument && document.visibilityState === 'visible') {
      this.canvasDocument.queueDraw();
    }
  }

  @HostListener('document:mousedown', ['$event'])
  handleMouseDown(event: MouseEvent) {
    if (!this.sizePositionHandler) {
      return;
    }
    this.sizePositionHandler.handleMouseDown(event);
  }

  @HostListener('document:mouseup', ['$event'])
  handleMouseUp(event: MouseEvent) {
    if (!this.sizePositionHandler) {
      return;
    }
    this.sizePositionHandler.handleMouseUp(event);
  }

  @HostListener('wheel', ['$event']) onMouseWheelChrome(event: any) {
    if (!this.sizePositionHandler) {
      return;
    }

    const objHierarchy = event.path || (event.composedPath && event.composedPath());
    for (const obj in objHierarchy) {
      if (
        this.SKIP_KEY_EVENTS_FOR_COMPONENTS?.length &&
        objHierarchy[obj] &&
        this.SKIP_KEY_EVENTS_FOR_COMPONENTS.includes(objHierarchy[obj].localName)
      ) {
        return;
      }
    }

    this.sizePositionHandler.handleMouseWheelEvent(event);
    // Prevent default to disable Safari/Firefox native mouse wheel animation to go back a page.
    event.preventDefault();
  }

  zoomIn() {
    this.sizePositionHandler?.zoomIn();
  }

  zoomOut() {
    this.sizePositionHandler?.zoomOut();
  }
  center() {
    this.sizePositionHandler.center();
  }

  @HostListener('document:keydown', ['$event']) handleKeyDownEvent(event: KeyboardEvent) {
    if (event.code === 'KeyV' && event.shiftKey && (event.ctrlKey || event.metaKey)) {
      this.isShiftKey = true;
    }
  }

  @HostListener('document:keyup', ['$event']) handleKeyUpEvent(event: KeyboardEvent) {
    this.isShiftKey = false;
  }

  @HostListener('document:paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    if (this.editorMode !== EditorMode.EDIT) {
      return;
    }
    const options: DocumentElement = {};
    if (this.sizePositionHandler.lastKnownMousePosition) {
      options.position = this.sizePositionHandler.computeDocumentPositionForCoordinates(
        this.sizePositionHandler.lastKnownMousePosition,
      );
    } else {
      options.position = this.sizePositionHandler.computeDocumentPositionForCanvasCenter();
    }

    this.documentService.documentClipboard.pasteCopiedElements(event, options, this.isShiftKey, { scaleSize: true });
  }
}

export function debounce(delay: number = 3): MethodDecorator {
  return function (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) {
    const timeoutKey = Symbol();
    const original = descriptor.value;
    descriptor.value = function (...args) {
      clearTimeout(this[timeoutKey]);
      this[timeoutKey] = setTimeout(() => original.apply(this, args), delay);
    };
    return descriptor;
  };
}
