<div class="relative h-full flex items-center">
  <input
    class="font-size-input"
    type="text"
    maxlength="3"
    [value]="size"
    (change)="inputResize($event); select.close()"
    (click)="$event.target.select(); select.open()"
  />
  <mat-select #select panelClass="font-size-select-dropdown" panelWidth="20">
    <mat-option
      class="tinymce-format-button"
      *ngFor="let sizeValue of sizes"
      [value]="sizeValue"
      [disabled]="isDisabled(sizeValue)"
      (onSelectionChange)="matSelectResize($event)"
    >
      {{ sizeValue }}
    </mat-option>
  </mat-select>
  <div>
    <div
      class="font-size-button font-size-button__inrease tinymce-format-button"
      data-test="font-size-increase"
      (click)="inc()"
    >
      <svg viewBox="0 0 10 5">
        <path xmlns="http://www.w3.org/2000/svg" d="M5 0l5 5H0z" fill-rule="evenodd" fill="currentColor"></path>
      </svg>
    </div>
    <div
      class="font-size-button font-size-button__decrease tinymce-format-button"
      data-test="font-size-decrease"
      (click)="dec()"
    >
      <svg viewBox="0 0 10 5">
        <path xmlns="http://www.w3.org/2000/svg" d="M5 5l5-5H0z" fill-rule="evenodd" fill="currentColor"></path>
      </svg>
    </div>
  </div>
</div>
