import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject, debounceTime, filter, takeUntil, tap } from 'rxjs';
import { DocumentActions } from '../document/document-store';
import { SideMenuOverlay } from '../document/document-store/document.state';
import { EntityChooserService } from './entity-chooser.service';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { ComposerService } from '../composer/composer.service';
import { PresentationCollectionElement, PresentationFrame } from '../presentation';
import { ItemData } from '@common/item-data/item-data';
import { ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AssortmentUtil } from '@common/assortments/assortment-util';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { FeatureFlag, Feature } from '@common/feature-flags/feature-flag';
import { DocumentService } from '../document/document.service';
import { ChooserSourceOptionTypes } from '@common/item-data-chooser/source-option';
import { PresentationItemExtractor } from '../presentation.item.extractor';

@Component({
  selector: 'app-entity-chooser',
  templateUrl: './entity-chooser.component.html',
  styleUrls: ['./entity-chooser.component.scss'],
})
export class EntityChooserComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  public currentData$: Observable<any>;
  public visible: Observable<boolean>;
  @Input() resultsHeight = '100%';
  @Input() targetSourceType: ChooserSourceOptionTypes;
  @Input() hideSource: boolean = false;
  @Input() title: string;
  currentFrame: PresentationFrame;
  private existingItemIds: Set<string> = new Set();
  public existingItemIds$: BehaviorSubject<any> = new BehaviorSubject(null);
  allowAddMultipleEntities = false;
  allowAddEntity = false;
  allowAddDuplicate = true;
  itemChooserLevelSelectionActive = false;
  gridFrameActiveContainer: PresentationCollectionElement;

  constructor(
    private store: Store<RootStoreState.State>,
    private composerDataChooserSevice: EntityChooserService,
    private composerService: ComposerService,
    private documentService: DocumentService,
    private snackBar: MatSnackBar,
  ) {
    this.visible = this.composerDataChooserSevice.visible;
    this.currentData$ = this.composerDataChooserSevice.currentData$;

    this.composerService.currentFrame.pipe(takeUntil(this.destroy$)).subscribe((frame) => {
      this.currentFrame = frame;

      this.allowAddEntity = true;
      this.allowAddMultipleEntities = true;
      if (['collection', 'grid'].indexOf(this.currentFrame?.type) !== -1) {
        // let itemIds: Set<string> = new Set();
        // frame.collection.set.forEach((item) => {
        //   itemIds.add(item.value);
        //   item.children?.forEach((option) => itemIds.add(option.value));
        // });
        // if (!this.areSetsEqual(this.existingItemIds, itemIds)) {
        //   this.existingItemIds = itemIds;
        //   this.existingItemIds$.next(this.existingItemIds);
        //   console.log(this.existingItemIds);
        // }
        this.allowAddDuplicate = true;
      } else if (this.currentFrame?.type === 'document') {
        this.allowAddDuplicate = true;
      }
    });

    // this.documentService.itemElements.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe((elements) => {
    //   if (this.currentFrame?.type === 'document') {
    //     let itemIds: Set<string> = new Set();
    //     elements.forEach((element) => itemIds.add(element.modelBindings.item.split(':')[1]));
    //     if (!this.areSetsEqual(this.existingItemIds, itemIds)) {
    //       this.existingItemIds = itemIds;
    //       this.existingItemIds$.next(this.existingItemIds);
    //       console.log(this.existingItemIds);
    //     }
    //   }
    // });

    // Extract existing items from the entire presentation
    this.composerService.presentation.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe((pres) => {
      const items = PresentationItemExtractor.extractItemsFromPresentation(pres);
      const itemIds: Set<string> = new Set();
      items.map((i) => itemIds.add(i.id));
      if (!this.areSetsEqual(this.existingItemIds, itemIds)) {
        this.existingItemIds = itemIds;
        this.existingItemIds$.next(this.existingItemIds);
      }
    });

    this.store
      .select(ShowcasesSelectors.gridFrameActiveContainer)
      .pipe(takeUntil(this.destroy$))
      .subscribe((gridFrameActiveContainer) => {
        this.gridFrameActiveContainer = gridFrameActiveContainer;
      });

    this.store
      .select(FeatureFlagsSelectors.featureFlags)
      .pipe(
        tap((flags: FeatureFlag[]) => {
          if (flags.map((x) => x.featureName).includes(Feature.ITEM_CHOOSER_LEVEL_SELECTION)) {
            this.itemChooserLevelSelectionActive = true;
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  hide() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Item Chooser';
    overlay.slug = 'addProduct';
    overlay.targetSourceType = null;
    overlay.showChooser = false;

    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  async addItemData(itemData: ItemData) {
    if (this.currentFrame.type === 'collection') {
      this.composerService.addItemsToCollectionFrame([itemData]);
    } else if (this.currentFrame.type === 'grid') {
      if (this.gridFrameActiveContainer) {
        this.composerService.addItemsToGridFrame([itemData], this.gridFrameActiveContainer);
      } else {
        this.snackBar.open('Please select at least one section before selecting an item.', '', { duration: 2000 });
      }
    } else if (this.currentFrame.type === 'document') {
      this.composerService.addItemToDocumentFrame(AssortmentUtil.convertItemData(itemData));
    }
  }

  async addMultipleItemData(itemData: ItemData[]) {
    if (this.currentFrame.type === 'collection') {
      this.composerService.addItemsToCollectionFrame(itemData);
    } else if (this.currentFrame.type === 'grid') {
      if (this.gridFrameActiveContainer) {
        this.composerService.addItemsToGridFrame(itemData, this.gridFrameActiveContainer);
      } else {
        this.snackBar.open('Please select at least one section before selecting an item.', '', { duration: 2000 });
      }
    } else if (this.currentFrame.type === 'document') {
      this.composerService.addItemsToDocumentFrame(itemData.map(AssortmentUtil.convertItemData));
    }
  }

  private areSetsEqual(set1, set2) {
    if (set1.size !== set2.size) {
      return false;
    }
    for (let item of set1) {
      if (!set2.has(item)) {
        return false;
      }
    }
    return true;
  }
}
