import { Component, Input, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { AssortmentHistoryComponent } from 'src/app/common/assortment-history/assortment-history.component';
import { Showcase } from 'src/app/showcases/showcases-store/showcases.state';
import { Workspace } from '@common/workspaces/workspaces-store/workspaces.state';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Entities } from '@contrail/sdk';

@Component({
  selector: 'app-showcase-configuration',
  templateUrl: './showcase-configuration.component.html',
  styleUrls: ['./showcase-configuration.component.scss'],
})
export class ShowcaseConfigurationComponent implements OnInit {
  @Input() showcase: Showcase;
  public selectedWorkspace$: Observable<Workspace>;

  public locationLoading = true;
  folders = [];

  constructor(
    private store: Store<RootStoreState.State>,
    private matDialog: MatDialog,
  ) {
    this.selectedWorkspace$ = this.store.select(WorkspacesSelectors.currentWorkspace);
  }

  async ngOnInit() {
    this.locationLoading = true;
    const ws = this.showcase?.workspace;
    if (ws?.workspaceType !== 'PROJECT') {
      this.folders.unshift(ws?.name);

      const rootId = ws?.rootWorkspaceId;
      let parentId = ws?.parentId;

      const arr = [...Array(20).keys()];
      for (let idx = 0; idx < arr.length; idx++) {
        const parent = await this.getParentFolder(parentId);
        if (parent?.id === rootId) {
          break;
        } else {
          parentId = parent?.parentId;
          this.folders.unshift(parent?.name);
        }
      }
      this.locationLoading = false;
    }
  }

  private async getParentFolder(id) {
    return await new Entities().get({ entityName: 'workspace', id });
  }

  viewHistory() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '1200px';
    dialogConfig.data = { assortmentId: this.showcase.sourceAssortmentId };
    dialogConfig.panelClass = 'no-padding'; // defined in _dialog.scss,

    const dialogRef = this.matDialog.open(AssortmentHistoryComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }

  getActorName(actor) {
    if (actor?.firstName) {
      return actor?.firstName + ' ' + actor?.lastName;
    }
    return actor?.email;
  }
}
