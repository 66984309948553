import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { ComposerToolbarService } from '../composer-toolbar/composer-toolbar.service';
import { ComposerService } from '../composer.service';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
@Component({
  selector: 'app-composer-frame-toolbar',
  templateUrl: './composer-frame-toolbar.component.html',
  styleUrls: ['./composer-frame-toolbar.component.scss'],
})
export class ComposerFrameToolBarTrayComponent implements OnInit {
  @ViewChild('backgroundColorPicker') backgroundColorPicker;
  currentFrame: any;
  public editorMode: EditorMode;
  constructor(
    private service: ComposerToolbarService,
    private store: Store<RootStoreState.State>,
  ) {
    this.store.select(EditorModeSelectors.editorMode).subscribe((mode) => (this.editorMode = mode));
  }

  ngOnInit(): void {}

  launchBackgroundImageLoader() {}

  showComposerFrameSettings() {
    this.service.showComposerFrameSettings();
  }
}
