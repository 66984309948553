import { DocumentElement, Document } from '@contrail/documents';
import { EntityReference } from '@contrail/sdk';

/**
 * This file should be generic so that it can be used in other apps.
 */
export class DocumentAssortmentHelper {
  public static getItemIdFromElement(element: DocumentElement) {
    const itemRef = element.modelBindings?.item;
    if (!itemRef) {
      return;
    }
    const ref = new EntityReference(itemRef);
    return ref.id;
  }

  public static getItemIdsFromDocuments(documents: Array<Document>): Array<string> {
    const itemIds: Set<string> = new Set();
    for (const doc of documents) {
      for (const element of doc.elements) {
        const id = this.getItemIdFromElement(element);
        if (id) {
          itemIds.add(id);
        }
      }
    }
    return [...itemIds];
  }
}
