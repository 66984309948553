import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { ListColumnDefinition } from 'src/app/common/components/list/list-column-definition';
import { RootStoreState } from 'src/app/root-store';
import { SearchBarComponent } from 'src/app/common/components/search-bar/search-bar.component';
import {
  ContextMenuActionDefinition,
  ContextMenuComponent,
} from 'src/app/common/components/context-menu/context-menu.component';
import { startWith, map } from 'rxjs/operators';
import { ConfirmationBoxService } from 'src/app/common/components/confirmation-box/confirmation-box';
import { SortByOptions } from '@common/util/filter-util';
import { UntypedFormControl } from '@angular/forms';
import { AuthSelectors } from '@common/auth/auth-store';
import { AuthService } from '@common/auth/auth.service';
import { FrameTemplatesActions, FrameTemplatesSelectors } from '@common/frame-templates/frame-templates-store';
import { FrameTemplate } from '@common/frame-templates/frame-template';
import { ObjectUtil } from '@contrail/util';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { FrameTemplatesService } from '@common/frame-templates/frame-templates.service';
import { FeatureFlagActions } from '@common/feature-flags';
import { DocumentService } from '../../document/document.service';
import { FrameTemplateEditorModal } from '../frame-template-editor-modal/frame-template-editor-modal.component';

@Component({
  selector: 'app-frame-template-library',
  templateUrl: './frame-template-library.component.html',
  styleUrls: ['./frame-template-library.component.scss'],
})
export class FrameTemplateLibrary implements AfterViewInit {
  public viewMode = 'cards';
  public templates$: Observable<Array<any>>;
  public columnDefinitions: Array<ListColumnDefinition>;
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;
  @ViewChild(ContextMenuComponent) contextMenu: ContextMenuComponent;
  public contextMenuActions: Array<ContextMenuActionDefinition>;
  public templateTypes = [
    { key: 'allTemplates', label: 'All Templates' },
    { key: 'private', label: 'Private' },
    { key: 'organization', label: 'Organization' },
  ];
  sortOptions = SortByOptions;
  public sortByControl = new UntypedFormControl({ label: 'Date Modified', property: 'updatedOn' });
  public myTemplatesOnly = new UntypedFormControl(false);
  public templateTypeControl = new UntypedFormControl('allTemplates');
  private isOrgAdmin = false;
  private authContext: any;
  public allowEdit: boolean;
  private editingTemplateId = '';
  constructor(
    private store: Store<RootStoreState.State>,
    private matDialog: MatDialog,
    private documentService: DocumentService,
    private confirmationBoxService: ConfirmationBoxService,
    private authService: AuthService,
    private frameTemplatesService: FrameTemplatesService,
  ) {
    this.columnDefinitions = [
      {
        index: 'name',
        label: 'Name',
        alignment: 'left',
        propertyType: 'text',
        getDisplay: this.getTemplateName,
      },
      {
        index: 'updatedBy.email',
        label: 'Updated By',
        alignment: 'right',
        propertyType: 'string',
      },
      {
        index: 'updatedOn',
        label: 'Updated',
        alignment: 'right',
        propertyType: 'date',
      },
      {
        index: 'createdBy.email',
        label: 'Created By',
        alignment: 'right',
        propertyType: 'string',
      },
      {
        index: 'createdOn',
        label: 'Created',
        alignment: 'right',
        propertyType: 'date',
      },
    ];
    this.isOrgAdmin = this.authService.isAdmin();
    this.allowEdit = !this.authService.isOnSharedLink();
    if (this.allowEdit) {
      this.contextMenuActions = [{ actionName: 'edit', icon: 'edit', label: 'Edit' }];
      this.contextMenuActions.push({ actionName: 'delete', icon: 'delete_forever', label: 'Delete' });
    }
    this.store.dispatch(FeatureFlagActions.loadFeatureFlags());
    this.frameTemplatesService.initialize(['SHOWCASE-FRAME']);
  }

  subscribe() {
    this.templates$ = combineLatest([
      this.searchBar.valueChange.pipe(startWith('')),
      this.myTemplatesOnly.valueChanges.pipe(startWith(false)),
      this.templateTypeControl.valueChanges.pipe(startWith('allTemplates')),
      this.sortByControl.valueChanges.pipe(startWith({ label: 'Date Modified', property: 'updatedOn' })),
      this.store.select(FrameTemplatesSelectors.frameTemplates),
      this.store.select(AuthSelectors.selectAuthContext),
    ]).pipe(
      map(([searchTerm, myTemplates, templateType, sortByOption, templates, authContext]) => {
        this.authContext = authContext;
        const sortByField = sortByOption?.property;
        const keys = 'name';
        if (this.editingTemplateId !== '') {
          this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
          const frameTemplate = templates.find((template) => template.id === this.editingTemplateId);
          this.showEditModal(frameTemplate);
        }
        return templates
          .filter((item) =>
            keys.split(',').some((key) => item.hasOwnProperty(key) && new RegExp(searchTerm, 'gi').test(item[key])),
          )
          .filter((sc) => this.testMyTemplates(sc, myTemplates, authContext))
          .filter((sc) => this.testTemplateType(sc, templateType))
          .sort((template1, template2) => {
            if (sortByField === 'name' || sortByField === 'createdById') {
              return template2[sortByField] < template1[sortByField] ? 1 : -1;
            } else {
              return template2[sortByField] > template1[sortByField] ? 1 : -1;
            }
          });
      }),
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.subscribe(), 0); // SOLVES STATE CHANGE ERROR
  }

  sortBy(option) {
    this.sortByControl.setValue(option);
  }

  private testMyTemplates(obj, myTemplates, authContext) {
    if (!myTemplates) {
      return true;
    }
    return obj.createdById === authContext.user.id;
  }

  private testTemplateType(sc: FrameTemplate, templateType: any): boolean {
    if (templateType === 'private' && !sc.private) {
      return false;
    }

    if (templateType === 'organization' && !sc.admin) {
      return false;
    }
    return true;
  }

  setViewMode(mode) {
    this.viewMode = mode;
  }

  handleContextMenu($event) {
    if (this.isEditAllowed($event.listItem)) {
      this.contextMenu.show($event.mouseEvent, $event.listItem);
    }
  }

  handleMenuAction($event) {
    switch ($event.action) {
      case 'edit':
        this.update($event.target);
        break;
      case 'delete':
        this.delete($event.target);
        break;
    }
  }

  async update(frameTemplate: FrameTemplate) {
    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true }));
    this.editingTemplateId = frameTemplate.id;
    if (!frameTemplate.document.elements) {
      this.store.dispatch(FrameTemplatesActions.loadFrameTemplate({ frameTemplateId: frameTemplate.id }));
    } else {
      await this.showEditModal(frameTemplate);
    }
  }

  async showEditModal(frameTemplate: FrameTemplate) {
    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
    const isEditAllowed = this.isEditAllowed(frameTemplate);
    this.editingTemplateId = '';
    const elements = ObjectUtil.cloneDeep(frameTemplate.document.elements);
    const frame = {
      position: { x: 0, y: 0 },
      size: ObjectUtil.cloneDeep(frameTemplate.document.size),
      type: 'frame',
      style: ObjectUtil.cloneDeep(frameTemplate.document.style),
    };
    const authContext = await this.authService.getAuthContext();
    const config = {
      data: {
        authContext,
        isOrgAdmin: this.isOrgAdmin,
        isEditAllowed,
        frameTemplate,
        elements,
        frame,
        ownerReference: this.documentService.ownerReference,
      },
      panelClass: 'frame-template-editor',
      minWidth: '1200px',
    };
    this.matDialog.open(FrameTemplateEditorModal, config);
  }

  async delete(frameTemplate) {
    const confirm = await this.confirmationBoxService.open(
      `Delete Frame Template: ${frameTemplate.name}`,
      'Are you sure you want to delete this template?',
    );
    if (confirm) {
      this.store.dispatch(FrameTemplatesActions.deleteFrameTemplate({ frameTemplate }));
    }
  }

  public showMenu($event) {
    if (this.isEditAllowed($event.listItem)) {
      this.contextMenu.show($event.mouseEvent, $event.listItem);
    }
  }

  private isEditAllowed(frameTemplate: FrameTemplate) {
    return this.myTemplatesOnly.value || this.isOrgAdmin || frameTemplate.createdById === this.authContext.user.id;
  }

  private getTemplateName(template: FrameTemplate): string {
    const icon = template.admin ? 'assets/images/group_icon.svg' : 'assets/images/person_icon.svg';
    const value =
      '<div style="display: flex; align-items: center;">' +
      ObjectUtil.getByPath(template, 'name') +
      '<span style="margin-left: 10px; opacity: 0.65"><img src="' +
      icon +
      '"></span></div>';
    return value;
  }
}
