import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DocumentElementEvent } from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { Subscription } from 'rxjs';
import { DocumentElement } from '@contrail/documents';
import { DocumentService } from '../../document.service';
import { DocumentStatusMessageService } from '../document-status-message.service';
import { MatLegacyTooltip as MatTooltip } from '@angular/material/legacy-tooltip';

@Component({
  selector: 'document-status-message',
  templateUrl: './document-status-message.component.html',
  styleUrls: ['./document-status-message.component.scss'],
})
export class DocumentStatusMessageComponent implements OnInit, OnDestroy {
  constructor(
    private service: DocumentService,
    private statusMessageService: DocumentStatusMessageService,
  ) {}

  public sourceEvent: DocumentElementEvent;
  public elements: Array<DocumentElement>;
  public annotationMessage: string;
  @ViewChild('annotationDiv') widgetElement: ElementRef;
  @ViewChild('tooltip') toolTipElement: MatTooltip;

  private eventSub: Subscription;

  ngOnInit(): void {
    this.subscribe();
  }
  ngOnDestroy() {
    this.unsubscribe();
  }

  subscribe() {
    this.eventSub = this.service.documentElementEvents.subscribe((event) => {
      if (!event) {
        return;
      }
      if (event.element) {
        if (event.eventType === 'showAnnotation') {
          this.show(event);
          this.toolTipElement.show();
        } else if (event.eventType === 'hideAnnotation') {
          this.toolTipElement.hide();
        }
      }
    });
  }
  unsubscribe() {
    this.eventSub.unsubscribe();
  }

  show(event: DocumentElementEvent) {
    if (event.element) {
      if (!this.widgetElement) {
        return;
      }
      this.annotationMessage = this.statusMessageService.getMessage(event.element);
      if (this.annotationMessage) {
        this.sourceEvent = event;
        const top = event.renderedElementPosition.y + event.renderedElementSize.height / 2;
        const left = event.renderedElementPosition.x + event.renderedElementSize.width;
        this.widgetElement.nativeElement.setAttribute('style', `left:${left}px; top:${top}px;`);
      }
    }
  }
}
