import { Component, OnInit } from '@angular/core';
import { ComposerService } from '../../composer.service';
import { PresentationFrame } from '../../../presentation';
import { ObjectUtil } from '@contrail/util';

@Component({
  selector: 'app-composer-frame-toolbar-visibility',
  templateUrl: './composer-frame-toolbar-visibility.component.html',
  styleUrls: ['./composer-frame-toolbar-visibility.component.scss'],
})
export class ComposerFrameToolbarVisibilityComponent implements OnInit {
  currentFrame: any;
  constructor(private composerService: ComposerService) {
    this.composerService.currentFrame.subscribe((frame) => {
      this.currentFrame = frame;
    });
  }

  ngOnInit(): void {}

  toggleSkipFrame(frame: PresentationFrame) {
    const existingFrame = ObjectUtil.cloneDeep(frame);
    frame.disabled = !frame.disabled;
    this.composerService.handleFrameChanges(frame, existingFrame);
  }
}
