import { ObjectUtil } from '@contrail/util';

export class ItemData {
  constructor(obj: any) {
    if (obj.entityType === 'assortment-item' || obj.assortmentItem) {
      this.loadFromAssortmentItem(obj);
    } else if (obj.clipboardId) {
      this.loadFromClipboardItem(obj);
    } else {
      this.loadFromItem(obj);
    }
  }

  public properties: any = {};
  public id;
  public item;
  public projectItem;
  public options: Array<ItemData> = [];
  public content: Array<any>;
  public assortmentItem: any;
  public thumbnail: string;
  public assortmentId: string;
  public clipboardItemId?: string;
  public clipboardItemUpdatedOn?: Date;

  loadFromItem(obj) {
    if (!obj) {
      return;
    }
    if (obj.item) {
      this.item = obj.item;
    } else {
      this.item = obj;
    }
    this.properties = Object.assign(this.properties, { ...this.item }, { ...obj.projectItem });
    this.properties.itemName = this.item.name;
    this.thumbnail = this.getThumbnail(this.item);
    this.properties.thumbnail = this.thumbnail;
    if (this.item.itemId) {
      this.properties.id = this.item.itemId;
    }
    this.properties.itemId = this.item.id;

    if (obj.projectItem) {
      this.projectItem = obj.projectItem;
      this.properties.projectItemId = obj.projectItem?.id;
      this.properties.projectId = obj.projectItem?.projectId;
    }

    this.id = this.item.id;
    if (obj.assortmentId) {
      this.assortmentId = obj.assortmentId;
    }
  }

  loadFromAssortmentItem(assortmentItem) {
    if (!assortmentItem.item) {
      return;
    }
    this.assortmentItem = ObjectUtil.cloneDeep(assortmentItem);
    delete this.assortmentItem.item;

    this.item = assortmentItem.item;

    this.properties = Object.assign(
      this.properties,
      { ...assortmentItem.item },
      { ...assortmentItem.projectItem },
      { ...assortmentItem },
    );
    this.properties.itemName = assortmentItem.item.name;
    this.thumbnail = this.getThumbnail(assortmentItem.item);
    this.properties.thumbnail = this.thumbnail;
    this.properties.id = assortmentItem.itemId;
    this.properties.itemId = assortmentItem.itemId;
    this.properties.assortmentItemId = assortmentItem.id; // HOLD ON TO THE SOURCE ASSORTMENT ITEM ID

    if (assortmentItem.projectItem) {
      this.projectItem = assortmentItem.projectItem;
      this.properties.projectItemId = assortmentItem.projectItem?.id;
      this.properties.projectId = assortmentItem.projectItem?.projectId;
    }

    // removed the item object from properties
    delete this.properties.item;

    this.id = assortmentItem.item.id;
    if (assortmentItem.assortmentId) {
      this.assortmentId = assortmentItem.assortmentId;
    }
    // this.properties.name = obj.item.optionName || this.properties.itemName;
  }

  loadFromClipboardItem(clipboardItem: any) {
    const item = {
      ...clipboardItem.item,
      projectItem: clipboardItem?.projectItem,
    };

    this.loadFromItem(item);

    this.projectItem = clipboardItem?.projectItem;
    this.properties.projectName = clipboardItem?.projectItem?.project?.name;
    this.clipboardItemId = clipboardItem.id;
    this.clipboardItemUpdatedOn = clipboardItem.updatedOn;
    this.properties = Object.assign(this.properties, { clipboardItemUpdatedOn: this.clipboardItemUpdatedOn });
  }

  getName() {
    return `${this.properties.itemName} ${this.properties.optionName ? '(' + this.properties.optionName + ')' : ''}`.trim();
  }
  getThumbnail(obj) {
    let thumb;
    thumb = obj.mediumViewableDownloadUrl;
    if (!thumb) {
      const viewable = obj.primaryViewable;
      if (viewable) {
        thumb =
          // viewable.smallViewable?.downloadUrl ||
          // viewable.mediumViewable?.downloadUrl ||
          viewable.primaryFile?.downloadUrl;
      }
    }
    return thumb;
  }
}
