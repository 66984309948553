import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Store } from '@ngrx/store';

import { RootStoreState } from 'src/app/root-store';
import { DocumentActions } from '../document-store';
import { SideMenuOverlay } from '../document-store/document.state';
import { DocumentService } from '../document.service';

@Component({
  selector: 'app-document-widget-tray',
  templateUrl: './document-widget-tray.component.html',
  styleUrls: ['./document-widget-tray.component.scss'],
})
export class DocumentWidgetTrayComponent implements OnInit {
  constructor(
    private documentService: DocumentService,
    private store: Store<RootStoreState.State>,
  ) {}

  @ViewChild(MatMenuTrigger)
  private menuTrigger: MatMenuTrigger;

  shapeMenus: Array<any> = [
    { id: 'rectangle', icon: 'crop_landscape', label: 'Rectangle' },
    { id: 'circle', icon: 'circle', label: 'Circle' },
    { id: 'line', icon: 'horizontal_rule', label: 'Line' },
    // {id: 'arrow', icon: 'arrow_right_alt', label: 'Arrow'}
  ];

  public elements;

  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };

  ngOnInit(): void {}

  setInteractionMode(mode) {
    this.documentService.setInteractionMode(mode);
  }

  getActiveShapeIcon() {
    return this.shapeMenus
      .filter((menu) => {
        return menu.id === this.documentService.getInteractionMode();
      })
      .map((menu) => {
        return menu.icon;
      })[0];
  }

  getActiveColor(mode) {
    return mode === this.documentService.getInteractionMode() || (mode === 'shape' && this.getActiveShapeIcon())
      ? 'accent'
      : '';
  }

  async onContextMenu(event: MouseEvent) {
    const menu = document.getElementById('toolMenuBtn');
    menu.style.display = '';
    menu.style.position = 'fixed';
    menu.style.left = '80px';
    menu.style.top = event.pageY - 55 + 'px';

    this.menuTrigger.openMenu();
  }

  closeToolMenu() {
    this.menuTrigger.closeMenu();
  }
  onToolMenuClosed(event): void {
    const menu = document.getElementById('toolMenuBtn');
    if (menu) {
      menu.style.display = 'none';
    }
  }

  showComments() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Comments';
    overlay.slug = 'showComments';
    overlay.showChooser = true;

    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  showItemChooser() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Item Chooser';
    overlay.slug = 'addProduct';
    overlay.showChooser = true;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }
}
