import { Component, Input, OnInit } from '@angular/core';
import { TypeProperty } from '@contrail/types';
import { LocalCsvExportService } from './local-csv-export.service';

@Component({
  selector: 'app-local-csv-export',
  templateUrl: './local-csv-export.component.html',
  styleUrls: ['./local-csv-export.component.scss'],
})
export class LocalCsvExportComponent implements OnInit {
  @Input() data: Array<any> = [];
  @Input() exportProperties: Array<TypeProperty> = [];
  @Input() owner;
  @Input() exportFileName = 'data_export';
  constructor(private service: LocalCsvExportService) {}
  ngOnInit(): void {}

  downloadCSV() {
    this.service.downloadFile(
      JSON.parse(JSON.stringify(this.data)),
      this.exportProperties,
      this.owner?.id,
      this.exportFileName,
    );
  }
}
