import {
  DocumentAction,
  DocumentChangeType,
  DocumentElement,
  DocumentElementFactory,
  PositionDefinition,
} from '@contrail/documents';
import { ClipboardContent } from '../clipboard-content';
import { DocumentService } from '../../document.service';
import { ObjectUtil } from '@contrail/util';
import { Document } from '@contrail/documents';
import { CoordinateHelper, SVGDocument } from '@contrail/svg';
import { DocumentItemService } from '../../document-item/document-item.service';
import { AuthService } from '@common/auth/auth.service';
import { EntityReference } from '@contrail/sdk';

const KEY_EVENT_IGNORE_PATHS = ['app-comment-overlay', 'app-item-data-chooser', 'textarea', 'input'];

export class ClipboardPasteHandler {
  constructor(
    private documentService: DocumentService,
    private authService: AuthService,
  ) {}

  private isEventAllowed(event: any) {
    for (const el of event.composedPath()) {
      // CHECK IF ORIGINATING FROM A MODAL
      if (KEY_EVENT_IGNORE_PATHS.includes(el.tagName?.toLowerCase())) {
        return false;
      }
    }
    return true;
  }

  public async handleDocumentPasteEvent(pasteEvent, options: DocumentElement = {}, shiftKey = false) {
    if (!this.isEventAllowed(pasteEvent)) {
      return;
    }

    // Get contents from OS clipboard
    const clipboardContents = await this.getClipboardContent(pasteEvent, shiftKey);
    if (!clipboardContents || clipboardContents.length === 0) {
      return;
    }

    if (clipboardContents.length === 1 && clipboardContents[0].type === 'json') {
      this.pasteDocumentElementsFromClipboard(options, clipboardContents[0].content, shiftKey);
    } else {
      const imageClipboardContents = clipboardContents.filter((c) => c.type === 'image' || c.type === 'svg');
      const textClipboardContents = clipboardContents.filter((c) => c.type === 'text');
      if (imageClipboardContents?.length > 0) {
        this.handleImageClipboardContent(imageClipboardContents, ObjectUtil.cloneDeep(options));
      }
      if (textClipboardContents?.length > 0) {
        this.handleTextClipboardContent(textClipboardContents, ObjectUtil.cloneDeep(options), shiftKey);
      }
    }

    pasteEvent.preventDefault();
  }

  /**
   * Process image files from clipboard, create Image or SVG document elements
   * and add them all in batch to the document.
   * @param clipboardContents
   * @param options
   */
  private async handleImageClipboardContent(clipboardContents: ClipboardContent[], options) {
    this.documentService.fileHandler.addImageElementsFromFiles(
      clipboardContents.map((c) => c.content),
      options,
    );
  }

  /**
   * Add elements from clipboard or new text elements to the document in batch.
   */
  private handleTextClipboardContent(
    clipboardContents: ClipboardContent[],
    options: DocumentElement = {},
    shiftKey = false,
  ) {
    const textElements = [];
    for (let i = 0; i < clipboardContents.length; i++) {
      const clipboardContent = clipboardContents[i];
      const textElement = this.createTextElementFromClipboardContent(clipboardContent, options, shiftKey);
      if (textElement) {
        textElements.push(textElement);
      }
    }
    if (textElements?.length > 0) {
      this.addNewElements(textElements);
    }
  }

  public async pasteDocumentElementsFromClipboard(
    options: DocumentElement = {},
    clipboardContent?: any,
    shiftKey = false,
    shouldPasteNearElement: boolean = false, // True when duplicating elements
  ) {
    const newElements = [];
    const sourceCopiedMap: Map<string, string> = new Map();
    let clipboardData = this.documentService.documentClipboard.getClipboardContent();
    // use data from local-storage if the copied data from local-storage has the same documentId as the raw json data from OS clipboard
    // or if the data in the OS clipboard belongs to a different org
    if (
      clipboardContent &&
      clipboardContent.orgId === this.authService.getCurrentOrg()?.orgId &&
      clipboardContent.documentId !== this.documentService.currentDocument?.id
    ) {
      clipboardData = clipboardContent;
    }
    let elements = clipboardData?.elements || [];

    if (elements?.length === 0) return;

    let lastFrameNumber;

    let reboundComponentElements = [];
    if (clipboardData?.documentId !== this.documentService.currentDocument?.id) {
      const componentElements = elements?.filter(DocumentItemService.isItemComponet);
      if (componentElements?.length > 0) {
        reboundComponentElements =
          await this.documentService.documentItemService.setComponentDataRelativeToSource(componentElements);
      }
    }

    // let positionOffset = { x: 50, y: 50 };
    // If elements are pasted to a different document id do not offset its position
    // if (clipboardData?.documentId !== this.documentService.currentDocument?.id) {
    let positionOffset = { x: 0, y: 0 };
    // }

    // Pasting between frames
    if (
      clipboardData?.documentId !== this.documentService.currentDocument?.id &&
      options?.position &&
      clipboardData?.entityType === 'presentation' &&
      this.documentService?.ownerReference
    ) {
      const entity = new EntityReference(this.documentService.ownerReference);
      if (entity?.entityType === 'presentation') {
        options.position = null;
      }
    }

    // If user is trying to paste elements from one frame to another
    const pasteElementsOnFrame = this.documentService.pasteElementsOnFrame(elements);
    if (pasteElementsOnFrame) {
      elements = pasteElementsOnFrame;
      options.position = null;
      positionOffset = { x: 0, y: 0 };
    }

    if (clipboardData?.documentId === this.documentService.currentDocument?.id) {
      const pasteFrameNearFramePosition = this.documentService.pasteFrame(elements, shiftKey);
      if (pasteFrameNearFramePosition) {
        options.position = pasteFrameNearFramePosition;
      }
    }

    // If there is is a specific position the elements should be pasted to, consider it
    // an offset position and place other pasted elements such that the original
    // layout is kept the same
    if (options?.position) {
      const firstElement = this.documentService.getLeftMostElement(elements);
      let firstElementPosition: PositionDefinition = this.documentService.getElementPosition(firstElement);
      const canvasElement = this.documentService.documentRenderer.getCanvasElementById(firstElement.id);
      const { width, height } = canvasElement
        ? canvasElement.getBoundingClientRect()
        : firstElement.size || { width: 0, height: 0 };
      const includesFrames = elements?.findIndex((e) => e.type === 'frame') !== -1;
      positionOffset = {
        x: options.position.x - (firstElementPosition.x + (width && !includesFrames ? width / 2 : 0)),
        y: options.position.y - (firstElementPosition.y + (height && !includesFrames ? height / 2 : 0)),
      };
    } else if (shouldPasteNearElement) {
      //Options.position is null when duplicating
      positionOffset = { x: 25, y: 25 }; // Added a 25 x and y offset to give a similar UX to powerpoint
    }

    for (let i = 0; i < elements.length; i++) {
      const item = elements[i];
      let copiedItem = ObjectUtil.mergeDeep({}, item);
      if (copiedItem.type === 'frame') {
        let frameNumber;
        if (lastFrameNumber != undefined) {
          frameNumber = lastFrameNumber + 1;
        } else {
          frameNumber = this.documentService.getNewFrameNumber();
        }
        lastFrameNumber = frameNumber;
        copiedItem.name = `Frame ${frameNumber}`;
      }

      if (copiedItem.isLocked) {
        copiedItem.isLocked = false;
      }

      if (
        DocumentItemService.isItemComponet(copiedItem) &&
        clipboardData?.documentId !== this.documentService.currentDocument?.id
      ) {
        const reboundComponentElement = reboundComponentElements.find((element) => element.id === copiedItem.id);
        if (reboundComponentElement) {
          copiedItem = reboundComponentElement;
        }
      }

      delete copiedItem.id;
      delete copiedItem.documentId;
      if (copiedItem?.elements?.length > 0) {
        for (const i of copiedItem.elements) {
          delete i.id;
          delete i.documentId;
        }
      }

      if (copiedItem.lineDefinition) {
        copiedItem.lineDefinition.x1 = copiedItem.lineDefinition.x1 + positionOffset.x;
        copiedItem.lineDefinition.y1 = copiedItem.lineDefinition.y1 + positionOffset.y;
        copiedItem.lineDefinition.x2 = copiedItem.lineDefinition.x2 + positionOffset.x;
        copiedItem.lineDefinition.y2 = copiedItem.lineDefinition.y2 + positionOffset.y;
      } else if (copiedItem.position) {
        copiedItem.position.x = copiedItem.position.x + positionOffset.x;
        copiedItem.position.y = copiedItem.position.y + positionOffset.y;
      }

      const element = DocumentElementFactory.createElement(item.type, copiedItem);
      newElements.push(element);
    }
    newElements
      .filter((newElement) => newElement.type === 'group')
      .forEach((groupElement) => {
        groupElement.elementIds = groupElement.elementIds.map((elementId) => sourceCopiedMap.get(elementId));
      });
    newElements
      ?.filter((newElement) => this.documentService?.documentRenderer?.isMask(newElement))
      ?.forEach((maskElement) => {
        const newElementIds = maskElement.elementIds
          .map((elementId) => sourceCopiedMap.get(elementId))
          .filter((id) => !!id);
        maskElement.elementIds = newElementIds?.length > 0 ? newElementIds : null;
      });
    this.addNewElements(newElements);
  }

  /**
   * Create new text elements or paste existing document elements from clipboard.
   * @param clipboardContent
   * @returns
   */
  private createTextElementFromClipboardContent(
    clipboardContent: ClipboardContent,
    options: DocumentElement = {},
    shiftKey = false,
  ) {
    const parsedContents = clipboardContent.content;
    // OS clipboard is empty if content === ' '. It was cleared when copying document elements from composer.
    // if OS clipboard contains an image copied from the composer, it starts with a string '<html>' and we don't
    // want to use that. We need to get it from the internal array.
    if (parsedContents.trim() === '' || parsedContents.startsWith('<html>') || parsedContents.startsWith('<meta')) {
      this.pasteDocumentElementsFromClipboard(options, null, shiftKey);
      return;
    }
    // the OS clipboard contains pure text
    const params: any = {
      size: {
        width: Math.min(1000, parsedContents.length * 12),
        height: 100,
      },
    };
    params.position = {
      x: options?.position?.x - params.size.width / 2 || 500,
      y: options?.position?.y - params.size.height / 2 || 500,
    };

    let newElement;
    const elements = this.documentService.documentClipboard.getClipboardContent()?.elements || [];
    if (elements.length === 1 && elements[0].type === 'text') {
      // The text elements in the clipboard could have '\n' in the content but the clipboard doesn't.
      if (elements[0].text.replace(/\n/g, '') === parsedContents) {
        const copiedItem = ObjectUtil.mergeDeep({}, elements[0]);
        copiedItem.position = {
          // move the copied text element so that it doesn't overlap with the source.
          x: options?.position ? options?.position?.x + copiedItem?.size?.width / 2 : copiedItem.position.x + 50,
          y: options?.position ? options?.position?.y + copiedItem?.size?.height / 2 : copiedItem.position.y + 50,
        };
        delete copiedItem.id;
        delete copiedItem.documentId;
        newElement = DocumentElementFactory.createElement('text', ObjectUtil.mergeDeep({}, copiedItem));
      } else {
        newElement = DocumentElementFactory.createTextElement(parsedContents, params);
      }
    } else {
      newElement = DocumentElementFactory.createTextElement(parsedContents, params);
    }
    return newElement;
  }

  private clipboardDataIncludesImageFile(data) {
    return (
      data &&
      !!data.find((item) => {
        return typeof item !== 'string' && item.type.indexOf('image') !== -1;
      })
    );
  }

  /**
   * Process @contentData to find relevant item to be pasted.
   * If DataTransferItems is string:
   * 1. <html> or <img> tags can be parsed to get image URL.
   *  Important: contentData.length should be > 1, otherwise this contentData
   *  is for pasting document elemens
   * 2. <svg> tags
   * 3. Pasting plain text or document elements
   *
   * If DataTransferItems is File:
   * 1. Pasting image, ai or pdf as images
   * @param contentData Resolved DataTransferItems
   */
  private getRelevantItems(contentData, shiftKey = false) {
    let items = [];

    for (const data of contentData) {
      if (typeof data === 'string') {
        // DataTransferItem is string
        let frame;
        try {
          // tries to determine if the string is JSON.
          frame = JSON.parse(data);
          if (frame.documentId) {
            return [
              {
                type: 'json',
                content: frame,
              },
            ];
          } else if (
            Array.isArray(frame) &&
            frame.length > 0 &&
            ['document', 'grid', 'collection', 'iframe', 'showroom'].includes(frame[0].type)
          ) {
            // this is a copied frame.. do not paste
            return;
          }
        } catch (e) {
          console.log('Not a JSON string');
        }
        // Image copied from web, google docs, etc.
        if (contentData.length > 1 && (data.startsWith('<html') || data.indexOf('<img ') !== -1)) {
          if (!this.clipboardDataIncludesImageFile(contentData)) {
            const el = document.createElement('html');
            el.innerHTML = data; // parse an html elem
            const imgElem = el.getElementsByTagName('img'); // look for the img elem
            if (imgElem.length > 0) {
              items = [
                {
                  type: 'derivedImage',
                  content: imgElem[0].src,
                },
              ];
              // We want to exit the loop because there could be two elements in contentData -
              // first one is string and second one is data file. If there is a string and we
              // found relevant element, we don't need the data file.
              break;
            }
          }
          // SVG copied from AI or plain svg html text
        } else if (data.indexOf('<svg') !== -1) {
          // Empty SVG - in case SVG was rasterized in AI it should be pasted as an image File
          if (contentData.length > 1 && (data.match(/<g/g) || []).length === 1 && /<g([^>]*)\/>/g.test(data) == true) {
            // <svg><g id="a"/></svg>
            console.log('Empty SVG', data);
          } else {
            if (shiftKey) {
              items = [
                {
                  type: 'svg',
                  content: new File([data], '', {
                    type: 'image/svg+xml',
                  }),
                },
              ];
              break;
            }
          }

          // Text copied from PPTX or Google Docs, etc.
        } else if (
          !data.startsWith('<html') &&
          !data.startsWith('{') &&
          !data.startsWith('<meta') &&
          data?.trim() !== ''
        ) {
          // MS office contains some additional item with text styles
          // When pasting an AI image with CTRL+v, the contentData will have a length of 2.  The first item in the array is the
          // SVG code and the second item is an image file (PNG).  However, the first item might not contain SVG code due to an
          // issue with AI on windows.  For this reason, if the first item does not contain SVG code, do not use the first item but
          // paste the second item as a PNG file.
          // When pasting an AI image with CTRL+SHIFT+v, the contentData will have a length of 1 and should only contain the SVG code.
          // However, due to an issue with AI on Windows, the contentData might not contain any SVG code but only a regular text.
          // In this case, only the regular text will be pasted.
          if (contentData.length === 1 || (contentData.length > 1 && !contentData[1].type?.startsWith('image'))) {
            items = [
              {
                type: 'text',
                content: data,
              },
            ];
            break;
          }
          // Copied document elements
        } else if (contentData.length === 1) {
          items = [
            {
              type: 'text',
              content: data,
            },
          ];
          break;
        } else {
          console.log('Unsupported DataTransferItem string', data);
        }
      } else {
        // DataTransferItem is a single File *.svg
        if (data.type === 'image/svg+xml') {
          items.push({
            type: 'svg-file',
            content: data,
          });
          // DataTransferItem is File (image, AI, PDF)
        } else if (
          data.type.indexOf('image') !== -1 ||
          ['application/postscript', 'application/pdf'].indexOf(data.type) !== -1
        ) {
          items.push({
            type: 'image',
            content: data,
          });
        } else {
          console.log('Unsupported DataTransferItem File', data);
        }
      }
    }
    return items;
  }

  private async getClipboardContent(pasteEvent, shiftKey = false): Promise<ClipboardContent[]> {
    // When await is called (for getAsString and getAsFile for DataTransferItem)
    // we are no longer in the original call stack of the function
    // and we lose pasteEvent.clipboardData.items. To go around this problem -
    // resolve clipboardData.item in Promise.all and then process them.
    const items = [...pasteEvent.clipboardData.items].map((item) => item);
    const promises = [];
    for (const dataTransferItem of items) {
      console.log(dataTransferItem);
      // There are two possible dataTransferItem.kind: string and file
      // https://developer.mozilla.org/en-US/docs/Web/API/DataTransferItem/kind
      if (dataTransferItem.kind === 'string') {
        promises.push(
          new Promise((res) =>
            dataTransferItem.getAsString((data) => {
              res(data);
            }),
          ),
        );
      } else if (dataTransferItem.kind === 'file') {
        promises.push(new Promise((res) => res(dataTransferItem.getAsFile())));
      } else {
        console.log('Unknown DataTransferItem', dataTransferItem);
      }
    }
    const contentData = await Promise.all(promises);

    const pasteItems = this.getRelevantItems(contentData, shiftKey);
    if (!pasteItems || pasteItems.length === 0) {
      return;
    }

    const clipboardContents = [];

    if (pasteItems.length === 1 && pasteItems[0].type === 'json') {
      clipboardContents.push({
        sourceEvent: pasteEvent,
        content: pasteItems[0].content,
        type: 'json',
      });
    } else {
      for (let i = 0; i < pasteItems.length; i++) {
        const item = pasteItems[i];
        const clipboardContent: ClipboardContent = {
          sourceEvent: pasteEvent,
          content: '',
          type: 'text',
        };

        if (item?.type === 'image') {
          // image in clipboard
          clipboardContent.type = 'image';
          clipboardContent.content = item.content;
        } else if (item?.type === 'text') {
          // text in clipboard
          clipboardContent.type = 'text';
          clipboardContent.content = item.content;
        } else if (item?.type === 'svg-file') {
          // const fileAsDataURL = URL.createObjectURL(item.content);
          // const response = await fetch(fileAsDataURL);
          // const svgContent = await response.text();
          clipboardContent.content = item.content; // Content is a File
          clipboardContent.type = 'svg';
        } else if (item?.type === 'svg') {
          // text in clipboard
          clipboardContent.type = 'svg';
          clipboardContent.content = item.content; // Content is a File
        } else if (item?.type === 'derivedImage') {
          // image that is copied from google slides/PPT/Word
          const response = await fetch(item.content);
          const blobFile = await response.blob();
          const file = new File([blobFile], 'fileName', { type: 'image/png' });
          clipboardContent.content = file;
          clipboardContent.type = 'image';
        }
        clipboardContents.push(clipboardContent);
      }
    }

    return Promise.resolve(clipboardContents);
  }

  private addNewElements(elements: DocumentElement[]) {
    this.documentService.deselectAllElements();
    const actions = elements.map((element) => {
      return new DocumentAction(
        {
          elementId: element.id,
          changeType: DocumentChangeType.ADD_ELEMENT,
          elementData: element,
        },
        {
          elementId: element.id,
          changeType: DocumentChangeType.DELETE_ELEMENT,
          elementData: element,
        },
      );
    });

    this.documentService.handleDocumentActions(actions);
  }
}
