import { Component } from '@angular/core';
import { DocumentElement } from '@contrail/documents';
import { ComposerToolbarService } from '../composer-toolbar.service';

@Component({
  selector: 'app-composer-toolbar-arrange-element',
  templateUrl: './composer-toolbar-arrange-element.component.html',
  styleUrls: ['./composer-toolbar-arrange-element.component.scss'],
})
export class ComposerToolbarArrangeElementComponent {
  public element: DocumentElement;
  constructor(private composerToolbarService: ComposerToolbarService) {}

  arrangeElements(actionType: string) {
    this.composerToolbarService.arrangeElements(actionType);
  }

  areMultipleElementsSelected(threshold: number) {
    return this.composerToolbarService.areMultipleElementsSelected(threshold);
  }
}
