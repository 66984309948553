import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
@Component({
  selector: 'app-object-reference-chip',
  templateUrl: './object-reference-chip.component.html',
  styleUrls: ['./object-reference-chip.component.scss'],
})
export class ObjectReferenceChipComponent implements OnChanges {
  @Input() object: any;
  @Input() edit = false;
  @Input() maxDisplayLength = 16;
  @Input() allowDisplayTextShortening = true;
  @Input() clickable = true;
  @Output() clearValue = new EventEmitter();
  @Output() clicked = new EventEmitter();

  public objectName: string;
  public objectNumber: string;
  public hasStatus: boolean;
  public colorStage: string;
  constructor() {}

  async ngOnChanges() {
    // console.log('CHIP: ', this.object );
    this.objectName = this.object.name;
    this.colorStage = null;
    if (this.object?.typeId) {
      if (this.object.itemStatus === 'released') {
        this.colorStage = 'released';
        this.hasStatus = true;
      } else if (this.object.itemStatus === 'development') {
        this.colorStage = 'development';
        this.hasStatus = true;
      } else if (this.object.itemStatus) {
        this.hasStatus = true;
      }
    }

    if (this.object.optionName) {
      this.objectName = this.object.optionName; // HACK FOR ITEMS
    }
    if (this.object.itemNumber) {
      // this.objectNumber = 'ITEM-' + this.object.itemNumber;
    }
  }

  remove() {
    this.clearValue.emit();
  }

  viewDetails() {
    if (this.clickable) {
      this.clicked.emit();
    }
  }
}
