import { Action, createReducer, on } from '@ngrx/store';
import { ItemChooserActions } from '.';
import { itemAdapter, itemInitialState, State } from './item-chooser-store.state';
const setItemData = (state: State, { data }) => {
  return {
    ...state,
    items: itemAdapter.setAll(data, state.items),
  };
};

export const itemChooserReducers = createReducer(
  itemInitialState,
  on(ItemChooserActions.loadItemsSuccess, setItemData),
  on(ItemChooserActions.setItems, setItemData),
);

export function reducer(state: State | undefined, action: Action): any {
  return itemChooserReducers(state, action);
}
