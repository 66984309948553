import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { timeStamp } from 'console';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { Subject } from 'rxjs/internal/Subject';
import { Url } from 'url';

@Injectable({
  providedIn: 'root',
})
export class PDFToSVGTransformationPollingService {
  // total timeout = DEBOUNCE_TIME * TIMEOUT_POLLING_COUNT_LIMIT = 600 seconds
  private TIMEOUT_POLLING_COUNT_LIMIT = 120;
  private DEBOUNCE_TIME = 5000;

  private jobPollingCount = new Map<string, number>();

  private pendingJobs: Array<string> = [];
  private pendingJobsSubject: Subject<string[]> = new BehaviorSubject(null);
  pendingJobsObservable: Observable<string[]> = this.pendingJobsSubject.asObservable();

  private completedJobs: Array<string> = [];
  private completedJobsSubject: Subject<string[]> = new BehaviorSubject(null);
  completedJobsObservable: Observable<string[]> = this.completedJobsSubject.asObservable();

  private ownerId: string;

  private errorSubject: Subject<boolean> = new BehaviorSubject(null);
  errorObservable: Observable<boolean> = this.errorSubject.asObservable();

  private initiatePollingSubject: Subject<string> = new BehaviorSubject(null);
  initiatePollingObservable: Observable<string> = this.initiatePollingSubject.asObservable();

  constructor() {
    this.initiatePollingObservable.pipe(debounceTime(this.DEBOUNCE_TIME)).subscribe((value) => {
      if (value) {
        this.getSVGURLs(value);
      }
    });
  }

  initPolling(jobId: string, ownerId: string) {
    this.ownerId = ownerId;

    this.pendingJobs.push(jobId);
    this.pendingJobsSubject.next(this.pendingJobs);

    this.initiatePollingSubject.next(jobId);

    this.updatePollingCount(jobId);
  }

  async getSVGURLs(jobId) {
    console.log(`getPollingURL - jobId ${JSON.stringify(jobId)}`);

    const object = {
      jobId: jobId,
    };

    const options = {
      entityName: 'presentation',
      id: this.ownerId + '/imports',
      criteria: {
        jobId: jobId,
      },
    };

    console.log(` ===> options ${JSON.stringify(options)}`);

    const job = await new Entities().get(options);
    console.log(` job ${JSON.stringify(job)}`);

    console.log(`1. pending jobs ${this.pendingJobs.length}`);

    if (job?.status === 'completed') {
      console.log(`removing ${job.id} from pending job`);

      this.pendingJobs = this.pendingJobs.filter((data) => data !== jobId);
      this.pendingJobsSubject.next(this.pendingJobs);

      this.completedJobs.push(jobId);
      this.completedJobsSubject.next(this.completedJobs);
    } else if (job?.status === 'error') {
      console.log(`Error - removing ${job.id} from pending job`);
      this.pendingJobs = this.pendingJobs.filter((data) => data !== jobId);
      this.pendingJobsSubject.next(this.pendingJobs);
      this.errorSubject.next(true);
    }

    console.log(`2. pending jobs ${this.pendingJobs.length}`);

    if (this.pendingJobs.length > 0) {
      const nextPendingJob = this.pendingJobs[0];
      try {
        this.updatePollingCount(nextPendingJob);
        this.initiatePollingSubject.next(nextPendingJob);
      } catch (err) {
        console.error(' Error : for jobId ' + nextPendingJob, err);
        console.log(` 2. Error - removing ${job.id} from pending job`);

        this.pendingJobs = this.pendingJobs.filter((data) => data !== nextPendingJob);
        this.pendingJobsSubject.next(this.pendingJobs);

        if (this.pendingJobs?.length) {
          this.initiatePollingSubject.next(this.pendingJobs[0]);
        }
        this.errorSubject.next(true);
      }
    }
  }

  private updatePollingCount(jobId) {
    let count = 0;
    if (this.jobPollingCount.has(jobId)) {
      count = this.jobPollingCount.get(jobId);
    }

    count += 1;
    this.jobPollingCount.set(jobId, count);
    if (count > this.TIMEOUT_POLLING_COUNT_LIMIT) {
      throw Error(
        ` PDF generation  timed out for jobid ${jobId}. Exceeded ${this.TIMEOUT_POLLING_COUNT_LIMIT} polling attempts with debounce duration of ${this.DEBOUNCE_TIME / 1000} seconds`,
      );
    }
  }

  prepForNewJob() {
    console.log(`reset invoked`);

    if (this.pendingJobs.length > 0) {
      this.pendingJobs = [];
      this.pendingJobsSubject.next(this.pendingJobs);
    }

    if (this.completedJobs.length > 0) {
      this.completedJobs = [];
      this.completedJobsSubject.next(this.completedJobs);
    }
  }
}
