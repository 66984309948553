import { Document, PositionDefinition, SizeDefinition } from '@contrail/documents';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { DocumentActions } from 'src/app/presentation/document/document-store';
import { CanvasDocument, CanvasUtil } from 'src/app/presentation/canvas-lib';

const SIDE_PANEL_WIDTH = 340; // How much horizontal space the side panel takes up
export class CanvasSizeSizePositionHandler {
  public lastKnownMousePosition: PositionDefinition = null;
  private startingDragCoord: PositionDefinition;
  private inRightClickPan;
  private rightClickDown;
  private isSpacePressed = false;

  public viewBox: any;
  public displayableSize: SizeDefinition; // HTML Container Size | Static
  public svgSize: SizeDefinition; // = displayableSize
  public slideCanvasSize: SizeDefinition;
  public zoomFactor = 1;
  private sidePanelOpen = false;
  public panOffset = {
    x: 0,
    y: 0,
  };
  private ASPECT_RATIO;

  constructor(
    private svgDocument: CanvasDocument,
    private containerElement: any,
    private store: Store<RootStoreState.State>,
    private document: Document,
  ) {
    this.viewBox = {
      // frame document default size 1200, 675
      width: 1200,
      height: 675,
      x: -40,
      y: -40,
    };
    this.svgSize = {
      width: 1200 + 40,
      height: 675 + 40,
    };
    this.slideCanvasSize = {
      width: this.document.size.width,
      height: this.document.size.height,
    };
    this.displayableSize = {
      width: 0,
      height: 0,
    };
    this.ASPECT_RATIO = this.document.size.height / this.document.size.width;
  }

  public setSidePanelOpen(isOpen: boolean) {
    if (isOpen == this.sidePanelOpen) {
      return;
    }
    this.sidePanelOpen = isOpen;
    this.setSizeAndPosition();
  }
  public setSizeAndPosition() {
    const CANVAS_SLIDE_PADDING = 40 * this.zoomFactor; // Minimum amount of padding we want between the 'slide' and surroundings

    // Size of the parent container
    const containerElement = this.containerElement.nativeElement.parentElement.parentElement;
    const containerWidth = containerElement.offsetWidth;
    const containerHeight = containerElement.offsetHeight;

    // How much visible space we have (adjusted for side panel)
    this.displayableSize = {
      width: containerElement.offsetWidth,
      height: containerElement.offsetHeight,
    };
    if (this.sidePanelOpen) {
      this.displayableSize.width = this.displayableSize.width - SIDE_PANEL_WIDTH; // subtract the space that the side panel takes
    }

    let targetCanvasSlideWidth = this.displayableSize.width - CANVAS_SLIDE_PADDING * 2;
    let targetCanvasSlideDims = {
      width: targetCanvasSlideWidth,
      height: targetCanvasSlideWidth * this.ASPECT_RATIO,
    };

    // Check if our height is too large compared to minimum padding
    if (targetCanvasSlideDims.height > this.displayableSize.height - CANVAS_SLIDE_PADDING * 2) {
      targetCanvasSlideDims = {
        width: (containerHeight - CANVAS_SLIDE_PADDING * 2) / this.ASPECT_RATIO,
        height: containerHeight - CANVAS_SLIDE_PADDING * 2,
      };
    }

    // Adjust the size of the drawn 'slide', which should always
    // map to the document size, scaled to the screen
    const slideCanvas = document.getElementById('slideCanvas');
    this.slideCanvasSize = {
      height: targetCanvasSlideDims.height,
      width: targetCanvasSlideDims.width,
    };
    slideCanvas.style.width = '' + this.slideCanvasSize.width + 'px';
    slideCanvas.style.height = '' + this.slideCanvasSize.height + 'px';

    // Position the slide canvas in the center of the displayable area.
    const slideCanvasPosition = {
      x: (this.displayableSize.width - this.slideCanvasSize.width) / 2 + this.panOffset.x,
      y: (this.displayableSize.height - this.slideCanvasSize.height) / 2 + this.panOffset.y,
    };
    slideCanvas.style.top = '' + slideCanvasPosition.y + 'px';
    slideCanvas.style.left = '' + slideCanvasPosition.x + 'px';

    // Adjust the size the SVG Canvas, which should always fill the viewable
    // screen space.
    this.svgSize = { width: this.displayableSize.width, height: this.displayableSize.height };

    // The ratio between the displayed document size
    // and the amount of pixel space on the screen.  This is needed
    // to derive the correct viewBox size.
    const viewBoxRatio = this.document.size.width / this.slideCanvasSize.width; // viewBoxRatio = 1 / viewScale

    // Adjust the view box, which should provide the corect level
    // of zoom & offset, based on the scaling on the screen.
    this.viewBox = {
      x: -(slideCanvasPosition.x * viewBoxRatio),
      y: -(slideCanvasPosition.y * viewBoxRatio),
      width: this.displayableSize.width * viewBoxRatio, // The view box height/width is based on displayable area
      height: this.displayableSize.height * viewBoxRatio,
    };

    this.setViewPort();
  }

  setViewPort() {
    this.svgDocument.syncState(
      this.document.size,
      { x: 0, y: 0, width: this.document.size.width, height: this.document.size.height },
      this.displayableSize,
      this.viewBox,
    );
    this.store.dispatch(
      DocumentActions.setViewSize({
        viewBox: { ...this.viewBox },
        viewScale: {
          x: this.displayableSize.width / this.viewBox.width,
          y: this.displayableSize.height / this.viewBox.height,
        },
        boundingClientRect: this.svgDocument.getBoundingClientRect(),
      }),
    );
  }

  public setZoomPresets(viewScale) {
    // 0.5: 50% | 2: 200%

    // const viewBoxRatio = 1 / viewScale;
    this.slideCanvasSize = {
      width: this.document.size.width * viewScale,
      height: this.document.size.height * viewScale,
    };

    const slideCanvas = document.getElementById('slideCanvas');
    this.slideCanvasSize = {
      width: this.document.size.width * viewScale,
      height: this.document.size.height * viewScale,
    };
    slideCanvas.style.width = '' + this.slideCanvasSize.width + 'px';
    slideCanvas.style.height = '' + this.slideCanvasSize.height + 'px';

    const slideCanvasPosition = {
      x: (this.displayableSize.width - this.slideCanvasSize.width) / 2 + this.panOffset.x,
      y: (this.displayableSize.height - this.slideCanvasSize.height) / 2 + this.panOffset.y,
    };
    slideCanvas.style.top = '' + slideCanvasPosition.y + 'px';
    slideCanvas.style.left = '' + slideCanvasPosition.x + 'px';

    let targetCanvasSlideDims = { width: this.slideCanvasSize.width, height: this.slideCanvasSize.height };

    if (Math.abs(targetCanvasSlideDims.height / targetCanvasSlideDims.width - this.ASPECT_RATIO) < 0.1) {
      this.zoomFactor = (this.displayableSize.width - targetCanvasSlideDims.width) / 80;
    } else {
      const containerHeight = this.containerElement.nativeElement.parentElement.parentElement?.offsetHeight;
      this.zoomFactor = (containerHeight - targetCanvasSlideDims.height) / 80;
    }

    this.viewBox = {
      width: this.displayableSize.width / viewScale,
      height: this.svgSize.height / viewScale,
      x: -(slideCanvasPosition.x / viewScale),
      y: -(slideCanvasPosition.y / viewScale),
    };

    this.setViewPort();
  }

  pan(distanceX, distanceY) {
    if (isNaN(distanceX) || isNaN(distanceY)) {
      return;
    }
    const MAX_X_PAN = this.document.size.width * 0.9;
    const newX = this.panOffset.x + distanceX;
    if (newX < MAX_X_PAN && newX > -MAX_X_PAN) {
      this.panOffset.x = newX;
    }
    const MAX_Y_PAN = this.document.size.height * 0.9;
    const newY = this.panOffset.y + distanceY;
    if (newY < MAX_Y_PAN && newY > -MAX_Y_PAN) {
      this.panOffset.y = newY;
    }
    this.setSizeAndPosition();
  }

  public zoomIn() {
    if (this.slideCanvasSize.height > 2025) {
      // 300%
      return;
    }

    this.zoomFactor += -0.75;
    this.setSizeAndPosition();
  }

  public zoomOut() {
    if (this.slideCanvasSize.height < 135) {
      // 20%
      return;
    }

    this.zoomFactor += 0.75;
    this.setSizeAndPosition();
  }

  handleMouseWheelEvent(event) {
    if (event.altKey || event.ctrlKey) {
      event.preventDefault();
      if (event.deltaY < 0) {
        this.zoomIn();
      } else if (event.deltaY > 0) {
        this.zoomOut();
      }
    } else {
      let x = -event.deltaX * 0.5;
      let y = -event.deltaY * 0.5;
      if (event.shiftKey) {
        x = y;
        y = 0;
      }
      this.pan(x, y);
    }
  }

  center() {
    this.panOffset = {
      x: 0,
      y: 0,
    };
    this.zoomFactor = 1;
    this.setSizeAndPosition();
  }

  public handleMouseMove(event) {
    if (event.which !== 3 && !this.isSpacePressed && this.rightClickDown) {
      // Right button is off (event.which is not 3) but rightClickDown is still true - means we missed mouse up event, so cancel right click panning
      this.handleMouseUp(event);
    }
    if (this.rightClickDown) {
      if (this.svgDocument.getInteractionMode() !== 'grabbing') {
        this.svgDocument.interactionHandler.setGrabbingMode(true);
      }
      this.inRightClickPan = true;
      const distanceMoved = {
        x: (event.x - this.startingDragCoord.x) * this.zoomFactor,
        y: (event.y - this.startingDragCoord.y) * this.zoomFactor,
      };
      this.startingDragCoord = { x: event.x, y: event.y };
      this.pan(distanceMoved.x / this.zoomFactor, distanceMoved.y / this.zoomFactor);
    }
  }

  public handleMouseUp(event) {
    this.svgDocument.interactionHandler.setGrabbingMode(false);
    this.stopGrabbingMode(event);
  }

  public setLastKnownMousePosition(position: PositionDefinition) {
    this.lastKnownMousePosition = position;
    this.store.dispatch(
      DocumentActions.setLastKnownMousePosition({ lastKnownMousePosition: this.lastKnownMousePosition }),
    );
  }

  public stopGrabbingMode(event) {
    if (this.rightClickDown && this.inRightClickPan) {
      event.preventDefault();
      // event.stopPropagation(); - do not want to stop propagation so this event is available in the property-configurator-bar
    }
    this.rightClickDown = false;
    this.inRightClickPan = false;
  }
  public startGrabbingMode(event) {
    this.rightClickDown = true;
    this.startingDragCoord = { x: event.x, y: event.y };
  }

  public handleMouseDown(event: MouseEvent) {
    if (event.button === 2 || this.isSpacePressed) {
      this.startGrabbingMode(event);
      event.preventDefault();
    }
  }

  /**
   * Grab mode starts when uset presses space but doesn't pan yet
   * @param event
   */
  public startGrabMode(event) {
    this.svgDocument.interactionHandler.setGrabbingMode(true, true);
    this.isSpacePressed = true;
  }

  /**
   * Grab mode stops when use releases space key
   * @param event
   */
  public stopGrabMode(event) {
    setTimeout(() => this.svgDocument.interactionHandler.setGrabbingMode(false, false), 5);
    this.isSpacePressed = false;
  }

  getCanvasCenterPosition() {
    return {
      x: this.svgSize.width / 2,
      y: this.svgSize.height / 2,
    };
  }

  computeDocumentPositionForCanvasCenter() {
    return this.computeDocumentPositionForCoordinates(this.getCanvasCenterPosition());
  }

  computeDocumentPositionForMouseEvent(event: MouseEvent) {
    return this.computeDocumentPositionForCoordinates({ x: event.x, y: event.y });
  }

  computeDocumentPositionForCoordinates(pos: PositionDefinition) {
    return CanvasUtil.toDocumentPosition(
      pos.x,
      pos.y,
      this.svgDocument.getViewBox(),
      this.svgDocument.getViewScale(),
      this.svgDocument.getBoundingClientRect(),
    );
  }
}
