import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ColorUtil } from '../../util/color-util';

export interface AvatarUser {
  email?: string;
  firstName?: string;
  lastName?: string;
  id?: string;
  profilePhoto?: string;
  clientId?: string;
}
@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit, OnChanges {
  @Input() user: AvatarUser;
  @Input() size = '32px';
  displayName: string;
  initials: string;
  id: string;
  public backgroundColor;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.user) {
      this.id = this.user.clientId || this.user.email + this.user.firstName + this.user.lastName;
      this.displayName = this.getDisplayName();
      this.initials = this.getInitial();
      const color = this.id || this.initials;
      this.backgroundColor = ColorUtil.stringToHslaColor(color);
    }
  }

  getDisplayName() {
    if (this.user) {
      if (this.user.firstName) {
        return this.user.firstName + ' ' + this.user.lastName;
      } else if (this.user.email) {
        return this.user.email;
      }
    }
  }

  getInitial() {
    if (this.user) {
      if (this.user.firstName) {
        return this.user.firstName.slice(0, 1);
      } else if (this.user.email) {
        return this.user.email.slice(0, 1);
      }
    }
  }
}
