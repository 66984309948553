import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import {
  DocumentAction,
  DocumentChangeType,
  DocumentElementEvent,
  PositionDefinition,
  SizeDefinition,
} from '@contrail/documents';
import { ObjectUtil } from '@contrail/util';
import { Subscription, Subject, fromEvent, timer, merge } from 'rxjs';
import { DocumentElement } from '@contrail/documents';
import { PropertyConfiguratorService } from '../property-configurator.service';
import { PROPERTY_MAP } from '../property-configurator-properties';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { ComponentEditorService } from '../../component-editor/component-editor.service';
import { DocumentService } from '../../document.service';
import { debounceTime, startWith, switchMap, map, switchMapTo, filter, takeUntil, tap } from 'rxjs/operators';
import { EntityReference } from '@contrail/sdk';
import { DocumentSelectors } from '../../document-store';
import { DocumentComponentService } from '../../document-component/document-component-service';
import { ActionRequest } from '@contrail/actions';
import { CanvasUtil } from 'src/app/presentation/canvas-lib';
import { DocumentViewSize } from '../../document-store/document.state';
import { AssortmentsSelectors } from '@common/assortments/assortments-store';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { Feature } from '@common/feature-flags/feature-flag';

@Component({
  selector: 'app-property-configurator-bar',
  templateUrl: './property-configurator-bar.component.html',
  styleUrls: ['./property-configurator-bar.component.scss'],
})
export class PropertyConfiguratorBarComponent implements OnInit, OnDestroy {
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private service: PropertyConfiguratorService,
    private documentComponentService: DocumentComponentService,
    private componentEditorService: ComponentEditorService,
    private documentService: DocumentService,
    private store: Store<RootStoreState.State>,
  ) {}

  public sourceEvent: DocumentElementEvent;
  public elements: Array<DocumentElement>;
  public selectedElement: DocumentElement; // Size Information Only
  public elementType: string;
  public selectedGroupElements: DocumentElement[];
  public selectedGroupElementsMatchSizeEligible: DocumentElement[];
  public selectedElements: DocumentElement[];
  public isLocked = false;
  properties: string[] = []; // PROPERTY_MAP.text.properties;
  @ViewChild('widgetTray') widgetElement: ElementRef;
  private eventSub: Subscription;
  private textElementSub: Subscription;
  private subject: Subject<string> = new Subject();
  private mouseWheelEventSub: Subscription;
  private mouseDragEventSub: Subscription = new Subscription();
  private toggleOverlaySub: Subscription = new Subscription();
  private toggleOverlay;
  private subscriptions: Subscription = new Subscription();

  private viewSize: DocumentViewSize;
  private editingInProgress = false;

  public contextualEntity;
  public contextualEntityReference: EntityReference;
  public visible = false;

  textFormat;
  textElement;

  private readonly BAR_HEIGHT = 70;
  private readonly PADDING = 10;

  public preventShowing = false;
  public hasSvgRecolorFeatureFlag = false;

  ngOnInit(): void {
    this.subscribe();

    this.subject.pipe(debounceTime(500)).subscribe((values) => this.updateValues(values));

    this.toggleOverlaySub = this.store.select(DocumentSelectors.toggleChooser).subscribe((toggleOverlay) => {
      this.toggleOverlay = toggleOverlay;
      if (toggleOverlay?.slug === 'componentEditor') {
        this.hide();
      }
    });
  }
  ngOnDestroy() {
    this.unsubscribe();
  }

  subscribe() {
    this.subscriptions.add(
      this.store.select(DocumentSelectors.viewSize).subscribe((viewSize) => {
        this.viewSize = viewSize;
      }),
    );

    this.subscriptions.add(
      this.documentService.documentActions.subscribe((actions: DocumentAction[]) => {
        if (this.elements?.length === 1 && actions?.length) {
          let element = this.elements[0];
          let modifiedElement = null;
          for (const action of actions) {
            const { changeDefinition } = action;
            if (
              changeDefinition.elementId === element.id &&
              [DocumentChangeType.MODIFY_ELEMENT, DocumentChangeType.REBIND_MODEL].includes(changeDefinition.changeType)
            ) {
              modifiedElement = changeDefinition.elementData;
              break;
            }
          }
          if (element && modifiedElement) {
            this.deriveSelectedEntity(element);
            this.elements[0] = ObjectUtil.cloneDeep(
              ObjectUtil.mergeDeep(ObjectUtil.cloneDeep(element), modifiedElement),
            );
            element = this.elements[0];
            this.elementType = element.type;
            const elementType = element.type === 'text' && element.isTextTool ? 'text_tool' : element.type;
            this.properties = PROPERTY_MAP[elementType].properties;
          }
        }
      }),
    );

    this.subscriptions.add(
      this.store.select(AssortmentsSelectors.backingAssortmentItemData).subscribe((backingAssortmentItemData) => {
        if (this.contextualEntityReference && this.contextualEntityReference.entityType === 'item') {
          const assortmentItem = backingAssortmentItemData.find((ai) => ai.id === this.contextualEntityReference.id);
          if (assortmentItem) {
            this.show(this.sourceEvent);
          }
        }
      }),
    );

    this.subscriptions.add(
      this.store.select(FeatureFlagsSelectors.featureFlags).subscribe((flags) => {
        this.hasSvgRecolorFeatureFlag = !!flags.find((x) => x.featureName === Feature.SVG_RECOLORING);
      }),
    );

    this.eventSub = this.service.documentElementEvents.subscribe((event) => {
      // console.log('prop-config-bar: ', event);
      if (!event) {
        return;
      }
      this.selectedGroupElements = this.documentService.getSelectedGroupElements();
      this.selectedGroupElementsMatchSizeEligible = this.selectedGroupElements?.filter(
        (e) => ['image', 'svg'].indexOf(e.type) !== -1,
      );
      this.selectedElements = this.documentService.getSelectedElements();
      const selectedElements = event?.selectedElements || this.documentService.getSelectedElements() || [];
      let selectedElement = event.element;
      if (selectedElement && selectedElements.findIndex((e) => e.id === selectedElement.id) === -1) {
        selectedElement = null;
      }
      const element = selectedElement || this.getSameTypeElement(selectedElements);

      if (element && PROPERTY_MAP[element.type]) {
        this.sourceEvent = event;
        this.preventShowing =
          this.documentService.elementTypesWithContextMenu.length === 0 ||
          this.documentService.elementTypesWithContextMenu.indexOf(element.type) > -1
            ? false
            : true;

        if (event.eventType === 'selected' && element.type !== 'hot_spot') {
          // hotspot does not need the property configurator
          this.selectedElement = element;
          this.elements = event.selectedElements;
          this.isLocked = this.elements?.findIndex((e) => e.isLocked) !== -1;
          this.elementType = element.type;
          this.properties = PROPERTY_MAP[element.type].properties;

          // Setup contextual entity information
          this.deriveSelectedEntity(element);

          if (
            this.documentService.elementTypesWithContextMenu.length === 0 ||
            this.documentService.elementTypesWithContextMenu.indexOf(element.type) > -1
          ) {
            this.show(event, element);
          } else {
            this.hide();
          }

          this.subscribeToMouseEvents();
        } else if (event.eventType === 'dragStarted') {
          this.hide();
        } else if (event.eventType === 'dragEnded') {
          if (
            this.documentService.elementTypesWithContextMenu.length === 0 ||
            this.documentService.elementTypesWithContextMenu.indexOf(element.type) > -1
          ) {
            this.show(event, element);
          }
        } else if (event.eventType === 'cropStarted') {
          this.preventShowing = true;
          this.hide();
        } else if (event.eventType === 'cropEnded') {
          this.preventShowing = false;
          this.show(event, element);
        }
      } else if (event.eventType === 'deselect') {
        this.unsubscribeFromMouseEvents();
        this.hide();
      } else if (element && !PROPERTY_MAP[element.type]) {
        this.hide();
      } else if (!element && event?.eventType === 'selected') {
        this.hide();
      } else if (event.eventType === 'cropEnded') {
        this.preventShowing = false;
      }
    });

    this.textElementSub = this.documentService.documentTextElementEvents.subscribe((event) => {
      this.textElement = event.element;
      this.textFormat = event.textFormat;
    });
  }

  private getSameTypeElement(selectedElements) {
    if (!selectedElements || selectedElements?.length === 0) {
      return null;
    }
    return selectedElements[0];
    // if (selectedElements?.length === 1) { return selectedElements[0]; }
    // let element = selectedElements[0];
    // for(let i = 1; i < selectedElements.length; i++) {
    //   if (element.type !== selectedElements[i].type) {
    //     element = null;
    //     break;
    //   } else {
    //     element = selectedElements[i];
    //   }
    // }
    // return element;
  }

  private deriveSelectedEntity(element: DocumentElement) {
    const keys = ['item', 'color', 'content', 'image', 'asset']; // asset and image have `content` binding : do we really use `image` binding?
    const foundBinding = keys.find((key) => element?.modelBindings?.[key]);

    this.contextualEntityReference = foundBinding ? new EntityReference(element.modelBindings[foundBinding]) : null;
  }

  unsubscribe() {
    this.eventSub.unsubscribe();
    this.textElementSub.unsubscribe();
    this.unsubscribeFromMouseEvents();
    this.toggleOverlaySub.unsubscribe();
  }

  subscribeToMouseEvents() {
    if (this.mouseWheelEventSub && this.mouseWheelEventSub.closed == false) {
      return;
    }

    // Subscribe to right click screen dragging
    // If dragging with right click hide the toolbar.
    // On mouse up show the toolbar.
    const mouseWheel$ = fromEvent<MouseEvent>(document.getElementById('mainCanvas'), 'wheel');
    const mouseDown$ = fromEvent<MouseEvent>(document.getElementById('mainCanvas'), 'mousedown');
    const mouseMove$ = fromEvent<MouseEvent>(document.getElementById('mainCanvas'), 'mousemove');
    const mouseUp$ = fromEvent<MouseEvent>(document.getElementById('mainCanvas'), 'mouseup');
    const keydown$ = fromEvent<MouseEvent>(document.getElementById('mainCanvas'), 'keydown');
    this.mouseDragEventSub = merge(
      mouseDown$.pipe(filter((event) => event['button'] === 2)),
      keydown$.pipe(filter((event) => event['code'] === 'Space')),
    )
      .pipe(
        switchMapTo(
          mouseMove$.pipe(
            tap((event) => {
              if (this.widgetElement?.nativeElement?.classList.contains('visible')) {
                this.hide();
              }
            }),
            takeUntil(
              mouseUp$.pipe(
                tap((event) => {
                  if (this.sourceEvent && !this.widgetElement?.nativeElement?.classList.contains('visible')) {
                    this.show(this.sourceEvent);
                  }
                }),
              ),
            ),
          ),
        ),
      )
      .subscribe();

    // Subscribe to mouse wheel events.
    // On mouse wheel hide the toolbar. If mouse wheel wasn't triggered
    // after interval$, show the toolbar.
    const interval$ = timer(300);
    this.mouseWheelEventSub = mouseWheel$
      .pipe(
        map((event) => {
          // const zoom = event.altKey || event.ctrlKey;
          if (this.widgetElement?.nativeElement?.classList.contains('visible')) {
            this.hide();
          }
          return event;
        }),
        switchMap((event: MouseEvent) => interval$.pipe(startWith(300))),
        debounceTime(300),
      )
      .subscribe(() => {
        if (this.sourceEvent && !this.widgetElement?.nativeElement?.classList.contains('visible')) {
          this.show(this.sourceEvent);
        }
      });
  }

  unsubscribeFromMouseEvents() {
    this.mouseDragEventSub?.unsubscribe();
    this.mouseWheelEventSub?.unsubscribe();
  }

  public toWindowPosition(position: PositionDefinition): PositionDefinition {
    return CanvasUtil.toWindowPosition(
      position?.x,
      position?.y,
      this.viewSize.viewBox,
      this.viewSize.viewScale,
      this.viewSize.boundingClientRect,
    );
  }

  public toWindowSize(size: SizeDefinition): SizeDefinition {
    return CanvasUtil.toWindowSize(size?.width, size?.height, this.viewSize.viewScale);
  }

  public getComponentSize(element): SizeDefinition {
    return {
      width: element.elements[0].size.width, // + CANVAS_COMPONENT_PADDING_X * 2,
      height: element.elements.reduce((height, element) => height + element.size.height, 0),
    };
  }

  public toWindowDimensions(element: DocumentElement) {
    let p = element.position;
    let s = element.size;
    if (element.type === 'line') {
      const lineDefinition = element.lineDefinition;
      s = {
        width: Math.abs(lineDefinition.x1 - lineDefinition.x2),
        height: Math.abs(lineDefinition.y1 - lineDefinition.y2),
      };
      p = {
        x: Math.min(element.lineDefinition.x1, element.lineDefinition.x2),
        y: Math.min(element.lineDefinition.y1, element.lineDefinition.y2),
      };
    }
    const position = this.toWindowPosition(p);
    const size = this.toWindowSize(element.type === 'component' ? this.getComponentSize(element) : s);
    return { size, position };
  }

  reset: Date;
  show(event: DocumentElementEvent, element = null) {
    if (!this.widgetElement || this.preventShowing) {
      return;
    }

    this.visible = true;
    this.reset = new Date();

    this.sourceEvent = event;
    if (element) {
      this.sourceEvent.element = element;
    }

    const canvasElement = this.documentService.documentRenderer.getCanvasElementById(this.sourceEvent?.element?.id);
    if (!canvasElement) {
      return;
    }
    if (canvasElement.isImageError) {
      return;
    }

    if (this.sourceEvent.element && this.viewSize) {
      if (this.sourceEvent.element.type === 'group') {
        this.sourceEvent.element = this.getGroupElement(element);
      }
      const { size, position } = this.toWindowDimensions(this.sourceEvent.element);
      let top = position.y - this.BAR_HEIGHT;
      if (top < this.PADDING) {
        top = position.y + size.height + this.PADDING;
      }
      const verticalStyle = `top:${top}px`;
      let horizontalStyle;
      if (window.innerWidth - position.x > (this.sourceEvent.element.type === 'text' ? 950 : 400)) {
        horizontalStyle = `left:${position.x}px;`;
      } else {
        horizontalStyle = `right:${Math.max(this.PADDING, window.innerWidth - position.x - size.width)}px;`;
      }

      this.widgetElement.nativeElement.setAttribute('style', `${horizontalStyle} ${verticalStyle}`);
      this.widgetElement?.nativeElement?.classList.add('visible');
    }
  }

  hide() {
    this.visible = false;
    this.widgetElement?.nativeElement?.classList?.remove('visible');
  }

  handleDelayedValueChange(values) {
    this.subject.next(values);
  }

  async updateValues(values) {
    if (values?.style?.backgroundColor && this.elements.findIndex((e) => e.type === 'svg') !== -1) {
      await this.documentComponentService.recolorAndUpdateSVGElements(
        this.elements.filter((e) => e.type === 'svg'),
        values?.style?.backgroundColor,
      );
    }
    const undoChanges = [];
    const changes = (
      values?.style?.backgroundColor ? this.elements.filter((e) => e.type !== 'svg') : this.elements
    ).map((el) => {
      const existingValues = { style: {} };
      Object.keys(values).forEach((key) => {
        if (key === 'style') {
          // nullify style properties that do not exist in the element for undo
          Object.keys(values.style).forEach((styleProp) => {
            existingValues.style[styleProp] =
              el.style && el.style[styleProp] ? ObjectUtil.cloneDeep(el.style[styleProp]) : null;
          });
        } else {
          existingValues[key] = el[key] ? ObjectUtil.cloneDeep(el[key]) : {};
        }
        if (key === 'isLocked') {
          this.isLocked = values[key];
        }
      });
      undoChanges.push(Object.assign({ id: el.id }, existingValues));

      // Modify the element itself to trigger OnChanges in inner components.
      // This is needed in cases where two elements have initial values as NULL - for example opacity.
      // If one element's opacity is changed to 70, the current element opacity value still stays NULL
      // in the component, and when user switches to another element, the opacity value in the config
      // bar shows 70, because OnChanges wasn't triggered since currentValue stayed NULL
      el = ObjectUtil.mergeDeep(el, values);

      return Object.assign({ id: el.id }, values);
    });
    this.service.handleElementChanges(changes, undoChanges);
  }

  getCurrentValue(index) {
    if (this.elements?.length) {
      return ObjectUtil.getByPath(this.elements[0], index);
    }
  }
  handleClick(event) {
    // event.stopPropagation();
  }

  showComponentConfigurator() {
    this.componentEditorService.showComponentConfigurator();
  }

  getTextAttributeValue(att) {
    if (this.textFormat) {
      return this.textFormat[att];
    }
    return null;
  }

  updateTextElement(values) {
    const action = {
      element: this.textElement,
      textFormat: values,
    };
    this.documentService.handleDocumentTextElementActions(action);
  }

  clearFormat(event) {
    const action = {
      element: this.textElement,
      textFormat: {
        type: 'clearFormat',
      },
    };
    this.documentService.handleDocumentTextElementActions(action);
  }

  openContextMenu(event) {
    this.documentService.handleDocumentElementEvent({
      element: this.elements[0],
      selectedElements: this.documentService.getSelectedElements(),
      eventType: 'contextmenu',
      sourceMouseEvent: event,
    });
  }

  cropImage(element) {
    this.documentService.startCrop(element);
  }

  emitActionRequest(actionType: string) {
    const sourceEvent = {
      ...this.sourceEvent,
      selectedElements: this.documentService.getSelectedElements(),
    };
    this.documentService.handleActionRequest(new ActionRequest(actionType, sourceEvent));
  }

  isLockingActionAllowed() {
    if (this.selectedGroupElements.length === 1) {
      const groupElement = this.documentService.getGroupByMemberId(this.selectedGroupElements[0].id);
      if (groupElement) {
        return false;
      }
    }
    return true;
  }

  private getGroupElement(element: DocumentElement): any {
    const groupElement = this.documentService.documentRenderer.getCanvasElementById(element.id);
    const dimensions = groupElement.getDimensions();
    const { x, y, width, height } = dimensions;
    return { id: element.id, size: { width, height }, position: { x, y } };
  }

  public setEditingInProgress(event) {
    this.editingInProgress = event;
  }
}
