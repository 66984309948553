import { Document } from '@contrail/documents';
import { Types, Entities, EntityReference } from '@contrail/sdk';
import { ObjectUtil } from '@contrail/util';
import { PropertyValueFormatter, Type } from '@contrail/types';

export class DocumentDataBinder {
  private typeMap = {};
  public async rebindDocuments(documents: Array<Document>, entityTypes = null) {
    try {
      const entityReferences = [];
      for (const document of documents) {
        const refs = await this.getReferencesFromElements(document.elements || [], entityTypes);
        entityReferences.push(...refs);
        const backgroundReferences = await this.getReferencesFromElements(document.background || [], ['file']);
        if (backgroundReferences && backgroundReferences.length) {
          entityReferences.push(...backgroundReferences);
        }
      }

      // map of entities
      // {"file:123": {fileObject123}, "item:345": {itemObject345}}
      const entityMap = await this.expandEntityReferences(entityReferences);
      for (const document of documents) {
        await this.rebind(document, entityMap);
      }
    } catch (e) {
      console.error('ERROR: ', e);
      throw e;
    }
  }

  /** Given a model binding definition, builds the model based
   * on a map of entities.
   */
  public async buildModel(modelBindings: any, entityMap: Map<string, any>) {
    const model = {};
    const formatter = new PropertyValueFormatter();
    for (const key of Object.keys(modelBindings)) {
      const entity = ObjectUtil.cloneDeep(entityMap.get(modelBindings[key]));
      if (!entity) {
        continue;
      }
      if (entity?.typeId) {
        const type: Type = this.typeMap[entity.typeId] || (await new Types().getType({ id: entity.typeId }));
        this.typeMap[entity.typeId] = type;
        for (const p of type.typeProperties) {
          if (entity[p.slug]) {
            entity[p.slug] = formatter.getDisplayValue(entity, p);
          }
        }
      }
      if (entity) {
        model[key] = entity;
      }
    }
    return model;
  }

  /** Given a document, rebinds data based on a pre-fetched set of entities */
  private async rebind(document: Document, entityMap: Map<string, any>) {
    if (!document) {
      return;
    }
    // TOP LEVEL ELEMENTS ONLY CURRENTLY
    let elements = document?.elements || [];
    if (document.background?.length) {
      elements = elements.concat(document.background);
    }
    for (const el of elements) {
      const modelBindings = el?.modelBindings;
      if (!modelBindings) {
        continue;
      }

      // CREATE A MODEL, BASED ON ENTITY MAP
      const model = await this.buildModel(modelBindings, entityMap);
      if (Object.keys(model).length < 1) {
        continue;
      }

      // BIND PROPERTIES FROM MODEL
      this.bindPropertiesToElement(el, model);
    }
  }

  public bindPropertiesToElement(element: any, model: any) {
    const localModel = Object.assign({}, model);
    if (element.propertyBindings) {
      for (const propertyKey of Object.keys(element.propertyBindings)) {
        const modelIndex = element.propertyBindings[propertyKey];

        const propertyValue = ObjectUtil.getByPath(localModel, modelIndex) || '';
        if (propertyValue) {
          element[propertyKey] = propertyValue;
        }
      }
    }
    // // Walk children
    if (element.elements) {
      element.elements.forEach((child) => {
        this.bindPropertiesToElement(child, localModel);
      });
    }
  }

  /**
   * Extracts just the file references from the element set.
   * Could / should be updated to extract all references, or references based on
   * a passed in list of entityTypes.
   */
  public getReferencesFromElements(elements, entityTypes = null) {
    if (!elements) {
      return;
    }
    const references = elements.reduce((refs, el) => {
      if (!el?.modelBindings) {
        return refs;
      }
      for (const binding of Object.values(el.modelBindings)) {
        let entityRef: EntityReference;
        try {
          entityRef = new EntityReference(binding?.toString());
          if (!entityTypes || entityTypes.includes(entityRef.entityType)) {
            refs.push(new EntityReference('' + binding));
          }
        } catch (e) {
          console.error('Error extracting bound reference: ', entityRef?.reference, e);
          console.trace();
        }
      }
      return refs;
    }, []);
    return references || [];
  }

  /**
   * Take collection of entity references and fetches them from the API
   * returning a map keyed by reference
   */
  public async expandEntityReferences(references: Array<EntityReference>): Promise<Map<string, any>> {
    // MAPS ALL ENTITY REFERENCES BASED ON TYPE.
    const entityTypeMap = this.getEntityTypeMap(references);

    // FETCH BASED ON ENTITY TYPE
    const typeBasedResults: Map<string, Array<any>> = new Map();
    for (const entityType of entityTypeMap.keys()) {
      const results = await this.getEntitysFromReferences(entityType, entityTypeMap.get(entityType));
      typeBasedResults.set(entityType, results);
    }
    // FLATTEN INTO A SINGLE MAP BASED ON ENTITYREFERENCE
    return this.createEntityMapFromTypeBasedResults(typeBasedResults);
  }

  /** Takes a collection of entity references and groups them by entityType.
   * Returning a map keyed by entityType with value equal to a list of ids.
   */
  public getEntityTypeMap(references: Array<EntityReference>) {
    return references.reduce((map: Map<string, any>, ref: EntityReference) => {
      const set: Set<any> = map.get(ref.entityType) || new Set();
      set.add(ref.id);
      map.set(ref.entityType, set);
      return map;
    }, new Map());
  }

  /** This needs to use batching.. */
  private async getEntitysFromReferences(entityType: string, ids: Array<string>) {
    return new Entities().get({ entityName: entityType, criteria: { ids: [...ids] } });
  }

  /** Takes a map of entityType:Array<entities> and creates a flat map with
   * keys equal to references and value equal the object
   */
  public createEntityMapFromTypeBasedResults(typeBasedResults: Map<string, Array<any>>): Map<string, any> {
    const entityMap: Map<string, any> = new Map();
    for (const entityType of typeBasedResults.keys()) {
      const entities = typeBasedResults.get(entityType);
      entities?.forEach((entity) => {
        const ref = entityType + ':' + entity.id;
        entityMap.set(ref, entity);
      });
    }
    return entityMap;
  }
}
