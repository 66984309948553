<div class="w-full h-full flex flex-col" data-test="lineboard-editor">
  <div class="header !mb-0">
    <div class="title">Lineboard options</div>
    <mat-icon (click)="hide()">close</mat-icon>
  </div>
  <div *ngIf="loading" class="flex items-center justify-center h-full">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
  <div *ngIf="documentGenerationConfig && assortment && !isEditable">
    <div class="source-wrapper items-center mb-2 p-3">
      <img class="source-icon opacity-50" src="assets/images/clipboard.svg" />
      <span> Clipboard </span>
      <div class="cursor-pointer text-accent text-xs ml-auto" (click)="toggleAssortmentSelector()">Change</div>
    </div>
    <div class="mx-3">
      <span class="material-icons text-warn-light text-xs scale-150 mr-2">warning</span>
      <span class="text-xs">This lineboard cannot be edited because it was created from another user's clipboard</span>
    </div>
  </div>

  <div class="overflow-x-hidden grow" *ngIf="!loading && documentGenerationConfig && isEditable">
    <mat-expansion-panel class="mat-elevation-z0" expanded>
      <mat-expansion-panel-header>
        <mat-panel-title class="text-sm font-normal"> Source </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="source-wrapper centered-vertically mb-2" *ngIf="documentGenerationConfig">
        <div class="flex">
          <img class="source-icon" src="assets/images/plan_icon.svg" *ngIf="!assortmentId?.includes('clipboard')" />
          <img
            class="source-icon opacity-50"
            src="assets/images/clipboard.svg"
            *ngIf="assortmentId?.includes('clipboard')"
          />
          <span
            [matTooltip]="documentGenerationConfig?.assortment?.name"
            [matTooltipDisabled]="documentGenerationConfig?.assortment?.name.length < 30"
          >
            {{ assortment?.name | shorten: 30 }}
          </span>
          <span *ngIf="assortmentId?.includes('clipboard')">Clipboard</span>
        </div>
        <div
          class="cursor-pointer text-accent text-xs"
          data-test="change-assortment"
          (click)="toggleAssortmentSelector()"
        >
          Change
        </div>
      </div>
      <div class="flex items-center justify-between">
        <div class="flex ml-[-10px] text-[12px]">
          <app-menu
            data-test="app-filter-panel"
            name="Filter"
            icon="filter_list"
            position="left"
            [highlight]="filterDefinition?.filterCriteria?.propertyCriteria?.length"
          >
            <app-filter-panel
              [equalsOnly]="false"
              [filterDefinition]="filterDefinition"
              [hideApplyOptions]="true"
              [sourceValues]="sourceValues"
              [propertyTypeDefaultFilterConditions]="propertyTypeDefaultFilterConditions"
              (filterCriteriaChange)="handleFilterChange($event)"
            >
            </app-filter-panel>
          </app-menu>

          <app-menu
            class="ml-[-10px]"
            data-test="app-sort-panel"
            name="Sort"
            icon="sort"
            position="left"
            [highlight]="sortDefinitions.length > 0"
          >
            <app-sort-panel
              data-test="sort-panel"
              [properties]="sortProperties"
              [hideApplyOptions]="true"
              [currentSorts]="sortDefinitions"
              (performSort)="handleSortChange($event)"
            >
            </app-sort-panel>
          </app-menu>
        </div>
        <div class="flex">
          <div class="count" matTooltip="Frame count" matTooltipPosition="above" data-test="edit-lineboard-frame-count">
            <mat-icon svgIcon="app-frame-icon"></mat-icon>
            {{ !loadingCounts ? frameCount : '' }}
            <mat-spinner *ngIf="loadingCounts" diameter="20"></mat-spinner>
          </div>
          <div
            class="count ml-2"
            matTooltip="Item count"
            matTooltipPosition="above"
            data-test="edit-lineboard-item-count"
          >
            <mat-icon svgIcon="tag"></mat-icon>
            {{ !loadingCounts ? itemCount : '' }}
            <mat-spinner *ngIf="loadingCounts" diameter="20"></mat-spinner>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-divider></mat-divider>
    <mat-expansion-panel class="mat-elevation-z0" expanded>
      <mat-expansion-panel-header>
        <mat-panel-title class="text-sm font-normal"> Groupings </mat-panel-title>
      </mat-expansion-panel-header>
      <div *ngFor="let grouping of groupings; let i = index" class="flex flex-col">
        <div class="flex items-center text-[12px]">
          <app-autocomplete
            [attr.data-test]="'edit-lineboard-grouping-level-' + (i + 1)"
            [value]="selectedProperties.get(i)"
            [label]="'Level ' + (i + 1)"
            [isRequired]="i === 0"
            [options]="availablePropertiesForGroup.get(i)"
            (valueSelected)="selectGroupingProperty($event, i)"
            [displayProperty]="'propertyDefinition.label'"
          >
          </app-autocomplete>
          <div *ngIf="i > 0">
            <mat-icon
              (click)="removeGrouping(i)"
              class="ml-2 mb-5 cursor-pointer"
              [attr.data-test]="'edit-lineboard-remove-grouping-' + (i + 1)"
              >close</mat-icon
            >
          </div>
        </div>
        <div class="flex items-center group-sortby" data-test="group-sortby">
          <p class="text-xs mr-2">Sort by:</p>
          <div
            class="flex-center cursor-pointer"
            *ngIf="selectedProperties.get(i)?.propertyDefinition.options"
            [matMenuTriggerFor]="sortByMenu"
          >
            <span class="text-primary text-xs">{{
              selectedProperties.get(i)?.sort.includes('list_order') ? 'List Order' : 'Value'
            }}</span>
          </div>
          <div *ngIf="!selectedProperties.get(i)?.propertyDefinition.options" class="flex-center cursor-pointer">
            <span class="text-primary text-xs">{{ 'Value' }}</span>
          </div>
          <div class="flex-center cursor-pointer">
            <span class="material-icons cursor-pointer" (click)="toggleSortOrder(i)">
              {{
                !selectedProperties.get(i) ||
                selectedProperties.get(i)?.sort === 'asc' ||
                selectedProperties.get(i)?.sort === 'list_order_asc'
                  ? 'arrow_drop_up'
                  : 'arrow_drop_down'
              }}
            </span>
          </div>
          <mat-menu #sortByMenu="matMenu">
            <button mat-menu-item (click)="onSortOptionSelected(i, 'Value')">Value</button>
            <button mat-menu-item (click)="onSortOptionSelected(i, 'List Order')">List Order</button>
          </mat-menu>
        </div>
      </div>
      <div
        *ngIf="groupings.length < 5"
        class="w-[70px] text-[12px] text-primary cursor-pointer"
        (click)="addGrouping()"
        data-test="edit-lineboard-add-grouping"
      >
        + Add level
      </div>
    </mat-expansion-panel>
    <mat-divider></mat-divider>
    <mat-expansion-panel class="mat-elevation-z0" expanded>
      <mat-expansion-panel-header>
        <mat-panel-title class="text-sm font-normal"> Layout </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="">
        <div class="text-[12px] mb-1 text-primary">Level</div>
        <mat-radio-group class="text-xs" [(ngModel)]="itemLevel" (change)="getFrameItemCounts()">
          <mat-radio-button value="option" class="" data-test="edit-lineboard-option-level"> Option </mat-radio-button>
          <mat-radio-button value="family" class="ml-2" data-test="edit-lineboard-family-level">
            Item
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="text-xs mt-3 plain-select" *ngIf="frameTemplates?.length > 0">
        <div class="text-[12px] mb-1 text-primary">Frame</div>
        <app-generator-frame-template-chooser
          #templateChooser
          [showTemplateBrowser]="false"
          [frameTemplates]="frameTemplates"
          [selectedFrameTemplateId]="this.selectedFrameTemplate?.id"
          (setFrameTemplate)="handleSetFrameTemplate($event)"
        >
        </app-generator-frame-template-chooser>
      </div>
      <div class="text-xs" [ngClass]="{ 'mt-3': frameTemplates?.length === 0 }">
        <div class="text-[12px] mb-2 text-primary">Frame Layout</div>
        <mat-radio-group class="radio-group" [(ngModel)]="gridTemplateDimensions" (change)="getFrameItemCounts()">
          <mat-radio-button class="mt-1 !flex" [value]="option" *ngFor="let option of gridTemplateOptions"
            >{{ option.label }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="text-xs mt-3">
        <div class="text-[12px] mb-2 text-primary">Alignment</div>
        <mat-radio-group class="radio-group" [(ngModel)]="gridAlignment" (change)="getFrameItemCounts()">
          <mat-radio-button class="mt-1 !flex" [value]="'left'">Left </mat-radio-button>
          <mat-radio-button class="mt-1 !flex" [value]="'center'">Center </mat-radio-button>
          <mat-radio-button class="mt-1 !flex" [value]="'right'">Right </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="mt-3 text-xs">
        <div *ngIf="!selectedFrameTemplate || selectedFrameTemplate?.id === 'default'">
          <mat-slide-toggle
            class="options"
            color="primary"
            labelPosition="before"
            [(ngModel)]="includeFramePanel"
            (change)="getFrameItemCounts()"
          >
            Frame info panel
          </mat-slide-toggle>
        </div>
        <div>
          <mat-slide-toggle
            class="options"
            color="primary"
            labelPosition="before"
            [(ngModel)]="groupMultiSelectInSeparateFrame"
            (change)="getFrameItemCounts()"
          >
            Multi-value properties in separate frames
          </mat-slide-toggle>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-divider></mat-divider>
    <mat-expansion-panel class="mat-elevation-z0" expanded>
      <mat-expansion-panel-header>
        <mat-panel-title class="text-sm font-normal"> Item Card Properties </mat-panel-title>
      </mat-expansion-panel-header>
      <div *ngIf="documentGenerationConfig && selectedComponentProperties">
        <!-- <div class="mb-2 text-xs">ITEM CARD PROPERTIES</div> -->
        <app-property-view-template-builder
          #itemCardViewDefinitionBuilder
          [availableViewProperties]="availableViewProperties"
          [defaultPropertyStyle]="defaultComponentPropertyStyle"
          [viewDefinitionApplicationViewSlug]="'common:item_component'"
          [selectedPropertiesList]="selectedComponentProperties"
          (propertiesChangedEvent)="handleItemCardPropertyChange($event)"
          [allowTemplateEdit]="isOrgAdmin"
        >
        </app-property-view-template-builder>
      </div>

      <div *ngIf="includeFramePanel" class="mt-6">
        <div class="mb-2 text-xs">Info Panel Properties</div>
        <app-property-view-template-builder
          #framePanelViewDefinitionBuilder
          [availableViewProperties]="availablePropertyPanelProperties"
          [defaultPropertyStyle]="defaultPanelPropertyStyle"
          [viewDefinitionApplicationViewSlug]="'common:frame_panel'"
          [selectedPropertiesList]="panelPropertyTemplate?.properties"
          (propertiesChangedEvent)="handlePanelPropertyChange($event)"
          [allowTemplateEdit]="isOrgAdmin"
        >
        </app-property-view-template-builder>
      </div>
    </mat-expansion-panel>
  </div>
  <div
    *ngIf="!loading && documentGenerationConfig && isEditable"
    class="border-t border-t-solid border-t-neutral-200 flex items-center justify-center p-3"
  >
    <button
      class="!mr-[18px] w-[150px]"
      [disabled]="frameCount === 0 || (includeFramePanel && !panelPropertyTemplate)"
      mat-raised-button
      color="primary"
      (click)="refreshLineboard()"
      data-test="edit-lineboard-refresh"
    >
      REGENERATE
    </button>
    <button class="w-[150px]" mat-stroked-button (click)="hide()" data-test="edit-lineboard-cancel">CANCEL</button>
  </div>
</div>
