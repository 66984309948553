import { Scene, AbstractMesh, ActionManager, ExecuteCodeAction, Observable } from '@babylonjs/core';

export class BaseHotspot360 {
  public scene: Scene;
  public mesh: AbstractMesh;

  public hotspotObs = new Observable();

  constructor(mesh: AbstractMesh, scene: Scene) {
    this.scene = scene;
    this.mesh = mesh;

    this.mesh.actionManager = new ActionManager(this.scene);
  }

  public enableHotspot(disable?: boolean): void {
    this.mesh.isVisible = !disable;
  }

  public disableHotspot(): void {
    this.enableHotspot(true);
  }
}

export class LocationHotspot360 extends BaseHotspot360 {
  public locationName: string;

  constructor(locationName: string, mesh: AbstractMesh, scene: Scene) {
    super(mesh, scene);

    this.locationName = locationName;

    if (!this.mesh.actionManager) {
      this.mesh.actionManager = new ActionManager(this.scene);
    }

    this.mesh.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnPickTrigger,
        },
        () => {
          this.hotspotObs.notifyObservers(['PICK:LOCATION', this.locationName]);
        },
      ),
    );
    this.mesh.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnPointerOverTrigger,
        },
        () => {
          // this.mesh.visibility = 1;
        },
      ),
    );
    this.mesh.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnPointerOutTrigger,
        },
        () => {
          // this.mesh.visibility = 0;
        },
      ),
    );
  }
}

export class EntityHotspot360 extends BaseHotspot360 {
  public entityReference: string;

  constructor(entityReference: string, mesh: AbstractMesh, scene: Scene) {
    super(mesh, scene);

    this.entityReference = entityReference;

    if (!this.mesh.actionManager) {
      this.mesh.actionManager = new ActionManager(this.scene);
    }

    this.mesh.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnPickTrigger,
        },
        () => {
          const eventData = ['PICK:ENTITY', this.entityReference];
          this.hotspotObs.notifyObservers(eventData);
        },
      ),
    );
    this.mesh.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnPointerOverTrigger,
        },
        () => {
          // this.mesh.visibility = 1;
          const eventData = ['ENTER:ENTITY', this.entityReference];
          this.hotspotObs.notifyObservers(eventData);
        },
      ),
    );
    this.mesh.actionManager.registerAction(
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnPointerOutTrigger,
        },
        () => {
          // this.mesh.visibility = 0;
          const eventData = ['LEAVE:ENTITY', this.entityReference];
          this.hotspotObs.notifyObservers(eventData);
        },
      ),
    );
  }
}
