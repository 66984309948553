<div class="header flex-between-center">
  <h5>Showcase Analytics</h5>
  <app-date-picker></app-date-picker>
</div>
<div class="body">
  <div class="column">
    <app-access-report class="dashboardComponent"></app-access-report>
  </div>
  <div class="column">
    <app-user-access-report class="dashboardComponent"></app-user-access-report>
    <app-item-report class="dashboardComponent"></app-item-report>
  </div>
</div>
