import { ComponentRef, Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { skip, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '@common/auth/auth.service';
import { CropDefinition, DocumentAction, DocumentChangeType } from '@contrail/documents';
import { ActionRequest } from '@contrail/actions';
import { CropImageElementComponent } from './crop-image-element.component';
import { DocumentService } from '../../document.service';

@Injectable({
  providedIn: 'root',
})
export class CropImageElementService {
  private destroy$ = new Subject();
  private cropImgOverlay: OverlayRef;
  private sourceEvent: any = null;

  private cropDefinition: CropDefinition;

  constructor(
    private authService: AuthService,
    private documentService: DocumentService,
    private overlay: Overlay,
  ) {
    // this.documentService.documentElementEvents.subscribe(event => {
    //   if (!event) {
    //     return;
    //   }
    //   if (event.eventType === 'dblclick' && event?.element && !event.element.isLocked && event.element.type === 'image') {
    //     this.documentService.handleActionRequest(new ActionRequest('crop_image', event.element));
    //   }
    // });
    // this.documentService.actionRequests.subscribe(request => {
    //   if (request?.actionType === 'crop_image') {
    //     // const imageId = `img-${request?.sourceEvent?.id}`;
    //     this.sourceEvent = request?.sourceEvent;
    //     this.cropDefinition = null;
    //     this.openCropImageOverlay();
    //     return;
    //   } else if (request?.actionType === 'exit_crop_mode') {
    //   }
    // });
  }

  async openCropImageOverlay() {
    this.destroy$.next(true);
    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
    // const positionStrategy = this.overlay.position().global().top('100px').left('400px');
    this.cropImgOverlay = this.overlay.create({
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      backdropClass: 'transparent-backdrop',
      panelClass: ['flex-center'],
      positionStrategy: positionStrategy,
      maxWidth: '70vw',
      maxHeight: '70vh',
      // width: '50vw',
      // height: '50vh'
    });
    const componentPortal = new ComponentPortal(CropImageElementComponent);
    let ref: ComponentRef<CropImageElementComponent>;
    ref = this.cropImgOverlay.attach(componentPortal);

    let initialCrop = null;
    if (this.sourceEvent?.cropDefinition?.width && this.sourceEvent?.cropDefinition?.height) {
      initialCrop = {
        x1: this.sourceEvent?.cropDefinition?.x1,
        x2: this.sourceEvent?.cropDefinition?.x2,
        y1: this.sourceEvent?.cropDefinition?.y1,
        y2: this.sourceEvent?.cropDefinition?.y2,
      };
    }
    ref.instance.initialCrop = initialCrop;
    // const rotate = this.sourceEvent?.rotate?.angle || 0
    // ref.instance.transform = `rotate(${rotate}deg)`;

    if (this.sourceEvent.url.includes('api.vibeiq.com') || this.sourceEvent.url.includes('api.dev.vibeiq.com')) {
      const authContext = await this.authService.getAuthContext();
      const blobRes = await fetch(this.sourceEvent.url, {
        headers: {
          'x-api-key': authContext.token,
          'x-api-org': authContext.currentOrg.orgSlug,
        },
      });
      ref.instance.imageBlob = await blobRes.blob();
      // const file = new File([blobFile], fileName, { type: 'image/png' });
    } else {
      const blobRes = await fetch(this.sourceEvent.url, {
        cache: 'no-cache',
      });
      ref.instance.imageBlob = await blobRes.blob();

      // load-image.service.ts / loadImageFromURL() has CORS error while loading some s3 bucket images.
      // ref.instance.imageURL = this.sourceEvent.url; // `https://picsum.photos/200`
    }

    ref.instance.cropDefinition$.pipe(skip(1), takeUntil(this.destroy$)).subscribe((crop) => {
      this.cropDefinition = crop;
    });

    this.cropImgOverlay
      ?.keydownEvents()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        res?.preventDefault(); // PREVENT ENTER KEY _ it is focused on CROP in property-configurator-bar
        if (res?.code === 'Escape' || res?.code === 'Enter' || res?.code === 'NumpadEnter') {
          ref.instance.imageBlob = null;
          ref.instance.imageURL = null;
          const scale = this.sourceEvent?.size?.width / ref.instance.imgWidth;
          if (res.code === 'Enter' || res?.code === 'NumpadEnter') {
            this.updateCropping(scale);
          }
          this.closeOverlay();
        }
      });

    this.cropImgOverlay
      ?.backdropClick()
      .pipe(take(1))
      .subscribe((evt) => {
        ref.instance.imageBlob = null;
        ref.instance.imageURL = null;
        this.updateCropping(1);
        this.closeOverlay();
      });
  }

  closeOverlay() {
    this.cropImgOverlay?.detach();
    this.cropImgOverlay?.dispose();
    this.overlay?.position().global().dispose();

    this.destroy$.next(null);
    this.destroy$.complete();
    this.sourceEvent = null;
    this.cropDefinition = null;
    this.documentService.deselectAllElements();
  }

  updateCropping(scale) {
    if (this.cropDefinition) {
      const actions: Array<DocumentAction> = [];

      const prevCropDefinition = this.sourceEvent?.cropDefinition || null;
      // const prevPosition = this.sourceEvent.position;
      // const prevX1 = prevCropDefinition?.x1 || 0;
      // const prevY1 = prevCropDefinition?.y1 || 0;
      // const diffX = this.cropDefinition.x1 - prevX1;
      // const diffY = this.cropDefinition.y1 - prevY1;
      // const newPosition = {
      //   x: prevPosition.x + (diffX * scale),
      //   y: prevPosition.y + (diffY * scale)
      // }

      const documentAction = new DocumentAction(
        {
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementId: this.sourceEvent.id,
          elementData: { id: this.sourceEvent.id, cropDefinition: this.cropDefinition },
        },
        {
          changeType: DocumentChangeType.MODIFY_ELEMENT,
          elementId: this.sourceEvent.id,
          elementData: { id: this.sourceEvent.id, cropDefinition: prevCropDefinition },
        },
      );
      actions.push(documentAction);
      this.documentService.handleDocumentActions(actions);
    }
  }
}
