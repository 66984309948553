<app-modal [title]="'Add Hot Spot'" [dialogRef]="dialogRef">
  <div class="header">
    <div>Select an item to add a hot spot.</div>
    <app-search-bar [lgGray]="true" [placeholder]="'Search'"></app-search-bar>
  </div>

  <div class="data-pane" *ngIf="(currentData$ | async)?.length">
    <div [id]="itemData.id" class="item-row" *ngFor="let itemData of currentData$ | async" (click)="select(itemData)">
      <div class="image-placeholder" *ngIf="!getImage(itemData)">
        <mat-icon>insert_photo</mat-icon>
      </div>
      <img *ngIf="getImage(itemData)" [attr.src]="itemData?.properties?.thumbnail | secureImage | async" />
      <div class="item-details">
        <div class="item-name">{{ itemData.properties.itemName }}</div>
        <div class="itemColorCode">{{ itemData?.properties.colorCode }}</div>
        <div class="item-meta">
          {{ itemData.properties.styleNumber }}
          <br />
          {{ itemData?.properties?.division }}
          {{ itemData?.properties?.projectType }}
          {{ itemData?.properties?.introDate }}
          {{ itemData?.properties?.wholesalePrice }}
        </div>
      </div>
    </div>
  </div>
  <div class="actions">
    <button mat-stroked-button (click)="cancel()">Cancel</button>
  </div>
</app-modal>
