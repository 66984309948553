import { Injectable, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  DocumentAction,
  DocumentChangeType,
  DocumentElement,
  DocumentElementEvent,
  DocumentTextElementEvent,
} from '@contrail/documents';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ComponentEditorService } from '../../document/component-editor/component-editor.service';
import { DocumentService } from '../../document/document.service';
import { UndoRedoHandler } from '../../handlers/undo-redo-handler';
import { UploadImageFileModalComponent } from './upload-image-file-modal/upload-image-file-modal.component';
import { RootStoreState } from 'src/app/root-store';
import { SideMenuOverlay } from '../../document/document-store/document.state';
import { DocumentActions } from '../../document/document-store';
import { ActionRequest } from '@contrail/actions';
import { ComposerService } from '../composer.service';
import { ComposerToolbarPasteModalComponent } from './composer-toolbar-copy-paste/composer-toolbar-paste-modal-component';

@Injectable({
  providedIn: 'root',
})
export class ComposerToolbarService implements OnDestroy {
  private documentElementEventsSubject: BehaviorSubject<DocumentElementEvent> = new BehaviorSubject(null);
  public documentElementEvents: Observable<DocumentElementEvent> = this.documentElementEventsSubject.asObservable();

  private textElementEventsSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public textElementEvents: Observable<any> = this.textElementEventsSubject.asObservable();

  private eventSub: Subscription;
  private textElementEventSub: Subscription;

  constructor(
    private documentService: DocumentService,
    private composerService: ComposerService,
    private componentEditorService: ComponentEditorService,
    private undoRedoHandler: UndoRedoHandler,
    private dialog: MatDialog,
    private store: Store<RootStoreState.State>,
  ) {
    this.subscribe();
  }

  subscribe() {
    this.eventSub = this.documentService.documentElementEvents.subscribe((event) => {
      this.documentElementEventsSubject.next(event);
    });

    this.textElementEventSub = this.documentService.documentTextElementEvents.subscribe((event) => {
      this.textElementEventsSubject.next(event);
    });
  }

  ngOnDestroy() {
    this.eventSub.unsubscribe();
    this.textElementEventSub.unsubscribe();
  }

  undoActions() {
    this.undoRedoHandler.undoActions();
  }

  redoActions() {
    this.undoRedoHandler.redoActions();
  }

  handleElementChanges(elements: Array<DocumentElement>, undoElements?: Array<DocumentElement>) {
    const actions: Array<DocumentAction> = [];
    elements.forEach((el, i) => {
      actions.push(
        new DocumentAction(
          {
            changeType: DocumentChangeType.MODIFY_ELEMENT,
            elementData: el,
            elementId: el.id,
          },
          undoElements
            ? {
                elementId: el.id,
                changeType: DocumentChangeType.MODIFY_ELEMENT,
                elementData: undoElements[i],
              }
            : null,
        ),
      );
    });
    this.documentService.handleDocumentActions(actions);
  }

  handleCopyRequest(action: ActionRequest) {
    this.documentService.handleActionRequest(action);
    this.composerService.composerClipboard.copyFrame(null);
  }

  openImageFileUploadModal() {
    const data = { autoFocus: false, disableClose: true };
    const dialogRef = this.dialog.open(UploadImageFileModalComponent, data);
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`The uploadpdf dialog was closed ${JSON.stringify(result)}`);
    });
  }

  showChooser(overlay) {
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  showComments() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Comments';
    overlay.slug = 'showComments';
    overlay.showChooser = true;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  showItemChooser() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Item Chooser';
    overlay.slug = 'addProduct';
    overlay.showChooser = true;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  showComponentConfigurator() {
    this.componentEditorService.showComponentConfigurator();
  }

  showComposerFrameSettings() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Frame Settings';
    overlay.slug = 'composerFrameSettings';
    overlay.showChooser = true;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  showCollectionFrameLayout() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Layout Options';
    overlay.slug = 'collectionFrameLayout';
    overlay.showChooser = true;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  showGridFrameLayout() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = 'Layout Options';
    overlay.slug = 'gridFrameLayout';
    overlay.showChooser = true;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  showDashboard() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = '';
    overlay.slug = 'dashboard';
    overlay.showChooser = true;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  showEditLineboardConfig() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = '';
    overlay.slug = 'lineboardEditor';
    overlay.showChooser = true;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  setInteractionMode(mode) {
    this.documentService.setInteractionMode(mode);
  }

  getInteractionMode() {
    return this.documentService.getInteractionMode();
  }

  arrangeElements(actionType: string) {
    const sourceEvent = {
      element: this.documentService.getSelectedElements()[0],
      selectedElements: this.documentService.getSelectedElements(),
    };
    this.documentService.handleActionRequest(new ActionRequest(actionType, sourceEvent));
  }

  areMultipleElementsSelected(threshold: number) {
    if (this.documentService.getSelectedElements() && this.documentService.getSelectedElements().length > threshold) {
      return true;
    }
    return false;
  }

  showPasteWarningModal() {
    const data = { autoFocus: false };
    const dialogRef = this.dialog.open(ComposerToolbarPasteModalComponent, data);
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`The uploadpdf dialog was closed ${JSON.stringify(result)}`);
    });
  }

  getSelectedElements() {
    return this.documentService.getSelectedElements();
  }

  setPaintFormatAction(action) {
    this.documentService.setInteractionMode(action);
    this.documentService.handleActionRequest(new ActionRequest(action));
  }

  handleTextElementActions(actions: DocumentTextElementEvent) {
    this.documentService.handleDocumentTextElementActions(actions);
  }

  handleStickyNoteElementActions(action: DocumentTextElementEvent) {
    this.documentService.handleDocumentStickyNoteElementActions(action);
  }
}
