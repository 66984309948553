<div class="widgets-list">
  <button
    [ngClass]="{ 'active-menu': getActiveColor('text') || getActiveColor('create_text_element') }"
    mat-icon-button
    matTooltip="Text [T]"
    [matMenuTriggerFor]="menu"
    [matMenuTriggerData]="{ menu: textMenus }"
    data-test="document-widget-tray-add-text-box"
  >
    <mat-icon svgIcon="app-text-create"></mat-icon>
  </button>
  <button mat-icon-button matTooltip="Insert image" (click)="uploadImage()">
    <mat-icon svgIcon="app-image-tool"></mat-icon>
  </button>
  <button
    [ngClass]="{ 'active-menu': getActiveColor('pen') || getActiveColor('highlighter') || getActiveColor('eraser') }"
    mat-icon-button
    matTooltip="Pen"
    (click)="
      getActiveColor('highlighter') ? setInteractionMode('highlighter') : setInteractionMode('pen');
      subscribeToDocumentClickToCloseMenu($event, ['pen', 'highlighter', 'eraser'], penMenuTrigger)
    "
    [matMenuTriggerFor]="penMenu"
    [matMenuTriggerData]="{ menu: penMenus }"
    #penMenuTrigger="matMenuTrigger"
    data-test="document-widget-tray-add-pen-tool"
  >
    <mat-icon
      *ngIf="getActiveColor('pen') || (!getActiveColor('highlighter') && !getActiveColor('eraser'))"
      class="icons material-symbols-outlined"
      >stylus</mat-icon
    >
    <mat-icon *ngIf="getActiveColor('highlighter')" class="icons material-symbols-outlined">ink_highlighter</mat-icon>
    <mat-icon *ngIf="getActiveColor('eraser')" class="icons material-symbols-outlined">ink_eraser</mat-icon>
  </button>
  <button
    [color]="getActiveColor('shape')"
    mat-icon-button
    matTooltip="Shape"
    (click)="closeMenu(penMenuTrigger)"
    [matMenuTriggerFor]="menu"
    [matMenuTriggerData]="{ menu: shapeMenus }"
    data-test="document-widget-tray-add-shapes"
  >
    <mat-icon *ngIf="!this.shapeSelected.shape" svgIcon="app-shape-tool"></mat-icon>
    <mat-icon
      *ngIf="this.shapeSelected.shape && this.shapeSelected.svgIcon"
      [svgIcon]="this.shapeSelected.icon"
      [class]="this.shapeSelected.class + ' selected-menu'"
    ></mat-icon>
    <mat-icon *ngIf="this.shapeSelected.shape && !this.shapeSelected.svgIcon" [class]="this.shapeSelected.class">
      {{ this.shapeSelected.icon }}
    </mat-icon>
  </button>
  <button
    [color]="getActiveColor('line')"
    mat-icon-button
    matTooltip="Lines"
    (click)="closeMenu(penMenuTrigger)"
    [matMenuTriggerFor]="menu"
    [matMenuTriggerData]="{ menu: lineMenus }"
    data-test="document-widget-tray-add-lines"
  >
    <mat-icon *ngIf="!isModeSelected(lineMenus)">horizontal_rule</mat-icon>
    <mat-icon *ngIf="isModeSelected(lineMenus)">{{ getActiveIcon(lineMenus) || lineMenus[0].icon }}</mat-icon>
  </button>
  <button
    [color]="getActiveColor('create_table')"
    (click)="setInteractionMode('create_table')"
    mat-icon-button
    matTooltip="Table"
    matTooltipPosition="below"
    data-test="document-widget-tray-create-table"
  >
    <mat-icon>grid_on</mat-icon>
  </button>
  <button
    mat-icon-button
    matTooltip="Sticky note"
    matTooltipPosition="below"
    data-test="document-widget-tray-add-sticky-note"
    (click)="closeMenu(penMenuTrigger); setInteractionMode('create_sticky_note')"
  >
    <mat-icon class="material-icons-outlined">sticky_note_2</mat-icon>
  </button>
  <app-document-add-iframe></app-document-add-iframe>
</div>

<mat-menu #penMenu="matMenu" class="tool-menu-row pen-menu-row" [hasBackdrop]="false">
  <ng-template matMenuContent let-menu="menu">
    <ng-container
      [ngTemplateOutlet]="secondaryMenu"
      [ngTemplateOutletContext]="{ menu: menu, tooltipPosition: 'below', keepMenuOpen: true }"
    >
    </ng-container>
    <ng-container *ngIf="['highlighter', 'pen'].indexOf(interactionMode) !== -1">
      <mat-divider vertical="true" class="divider !ml-px"></mat-divider>
      <app-property-configurator-color-picker
        matTooltip="Line color"
        matTooltipPosition="below"
        (click)="$event.preventDefault(); $event.stopPropagation()"
        (valueChange)="updateValues($event)"
        [currentValue]="getCurrentValue('border.color')"
        [matIconType]="'border_color'"
        [colorType]="'borderColor'"
        [closeOnSelect]="true"
        [colorChip]="true"
      >
      </app-property-configurator-color-picker>
      <app-property-configurator-border-size
        matTooltip="Line thickness"
        matTooltipPosition="below"
        (click)="$event.preventDefault(); $event.stopPropagation()"
        (valueChange)="updateValues($event)"
        [currentValue]="getCurrentValue('border.width')"
        [borderSizes]="[2, 4, 6, 8, 10, 12, 14, 16, 24, 48, 96]"
        [keepParentMenuOpen]="true"
      >
      </app-property-configurator-border-size>
    </ng-container>
  </ng-template>
</mat-menu>

<mat-menu #menu="matMenu" class="tool-menu-row">
  <ng-template matMenuContent let-menu="menu">
    <ng-container
      [ngTemplateOutlet]="secondaryMenu"
      [ngTemplateOutletContext]="{ menu: menu, tooltipPosition: 'below' }"
    >
    </ng-container>
  </ng-template>
</mat-menu>

<ng-template #secondaryMenu let-menu="menu" let-tooltipPosition="tooltipPosition" let-keepMenuOpen="keepMenuOpen">
  <button
    *ngFor="let m of menu"
    mat-menu-item
    [matTooltip]="m.label"
    [matTooltipPosition]="tooltipPosition"
    (click)="keepMenuOpen ? $event.stopPropagation() : null; setInteractionMode(m.id)"
    [ngClass]="{ 'active-menu': getActiveColor(m.id) }"
    [attr.data-test]="'document-widget-tray-add-' + m.id"
  >
    <mat-icon class="icons {{ m.class }}" [svgIcon]="m.svgIcon">{{ m.icon }}</mat-icon>
  </button>
</ng-template>
