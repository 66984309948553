import { OrgMembership } from '@common/auth/auth.service';

export async function GetContentPolicies(owner, org: OrgMembership) {
  if (owner && org) {
    return await getContentPoliciesForContentOwner(owner, org); //TODO: replace with Action Validators when implemented
  }
  return [];
}

export function requiresContentPolicies(org) {
  return org && isValidNewBalanceOrganization(org);
}

async function getContentPoliciesForContentOwner(owner, org: OrgMembership) {
  if (!isValidNewBalanceOrganization(org)) {
    return [];
  }

  if (owner?.nbHSPSecurityStatus === 'lock') {
    return getPoliciesByOrgSlug(org.orgSlug);
  }

  return [];
}

function getPoliciesByOrgSlug(orgSlug: string) {
  switch (orgSlug) {
    case 'new-balance-plm-sandbox-1':
      return ['NVxNUbj-v7vYzHhr'];
    case 'nb-reimagine-dev-1':
      return ['mr24aAM0_uIZmmba'];
    case 'nb-reimagine-dev-2':
      return ['PcYXgmQitXZc-0bF'];
    case 'nb-reimagine-training':
      return ['7uIcgcIx3rgFm7xa'];
    case 'nb-reimagine-prod':
      return ['yrLoNY5V4Kq3tk5X'];
    case 'nb-reimagine-qa-1':
      return ['-YZqQgeYt8f_elri'];
    case 'lauren-zone-3':
      return ['t4xXULRuftdOnI-E'];
    default:
      return [];
  }
}

function isValidNewBalanceOrganization(org: OrgMembership): boolean {
  const validNewBalanceOrgSlugs = [
    'demo',
    'lauren-zone-3',
    'new-balance-plm-sandbox-1',
    'nb-reimagine-dev-1',
    'nb-reimagine-dev-2',
    'nb-reimagine-training',
    'nb-reimagine-prod',
    'nb-reimagine-qa-1',
  ];
  return validNewBalanceOrgSlugs.includes(org.orgSlug);
}
