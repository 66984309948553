import { Input, Component, OnInit } from '@angular/core';
import { PresentationFrame } from 'src/app/presentation/presentation';

@Component({
  selector: 'app-composer-showroom-frame',
  templateUrl: './composer-showroom-frame.component.html',
  styleUrls: ['./composer-showroom-frame.component.scss'],
})
export class ComposerShowroomFrameComponent implements OnInit {
  @Input() frame: PresentationFrame;
  constructor() {}

  ngOnInit(): void {}
}
