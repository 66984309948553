import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { ShareLink } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class ShareShowcaseService {
  constructor() {}

  createSharedLink(showcase): Promise<ShareLink> {
    return new Entities().create({
      entityName: 'shared-link',
      object: { contextType: 'showcase', contextId: showcase?.id },
    });
  }

  getSharedLinks(showcase): Promise<ShareLink[]> {
    return new Entities().get({
      entityName: 'shared-link',
      criteria: { contextReference: `showcase:${showcase?.id}`, relations: ['principal'] },
    });
  }

  updateShareLinkAccessLevel(link, accessLevel): Promise<ShareLink> {
    return new Entities().update({ entityName: 'shared-link', id: link?.id, object: { accessLevel } });
  }

  deleteShareLink(id: string) {
    return new Entities().delete({ entityName: 'shared-link', id });
  }
}
