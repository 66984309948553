import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommentsSelectors } from 'src/app/common/comments/comments-store';
import { Comment, CommentsService } from 'src/app/common/comments/comments.service';
import { PinnedComment } from '../composer-pinned-comments.component';
import { AddPinnedCommentsService } from '../add-pinned-comments-service';
import { ComposerService } from '../../composer.service';
import { PresentationFrame } from 'src/app/presentation/presentation';

export const ITEM_PINNED_COMMENT_ID_SUFFIX = 'item-pinned-comment';

@Component({
  selector: 'app-composer-collection-item-pinned-comments',
  templateUrl: './composer-collection-item-pinned-comments.component.html',
  styleUrls: ['./composer-collection-item-pinned-comments.component.scss'],
})
export class ComposerCollectionItemPinnedComments implements OnInit {
  @Input() item?: any; // Item can be item family or item option - functionality is the same.

  private subscription: Subscription = new Subscription();
  private frame: PresentationFrame;

  public pinnedComment$: Observable<PinnedComment>;
  public elementId: string;
  public showPinnedComments: boolean;

  constructor(
    private store: Store<RootStoreState.State>,
    private composerService: ComposerService,
    private addPinnedCommentsService: AddPinnedCommentsService,
  ) {}

  ngOnInit(): void {
    this.elementId = `${ITEM_PINNED_COMMENT_ID_SUFFIX}-${this.item.value}`;
    this.subscription.add(this.composerService.currentFrame.subscribe((frame) => (this.frame = frame)));
    this.pinnedComment$ = this.store.select(CommentsSelectors.selectContextComments).pipe(
      map((comments) => comments.filter((comment) => comment.status !== 'closed' && this.isItemComment(comment))),
      map((comments) => Object.values(this.groupByDocumentElement(comments))?.pop()),
    );
    this.subscription.add(
      this.store.select(CommentsSelectors.showPinnedComments).subscribe((showPinnedComments) => {
        this.showPinnedComments = showPinnedComments;
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Item comments is defined by:
   * subContextReference: 'presentation-frame:<frameId>' - belongs to a particular frame
   * ownedByReference: 'item:<itemId>' - can be item family or item option
   * documentElementId and documentPositions should be undefined
   * @param comment
   */
  private isItemComment(comment: Comment) {
    return CommentsService.isEntityOwnedComment(
      comment,
      `item:${this.item.value}`,
      `presentation-frame:${this.frame.id}`,
    );
  }

  private groupByDocumentElement(comments: Array<Comment>) {
    return comments.reduce((counter, comment) => {
      const groupKey = comment.ownedByReference;
      if (counter.hasOwnProperty(groupKey)) {
        counter[groupKey].count = counter[groupKey].count + 1;
      } else {
        counter[groupKey] = {
          groupKey,
          count: 1,
          firstComment: comment,
        };
      }
      return counter;
    }, {});
  }

  /**
   * Show comment overlay with comment bubble is clicked
   * @param comment
   */
  showComments(comment: Comment) {
    this.addPinnedCommentsService.addItemComment(this.item.value, this.frame.id);
  }
}
