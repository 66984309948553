import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Showroom360Viewer } from './utils/showroom-360-viewer';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Overlay } from '@angular/cdk/overlay';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { Entities } from '@contrail/sdk';
import { AssortmentsService } from '@common/assortments/assortments.service';

@Component({
  selector: 'app-showroom-viewer',
  templateUrl: './showroom-viewer.component.html',
  styleUrls: ['./showroom-viewer.component.scss'],
})
export class ShowroomViewerComponent implements OnInit, OnDestroy {
  @Input() showroomId: string;
  viewer: Showroom360Viewer;
  link: SafeResourceUrl = '';

  constructor(
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private overlay: Overlay,
    private store: Store<RootStoreState.State>,
  ) {}

  async ngOnInit() {
    if (!this.showroomId) {
      return;
    }
    const currentShowroom = await new Entities().get({
      entityName: 'showroom',
      id: this.showroomId,
      relations: ['showroomLayouts'],
    });
    const sourceAssortment = await AssortmentsService.getAssortment(currentShowroom?.sourceAssortmentId);
    this.viewer = new Showroom360Viewer();
    const canvas = document.getElementById('360ViewerCanvas') as HTMLCanvasElement;
    this.viewer.setupScene(canvas, this.matDialog, this.overlay, sourceAssortment, currentShowroom);
    const engine = this.viewer.engine;
    setTimeout(() => {
      engine.resize();
    }, 10);
  }

  ngOnDestroy() {
    this.viewer.clear();
    this.viewer.engine.dispose();
    document.removeAllListeners('mousemove');
  }
}
