<div
  *ngIf="collectionElements?.length"
  [ngClass]="{ 'sortable-list': !sidebarOpened, 'sortable-list-mini': sidebarOpened }"
>
  <virtual-scroller #scroll [items]="collectionElements" cdkDropList (cdkDropListDropped)="drop($event)">
    <app-presentation-collection-item
      class="list-value"
      *ngFor="let element of scroll.viewPortItems"
      [assortmentItemData]="backingAssortmentItems$ | async"
      [item]="element"
      cdkDrag
      [cdkDragDisabled]="editorMode !== 'EDIT'"
      [cdkDragData]="getItemIndex(element.value)"
      [itemIndex]="getItemIndex(element.value)"
      (updateCollectionItem)="updateCollectionItem($event)"
      (viewItemDetail)="viewItemDetail($event)"
      [annotationType]="annotationType"
      [annotationOptions]="annotationOptions"
      [statusMessages]="statusMessages"
      [editorMode]="editorMode"
      [frameId]="frame?.id"
      [searchResults]="searchResults"
      [activeSearchResultElement]="activeSearchResultElement"
      [ngClass]="{ selected: (collectionFrameActiveContainer$ | async)?.value === element.value }"
      cdkDrag
    >
    </app-presentation-collection-item>
  </virtual-scroller>
</div>
