import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { EditorMode } from '@common/editor-mode/editor-mode-store/editor-mode.state';
import { ComposerService } from '../../composer.service';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
@Component({
  selector: 'app-composer-frame-toolbar-name',
  templateUrl: './composer-frame-toolbar-name.component.html',
  styleUrls: ['./composer-frame-toolbar-name.component.scss'],
})
export class ComposerFrameToolbarNameComponent implements OnInit {
  currentFrame: any;
  frames: any;
  frameNumber: string;
  editName = false;
  @ViewChild('editNameInput') editNameInput: ElementRef;
  name: string;
  form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    selectedFrame: new UntypedFormControl('', []),
  });
  type: any;
  public editorMode: EditorMode;
  constructor(
    private composerService: ComposerService,
    private store: Store<RootStoreState.State>,
  ) {
    this.store.select(EditorModeSelectors.editorMode).subscribe((mode) => (this.editorMode = mode));
    this.composerService.presentation.subscribe((pres) => {
      this.frames = pres?.frames;
      if (this.currentFrame) {
        this.currentFrame = this.frames?.filter((presFrame) => presFrame.id === this.currentFrame.id)[0];
        this.form.get('selectedFrame').setValue(this.currentFrame);
      } else {
      }
      // this.frames = this.frames.filter(frame => frame?.type === 'document');
    });
    this.composerService.currentFrame.subscribe((frame) => {
      this.currentFrame = frame;
      if (frame) {
        const updatedFrame = this.frames?.filter((presFrame) => presFrame.id === frame.id)[0];
        if (updatedFrame) {
          this.currentFrame = updatedFrame;
        }
      }

      this.form.get('selectedFrame').setValue(this.currentFrame);
      if (this.currentFrame && !this.currentFrame.name) {
        if (this.frames) {
          this.frameNumber = this.frames.findIndex((frame) => frame.id === this.currentFrame.id);
          this.frameNumber = Math.sign(+this.frameNumber) < 0 ? this.frames.length + 1 : this.frameNumber + 1;
          this.frameNumber = 'FRAME ' + this.frameNumber;
          this.form.get('name').setValue(this.frameNumber);
          const existingFrame = ObjectUtil.cloneDeep(this.currentFrame);
          this.currentFrame.name = this.frameNumber;
          this.composerService.handleFrameChanges(this.currentFrame, existingFrame);
        }
      } else {
        this.form.get('name').setValue(this.currentFrame?.name);
      }
    });
  }

  ngOnInit(): void {}

  startNameEdit() {
    this.composerService.setSelectedFrame(null);
    this.editName = true;
    setTimeout(() => {
      this.editNameInput.nativeElement.focus();
    }, 100);
  }

  endEditName() {
    this.composerService.setSelectedFrame(this.currentFrame);
    const existingFrame = ObjectUtil.cloneDeep(this.currentFrame);
    this.editName = false;
    const name = this.form.controls.name.value;
    this.currentFrame.name = name;
    this.composerService.handleFrameChanges(this.currentFrame, existingFrame);
  }

  onSelectedFrameChange(event) {
    const frame = this.form.get('selectedFrame').value;
    this.composerService.setCurrentFrame(frame);
    this.composerService.setSelectedFrame(frame);
  }
}
