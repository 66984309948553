import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { ObjectUtil } from '@contrail/util';

@Component({
  selector: 'app-composer-frame-property-configurator',
  templateUrl: './composer-frame-property-configurator.component.html',
  styleUrls: ['./composer-frame-property-configurator.component.scss'],
})
export class ComposerFramePropertyConfiguratorComponent {
  public sourceEvent: any;
  @ViewChild('widgetTray') widgetElement: ElementRef;
  @Output() valuesChanged = new EventEmitter<any>();

  show(event: any) {
    this.sourceEvent = event;
    if (!this.widgetElement) {
      return;
    }
    const top = event.renderedPosition.y - 70;
    const left = event.renderedPosition.x - 100;
    this.widgetElement.nativeElement.setAttribute('style', `left:${left}px; top:${top}px;`);
    this.widgetElement.nativeElement.classList.add('visible');
  }

  hide() {
    if (this.widgetElement) {
      this.widgetElement.nativeElement.classList.remove('visible');
    }
  }

  updateValues(values) {
    let changes;
    if (values.type === 'fontSize') {
      changes = {
        style: {
          font: {
            size: values.value,
          },
        },
      };
    } else if (values.type === 'textColor') {
      changes = {
        style: {
          color: values.value,
        },
      };
    }
    this.valuesChanged.emit(changes);
  }

  getCurrentValue(index) {
    return ObjectUtil.getByPath(this.sourceEvent.property, index);
  }

  handleClick(event) {
    event.stopPropagation();
  }
}
