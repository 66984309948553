import { State } from './document.state';
import { Action, createReducer, on } from '@ngrx/store';
import { documentInitialState } from './document.state';
import * as DocumentActions from './document.actions';

const toggleChooser = (state: State, { overlay }) => {
  return {
    ...state,
    toggleChooser: { ...overlay },
  };
};

const setViewSize = (state: State, viewSize) => {
  return {
    ...state,
    viewSize,
  };
};

const setLastKnownMousePosition = (state: State, { lastKnownMousePosition }) => {
  return {
    ...state,
    lastKnownMousePosition,
  };
};

const setAnnotatedElements = (state: State, { annotatedElements }) => {
  return {
    ...state,
    annotatedElements,
  };
};

export const documentReducers = createReducer(
  documentInitialState,
  on(DocumentActions.toggleChooser, toggleChooser),
  on(DocumentActions.setViewSize, setViewSize),
  on(DocumentActions.setLastKnownMousePosition, setLastKnownMousePosition),
  on(DocumentActions.setAnnotatedElements, setAnnotatedElements),
);

export function reducer(state: State | undefined, action: Action): any {
  return documentReducers(state, action);
}
