import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ShowcasesService } from 'src/app/showcases/showcases.service';
import { RoutingService } from 'src/app/common/routing/routing.service';
import { ShareShowcaseModalLauncher } from 'src/app/showcases/share-showcase/share-showcase-modal-launcher';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RootStoreState, UserSessionActions } from 'src/app/root-store';
import { ShowcasesSelectors } from 'src/app/showcases/showcases-store';
import { EditorModeSelectors } from '@common/editor-mode/editor-mode-store';
import { DocumentHistoryActions, DocumentHistorySelectors } from '@common/document-history/document-history-store';
import { SideMenuOverlay } from 'src/app/presentation/document/document-store/document.state';
import { DocumentActions } from 'src/app/presentation/document/document-store';
import { CollectionStatusMessageService } from 'src/app/presentation/composer/side-panel/status-messages/collection-status-message.service';
import { CollectionStatusMessage } from '@common/collection-status-message/collection-status-message';
import { Observable } from 'rxjs';
import { AuthService } from '@common/auth/auth.service';
import { ChooserSourceOptionTypes } from '@common/item-data-chooser/source-option';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { Feature } from '@common/feature-flags/feature-flag';
import { UserSession } from '@common/user-sessions/user-session';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  public editorMode = 'EDIT';
  showcase: any;
  loaded = false;
  editName = false;
  isSnapshotRestoreAllowed = true;
  @ViewChild('editNameInput') editNameInput: ElementRef;
  navOptions: any;
  name: string;
  form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
  });
  currentSnapshot: any;
  messages$: Observable<Array<CollectionStatusMessage>>;
  constructor(
    private authService: AuthService,
    private routingService: RoutingService,
    private showcasesService: ShowcasesService,
    private collectionStatusMessageService: CollectionStatusMessageService,
    private shareShowcaseModalLauncher: ShareShowcaseModalLauncher,
    private store: Store<RootStoreState.State>,
  ) {
    this.store.select(EditorModeSelectors.editorMode).subscribe((mode) => (this.editorMode = mode));
    this.store
      .select(DocumentHistorySelectors.currentEntitySnapshot)
      .subscribe((snapshot) => (this.currentSnapshot = snapshot));
  }

  ngOnInit(): void {
    this.isSnapshotRestoreAllowed = !this.authService.isOnSharedLink();
    this.store.select(ShowcasesSelectors.currentShowcase).subscribe((showcase) => {
      if (!showcase) {
        delete this.showcase;
        return;
      }
      this.showcase = showcase;
      this.name = this.showcase.name;
      if (showcase.id) {
        this.store.dispatch(UserSessionActions.loadRemoteUsers({ sessionId: `showcase:${showcase.id}` }));
        this.store.dispatch(UserSessionActions.joinSession({ sessionId: `showcase:${showcase.id}` }));

        this.loaded = true;
        this.form.get('name').setValue(showcase.name);
        this.navOptions = [
          {
            routerLink: this.routingService.getRouterLink(`/showcase-details/${showcase.id}/summary`),
            label: 'Summary',
          },
          {
            routerLink: this.routingService.getRouterLink(`/showcase-details/${showcase.id}/presentation`),
            label: 'Assortment',
          },
          {
            routerLink: this.routingService.getRouterLink(`/showcase-details/${showcase.id}/composer`),
            label: 'Composer',
          },
        ];
      } else {
        this.loaded = false;
      }
    });

    this.messages$ = this.collectionStatusMessageService.messages$;
  }
  preview() {
    this.showcasesService.openShowcaseViewer(this.showcase);
  }
  share() {
    this.shareShowcaseModalLauncher.openModal(this.showcase);
  }

  startNameEdit() {
    this.editName = true;
    setTimeout(() => {
      this.editNameInput.nativeElement.focus();
    }, 100);
  }
  endEditName() {
    this.editName = false;
    const name = this.form.controls.name.value;
    this.name = name;
    this.showcasesService.updateShowcase(this.showcase.id, { name, isArchived: false });
  }

  home() {
    this.routingService.go('/');
  }

  clearSnapshot() {
    this.store.dispatch(DocumentHistoryActions.clearCurrentEntitySnapshot());
  }

  openHistoryPanel($event) {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.label = '';
    overlay.slug = $event;
    overlay.showChooser = true;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  restoreSnapshot() {
    this.store.dispatch(DocumentHistoryActions.restoreEntitySnapshot({ id: this.currentSnapshot.id }));
  }

  assignSourceAssortment(data?: any) {
    if (!data) {
      return;
    }
    this.showcasesService.assignSourceAssortment(data.sourceAssortmentId);
  }

  removeSourceAssorment() {
    this.showcasesService.clearSourceAssortment();
  }

  onShowAlertMessages(event) {
    this.collectionStatusMessageService.showAlertMessages();
  }

  onShowClipboard() {
    const overlay: SideMenuOverlay = {};
    overlay.icon = '';
    overlay.title = 'Clipboard';
    overlay.slug = 'addProduct';
    overlay.showChooser = true;
    overlay.targetSourceType = ChooserSourceOptionTypes.CLIPBOARD;
    overlay.hideSource = true;
    this.store.dispatch(DocumentActions.toggleChooser({ overlay }));
  }

  navigateToUserFrame(session: UserSession) {
    this.store.dispatch(UserSessionActions.navigateToSessionLocation({ session }));
    //action to be dispatched to composerService to
  }
}
