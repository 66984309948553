import { Injectable } from '@angular/core';
import { EntitySnapshot } from '@common/document-history/document-history-store/document-history.state';
import { SessionMessage, SessionMessageHandler } from '../../common/web-socket/session-message';
import { WebSocketMessageDispatcher } from '../../common/web-socket/web-socket-mesage-dispatcher';
import { ShowcaseBackingAssortmentService } from './backing-assortment/showcase-backing-assortment-service';
import { ComposerService } from './composer.service';
import { ConfirmationBoxService } from '@components/confirmation-box/confirmation-box';
import { ShowcasesService } from 'src/app/showcases/showcases.service';
import { ComposerPropertyPoliciesService } from './composer-property-policies/composer-property-policies.service';
import { RootStoreState, UserSessionActions } from '@rootstore';
import { Store } from '@ngrx/store';
import { AssortmentsSelectors } from '@common/assortments/assortments-store';
import { RemoteMouseTrackerActions } from '@common/remote-mouse-tracker/remote-mouse-tracker-store';

@Injectable({
  providedIn: 'root',
})
export class ComposerManagerMessageHandler implements SessionMessageHandler {
  public someProp = false;
  private entitySnapshot: EntitySnapshot;
  private sourceAssortmentChangeInProgress = false;
  private backingAssortmentItems: any[];
  private currentFrameObject: any;

  constructor(
    private composerService: ComposerService,
    private showcasesService: ShowcasesService,
    private store: Store<RootStoreState.State>,
    private backingAssortmentService: ShowcaseBackingAssortmentService,
    private confirmationBoxService: ConfirmationBoxService,
    private composerPropertyPoliciesService: ComposerPropertyPoliciesService,
    private websocketMessageDispatcher: WebSocketMessageDispatcher,
  ) {
    this.init();
  }

  private init() {
    this.websocketMessageDispatcher.registerHandler('CREATE_PRESENTATION_FRAME', this);
    this.websocketMessageDispatcher.registerHandler('UPDATE_PRESENTATION_FRAME', this);
    this.websocketMessageDispatcher.registerHandler('DELETE_PRESENTATION_FRAME', this);
    this.websocketMessageDispatcher.registerHandler('DOCUMENT_ELEMENT_CHANGED', this);
    this.websocketMessageDispatcher.registerHandler('UPDATE_ASSORTMENT_ITEMS', this);
    this.websocketMessageDispatcher.registerHandler('ADD_ASSORTMENT_ITEMS', this);
    this.websocketMessageDispatcher.registerHandler('REMOVE_ASSORTMENT_ITEMS', this);
    this.websocketMessageDispatcher.registerHandler('RESTORE_SHOWCASE_SNAPSHOT', this);
    this.websocketMessageDispatcher.registerHandler('ASSIGN_SOURCE_ASSORTMENT', this);
    this.websocketMessageDispatcher.registerHandler('CLEAR_SOURCE_ASSORTMENT', this);
    this.websocketMessageDispatcher.registerHandler('REMOTE_USER_MOUSE_MOVED', this);
    this.websocketMessageDispatcher.registerHandler('REMOTE_USER_CURRENT_CONTEXT', this);

    this.store
      .select(AssortmentsSelectors.backingAssortmentItems)
      .subscribe((backingAssortmentItems) => (this.backingAssortmentItems = backingAssortmentItems));
    this.composerService.currentFrame.subscribe((currentFrame) => {
      this.currentFrameObject = currentFrame;
    });
  }

  public async handleMessage(message: SessionMessage) {
    // console.log('DocumentManagerMessageHandler: handleMessage --> ', message);

    // Ignore all inbound events when viewing historical version.
    if (this.entitySnapshot) {
      return;
    }

    switch (message.event?.eventType) {
      case 'CREATE_PRESENTATION_FRAME': {
        this.composerService.addNewFrame(message.event.changes.createdFrame);
        break;
      }
      case 'UPDATE_PRESENTATION_FRAME': {
        this.composerService.reorderFrames(message.event.changes);
        break;
      }
      case 'DELETE_PRESENTATION_FRAME': {
        this.composerService.removeFrame(message.event.changes.deletedFrame);
        break;
      }
      case 'DOCUMENT_ELEMENT_CHANGED': {
        this.composerPropertyPoliciesService.handleWebsocketMessage(
          message.event.changes,
          this.currentFrameObject,
          this.backingAssortmentItems,
        );
        this.composerService.handleDocumentElementsUpdated(message.event.changes.actions);
        break;
      }
      case 'UPDATE_ASSORTMENT_ITEMS': {
        this.composerPropertyPoliciesService.handleWebsocketMessage(
          message.event.changes,
          this.currentFrameObject,
          this.backingAssortmentItems,
        );
        this.backingAssortmentService.handleAssortmentItemChanges(message.event.changes);
        break;
      }
      case 'ADD_ASSORTMENT_ITEMS': {
        this.backingAssortmentService.handleAssortmentAddItems(message.event.changes);
        break;
      }
      case 'REMOVE_ASSORTMENT_ITEMS': {
        this.backingAssortmentService.handleAssortmentRemoveItems(message.event.changes);
        break;
      }
      case 'RESTORE_SHOWCASE_SNAPSHOT': {
        const confirm = await this.confirmationBoxService.open(
          'New Version',
          'Another version of this document has been updated or restored. Please refresh your browser to load the new changes.',
          '',
          'REFRESH',
          true,
          true,
        );
        if (confirm) {
          this.composerService.loadPresentation();
        }
        break;
      }
      case 'ASSIGN_SOURCE_ASSORTMENT': {
        if (this.sourceAssortmentChangeInProgress) {
          this.confirmationBoxService.close(); // close existing confirmation box if it's open
        }
        this.sourceAssortmentChangeInProgress = true;
        const confirm = await this.confirmationBoxService.open(
          'Updated Source Assortment',
          'A user has changed or removed the source assortment for this document. Please refresh your browser to load the new changes.',
          '',
          'REFRESH',
          true,
          true,
        );
        if (confirm) {
          this.sourceAssortmentChangeInProgress = false;
          this.showcasesService.assignSourceAssortmentAndRefresh(message.event.changes);
        }
        break;
      }
      case 'CLEAR_SOURCE_ASSORTMENT': {
        if (this.sourceAssortmentChangeInProgress) {
          this.confirmationBoxService.close(); // close existing confirmation box if it's open
        }
        this.sourceAssortmentChangeInProgress = true;
        const confirm = await this.confirmationBoxService.open(
          'Removed Source Assortment',
          'A user has changed or removed the source assortment for this document. Please refresh your browser to load the new changes.',
          '',
          'REFRESH',
          true,
          true,
        );
        if (confirm) {
          this.sourceAssortmentChangeInProgress = false;
          this.showcasesService.clearSourceAssortmentAndRefresh(message.event.changes);
        }
        break;
      }
      case 'REMOTE_USER_MOUSE_MOVED': {
        this.store.dispatch(
          UserSessionActions.syncRemoteUsers({
            data: {
              sessionId: message.sessionId,
              context: message.context,
            },
          }),
        );
        this.store.dispatch(
          UserSessionActions.remoteUserCurrentContextChange({
            data: {
              sessionId: message.sessionId,
              context: message.context,
              event: message.event.changes,
            },
          }),
        );
        this.store.dispatch(
          RemoteMouseTrackerActions.remoteUserMouseMoved({
            data: {
              sessionId: message.sessionId,
              context: message.context,
              event: message.event.changes,
            },
          }),
        );
        break;
      }
      case 'REMOTE_USER_CURRENT_CONTEXT': {
        this.store.dispatch(RemoteMouseTrackerActions.removeRemoteUserFromMouseSession({ user: message.context.user }));
        this.store.dispatch(
          UserSessionActions.remoteUserCurrentContextChange({
            data: {
              sessionId: message.sessionId,
              context: message.context,
              event: message.event.changes,
            },
          }),
        );
        break;
      }
    }
  }
}
