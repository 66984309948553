import { Injectable } from '@angular/core';
import { RemoteMouseTrackerActions } from '@common/remote-mouse-tracker/remote-mouse-tracker-store';
import { Store } from '@ngrx/store';
import { RootStoreState, UserSessionActions } from 'src/app/root-store';
import { SessionMessage, SessionMessageHandler } from '../web-socket/session-message';
import { WebSocketMessageDispatcher } from '../web-socket/web-socket-mesage-dispatcher';

@Injectable({
  providedIn: 'root',
})
export class UserSessionMessageHandler implements SessionMessageHandler {
  public someProp = false;
  constructor(
    private store: Store<RootStoreState.State>,
    private websocketMessageDispatcher: WebSocketMessageDispatcher,
  ) {
    this.init();
  }

  private init() {
    this.websocketMessageDispatcher.registerHandler('JOIN_SESSION', this);
    this.websocketMessageDispatcher.registerHandler('LEAVE_SESSION', this);
  }

  public handleMessage(message: SessionMessage) {
    // console.log("UserSessionMessageHandler: handleMessage --> ", message);
    switch (message.action) {
      case 'JOIN_SESSION': {
        this.store.dispatch(UserSessionActions.addRemoteUserToSession({ user: message.context.user }));
        break;
      }
      case 'LEAVE_SESSION': {
        this.store.dispatch(UserSessionActions.removeRemoteUserFromSession({ user: message.context.user }));
        this.store.dispatch(UserSessionActions.removeRemoteUserCurrentContext({ user: message.context.user }));
        this.store.dispatch(RemoteMouseTrackerActions.removeRemoteUserFromMouseSession({ user: message.context.user }));
        break;
      }
    }
  }
}
